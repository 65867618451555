/* eslint-disable jsx-a11y/label-has-for */
import React, { useCallback, useEffect, useState } from "react";
import { Button } from "react-blueprint-library";
import { useDispatch } from "react-redux";

import BackButtonModal from "../../../../../Forms/BackButtonModal";
import { logoutActions } from "../../../../../../../store/actions";

import "./CommentsForm.scss";

const CriminalDisclosureAddDRPCommentsForm = ({
  updateCCBForm,
  backFormId,
  nextFormId,
  getSubmitDataValues,
  data,
  groupKey,
  headerText,
}) => {
  const dispatch = useDispatch();
  const [showBackModal, setShowBackModal] = useState(false);
  const [comments, setComments] = useState(data || "");
  const [formHasChanged, setFormHasChanged] = useState(false);

  const onCommentsChangeHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setComments(value);
  };

  const handleCloseBackModal = () => setShowBackModal(false);

  const isUpdates = formHasChanged;

  useEffect(() => {
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: true }));
  }, [isUpdates, dispatch]);

  const handleShowBackModal = () => {
    if (formHasChanged) {
      setShowBackModal(true);
    } else {
      if (
        (data !== null && data && !Object.keys(data).length) ||
        data === null
      ) {
        getSubmitDataValues({ comments: null }, headerText);
      }
      updateCCBForm(backFormId);
    }
  };

  const handleBackClick = () => {
    if (!data) {
      getSubmitDataValues({ comments: null }, headerText);
    }
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: false }));
    updateCCBForm(backFormId);
    handleCloseBackModal();
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  const handleNext = useCallback(() => {
    getSubmitDataValues({ [`${groupKey}`]: comments }, headerText, true);
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: false }));
    updateCCBForm(nextFormId);
  }, [
    updateCCBForm,
    nextFormId,
    getSubmitDataValues,
    comments,
    dispatch,
    headerText,
    groupKey,
  ]);

  return (
    <div className="criminal-disclosure-comments-form-wrapper">
      <div className="crim-comments-container">
        <label
          htmlFor="crimDisclCommentsId"
          className="comments-label"
          id="crimDisclCommentsLabelId"
        >
          You may use this field to provide a brief summary of the circumstances
          leading to the investigation as well as the current status or final
          disposition and/or finding(s).{" "}
          <span style={{ paddingLeft: "5px" }}>(Optional)</span>
        </label>
        <textarea
          id="crimDisclCommentsId"
          onChange={onCommentsChangeHandler}
          className={"form-control"}
          minLength={0}
          maxLength={500}
          rows="3"
          cols={500}
          value={comments}
        />
      </div>

      <div className="backbutton-drp-crim row">
        <div className="col-md-12 buttons">
          <span role="presentation" onClick={handleShowBackModal}>
            <strong id="crimDisclBack">Back</strong>
          </span>
          <Button label="Complete DRP" onClick={handleNext} disabled={false} />
          {false && <span className="mm-dots-loading"></span>} {/* loading */}
        </div>
      </div>

      <BackButtonModal
        isOpen={showBackModal}
        closeBackModal={handleCloseBackModal}
        backClick={handleBackClick}
      />
    </div>
  );
};

export default CriminalDisclosureAddDRPCommentsForm;
