/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button } from "react-blueprint-library";
import BackButtonModal from "../../../../../../Forms/BackButtonModal";
import "../../BankruptcyDisclosure.scss";
import { CCB_FORM_ID } from "../../../constants";

const courtOptions = {
  FEDERAL: "Federal Court",
  STATE: "State Court",
  FOREIGN: "Foreign Court",
  OTHER: "Other",
};

const BankruptcyDisclosureComments = ({
  updateCCBForm,
  nextFormId,
  backFormId,
  updateTempBankruptcyDRP,
  tempBankruptcyDRP,
  updateBankruptcyDisclosureDRPs,
  hasSIPA,
  hasCompromise,
}) => {
  const [bankruptcyComments, setBankruptcyComments] = useState("");
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [showBackModal, toggleShowBackModal] = useState(false);
  const [currentFormChanged, hasCurrentFormChanged] = useState(false);

  const handleToggleBackModal = (value) => {
    if (currentFormChanged) {
      toggleShowBackModal(value);
    } else {
      handleBack();
    }
  };
  const handleNext = () => {
    let tmpDRP = {
      ...tempBankruptcyDRP,
      bankruptcyComments: bankruptcyComments,
    };

    updateTempBankruptcyDRP(tmpDRP);
    updateBankruptcyDisclosureDRPs(tmpDRP);
    updateCCBForm(nextFormId);
    hasCurrentFormChanged(false);

    //wait/updateDRP stuff
  };
  const handleBack = () => {
    const tmpDRP = {
      ...tempBankruptcyDRP,
      bankruptcyComments,
    };
    if (hasSIPA(tmpDRP)) {
      updateCCBForm(CCB_FORM_ID.ADD_BANKRUPTCY_DRP_PAGE_5);
    } else if (hasCompromise(tmpDRP)) {
      updateCCBForm(CCB_FORM_ID.ADD_BANKRUPTCY_DRP_PAGE_4);
    } else {
      updateCCBForm(CCB_FORM_ID.ADD_BANKRUPTCY_DRP_PAGE_3);
    }
  };

  const handleChange = ({ target: { id, value } }) => {
    setBankruptcyComments(value);
    hasCurrentFormChanged(true);
  };

  useEffect(() => {
    setIsFormComplete(true);
  });
  useEffect(() => {
    if (tempBankruptcyDRP?.bankruptcyComments) {
      setBankruptcyComments(tempBankruptcyDRP?.bankruptcyComments);
      hasCurrentFormChanged(true);
      setIsFormComplete(true);
    }
  }, []);
  return (
    <div className="bankruptcy-form-wrapper">
      <br />
      <div className="row">
        <div className="col-md-12">
          <span id="bondAddDrpText" className="comments-label">
            You may use this field to provide a brief summary of the
            circumstances leading to the action as well as the current status or
            final disposition. (Optional)
          </span>
        </div>
      </div>
      <div style={{ paddingTop: "20px" }}>
        <textarea
          id="bondComments"
          className="form-control comments-label"
          value={bankruptcyComments}
          rows="3"
          maxLength={500}
          onChange={handleChange}
        ></textarea>
      </div>
      <div className="row backbutton-drp-bankruptcy">
        <div className="col-md-12 buttons">
          <span
            id="bankruptcyAddDRPBack"
            className="back-button"
            onClick={() => {
              handleToggleBackModal(true);
            }}
          >
            <strong id="bankruptcyDisclBack">Back</strong>
          </span>
          <Button
            disabled={!isFormComplete}
            id="bankruptcyAddDRPNext"
            style={{ marginRight: "10px" }}
            onClick={handleNext}
          >
            Complete DRP
          </Button>
        </div>
      </div>
      <BackButtonModal
        isOpen={showBackModal}
        closeBackModal={() => {
          handleToggleBackModal(false);
        }}
        backClick={handleBack}
      />
    </div>
  );
};
export default BankruptcyDisclosureComments;
