import React from "react";
import AddressComponent from "../../SharedComponents/AddressVerificationForm";
import { FORM_ID } from "../../../constants/Forms";
import "../../../assets/stylesheets/StartApplication/IndividualBroker/BusinessInformation.scss";
import SectionTitle from "../../SharedComponents/SectionTitle";
import { CONTRACT_TYPE_DESCRIPTIONS } from "../../../constants/Contract";

export default function BusinessAddressForm(props) {
  let previousPage =
    props.dashboardInfo.contracttypedesc.includes("Career") ||
    props.dashboardInfo.contracttypedesc.includes("College") ||
    props.dashboardInfo.contracttypedesc.includes("MMLIS") ||
    props.dashboardInfo.contracttypedesc.includes("Alternate")
      ? FORM_ID.RESIDENTIAL_HISTORY
      : FORM_ID.RESIDENTIAL_ADDRESS;

  let nextPage =
    props.dashboardInfo.contracttypedesc.includes("Career") ||
    props.dashboardInfo.contracttypedesc.includes("College") ||
    props.dashboardInfo.contracttypedesc.includes("Alternate")
      ? FORM_ID.EDUCATION
      : FORM_ID.BROKER_DEALER;
  if (
    props.dashboardInfo.contracttypedesc === CONTRACT_TYPE_DESCRIPTIONS.MMLIS
  ) {
    nextPage = FORM_ID.EMPLOYMENT_HISTORY;
  }
  return (
    <div id="businessinformation">
      {(props.contracttypedesc === CONTRACT_TYPE_DESCRIPTIONS.BROKER_CORP ||
        props.contracttypedesc === CONTRACT_TYPE_DESCRIPTIONS.MEMBER_CORP) && (
        <div className="row">
          <div className="col-md-12">
            <div className="eyebrow">
              <strong>Member of Corporation</strong>
            </div>
          </div>
        </div>
      )}
      <SectionTitle
        titleID="bus_addr_title"
        titleName="Business Information"
      ></SectionTitle>
      <div id="business-info-instructions">
        <div className="row">
          <div className="col-12">
            <h3 id="bus_addr_provide_text">
              Provide your current business information.
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <span id="bus_addr_po_box_text" className="required-field-text">
              A P.O. Box or private mailbox number (PMB) will not be accepted
              for your business address.
            </span>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <AddressComponent
            id="business"
            backFunction={() => props.toggleForm(previousPage)}
            nextFunction={() => props.toggleForm(nextPage)}
            resAddress={props.resAddress}
            busAddress={props.busAddress}
            setBusAddrChecked={props.setBusAddrChecked}
            busAddrCheck={props.busAddrCheck}
            saveBusAddress={props.saveBusAddress}
            isFormComplete={props.isFormComplete}
            toggleFormComplete={props.toggleFormComplete}
            subId={props.subId}
            hasCurrentFormChanged={props.hasCurrentFormChanged}
            contracttypedesc={props.dashboardInfo.contracttypedesc}
            residentialHistory={props.residentialHistory}
            resAddressHasChanged={props.resAddressHasChanged}
            candidateid={props.dashboardInfo.candidateid}
            agencynumber={props.dashboardInfo.agencynumber}
            contracttypeid={props.dashboardInfo.contracttypeid}
          />
        </div>
      </div>
    </div>
  );
}
