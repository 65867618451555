import { FORM_ID } from "../../../../../constants/Forms";

function ReviewCCB(props) {
  const toggleForm = () => {
    props.toggleForm(FORM_ID.CCB);
  };
  return (
    <div id="rew-ccb">
      <div className="row">
        <div className="col-8" id="ccb-heading">
          <h4 id="charactercb">Character, Credit, and Bonding</h4>
        </div>
        <div className="col-4" id="et-btn">
          <span className="btn btn-link float-right" onClick={toggleForm}>
            Edit
          </span>
        </div>
      </div>

      <div className="row">
        <div className="col-6" id="ccb-ques">
          <h5>
            Are you now or have been in the past been directly or indirectly
            involved in a lawsuit since you have been in the business of
            insurance?
          </h5>
        </div>
        <div id="lawsuit" className="col-6 data">
          {props.characterCreditBonding.invlawsuit ? "Yes" : "No"}
        </div>
      </div>

      {props.characterCreditBonding.invlawsuit && (
        <div className="row">
          <div className="col-6" id="qstn-lwst">
            <h5>
              Please explain your involvement in a lawsuit relative to insurance
              practice.
            </h5>
          </div>
          <div id="lawsuit_text" className="col-6 data">
            <>{props.characterCreditBonding.invlawsuitdesc}</>
          </div>
        </div>
      )}

      <div className="row">
        <div className="col-6" id="questn-cnvtd">
          <h5>
            Have you ever been convicted of any crime (felony or misdemeanor)?
          </h5>
        </div>
        <div id="crime" className="col-6 data">
          {props.characterCreditBonding.convictcrime ? "Yes" : "No"}
        </div>
      </div>

      {props.characterCreditBonding.convictcrime && (
        <div className="row">
          <div className="col-6" id="crime">
            <h5>
              Please explain the crime (felony or misdemeanor) of which you have
              been convicted.
            </h5>
          </div>
          <div id="crime_text" className="col-6 data">
            {props.characterCreditBonding.convictcrimedesc}
          </div>
        </div>
      )}

      <div className="row">
        <div className="col-6" id="license">
          <h5 id="lcnse">
            Have you ever had a professional license suspended, revoked,
            cancelled, or had your employment or contract ‘terminated for
            cause’?
          </h5>
        </div>
        <div id="suspended" className="col-6 data">
          {props.characterCreditBonding.licsuspend ? "Yes" : "No"}
        </div>
      </div>

      {props.characterCreditBonding.licsuspend && (
        <div className="row">
          <div className="col-6" id="lcnse-suspended">
            <h5>
              Please explain why your professional license was suspended,
              revoked, cancelled, or why your employment or contract was
              ‘terminated for cause’.
            </h5>
          </div>
          <div id="suspended_text" className="col-6 data">
            {props.characterCreditBonding.licsuspenddesc}
          </div>
        </div>
      )}

      <div className="row">
        <div className="col-6" id="bankruptcy">
          <h5 id="bnkrpcy">
            Have you ever failed in business, declared bankruptcy, compromised
            with creditors, or had any liens or judgments against you?
          </h5>
        </div>
        <div id="bankruptcy" className="col-6 data">
          {props.characterCreditBonding.failedbusiness ? "Yes" : "No"}
        </div>
      </div>

      {props.characterCreditBonding.failedbusiness && (
        <div className="row">
          <div className="col-6" id="bsiness">
            <h5>
              Please explain if you have ever failed in business, declared
              bankruptcy, compromised with creditors, or had liens or judgments
              against you.
            </h5>
          </div>
          <div id="bankruptcy_text" className="col-6 data">
            {props.characterCreditBonding.failedbusinessdesc}
          </div>
        </div>
      )}
    </div>
  );
}
export default ReviewCCB;
