import { withLDProvider, useFlags } from "launchdarkly-react-client-sdk";
import OOSPage from "../LandingPages/OOSPage";
import StartApplication from "./StartApplication";

function StartApplicationMain(props) {
  //LAUNCH DARKLY FLAGS
  const { candidateGlobalOos, candidatePresentationMode } = useFlags();

  return (
    <>
      {candidateGlobalOos === true && <OOSPage />}
      {(candidateGlobalOos === false ||
        typeof candidateGlobalOos === "undefined") && (
        <StartApplication
          candidatePresentationMode={candidatePresentationMode}
        />
      )}
    </>
  );
}

export default withLDProvider({
  clientSideID: window.REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
})(StartApplicationMain);
