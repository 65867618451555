/* eslint-disable jsx-a11y/label-has-for */
import React, { forwardRef, useState } from "react";
import { Button } from "react-blueprint-library";
import { useDispatch } from "react-redux";
import { logoutActions } from "../../../../../../store/actions";

const CustomSubCategoryCard = forwardRef(
  (
    {
      data,
      category,
      handleOnEditClick,
      handleChangeChecked,
      handleOnSaveClick,
      section,
      isSaved,
      isRequired,
      isLoading,
      disabledSave,
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const [isEditHidden, setIsEditHidden] = useState(false);

    const onEditChange = () => {
      handleOnEditClick(section);
      setIsEditHidden((prev) => !prev);
    };

    const handleOnSave = () => {
      dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: false }));

      return !disabledSave && handleOnSaveClick(section);
    };

    return (
      <div className="sub-card-container" ref={ref}>
        <div className="save-edit-btn-wrapper">
          {isSaved[section] && !isEditHidden && disabledSave ? (
            <Button
              id="term-edit"
              variant="link"
              className="edit"
              onClick={onEditChange}
            >
              <strong id={`edit-${section}`}>Edit</strong>
            </Button>
          ) : null}
        </div>

        <>
          {category.map(
            (
              {
                header,
                subHeader,
                questionA,
                questionB = null,
                questionC = null,
                optionsA,
                optionsB,
                optionsC,
              },
              i
            ) => {
              const isAChecked =
                (optionsA.valueOne &&
                  data[optionsA.name] === optionsA.valueOne) ||
                (optionsA.valueTwo &&
                  data[optionsA.name] === optionsA.valueTwo);

              const isBChecked =
                questionB &&
                ((optionsB.valueOne &&
                  data[optionsB.name] === optionsB.valueOne) ||
                  (optionsB.valueTwo &&
                    data[optionsB.name] === optionsB.valueTwo));

              const id = `${i * 1}-${header?.length}`;

              return (
                <div className="category" key={`${i * 1}_category_${header}`}>
                  <div id={`header-${section}-${id}`} className="header">
                    {header}
                  </div>
                  <div
                    className={`sub-category ${
                      !header ? "without-header" : ""
                    }`}
                  >
                    <span id={`term-discl-subHeader-${section}-${id}`}>
                      {subHeader}
                    </span>
                    <div className="sub-category">
                      <span id={`term-discl-questionA-${section}-${id}`}>
                        {questionA}
                      </span>
                      <div className="options">
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            name={optionsA.name}
                            id={optionsA.idOne}
                            className={`custom-control-input ${
                              isRequired && !isAChecked ? "is-invalid" : ""
                            }`}
                            checked={data[optionsA.name] === optionsA?.valueOne}
                            value={optionsA?.valueOne}
                            onChange={handleChangeChecked}
                            disabled={optionsA.disabled}
                          />
                          <label
                            id={`term-discl-radio-${optionsA.idOne}-${optionsA.valueOne}`}
                            className="custom-control-label"
                            htmlFor={optionsA.idOne}
                          >
                            {optionsA.labelOne}
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            name={optionsA.name}
                            id={optionsA.idTwo}
                            className={`custom-control-input ${
                              isRequired && !isAChecked ? "is-invalid" : ""
                            }`}
                            checked={data[optionsA.name] === optionsA.valueTwo}
                            value={optionsA.valueTwo}
                            onChange={handleChangeChecked}
                            disabled={optionsA.disabled}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={optionsA.idTwo}
                            id={`term-discl-radio-${optionsA.idTwo}-${optionsA.valueTwo}`}
                          >
                            {optionsA.labelTwo}
                          </label>
                        </div>
                      </div>

                      {questionB && (
                        <>
                          <span id={`term-discl-questionB-${section}-${id}`}>
                            {questionB}
                          </span>
                          <div className="options">
                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                name={optionsB.name}
                                id={optionsB.idOne}
                                className={`custom-control-input ${
                                  isRequired && !isBChecked ? "is-invalid" : ""
                                }`}
                                checked={
                                  data[optionsB.name] === optionsB?.valueOne
                                }
                                value={optionsB?.valueOne}
                                onChange={handleChangeChecked}
                                disabled={optionsB.disabled}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={optionsB.idOne}
                                id={`term-discl-radio-${optionsB.idOne}-${optionsB.valueOne}`}
                              >
                                {optionsB.labelOne}
                              </label>
                            </div>

                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                name={optionsB.name}
                                id={optionsB.idTwo}
                                className={`custom-control-input ${
                                  isRequired && !isBChecked ? "is-invalid" : ""
                                }`}
                                checked={
                                  data[optionsB.name] === optionsB.valueTwo
                                }
                                value={optionsB.valueTwo}
                                onChange={handleChangeChecked}
                                disabled={optionsB.disabled}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={optionsB.idTwo}
                                id={`term-discl-radio-${optionsB.idTwo}-${optionsB.valueTwo}`}
                              >
                                {optionsB.labelTwo}
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {questionC && (
                        <>
                          <span id={`term-discl-questionC-${section}-${id}`}>
                            {questionC}
                          </span>
                          <div className="options">
                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                name={optionsC.name}
                                id={optionsC.idOne}
                                className={`custom-control-input ${
                                  isRequired && !isBChecked ? "is-invalid" : ""
                                }`}
                                checked={
                                  data[optionsC.name] === optionsC?.valueOne
                                }
                                value={optionsC?.valueOne}
                                onChange={handleChangeChecked}
                                disabled={optionsC.disabled}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={optionsC.idOne}
                                id={`term-discl-radio-${optionsC.idOne}-${optionsC.valueOne}`}
                              >
                                {optionsC.labelOne}
                              </label>
                            </div>

                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                name={optionsC.name}
                                id={optionsC.idTwo}
                                className={`custom-control-input ${
                                  isRequired && !isBChecked ? "is-invalid" : ""
                                }`}
                                checked={
                                  data[optionsC.name] === optionsC.valueTwo
                                }
                                value={optionsC.valueTwo}
                                onChange={handleChangeChecked}
                                disabled={optionsC.disabled}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={optionsC.idTwo}
                                id={`term-discl-radio-${optionsC.idTwo}-${optionsC.valueTwo}`}
                              >
                                {optionsC.labelTwo}
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {isRequired && !(isBChecked && isAChecked) ? (
                        <div id="is_required_text_error" className="isRequired">
                          Please choose an answer
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              );
            }
          )}
        </>

        <div className="save-edit-btn-wrapper">
          {isEditHidden || !isSaved[section] || !disabledSave ? (
            <Button
              variant="link"
              id={`term-save-button-${section}`}
              className={`save ${disabledSave ? "disabled" : ""}`}
              disabled={disabledSave}
              onClick={handleOnSave}
            >
              <strong id={`save-continue-${section}`}>Save And Continue</strong>

              {isLoading && <span className="mm-dots-loading save"></span>}
            </Button>
          ) : null}
        </div>
      </div>
    );
  }
);

export default CustomSubCategoryCard;
