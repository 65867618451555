export const initialState = {
  tenyearscompromise: null,
  tenyearsorgcompromise: null,
  tenyearsbankruptcyboolean: null,
  deniedbonding: null,
  unsatisfiedjudgements: null,
};

const persBankruptcy = {
  mainQuestion: "Within the past 10 years:",
  questionA: `1. have you made a compromise with creditors, filed a bankruptcy petition or been the subject of an involuntary bankruptcy petition?`,
};

export const DRPSummaryPersonalBankruptcy = {
  tenyearscompromise: {
    key: "14K(1)",
    mainQuestion: persBankruptcy.mainQuestion,
    question: persBankruptcy.questionA,
  },
};

export const financialDisclosurePersBankruptcy = (disabled) => [
  {
    header: "",
    subHeader: `14K. ${persBankruptcy.mainQuestion}`,
    questionA: persBankruptcy.questionA,
    optionsA: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "tenyearscompromise-y",
      idTwo: "tenyearscompromise-n",
      name: "tenyearscompromise",
      disabled: disabled,
    },
  },
];

const orgBankruptcy = {
  mainQuestion: "In the past 10 years:",
  questionA: `2. based upon events that occurred while you exercised control over it, has an organization made a compromise with creditors, filed a bankruptcy petition or been the subject of an involuntary bankruptcy petition?`,
  questionB: `3. based upon events that occurred while you exercised control over it, has a broker or dealer been the subject of an involuntary bankruptcy petition, or had a trustee appointed, or had a direct payment procedure initiated under the Securities Investor Protection Act?`,
};

export const DRPSummaryOrganizationBankruptcy = {
  tenyearsorgcompromise: {
    key: "14K(2)",
    mainQuestion: orgBankruptcy.mainQuestion,
    question: orgBankruptcy.questionA,
  },
  tenyearsbankruptcyboolean: {
    key: "14K(3)",
    mainQuestion: orgBankruptcy.mainQuestion,
    question: orgBankruptcy.questionB,
  },
};

export const financialDisclosureOrgBankruptcy = (disabled) => [
  {
    header: "",
    subHeader: `14K. ${orgBankruptcy.mainQuestion}`,
    questionA: orgBankruptcy.questionA,
    questionB: orgBankruptcy.questionB,
    optionsA: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "tenyearsorgcompromise-y",
      idTwo: "tenyearsorgcompromise-n",
      name: "tenyearsorgcompromise",
      disabled: disabled,
    },
    optionsB: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "tenyearsbankruptcyboolean-y",
      idTwo: "tenyearsbankruptcyboolean-n",
      name: "tenyearsbankruptcyboolean",
      disabled: disabled,
    },
  },
];

const bondDisclosures = {
  mainQuestion:
    "Has a bonding company ever denied, paid out on, or revoked a bond for you?",
};

export const DRPSummaryBondDisclosures = {
  deniedbonding: {
    key: "14L",
    mainQuestion: bondDisclosures.mainQuestion,
  },
};

export const financialDisclosureBondDisclosures = (disabled) => [
  {
    header: "",
    subHeader: `14L. ${bondDisclosures.mainQuestion}`,
    questionA: "",
    optionsA: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "deniedbonding-y",
      idTwo: "deniedbonding-n",
      name: "deniedbonding",
      disabled: disabled,
    },
  },
];

const judgLienDiscl = {
  mainQuestion: "Do you have any unsatisfied judgments or liens against you?",
};

export const DRPSummaryJudgementLienDisclosures = {
  unsatisfiedjudgements: {
    key: "14M",
    mainQuestion: judgLienDiscl.mainQuestion,
  },
};

export const financialDisclosureJudgementLienDisclosures = (disabled) => [
  {
    header: "",
    subHeader: `14M. ${judgLienDiscl.mainQuestion}`,
    questionA: "",
    optionsA: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "unsatisfiedjudgements-y",
      idTwo: "unsatisfiedjudgements-n",
      name: "unsatisfiedjudgements",
      disabled: disabled,
    },
  },
];
