import { KEYS } from "../../constants";
import {
  getDRPSummaryQuestions,
  getDRPSummaryUncheckedQuestionKeys,
  getSpecificObjByKeys,
} from "../../helpers";
import {
  DRPSummaryBondDisclosures,
  DRPSummaryJudgementLienDisclosures,
  DRPSummaryOrganizationBankruptcy,
  DRPSummaryPersonalBankruptcy,
} from "../state";

export const isDrpSummaryPage = (data) => {
  const personalBankruptcyObj = getSpecificObjByKeys(
    data,
    KEYS.personalBankruptcy
  );
  const organizationBankruptcyObj = getSpecificObjByKeys(
    data,
    KEYS.organizationBankruptcy
  );
  const bondDisclosuresObj = getSpecificObjByKeys(data, KEYS.bondDisclosures);
  const judgementLienDisclosuresObj = getSpecificObjByKeys(
    data,
    KEYS.judgementLienDisclosures
  );

  const persBankruptcy = getDRPSummaryQuestions(
    DRPSummaryPersonalBankruptcy,
    personalBankruptcyObj
  );
  const orgBankruptcy = getDRPSummaryQuestions(
    DRPSummaryOrganizationBankruptcy,
    organizationBankruptcyObj
  );
  const bondDiscl = getDRPSummaryQuestions(
    DRPSummaryBondDisclosures,
    bondDisclosuresObj
  );
  const judgLienDisclosures = getDRPSummaryQuestions(
    DRPSummaryJudgementLienDisclosures,
    judgementLienDisclosuresObj
  );

  let unchecked = [
    ...getDRPSummaryUncheckedQuestionKeys(
      DRPSummaryPersonalBankruptcy,
      personalBankruptcyObj
    ),
    ...getDRPSummaryUncheckedQuestionKeys(
      DRPSummaryOrganizationBankruptcy,
      organizationBankruptcyObj
    ),
    ...getDRPSummaryUncheckedQuestionKeys(
      DRPSummaryBondDisclosures,
      bondDisclosuresObj
    ),
    ...getDRPSummaryUncheckedQuestionKeys(
      DRPSummaryJudgementLienDisclosures,
      judgementLienDisclosuresObj
    ),
  ];

  return {
    persBankruptcy,
    orgBankruptcy,
    bondDiscl,
    judgLienDisclosures,
    unchecked,
  };
};
