/* eslint-disable jsx-a11y/label-has-for */
import React, { useEffect, useState } from "react";
import { Button } from "react-blueprint-library";
import { useDispatch } from "react-redux";
import BackButtonModal from "../../../../../../Forms/BackButtonModal";
import { logoutActions } from "../../../../../../../../store/actions";
import "./CommentsForm.scss";

const JudgmentLienAddDRPCommentsForm = ({
  updateCCBForm,
  nextFormId,
  backFormId,
  updateTempDRP,
  tempJudgmentLienDRP = {},
  updateJudgmentDisclosureDRPs,
}) => {
  const dispatch = useDispatch();
  const [showBackModal, setShowBackModal] = useState(false);
  const [comments, setComments] = useState(tempJudgmentLienDRP?.comments || "");

  const onCommentsChangeHandler = ({ target: { value } }) => {
    setComments(value);
  };

  const handleCloseBackModal = () => setShowBackModal(false);

  const isUpdated = comments?.length;

  useEffect(() => {
    if (isUpdated) {
      dispatch(
        logoutActions.setShowLogoutModal({ showLogoutModal: isUpdated })
      );
    }
  }, [isUpdated, dispatch]);

  const handleShowBackModal = () => {
    if (isUpdated) {
      setShowBackModal(true);
    } else {
      updateCCBForm(backFormId);
    }
  };

  const handleBackClick = () => {
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: false }));
    updateCCBForm(backFormId);
    handleCloseBackModal();
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  const handleNext = () => {
    const submitData = {
      ...tempJudgmentLienDRP,
      comments,
    };
    updateJudgmentDisclosureDRPs(submitData);
    updateCCBForm(nextFormId);
  };

  return (
    <div className="judg-lien-disclosure-comments-form-wrapper">
      <div className="judg-lien-comments-container">
        <label
          htmlFor="judgLienDisclCommentsId"
          className="comments-label"
          id="judgLienDisclCommentsLabelId"
        >
          You may use this field to provide a brief summary of the circumstances
          leading to the action as well as the current status or final
          disposition. (Optional)
        </label>
        <textarea
          id="judgLienDisclCommentsId"
          onChange={onCommentsChangeHandler}
          className={"form-control"}
          minLength={0}
          maxLength={500}
          rows="3"
          cols={500}
          value={comments}
        />
      </div>

      <div className="backbutton-drp-judg-lien row">
        <div className="col-md-12 buttons">
          <span role="presentation" onClick={handleShowBackModal}>
            <strong id="judgLienDisclBack">Back</strong>
          </span>
          <Button label="Complete DRP" onClick={handleNext} disabled={false} />
          {false && <span className="mm-dots-loading"></span>} {/* loading */}
        </div>
      </div>

      <BackButtonModal
        isOpen={showBackModal}
        closeBackModal={handleCloseBackModal}
        backClick={handleBackClick}
      />
    </div>
  );
};

export default JudgmentLienAddDRPCommentsForm;
