import React from "react";

export default (
  <svg width="16" height="16" fill="none">
    <g fill="#004DB2" clipPath="url(#a)">
      <path d="M8 4c.368 0 .666.298.666.667v2.666h2.667a.667.667 0 0 1 0 1.333H8.666v2.667a.667.667 0 0 1-1.333 0V8.666H4.666a.667.667 0 1 1 0-1.333h2.667V4.666c0-.368.298-.666.666-.666Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 15.333A7.333 7.333 0 1 0 8 .666a7.333 7.333 0 0 0 0 14.667ZM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
