/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/jsx-no-comment-textnodes */
import React from "react";

import "./CheckboxList.scss";
import {
  DRPCheckboxListValuesFullName,
  DRPCheckboxListValuesProd,
} from "../../constants";

const CheckboxList = ({
  selectedItems,
  list,
  header,
  onChangeChecked,
  onOtherChangeHandler,
  onBlurOtherHandler,
  otherTextError,
  otherText,
  isScroll = true,
}) => {
  return (
    <div className="drp-generic-checkbox-list-container">
      <label
        className={`checkbox-label ${isScroll ? "is-scroll" : ""}`}
        htmlFor=""
        id={`checkbox-label-header-id-${header.substring(0, 3).toLowerCase()}`}
      >
        {header}
      </label>

      <div className={`checkbox-list-card ${isScroll ? "is-scroll" : ""}`}>
        {list.map(({ id, value }, index) => {
          const parsedKey = `${id}_${index * 1}`;
          const checked = !!selectedItems.includes(value);

          return (
            <div key={parsedKey} className="value-container">
              <div className="card-checkbox">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    onChange={onChangeChecked}
                    id={`drpCheckbox-${parsedKey}`}
                    value={value}
                    checked={checked}
                    aria-checked={checked}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={`drpCheckbox-${parsedKey}`}
                    id={`drpCheckbox-${parsedKey}-id`}
                  />
                </div>
              </div>
              <label
                className={`label-strong ${isScroll ? "is-scroll" : ""}`}
                htmlFor={`drpCheckbox-${parsedKey}`}
                id={`drpCheckbox-${parsedKey}-label-id`}
              >
                {isScroll ? <strong>{value}</strong> : value}
              </label>
              {[
                DRPCheckboxListValuesFullName.OTHER_FN,
                DRPCheckboxListValuesProd.OTHER,
              ].includes(value) && checked ? (
                <div className="other-input-wrapper">
                  <div className="col-md-12">
                    <input
                      id="othertextbox"
                      style={{ width: "100%" }}
                      type="text"
                      className={
                        otherTextError
                          ? "form-control form-control-15 is-invalid"
                          : "form-control form-control-15"
                      }
                      maxLength={500}
                      minLength={2}
                      value={otherText}
                      onChange={onOtherChangeHandler}
                      onBlur={onBlurOtherHandler}
                      autoComplete="off"
                    />
                    <div id="otherTextError" className="invalid-feedback">
                      Please enter a response
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CheckboxList;
