import React from "react";
import "./DRPCard.scss";
import svgs, { svgType } from "../../../../../../assets/svgs";
import { Button } from "react-blueprint-library";

const DRPCard = ({ header, onClick, children, isDrp }) => {
  const drpId = header.replace(/\s/g, "");

  return (
    <div className={`drp-main-card ${isDrp ? "is-drp" : ""}`}>
      <div className="header-wrapper">
        <div
          key={`drp-header-${drpId}`}
          id={`drp-header-${drpId}`}
          className="header"
        >
          {header}
        </div>

        <div className="add-drp-btn-wrapper">
          <Button
            id="add-drp"
            variant="secondary"
            className="add-drp-btn"
            onClick={onClick}
          >
            <div className="btn-content">
              <span className="indicator">{svgs[svgType.iconAddDRP]}</span>
              <strong id={`add-drp-${drpId}`}>Add A DRP</strong>
            </div>
          </Button>
        </div>
      </div>
      <div className="drp-container">{children}</div>
    </div>
  );
};

export default DRPCard;
