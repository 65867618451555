import React from "react";

export default (
  <svg width="25" height="25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g fill="#327320">
      <path d="M18.503 8.902a1 1 0 0 1-.016 1.414l-6.59 6.447a1 1 0 0 1-1.368.03l-3.721-3.34a1 1 0 1 1 1.335-1.489l3.024 2.714 5.921-5.792a1 1 0 0 1 1.415.016Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.278 20.502c4.296-4.296 4.296-11.26 0-15.556C15.982.65 9.018.65 4.722 4.946c-4.296 4.296-4.296 11.26 0 15.556 4.296 4.296 11.26 4.296 15.556 0Zm-1.414-1.414A9 9 0 1 0 6.136 6.36a9 9 0 0 0 12.728 12.728Z"
      />
    </g>
  </svg>
);
