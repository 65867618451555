import React, { Component } from "react";
import { Button, FormCustomControl } from "react-blueprint-library";
import BackButtonModal from "../Forms/BackButtonModal";
import { putErrorsAndOmissions } from "../../../api/makeAPIRequest";
import { FORM_ID } from "../../../constants/Forms";
import { UtagTracking } from "../../../utils/utag-tracking";
import { CONTRACT_TYPE_DESCRIPTIONS } from "../../../constants/Contract";

class ErrorsAndOmissions extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState();
    this.onValueChange = this.onValueChange.bind(this);
    this.handleChecked = this.handleChecked.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleShowBackModal = this.handleShowBackModal.bind(this);
    this.handleCloseBackModal = this.handleCloseBackModal.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
  }

  async componentDidMount() {
    try {
      const result = this.props.eocData;

      await this.setState({
        checked: result.eoacknowledge === null ? false : result.eoacknowledge,
        overmillion:
          result.eoindgreatermil === null ? "" : result.eoindgreatermil,
        lessmillion: result.eoindlessmil === null ? "" : result.eoindlessmil,
        grouppolicy: result.eogroup === null ? "" : result.eogroup,
        candidate_id: result.candidateid,
        is_form_complete: true,
      });

      UtagTracking.sendPageNameView(
        "errorsandomissionsform",
        this.props.candidateId,
        this.props.agencynumber,
        this.props.contracttypeid
      );

      this.props.isFormComplete(
        "EOCompleteInd",
        this.state.lessmillion === true ||
          this.state.overmillion === true ||
          this.state.grouppolicy === true
      );
    } catch (err) {
      console.log(err);
    }
  }

  initialState() {
    return {
      checked: false,
      overmillion: false,
      lessmillion: false,
      grouppolicy: false,
      candidate_id: "",
      showBackModal: false,
      formHasChanged: false,
      is_form_complete: false,
    };
  }

  async handleChecked() {
    let currentCheck = this.state.checked;
    await this.setState({
      checked: !currentCheck,
      formHasChanged: true,
    });
    this.props.hasCurrentFormChanged(true);

    if (this.state.checked === false) {
      this.props.isFormComplete("EOCompleteInd", false);
    } else {
      if (
        this.state.lessmillion === true ||
        this.state.overmillion === true ||
        this.state.grouppolicy === true
      ) {
        // this.props.isFormComplete("EOCompleteInd", true);
      }
      var obj_error = document.getElementById("acknowledgement_error");
      obj_error.style.display = "none";
      var check_yes = document.getElementById("acknowledgement");
      check_yes.classList.remove("is-invalid");
    }
  }

  async onValueChange(event) {
    this.props.isFormComplete("EOCompleteInd", false);
    this.props.hasCurrentFormChanged(true);

    await this.setState({
      overmillion: false,
      lessmillion: false,
      grouppolicy: false,
      is_form_complete: false,
    });

    if (event.target.id === "greater") {
      await this.setState({
        overmillion: true,
      });
    } else if (event.target.id === "lessthan") {
      await this.setState({
        lessmillion: true,
      });
    } else if (event.target.id === "group") {
      await this.setState({
        grouppolicy: true,
      });
    }

    var obj_error = document.getElementById("radio_error");
    obj_error.style.display = "none";
    var greater = document.getElementById("greater");
    greater.classList.remove("is-invalid");
    var lessthan = document.getElementById("lessthan");
    lessthan.classList.remove("is-invalid");
    var group = document.getElementById("group");
    group.classList.remove("is-invalid");

    this.setState({ formHasChanged: true });
  }

  handleNext() {
    var allFilled = true;
    if (this.state.checked === false) {
      allFilled = false;
      var obj_error = document.getElementById("acknowledgement_error");
      obj_error.style.display = "block";
      var ack = document.getElementById("acknowledgement");
      ack.classList.add("is-invalid");
      var acklabel = document.getElementById("acknowledgement").labels[0];
      acklabel.style.color = "#555";
    }
    if (
      this.state.lessmillion !== true &&
      this.state.overmillion !== true &&
      this.state.grouppolicy !== true
    ) {
      var obj_error2 = document.getElementById("radio_error");
      obj_error2.style.display = "block";
      var greater = document.getElementById("greater");
      greater.classList.add("is-invalid");
      var label_greater = document.getElementById("label_greater");
      label_greater.style.color = "#555";
      var lessthan = document.getElementById("lessthan");
      lessthan.classList.add("is-invalid");
      var label_lessthan = document.getElementById("label_lessthan");
      label_lessthan.style.color = "#555";
      var group = document.getElementById("group");
      group.classList.add("is-invalid");
      var label_group = document.getElementById("label_group");
      label_group.style.color = "#555";
    }

    if (
      this.state.checked === false ||
      (this.state.lessmillion !== true &&
        this.state.overmillion !== true &&
        this.state.grouppolicy !== true)
    ) {
      allFilled = false;
    }

    if (allFilled) {
      this.props.saveErrorsOmissions("errorsAndOmissions", {
        ...this.props.eocData,
        eoacknowledge: this.state.checked,
        eogroup: this.state.grouppolicy,
        eoindgreatermil: this.state.overmillion,
        eoindlessmil: this.state.lessmillion,
      });
      let data = {
        candidateid: this.state.candidate_id,
        overmillion: this.state.overmillion,
        undermillion: this.state.lessmillion,
        grouppolicy: this.state.grouppolicy,
        acknowledge: this.state.checked,
      };
      putErrorsAndOmissions(this.props.subId, data);
      this.setState({ is_form_complete: true });
      this.setState({ formHasChanged: false });
      this.props.hasCurrentFormChanged(false);

      if (
        this.props.contractType === CONTRACT_TYPE_DESCRIPTIONS.BROKER_CORP ||
        this.props.contractType === CONTRACT_TYPE_DESCRIPTIONS.MEMBER_CORP
      ) {
        this.props.toggleForm(FORM_ID.REVIEW_SIGN_1);
      } else {
        this.props.toggleForm(FORM_ID.DIRECT_DEPOSIT);
      }
    }
    this.props.isFormComplete("EOCompleteInd", allFilled);
  }

  handleCloseBackModal() {
    this.setState({ showBackModal: false });
  }

  handleBackClick() {
    const { eoacknowledge, eogroup, eoindgreatermil, eoindlessmil } =
      this.props.eocData;
    this.props.isFormComplete(
      "EOCompleteInd",
      eoacknowledge === true &&
        (eogroup === true || eoindgreatermil === true || eoindlessmil === true)
    );
    this.setState({ formHasChanged: false });
    this.handleCloseBackModal();
    this.props.toggleForm(FORM_ID.CCB);
  }

  handleShowBackModal() {
    //show back modal if anything on the form has changed
    if (this.state.formHasChanged) {
      this.setState({ showBackModal: true });
    } else {
      this.props.toggleForm(FORM_ID.CCB);
    }
  }

  render() {
    return (
      <>
        {(this.props.contractType === CONTRACT_TYPE_DESCRIPTIONS.BROKER_CORP ||
          this.props.contractType ===
            CONTRACT_TYPE_DESCRIPTIONS.MEMBER_CORP) && (
          <div className="row">
            <div className="col-md-12">
              <div className="eyebrow">
                <strong>Member of Corporation</strong>
              </div>
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-md-12">
            <h1 id="eo_header">
              Errors and Omissions (E&O) Coverage, Group Insurance
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <span id="ccb_header" className="required-field-text">
              * = Required Field
            </span>
          </div>
        </div>
        <div className="row">
          <div
            id="eao_question"
            className="col-md-12"
            style={{ paddingTop: "15px", marginBottom: "15px" }}
          >
            <h3>Please select one of the following*</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-12 copy-md-8 form-group">
            <div className="custom-control custom-radio">
              <input
                name="radio-guide"
                type="radio"
                id="greater"
                className="custom-control-input"
                value="I currently have an individual E&O coverage policy that is equal
                to or greater than $1 million"
                checked={this.state.overmillion}
                onChange={this.onValueChange}
              />
              <label
                htmlFor="greater"
                className="custom-control-label"
                id="label_greater"
              >
                I currently have an individual E&O coverage policy that is equal
                to or greater than $1 million
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                name="radio-guide"
                type="radio"
                id="lessthan"
                className="custom-control-input"
                value="I currently have an individual E&O coverage policy that is less
                than $1 million"
                checked={this.state.lessmillion}
                onChange={this.onValueChange}
              />
              <label
                htmlFor="lessthan"
                className="custom-control-label"
                id="label_lessthan"
              >
                I currently have an individual E&O coverage policy that is less
                than $1 million
              </label>
            </div>
            <div>
              <div className="custom-control custom-radio">
                <input
                  name="radio-guide"
                  type="radio"
                  id="group"
                  className="custom-control-input"
                  value="I am currently covered under a Group E&O policy"
                  checked={this.state.grouppolicy}
                  onChange={this.onValueChange}
                />
                <label
                  htmlFor="group"
                  className="custom-control-label"
                  id="label_group"
                >
                  I am currently covered under a Group E&O policy
                </label>
              </div>
            </div>
            <div id="radio_error" className="invalid-feedback">
              Please choose a response.
            </div>
          </div>
        </div>

        <div className="row">
          <div
            id="eo_acknowledgement_heading"
            className="col-md-12"
            style={{ paddingTop: "5px", marginBottom: "15px" }}
          >
            <h3>Acknowledgement*</h3>
          </div>
        </div>

        <div className="row">
          <div style={{ paddingLeft: "1.6rem" }}>
            <FormCustomControl
              id="acknowledgement"
              inputType="checkbox"
              label=" I understand that I must acquire and maintain a Life Agents Errors & Omissions Policy with a minimum coverage of $1,000,000 per claim/$1,000,000 per aggregate to obtain and maintain a contract. I agree that if I do not currently have coverage at these levels, I will increase my coverage to these levels at the next renewal of my policy and maintain these levels at each subsequent renewal. I agree to submit a copy of my Errors & Omissions policy to the General Agent executing the contract."
              onChange={this.handleChecked}
              checked={this.state.checked}
            />
            <div id="acknowledgement_error" className="invalid-feedback">
              Please check the box to confirm your acknowledgement.
            </div>
          </div>
        </div>

        <div className="row">
          <div
            className="col-md-12"
            style={{ textAlign: "right", paddingTop: "30px" }}
          >
            <span
              id="eao_back"
              onClick={() => {
                this.handleShowBackModal();
              }}
              style={{
                marginRight: "25px",
                color: "#3174af",
                cursor: "pointer",
              }}
            >
              <strong> Back </strong>
            </span>
            <Button
              id="eao_next"
              style={{ marginRight: "10px" }}
              onClick={this.handleNext}
            >
              Next
            </Button>
          </div>
        </div>

        <BackButtonModal
          isOpen={this.state.showBackModal}
          closeBackModal={this.handleCloseBackModal}
          backClick={this.handleBackClick}
        />
      </>
    );
  }
}

export default ErrorsAndOmissions;
