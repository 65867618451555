/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
import InputMask from "react-input-mask";
import React, { useEffect, useState } from "react";
import { Button } from "react-blueprint-library";
import BackButtonModal from "../../../../../../Forms/BackButtonModal";
import "../../BankruptcyDisclosure.scss";
import { CCB_FORM_ID } from "../../../constants";
import moment from "moment";
import {
  formatMoney,
  onlyNumeric,
  pasteCommaDelimited,
} from "../../../../../../../../common/Functions";
import "../../../BondDisclosureForm/AddDRPForms/BondDisclosureAddDRPDispositionDetailsForm/BondDisclosureDispositionDetailsForm.scss";
import { useDispatch } from "react-redux";
import { logoutActions } from "../../../../../../../../store/actions";

const currentlyOptions = {
  YES: "Yes",
  NO: "No",
};

const dateOptions = {
  EXACT: "Exact date",
  EXPLANATION: "Explanation",
};

const BankruptcyDisclosureSIPADetails = ({
  updateCCBForm,
  nextFormId,
  backFormId,
  updateTempBankruptcyDRP,
  tempBankruptcyDRP,
  hasCompromise,
}) => {
  const dispatch = useDispatch();
  const [sipaTrusteeName, setSipaTrusteeName] = useState("");
  const [sipaTrusteeNameError, setSipaTrusteeNameError] = useState(false);
  const [sipaAmountPaid, setSipaAmountPaid] = useState("");
  const [sipaAmountPaidError, setSipaAmountPaidError] = useState(false);
  const [sipaAmountPaidErrorText, setSipaAmountPaidErrorText] = useState(false);
  const [sipaCurrentlyOpen, setSipaCurrentlyOpen] = useState("");
  const [sipaAppointedDate, setSipaAppointedDate] = useState("");
  const [sipaAppointedDateError, setSipaAppointedDateError] = useState(false);
  const [sipaAppointedDateErrorText, setSipaAppointedDateErrorText] =
    useState("");
  const [sipaDateOption, setSipaDateOption] = useState("");
  const [sipaAppointedDateExplanation, setSipaAppointedDateExplanation] =
    useState("");
  const [
    sipaAppointedDateExplanationError,
    setSipaAppointedDateExplanationError,
  ] = useState(false);
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [showBackModal, toggleShowBackModal] = useState(false);
  const [currentFormChanged, hasCurrentFormChanged] = useState(false);

  const handleToggleBackModal = (value) => {
    if (currentFormChanged) {
      toggleShowBackModal(value);
    } else {
      handleBack();
    }
  };
  const handleNext = () => {
    const tmpDRP = {
      ...tempBankruptcyDRP,
      sipaTrusteeName: sipaTrusteeName.trimStart().trimEnd(),
      sipaAmountPaid: sipaAmountPaid,
      sipaCurrentlyOpen: sipaCurrentlyOpen,
      sipaAppointedDate: sipaAppointedDate,
      sipaDateOption: sipaDateOption,
      sipaAppointedDateExplanation: sipaAppointedDateExplanation,
    };
    updateTempBankruptcyDRP(tmpDRP);
    updateCCBForm(nextFormId);
    hasCurrentFormChanged(false);
  };
  const handleBack = () => {
    const tmpDRP = {
      ...tempBankruptcyDRP,
      sipaTrusteeName,
      sipaAmountPaid,
      sipaCurrentlyOpen,
      sipaAppointedDate,
      sipaDateOption,
      sipaAppointedDateExplanation,
    };
    if (hasCompromise(tmpDRP)) {
      updateCCBForm(CCB_FORM_ID.ADD_BANKRUPTCY_DRP_PAGE_4);
    } else {
      updateCCBForm(CCB_FORM_ID.ADD_BANKRUPTCY_DRP_PAGE_3);
    }
  };
  const handleChange = ({ target: { id, value } }) => {
    hasCurrentFormChanged(true);
    switch (id) {
      case "sipaAmountPaid":
        setSipaAmountPaid(formatMoney(value));
        break;
      case "sipaTrusteeName":
        setSipaTrusteeName(value);
        break;

      case "sipaAppointedDate":
        setSipaAppointedDate(value);
        break;
      case "sipaAppointedDateExplanation":
        setSipaAppointedDateExplanation(value);
        break;
      default:
        break;
    }
  };
  const handleCurrentlyOpenChange = ({ target: { value } }) => {
    setSipaCurrentlyOpen(value);
    hasCurrentFormChanged(true);
  };
  const handleSipaDateOptionChange = ({ target: { value } }) => {
    setSipaDateOption(value);
    if (value === dateOptions.EXACT) {
      setSipaAppointedDateExplanation("");
      setSipaAppointedDateExplanationError(false);
    }
    hasCurrentFormChanged(true);
  };

  const validationDate = (date, trueDate) => {
    return String(date) === String(trueDate);
  };

  const isInvalidDate = (value) => {
    const todaysDate = moment();
    const inputValue = moment(value);
    const dateValidation = moment(value).format("MM/DD/YYYY");

    return (
      !inputValue.isValid() ||
      value.includes("_") ||
      value === "" ||
      value.slice(-4) === "0000" ||
      !validationDate(value, dateValidation) ||
      inputValue.isAfter(todaysDate) ||
      todaysDate.diff(inputValue, "year") >= 100 ||
      value.slice(-4, -1) === "000"
    );
  };

  const onBlurDateInput = ({ target: { value } }) => {
    const todaysDate = moment();
    const inputValue = moment(value);
    const dateValidation = moment(value).format("MM/DD/YYYY");

    let hasError = false;
    let errorText = "";
    if (
      !inputValue.isValid() ||
      value.includes("_") ||
      value === "" ||
      value.slice(-4) === "0000" ||
      !validationDate(value, dateValidation)
    ) {
      errorText = "Please enter a valid date";
      hasError = true;
    } else if (inputValue.isAfter(todaysDate)) {
      errorText = "Date cannot be a future date";
      hasError = true;
    } else if (
      todaysDate.diff(inputValue, "year") >= 100 ||
      value.slice(-4, -1) === "000"
    ) {
      errorText = "Date cannot be more than 100 years ago";
      hasError = true;
    }

    setSipaAppointedDateError(hasError);
    setSipaAppointedDateErrorText(errorText);
  };

  const onBlur = ({ target: { id, value } }) => {
    switch (id) {
      case "sipaAmountPaid":
        const isMoneyError =
          parseInt(value?.replaceAll(",", "")) < 0 ||
          parseInt(value?.replaceAll(",", "")) > 100000000 ||
          value?.length < 1;
        const isMoneyValid =
          parseInt(value?.replaceAll(",", "")) < 0 ||
          parseInt(value?.replaceAll(",", "")) > 100000000;

        const moneyErrMessage =
          parseInt(value?.replaceAll(",", "")) > 100000000 ||
          parseInt(value?.replaceAll(",", "")) < 0
            ? "Please enter a valid number"
            : value?.length < 1
            ? "Please enter Amount paid or agreed"
            : "";
        //If no name, set error if invalid or empty
        if (sipaTrusteeName.length === 0) {
          setSipaAmountPaidError(isMoneyError);
          setSipaAmountPaidErrorText(moneyErrMessage);
          if (isMoneyError === false) {
            setSipaTrusteeNameError(false);
          }
        }
        //If valid name, only set error if invalid
        else if (sipaTrusteeName.trim().length > 1) {
          setSipaAmountPaidError(isMoneyValid);
          setSipaAmountPaidErrorText(moneyErrMessage);
        }
        //If name error but entered amount, only fix if valid/empty name
        else if (sipaTrusteeNameError && !isMoneyError) {
          if (
            sipaTrusteeName.trim().length === 1 ||
            sipaTrusteeName.trim().length < 2
          ) {
            setSipaTrusteeNameError(true);
            setSipaAmountPaidError(false);
            setSipaAmountPaidErrorText(moneyErrMessage);
          } else if (
            sipaTrusteeName.trim().length > 1 ||
            sipaTrusteeName.length === 0
          ) {
            setSipaTrusteeNameError(false);
          }
        } else if (sipaTrusteeNameError) {
          setSipaAmountPaidError(isMoneyError);
          setSipaAmountPaidErrorText(moneyErrMessage);
        }

        //If amount error, only fix if valid amount, or if empty with valid name
        else if (sipaAmountPaidError) {
          if (sipaTrusteeName.trim().length > 1) {
            if (value.length === 0) {
              setSipaAmountPaidError(false);
            } else {
              setSipaAmountPaidError(isMoneyError);
              setSipaAmountPaidErrorText(moneyErrMessage);
            }
          } else {
            setSipaAmountPaidError(isMoneyError);
            setSipaAmountPaidErrorText(moneyErrMessage);
          }
        }
        if (parseInt(value?.replaceAll(",", "")) > 100000000) {
          setSipaAmountPaidError(true);
          setSipaAmountPaidErrorText(moneyErrMessage);
        }
        if (value?.length < 1 && sipaTrusteeName.trim().length === 0) {
          setSipaAmountPaidError(true);
          setSipaAmountPaidErrorText(moneyErrMessage);
        }
        break;
      //FIX BUG WHERE MONEY ERROR IS REMOVED WHEN TRUSTEE NAME IS RIGHT
      case "sipaTrusteeName":
        const isMoneyErrorSIPA =
          parseInt(sipaAmountPaid?.replaceAll(",", "")) < 0 ||
          parseInt(sipaAmountPaid?.replaceAll(",", "")) > 100000000 ||
          sipaAmountPaid?.length < 1;
        const isMoneyErrorSIPAValid =
          parseInt(sipaAmountPaid?.replaceAll(",", "")) < 0 ||
          parseInt(sipaAmountPaid?.replaceAll(",", "")) > 100000000;
        const moneyErrMessageSIPA =
          parseInt(sipaAmountPaid?.replaceAll(",", "")) > 100000000 ||
          parseInt(sipaAmountPaid?.replaceAll(",", "")) < 0
            ? "Please enter a valid number"
            : sipaAmountPaid?.length < 1
            ? "Please enter Amount paid or agreed"
            : "";
        //If no amount, set error if invalid or empty
        if (sipaAmountPaid.length < 1 && value.trim().length < 2)
          setSipaTrusteeNameError(true);
        //If valid amount, only set error if invalid
        else if (sipaAmountPaid.length && value.trim().length === 1)
          setSipaTrusteeNameError(true);
        //If amount error but entered blanks, its fine if its valid
        else if (sipaAmountPaid.length && value.trim().length === 0) {
          setSipaAmountPaidError(isMoneyErrorSIPAValid);
          setSipaAmountPaidErrorText(moneyErrMessageSIPA);
          setSipaTrusteeNameError(false);
          if (sipaAmountPaidError === true) {
            setSipaTrusteeNameError(true);
          }
        } else if (sipaAmountPaid.length && value.trim().length > 1) {
          setSipaAmountPaidError(isMoneyErrorSIPAValid);
          setSipaAmountPaidErrorText(moneyErrMessageSIPA);
          setSipaTrusteeNameError(false);
        }

        //If amount error but entered name, only fix if valid/empty amount
        else if (sipaAmountPaidError && value.trim().length > 1) {
          setSipaAmountPaidError(isMoneyErrorSIPAValid);
          setSipaAmountPaidErrorText(moneyErrMessageSIPA);
          setSipaTrusteeNameError(false);
        }
        //If name error, only fix if valid name, or if empty with valid amount
        else if (sipaAmountPaid.length && isMoneyErrorSIPA === false) {
          if (value.length === 1) {
            setSipaTrusteeNameError(true);
          } else if (value.length > 1 && value.trim().length < 2) {
            setSipaTrusteeNameError(true);
          } else if (value.length === 0 || value.trim().length > 1) {
            setSipaTrusteeNameError(false);
          }
        } else {
          setSipaTrusteeNameError(value.trim().length < 2);
        }

        break;
      case "sipaAppointedDateExplanation":
        setSipaAppointedDateExplanationError(value.trim().length < 2);
        break;
      default:
        break;
    }
  };

  const allFilled = () => {
    // let noWhiteSpace = true;
    // if (sipaTrusteeName.length > 0) {
    //   noWhiteSpace = sipaTrusteeName.trim().length > 1;
    // }

    let greaterCheck = false;
    if (sipaAmountPaid !== "") {
      let num = parseInt(sipaAmountPaid?.replaceAll(",", ""));
      greaterCheck = num <= 100000000;
    } else {
      greaterCheck = true;
    }
    return (
      (sipaAmountPaid.trim().length > 0 || sipaTrusteeName.trim().length > 1) &&
      greaterCheck &&
      sipaTrusteeName.trim().length !== 1 &&
      sipaCurrentlyOpen !== "" &&
      //noWhiteSpace &&
      !isInvalidDate(sipaAppointedDate) &&
      ((sipaDateOption === dateOptions.EXPLANATION &&
        sipaAppointedDateExplanation.trim().length > 1) ||
        sipaDateOption === dateOptions.EXACT)
    );
  };
  const noErrors = () => {
    return (
      !sipaAmountPaidError &&
      !sipaTrusteeNameError &&
      !sipaAppointedDateError &&
      !sipaAppointedDateExplanationError
    );
  };

  useEffect(() => {
    setIsFormComplete(allFilled() && noErrors());
  });
  useEffect(() => {
    if (
      (tempBankruptcyDRP?.sipaAmountPaid ||
        tempBankruptcyDRP?.sipaTrusteeName) &&
      tempBankruptcyDRP?.sipaCurrentlyOpen &&
      tempBankruptcyDRP?.sipaAppointedDate &&
      tempBankruptcyDRP?.sipaDateOption
    ) {
      setSipaAmountPaid(tempBankruptcyDRP?.sipaAmountPaid);
      setSipaTrusteeName(tempBankruptcyDRP?.sipaTrusteeName);
      setSipaCurrentlyOpen(tempBankruptcyDRP?.sipaCurrentlyOpen);
      setSipaAppointedDate(tempBankruptcyDRP?.sipaAppointedDate);
      setSipaDateOption(tempBankruptcyDRP?.sipaDateOption);
      setSipaAppointedDateExplanation(
        tempBankruptcyDRP?.sipaAppointedDateExplanation
      );
      setIsFormComplete(true);
      //       if (editIndex !== null) {
      //   hasCurrentFormChanged(false);
      // } else {
      hasCurrentFormChanged(true);
      dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: true }));
      // }
    }
  }, []);
  useEffect(() => {
    if (currentFormChanged || !noErrors()) {
      hasCurrentFormChanged(true);
      dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: true }));
    }
  }, [currentFormChanged, noErrors(), dispatch]);

  return (
    <>
      <div className="bankruptcy-form-wrapper">
        <br />
        <div className="row">
          <div className="col-md-12">
            <label id="SIPAIntroText" className="field-label">
              <strong id="SIPAIntroTextBold">
                If a SIPA Trustee was appointed or a direct payment procedure
                was begun:
              </strong>
            </label>
          </div>
        </div>
      </div>

      <div className="bond-disposition-details-form-wrapper">
        <div className="row" style={{ marginTop: "10px" }}>
          <div className="col-md-4">
            <label id="sipaAmountPaidLabel" className="field-label">
              Amount paid or agreed to be paid by you: *
            </label>
            <div
              className={
                sipaAmountPaidError ? "input-group is-invalid" : "input-group"
              }
              id="sipaAmountPaidInput"
              style={{ width: "80%", marginBottom: "5px" }}
            >
              <div className="input-group-prepend">
                <span
                  className="input-group-text input-group-text-pad-right"
                  id="sipaAmountPaid$"
                >
                  $
                </span>
              </div>
              <input
                type="text"
                id="sipaAmountPaid"
                className={
                  sipaAmountPaidError
                    ? "form-control form-control-15 is-invalid"
                    : "form-control form-control-15"
                }
                spellCheck="false"
                maxLength={11}
                value={sipaAmountPaid}
                onBlur={onBlur}
                onChange={handleChange}
                onKeyUp={handleChange}
                onKeyPress={onlyNumeric}
                onPaste={pasteCommaDelimited}
                autoComplete="off"
              />
            </div>
            <div
              id="compOriginalAmountOwedError"
              className={
                sipaAmountPaidError
                  ? "show invalid-feedback"
                  : "invalid-feedback"
              }
              style={{ marginTop: "10px" }}
            >
              {sipaAmountPaidErrorText}
            </div>
          </div>
        </div>
      </div>

      <div className="bankruptcy-form-wrapper">
        <div className="row">
          <div className="col-md-12">
            <label
              id="sipaTrusteeNameLabel"
              className="field-label"
              htmlFor="sipaTrusteeName"
              style={{ marginTop: "20px" }}
            >
              Or the name of the Trustee
            </label>
            <input
              style={{ width: "50%", marginBottom: "5px" }}
              id="sipaTrusteeName"
              type="text"
              className={
                sipaTrusteeNameError
                  ? "form-control is-invalid"
                  : "form-control"
              }
              onChange={handleChange}
              onBlur={onBlur}
              maxLength={200}
              minLength={2}
              value={sipaTrusteeName}
              autoComplete="off"
            ></input>
            <div
              id="sipaTrusteeNameError"
              className="invalid-feedback"
              style={{ marginBottom: "5px" }}
            >
              Please enter Name of Trustee
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <label
              id="sipaCurrentlyOpenLabelId"
              className="field-label"
              style={{ marginTop: "20px" }}
            >
              Currently Open? *
            </label>
          </div>
        </div>
        <div className="row">
          <div className="drp-radio-input-wrapper">
            <div className="col-12 copy-md-4 form-group">
              <div className="custom-control custom-radio">
                <input
                  name="sipaCurrentlyOpenYes"
                  type="radio"
                  id="sipaCurrentlyOpenYes"
                  className="custom-control-input"
                  value={currentlyOptions.YES}
                  checked={sipaCurrentlyOpen === currentlyOptions.YES}
                  onChange={handleCurrentlyOpenChange}
                />
                <label
                  id="sipaCurrentlyOpenYesLabelId"
                  htmlFor="sipaCurrentlyOpenYes"
                  className="custom-control-label"
                >
                  {currentlyOptions.YES}
                </label>
              </div>
              <div className="custom-control custom-radio">
                <input
                  name="sipaCurrentlyOpenNo"
                  type="radio"
                  id="sipaCurrentlyOpenNo"
                  className="custom-control-input"
                  value={currentlyOptions.NO}
                  checked={sipaCurrentlyOpen === currentlyOptions.NO}
                  onChange={handleCurrentlyOpenChange}
                />
                <label
                  id="sipaCurrentlyOpenNoLabelId"
                  htmlFor="sipaCurrentlyOpenNo"
                  className="custom-control-label"
                >
                  {currentlyOptions.NO}
                </label>
              </div>

              <div id="sipaCurrentlyOpenError" className="invalid-feedback">
                Please enter a court action
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 action-date-wrapper">
            <label
              id="sipaAppointedDateLabelId"
              className="field-label"
              htmlFor="sipaAppointedDateId"
            >
              Date Direct Payment Initiated/Filed or Trustee Appointed: *
            </label>
            <InputMask
              style={{ width: "25%", marginBottom: "5px" }}
              id="sipaAppointedDate"
              mask="99/99/9999"
              maskChar="_"
              className={`${
                sipaAppointedDateError
                  ? "form-control is-invalid"
                  : "form-control"
              } action-date-input`}
              placeholder="MM/DD/YYYY"
              autoComplete="off"
              value={sipaAppointedDate}
              onChange={handleChange}
              onBlur={onBlurDateInput}
            />
            <div
              id="sipaAppointedDateError"
              className="invalid-feedback"
              style={{ marginBottom: "10px" }}
            >
              {sipaAppointedDateErrorText}
            </div>
          </div>
        </div>

        <div className="row">
          <div
            className="col-12 copy-md-8 form-group"
            style={{ marginLeft: "10px" }}
          >
            <div className="drp-radio-input-wrapper">
              <div className="custom-control custom-radio">
                <input
                  name="sipaAppointedDateOptionExact"
                  type="radio"
                  id="sipaAppointedDateOptionExact"
                  className="custom-control-input"
                  value={dateOptions.EXACT}
                  checked={sipaDateOption === dateOptions.EXACT}
                  onChange={handleSipaDateOptionChange}
                />
                <label
                  htmlFor="sipaAppointedDateOptionExact"
                  className="custom-control-label"
                  id="sipaAppointedDateOptionExactLabel"
                >
                  {dateOptions.EXACT}
                </label>
              </div>
              <div className="custom-control custom-radio">
                <input
                  name="sipaAppointedDateOptionExplanation"
                  type="radio"
                  id="sipaAppointedDateOptionExplanation"
                  className="custom-control-input"
                  value={dateOptions.EXPLANATION}
                  checked={sipaDateOption === dateOptions.EXPLANATION}
                  onChange={handleSipaDateOptionChange}
                />
                <label
                  htmlFor="sipaAppointedDateOptionExplanation"
                  className="custom-control-label"
                  id="sipaAppointedDateOptionExplanationLabel"
                >
                  {dateOptions.EXPLANATION}
                </label>
              </div>
            </div>
            <div
              style={{
                marginTop: "10px",
                marginLeft: "20px",
                width: "80% !important",
              }}
            >
              {sipaDateOption === dateOptions.EXPLANATION ? (
                <div className="action-date-explanation-container">
                  <label
                    htmlFor="sipaAppointedDateExplanation"
                    className="field-label"
                    id="sipaAppointedDateExplanationLabelId"
                  >
                    If not exact, provide explanation *
                  </label>
                  <textarea
                    id="sipaAppointedDateExplanation"
                    className={
                      sipaAppointedDateExplanationError
                        ? "form-control form-control-15 is-invalid"
                        : "form-control form-control-15"
                    }
                    maxLength={500}
                    minLength={2}
                    rows="3"
                    cols={500}
                    value={sipaAppointedDateExplanation}
                    onChange={handleChange}
                    onBlur={onBlur}
                  />
                  <div
                    id="sipaAppointedDateExplanationError"
                    className="invalid-feedback"
                  >
                    Please enter an Explanation
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="row backbutton-drp-bankruptcy">
          <div className="col-md-12 buttons">
            <span
              id="bankruptcyAddDRPBack"
              className="back-button"
              onClick={() => {
                handleToggleBackModal(true);
              }}
            >
              <strong id="bankruptcyDisclBack">Back</strong>
            </span>
            <Button
              disabled={!isFormComplete}
              id="bankruptcyAddDRPNext"
              style={{ marginRight: "10px" }}
              onClick={handleNext}
            >
              Next
            </Button>
          </div>
        </div>
        <BackButtonModal
          isOpen={showBackModal}
          closeBackModal={() => {
            handleToggleBackModal(false);
          }}
          backClick={handleBack}
        />
      </div>
    </>
  );
};
export default BankruptcyDisclosureSIPADetails;
