import React, { useEffect, useState } from "react";
import { FORM_ID } from "../../../../../constants/Forms";
import { CCB_FORM_ID, headerText } from "./constants";
import DisclosureReportingPageLayout from "../common/DRPLayout";
import CustomerComplaintForm from "./CustomerComplaintForm";
import CustomerComplaintDRPSummary from "./CustomerComplaintDRPSummary";
import { useSelector } from "react-redux";
import { isDrpSummaryPage } from "./utils";
import { UtagTracking } from "../../../../../utils/utag-tracking";
import CustomerComplaintAddDRPStatusDetailsForm from "./AddDRPForms/CustomerComplaintAddDRPStatusDetailsForm";
import CustomerComplaintAddDRPCommentsForm from "./AddDRPForms/CustomerComplaintAddDRPCommentsForm";
import AddDRPFormLayout from "../common/AddDRPFormLayout";
import CustomerComplaintAddDRPCustomerComplaintForm from "./AddDRPForms/CustomerComplaintAddDRPCustomerComplaintForm";
import CustomerComplaintAddDRPArbitrationCFTCForm from "./AddDRPForms/CustomerComplaintAddDRPArbitrationCFTCForm";
import {
  deleteCustomerComplaintDRP,
  getCustomerComplaintDRP,
  insertCustomerComplaintDRP,
  updateCustomerComplaintDRP,
} from "../../../../../api/CCB/DRP/customerComplaintDisclosureDRP";
import { groupArrayByKey } from "../helpers";
import CustomerComplaintAddDRPCivilLitigationForm from "./AddDRPForms/CustomerComplaintAddDRPCivilLitigationForm";

const CustomerComplaint = ({
  toggleForm,
  subId: oktaSubId,
  updateCCB14CompletedInd,
  updateCCB14Step,
  candidateId,
  agencynumber,
  contracttypeid,
}) => {
  /********************************
   *       Initialize State       *
   ********************************/
  const { data: ccbData, isBack } = useSelector((state) => state.ccb14);
  const { custCompl, unchecked } = isDrpSummaryPage(ccbData);
  const [tempCustComplDRP, setTempCustComplDRP] = useState({});
  const [custComplDRPs, setCustComplDRPs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [groupedList, setGroupedList] = useState("");
  const [currentFormId, setCurrentFormId] = useState(
    CCB_FORM_ID.CUST_COMP_DISCL
  );

  const [isDRPCompleted, setIsDRPCompleted] = useState(false);
  const [isDRPNextDisabled, setIsDRPNextDisabled] = useState(false);

  /************************************************************************************************
   * getDRPList is used to format the DRP data into data that can be used in the DRPSummaryTable  *
   ************************************************************************************************/
  const getDRPList = (DRPs) => {
    let list = [];
    if (DRPs !== list && DRPs !== "") {
      for (let i in DRPs) {
        let currDRP = DRPs[i].complaintdrp;

        if (currDRP.drpSelectedCheckList.length > 0) {
          for (let j in currDRP.drpSelectedCheckList) {
            list.push({
              date: currDRP.custComplaintDateReceived,
              description: [currDRP.statusDetailsMatter],
              questionKey: currDRP.drpSelectedCheckList[j],
              mainIndex: DRPs[i].ccbdrpid,
              orgName: currDRP.custComplaintStatusOption,
            });
          }
        }
      }
    }
    return list;
  };

  /*****************************************************************************************
   * updateCustComplDisclosureDRPs: Inserts or Updates DRPs in the custComplDRPs arrayList *
   *****************************************************************************************/
  const updateCustComplDisclosureDRPs = async (newDRP) => {
    if (editIndex !== null) {
      let newCustComplDRPs = custComplDRPs.filter(
        (e) => e.ccbdrpid !== editIndex
      );
      newCustComplDRPs.push({
        complaintdrp: newDRP,
        ccbdrpid: editIndex,
      });
      await updateCustomerComplaintDRP(oktaSubId, {
        complaintjsondata: newDRP,
        ccbdrpid: editIndex,
      });
      setEditIndex(null);
      setCustComplDRPs(newCustComplDRPs);
    } else {
      setLoading(true);
      let newCustComplDRPs = custComplDRPs;
      const ccbdrpid = await insertCustomerComplaintDRP(oktaSubId, {
        complaintjsondata: newDRP,
      });
      newCustComplDRPs.push({
        complaintdrp: newDRP,
        ccbdrpid: ccbdrpid,
      });
      setCustComplDRPs(newCustComplDRPs);
      setLoading(false);
    }
  };

  /***************************************************************************************
   * deleteCustComplDisclosureDRP: Delete DRPs from database and custComplDRPs arrayList *
   ***************************************************************************************/
  const deleteCustComplDisclosureDRP = async ({ dataIndex }) => {
    const newCustComplDRPs = custComplDRPs.filter(
      (e) => e.ccbdrpid !== dataIndex
    );

    setCustComplDRPs(newCustComplDRPs);
    await deleteCustomerComplaintDRP(oktaSubId, dataIndex);
  };

  /********************************************************************************************
   * editCustComplDisclosureDRP: Edit DRP sets a ccbdrpid and associated DRP into the tempDRP *
   ********************************************************************************************/
  const editCustComplDisclosureDRP = ({ dataIndex }) => {
    setEditIndex(dataIndex);
    const { complaintdrp } = custComplDRPs.filter(
      (e) => e.ccbdrpid === dataIndex
    )[0];
    updateTempCustComplDRP(complaintdrp);
    updateCCBForm(CCB_FORM_ID.ADD_DRP_STATUS_DETAILS);
  };

  /**********************************************************
   * setData: Fetch CustComplaintDRPData and set into state *
   **********************************************************/
  const setData = async () => {
    setLoading(true);
    const { data } = await getCustomerComplaintDRP(oktaSubId);
    if (data !== "") {
      setCustComplDRPs(data);
    }
    setLoading(false);
  };

  /********************************************************************
   * this useEffect triggers when the component is initially rendered *
   ********************************************************************/
  useEffect(() => {
    if (groupedList === "") {
      let list = getDRPList(custComplDRPs);
      setGroupedList(groupArrayByKey(list, "questionKey"));
    }

    if (custCompl?.length && isBack) {
      setCurrentFormId(CCB_FORM_ID.DRP_SUMMARY);
    }

    setData();
    UtagTracking.sendPageNameView(
      "customercomplaintform",
      candidateId,
      agencynumber,
      contracttypeid
    );
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  /************************************************************************
   * this useEffect triggers every time custComplDRPs and loading changes *
   ************************************************************************/
  useEffect(() => {
    const list = getDRPList(custComplDRPs);
    setGroupedList(groupArrayByKey(list, "questionKey"));
  }, [custComplDRPs, loading]);

  /********************************
   * updateCCBForm: toggles form id *
   ********************************/
  const updateCCBForm = async (formId, options = null) => {
    window.scrollTo(0, 0);

    if (!isDRPCompleted && (isBack || options?.isBackClick)) {
      updateCCB14Step({ custCompDisclosureCompleteStep: "1/2" });
      updateCCB14CompletedInd({ custCompDisclosureCompleteInd: false });
    }

    if (
      formId === CCB_FORM_ID.DRP_SUMMARY ||
      formId === CCB_FORM_ID.CUST_COMP_DISCL
    ) {
      updateTempCustComplDRP({});
    }
    setCurrentFormId(formId);
  };

  /*************************************************************************************
   * updateTempCustComplDRP: sets tempDRP which is used when proceeding through addDRP *
   *************************************************************************************/
  const updateTempCustComplDRP = (tmpDrp) => {
    setTempCustComplDRP(tmpDrp);
  };

  /********************************************************
   * renderForm: handles which screen the user should see *
   ********************************************************/
  const renderForm = (id) => {
    switch (id) {
      case CCB_FORM_ID.CUST_COMP_DISCL:
        return (
          <CustomerComplaintForm
            header={headerText.MAIN_HEADER}
            toggleForm={toggleForm}
            oktaSubId={oktaSubId}
            updateCCBForm={updateCCBForm}
            backFormId={FORM_ID.CIVIL_JUDICIAL_DISCLOSURE}
            nextFormId={CCB_FORM_ID.DRP_SUMMARY}
            updateCCB14CompletedInd={updateCCB14CompletedInd}
            updateCCB14Step={updateCCB14Step}
            customerComplaintDRPs={custComplDRPs}
            custCompl={custCompl}
            isFetchingDRPs={loading}
            unchecked={unchecked}
            setIsLoadingProp={setLoading}
            setData={setData}
          />
        );
      case CCB_FORM_ID.DRP_SUMMARY:
        return (
          <DisclosureReportingPageLayout
            header={headerText.MAIN_HEADER}
            backFormId={CCB_FORM_ID.CUST_COMP_DISCL}
            nextFormId={FORM_ID.TERMINATION_DISCLOSURE}
            updateCCBForm={updateCCBForm}
            toggleForm={toggleForm}
            updateCCB14CompletedInd={updateCCB14CompletedInd}
            ind="custCompDisclosureCompleteInd"
            isLoading={loading}
            isNextDisabled={isDRPNextDisabled}
          >
            <CustomerComplaintDRPSummary
              updateCCBForm={updateCCBForm}
              loading={loading}
              setIsDRPCompleted={setIsDRPCompleted}
              updateCCB14Step={updateCCB14Step}
              updateCCB14CompletedInd={updateCCB14CompletedInd}
              setIsDRPNextDisabled={setIsDRPNextDisabled}
              setLoading={setLoading}
              oktaSubId={oktaSubId}
              data={custComplDRPs}
              custCompl={custCompl}
              deleteCustComplDisclosureDRP={deleteCustComplDisclosureDRP}
              editCustComplDisclosureDRP={editCustComplDisclosureDRP}
              groupedList={groupedList}
              isSummaryPage
            />
          </DisclosureReportingPageLayout>
        );
      case CCB_FORM_ID.ADD_DRP_STATUS_DETAILS:
        return (
          <AddDRPFormLayout
            updateCCBForm={updateCCBForm}
            header={headerText.SECTION_HEADER}
            section={headerText.CUST_COMP_ARB_CIV_LIT}
            activeStep={1}
            details="Status Details"
            steps={5}
          >
            <CustomerComplaintAddDRPStatusDetailsForm
              updateCCBForm={updateCCBForm}
              backFormId={CCB_FORM_ID.DRP_SUMMARY}
              nextFormId={CCB_FORM_ID.ADD_DRP_CUSTOMER_COMPLAINT}
              data={{ drpCheckList: custCompl }}
              updateTempCustComplDRP={updateTempCustComplDRP}
              tempDRP={tempCustComplDRP}
            ></CustomerComplaintAddDRPStatusDetailsForm>
          </AddDRPFormLayout>
        );
      case CCB_FORM_ID.ADD_DRP_CUSTOMER_COMPLAINT:
        return (
          <AddDRPFormLayout
            updateCCBForm={updateCCBForm}
            header={headerText.SECTION_HEADER}
            section={headerText.CUST_COMP_ARB_CIV_LIT}
            activeStep={2}
            details="Customer Complaint"
            steps={5}
          >
            <CustomerComplaintAddDRPCustomerComplaintForm
              updateCCBForm={updateCCBForm}
              backFormId={CCB_FORM_ID.ADD_DRP_STATUS_DETAILS}
              nextFormId={CCB_FORM_ID.ADD_DRP_ARBITRATION_CFTC}
              data={{ drpCheckList: custCompl }}
              updateTempCustComplDRP={updateTempCustComplDRP}
              tempDRP={tempCustComplDRP}
            ></CustomerComplaintAddDRPCustomerComplaintForm>
          </AddDRPFormLayout>
        );
      case CCB_FORM_ID.ADD_DRP_ARBITRATION_CFTC:
        return (
          <AddDRPFormLayout
            updateCCBForm={updateCCBForm}
            header={headerText.SECTION_HEADER}
            section={headerText.CUST_COMP_ARB_CIV_LIT}
            activeStep={3}
            details="Arbitration/CFTC"
            steps={5}
          >
            <CustomerComplaintAddDRPArbitrationCFTCForm
              updateCCBForm={updateCCBForm}
              backFormId={CCB_FORM_ID.ADD_DRP_CUSTOMER_COMPLAINT}
              nextFormId={CCB_FORM_ID.ADD_DRP_COMMENTS}
              updateTempCustComplDRP={updateTempCustComplDRP}
              tempDRP={tempCustComplDRP}
            ></CustomerComplaintAddDRPArbitrationCFTCForm>
          </AddDRPFormLayout>
        );
      case CCB_FORM_ID.ADD_DRP_CIVIL_LITIGATION:
        return (
          <AddDRPFormLayout
            updateCCBForm={updateCCBForm}
            header={headerText.SECTION_HEADER}
            section={headerText.CUST_COMP_ARB_CIV_LIT}
            activeStep={4}
            details="Civil Litigation"
            steps={5}
          >
            <CustomerComplaintAddDRPCivilLitigationForm
              updateCCBForm={updateCCBForm}
              backFormId={CCB_FORM_ID.ADD_DRP_CUSTOMER_COMPLAINT}
              nextFormId={CCB_FORM_ID.ADD_DRP_COMMENTS}
              updateTempCustComplDRP={updateTempCustComplDRP}
              tempDRP={tempCustComplDRP}
            ></CustomerComplaintAddDRPCivilLitigationForm>
          </AddDRPFormLayout>
        );
      case CCB_FORM_ID.ADD_DRP_COMMENTS:
        return (
          <AddDRPFormLayout
            updateCCBForm={updateCCBForm}
            header={headerText.SECTION_HEADER}
            section={headerText.CUST_COMP_ARB_CIV_LIT}
            activeStep={5}
            details="Comments"
            steps={5}
          >
            <CustomerComplaintAddDRPCommentsForm
              updateCCBForm={updateCCBForm}
              backFormId={CCB_FORM_ID.ADD_DRP_CUSTOMER_COMPLAINT}
              nextFormId={CCB_FORM_ID.DRP_SUMMARY}
              updateTempCustComplDRP={updateTempCustComplDRP}
              tempDRP={tempCustComplDRP}
              updateCustComplDisclosureDRPs={updateCustComplDisclosureDRPs}
            ></CustomerComplaintAddDRPCommentsForm>
          </AddDRPFormLayout>
        );
      default:
        break;
    }
  };

  return (
    <div className="customer-complaint-wrapper">
      {renderForm(currentFormId)}
    </div>
  );
};

export default CustomerComplaint;
