export const TOAST_MESSAGE = "Your Disclosure has been saved successfully.";

export const INTRO_TEXT = `If the answer to any of the following questions is “Yes”, please
provide details of all events or proceedings on the appropriate
Disclosure Reporting Page(s).`;

export const INTRO_REV = `Rev. Form U4`;

export const SECTION = {
  individualCriminalDisclosure: 1,
  organizationCriminalDisclosure: 2,
  regulatoryActionSECorCFTC: 3,
  regulatoryActionOtherRegulators: 4,
  regulatoryActionSRO: 5,
  professionalSuspension: 6,
  formalPendingActionInvestigation: 7,
  civilJudicialActions: 8,
  customerComplaintArbitrationCivilLitigation: 9,
  terminationDisclosures: 10,
  personalBankruptcy: 11,
  organizationBankruptcy: 12,
  bondDisclosures: 13,
  judgementLienDisclosures: 14,
};

export const KEYS = {
  individualCriminalDisclosure: [
    "convictedfelony",
    "chargedfelony",
    "convictedmisdemeanor",
    "chargedmisdemeanor",
  ],
  organizationCriminalDisclosure: [
    "orgconvictedfelony",
    "orgchargedfelony",
    "orgconvictedmisdemeanor",
    "orgchargedmisdemeanor",
  ],
  regulatoryActionSECorCFTC: [
    "secfalsestatement",
    "secinvolvedviolation",
    "seccauseinvestrelatedauth",
    "secorderagainst",
    "secceaseanddesist",
    "secviolatedsecuritiesact",
    "secaidedabettersecuritiesact",
    "secfailedtosupervise",
  ],
  regulatoryActionOtherRegulators: [
    "fedfalsestatement",
    "fedinvolvedviolation",
    "fedcauseinvestrelatedauth",
    "fedorderagainst",
    "fedregdenied",
    "finalorderbarred",
    "finalorderviolations",
  ],
  regulatoryActionSRO: [
    "selffalsestatement",
    "selfinvolvedviolation",
    "selfcauseinvestrelatedauth",
    "selfdisciplined",
    "selfviolatedsecuritiesact",
    "selfaidedabettersecuritiesact",
    "selffailedtosupervise",
  ],
  professionalSuspension: ["authrevoked"],
  formalPendingActionInvestigation: [
    "notifiedproceeding",
    "notifiedinvestigation",
  ],
  civilJudicialActions: [
    "enjoined",
    "foundinvolvedviolation",
    "dismissedcivilaction",
    "namedinpendingcivilaction",
  ],
  customerComplaintArbitrationCivilLitigation: [
    "litigationpending",
    "litigationaward",
    "litigationsettledprior",
    "litigationsettledafter",
    "complaintsettledprior",
    "complaintsettledafter",
    "complaint24monthssalesviolation",
    "complaint24monthsforgery",
    "violationover15k",
    "violationaward",
    "arbitrationsalesviolation",
    "arbitrationforgery",
  ],
  terminationDisclosures: [
    "termviolation",
    "termfraud",
    "termfailuretosupervise",
  ],
  personalBankruptcy: ["tenyearscompromise"],
  organizationBankruptcy: [
    "tenyearsorgcompromise",
    "tenyearsbankruptcyboolean",
  ],
  bondDisclosures: ["deniedbonding"],
  judgementLienDisclosures: ["unsatisfiedjudgements"],
};

export const RADIO = {
  yes: "true",
  no: "false",
};

export const dateInputMask = {
  EMPTY: "__/__/____",
};

export const eventStatusDateType = {
  EXACT: "Exact date",
  EXPLANATION: "Explanation",
};

export const DRPCheckboxListValuesProd = {
  NO_PROD: "No Product",
  ANN_CHARIT: "Annuity-Charitable",
  ANN_FIXED: "Annuity-Fixed",
  ANN_VARIABLE: "Annuity-Variable",
  BANKING_PROD: "Banking Product (other than CD)",
  CD: "CD",
  COMM_OPT: "Commodity Option",
  DEBT_ASSET: "Debt-Asset Backed",
  DEBT_CORP: "Debt-Corporate",
  DEBT_GOV: "Debt-Government",
  DEBT_MUN: "Debt-Municipal",
  DERIV: "Derivative",
  DIR_INV_LP_INT: "Direct Investment-DPP & LP Interest",
  EQUIP_LEAS: "Equipment Leasing",
  EQUIP_LIST: "Equity Listed (Common & Preferred Stock)",
  EQUITY_OTC: "Equity-OTC",
  FUTURES_COM: "Futures Commodity",
  FUTURES_FIN: "Futures-Financial",
  INDEX_OPT: "Index Option",
  INSURANCE: "Insurance",
  INV_CONTR: "Investment Contract",
  MONEY_MARKET_FUND: "Money Market Fund",
  MUTUAL_FUND: "Mutual Fund",
  OIL_GAS: "Oil & Gas",
  OPTIONS: "Options",
  PENNY_STOCK: "Penny Stock",
  PRIME_BANK_INST: "Prime Bank Instrument",
  PROM_NOTE: "Promissory Note",
  REAL_EST_SEC: "Real Estate Security",
  SEC_FUTURES: "Security Futures",
  UNIT_INV: "Unit Investment Trust",
  VIA_SETT: "Viatical Settlement",
  OTHER: "Other",
};

export const DRPProdCheckList = [
  { id: 1, value: DRPCheckboxListValuesProd.NO_PROD, checked: false },
  { id: 2, value: DRPCheckboxListValuesProd.ANN_CHARIT, checked: false },
  { id: 3, value: DRPCheckboxListValuesProd.ANN_FIXED, checked: false },
  { id: 4, value: DRPCheckboxListValuesProd.ANN_VARIABLE, checked: false },
  { id: 5, value: DRPCheckboxListValuesProd.BANKING_PROD, checked: false },
  { id: 6, value: DRPCheckboxListValuesProd.CD, checked: false },
  { id: 7, value: DRPCheckboxListValuesProd.COMM_OPT, checked: false },
  { id: 8, value: DRPCheckboxListValuesProd.DEBT_ASSET, checked: false },
  { id: 9, value: DRPCheckboxListValuesProd.DEBT_CORP, checked: false },
  { id: 10, value: DRPCheckboxListValuesProd.DEBT_GOV, checked: false },
  { id: 11, value: DRPCheckboxListValuesProd.DEBT_MUN, checked: false },
  { id: 12, value: DRPCheckboxListValuesProd.DERIV, checked: false },
  { id: 13, value: DRPCheckboxListValuesProd.DIR_INV_LP_INT, checked: false },
  { id: 14, value: DRPCheckboxListValuesProd.EQUIP_LEAS, checked: false },
  { id: 15, value: DRPCheckboxListValuesProd.EQUIP_LIST, checked: false },
  { id: 16, value: DRPCheckboxListValuesProd.EQUITY_OTC, checked: false },
  { id: 17, value: DRPCheckboxListValuesProd.FUTURES_COM, checked: false },
  { id: 18, value: DRPCheckboxListValuesProd.FUTURES_FIN, checked: false },
  { id: 19, value: DRPCheckboxListValuesProd.INDEX_OPT, checked: false },
  { id: 20, value: DRPCheckboxListValuesProd.INSURANCE, checked: false },
  { id: 21, value: DRPCheckboxListValuesProd.INV_CONTR, checked: false },
  {
    id: 22,
    value: DRPCheckboxListValuesProd.MONEY_MARKET_FUND,
    checked: false,
  },
  { id: 23, value: DRPCheckboxListValuesProd.MUTUAL_FUND, checked: false },
  { id: 24, value: DRPCheckboxListValuesProd.OIL_GAS, checked: false },
  { id: 25, value: DRPCheckboxListValuesProd.OPTIONS, checked: false },
  { id: 26, value: DRPCheckboxListValuesProd.PENNY_STOCK, checked: false },
  { id: 27, value: DRPCheckboxListValuesProd.PRIME_BANK_INST, checked: false },
  { id: 28, value: DRPCheckboxListValuesProd.PROM_NOTE, checked: false },
  { id: 29, value: DRPCheckboxListValuesProd.REAL_EST_SEC, checked: false },
  { id: 30, value: DRPCheckboxListValuesProd.SEC_FUTURES, checked: false },
  { id: 31, value: DRPCheckboxListValuesProd.UNIT_INV, checked: false },
  { id: 32, value: DRPCheckboxListValuesProd.VIA_SETT, checked: false },
  { id: 33, value: DRPCheckboxListValuesProd.OTHER, checked: false },
];

export const DRPCheckboxListValuesFullName = {
  BAR: "Bar",
  CEASE_DESIST: "Cease and Desist",
  CIVIL_ADMIN: "Civil and Administrative Penalty(ies)/Fine(s)",
  CENSURE: "Censure",
  EXP: "Expulsion",
  REPR: "Reprimand",
  REST: "Restitution",
  UNDERTAKING: "Undertaking",
  DISGORG: "Disgorgement",
  PROHIB: "Prohibition",
  RESCISSION: "Rescission",
  SUSP: "Suspension",
  DENIAL: "Denial",
  MONETARY: "Monetary Penalty other than Fines",
  REQUALIFICATION: "Requalification",
  REVO: "Revocation",
  OTHER_FN: "Other",
};

export const DRPFullNameCheckList = [
  { id: 34, value: DRPCheckboxListValuesFullName.BAR, checked: false },
  { id: 35, value: DRPCheckboxListValuesFullName.CEASE_DESIST, checked: false },
  { id: 36, value: DRPCheckboxListValuesFullName.CIVIL_ADMIN, checked: false },
  { id: 37, value: DRPCheckboxListValuesFullName.CENSURE, checked: false },
  { id: 38, value: DRPCheckboxListValuesFullName.EXP, checked: false },
  { id: 39, value: DRPCheckboxListValuesFullName.REPR, checked: false },
  { id: 40, value: DRPCheckboxListValuesFullName.REST, checked: false },
  { id: 41, value: DRPCheckboxListValuesFullName.UNDERTAKING, checked: false },
  { id: 42, value: DRPCheckboxListValuesFullName.DISGORG, checked: false },
  { id: 43, value: DRPCheckboxListValuesFullName.PROHIB, checked: false },
  { id: 44, value: DRPCheckboxListValuesFullName.RESCISSION, checked: false },
  { id: 45, value: DRPCheckboxListValuesFullName.SUSP, checked: false },
  { id: 46, value: DRPCheckboxListValuesFullName.DENIAL, checked: false },
  { id: 47, value: DRPCheckboxListValuesFullName.MONETARY, checked: false },
  {
    id: 48,
    value: DRPCheckboxListValuesFullName.REQUALIFICATION,
    checked: false,
  },
  { id: 49, value: DRPCheckboxListValuesFullName.REVO, checked: false },
  { id: 50, value: DRPCheckboxListValuesFullName.OTHER_FN, checked: false },
];

export const DRPCheckboxListValuesSanctionsOrdered = {
  BAR_PERM: "Bar (Permanent)",
  BAR_TEMP: "Bar (Temporary/Time Limited)",
  CEASE_DESIST: "Cease and Desist",
  CENSURE: "Censure",
  CIVIL_ADMIN: "Civil and Administrative Penalty(ies)/Fine(s)",
  DENIAL: "Denial",
  DISGORG: "Disgorgement",
  EXP: "Expulsion",
  REPR: "Letter of Reprimand",
  MONETARY: "Monetary Penalty other than Fines",
  PROHIB: "Prohibition",
  REQUALIFICATION: "Requalification",
  RESCISSION: "Rescission",
  REST: "Restitution",
  REVO: "Revocation",
  SUSP: "Suspension",
  UNDERTAKING: "Undertaking",
};

export const DRPSanctOrdCheckList = [
  {
    id: 51,
    value: DRPCheckboxListValuesSanctionsOrdered.BAR_PERM,
    checked: false,
  },
  {
    id: 52,
    value: DRPCheckboxListValuesSanctionsOrdered.BAR_TEMP,
    checked: false,
  },
  {
    id: 53,
    value: DRPCheckboxListValuesSanctionsOrdered.CEASE_DESIST,
    checked: false,
  },
  {
    id: 54,
    value: DRPCheckboxListValuesSanctionsOrdered.CENSURE,
    checked: false,
  },
  {
    id: 55,
    value: DRPCheckboxListValuesSanctionsOrdered.CIVIL_ADMIN,
    checked: false,
  },
  {
    id: 56,
    value: DRPCheckboxListValuesSanctionsOrdered.DENIAL,
    checked: false,
  },
  {
    id: 57,
    value: DRPCheckboxListValuesSanctionsOrdered.DISGORG,
    checked: false,
  },
  { id: 58, value: DRPCheckboxListValuesSanctionsOrdered.EXP, checked: false },
  { id: 59, value: DRPCheckboxListValuesSanctionsOrdered.REPR, checked: false },
  {
    id: 60,
    value: DRPCheckboxListValuesSanctionsOrdered.MONETARY,
    checked: false,
  },
  {
    id: 61,
    value: DRPCheckboxListValuesSanctionsOrdered.PROHIB,
    checked: false,
  },
  {
    id: 62,
    value: DRPCheckboxListValuesSanctionsOrdered.REQUALIFICATION,
    checked: false,
  },
  {
    id: 63,
    value: DRPCheckboxListValuesSanctionsOrdered.RESCISSION,
    checked: false,
  },
  { id: 64, value: DRPCheckboxListValuesSanctionsOrdered.REST, checked: false },
  { id: 65, value: DRPCheckboxListValuesSanctionsOrdered.REVO, checked: false },
  { id: 66, value: DRPCheckboxListValuesSanctionsOrdered.SUSP, checked: false },
  {
    id: 67,
    value: DRPCheckboxListValuesSanctionsOrdered.UNDERTAKING,
    checked: false,
  },
];

export const DRPCheckboxListValuesReliefSought = {
  CEASE_DESIST: "Cease and Desist",
  CIVIL_ADMIN: "Civil and Administrative Penalty(ies)/Fine(s)",
  DISGORG: "Disgorgement",
  INJ: "Injunction",
  MONETARY: "Monetary Penalty other than Fines",
  RESTITUTION: "Restitution",
  REST_ORD: "Restraining Order",
  OTHER_FN: "Other",
};

export const DRPReliefSoughtCheckList = [
  {
    id: 68,
    value: DRPCheckboxListValuesReliefSought.CEASE_DESIST,
    checked: false,
  },
  {
    id: 69,
    value: DRPCheckboxListValuesReliefSought.CIVIL_ADMIN,
    checked: false,
  },
  { id: 70, value: DRPCheckboxListValuesReliefSought.DISGORG, checked: false },
  { id: 71, value: DRPCheckboxListValuesReliefSought.INJ, checked: false },
  { id: 72, value: DRPCheckboxListValuesReliefSought.MONETARY, checked: false },
  {
    id: 73,
    value: DRPCheckboxListValuesReliefSought.RESTITUTION,
    checked: false,
  },
  { id: 74, value: DRPCheckboxListValuesReliefSought.REST_ORD, checked: false },
  { id: 75, value: DRPCheckboxListValuesReliefSought.OTHER_FN, checked: false },
];

export const DRPCheckboxListValuesSanctionsOrderedReliefGranted = {
  CIVIL_ADMIN: "Civil and Administrative Penalty(ies)/Fine(s)",
  CEASE_DESIST: "Cease and Desist",
  DISGORG: "Disgorgement",
  INJ: "Injunction",
  MONETARY: "Monetary Penalty other than fines",
  RESTITUTION: "Restitution",
};

export const DRPSanctionsOrderedReliefGrantedCheckList = [
  {
    id: 76,
    value: DRPCheckboxListValuesSanctionsOrderedReliefGranted.CIVIL_ADMIN,
    checked: false,
  },
  {
    id: 77,
    value: DRPCheckboxListValuesSanctionsOrderedReliefGranted.CEASE_DESIST,
    checked: false,
  },
  {
    id: 78,
    value: DRPCheckboxListValuesSanctionsOrderedReliefGranted.DISGORG,
    checked: false,
  },
  {
    id: 79,
    value: DRPCheckboxListValuesSanctionsOrderedReliefGranted.INJ,
    checked: false,
  },
  {
    id: 80,
    value: DRPCheckboxListValuesSanctionsOrderedReliefGranted.MONETARY,
    checked: false,
  },
  {
    id: 81,
    value: DRPCheckboxListValuesSanctionsOrderedReliefGranted.RESTITUTION,
    checked: false,
  },
];
