import { Button } from "react-blueprint-library";
import { FORM_ID } from "../../../constants/Forms";
import React, { useReducer, useState, useEffect } from "react";
import InputMask from "react-input-mask";
import Moment from "moment";
import BackModal from "../Forms/BackButtonModal";
/* HTTP Request Functions */
import {
  insertProfessionalDesignations,
  deleteProfessionalDesignations,
} from "../../../api/makeAPIRequest";
/* Helpers for State Management */
import {
  pdArr,
  pdInitialState,
  pdReducer,
} from "./helpers/ProfessionalDesignationsState";
import {
  profDesignationDesc,
  profDesignationID,
} from "../../../constants/professionalDesignations";
/* Styling and Functions */
import "../../../assets/stylesheets/StartApplication/CAS/ProfessionalDesignations.scss";
import { isValidDate } from "../../../common/Functions";
import NavigationButtons from "../../SharedComponents/NavigationButtons";
import { UtagTracking } from "../../../utils/utag-tracking";

const ProfessionalDesignationsForm = (props) => {
  const [showSaveLoader, setShowSaveLoader] = useState(false);
  const [hasFormChanged, setHasFormChanged] = useState(false);
  const [state, dispatch] = useReducer(pdReducer, pdInitialState);
  const { isDataFetched } = state;
  const { subId, isFormComplete } = props;

  let hasError = false;

  useEffect(() => {
    if (isDataFetched === false) {
      fetchData();
    }
    async function fetchData() {
      const { hasprofdesignation, professionalDesignations } =
        props.profDesData;
      if (hasprofdesignation !== null) {
        dispatch({
          type: "onLoad",
          fetchedData: professionalDesignations,
          hasprofdesignation: hasprofdesignation,
          inputValue: true,
        });
        if (
          hasprofdesignation === true &&
          professionalDesignations.length > 0
        ) {
          for (let o = 0; o < professionalDesignations.length; o++) {
            if (
              professionalDesignations[o].profdesignationid !==
              profDesignationID.Other
            ) {
              var designation =
                professionalDesignations[o].designationdescription.match(
                  /\((.*)\)/
                );
              if (designation !== null) {
                dispatch({
                  type: "setDesignation",
                  designation: designation[1].toLowerCase(),
                  designationDate: Moment.utc(
                    professionalDesignations[o].dateacquired
                  ).format("MM/DD/YYYY"),
                });
              }
            }
          }
        }
      }
    }
    UtagTracking.sendPageNameView(
      "professionaldesignationsform",
      props.candidateId,
      props.agencynumber,
      props.contracttypeid
    );
  }, [isDataFetched, subId, isFormComplete, props]);

  /* When User Submits Data */
  const handleNext = async () => {
    if (state.certificationsNoCheck) {
      if (hasFormChanged) {
        setShowSaveLoader(true);
        await deleteProfessionalDesignations(props.subId);
        let data = {
          hasprofdesignation: false,
        };
        props.saveProfDesData({
          hasprofdesignation: false,
        });
        await insertProfessionalDesignations(props.subId, data);
        props.isFormComplete(true);
        props.hasCurrentFormChanged(false);
      }
      if (!hasError) {
        props.toggleForm(FORM_ID.EMPLOYMENT_HISTORY);
      }
    } else if (
      state.certificationsYesCheck === false &&
      state.certificationsNoCheck === false
    ) {
      dispatch({
        type: "onChange",
        fieldName: "certificationsError",
        inputValue: true,
      });
    } else {
      let isOneChecked = false;
      let areThereErrors = false;
      for (let i = 0; i < pdArr.length; i++) {
        if (state[pdArr[i]] === true) {
          isOneChecked = true;
          if (
            state[`${pdArr[i]}Date`] === "" &&
            state[`${pdArr[i]}DateError`] === false
          ) {
            dispatch({
              type: "onBlur",
              fieldName: `${pdArr[i]}DateError`,
              errorText: "Please enter a valid Date",
              hasError: true,
            });
            hasError = true;
            areThereErrors = true;
          } else if (state[`${pdArr[i]}DateError`] === true) {
            areThereErrors = true;
            hasError = true;
          }
          //}
        }
      }

      if (isOneChecked && areThereErrors === false && hasFormChanged) {
        setShowSaveLoader(true);
        await deleteProfessionalDesignations(props.subId);
        let profDesArray = [];
        for (let i in pdArr) {
          if (state[pdArr[i]] === true) {
            let item = pdArr[i].toUpperCase();
            let data = {
              hasprofdesignation: true,
              candidateid: props.candidateId,
              profdesignationid: profDesignationID[item],
              designationdescription: profDesignationDesc[item],
              dateacquired: state[`${pdArr[i]}Date`],
            };
            profDesArray.push(data);
            await insertProfessionalDesignations(props.subId, data);
          }
        }
        setShowSaveLoader(false);
        props.isFormComplete(true);
        props.hasCurrentFormChanged(false);
        props.saveProfDesData({
          hasprofdesignation: true,
          professionalDesignations: profDesArray,
        });
        if (!hasError) {
          props.toggleForm(FORM_ID.EMPLOYMENT_HISTORY);
        }
      } else if (!isOneChecked) {
        dispatch({
          type: "onChange",
          fieldName: "professionalDesignationError",
          inputValue: true,
        });
      } else {
        if (!hasError) {
          props.toggleForm(FORM_ID.EMPLOYMENT_HISTORY);
        }
      }
    }
  };

  const onBlur = (event) => {
    let errorMessage = "";

    if (event.target.id.includes("Date")) {
      let currDate = Moment();
      let dateParts = event.target.value.split("/");
      let month = dateParts[0];
      let day = dateParts[1];
      let year = dateParts[2];
      let dateCalc = Moment(year + "-" + month + "-" + day);
      let dif = currDate.diff(dateCalc, "year");

      if (!isValidDate(event.target.value) || year === "0000") {
        errorMessage = "Please enter a valid date";
        hasError = true;
      } else if (dif >= 100) {
        errorMessage = "Date cannot be more than 100 years";
        hasError = true;
      } else if (dateCalc > currDate) {
        errorMessage = "Date cannot be a future date";
        hasError = true;
      }

      dispatch({
        type: "onBlur",
        fieldName: `${event.target.id}Error`,
        errorText: errorMessage,
        hasError: hasError,
      });
    }
  };

  /* For TextFields */
  const handleChange = (event) => {
    setHasFormChanged(true);
    props.isFormComplete(false);
    props.hasCurrentFormChanged(true);

    dispatch({
      type: "onChange",
      fieldName: event.target.id,
      inputValue: event.target.value,
    });
  };

  /* For CheckBoxes */
  const handleCheck = (event) => {
    setHasFormChanged(true);
    props.isFormComplete(false);
    props.hasCurrentFormChanged(true);

    dispatch({
      type: "onCheckChange",
      fieldName: event.target.id,
      inputValue: event.target.checked,
    });
    if (!event.target.checked) {
      clearInput(`${event.target.id}Date`);
    }
  };

  /* Clear Date Field and Error when unchecked */
  const clearInput = (dateField) => {
    dispatch({
      type: "onChange",
      fieldName: dateField,
      inputValue: "",
    });
    dispatch({
      type: "onBlur",
      fieldName: `${dateField}Error`,
      hasError: false,
      errorText: "",
    });
  };

  /* For Radio Buttons */
  const handleRadioChange = async (event) => {
    setHasFormChanged(true);
    props.isFormComplete(false);
    props.hasCurrentFormChanged(true);

    dispatch({
      type: "onRadioChange",
      fieldName: event.target.id,
      inputValue: event.target.checked,
    });
    if (event.target.id === "certificationsNoCheck") {
      dispatch({
        type: "resetDesignations",
      });
    }
  };

  const handleCloseBackModal = () => {
    dispatch({
      type: "onChange",
      fieldName: "showBackModal",
      inputValue: false,
    });
  };
  const handleBackClick = () => {
    handleCloseBackModal();
    props.toggleForm(FORM_ID.EDUCATION);
  };

  const handleShowBackModal = () => {
    if (hasFormChanged) {
      dispatch({
        type: "onChange",
        fieldName: "showBackModal",
        inputValue: true,
      });
    } else {
      props.toggleForm(FORM_ID.EDUCATION);
    }
  };

  return (
    <div id="professionalDesignationsForm">
      <div className="row">
        <div className="col-md-12">
          <div className="eyebrow">
            <strong>EDUCATION</strong>
          </div>
        </div>
        <div className="col-md-12">
          <h1>Professional Designations</h1>
        </div>
      </div>
      <div>
        <div className="row">
          <div className="col-md-12">
            <span className="required-field-text">* = Required Field</span>
          </div>
        </div>
        <div>
          <h3 id="pdQuestion">
            Do you hold any professional certifications, trade certifications,
            or professional designations?*
          </h3>
          <div className="custom-control pd-certifications">
            <div className="custom-control custom-radio">
              <input
                type="radio"
                name="certifications"
                id="certificationsYesCheck"
                className={
                  state.certificationsError
                    ? "custom-control-input is-invalid"
                    : "custom-control-input"
                }
                checked={state.certificationsYesCheck}
                onChange={handleRadioChange}
                onBlur={onBlur}
              />
              <label
                htmlFor="certificationsYesCheck"
                className="custom-control-label"
                id="certificationsYesLabel"
              >
                Yes
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                type="radio"
                name="certifications"
                className={
                  state.certificationsError
                    ? "custom-control-input is-invalid"
                    : "custom-control-input"
                }
                id="certificationsNoCheck"
                checked={state.certificationsNoCheck}
                onChange={handleRadioChange}
                onBlur={onBlur}
              />
              <label
                htmlFor="certificationsNoCheck"
                className="custom-control-label"
                id="certificationsNoLabel"
              >
                No
              </label>
            </div>
            {state.certificationsError && (
              <div id="certificationsError" className="invalid-feedback">
                Please choose a response
              </div>
            )}
          </div>
          {state.certificationsYesCheck && (
            <div>
              <span>Select all that apply</span>
              <div className="custom-control designations">
                <div className="custom-control custom-checkbox">
                  <input
                    id="clu"
                    type="checkbox"
                    checked={state.clu === true}
                    className={
                      state.professionalDesignationError
                        ? "custom-control-input is-invalid"
                        : "custom-control-input"
                    }
                    onChange={handleCheck}
                  />
                  <label className="custom-control-label" htmlFor="clu">
                    <strong>Chartered Life Underwriter (CLU)</strong>
                  </label>
                </div>
                {state.clu === true && (
                  <div className="row date-wrapper">
                    <label>
                      <strong>Date attained</strong>
                    </label>
                    <div className="form-group col-md-3">
                      <InputMask
                        id="cluDate"
                        mask="99/99/9999"
                        maskChar="_"
                        className={
                          state.cluDateError
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholder="MM/DD/YYYY"
                        value={state.cluDate}
                        onBlur={onBlur}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                      {state.cluDateError && (
                        <div id="cluDateError" className="invalid-feedback">
                          {state.cluDateErrorText}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className="custom-control custom-checkbox">
                  <input
                    id="chfc"
                    type="checkbox"
                    checked={state.chfc === true}
                    className={
                      state.professionalDesignationError
                        ? "custom-control-input is-invalid"
                        : "custom-control-input"
                    }
                    onChange={handleCheck}
                  />
                  <label className="custom-control-label" htmlFor="chfc">
                    <strong>Chartered Financial Consultant (CHFC)</strong>
                  </label>
                </div>
                {state.chfc === true && (
                  <div className="row date-wrapper">
                    <label>
                      <strong>Date attained</strong>
                    </label>
                    <div className="form-group col-md-3">
                      <InputMask
                        id="chfcDate"
                        mask="99/99/9999"
                        maskChar="_"
                        className={
                          state.chfcDateError
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholder="MM/DD/YYYY"
                        value={state.chfcDate}
                        onBlur={onBlur}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                      {state.chfcDateError && (
                        <div id="chfcDateError" className="invalid-feedback">
                          {state.chfcDateErrorText}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className="custom-control custom-checkbox">
                  <input
                    id="msfs"
                    type="checkbox"
                    checked={state.msfs === true}
                    className={
                      state.professionalDesignationError
                        ? "custom-control-input is-invalid"
                        : "custom-control-input"
                    }
                    onChange={handleCheck}
                  />
                  <label className="custom-control-label" htmlFor="msfs">
                    <strong>
                      Master of Science in Financial Services (MSFS)
                    </strong>
                  </label>
                </div>
                {state.msfs === true && (
                  <div className="row date-wrapper">
                    <label>
                      <strong>Date attained</strong>
                    </label>
                    <div className="form-group col-md-3">
                      <InputMask
                        id="msfsDate"
                        mask="99/99/9999"
                        maskChar="_"
                        className={
                          state.msfsDateError
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholder="MM/DD/YYYY"
                        value={state.msfsDate}
                        onBlur={onBlur}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                      {state.msfsDateError && (
                        <div id="msfsDateError" className="invalid-feedback">
                          {state.msfsDateErrorText}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className="custom-control custom-checkbox">
                  <input
                    id="cfp"
                    type="checkbox"
                    checked={state.cfp === true}
                    className={
                      state.professionalDesignationError
                        ? "custom-control-input is-invalid"
                        : "custom-control-input"
                    }
                    onChange={handleCheck}
                  />
                  <label className="custom-control-label" htmlFor="cfp">
                    <strong>Certified Financial Planner (CFP)</strong>
                  </label>
                </div>
                {state.cfp === true && (
                  <div className="row date-wrapper">
                    <label>
                      <strong>Date attained</strong>
                    </label>
                    <div className="form-group col-md-3">
                      <InputMask
                        id="cfpDate"
                        mask="99/99/9999"
                        maskChar="_"
                        className={
                          state.cfpDateError
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholder="MM/DD/YYYY"
                        value={state.cfpDate}
                        onBlur={onBlur}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                      {state.cfpDateError && (
                        <div id="cfpDateError" className="invalid-feedback">
                          {state.cfpDateErrorText}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className="custom-control custom-checkbox">
                  <input
                    id="cic"
                    type="checkbox"
                    checked={state.cic === true}
                    className={
                      state.professionalDesignationError
                        ? "custom-control-input is-invalid"
                        : "custom-control-input"
                    }
                    onChange={handleCheck}
                  />
                  <label className="custom-control-label" htmlFor="cic">
                    <strong>Certified Insurance Counselor (CIC)</strong>
                  </label>
                </div>
                {state.cic === true && (
                  <div className="row date-wrapper">
                    <label>
                      <strong>Date attained</strong>
                    </label>
                    <div className="form-group col-md-3">
                      <InputMask
                        name="check"
                        id="cicDate"
                        mask="99/99/9999"
                        maskChar="_"
                        className={
                          state.cicDateError
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholder="MM/DD/YYYY"
                        value={state.cicDate}
                        onBlur={onBlur}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                      {state.cicDateError && (
                        <div id="cicDateError" className="invalid-feedback">
                          {state.cicDateErrorText}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className="custom-control custom-checkbox">
                  <input
                    id="lutcf"
                    type="checkbox"
                    checked={state.lutcf === true}
                    className={
                      state.professionalDesignationError
                        ? "custom-control-input is-invalid"
                        : "custom-control-input"
                    }
                    onChange={handleCheck}
                  />
                  <label className="custom-control-label" htmlFor="lutcf">
                    <strong>
                      Life Underwriter Training Council Fellow (LUTCF)
                    </strong>
                  </label>
                </div>
                {state.lutcf === true && (
                  <div className="row date-wrapper">
                    <label>
                      <strong>Date attained</strong>
                    </label>
                    <div className="form-group col-md-3">
                      <InputMask
                        id="lutcfDate"
                        mask="99/99/9999"
                        maskChar="_"
                        className={
                          state.lutcfDateError
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholder="MM/DD/YYYY"
                        value={state.lutcfDate}
                        onBlur={onBlur}
                        onChange={handleChange}
                        autoComplete="off"
                      />

                      {state.lutcfDateError && (
                        <div id="lutcfDateError" className="invalid-feedback">
                          {state.lutcfDateErrorText}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className="custom-control custom-checkbox">
                  <input
                    id="flmi"
                    type="checkbox"
                    checked={state.flmi === true}
                    className={
                      state.professionalDesignationError
                        ? "custom-control-input is-invalid"
                        : "custom-control-input"
                    }
                    onChange={handleCheck}
                  />
                  <label className="custom-control-label" htmlFor="flmi">
                    <strong>Fellow, Life Management Institute (FLMI)</strong>
                  </label>
                </div>
                {state.flmi === true && (
                  <div className="row date-wrapper">
                    <label>
                      <strong>Date attained</strong>
                    </label>
                    <div className="form-group col-md-3">
                      <InputMask
                        id="flmiDate"
                        mask="99/99/9999"
                        maskChar="_"
                        className={
                          state.flmiDateError
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholder="MM/DD/YYYY"
                        value={state.flmiDate}
                        onBlur={onBlur}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                      {state.flmiDateError && (
                        <div id="flmiDateError" className="invalid-feedback">
                          {state.flmiDateErrorText}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className="custom-control custom-checkbox">
                  <input
                    id="rhu"
                    type="checkbox"
                    checked={state.rhu === true}
                    className={
                      state.professionalDesignationError
                        ? "custom-control-input is-invalid"
                        : "custom-control-input"
                    }
                    onChange={handleCheck}
                  />
                  <label className="custom-control-label" htmlFor="rhu">
                    <strong>Registered Health Underwriter (RHU)</strong>
                  </label>
                </div>
                {state.rhu === true && (
                  <div className="row date-wrapper">
                    <label>
                      <strong>Date attained</strong>
                    </label>
                    <div className="form-group col-md-3">
                      <InputMask
                        id="rhuDate"
                        mask="99/99/9999"
                        maskChar="_"
                        className={
                          state.rhuDateError
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholder="MM/DD/YYYY"
                        value={state.rhuDate}
                        onBlur={onBlur}
                        onChange={handleChange}
                        autoComplete="off"
                      />

                      {state.rhuDateError && (
                        <div id="rhuDateError" className="invalid-feedback">
                          {state.rhuDateErrorText}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className="custom-control custom-checkbox">
                  <input
                    id="msm"
                    type="checkbox"
                    checked={state.msm === true}
                    className={
                      state.professionalDesignationError
                        ? "custom-control-input is-invalid"
                        : "custom-control-input"
                    }
                    onChange={handleCheck}
                  />
                  <label className="custom-control-label" htmlFor="msm">
                    <strong>Master of Science in Management (MSM)</strong>
                  </label>
                </div>
                {state.msm === true && (
                  <div className="row date-wrapper">
                    <label>
                      <strong>Date attained</strong>
                    </label>
                    <div className="form-group col-md-3">
                      <InputMask
                        id="msmDate"
                        mask="99/99/9999"
                        maskChar="_"
                        className={
                          state.msmDateError
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholder="MM/DD/YYYY"
                        value={state.msmDate}
                        onBlur={onBlur}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                      {state.msmDateError && (
                        <div id="msmDateError" className="invalid-feedback">
                          {state.msmDateErrorText}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className="custom-control custom-checkbox">
                  <input
                    id="jd"
                    type="checkbox"
                    checked={state.jd === true}
                    className={
                      state.professionalDesignationError
                        ? "custom-control-input is-invalid"
                        : "custom-control-input"
                    }
                    onChange={handleCheck}
                  />
                  <label className="custom-control-label" htmlFor="jd">
                    <strong>Juris Doctor (JD)</strong>
                  </label>
                </div>
                {state.jd === true && (
                  <div className="row date-wrapper">
                    <label>
                      <strong>Date attained</strong>
                    </label>
                    <div className="form-group col-md-3">
                      <InputMask
                        id="jdDate"
                        mask="99/99/9999"
                        maskChar="_"
                        className={
                          state.jdDateError
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholder="MM/DD/YYYY"
                        value={state.jdDate}
                        onBlur={onBlur}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                      {state.jdDateError && (
                        <div id="jdDateError" className="invalid-feedback">
                          {state.jdDateErrorText}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className="custom-control custom-checkbox">
                  <input
                    id="pfs"
                    type="checkbox"
                    checked={state.pfs === true}
                    className={
                      state.professionalDesignationError
                        ? "custom-control-input is-invalid"
                        : "custom-control-input"
                    }
                    onChange={handleCheck}
                  />
                  <label className="custom-control-label" htmlFor="pfs">
                    <strong>Personal Financial Specialist (PFS)</strong>
                  </label>
                </div>
                {state.pfs === true && (
                  <div className="row date-wrapper">
                    <label>
                      <strong>Date attained</strong>
                    </label>
                    <div className="form-group col-md-3">
                      <InputMask
                        id="pfsDate"
                        mask="99/99/9999"
                        maskChar="_"
                        className={
                          state.pfsDateError
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholder="MM/DD/YYYY"
                        value={state.pfsDate}
                        onBlur={onBlur}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                      {state.pfsDateError && (
                        <div id="pfsDateError" className="invalid-feedback">
                          {state.pfsDateErrorText}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className="custom-control custom-checkbox">
                  <input
                    id="cfa"
                    type="checkbox"
                    checked={state.cfa === true}
                    className={
                      state.professionalDesignationError
                        ? "custom-control-input is-invalid"
                        : "custom-control-input"
                    }
                    onChange={handleCheck}
                  />
                  <label className="custom-control-label" htmlFor="cfa">
                    <strong>Chartered Financial Analyst (CFA)</strong>
                  </label>
                </div>
                {state.cfa === true && (
                  <div className="row date-wrapper">
                    <label>
                      <strong>Date attained</strong>
                    </label>
                    <div className="form-group col-md-3">
                      <InputMask
                        id="cfaDate"
                        mask="99/99/9999"
                        maskChar="_"
                        className={
                          state.cfaDateError
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholder="MM/DD/YYYY"
                        value={state.cfaDate}
                        onBlur={onBlur}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                      {state.cfaDateError && (
                        <div id="cfaDateError" className="invalid-feedback">
                          {state.cfaDateErrorText}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {state.professionalDesignationError && (
                  <div
                    id="professionalDesignationError"
                    className="invalid-feedback"
                  >
                    Please choose a response
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      <NavigationButtons
        onBack={handleShowBackModal}
        onNext={handleNext}
        isLoading={showSaveLoader}
        nxtbtnid={"nxtedubtn"}
        bckbtnid={"edubckbtn"}
      />
      <BackModal
        isOpen={state.showBackModal}
        closeBackModal={handleCloseBackModal}
        backClick={handleBackClick}
      />
    </div>
  );
};

export default ProfessionalDesignationsForm;
