import { RequestMethodTypes } from "../helpers/constants";
import { fetchWrapper } from "../helpers";

/**
 * GET /v1/candidate/{oktaSubId}/ccb14:
 * @param {string} oktaSubId oktaSubId
 * @returns {Promise<Object>}
 */
export const getCCB14 = async ({ oktaSubId }) => {
  const ccb = await fetchWrapper({
    method: RequestMethodTypes.GET,
    url: `${oktaSubId}/ccb14`,
  });

  return ccb.data;
};

/**
 * POST /v1/candidate/{oktaSubId}/ccb14:
 * @param {string} oktaSubId oktaSubId
 * @param {Object} data Body Object
 * @returns {Promise<void>}
 */
export const insertCCB14 = ({ oktaSubId, data }) =>
  fetchWrapper({
    method: RequestMethodTypes.POST,
    url: `${oktaSubId}/ccb14`,
    data,
  });

/**
 * PUT /v1/candidate/{oktaSubId}/ccb14:
 * @param {string} oktaSubId oktaSubId
 * @returns {Promise<Object>}
 */
export const updateCCB14 = async ({ oktaSubId, data }) => {
  await fetchWrapper({
    method: RequestMethodTypes.PUT,
    url: `${oktaSubId}/ccb14`,
    data: data,
  });
};

/**
 * PUT /v1/candidate/{oktaSubId}/ccb14/status:
 * @param {string} oktaSubId oktaSubId
 * @returns {Promise<Object>}
 */
export const updateCCB14Status = async ({ oktaSubId, data }) => {
  await fetchWrapper({
    method: RequestMethodTypes.PUT,
    url: `${oktaSubId}/ccb14/status`,
    data: data,
  });
};

/**
 * DELETE /v1/candidate/{oktaSubId}/ccb14:
 * @param {string} oktaSubId oktaSubId
 * @returns {Promise<Object>}
 */
export const deleteCCB14 = ({ oktaSubId }) =>
  fetchWrapper({
    method: RequestMethodTypes.DELETE,
    url: `${oktaSubId}/ccb14`,
  });
