import React from "react";
import NotificationModal from "../Forms/NotificationModal";
import { Button } from "react-blueprint-library";
import "../StartApplication.scss";
import { putReleaseInfomation } from "../../../api/makeAPIRequest";
import { FORM_ID } from "../../../constants/Forms";
import { UtagTracking } from "../../../utils/utag-tracking";
import { CONTRACT_TYPE_DESCRIPTIONS } from "../../../constants/Contract";
import BackButtonModal from "../Forms/BackButtonModal";

class ReleaseInformationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initialState();
    this.handleReleaseChange = this.handleReleaseChange.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleExitApplication = this.handleExitApplication.bind(this);
    this.handleShowBackModal = this.handleShowBackModal.bind(this);
    this.handleCloseBackModal = this.handleCloseBackModal.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
  }

  async componentDidMount() {
    try {
      const { releaseInfoData } = this.props;

      let releaseOfInfo =
        releaseInfoData.releaseofinfo === null
          ? ""
          : releaseInfoData.releaseofinfo;
      let candidateID =
        releaseInfoData.candidateid === null ? "" : releaseInfoData.candidateid;

      if (releaseOfInfo === true) {
        releaseOfInfo = "Y";
      } else if (releaseOfInfo === false) {
        releaseOfInfo = "N";
      }
      await this.setState({
        released: releaseOfInfo,
        candidateId: candidateID,
        is_form_saved: true,
      });
      UtagTracking.sendPageNameView(
        "releaseinformationform",
        this.props.candidateId,
        this.props.agencynumber,
        this.props.contracttypeid
      );
    } catch (err) {
      console.log(err);
    }
  }

  initialState() {
    return {
      released: "",
      showModal: false,
      candidateId: "",
      is_form_saved: false,
      showBackModal: false,
      formHasChanged: false,
    };
  }

  async handleReleaseChange(event) {
    this.setState({ is_form_saved: false, formHasChanged: true });
    this.props.hasCurrentFormChanged(true);

    if (event.target.value === "Y") {
      this.props.isFormComplete("RInfoCompleteInd", false);
    } else if (event.target.value === "N") {
      this.setState({ showModal: true });
      this.props.isFormComplete("RInfoCompleteInd", false);
    }
    //hide error stuff
    var obj_error = document.getElementById("release_error");
    obj_error.style.display = "none";
    var release_yes = document.getElementById("release_yes");
    release_yes.classList.remove("is-invalid");
    var release_no = document.getElementById("release_no");
    release_no.classList.remove("is-invalid");

    await this.setState({ released: event.target.value });
  }

  async handleNext(source) {
    if (this.state.released === "" && source !== "onload") {
      //show error and change colors of radios
      var obj_error = document.getElementById("release_error");
      obj_error.style.display = "block";
      var release_yes = document.getElementById("release_yes");
      release_yes.classList.add("is-invalid");
      var release_no = document.getElementById("release_no");
      release_no.classList.add("is-invalid");
      var label_yes = document.getElementById("label_yes");
      label_yes.style.color = "#555";
      var label_no = document.getElementById("label_no");
      label_no.style.color = "#555";
    } else if (this.state.released === "N") {
      //toss up modal
      this.setState({ showModal: true });
    } else {
      if (source !== "onload") {
        //save and move on
        let data = {
          candidateid: this.state.candidateId,
          releaseofinfo: true,
        };
        await putReleaseInfomation(this.props.subId, data);
        this.setState({ is_form_saved: true });
        this.props.hasCurrentFormChanged(false);
        this.props.isFormComplete("RInfoCompleteInd", true);
        this.props.toggleForm(FORM_ID.AUTHORIZATION);
        this.props.saveReleaseInfoAuthToWork("releaseInfoAuthToWork", {
          ...this.props.releaseInfoData,
          releaseofinfo: true,
        });
      }
    }
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  handleExitApplication() {
    this.handleCloseModal();
    window.location = "/";
  }

  handleShowBackModal() {
    //show back modal if anything on the form has changed
    if (this.state.formHasChanged) {
      this.setState({ showBackModal: true });
    } else {
      this.props.toggleForm(FORM_ID.CORPORATE_DIRECT_DEPOSIT);
    }
  }

  handleCloseBackModal() {
    this.setState({ showBackModal: false });
  }

  handleBackClick() {
    this.setState({ formHasChanged: false });
    this.handleCloseBackModal();
    this.props.toggleForm(FORM_ID.CORPORATE_DIRECT_DEPOSIT);
  }

  render() {
    return (
      <>
        {(this.props.contractType === CONTRACT_TYPE_DESCRIPTIONS.BROKER_CORP ||
          this.props.contractType ===
            CONTRACT_TYPE_DESCRIPTIONS.MEMBER_CORP) && (
          <div className="row">
            <div className="col-md-12">
              <div className="eyebrow">
                <strong>Member of Corporation</strong>
              </div>
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-md-12">
            <h1 id="release_title">Release of Information</h1>
          </div>
        </div>
        <div className="row">
          <div id="release_req" className="col-md-12">
            <span className="required-field-text">* = Required Field</span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12" style={{ paddingTop: "15px" }}>
            <h3 id="release_text_header">
              As part of this application review process, MassMutual (“we”) will
              be requesting a consumer report on you.*
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <label id="release_text_info" className="authQuestions">
              The report may provide information about your background,
              including, but not limited to, your character, general reputation,
              personal characteristics, or mode of living. The report may also
              verify information that you provide in this application,
              including, but not limited to, your employment, criminal, credit
              and education history. By selecting the “I Accept” button below
              and clicking the “Next” button, you consent to a consumer report
              being requested on you, and you authorize MassMutual to share such
              consumer report with the firm at which you are seeking to contract
              with.
              <br />
              <br />
              In addition, and if applicable, we may avail ourselves of
              information about you that is available to the public (e.g.,
              information on the FINRA’s website). Information we may gather
              from FINRA will not result in a notification to your current firm.
              <br />
              <br />
            </label>
            <div className="custom-control custom-radio">
              <input
                type="radio"
                id="release_yes"
                name="released"
                className="custom-control-input"
                checked={this.state.released === "Y"}
                value="Y"
                onChange={this.handleReleaseChange}
              />
              <label
                htmlFor="release_yes"
                id="label_yes"
                className="custom-control-label"
              >
                I Accept
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                type="radio"
                id="release_no"
                name="released"
                className="custom-control-input"
                checked={this.state.released === "N"}
                value="N"
                onChange={this.handleReleaseChange}
              />
              <label
                htmlFor="release_no"
                className="custom-control-label"
                id="label_no"
              >
                I Decline
              </label>
            </div>
            <div id="release_error" className="invalid-feedback">
              Please choose a response.
            </div>
            <div className="row">
              <div className="col-md-12">
                <label id="release_text_consent" className="authQuestions">
                  <strong> Please note: </strong>
                  You will not be able to proceed with this application if you
                  do not consent to the consumer report described above. If you
                  have any questions, please contact your Agency Licensing
                  Coordinator or Agency Supervisory Officer. You have the right
                  to receive a copy of your consumer report from Business
                  Information Group, Inc. (“BIG”). Please contact BIG at
                  1-800-369-2612 for more information.
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div
            className="col-md-12"
            style={{ textAlign: "right", paddingTop: "30px" }}
          >
            {this.props.contractType ===
              CONTRACT_TYPE_DESCRIPTIONS.BROKER_CORP && (
              <span
                id="dd_back"
                onClick={() => {
                  this.handleShowBackModal();
                }}
                style={{
                  marginRight: "25px",
                  color: "#3174af",
                  cursor: "pointer",
                }}
              >
                <strong> Back </strong>
              </span>
            )}
            <Button
              id="release_next"
              style={{ marginRight: "10px" }}
              onClick={() => {
                this.handleNext();
              }}
            >
              Next
            </Button>
          </div>
        </div>

        <NotificationModal
          isOpen={this.state.showModal}
          closeModal={this.handleCloseModal}
          exitApplication={this.handleExitApplication}
        />

        <BackButtonModal
          isOpen={this.state.showBackModal}
          closeBackModal={this.handleCloseBackModal}
          backClick={this.handleBackClick}
        />
      </>
    );
  }
}

export default ReleaseInformationForm;
