import { convertGroupKey } from "../../helpers";

export const headerText = {
  HEADER: "Criminal Disclosure",
  IND_CRIM_DISCL: "Individual Criminal Disclosure",
  ORG_CRIM_DISCL: "Organization Criminal Disclosure",
};

export const orgDetails = {
  DESCRIPTION: `Use this DRP to report all charges arising out of the same event.
  One event may result in more than one affirmative answer to the
  below items. Multiple counts of the same charge arising out of the
  same event should be reported on the same DRP. Unrelated criminal
  actions, including separate cases arising out of the same event,
  must be reported on separate DRPs.`,
};

export const initialState = {
  [convertGroupKey(headerText.ORG_CRIM_DISCL)]: {
    orgDetails: null,
    courtDetails: null,
    eventStatus: null,
    eventDispDetails: null,
    comments: null,
  },
  [convertGroupKey(headerText.IND_CRIM_DISCL)]: {
    courtDetails: null,
    eventStatus: null,
    eventDispDetails: null,
    comments: null,
  },
};

export const CCB_FORM_ID = {
  CRIMINAL_DISCLOSURE: 1,
  DRP_SUMMARY: 2,
  ADD_DRP_ORG_DETAILS: 3,
  ADD_DRP_ORG_COURT_DETAILS: 4,
  ADD_DRP_ORG_EVENT_STAT: 5,
  ADD_DRP_ORG_EVENT_DISP_DETAILS: 6,
  ADD_DRP_ORG_COMMENTS: 7,
  ADD_DRP_IND_COURT_DETAILS: 8,
  ADD_DRP_IND_EVENT_STAT: 9,
  ADD_DRP_IND_EVENT_DISP_DETAILS: 10,
  ADD_DRP_IND_COMMENTS: 11,
};
