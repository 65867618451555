/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button } from "react-blueprint-library";
import BackButtonModal from "../../../../../../Forms/BackButtonModal";
import DRPCardNoButton from "../../../../common/DRPCard/DRPCardNoButton";
import DRPSummaryListingCardCheck from "../../../../common/DRPSummaryListingCard";
import { DRPSummaryOrganizationBankruptcy } from "../../../state";
import {
  getDRPSummaryQuestions,
  getSpecificObjByKeys,
} from "../../../../helpers";
import { useSelector } from "react-redux";
import { KEYS } from "../../../../constants";
//import "./BankruptcyDisclosureOrgDetails.scss";
import { useDispatch } from "react-redux";
import { logoutActions } from "../../../../../../../../store/actions";

const investOptions = {
  YES: "Yes",
  NO: "No",
};

const BankruptcyDisclosureOrgDetails = ({
  updateCCBForm,
  nextFormId,
  backFormId,
  updateTempBankruptcyDRP,
  tempBankruptcyDRP,
  isOrg,
  setIsOrg,
}) => {
  const dispatch = useDispatch();
  const [drpList, setDrpList] = useState([]);
  const [orgName, setOrgName] = useState("");
  const [orgNameError, setOrgNameError] = useState(false);
  const [orgPrtName, setOrgPrtName] = useState("");
  const [orgPrtNameError, setOrgPrtNameError] = useState(false);
  const [orgInvestType, setOrgInvestType] = useState("");
  const [orgInvestTypeError, setOrgInvestTypeError] = useState(false);
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [showBackModal, toggleShowBackModal] = useState(false);
  const [currentFormChanged, hasCurrentFormChanged] = useState(false);

  const { data } = useSelector((state) => state.ccb14);

  const bankruptcyDisclosureObj = getSpecificObjByKeys(
    data,
    KEYS.organizationBankruptcy
  );

  const bankDiscl = getDRPSummaryQuestions(
    DRPSummaryOrganizationBankruptcy,
    bankruptcyDisclosureObj
  );

  const handleToggleBackModal = (value) => {
    if (currentFormChanged) {
      toggleShowBackModal(value);
    } else {
      handleBack();
    }
  };
  const handleNext = () => {
    setIsOrg(true);
    const tmpDRP = {
      ...tempBankruptcyDRP,
      drpList: drpList,
      orgName: orgName,
      orgPrtName: orgPrtName,
      orgInvestType: orgInvestType,
    };
    updateTempBankruptcyDRP(tmpDRP);
    updateCCBForm(nextFormId);
    hasCurrentFormChanged(false);
  };
  const handleBack = () => {
    updateCCBForm(backFormId);
  };
  const handleChange = ({ target: { id, value } }) => {
    hasCurrentFormChanged(true);
    switch (id) {
      case "orgName":
        setOrgName(value);

        break;
      case "orgPrtName":
        setOrgPrtName(value);

        break;
      default:
        break;
    }
  };

  const handleCheck = ({ target: { value } }) => {
    hasCurrentFormChanged(true);
    if (drpList.includes(value)) {
      setDrpList(drpList.filter((e) => e !== value));
    } else {
      setDrpList([...drpList, value]);
    }
  };

  const handleInvestTypeChange = ({ target: { value } }) => {
    hasCurrentFormChanged(true);
    setOrgInvestType(value);
  };

  const onBlur = ({ target: { id, value } }) => {
    switch (id) {
      case "orgName":
        setOrgNameError(value.trim().length < 2);
        break;
      case "orgPrtName":
        setOrgPrtNameError(value.trim().length < 2);
        break;
      default:
        break;
    }
  };

  const allFilled = () => {
    return (
      drpList.length > 0 &&
      orgName.length > 1 &&
      orgNameError === false &&
      orgPrtName.length > 1 &&
      orgPrtNameError === false &&
      orgInvestType !== ""
    );
  };
  const noErrors = () => {
    return !orgNameError && !orgPrtNameError && !orgInvestTypeError;
  };

  useEffect(() => {
    setIsFormComplete(allFilled() && noErrors());
  });
  useEffect(() => {
    if (bankDiscl.length && bankDiscl.length < 2) {
      setDrpList([bankDiscl[0].key]);
    }

    if (
      tempBankruptcyDRP?.orgName &&
      tempBankruptcyDRP?.orgPrtName &&
      tempBankruptcyDRP?.orgInvestType
    ) {
      setDrpList(tempBankruptcyDRP?.drpList);
      setOrgName(tempBankruptcyDRP?.orgName);
      setOrgPrtName(tempBankruptcyDRP?.orgPrtName);
      setOrgInvestType(tempBankruptcyDRP?.orgInvestType);
      setIsFormComplete(true);
      hasCurrentFormChanged(true);
      dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: true }));
    } else {
      hasCurrentFormChanged(false);
      dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: false }));
    }
  }, []);
  useEffect(() => {
    if (currentFormChanged || !noErrors()) {
      dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: true }));
      hasCurrentFormChanged(true);
    } else {
      dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: false }));
    }
  }, [currentFormChanged, noErrors(), dispatch]);
  return (
    <div className="bankruptcy-form-wrapper">
      <br />
      <div className="term-disclosure-drp-summary">
        <div className="row">
          <div
            style={{ paddingBottom: "20px" }}
            className="col-md-12 info-label"
            id="orgBankruptcyHeaderEventsText"
          >
            If events result in affirmative answers to both 14K(1) and 14K(2),
            details to each must be provided on separate DRPs.
          </div>
        </div>
        {bankDiscl.length && bankDiscl.length > 1 ? (
          <DRPCardNoButton
            header={"Check the question(s) you are responding to:"}
            isDrp
          >
            {bankDiscl.map(({ key, mainQuestion, question }, index) => (
              <DRPSummaryListingCardCheck
                key={`${index}_${key}`}
                questionKey={key}
                mainQuestion={mainQuestion}
                question={question}
                index={index}
                length={bankDiscl.length}
                handleChange={handleCheck}
                isCheckbox
                drpCheckList={drpList}
              />
            ))}
          </DRPCardNoButton>
        ) : null}
      </div>
      <div className="row">
        <div style={{ paddingBottom: "20px" }} className="col-md-12 info-label">
          <strong id="orgBankruptcyChargesText">
            If charge(s) were brought against an organization over which you
            exercise(d) control:
          </strong>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <label id="orgNameLabel" className="field-label" htmlFor="orgName">
            Organization Name *
          </label>
          <input
            style={{ width: "50%" }}
            id="orgName"
            type="text"
            className={
              orgNameError ? "form-control is-invalid" : "form-control"
            }
            onChange={handleChange}
            onBlur={onBlur}
            value={orgName}
            maxLength={200}
            minLength={2}
            autoComplete="off"
            placeholder="Name of organization"
          ></input>
          <div
            id="orgNameError"
            className="invalid-feedback"
            style={{ marginBottom: "20px" }}
          >
            Please enter Organization Name
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <label id="actionTypeLabelId" className="field-label">
            Investment related business? *
          </label>
        </div>
      </div>
      <div className="row">
        <div className="drp-radio-input-wrapper">
          <div className="col-12 copy-md-4 form-group">
            <div className="custom-control custom-radio">
              <input
                name="orgInvestTypeYes"
                type="radio"
                id="orgInvestTypeYes"
                className="custom-control-input"
                value={investOptions.YES}
                checked={orgInvestType === investOptions.YES}
                onChange={handleInvestTypeChange}
              />
              <label
                id="orgInvestTypeYesLabelId"
                htmlFor="orgInvestTypeYes"
                className="custom-control-label"
              >
                {investOptions.YES}
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                name="orgInvestTypeNo"
                type="radio"
                id="orgInvestTypeNo"
                className="custom-control-input"
                value={investOptions.NO}
                checked={orgInvestType === investOptions.NO}
                onChange={handleInvestTypeChange}
              />
              <label
                id="orgInvestTypeNoLabelId"
                htmlFor="orgInvestTypeNo"
                className="custom-control-label"
              >
                {investOptions.NO}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <label
            id="orgPrtNameLabel"
            className="field-label"
            htmlFor="orgPrtName"
          >
            Position, Title, or Relationship *
          </label>
          <input
            style={{ width: "30%" }}
            id="orgPrtName"
            type="text"
            className={
              orgPrtNameError ? "form-control is-invalid" : "form-control"
            }
            onChange={handleChange}
            onBlur={onBlur}
            value={orgPrtName}
            maxLength={200}
            minLength={2}
            autoComplete="off"
          ></input>
          <div id="orgPrtNameError" className="invalid-feedback">
            Please enter Position, Title, or Relationship
          </div>
        </div>
      </div>
      <div className="row backbutton-drp-bankruptcy">
        <div className="col-md-12 buttons">
          <span
            id="bankruptcyAddDRPBack"
            className="back-button"
            onClick={() => {
              handleToggleBackModal(true);
            }}
          >
            <strong id="bankruptcyDisclBack">Back</strong>
          </span>
          <Button
            disabled={!isFormComplete}
            id="bankruptcyAddDRPNext"
            style={{ marginRight: "10px" }}
            onClick={handleNext}
          >
            Next
          </Button>
        </div>
      </div>
      <BackButtonModal
        isOpen={showBackModal}
        closeBackModal={() => {
          handleToggleBackModal(false);
        }}
        backClick={handleBack}
      />
    </div>
  );
};
export default BankruptcyDisclosureOrgDetails;
