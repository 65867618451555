import { RequestMethodTypes } from "./helpers/constants";
import { fetchWrapper } from "./helpers";

// Dashboard/Landing Page data
export async function getDashboard(subId) {
  const url = `${subId}/dashboard`;

  const dashboardData = await fetchWrapper({
    method: RequestMethodTypes.GET,
    url,
  });

  return dashboardData?.data[0];
}

export async function getStartAppDashboard(subId, contractnumber) {
  const url = `${subId}/dashboard`;
  const { data: dashboardData } = await fetchWrapper({
    method: RequestMethodTypes.GET,
    url,
  });

  const filteredDashboard = dashboardData?.filter(
    ({ contractid }) => contractid === contractnumber
  )[0];

  return filteredDashboard;
}

// Multiple contracts
export async function getDashboardMultiContract(subId) {
  const url = `${subId}/dashboard`;
  const dashboardData = await fetchWrapper({
    method: RequestMethodTypes.GET,
    url,
  });

  return dashboardData?.data;
}

export async function getContractNumber(subId) {
  const url = `${subId}/dashboard`;
  const dashboardData = await fetchWrapper({
    method: RequestMethodTypes.GET,
    url,
  });

  return dashboardData?.data[0]?.contractid;
}

// Release Information
export async function getReleaseInformation(subId) {
  const url = `${subId}/authtowork`;
  const authToWorkData = await fetchWrapper({
    method: RequestMethodTypes.GET,
    url,
  });

  return authToWorkData?.data[0];
}

export async function putReleaseInfomation(oktasubid, data) {
  const url = `${oktasubid}/releaseinfo`;

  await fetchWrapper({
    method: RequestMethodTypes.PUT,
    url,
    data,
  });
}

// Authorization to Work
export async function getAuthorizationToWork(subId) {
  const url = `${subId}/authtowork`;
  const authToWorkData = await fetchWrapper({
    method: RequestMethodTypes.GET,
    url,
  });

  return authToWorkData?.data[0];
}

export async function putAuthorizationToWork(oktasubid, data) {
  const url = `${oktasubid}/authtowork`;

  await fetchWrapper({
    method: RequestMethodTypes.PUT,
    url,
    data,
  });
}

// Personal Information Opportunites Dropdown
export async function getPersonalInfoOpportunities() {
  const url = `opportunities`;
  const opportunities = await fetchWrapper({
    method: RequestMethodTypes.GET,
    url,
  });

  return opportunities?.data;
}

// Personal Information
export async function getPersonalInformation(subId) {
  const url = `${subId}/personalinformation`;
  const personalInformationData = await fetchWrapper({
    method: RequestMethodTypes.GET,
    url,
  });

  return personalInformationData?.data[0];
}

export async function putPersonalInformation(oktasubid, data) {
  const url = `${oktasubid}/personalinformation`;
  const putPersonalres = await fetchWrapper({
    method: RequestMethodTypes.PUT,
    url,
    data,
  });
  return putPersonalres?.data;
}

// Business information
export async function getBusinessInfo(subId) {
  const url = `${subId}/busaddress`;
  const businessInfoData = await fetchWrapper({
    method: RequestMethodTypes.GET,
    url,
  });
  return businessInfoData?.data[0];
}

export async function putBusinessAddress(oktaId, data) {
  const url = `${oktaId}/busaddress`;

  await fetchWrapper({
    method: RequestMethodTypes.PUT,
    url,
    data,
  });
}

// Residential Address
export async function getResidentialAddress(subId) {
  const url = `${subId}/resaddress`;
  const residentialAddressData = await fetchWrapper({
    method: RequestMethodTypes.GET,
    url,
  });

  return residentialAddressData?.data[0];
}

export async function putResidentialAddress(oktasubid, data) {
  const url = `${oktasubid}/resaddress`;

  await fetchWrapper({
    method: RequestMethodTypes.PUT,
    url,
    data,
  });
}

// Broker Dealer Information
export async function getBrokerDealerInfo(subId) {
  const url = `${subId}/brokerdealerinfo`;
  const brokerDealerData = await fetchWrapper({
    method: RequestMethodTypes.GET,
    url,
  });

  return brokerDealerData?.data[0];
}

export async function putBrokerDealerInfo(oktaSubId, data) {
  const url = `${oktaSubId}/brokerdealerinfo`;

  await fetchWrapper({
    method: RequestMethodTypes.PUT,
    url,
    data,
  });
}

// Character Credit and Bonding
export async function getCCBInfo(subId) {
  const url = `${subId}/ccb`;
  const ccbInfo = await fetchWrapper({
    method: RequestMethodTypes.GET,
    url,
  });

  return ccbInfo?.data?.length === 0
    ? {
        convictcrime: null,
        failedbusiness: null,
        invlawsuit: null,
        licsuspend: null,
      }
    : ccbInfo?.data[0];
}

export async function putCCBInfo(oktasubid, data) {
  const url = `${oktasubid}/ccb`;

  await fetchWrapper({
    method: RequestMethodTypes.PUT,
    url,
    data,
  });
}

// Errors and Omissions
export async function getErrorsAndOmissions(subId) {
  const url = `${subId}/eocoverage`;

  const eoData = await fetchWrapper({
    method: RequestMethodTypes.GET,
    url,
  });

  return eoData?.data[0];
}

export async function putErrorsAndOmissions(oktasubid, data) {
  const url = `${oktasubid}/eocoverage`;

  await fetchWrapper({
    method: RequestMethodTypes.PUT,
    url,
    data,
  });
}

// Direct Deposit Calls
export async function getDirectDepositInfo(subId) {
  const url = `${subId}/directdeposit`;
  const directDepositInfo = await fetchWrapper({
    method: RequestMethodTypes.GET,
    url,
  });

  return directDepositInfo?.data[0];
}

export async function putDirectDepositInfo(oktasubid, data) {
  const url = `${oktasubid}/directdeposit`;

  await fetchWrapper({
    method: RequestMethodTypes.PUT,
    url,
    data,
  });
}

export async function getBankRoutingInfo(routingNumber) {
  const data = {
    bankrouting: routingNumber,
  };

  const bankRoute = await fetchWrapper({
    method: RequestMethodTypes.POST,
    url: `bankrouting`,
    data,
  });

  return bankRoute?.data;
}

// status update
export async function updateContractStatus(
  oktasubid,
  contractid,
  candidateemail,
  newstatusid
) {
  const url = `${oktasubid}/updateStatus`;

  const data = {
    contractid: contractid,
    contractstatusid: newstatusid,
    changeorigin: "Candidate",
    changerid: candidateemail,
  };

  await fetchWrapper({
    method: RequestMethodTypes.PUT,
    url,
    isContractURL: true,
    data,
  });
}

export async function getCoversheetData(oktaSubId, contractid) {
  const url = `${oktaSubId}/coverpagedata`;

  const { data: coversheetData } = await fetchWrapper({
    method: RequestMethodTypes.POST,
    url,
    isContractURL: true,
    data: {
      contractid: contractid,
    },
  });

  return coversheetData;
}

export async function saveContract(
  oktasubid,
  contractid,
  corporationname = ""
) {
  const { contracttypedesc, agencycode, gasignature } = await getCoversheetData(
    oktasubid,
    contractid
  );

  const data = {
    oktasubid,
    contracttypedesc,
    agencyid: agencycode,
    gasignature,
    contractid,
    corporationname,
  };

  const url = `save/contractwcoversheet`;

  await fetchWrapper({
    method: RequestMethodTypes.POST,
    isContractURL: true,
    url,
    data,
  });
}

export async function saveBIGFICRADisclosure(oktasubid, contractid) {
  const data = {
    oktasubid,
    contractid,
  };
  const url = `save/bigficradisclosure`;

  await fetchWrapper({
    method: RequestMethodTypes.POST,
    isContractURL: true,
    url,
    data,
  });
}

export async function saveFastCashForm(oktaSubId) {
  let directDepositInfo = await getDirectDepositInfo(oktaSubId);
  const personalInfo = await getPersonalInformation(oktaSubId);
  const bankname1 = await getBankRoutingInfo(
    directDepositInfo?.mmroutingnumber
  );

  directDepositInfo["mmbankname"] = bankname1?.agreements[0]?.bankName;

  let bankname2;

  if (directDepositInfo?.compensationtype === 2) {
    bankname2 = await getBankRoutingInfo(directDepositInfo?.brokroutingnumber);
    directDepositInfo["brokbankname"] = bankname2?.agreements[0]?.bankName;
  }

  const data = {
    direct_deposit_info: directDepositInfo,
    personal_info: personalInfo,
  };

  const url = `${oktaSubId}/fastcashform`;

  await fetchWrapper({
    method: RequestMethodTypes.POST,
    isContractURL: true,
    url,
    data,
  });
}

export async function saveFastCashFormBrokerCorp(oktaSubId, corpid) {
  const corporation = {
    corpid: corpid,
  };

  //get corporation direct deposit info
  let directDepositInfo = await getCorporationDD(oktaSubId, corporation);
  //get corporation info
  const corporationInfo = await getCorporation(oktaSubId, corporation);
  //get member of corp personal info
  const personalInfo = await getPersonalInformation(oktaSubId);

  let bankname1 = await getBankRoutingInfo(directDepositInfo?.mmroutingnumber);
  directDepositInfo["mmbankname"] = bankname1?.agreements[0]?.bankName;

  let bankname2;

  if (directDepositInfo.compensationtypeid === 2) {
    bankname2 = await getBankRoutingInfo(directDepositInfo?.corproutingnumber);
    directDepositInfo["corpbankname"] = bankname2?.agreements[0]?.bankName;
  }

  const data = {
    direct_deposit_info: directDepositInfo,
    corporation_info: corporationInfo,
    personal_info: personalInfo,
  };

  const url = `${oktaSubId}/fastcashformbrokercorp`;

  await fetchWrapper({
    method: RequestMethodTypes.POST,
    isContractURL: true,
    url,
    data,
  });
}

export async function getContract(oktaSubId, contractid) {
  const url = `${oktaSubId}/contractwcoversheet`;

  const { data: pdfData } = await fetchWrapper({
    method: RequestMethodTypes.POST,
    isContractURL: true,
    url,
    responseType: "arraybuffer",
    data: { contractid },
  });

  return pdfData;
}

export async function getMMLISAddOnContract(oktaSubId, contractid) {
  const url = `${oktaSubId}/mmlisaddoncontract`;

  const { data: pdfData } = await fetchWrapper({
    method: RequestMethodTypes.POST,
    isContractURL: true,
    url,
    responseType: "arraybuffer",
    data: { contractid },
  });

  return pdfData;
}

export async function getBlankContract(contractType) {
  const url = `blankcontractwcover`;

  const pdfData = await fetchWrapper({
    method: RequestMethodTypes.POST,
    isContractURL: true,
    isPDF: true,
    url,
    responseType: "arraybuffer",
    data: { contracttype: contractType },
  });

  return pdfData?.data;
}

export async function getBlankDisclosure() {
  const url = `bigficradisclosure/blankdisclosure`;

  const pdfData = await fetchWrapper({
    method: RequestMethodTypes.GET,
    isContractURL: true,
    isPDF: true,
    url,
    responseType: "arraybuffer",
  });

  return pdfData?.data;
}

export async function getBIGFICRADisclosure(oktaSubId, contractid) {
  const url = `${oktaSubId}/bigficradisclosure`;

  const { data: pdfData } = await fetchWrapper({
    method: RequestMethodTypes.POST,
    isContractURL: true,
    url,
    responseType: "arraybuffer",
    data: {
      contractid,
    },
  });

  return pdfData;
}

// UBIQ Testing
export async function getUbiqInformation(id) {
  const url = `${id}/basicdemographic`;

  let ubiqInformationData = await fetchWrapper({
    method: RequestMethodTypes.GET,
    isUBIQ: true,
    url,
  });

  return ubiqInformationData?.data;
}

export async function putUbiqInformation(data) {
  const ubiqInformationData = await fetchWrapper({
    method: RequestMethodTypes.PUT,
    isUBIQ: true,
    url: "insertnew",
    data,
  });

  return ubiqInformationData?.data;
}

//e-signature information
export async function putESignatureInformation(oktasubid, data) {
  const url = `${oktasubid}/esignature`;

  await fetchWrapper({
    method: RequestMethodTypes.PUT,
    url,
    isContractURL: true,
    data,
  });
}

//employment history information
export async function insertEmploymentHistoryInformation(candidateId, data) {
  const url = `${candidateId}/employmenthistory`;

  const result = await fetchWrapper({
    method: RequestMethodTypes.POST,
    url,
    data,
  });

  return result;
}
export async function insertEmploymentBreakHistoryInformation(
  candidateId,
  data
) {
  const url = `${candidateId}/employmentbreakhistory`;

  const result = await fetchWrapper({
    method: RequestMethodTypes.POST,
    url,
    data,
  });

  return result;
}
export async function deleteEmploymentHistoryInformation(
  candidateId,
  empHistId
) {
  const url = `${candidateId}/employmenthistory/${empHistId}`;
  const result = await fetchWrapper({
    method: RequestMethodTypes.DELETE,
    url,
  });

  return result;
}
export async function deleteEmploymentBreakHistoryInformation(
  candidateId,
  empHistBreakId
) {
  const url = `${candidateId}/employmentbreakhistory/${empHistBreakId}`;
  const result = await fetchWrapper({
    method: RequestMethodTypes.DELETE,
    url,
  });

  return result;
}
export async function updateEmploymentHistoryInformation(
  oktaSubId,
  empHistId,
  data
) {
  const url = `${oktaSubId}/employmenthistory/${empHistId}`;
  const result = await fetchWrapper({
    method: RequestMethodTypes.PUT,
    url,
    data,
  });

  return result;
}

export async function updateEmploymentBreakHistoryInformation(
  candidateId,
  empHistId,
  data
) {
  const url = `${candidateId}/employmentbreakhistory/${empHistId}`;
  const result = await fetchWrapper({
    method: RequestMethodTypes.PUT,
    url,
    data,
  });

  return result;
}

export async function getEmploymentHistoryInformation(oktasubId, historyId) {
  const url = `${oktasubId}/employmenthistory/${historyId}`;
  const empHistInfo = await fetchWrapper({
    method: RequestMethodTypes.GET,
    url,
  });

  return empHistInfo?.data;
}

export async function getEmploymentBreakHistoryInformation(
  oktasubId,
  historyId
) {
  const url = `${oktasubId}/employmentbreakhistory/${historyId}`;
  const empHistInfo = await fetchWrapper({
    method: RequestMethodTypes.GET,
    url,
  });

  return empHistInfo?.data;
}

export async function getCombinedEmploymentHistoryInformation(subId) {
  const url = `${subId}/employmenthistorybreaks`;
  const empHistInfo = await fetchWrapper({
    method: RequestMethodTypes.GET,
    url,
  });

  return empHistInfo?.data;
}

export async function getPSREmploymentHistoryInformation(oktasubId) {
  const url = `${oktasubId}/psremploymenthistory`;
  const psrEmpHistInfo = await fetchWrapper({
    method: RequestMethodTypes.GET,
    url,
  });

  return psrEmpHistInfo?.data;
}

export async function insertPSRRecord(oktasubid, employmentHistoryId, data) {
  const url = `${oktasubid}/personalsalesrecords/employmenthistory/${employmentHistoryId}`;
  const result = await fetchWrapper({
    method: RequestMethodTypes.POST,
    url,
    data,
  });

  return result;
}

export async function getPSRRecord(oktasubId, psrId) {
  const url = `${oktasubId}/personalsalesrecords/${psrId}`;
  const empHistInfo = await fetchWrapper({
    method: RequestMethodTypes.GET,
    url,
  });

  return empHistInfo?.data;
}

export async function updatePSRRecord(oktaSubId, psrId, data) {
  const url = `${oktaSubId}/personalsalesrecords/${psrId}`;
  const result = await fetchWrapper({
    method: RequestMethodTypes.PUT,
    url,
    data,
  });

  return result;
}

export async function updateInternEducation(oktasubid, data) {
  const url = `${oktasubid}/interneducation`;
  const result = await fetchWrapper({
    method: RequestMethodTypes.PUT,
    url,
    data,
  });

  return result;
}

export async function getInternEducation(oktasubId) {
  const url = `${oktasubId}/interneducation`;
  const result = await fetchWrapper({
    method: RequestMethodTypes.GET,
    url,
  });

  return result?.data;
}

export async function updateCandidateEducation(oktasubid, data) {
  const url = `${oktasubid}/education`;
  const result = await fetchWrapper({
    method: RequestMethodTypes.PUT,
    url,
    data,
  });

  return result;
}
export async function getCandidateEducation(oktasubId) {
  const url = `${oktasubId}/education`;
  const result = await fetchWrapper({
    method: RequestMethodTypes.GET,
    url,
  });

  return result?.data;
}

export async function getEducationDegrees() {
  const url = `educationdegree`;

  const degrees = await fetchWrapper({
    method: RequestMethodTypes.GET,
    isContractURL: true,
    url,
  });

  return degrees?.data;
}
export async function getEducationLevels() {
  const url = `educationlevel`;

  const levels = await fetchWrapper({
    method: RequestMethodTypes.GET,
    isContractURL: true,
    url,
  });

  return levels?.data;
}

export async function getProfessionalDesignations(oktasubid) {
  const url = `${oktasubid}/designations`;
  const profDes = await fetchWrapper({
    method: RequestMethodTypes.GET,
    url,
  });

  return profDes?.data;
}
export async function insertProfessionalDesignations(oktasubid, data) {
  const url = `${oktasubid}/designations`;

  await fetchWrapper({
    method: RequestMethodTypes.PUT,
    url,
    data,
  });
}

export async function deleteProfessionalDesignations(oktasubid) {
  const url = `${oktasubid}/designations`;

  await fetchWrapper({
    method: RequestMethodTypes.DELETE,
    url,
  });
}

export async function getPSRChartData(oktasubId) {
  const url = `${oktasubId}/psrchart`;

  const psrChartData = await fetchWrapper({
    method: RequestMethodTypes.GET,
    url,
  });

  return psrChartData?.data;
}

export const getAdditionalInformation = async (oktaSubId) => {
  const url = `${oktaSubId}/miscquestion`;

  const miscQuestions = await fetchWrapper({
    method: RequestMethodTypes.GET,
    url,
  });

  return miscQuestions?.data;
};

export async function insertAdditionalInformation(oktasubid, data) {
  const url = `${oktasubid}/miscquestion`;

  await fetchWrapper({
    method: RequestMethodTypes.PUT,
    url,
    data,
  });
}

export async function getSignatureWording(contracttypeid, options = {}) {
  let url = `${contracttypeid}/signaturewording`;

  // If options are provided, add them as query parameters
  const params = new URLSearchParams();
  if (options.mmlis !== undefined) {
    params.append("mmlis", options.mmlis);
  }
  if (options.finance !== undefined) {
    params.append("finance", options.finance);
  }

  if (params.toString()) {
    url += `?${params.toString()}`;
  }

  const verbiage = await fetchWrapper({
    method: RequestMethodTypes.GET,
    isContractURL: true,
    url,
  });

  return verbiage?.data;
}

export async function getEmploymentHistoryIndustries() {
  const url = `industryopts`;

  const industries = await fetchWrapper({
    method: RequestMethodTypes.GET,
    isContractURL: true,
    url,
  });

  return industries?.data;
}

export async function getCandidateCorp(oktasubid, data) {
  const url = `${oktasubid}/candidatecorpinfo`;
  const result = await fetchWrapper({
    method: RequestMethodTypes.POST,
    url,
    data,
  });

  return result?.data;
}

export async function insertCandidateCorp(oktasubid, data) {
  const url = `${oktasubid}/candidatecorp`;
  await fetchWrapper({
    method: RequestMethodTypes.POST,
    url,
    data,
  });
}

export async function getCorporation(oktasubid, data) {
  const url = `${oktasubid}/corpbrokerinfo`;
  const result = await fetchWrapper({
    method: RequestMethodTypes.POST,
    url,
    data,
  });

  return result?.data;
}

export async function insertCorporation(oktasubid, data) {
  const url = `${oktasubid}/corpbroker`;
  const result = await fetchWrapper({
    method: RequestMethodTypes.POST,
    url,
    data,
  });

  return result?.data;
}

export async function updateCorporation(oktasubid, data) {
  const url = `${oktasubid}/corpbroker`;
  const result = await fetchWrapper({
    method: RequestMethodTypes.PUT,
    url,
    data,
  });

  return result?.data;
}

export async function getCorporationCCB(oktasubid, data) {
  const url = `${oktasubid}/corpccbinfo`;
  const result = await fetchWrapper({
    method: RequestMethodTypes.POST,
    url,
    data,
  });

  return result?.data;
}

export async function insertCorporationCCB(oktasubid, data) {
  const url = `${oktasubid}/corpccb`;
  const result = await fetchWrapper({
    method: RequestMethodTypes.POST,
    url,
    data,
  });

  return result?.data;
}

export async function updateCorporationCCB(oktasubid, data) {
  const url = `${oktasubid}/corpccb`;
  let result = await fetchWrapper({
    method: RequestMethodTypes.PUT,
    url,
    data,
  });

  return result?.data;
}

export async function getCorporationEO(oktasubid, data) {
  const url = `${oktasubid}/corperrorsomissionsinfo`;
  let result = await fetchWrapper({
    method: RequestMethodTypes.POST,
    url,
    data,
  });

  return result?.data;
}

export async function insertCorporationEO(oktasubid, data) {
  const url = `${oktasubid}/corperrorsomissions`;

  const result = await fetchWrapper({
    method: RequestMethodTypes.POST,
    url,
    data,
  });

  return result?.data;
}

export async function updateCorporationEO(oktasubid, data) {
  const url = `${oktasubid}/corperrorsomissions`;
  const result = await fetchWrapper({
    method: RequestMethodTypes.PUT,
    url,
    data,
  });

  return result?.data;
}

export async function getCorporationDD(oktasubid, data) {
  const url = `${oktasubid}/corpdirectdepositinfo`;
  const result = await fetchWrapper({
    method: RequestMethodTypes.POST,
    url,
    data,
  });

  return result?.data;
}

export async function insertCorporationDD(oktasubid, data) {
  const url = `${oktasubid}/corpdirectdeposit`;
  const result = await fetchWrapper({
    method: RequestMethodTypes.POST,
    url,
    data,
  });

  return result?.data;
}

export async function updateCorporationDD(oktasubid, data) {
  const url = `${oktasubid}/corpdirectdeposit`;
  const result = await fetchWrapper({
    method: RequestMethodTypes.PUT,
    url,
    data,
  });

  return result?.data;
}

export async function getCandidateMMLIS(oktasubid) {
  const url = `${oktasubid}/mmlis`;

  const mmlis = await fetchWrapper({
    method: RequestMethodTypes.GET,
    url,
  });

  return mmlis?.data;
}

export async function insertCandidateMMLIS(oktasubid, data) {
  const url = `${oktasubid}/mmlis`;
  const result = await fetchWrapper({
    method: RequestMethodTypes.POST,
    url,
    data,
  });

  return result?.data;
}

export async function updateCandidateMMLIS(oktasubid, data) {
  const url = `${oktasubid}/mmlis`;
  const result = await fetchWrapper({
    method: RequestMethodTypes.PUT,
    url,
    data,
  });

  return result?.data;
}

export async function getMMLISManagedContributions(oktasubid) {
  const url = `${oktasubid}/mmlismanage`;

  const mmlis = await fetchWrapper({
    method: RequestMethodTypes.GET,
    url,
  });

  return mmlis?.data;
}

export async function insertMMLISManagedContribution(oktasubid, data) {
  const url = `${oktasubid}/mmlismanage`;
  const result = await fetchWrapper({
    method: RequestMethodTypes.POST,
    url,
    data,
  });

  return result?.data;
}

export async function updateMMLISManagedContribution(oktasubid, data) {
  const url = `${oktasubid}/mmlismanage`;
  const result = await fetchWrapper({
    method: RequestMethodTypes.PUT,
    url,
    data,
  });
  return result.data;
}

export async function deleteMMLISManagedContribution(oktasubid, mmlisid) {
  const url = `${oktasubid}/mmlismanage/${mmlisid}`;
  const result = await fetchWrapper({
    method: RequestMethodTypes.DELETE,
    url,
  });

  return result;
}

export async function getMMLISGeneralContributions(oktasubid) {
  const url = `${oktasubid}/mmlisgeneral`;

  const mmlis = await fetchWrapper({
    method: RequestMethodTypes.GET,
    url,
  });

  return mmlis?.data;
}

export async function insertMMLISGeneralContribution(oktasubid, data) {
  const url = `${oktasubid}/mmlisgeneral`;
  const result = await fetchWrapper({
    method: RequestMethodTypes.POST,
    url,
    data,
  });

  return result?.data;
}

export async function updateMMLISGeneralContribution(oktasubid, data) {
  const url = `${oktasubid}/mmlisgeneral`;
  const result = await fetchWrapper({
    method: RequestMethodTypes.PUT,
    url,
    data,
  });

  return result?.data;
}

export async function deleteMMLISGeneralContribution(oktasubid, mmlisid) {
  const url = `${oktasubid}/mmlisgeneral/${mmlisid}`;
  const result = await fetchWrapper({
    method: RequestMethodTypes.DELETE,
    url,
  });

  return result;
}

export async function getMMLISObaCria(oktasubid) {
  const url = `${oktasubid}/mmlisoba`;

  const mmlis = await fetchWrapper({
    method: RequestMethodTypes.GET,
    url,
  });

  return mmlis?.data;
}

export async function insertMMLISObaCria(oktasubid, data) {
  const url = `${oktasubid}/mmlisoba`;
  const result = await fetchWrapper({
    method: RequestMethodTypes.POST,
    url,
    data,
  });

  return result?.data;
}
