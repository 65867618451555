/* global google */
import { Dropdown, DropdownButton } from "react-bootstrap";
import React from "react";
import USPSValidationModal from "./USPSValidationModal";
import InputMask from "react-input-mask";
import { FormCustomControl } from "react-blueprint-library";
import BackButtonModal from "../StartApplication/Forms/BackButtonModal";
import {
  putBusinessAddress,
  putResidentialAddress,
  updateCorporation,
} from "../../api/makeAPIRequest";
import Autocomplete from "react-google-autocomplete";
import { getUSPSAddress } from "../../api/uspsAddress";
import { CONTRACT_TYPE_DESCRIPTIONS } from "../../constants/Contract";
import NavigationButtons from "./NavigationButtons";
import { UtagTracking } from "../../utils/utag-tracking";

function onlyNumeric(event) {
  if (!/[0-9\-/]/.test(event.key)) {
    event.preventDefault();
  }
}

function clickFunc(event) {
  event.target.focus();
}

class ValidationForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.initialState();
    this.state.form_id = props.id;
    this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.onFocusState = this.onFocusState.bind(this);

    this.clearForm = this.clearForm.bind(this);
    this.clearInputErrors = this.clearInputErrors.bind(this);

    this.handleUseSuggestedAddress = this.handleUseSuggestedAddress.bind(this);
    this.handleUseEnteredAddress = this.handleUseEnteredAddress.bind(this);
    this.getAddressComponents = this.getAddressComponents.bind(this);

    //loads Business and Residential Address
    this.loadBusAddress = this.loadBusAddress.bind(this);
    this.loadResAddress = this.loadResAddress.bind(this);

    //Toggle the Verification Modal
    this.handleCloseModal = this.handleCloseModal.bind(this);

    //Saves the Residential Address
    this.saveResAddress = this.saveResAddress.bind(this);
    this.saveBusAddress = this.saveBusAddress.bind(this);

    //Allows Business Address form to use Residential Address
    this.handleCheck = this.handleCheck.bind(this);
    this.useResAddress = this.useResAddress.bind(this);

    //Unchecks the navigation
    this.onKeyDown = this.onKeyDown.bind(this);

    //back button modal
    this.handleShowBackModal = this.handleShowBackModal.bind(this);
    this.handleCloseBackModal = this.handleCloseBackModal.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);

    //throw errors
    this.throwBusinessInfoError = this.throwBusinessInfoError.bind(this);
    this.throwErrors = this.throwErrors.bind(this);

    this.validateAddress = this.validateAddress.bind(this);
  }

  async loadBusAddress() {
    const busAddr = this.props.busAddress;
    const resAddr = this.props.resAddress;
    const resHistory = this.props.residentialHistory;
    const { busAddrCheck } = this.props;

    if (
      this.props.contracttypedesc ===
        CONTRACT_TYPE_DESCRIPTIONS.INDIVIDUAL_BROKER ||
      this.props.contracttypedesc === CONTRACT_TYPE_DESCRIPTIONS.MEMBER_CORP
    ) {
      if (
        busAddrCheck &&
        (busAddr.busaddress1 !== resAddr.address1 ||
          busAddr.busaddress2 !== resAddr.address2 ||
          busAddr.buscity !== resAddr.city ||
          busAddr.busstate !== resAddr.state ||
          busAddr.buszip !== resAddr.zip ||
          busAddr.busresurbanization !== resAddr.resurbanization)
      ) {
        await this.setState({
          candidate_id: busAddr.candidateid,
          autocomplete: resAddr.address1 === null ? "" : resAddr.address1,
          address2: resAddr.address2 === null ? "" : resAddr.address2,
          city: resAddr.city === null ? "" : resAddr.city,
          urbanization:
            resAddr.resurbanization === null ? "" : resAddr.resurbanization,
          state: resAddr.state === null ? "" : resAddr.state,
          zip_code: resAddr.zip === null ? "" : resAddr.zip,
          business_phone:
            busAddr.businessphone === null ? "" : busAddr.businessphone,
          business_fax: busAddr.businessfax === null ? "" : busAddr.businessfax,
        });
      } else {
        await this.setState({
          candidate_id: busAddr.candidateid,
          autocomplete: busAddr.busaddress1 === null ? "" : busAddr.busaddress1,
          address2: busAddr.busaddress2 === null ? "" : busAddr.busaddress2,
          city: busAddr.buscity === null ? "" : busAddr.buscity,
          urbanization:
            busAddr.busresurbanization === null
              ? ""
              : busAddr.busresurbanization,
          state: busAddr.busstate === null ? "" : busAddr.busstate,
          zip_code: busAddr.buszip === null ? "" : busAddr.buszip,
          business_phone:
            busAddr.businessphone === null ? "" : busAddr.businessphone,
          business_fax: busAddr.businessfax === null ? "" : busAddr.businessfax,
        });
      }

      if (
        busAddr.busaddress1 === resAddr.address1 &&
        busAddr.busaddress2 === resAddr.address2 &&
        busAddr.buscity === resAddr.city &&
        busAddr.busstate === resAddr.state &&
        busAddr.buszip === resAddr.zip &&
        busAddr.busresurbanization === resAddr.resurbanization
      ) {
        this.props.setBusAddrChecked(true);
      }
    } else if (
      this.props.contracttypedesc === CONTRACT_TYPE_DESCRIPTIONS.BROKER_CORP
    ) {
      if (this.props.addrType !== "corporate") {
        if (
          busAddrCheck &&
          (busAddr.busaddress1 !== resAddr.address1 ||
            busAddr.busaddress2 !== resAddr.address2 ||
            busAddr.buscity !== resAddr.city ||
            busAddr.busstate !== resAddr.state ||
            busAddr.buszip !== resAddr.zip ||
            busAddr.busresurbanization !== resAddr.resurbanization)
        ) {
          await this.setState({
            candidate_id: busAddr.candidateid,
            autocomplete: resAddr.address1 === null ? "" : resAddr.address1,
            address2: resAddr.address2 === null ? "" : resAddr.address2,
            city: resAddr.city === null ? "" : resAddr.city,
            urbanization:
              resAddr.resurbanization === null ? "" : resAddr.resurbanization,
            state: resAddr.state === null ? "" : resAddr.state,
            zip_code: resAddr.zip === null ? "" : resAddr.zip,
            business_phone:
              busAddr.businessphone === null ? "" : busAddr.businessphone,
            business_fax:
              busAddr.businessfax === null ? "" : busAddr.businessfax,
          });
        } else {
          await this.setState({
            candidate_id: busAddr.candidateid,
            autocomplete:
              busAddr.busaddress1 === null ? "" : busAddr.busaddress1,
            address2: busAddr.busaddress2 === null ? "" : busAddr.busaddress2,
            city: busAddr.buscity === null ? "" : busAddr.buscity,
            urbanization:
              busAddr.busresurbanization === null
                ? ""
                : busAddr.busresurbanization,
            state: busAddr.busstate === null ? "" : busAddr.busstate,
            zip_code: busAddr.buszip === null ? "" : busAddr.buszip,
            business_phone:
              busAddr.businessphone === null ? "" : busAddr.businessphone,
            business_fax:
              busAddr.businessfax === null ? "" : busAddr.businessfax,
          });
        }

        if (
          busAddr.busaddress1 === resAddr.address1 &&
          busAddr.busaddress2 === resAddr.address2 &&
          busAddr.buscity === resAddr.city &&
          busAddr.busstate === resAddr.state &&
          busAddr.buszip === resAddr.zip &&
          busAddr.busresurbanization === resAddr.resurbanization
        ) {
          this.props.setBusAddrChecked(true);
        }
      } else {
        await this.setState({
          hideBusinessInfoCheckBox: true,
          corpid: this.props.corpid,
          autocomplete: busAddr.address1 === null ? "" : busAddr.address1,
          address2: busAddr.address2 === null ? "" : busAddr.address2,
          city: busAddr.city === null ? "" : busAddr.city,
          urbanization:
            busAddr.corpurbanization === null ? "" : busAddr.corpurbanization,
          state: busAddr.state === null ? "" : busAddr.state,
          zip_code: busAddr.zipcode === null ? "" : busAddr.zipcode,
          business_phone:
            busAddr.phonenumber === null ? "" : busAddr.phonenumber,
          business_fax: busAddr.faxnumber === null ? "" : busAddr.faxnumber,
          corpData: busAddr,
        });
        this.props.setBusAddrChecked(false);
      }
    } else if (
      this.props.contracttypedesc === CONTRACT_TYPE_DESCRIPTIONS.CAREER_AGENT ||
      this.props.contracttypedesc ===
        CONTRACT_TYPE_DESCRIPTIONS.CAREER_SAMPLING ||
      this.props.contracttypedesc ===
        CONTRACT_TYPE_DESCRIPTIONS.COLLEGE_INTERN ||
      this.props.contracttypedesc ===
        CONTRACT_TYPE_DESCRIPTIONS.ALTERNATE_AGENT ||
      this.props.contracttypedesc === CONTRACT_TYPE_DESCRIPTIONS.MMLIS
    ) {
      const searchForPresent = resHistory.filter(
        (resAddress) => resAddress.currentaddress === true
      );

      if (
        (searchForPresent.length === 1 &&
          searchForPresent[0].country !== "US") ||
        searchForPresent.length > 1
      ) {
        this.setState({ hideBusinessInfoCheckBox: true });
      }
      if (
        busAddrCheck &&
        searchForPresent.length === 1 &&
        searchForPresent[0].country === "US" &&
        (busAddr.busaddress1 !== searchForPresent[0].address1 ||
          busAddr.busaddress2 !== searchForPresent[0].address2 ||
          busAddr.buscity !== searchForPresent[0].city ||
          busAddr.busstate !== searchForPresent[0].state ||
          busAddr.buszip !== searchForPresent[0].zipcode ||
          busAddr.busresurbanization !== searchForPresent[0].urbanization)
      ) {
        this.setState({
          candidate_id: busAddr.candidateid,
          autocomplete:
            searchForPresent[0].address1 === null
              ? ""
              : searchForPresent[0].address1,
          address2:
            searchForPresent[0].address2 === null
              ? ""
              : searchForPresent[0].address2,
          city:
            searchForPresent[0].city === null ? "" : searchForPresent[0].city,
          urbanization:
            searchForPresent[0].urbanization === null
              ? ""
              : searchForPresent[0].urbanization,
          state:
            searchForPresent[0].state === null ? "" : searchForPresent[0].state,
          zip_code:
            searchForPresent[0].zipcode === null
              ? ""
              : searchForPresent[0].zipcode,
          business_phone:
            busAddr.businessphone === null ? "" : busAddr.businessphone,
          business_fax: busAddr.businessfax === null ? "" : busAddr.businessfax,
        });
      } else {
        await this.setState({
          candidate_id: busAddr.candidateid,
          autocomplete: busAddr.busaddress1 === null ? "" : busAddr.busaddress1,
          address2: busAddr.busaddress2 === null ? "" : busAddr.busaddress2,
          city: busAddr.buscity === null ? "" : busAddr.buscity,
          urbanization:
            busAddr.busresurbanization === null ||
            busAddr.busresurbanization === "null"
              ? ""
              : busAddr.busresurbanization,
          state: busAddr.busstate === null ? "" : busAddr.busstate,
          zip_code: busAddr.buszip === null ? "" : busAddr.buszip,
          business_phone:
            busAddr.businessphone === null ? "" : busAddr.businessphone,
          business_fax: busAddr.businessfax === null ? "" : busAddr.businessfax,
        });

        const resHistPresentUrbanization =
          searchForPresent[0].urbanization === null ? "" : searchForPresent[0];

        if (
          this.state.hideBusinessInfoCheckBox === false &&
          searchForPresent.length === 1 &&
          this.state.autocomplete !== null &&
          this.state.autocomplete !== "" &&
          this.state.autocomplete === searchForPresent[0].address1 &&
          this.state.address2 === searchForPresent[0].address2 &&
          this.state.city === searchForPresent[0].city &&
          this.state.state === searchForPresent[0].state &&
          this.state.zip_code === searchForPresent[0].zipcode &&
          this.state.urbanization === resHistPresentUrbanization
        ) {
          this.props.setBusAddrChecked(true);
        }
      }
    } else if (
      this.props.contracttypedesc === CONTRACT_TYPE_DESCRIPTIONS.BROKER_CORP &&
      this.props.addrType === "corporate"
    ) {
      this.setState({ hideBusinessInfoCheckBox: true });
    }

    if (
      this.state.autocomplete !== null &&
      this.state.autocomplete !== "" &&
      this.state.address2 !== null &&
      this.state.city !== null &&
      this.state.city !== "" &&
      this.state.state !== null &&
      this.state.state !== "" &&
      this.state.zip_code !== null &&
      this.state.zip_code !== "" &&
      this.state.business_phone !== null &&
      this.state.business_phone !== ""
    ) {
      this.setState({ is_validated: true, is_next_disabled: false });
    }

    if (this.state.state === "PR") {
      this.handleShowUrbanization();
    }
    if (
      this.state.business_fax === null ||
      this.state.business_fax === undefined
    ) {
      this.setState({ business_fax: "" });
    }
  }

  //broker side
  async loadResAddress() {
    const resAddr = this.props.resAddress;

    this.setState({
      candidate_id: this.props.candidateid,
      autocomplete: resAddr.address1 === null ? "" : resAddr.address1,
      address2: resAddr.address2 === null ? "" : resAddr.address2,
      city: resAddr.city === null ? "" : resAddr.city,
      urbanization:
        resAddr.resurbanization === null ? "" : resAddr.resurbanization,
      state: resAddr.state === null ? "" : resAddr.state,
      zip_code: resAddr.zip === null ? "" : resAddr.zip,
    });

    if (this.state.form_id === "residential") {
      if (
        resAddr.address1 !== null &&
        resAddr.address2 !== null &&
        resAddr.city !== null &&
        resAddr.state !== null &&
        resAddr.zip !== null
      ) {
        if (
          resAddr.address1 !== "" &&
          resAddr.city !== "" &&
          resAddr.state !== "" &&
          resAddr.zip !== ""
        ) {
          this.setState({ is_next_disabled: false });
        }
        this.setState({ is_validated: true });
      }
      if (resAddr.state === "PR") {
        this.handleShowUrbanization();
      }
    }
  }

  saveResAddress() {
    this.props.saveResAddress("residentialAddress", {
      address1: this.state.autocomplete,
      address2: this.state.address2,
      city: this.state.city,
      resurbanization: this.state.urbanization,
      state: this.state.state,
      zip: this.state.zip_code,
    });

    let data = {
      candidateid: this.state.candidate_id,
      address1: this.state.autocomplete,
      address2: this.state.address2,
      city: this.state.city,
      state: this.state.state,
      zip: this.state.zip_code,
      resurbanization: this.state.urbanization,
    };
    putResidentialAddress(this.props.subId, data);
  }

  async saveBusAddress() {
    if (
      this.props.contracttypedesc !== CONTRACT_TYPE_DESCRIPTIONS.BROKER_CORP
    ) {
      this.props.saveBusAddress("businessAddress", {
        ...this.props.busAddr,
        busaddress1: this.state.autocomplete,
        busaddress2: this.state.address2,
        buscity: this.state.city,
        businessfax: this.state.business_fax,
        businessphone: this.state.business_phone,
        busresurbanization: this.state.urbanization,
        busstate: this.state.state,
        buszip: this.state.zip_code,
      });

      let data = {
        address1: this.state.autocomplete,
        address2: this.state.address2,
        city: this.state.city,
        state: this.state.state,
        zip: this.state.zip_code,
        urbanization: this.state.urbanization,
        phone: this.state.business_phone,
        fax: this.state.business_fax,
      };
      putBusinessAddress(this.props.subId, data);
    } else {
      if (this.props.addrType === "corporate") {
        this.props.saveBusAddress("corporationData", {
          ...this.props.busAddr,
          address1: this.state.autocomplete,
          address2: this.state.address2,
          city: this.state.city,
          faxnumber: this.state.business_fax,
          phonenumber: this.state.business_phone,
          corpurbanization: this.state.urbanization,
          state: this.state.state,
          zipcode: this.state.zip_code,
          corpid: this.state.corpData.corpid,
          corpname: this.state.corpData.corpname,
          corpofficername: this.state.corpData.corpofficername,
          corptaxid: this.state.corpData.corptaxid,
          numberofreps: this.state.corpData.numberofreps,
          corpemailaddress: this.state.corpData.corpemailaddress,
        });
        let data = {
          corpid: this.state.corpid,
          address1: this.state.autocomplete,
          address2: this.state.address2,
          city: this.state.city,
          state: this.state.state,
          zipcode: this.state.zip_code,
          corpurbanization: this.state.urbanization,
          phonenumber: this.state.business_phone,
          faxnumber: this.state.business_fax,
          corptaxid: this.state.corpData.corptaxid,
        };
        await updateCorporation(this.props.subId, data);
      } else {
        this.props.saveBusAddress("businessAddress", {
          ...this.props.busAddr,
          busaddress1: this.state.autocomplete,
          busaddress2: this.state.address2,
          buscity: this.state.city,
          businessfax: this.state.business_fax,
          businessphone: this.state.business_phone,
          busresurbanization: this.state.urbanization,
          busstate: this.state.state,
          buszip: this.state.zip_code,
        });

        let data = {
          address1: this.state.autocomplete,
          address2: this.state.address2,
          city: this.state.city,
          state: this.state.state,
          zip: this.state.zip_code,
          urbanization: this.state.urbanization,
          phone: this.state.business_phone,
          fax: this.state.business_fax,
        };

        putBusinessAddress(this.props.subId, data);
      }
    }
  }

  useResAddress() {
    try {
      const { address1, address2, city, state, zip, resurbanization } =
        this.props.resAddress;
      this.setState({
        autocomplete: address1,
        address2: address2,
        city: city,
        state: state,
        zip_code: zip,
        urbanization: resurbanization,
        is_next_disabled: true,
      });

      if (state === "PR") {
        this.handleShowUrbanization();
      } else {
        this.handleHideUrbanization();
      }

      this.clearInputErrors();
    } catch (error) {
      console.log("no residential address submitted");
    }
  }

  useResHisAdd = async () => {
    const resHistory = this.props.residentialHistory;
    const searchForPresent = resHistory.filter(
      (resAddress) => resAddress.currentaddress === true
    );

    await this.setState({
      autocomplete: searchForPresent[0].address1,
      address2: searchForPresent[0].address2,
      city: searchForPresent[0].city,
      state: searchForPresent[0].state,
      zip_code: searchForPresent[0].zipcode,
      urbanization: searchForPresent[0].urbanization,
      is_next_disabled: true,
    });

    this.clearInputErrors();
  };

  initialState() {
    return {
      form_id: "",
      autocomplete: "",
      address2: "",
      city: "",
      state: "",
      zip_code: "",
      country: "US",
      usps_address: "",
      usps_address2: "",
      usps_city: "",
      usps_state: "",
      usps_zip_code: "",
      usps_country: "",
      urbanization: "",
      business_phone: "",
      business_fax: "",
      showModal: false,
      showUrbanization: false,
      is_validated: false,
      useSuggestedAddress: "",
      addressNotFound: false,
      candidate_id: "",
      showBackModal: false,
      formHasChanged: false,
      ctrl_with_focus: "",
      is_next_disabled: true,
      USPSLoading: false,
      hideBusinessInfoCheckBox: false,
      corpData: "",
    };
  }

  handleCloseModal(x) {
    this.setState({ showModal: false });
  }

  async handleUseEnteredAddress() {
    await this.setState({ useSuggestedAddress: false, is_validated: true });

    if (this.state.form_id === "business") {
      if (
        !this.state.business_phone.includes("_") &&
        this.state.business_phone !== ""
      ) {
        this.saveBusAddress();
      }
    }
    this.handleCloseModal();
  }

  handleShowUrbanization() {
    this.setState({ showUrbanization: true });
  }

  handleHideUrbanization() {
    this.setState({ showUrbanization: false });
  }

  componentDidMount() {
    this.props.hasCurrentFormChanged(false);
    if (this.props.id === "business") {
      UtagTracking.sendPageNameView(
        "businessaddressform",
        this.props.candidateid,
        this.props.agencynumber,
        this.props.contracttypeid
      );
      this.loadBusAddress();
    } else {
      UtagTracking.sendPageNameView(
        "residentialaddressform",
        this.props.candidateid,
        this.props.agencynumber,
        this.props.contracttypeid
      );
      this.loadResAddress();
    }
  }

  async validateAddress() {
    this.setState({ USPSLoading: true });
    let street =
      this.state.address2 === "" ||
      this.state.address2 === null ||
      this.state.address2 === undefined
        ? this.state.autocomplete
        : `${this.state.autocomplete}, ${this.state.address2}`;

    if (this.state.address2 === undefined) {
      this.setState({ address2: "" });
    }

    let data = {
      street: street,
      city: this.state.city,
      state: this.state.state,
      zip: this.state.zip_code,
      resurbanization: this.state.urbanization,
    };

    const USPSValidationResponse = await getUSPSAddress(data);

    this.setState({ USPSLoading: false });

    let verifyLevel = "default";
    if (USPSValidationResponse.data !== "undefined") {
      verifyLevel = USPSValidationResponse.data.VerificationFlags.VerifyLevel;
    }
    if (verifyLevel === "Verified") {
      this.setState({
        usps_address: USPSValidationResponse.data.Address.Street1,
        usps_address2: USPSValidationResponse.data.Address.Street2,
        usps_city: USPSValidationResponse.data.Address.City,
        usps_state: USPSValidationResponse.data.Address.State,
        usps_zip_code: USPSValidationResponse.data.Address.Zip,
        usps_country: "US",
        showModal: true,
        addressNotFound: false,
      });
    } else {
      this.setState({
        usps_address: "USPS does not recognize the address you entered.",
        usps_address2: "",
        usps_city: "",
        usps_state: "",
        usps_zip_code: "",
        showModal: true,
        addressNotFound: true,
      });
    }
  }

  clearInputErrors() {
    let streetInput = document.querySelector(
      "#autocomplete-" + this.state.form_id
    );
    let address2Input = document.querySelector(
      "#address2-" + this.state.form_id
    );
    let cityInput = document.querySelector("#city-" + this.state.form_id);
    let stateInput = document.querySelector("#state-" + this.state.form_id);

    let zipCodeInput = document.querySelector(
      "#zip_code-" + this.state.form_id
    );

    let streetErrorMessageBox = document.getElementById(
      streetInput.id + "_error"
    );
    let address2ErrorMessageBox = document.getElementById(
      address2Input.id + "_error"
    );
    let cityErrorMessageBox = document.getElementById(cityInput.id + "_error");
    let stateErrorMessageBox = document.getElementById(
      stateInput.id + "_error"
    );
    let zipCodeErrorMessageBox = document.getElementById(
      zipCodeInput.id + "_error"
    );

    streetInput.style = "1px solid #6b748e";
    address2Input.style = "1px solid #6b748e";
    cityInput.style = "1px solid #6b748e";
    stateInput.style = "1px solid #6b748e";
    zipCodeInput.style = "1px solid #6b748e";

    streetErrorMessageBox.style.display = "none";
    address2ErrorMessageBox.style.display = "none";
    cityErrorMessageBox.style.display = "none";
    stateErrorMessageBox.style.display = "none";
    zipCodeErrorMessageBox.style.display = "none";
  }

  onFocus = (event) => {
    if (event.target.autocomplete) {
      event.target.autocomplete = "new-address";
    }
  };

  onBlurAddress2 = (event) => {
    var obj_error = document.getElementById(event.target.id + "_error");

    if (
      obj_error.style.display === "block" &&
      event.target.value.trim(" ").length > 0
    ) {
      event.target.removeAttribute("style");
      obj_error.style.display = "none";
    }
  };
  onFocusState() {
    this.setState({ ctrl_with_focus: "" });

    var inputs = document.querySelectorAll("button, a");
    try {
      for (var i = 0; i < inputs.length; ++i) {
        inputs[i].addEventListener("focusout", (event) => {
          if (event.relatedTarget == null) {
            this.setState({ ctrl_with_focus: "" });
          } else {
            this.setState({ ctrl_with_focus: event.relatedTarget.id });
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  }
  onBlur = (event) => {
    if (
      this.state.ctrl_with_focus.includes("option") &&
      this.state.ctrl_with_focus !== event.target.id
    ) {
      //do nothing
      return;
    } else if (
      !event.target.id.includes("urbanization") &&
      !event.target.id.includes("option")
    ) {
      var obj_error = document.getElementById(event.target.id + "_error");
      if (event.target.className !== "dropdown-item")
        event.target.removeAttribute("style");
      obj_error.style.display = "none";
    }

    if (!event.target.id.includes("state")) {
      if (event.target.id.includes("zip_code")) {
        var zipCodeError;
        if (event.target.value.trim(" ").length === 0) {
          obj_error.innerText = "Please enter a Zip Code";
          zipCodeError = true;
        }
        if (
          event.target.value.trim(" ").length > 0 &&
          event.target.value.trim(" ").length < 5
        ) {
          obj_error.innerText = "Please enter a valid Zip Code";
          zipCodeError = true;
        }
        if (zipCodeError) {
          event.target.style.border = "1px solid #e21224";
          event.target.style.color = "#e21224";
          obj_error.style.display = "block";
        }
      } else if (event.target.id.includes("urbanization")) {
        if (event.target.value.trim(" ").length === 0) {
          this.setState({ urbanization: "" });
          event.target.value = "";
        }
      } else if (
        !event.target.id.includes("urbanization") &&
        !event.target.id.includes("business_fax") &&
        event.target.value.trim(" ").length === 0
      ) {
        event.target.style.border = "1px solid #e21224";
        event.target.style.color = "#e21224";
        obj_error.style.display = "block";
        event.target.classList.add("validation-error");
      }

      if (event.target.id.includes("business_phone")) {
        if (
          this.state.business_phone.includes("_") ||
          this.state.business_phone === ""
        ) {
          event.target.style.border = "1px solid #e21224";
          event.target.style.color = "#e21224";
          obj_error.style.display = "block";
          event.target.classList.add("validation-error");
        }
      }

      if (event.target.id.includes("business_fax")) {
        if (
          event.target.value.includes("_") &&
          event.target.value !== "1-___-___-____"
        ) {
          event.target.style.border = "1px solid #e21224";
          event.target.style.color = "#e21224";
          obj_error.style.display = "block";
          event.target.classList.add("validation-error");
        }
      }
    } else {
      var stateCtrl = document.getElementById("state-" + this.state.form_id);
      stateCtrl.style = "none";
      obj_error = document.getElementById(
        "state-" + this.state.form_id + "_error"
      );
      if (
        event.target.id.includes("option") &&
        this.state.ctrl_with_focus.includes("state_option")
      ) {
        //do nothing
      } else if (
        (event.target.id === "state-" + this.state.form_id &&
          event.target.innerText.trim(" ").length === 0) ||
        this.state.state === ""
      ) {
        stateCtrl.style.border = "1px solid #e21224";
        stateCtrl.style.color = "#e21224";
        obj_error.style.display = "block";
      }
    }
    //this.setState({ formHasChanged: true });
    //this.props.hasCurrentFormChanged(true);
    if (this.state.form_id === "business") {
      if (
        this.state.autocomplete.length > 0 &&
        this.state.city.length > 0 &&
        this.state.state.length > 0 &&
        this.state.zip_code.length > 4 &&
        !this.state.business_phone.includes("_") &&
        this.state.business_phone !== "" &&
        (!this.state.business_fax.includes("_") ||
          this.state.business_fax.includes("1-___-___-____"))
      ) {
        this.setState({ is_next_disabled: false });
      } else {
        this.setState({ is_next_disabled: true });
      }
    }
    if (this.state.form_id === "residential") {
      if (
        this.state.autocomplete.length > 0 &&
        this.state.city.length > 0 &&
        this.state.state.length > 0 &&
        this.state.zip_code.length > 4
      ) {
        this.setState({ is_next_disabled: false });
      } else {
        this.setState({ is_next_disabled: true });
      }
    }
  };

  handleChange(event) {
    if (
      this.props.contracttypedesc !== CONTRACT_TYPE_DESCRIPTIONS.BROKER_CORP
    ) {
      this.props.isFormComplete(
        this.state.form_id === "residential"
          ? "ResAddrCompleteInd"
          : "BusAddrCompleteInd",
        false
      );
    } else {
      if (this.props.addrType === "corporate") {
        this.props.isFormComplete(
          this.state.form_id === "residential"
            ? "ResAddrCompleteInd"
            : "CorpBusAddrCompleteInd",
          false
        );
      } else {
        this.props.isFormComplete(
          this.state.form_id === "residential"
            ? "ResAddrCompleteInd"
            : "BusAddrCompleteInd",
          false
        );
      }
    }
    this.setState({ is_validated: false, formHasChanged: true });
    //this.setState({ formHasChanged: true });
    //this.props.hasCurrentFormChanged(true);

    const idArray = event.target.id.split("-");
    this.setState({ [idArray[0]]: event.target.value });
    if (
      event.target.id === "state-" + this.state.form_id &&
      event.target.value === "PR"
    )
      this.handleShowUrbanization();
    else if (
      event.target.id === "state-" + this.state.form_id &&
      event.target.value !== "PR"
    ) {
      this.setState({ urbanization: "" });
      this.handleHideUrbanization();
    }
  }

  async handleUseSuggestedAddress() {
    if (this.state.usps_address.toUpperCase().includes("URB")) {
      await this.setState({
        autocomplete: this.state.usps_address2,
        address2: "",
        city: this.state.usps_city,
        state: this.state.usps_state,
        zip_code: this.state.usps_zip_code,
        country: this.state.country,
        urbanization: this.state.usps_address,
        is_validated: true,
        useSuggestedAddress: true,
      });
    } else {
      await this.setState({
        autocomplete: this.state.usps_address,
        address2: this.state.usps_address2,
        city: this.state.usps_city,
        state: this.state.usps_state,
        zip_code: this.state.usps_zip_code,
        country: this.state.country,
        urbanization: "",
        is_validated: true,
        useSuggestedAddress: true,
      });
    }

    if (this.state.usps_state === "PR") this.handleShowUrbanization();
    else this.handleHideUrbanization();

    if (this.state.form_id === "business") {
      this.saveBusAddress();
    }

    this.handleCloseModal();
    this.clearInputErrors();
  }

  handleBack() {
    this.props.backFunction();
  }

  getUSPSValidation() {}

  async handleNext() {
    switch (this.state.form_id) {
      case "residential":
        if (
          this.state.autocomplete.length === 0 ||
          this.state.city.length === 0 ||
          this.state.state.length === 0 ||
          this.state.zip_code.length === 0
        ) {
          // console.log("throwErrors");
          this.throwErrors();
        } else if (
          this.state.is_validated === true &&
          this.state.autocomplete.length > 0 &&
          this.state.city.length > 0 &&
          this.state.state.length > 0 &&
          this.state.zip_code.length > 0
        ) {
          // console.log("proceed");
          if (this.state.formHasChanged) {
            this.saveResAddress();
          }
          this.props.isFormComplete("ResAddrCompleteInd", true);
          this.props.nextFunction();
        } else if (
          this.state.formHasChanged === true &&
          this.state.is_validated === false &&
          this.state.autocomplete.length > 0 &&
          this.state.city.length > 0 &&
          this.state.state.length > 0 &&
          this.state.zip_code.length > 0
        ) {
          // console.log("Validate USPS");
          this.validateAddress();
        }
        break;
      case "business":
        if (
          this.props.busAddrCheck === false &&
          (this.state.autocomplete.length === 0 ||
            this.state.city.length === 0 ||
            this.state.state.length === 0 ||
            this.state.zip_code.length === 0 ||
            this.state.business_phone.length === 0 ||
            this.state.business_phone.includes("_"))
        ) {
          //console.log("throwErrors");
          this.throwErrors();
          this.throwBusinessInfoError();
        } else if (
          (this.props.busAddrCheck === true &&
            this.state.business_phone.length === 0) ||
          this.state.business_phone.includes("_")
        ) {
          this.throwBusinessInfoError();
        } else if (
          this.state.is_validated === true &&
          this.state.autocomplete.length > 0 &&
          this.state.city.length > 0 &&
          this.state.state.length > 0 &&
          this.state.zip_code.length > 0 &&
          !this.state.business_phone.includes("_") &&
          this.state.business_phone !== "" &&
          !this.state.business_fax.includes("_")
        ) {
          // console.log("proceed");
          if (this.state.formHasChanged || this.props.busAddrCheck === true) {
            this.saveBusAddress();
            if (
              this.props.contracttypedesc !==
              CONTRACT_TYPE_DESCRIPTIONS.BROKER_CORP
            ) {
              this.props.isFormComplete("BusAddrCompleteInd", true);
            } else {
              if (this.props.addrType === "corporate") {
                this.props.isFormComplete("CorpBusAddrCompleteInd", true);
              } else {
                this.props.isFormComplete("BusAddrCompleteInd", true);
              }
            }
          }
          if (
            this.state.formHasChanged === false ||
            this.props.busAddrCheck === true
          ) {
            this.props.isFormComplete("BusAddrCompleteInd", true);
          }

          this.props.nextFunction();
        } else if (
          this.props.busAddrCheck === false &&
          this.state.formHasChanged === true &&
          this.state.is_validated === false &&
          this.state.autocomplete.length > 0 &&
          this.state.city.length > 0 &&
          this.state.state.length > 0 &&
          this.state.zip_code.length > 0 &&
          !this.state.business_phone.includes("_") &&
          this.state.business_phone !== "" &&
          !this.state.business_fax.includes("_")
        ) {
          // console.log("Validate USPS");
          this.validateAddress();
        } else if (
          this.state.formHasChanged ||
          this.props.busAddrCheck === true
        ) {
          this.saveBusAddress();
          if (
            this.props.contracttypedesc !==
            CONTRACT_TYPE_DESCRIPTIONS.BROKER_CORP
          ) {
            this.props.isFormComplete("BusAddrCompleteInd", true);
          } else {
            if (this.props.addrType === "corporate") {
              this.props.isFormComplete("CorpBusAddrCompleteInd", true);
            } else {
              this.props.isFormComplete("BusAddrCompleteInd", true);
            }
          }
          this.props.nextFunction();
        }

        break;
      default:
        break;
    }
  }

  async handleCheck(event) {
    this.props.isFormComplete("BusAddrCompleteInd", false);
    this.setState({ formHasChanged: true });
    this.props.hasCurrentFormChanged(true);
    this.props.setBusAddrChecked(event.target.checked === true);
    if (
      !this.props.busAddrCheck &&
      (this.props.contracttypedesc ===
        CONTRACT_TYPE_DESCRIPTIONS.INDIVIDUAL_BROKER ||
        this.props.contracttypedesc ===
          CONTRACT_TYPE_DESCRIPTIONS.BROKER_CORP ||
        this.props.contracttypedesc === CONTRACT_TYPE_DESCRIPTIONS.MEMBER_CORP)
    ) {
      this.useResAddress();
    } else if (
      !this.props.busAddrCheck &&
      (this.props.contracttypedesc ===
        CONTRACT_TYPE_DESCRIPTIONS.CAREER_AGENT ||
        this.props.contracttypedesc ===
          CONTRACT_TYPE_DESCRIPTIONS.CAREER_SAMPLING ||
        this.props.contracttypedesc ===
          CONTRACT_TYPE_DESCRIPTIONS.COLLEGE_INTERN ||
        this.props.contracttypedesc ===
          CONTRACT_TYPE_DESCRIPTIONS.ALTERNATE_AGENT ||
        this.props.contracttypedesc === CONTRACT_TYPE_DESCRIPTIONS.MMLIS)
    ) {
      await this.useResHisAdd();
    } else if (event.target.checked === false) {
      this.clearForm();
      this.handleHideUrbanization();
    }
  }

  clearForm() {
    this.setState({
      autocomplete: "",
      address2: "",
      city: "",
      state: "",
      zip_code: "",
      urbanization: "",
      usps_address: "",
      usps_address2: "",
      usps_city: "",
      usps_state: "",
      usps_zip_code: "",
      showUrbanization: false,
      addressNotFound: false,
      is_validated: false,
      is_next_disabled: true,
    });
  }

  handlePlaceSelect(addressObject) {
    if (this.props.addrType === "corporate") {
      this.props.isFormComplete(
        this.state.form_id === "residential"
          ? "ResAddrCompleteInd"
          : "CorpBusAddrCompleteInd",
        false
      );
    } else {
      this.props.isFormComplete(
        this.state.form_id === "residential"
          ? "ResAddrCompleteInd"
          : "BusAddrCompleteInd",
        false
      );
    }
    this.props.hasCurrentFormChanged(true);
    this.setState({ is_validated: false });
    var address_components = this.getAddressComponents(
      addressObject.address_components
    );

    let input;

    if (address_components.street_number === "") {
      input = this.state.autocomplete.split(" ");

      if (address_components.street === "") {
        for (let i = 0; i < input.length; i++)
          input[i] = input[i][0].toUpperCase() + input[i].substr(1);
        this.setState({
          autocomplete: input.join(" "),
        });
      } else {
        this.setState({
          autocomplete: input[0] + " " + address_components.street,
        });
      }
    } else {
      let addr = address_components.street;
      if (addr.toLocaleUpperCase().includes("AVENIDA")) {
        addr = addr.replace("Avenida", "Ave");
      }
      this.setState({
        autocomplete: address_components.street_number + " " + addr,
      });
    }
    let geocoder = new google.maps.Geocoder();

    if (address_components.city === "" && address_components.zip_code !== "") {
      geocoder
        .geocode({
          address:
            address_components.zip_code + "," + address_components.country,
        })
        .then((response) => {
          var newAddressComponents = response.results[0]["address_components"];
          for (var i = 0; i < newAddressComponents.length; i++) {
            if (newAddressComponents[i].types.includes("locality") === true) {
              this.setState({ city: newAddressComponents[i]["long_name"] });
            }
          }
        });
    } else if (
      address_components.city !== "" &&
      address_components.zip_code === ""
    ) {
      geocoder
        .geocode({
          address:
            "10" +
            address_components.street +
            "," +
            address_components.city +
            "," +
            address_components.state +
            "," +
            address_components.country,
        })
        .then((response) => {
          let newAddressComponents = response.results[0]["address_components"];
          for (var i = 0; i < newAddressComponents.length; i++) {
            if (
              newAddressComponents[i].types.includes("postal_code") === true
            ) {
              this.setState({ zip_code: newAddressComponents[i]["long_name"] });
            }
          }
        });
    }
    this.clearInputErrors();

    this.setState({
      city: address_components.city,
      state: address_components.state,
      zip_code: address_components.zip_code,
      address2: "",
      urbanization: "",
      country:
        address_components.country === "Puerto Rico"
          ? "US"
          : address_components.country.match(/\b(\w)/g).join(""),
    });

    if (this.state.form_id === "business") {
      if (!this.state.business_phone.includes("_")) {
        if (
          this.state.business_phone !== "" &&
          (this.state.business_fax === "1-___-___-____" ||
            this.state.business_fax === "" ||
            (this.state.business_fax !== "1-___-___-____" &&
              !this.state.business_fax.includes("_")))
        ) {
          this.setState({
            is_next_disabled: false,
          });
        }
      } else {
        let businessPhoneInput = document.querySelector("#business_phone");
        let businessPhoneErrorMessageBox = document.querySelector(
          "#business_phone_error"
        );
        businessPhoneInput.style.border = "1px solid #e21224";
        businessPhoneInput.style.color = "#e21224";
        businessPhoneErrorMessageBox.style.display = "block";
      }
      if (
        this.state.business_fax === "1-___-___-____" ||
        this.state.business_fax === "" ||
        (this.state.business_fax !== "1-___-___-____" &&
          !this.state.business_fax.includes("_"))
      ) {
        if (
          !this.state.business_phone.includes("_") &&
          this.state.business_phone !== ""
        ) {
          this.setState({
            is_next_disabled: false,
          });
        }
      } else {
        let businessFaxInput = document.querySelector("#business_fax");
        let businessFaxErrorMessageBox = document.querySelector(
          "#business_fax_error"
        );
        businessFaxInput.style.border = "1px solid #e21224";
        businessFaxInput.style.color = "#e21224";
        businessFaxErrorMessageBox.style.display = "block";
      }
    } else {
      this.setState({
        is_next_disabled: false,
      });
    }
  }

  getAddressComponents(address) {
    var USAddressComponents = {
      street_number: ["street_number"],
      street: ["street_address", "route"],
      city: [
        "locality",
        "sublocality",
        "sublocality_level_1",
        "sublocality_level_2",
        "sublocality_level_3",
        "sublocality_level_4",
      ],
      state: [
        "administrative_area_level_1",
        "administrative_area_level_2",
        "administrative_area_level_3",
        "administrative_area_level_4",
        "administrative_area_level_5",
      ],
      zip_code: ["postal_code"],
    };

    var address_components = {
      street_number: "",
      street: "",
      city: "",
      state: "",
      zip_code: "",
      country: "",
      urbanization: "",
    };

    address.forEach((component) => {
      switch (component.long_name) {
        case "Puerto Rico":
        case "United States":
          address_components.country = component.long_name;

          if (address_components.country === "Puerto Rico")
            this.handleShowUrbanization();
          else this.handleHideUrbanization();

          break;
        default:
      }
    });

    address.forEach((component) => {
      for (var addressComponent in USAddressComponents) {
        if (
          USAddressComponents[addressComponent].indexOf(component.types[0]) !==
          -1
        ) {
          if (addressComponent === "state") {
            if (address_components.country === "Puerto Rico") {
              address_components[addressComponent] = "PR";
            } else {
              address_components[addressComponent] = component.short_name;
            }
          } else {
            address_components[addressComponent] = component.long_name;
          }
        }
      }
    });
    return address_components;
  }

  throwBusinessInfoError() {
    //businessPhoneNumber
    let businessPhoneInput = document.querySelector("#business_phone");

    let businessPhoneErrorMessageBox = document.getElementById(
      businessPhoneInput.id + "_error"
    );

    let businessFaxInput = document.querySelector("#business_fax");

    let businessFaxErrorMessageBox = document.getElementById(
      businessFaxInput.id + "_error"
    );

    if (
      typeof this.state.business_phone === "undefined" ||
      this.state.business_phone.length === 0 ||
      this.state.business_phone.includes("_")
    ) {
      businessPhoneInput.style.border = "1px solid #e21224";
      businessPhoneInput.style.color = "#e21224";
      businessPhoneErrorMessageBox.style.display = "block";
    }
    if (
      this.state.business_fax.includes("_") &&
      this.state.business_fax !== "1-___-___-____"
    ) {
      businessFaxInput.style.border = "1px solid #e21224";
      businessFaxInput.style.color = "#e21224";
      businessFaxErrorMessageBox.style.display = "block";
    }
  }

  throwErrors() {
    // street
    let streetInput = document.querySelector(
      "#autocomplete-" + this.state.form_id
    );
    let streetErrorMessageBox = document.getElementById(
      streetInput.id + "_error"
    );
    if (this.state.autocomplete.length === 0) {
      streetInput.style.border = "1px solid #e21224";
      streetInput.style.color = "#e21224";
      streetErrorMessageBox.style.display = "block";
      streetInput.classList.add("validation-error");
    }
    //city
    let cityInput = document.querySelector("#city-" + this.state.form_id);
    let cityErrorMessageBox = document.getElementById(cityInput.id + "_error");

    if (this.state.city.length === 0) {
      cityInput.style.border = "1px solid #e21224";
      cityInput.style.color = "#e21224";
      cityErrorMessageBox.style.display = "block";
    }
    //state
    let stateInput = document.querySelector("#state-" + this.state.form_id);
    let stateErrorMessageBox = document.getElementById(
      stateInput.id + "_error"
    );
    if (this.state.state.length === 0) {
      stateInput.style.border = "1px solid #e21224";
      stateInput.style.color = "#e21224";
      stateErrorMessageBox.style.display = "block";
    }
    //zip code
    let zipCodeInput = document.querySelector(
      "#zip_code-" + this.state.form_id
    );
    let zipCodeErrorMessageBox = document.getElementById(
      zipCodeInput.id + "_error"
    );
    if (this.state.zip_code.length === 0) {
      zipCodeInput.style.border = "1px solid #e21224";
      zipCodeInput.style.color = "#e21224";
      zipCodeErrorMessageBox.style.display = "block";
    }
  }
  onKeyDown() {
    if (this.props.addrType === "corporate") {
      this.props.isFormComplete(
        this.state.form_id === "residential"
          ? "ResAddrCompleteInd"
          : "CorpBusAddrCompleteInd",
        false
      );
    } else {
      this.props.isFormComplete(
        this.state.form_id === "residential"
          ? "ResAddrCompleteInd"
          : "BusAddrCompleteInd",
        false
      );
    }
    this.props.hasCurrentFormChanged(true);
    this.setState({ formHasChanged: true });
  }

  handleCloseBackModal() {
    this.setState({ showBackModal: false });
  }

  handleBackClick() {
    const { id, busAddress, resAddress } = this.props;
    if (id === "business") {
      if (
        this.props.contracttypedesc !== CONTRACT_TYPE_DESCRIPTIONS.BROKER_CORP
      ) {
        this.props.setBusAddrChecked(
          busAddress.busaddress1 === resAddress.address1 &&
            busAddress.busaddress2 === resAddress.address2 &&
            busAddress.buscity === resAddress.city &&
            busAddress.busstate === resAddress.state &&
            busAddress.buszip === resAddress.zip &&
            busAddress.busresurbanization === resAddress.resurbanization
        );

        if (
          busAddress.busaddress1 !== null &&
          busAddress.buscity !== null &&
          busAddress.busstate !== null &&
          busAddress.buszip !== null
        ) {
          this.props.isFormComplete("BusAddrCompleteInd", true);
          this.props.saveBusAddress("resAddressHasChanged", false);
        }
      } else {
        if (
          busAddress.address1 !== null &&
          busAddress.city !== null &&
          busAddress.state !== null &&
          busAddress.zipcode !== null
        ) {
          if (this.props.addrType === "corporate") {
            this.props.isFormComplete("CorpBusAddrCompleteInd", true);
          } else {
            this.props.isFormComplete("BusAddrCompleteInd", true);
          }
          this.props.saveBusAddress("resAddressHasChanged", false);
        }
      }
    }

    this.props.hasCurrentFormChanged(false);
    this.handleCloseBackModal();
    this.handleBack();
  }

  handleShowBackModal() {
    //show back modal if anything on the form has changed

    const { resAddressHasChanged, id } = this.props;

    if (
      this.state.formHasChanged ||
      (id === "business" && resAddressHasChanged === true)
    ) {
      this.setState({ showBackModal: true });
    } else {
      this.handleBack();
    }
  }

  render() {
    return (
      <>
        {this.state.form_id === "business" && (
          <div className="row">
            <div className="col-md-12" style={{ paddingTop: "15px" }}>
              {this.state.hideBusinessInfoCheckBox === false && (
                <label
                  htmlFor="useResidentialAddress"
                  className="required-field-text"
                  style={{ marginBottom: "0" }}
                >
                  <FormCustomControl
                    id="useResidentialAddress"
                    inputType="checkbox"
                    label="My business address is the same as my residential address"
                    onChange={this.handleCheck}
                    checked={this.props.busAddrCheck === true}
                    // disabled={
                    //   this.props.contracttypedes === "Career Agent Contract"
                    //     ? this.props.residentialHistory === ""
                    //     : this.props.resAddress === ""
                    // }
                  />
                </label>
              )}
            </div>
          </div>
        )}
        <address id={this.state.form_id}>
          <div>
            <div className="row">
              <div className="col-md-12">
                <div
                  className="row"
                  style={{
                    paddingTop:
                      this.state.form_id === "residential" ? "20px" : "0px",
                  }}
                >
                  <div className="col">
                    <label id="address_street_addr">
                      <strong> Street Address* </strong>
                    </label>
                    <Autocomplete
                      apiKey={window.REACT_APP_GOOGLE_API_KEY}
                      onPlaceSelected={(place) => {
                        this.handlePlaceSelect(place);
                      }}
                      options={{
                        types: ["geocode", "establishment"],
                        componentRestrictions: {
                          country: [
                            "us" /*United States*/,
                            //"as" /*American Samoa*/,
                            //"fm" /*Federated States of Micronesia*/,
                            //"gu" /*Guam*/,
                            //"mh" /*Marshall Islands*/,
                            //"mp" /*Northern Mariana Islands*/,
                            "pr" /*Puerto Rico*/,
                            //"pw" /*Palau*/,
                            //"vi" /*U.S. Virgin Islands*/,
                            //"um" /*U.S. Minor Outlying Islands*/,
                          ],
                        },
                      }}
                      id={"autocomplete-" + this.state.form_id}
                      className="form-control form-control-15"
                      placeholder="Enter an Address"
                      aria-label="Aria Autocomplete Streetaddress Verify"
                      value={this.state.autocomplete}
                      onChange={this.handleChange}
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                      onKeyDown={this.onKeyDown}
                      maxLength={320}
                      disabled={
                        !this.props.contracttypedesc.includes("Career")
                          ? this.props.busAddrCheck === true
                          : this.props.busAddrCheck === true &&
                            this.state.hideBusinessInfoCheckBox === false
                      }
                    />
                    <div
                      id={"autocomplete-" + this.state.form_id + "_error"}
                      className="invalid-feedback"
                    >
                      Please enter a Street Address
                    </div>
                  </div>
                </div>
                <div className="row" style={{ paddingTop: "20px" }}>
                  <div className="col">
                    <label id="address_apartment_code">
                      <strong> Apartment, Suite, Building, Gate Code </strong>
                    </label>
                    <input
                      id={"address2-" + this.state.form_id}
                      className="form-control form-control form-control-15"
                      value={this.state.address2}
                      aria-label="Aria Address2 Verify"
                      onChange={this.handleChange}
                      onKeyDown={this.onKeyDown}
                      maxLength={320}
                      autoComplete="address-line2"
                      onBlur={this.onBlurAddress2}
                      disabled={
                        !this.props.contracttypedesc.includes("Career")
                          ? this.props.busAddrCheck === true
                          : this.props.busAddrCheck === true &&
                            this.state.hideBusinessInfoCheckBox === false
                      }
                    ></input>
                    <div
                      id={"address2-" + this.state.form_id + "_error"}
                      className="invalid-feedback"
                    >
                      Please enter an Apartment, Suite, Building, or Gate Code.
                    </div>
                  </div>
                </div>
                <div className="row" style={{ paddingTop: "20px" }}>
                  <div className="col">
                    <label id="address_city" aria-label="City">
                      <strong>City*</strong>
                    </label>
                    <input
                      id={"city-" + this.state.form_id}
                      className="form-control form-control form-control-15"
                      title="Aria Address City"
                      value={this.state.city}
                      onChange={this.handleChange}
                      autoComplete="address-level2"
                      aria-label="Aria Address City"
                      onBlur={this.onBlur}
                      onKeyDown={this.onKeyDown}
                      maxLength={320}
                      disabled={
                        !this.props.contracttypedesc.includes("Career")
                          ? this.props.busAddrCheck === true
                          : this.props.busAddrCheck === true &&
                            this.state.hideBusinessInfoCheckBox === false
                      }
                    />
                    <div
                      id={"city-" + this.state.form_id + "_error"}
                      className="invalid-feedback"
                    >
                      Please enter a City
                    </div>
                  </div>
                </div>
                <div className="row" style={{ paddingTop: "20px" }}>
                  <div className="col">
                    <label id="address_state">
                      <strong>State/Province*</strong>
                    </label>

                    <DropdownButton
                      id={"state-" + this.state.form_id}
                      type="default"
                      variant="secondary"
                      title={this.state.state}
                      selected={false}
                      aria-hidden="true"
                      aria-label="Aria Address State Verify"
                      autoComplete="new-address"
                      onSelect={(key, event) => {
                        event.target.id = "state-" + this.state.form_id;
                        event.target.value = key;
                        this.handleChange(event);
                        this.onKeyDown();
                        event.target.title =
                          "state-" + this.state.form_id + "-" + key;
                      }}
                      role="button"
                      onBlur={this.onBlur}
                      onClick={clickFunc}
                      onFocus={this.onFocusState}
                      disabled={
                        !this.props.contracttypedesc.includes("Career")
                          ? this.props.busAddrCheck === true
                          : this.props.busAddrCheck === true &&
                            this.state.hideBusinessInfoCheckBox === false
                      }
                      required
                    >
                      <Dropdown.Item eventKey={"PR"} id="state_option_PR">
                        PR - Puerto Rico
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={"AL"} id="state_option_AL">
                        AL - Alabama
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={"AK"} id="state_option_AK">
                        AK - Alaska
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={"AZ"} id="state_option_AZ">
                        AZ - Arizona
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={"AR"} id="state_option_AR">
                        AR - Arkansas
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={"CA"} id="state_option_CA">
                        CA - California
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={"CO"} id="state_option_CO">
                        CO - Colorodo
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={"CT"} id="state_option_CT">
                        CT - Connecticut
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={"DE"} id="state_option_DE">
                        DE - Delaware
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={"DC"} id="state_option_DC">
                        DC - District of Columbia
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={"FL"} id="state_option_FL">
                        FL - Florida
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={"GA"} id="state_option_GA">
                        GA - Georgia
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={"HI"} id="state_option_HI">
                        HI - Hawaii
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={"ID"} id="state_option_ID">
                        ID - Idaho
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={"IL"} id="state_option_IL">
                        IL - Illinois
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={"IN"} id="state_option_IN">
                        IN - Indiana
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={"IA"} id="state_option_IA">
                        IA - Iowa
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={"KS"} id="state_option_KS">
                        KS - Kansas
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={"KY"} id="state_option_KY">
                        KY - Kentucky
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={"LA"} id="state_option_LA">
                        LA - Louisiana
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={"ME"} id="state_option_ME">
                        ME - Maine
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={"MD"} id="state_option_MD">
                        MD - Maryland
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={"MA"} id="state_option_MA">
                        MA - Massachusetts
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={"MI"} id="state_option_MI">
                        MI - Michigan
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={"MN"} id="state_option_MN">
                        MN - Minnesota
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={"MS"} id="state_option_MS">
                        MS - Mississippi
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={"MO"} id="state_option_MO">
                        MO - Missouri
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={"MT"} id="state_option_MT">
                        MT - Montana
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={"NE"} id="state_option_NE">
                        NE - Nebraska
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={"NV"} id="state_option_NV">
                        NV - Nevada
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={"NH"} id="state_option_NH">
                        NH - New Hampshire
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={"NJ"} id="state_option_NJ">
                        NJ - New Jersey
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={"NM"} id="state_option_NM">
                        NM - New Mexico
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={"NY"} id="state_option_NY">
                        NY - New York
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={"NC"} id="state_option_NC">
                        NC - North Carolina
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={"ND"} id="state_option_ND">
                        ND - North Dakota
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={"OH"} id="state_option_OH">
                        OH - Ohio
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={"OK"} id="state_option_OK">
                        OK - Oklahoma
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={"OR"} id="state_option_OR">
                        OR - Oregon
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={"PA"} id="state_option_PA">
                        PA - Pennsylvania
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={"RI"} id="state_option_RI">
                        RI - Rhode Island
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={"SC"} id="state_option_SC">
                        SC - South Carolina
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={"SD"} id="state_option_SD">
                        SD - South Dakota
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={"TN"} id="state_option_TN">
                        TN - Tennessee
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={"TX"} id="state_option_TX">
                        TX - Texas
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={"UT"} id="state_option_UT">
                        UT - Utah
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={"VT"} id="state_option_VT">
                        VT - Vermont
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={"VA"} id="state_option_VA">
                        VA - Virginia
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={"WA"} id="state_option_WA">
                        WA - Washington
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={"WV"} id="state_option_WV">
                        WV - West Virginia
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={"WI"} id="state_option_WI">
                        WI - Wisconsin
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={"WY"} id="state_option_WY">
                        WY - Wyoming
                      </Dropdown.Item>
                    </DropdownButton>
                    <div
                      id={"state-" + this.state.form_id + "_error"}
                      className="invalid-feedback"
                      aria-label="Aria Address State Verify Error"
                    >
                      Please enter a State
                    </div>
                  </div>
                </div>
                <div className="row" style={{ paddingTop: "20px" }}>
                  <div className="col">
                    <label id="address_zip">
                      <strong> Zip Code* </strong>
                    </label>
                    <input
                      id={"zip_code-" + this.state.form_id}
                      className="form-control form-control form-control-15"
                      value={this.state.zip_code}
                      onChange={this.handleChange}
                      aria-label="Aria Zip Code Verify"
                      onBlur={this.onBlur}
                      maxLength={10}
                      onKeyPress={onlyNumeric}
                      disabled={
                        !this.props.contracttypedesc.includes("Career")
                          ? this.props.busAddrCheck === true
                          : this.props.busAddrCheck === true &&
                            this.state.hideBusinessInfoCheckBox === false
                      }
                      autoComplete="off"
                    />
                    <div
                      id={"zip_code-" + this.state.form_id + "_error"}
                      className="invalid-feedback"
                    >
                      Please enter a Zip Code
                    </div>
                  </div>
                </div>
                {this.state.showUrbanization === true && (
                  <div className="row" style={{ paddingTop: "20px" }}>
                    <div className="col">
                      <label id="address_urbanization">
                        <strong> Urbanization </strong>
                      </label>
                      <input
                        id={"urbanization-" + this.state.form_id}
                        type="text"
                        className="form-control form-control form-control-15"
                        value={this.state.urbanization}
                        onChange={this.handleChange}
                        onKeyDown={this.onKeyDown}
                        onBlur={this.onBlur}
                        autoComplete="off"
                        disabled={
                          !this.props.contracttypedesc.includes("Career")
                            ? this.props.busAddrCheck === true
                            : this.props.busAddrCheck === true &&
                              this.state.hideBusinessInfoCheckBox === false
                        }
                        placeholder="Puerto Rico Residents Only"
                        maxLength={100}
                        aria-label="Urbanization input"
                      />
                    </div>
                  </div>
                )}

                {this.state.form_id === "business" && (
                  <>
                    <div className="row" style={{ paddingTop: "20px" }}>
                      <div className="col-md-12">
                        <label
                          id="address_business_phone"
                          aria-label="Aria Business Phone Label"
                        >
                          <strong>Business Phone Number*</strong>
                        </label>
                        <InputMask
                          id="business_phone"
                          mask="1-999-999-9999"
                          maskChar="_"
                          className="form-control"
                          autoComplete="off"
                          aria-label="Aria Business Phone"
                          value={
                            this.state.business_phone === "1-___-___-____"
                              ? ""
                              : this.state.business_phone
                          }
                          onBlur={this.onBlur}
                          onChange={this.handleChange}
                          onKeyDown={this.onKeyDown}
                        />
                        <div
                          id="business_phone_error"
                          aria-label="Aria Business Phone Error"
                          className="invalid-feedback"
                        >
                          Please enter a valid phone number
                        </div>
                      </div>
                    </div>
                    <div className="row" style={{ paddingTop: "20px" }}>
                      <div className="col-md-12">
                        <label id="address_business_fax">
                          <strong>Business Fax Number</strong>
                        </label>
                        <InputMask
                          id="business_fax"
                          mask="1-999-999-9999"
                          maskChar="_"
                          className="form-control"
                          autoComplete="new-address"
                          aria-label="Aria Business Fax"
                          value={
                            this.state.business_fax === "1-___-___-____"
                              ? ""
                              : this.state.business_fax
                          }
                          onBlur={this.onBlur}
                          onChange={this.handleChange}
                          onKeyDown={this.onKeyDown}
                        />
                        <div
                          id="business_fax_error"
                          className="invalid-feedback"
                        >
                          Please enter a valid fax number
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <NavigationButtons
                  onBack={this.handleShowBackModal}
                  onNext={this.handleNext}
                  isLoading={this.state.USPSLoading}
                  nxtbtnid={"address_next"}
                  bckbtnid={"address_back"}
                />
              </div>

              <div className="col-md-2"></div>
            </div>
          </div>

          <USPSValidationModal
            isOpen={this.state.showModal}
            closeModal={this.handleCloseModal}
            useSuggestedAddress={this.handleUseSuggestedAddress}
            useEnteredAddress={this.handleUseEnteredAddress}
            street_address={this.state.autocomplete}
            address2={this.state.address2}
            city={this.state.city}
            state={this.state.state}
            zip_code={this.state.zip_code}
            country={this.state.country}
            usps_address={this.state.usps_address}
            usps_address2={this.state.usps_address2}
            usps_city={this.state.usps_city}
            usps_state={this.state.usps_state}
            usps_zip_code={this.state.usps_zip_code}
            usps_country={this.state.usps_country}
            urbanization={this.state.urbanization}
            addressNotFound={this.state.addressNotFound}
            contracttypedesc={this.props.contracttypedesc}
            src_id={this.state.form_id}
            formHasChanged={this.state.formHasChanged}
            id={this.props.id}
          />

          <BackButtonModal
            isOpen={this.state.showBackModal}
            closeBackModal={this.handleCloseBackModal}
            backClick={this.handleBackClick}
          />
        </address>
      </>
    );
  }
}

export default ValidationForm;
