import React from "react";
import { Button, Modal } from "react-blueprint-library";
import "../../../assets/stylesheets/BackButtonModal.scss";

export default function EducationRequirementsModal(props) {
  return (
    <>
      <div>
        <div id="eduReqModal"></div>

        <div>
          <Modal
            id="logoutModal"
            show={props.isOpen}
            className="modal-md"
            title=""
          >
            <div>
              <div className="modal-body">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <h2>
                        <div style={{ width: "400px" }}>
                          Education Requirements Not Met
                        </div>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close modal dialog"
                          onClick={props.closeBackModal}
                          style={{ marginLeft: "30px", marginBottom: "-2px" }}
                        />
                      </h2>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <p style={{ fontSize: "1.4rem" }}>
                        Based on your answers, we cannot move you forward in the
                        application. Please notify your Agency so they can
                        contact you about next steps.
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <div
                      className="col-md-12"
                      style={{
                        paddingTop: "20px",
                        paddingBottom: "20px",
                        display: "flex",
                      }}
                    >
                      <Button
                        label="Exit Application"
                        onClick={props.backClick}
                        style={{
                          padding: "5px",
                          float: "left",
                          width: "239px",
                          fontSize: "1.3rem",
                        }}
                      ></Button>
                      <button
                        id="exit-btn"
                        type="button"
                        className="btn btn-link"
                        onClick={props.closeBackModal}
                        style={{
                          width: "45px",
                          textAlign: "left",
                          fontSize: "1.4rem",
                          padding: "4px 0px",
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
}
