import React, { Component, useState } from "react";
import { Button } from "react-blueprint-library";
import { FORM_ID } from "../../../constants/Forms";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { COUNTRIES } from "../../../constants/countries";
import InputMask from "react-input-mask";
import Moment from "moment";
import { useEffect } from "react";
import BackModal from "../Forms/BackButtonModal";
import {
  getOtherBusiness,
  saveOtherBusinessData,
} from "../../../api/otherBusiness";
import { isValidDate, clickFunc } from "../../../common/Functions";
import { UtagTracking } from "../../../utils/utag-tracking";

const OtherBusinessForm = (props) => {
  const [data, setData] = useState({});
  const [engagedInBusiness, setEngagedInBusiness] = useState("");
  const [otherBusinessName, setOtherBusinessName] = useState("");
  const [investmentRelated, setInvestmentRelated] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [country, setCountry] = useState("");
  const [businessNature, setBusinessNature] = useState("");
  const [title, setTitle] = useState("");
  const [relationshipWithBusiness, setRelationShipWithBusiness] = useState("");
  const [startDate, setStartDate] = useState("");
  const [numberOfHours, setNumberOfHours] = useState("");
  const [numberOfHoursTrading, setNumberOfHoursTrading] = useState("");
  const [duties, setDuties] = useState("");
  const [formHasError, setFormHasError] = useState(true);
  const [formHasChanged, setFormHasChanged] = useState(false);
  const [stateMandatory, setStateMandatory] = useState(false);
  const [cntrlWithFocus, setCntrlWithFocus] = useState("");
  const [showBackModal, setShowBackModal] = useState(false);

  const handleNext = async (source) => {
    setFormHasError(false);
    if (source === "nextclick") {
      if (engagedInBusiness === "") {
        setFormHasError(true);
        let obj_error = document.getElementById("engagedInBusiness_text_error");

        obj_error.style.display = "block";
        let engagedInBusiness_yes = document.getElementById(
          "engagedInBusiness_yes"
        );
        engagedInBusiness_yes.classList.add("is-invalid");
        let engagedInBusiness_no = document.getElementById(
          "engagedInBusiness_no"
        );
        engagedInBusiness_no.classList.add("is-invalid");
        let label_yes = document.getElementById("label_engagedInBusiness_yes");
        label_yes.style.color = "#555";
        let label_no = document.getElementById("label_engagedInBusiness_no");
        label_no.style.color = "#555";
      }
      if (engagedInBusiness === "Y") {
        let otherBusiness = new Event("blur");
        Object.defineProperty(otherBusiness, "target", {
          writable: false,
          value: document.getElementById("otherBusinessName_text"),
        });
        onBlur(otherBusiness);

        if (investmentRelated === "") {
          setFormHasError(true);
          let obj_error = document.getElementById("investmentRelated_error");

          obj_error.style.display = "block";
          let investmentRelated_yes = document.getElementById(
            "investmentRelated_yes"
          );
          investmentRelated_yes.classList.add("is-invalid");
          let investmentRelated_no = document.getElementById(
            "investmentRelated_no"
          );
          investmentRelated_no.classList.add("is-invalid");
          let label_yes = document.getElementById(
            "label_investmentRelated_yes"
          );
          label_yes.style.color = "#555";
          let label_no = document.getElementById("label_investmentRelated_no");
          label_no.style.color = "#555";
        }

        // let investmentRelatedBusiness = new Event("blur");
        // Object.defineProperty(investmentRelatedBusiness, "target", {
        //   writable: false,
        //   value: document.getElementById("investmentRelated"),
        // });
        // onBlur(investmentRelatedBusiness);

        let address1 = new Event("blur");
        Object.defineProperty(address1, "target", {
          writable: false,
          value: document.getElementById("address_text"),
        });
        onBlur(address1);

        let city1 = new Event("blur");
        Object.defineProperty(city1, "target", {
          writable: false,
          value: document.getElementById("city_text"),
        });
        onBlur(city1);

        let ziptxt = new Event("blur");
        Object.defineProperty(ziptxt, "target", {
          writable: false,
          value: document.getElementById("zip_text"),
        });
        onBlur(ziptxt);

        let statetxt = new Event("blur");
        Object.defineProperty(statetxt, "target", {
          writable: false,
          value: document.getElementById("province_id_opt"),
        });
        onBlur(statetxt);

        let countrytxt = new Event("blur");
        Object.defineProperty(countrytxt, "target", {
          writable: false,
          value: document.getElementById("country_id_opt"),
        });
        onBlur(countrytxt);

        let businesstxt = new Event("blur");
        Object.defineProperty(businesstxt, "target", {
          writable: false,
          value: document.getElementById("business_nature_text"),
        });
        onBlur(businesstxt);

        let positiontxt = new Event("blur");
        Object.defineProperty(positiontxt, "target", {
          writable: false,
          value: document.getElementById("position_text"),
        });
        onBlur(positiontxt);

        let relationshptxt = new Event("blur");
        Object.defineProperty(relationshptxt, "target", {
          writable: false,
          value: document.getElementById("business_relationship_text"),
        });
        onBlur(relationshptxt);

        let startdate = new Event("blur");
        Object.defineProperty(startdate, "target", {
          writable: false,
          value: document.getElementById("start_date_text"),
        });
        onBlur(startdate);

        let hours = new Event("blur");
        Object.defineProperty(hours, "target", {
          writable: false,
          value: document.getElementById("number_of_hour_text"),
        });
        onBlur(hours);

        let hours1 = new Event("blur");
        Object.defineProperty(hours1, "target", {
          writable: false,
          value: document.getElementById("number_of_trading_text"),
        });
        onBlur(hours1);

        let duty = new Event("blur");
        Object.defineProperty(duty, "target", {
          writable: false,
          value: document.getElementById("duties_text"),
        });
        onBlur(duty);
      }
      if (formHasChanged === true) {
        await call();
      }

      if (formHasError === false && formHasChanged === false) {
        props.toggleForm(FORM_ID.CCB_INTRO);
      }
    }
  };

  useEffect(() => {
    if (country === "US") {
      setStateMandatory(true);
    } else {
      setStateMandatory(false);
      setState("");
      // let obj_error = document.getElementById("state_text_error");
      // let countryCntrl = document.getElementById("state_id_opt");
      // countryCntrl.removeAttribute("style");
      // obj_error.style.display = "none";
    }
  }, [country]);

  useEffect(() => {
    if (formHasError === false && formHasChanged === false) {
      props.toggleForm(FORM_ID.CCB_INTRO);
    }
  }, [formHasChanged, formHasError]);

  const call = async () => {
    if (formHasChanged) {
      await saveData();
      setFormHasChanged(false);
    }
  };
  useEffect(() => {}, [formHasError]);

  function handleChange(event) {
    setFormHasChanged(true);
    setFormHasError(false);
    if (event.target.id.includes("engagedInBusiness")) {
      setEngagedInBusiness(event.target.value);
      setFormHasError(false);
      if (engagedInBusiness === "N") {
        setOtherBusinessName("");
        setInvestmentRelated("");
        setAddress("");
        setCity("");
        setState("");
        setZip("");
        setCountry("");
        setBusinessNature("");
        setTitle("");
        setRelationShipWithBusiness("");
        setStartDate("");
        setNumberOfHours("");
        setNumberOfHoursTrading("");
        setDuties("");
        setStateMandatory(false);
      }
      let obj_error = document.getElementById("engagedInBusiness_text_error");

      obj_error.style.display = "none";
      let engagedInBusiness_yes = document.getElementById(
        "engagedInBusiness_yes"
      );
      engagedInBusiness_yes.classList.remove("is-invalid");
      let engagedInBusiness_no = document.getElementById(
        "engagedInBusiness_no"
      );
      engagedInBusiness_no.classList.remove("is-invalid");
    }
    if (event.target.id === "otherBusinessName_text") {
      setOtherBusinessName(event.target.value);
    }
    if (event.target.id === "address_text") {
      setAddress(event.target.value);
    }
    if (event.target.id.includes("investmentRelated")) {
      setInvestmentRelated(event.target.value);
      setFormHasError(false);
      let obj_error = document.getElementById("investmentRelated_error");

      obj_error.style.display = "none";
      let investmentRelated_yes = document.getElementById(
        "investmentRelated_yes"
      );
      investmentRelated_yes.classList.remove("is-invalid");
      let investmentRelated_no = document.getElementById(
        "investmentRelated_no"
      );
      investmentRelated_no.classList.remove("is-invalid");
    }
    if (event.target.id.includes("country_id_opt")) {
      setCountry(event.target.value);
      setFormHasError(false);
      setFormHasChanged(true);
      // event.target.style.border = "1px solid #6b748e";
      event.target.style.color = "#6b748e";
      let countryCntrl = document.getElementById("country_id_opt");
      countryCntrl.removeAttribute("style");
      let obj_error = document.getElementById("country_text_error");
      obj_error.style.display = "none";
      if (event.target.value !== "US") {
        let stateCntrl = document.getElementById("province_id_opt");
        stateCntrl.removeAttribute("style");
        let obj_error = document.getElementById("province_id_opt_error");
        obj_error.style.display = "none";
      }
    }
    if (event.target.id.includes("province_id_opt")) {
      setState(event.target.value);
      setFormHasError(false);
      setFormHasChanged(true);
      // event.target.style.border = "1px solid #6b748e";
      let countryCntrl = document.getElementById("province_id_opt");
      countryCntrl.removeAttribute("style");
      let obj_error = document.getElementById("province_id_opt_error");
      obj_error.style.display = "none";

      // countryCntrl.style.border = "1px solid #6b748e";
      // countryCntrl.style.color = "#6b748e";
    }
  }

  function onBlur(event) {
    let obj_error;
    let hasError = false;
    if (event.target.id.includes("engagedInBusiness")) {
      obj_error = document.getElementById("engagedInBusiness_text_error");
      if (engagedInBusiness === "") {
        setFormHasError(true);
        let obj_error = document.getElementById("engagedInBusiness_text_error");

        obj_error.style.display = "block";
        let engagedInBusiness_yes = document.getElementById(
          "engagedInBusiness_yes"
        );
        engagedInBusiness_yes.classList.add("is-invalid");
        let engagedInBusiness_no = document.getElementById(
          "engagedInBusiness_no"
        );
        engagedInBusiness_no.classList.add("is-invalid");
        let label_yes = document.getElementById("label_engagedInBusiness_yes");
        label_yes.style.color = "#555";
        let label_no = document.getElementById("label_engagedInBusiness_no");
        label_no.style.color = "#555";
      } else {
        let obj_error = document.getElementById("engagedInBusiness_text_error");

        obj_error.style.display = "none";
        let engagedInBusiness_yes = document.getElementById(
          "engagedInBusiness_yes"
        );
        engagedInBusiness_yes.classList.remove("is-invalid");
        let engagedInBusiness_no = document.getElementById(
          "engagedInBusiness_no"
        );
        engagedInBusiness_no.classList.remove("is-invalid");
      }
    }

    if (engagedInBusiness === "Y") {
      if (
        cntrlWithFocus.includes("option") &&
        cntrlWithFocus !== event.target.id
      ) {
        //do nothing
      } else if (event.target.id.includes("otherBusinessName_text")) {
        obj_error = document.getElementById("otherBusinessName_error");
        if (otherBusinessName === "" || otherBusinessName.length < 2) {
          setFormHasError(true);
          hasError = true;
        } else {
          // hasError = false;
        }
      } else if (event.target.id.includes("investmentRelated")) {
        obj_error = document.getElementById("investmentRelated_error");
        if (investmentRelated === "") {
          hasError = true;
          setFormHasError(true);
        }
      } else if (event.target.id.includes("address_text")) {
        obj_error = document.getElementById("address_error");
        if (address === "" || address.length < 2) {
          hasError = true;
          setFormHasError(true);
        }
      } else if (event.target.id.includes("city_text")) {
        obj_error = document.getElementById("city_text_error");
        if (city === "" || city.length < 2) {
          hasError = true;
          setFormHasError(true);
        }
      } else if (event.target.id.includes("zip_text")) {
        obj_error = document.getElementById("zip_error");
        if (zip === "" || zip.length < 5) {
          hasError = true;
          setFormHasError(true);
        }
      } else if (event.target.id.includes("province_id_opt")) {
        let countryCntrl = document.getElementById("province_id_opt");
        obj_error = document.getElementById("province_id_opt_error");

        if (state === "" && stateMandatory) {
          event.target.style.border = "1px solid #e21224";
          event.target.style.color = "#e21224";
          obj_error.style.display = "block";
          event.target.classList.add("validation-error");
          hasError = true;
          setFormHasError(true);
        } else {
          countryCntrl.removeAttribute("style");
          obj_error.style.display = "none";
        }
      } else if (event.target.id.includes("country_id_opt")) {
        let countryCntrl = document.getElementById("country_id_opt");
        obj_error = document.getElementById("country_text_error");
        if (country === "") {
          event.target.style.border = "1px solid #e21224";
          event.target.style.color = "#e21224";
          obj_error.style.display = "block";
          event.target.classList.add("validation-error");
          hasError = true;
          setFormHasError(true);
        } else {
          countryCntrl.removeAttribute("style");
          obj_error.style.display = "none";
        }
      } else if (event.target.id.includes("business_nature_text")) {
        obj_error = document.getElementById("business_nature_text_error");
        if (businessNature === "" || businessNature.length < 2) {
          hasError = true;
          setFormHasError(true);
        }
      } else if (event.target.id.includes("position_text")) {
        obj_error = document.getElementById("position_text_error");
        if (title === "" || title.length < 2) {
          hasError = true;
          setFormHasError(true);
        }
      } else if (event.target.id.includes("business_relationship_text")) {
        obj_error = document.getElementById("business_relationship_text_error");
        if (
          relationshipWithBusiness === "" ||
          relationshipWithBusiness.length < 2
        ) {
          hasError = true;
          setFormHasError(true);
        }
      } else if (event.target.id.includes("start_date_text")) {
        obj_error = document.getElementById("start_date_text_error");
        let currDate3 = Moment();
        let start_date =
          startDate === "" ? "" : new Moment(startDate, "MM/YYYY");
        let yearDiff3 = currDate3.diff(start_date, "year");
        if (startDate.includes("_") || startDate === "") {
          hasError = true;
          obj_error.innerText = "Please enter a valid date";
          setFormHasError(true);
        } else if (!isValidDate(event.target.value)) {
          obj_error.innerText = "Please enter a valid date";
          hasError = true;
          setFormHasError(true);
        } else if (yearDiff3 > 100) {
          obj_error.innerText = "Start date cannot be more than 100 years ago";
          hasError = true;
          setFormHasError(true);
        } else if (start_date > currDate3) {
          obj_error.innerText = "Start date cannot be a future date";
          hasError = true;
          setFormHasError(true);
        }
      } else if (event.target.id.includes("number_of_hour_text")) {
        obj_error = document.getElementById("number_of_hour_text_error");
        if (numberOfHours === "" || numberOfHours.length < 1) {
          hasError = true;
          setFormHasError(true);
        }
      } else if (event.target.id.includes("number_of_trading_text")) {
        obj_error = document.getElementById("number_of_trading_text_error");
        if (numberOfHoursTrading === "" || numberOfHoursTrading.length < 1) {
          hasError = true;
          setFormHasError(true);
        }
      } else if (event.target.id.includes("duties_text")) {
        obj_error = document.getElementById("duties_text_error");
        if (duties === "" || duties.length < 1) {
          hasError = true;
          setFormHasError(true);
        }
      }
    }

    if (hasError) {
      setFormHasError(true);
      event.target.style.border = "1px solid #e21224";
      event.target.style.color = "#e21224";
      obj_error.style.display = "block";
      event.target.classList.add("validation-error");
    } else {
      event.target.removeAttribute("style");
      // event.target.style.border = "1px solid #6b748e";
      // event.target.style.color = "#6b748e";
      obj_error.style.display = "none";
      event.target.classList.remove("validation-error");
      // if (event.target.id.includes("country_id_opt")) {
      //   let countryCntrl = document.getElementById("country_id_opt");
      //   obj_error = document.getElementById("country_text_error");
      //   if (country === "" || event.target.value === "") {
      //     event.target.style.border = "1px solid #e21224";
      //     event.target.style.color = "#e21224";
      //     obj_error.style.display = "block";
      //     event.target.classList.add("validation-error");
      //     setFormHasError(true);
      //   } else {
      //     event.target.style.border = "1px solid #6b748e";
      //     event.target.style.color = "#6b748e";
      //     obj_error.style.display = "none";
      //     event.target.classList.remove("validation-error");
      //   }
      // }
    }
  }

  const onFocusState = () => {
    setCntrlWithFocus("");
    let inputs = document.querySelectorAll("button, a");
    try {
      for (let i = 0; i < inputs.length; ++i) {
        inputs[i].addEventListener("focusout", (event) => {
          if (event.relatedTarget == null) {
            setCntrlWithFocus("");
          } else {
            setCntrlWithFocus(event.relatedTarget.id);
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleCloseBackModal = () => {
    setShowBackModal(false);
  };

  const handleBackClick = () => {
    handleCloseBackModal();
    props.toggleForm(FORM_ID.PERSONAL_SALES_RECORD);
  };

  function handleShowBackModal() {
    if (formHasChanged) {
      setShowBackModal(true);
    } else {
      if (props.psrData > 0) {
        props.toggleForm(FORM_ID.PERSONAL_SALES_RECORD);
      } else if (props.psrData <= 0) {
        props.toggleForm(FORM_ID.EMPLOYMENT_HISTORY);
      }
    }
  }

  const saveData = async () => {
    let data;
    if (engagedInBusiness === "N") {
      data = {
        candidateid: props.candidateId,
        currentlyengaged: false,
      };
    } else {
      data = {
        candidateid: props.candidateId,
        currentlyengaged: true,
        businessname: otherBusinessName,
        investrelated: investmentRelated === "Y" ? true : false,
        address1: address,
        city: city,
        state: state,
        zipcode: zip,
        country: country,
        businessnature: businessNature,
        title: title,
        businessrelationship: relationshipWithBusiness,
        startdate: startDate,
        hourspermonth: parseInt(numberOfHours),
        securitytradinghours: parseInt(numberOfHoursTrading),
        businessduties: duties,
      };
    }

    await saveOtherBusinessData(props.subId, data);
  };

  const fetchData = async () => {
    let result = await getOtherBusiness(props.subId);
    if (result !== undefined) {
      await setData(result[0]);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (data !== undefined) {
      if (!data.currentlyengaged) {
        setEngagedInBusiness("N");
      } else {
        setEngagedInBusiness("Y");
        setOtherBusinessName(data.businessname);
        setInvestmentRelated(data.investrelated === true ? "Y" : "N");
        setAddress(data.address1);
        setCity(data.city);
        setState(data.state);
        setZip(data.zipcode);
        setCountry(data.country);
        setBusinessNature(data.businessnature);
        setTitle(data.title);
        setRelationShipWithBusiness(data.businessrelationship);
        setStartDate(Moment(data.startdate, "MM/YYYY").format("MM/YYYY"));
        setNumberOfHours(
          data.hourspermonth !== null ? data.hourspermonth.toString() : ""
        );
        setNumberOfHoursTrading(
          data.securitytradinghours !== null
            ? data.securitytradinghours.toString()
            : ""
        );
        setDuties(data.businessduties);
      }
    } else {
      setEngagedInBusiness("");
      setOtherBusinessName("");
      setInvestmentRelated("");
      setAddress("");
      setCity("");
      setState("");
      setZip("");
      setCountry("");
      setBusinessNature("");
      setTitle("");
      setRelationShipWithBusiness("");
      setStartDate("");
      setNumberOfHours("");
      setNumberOfHoursTrading("");
      setDuties("");
    }
    UtagTracking.sendPageNameView(
      "otherbusinessform",
      props.candidateId,
      props.agencynumber,
      props.contracttypeid
    );
  }, [data]);

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="eyebrow">
            <strong>Employment History</strong>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <h1>Other Business</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <span className="required-field-text">* = Required Field</span>
        </div>
      </div>

      <h3 style={{ color: "black", paddingTop: "10px" }}>
        We will need to capture the information for any other business that you
        are compensated for. Under FINRA Rule 3280, you must disclose the
        following information to the Firm in which you are applying to be
        contracted with.
      </h3>
      <div style={{ paddingTop: "20px" }}>
        Are you currently engaged in any other business either as a proprietor,
        partner, officer, director, employee, trustee, agent or otherwise?*
        (Please exclude non investment-related activity that is exclusively
        charitable, civic, religious or fraternal and is recognized as tax
        exempt.)
        <div className="custom-control" style={{ paddingTop: "20px" }}>
          <div className="custom-control custom-radio">
            <input
              type="radio"
              name="engagedInBusiness"
              id="engagedInBusiness_yes"
              className="custom-control-input"
              checked={engagedInBusiness === "Y"}
              value="Y"
              onChange={(e) => handleChange(e)}
            />
            <label
              htmlFor="engagedInBusiness_yes"
              className="custom-control-label"
              id="label_engagedInBusiness_yes"
            >
              Yes
            </label>
          </div>
          <div className="custom-control custom-radio">
            <input
              type="radio"
              name="engagedInBusiness"
              className="custom-control-input"
              id="engagedInBusiness_no"
              checked={engagedInBusiness === "N"}
              value="N"
              onChange={(e) => handleChange(e)}
            />
            <label
              htmlFor="engagedInBusiness_no"
              className="custom-control-label"
              id="label_engagedInBusiness_no"
            >
              No
            </label>
          </div>
          <div id="engagedInBusiness_text_error" className="invalid-feedback">
            Please choose an answer
          </div>
        </div>
      </div>
      {engagedInBusiness === "Y" && (
        <div style={{ paddingTop: "20px" }}>
          If YES, please provide the following details:
          <div style={{ paddingTop: "20px" }}>
            <h4>Name of the other business*</h4>
            <div>
              <input
                type="text"
                id="otherBusinessName_text"
                className="form-control col-md-4"
                value={otherBusinessName}
                onBlur={onBlur}
                minLength={2}
                maxLength={50}
                placeholder="Name of business"
                onChange={handleChange}
                autoComplete="new-password"
              />
              <div id="otherBusinessName_error" className="invalid-feedback">
                Please enter a valid Name of other business
              </div>
            </div>
          </div>
          <div>
            <h4 style={{ paddingTop: "20px" }}>
              Is this business investment-related?*
            </h4>
            <div className="custom-control">
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  name="investmentRelated"
                  id="investmentRelated_yes"
                  className="custom-control-input"
                  checked={investmentRelated === "Y"}
                  value="Y"
                  onChange={(e) => handleChange(e)}
                  // onKeyDown={onKeyDown}
                />
                <label
                  htmlFor="investmentRelated_yes"
                  className="custom-control-label"
                  id="label_investmentRelated_yes"
                >
                  Yes
                </label>
              </div>
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  name="investmentRelated"
                  className="custom-control-input"
                  id="investmentRelated_no"
                  checked={investmentRelated === "N"}
                  value="N"
                  onChange={(e) => handleChange(e)}
                />
                <label
                  htmlFor="investmentRelated_no"
                  className="custom-control-label"
                  id="label_investmentRelated_no"
                >
                  No
                </label>
              </div>
              <div id="investmentRelated_error" className="invalid-feedback">
                Please choose an answer
              </div>
            </div>
          </div>
          <div style={{ paddingTop: "20px" }}>
            <h4>Address of other business*</h4>
            <div>
              <input
                id="address_text"
                className="form-control"
                value={address}
                onBlur={onBlur}
                minLength={2}
                maxLength={50}
                onChange={(e) => handleChange(e)}
                autoComplete="off"
              />
              <div id="address_error" className="invalid-feedback">
                Please enter a valid Address
              </div>
            </div>
          </div>
          <div style={{ paddingTop: "20px" }}>
            <h4>City*</h4>
            <div>
              <input
                id="city_text"
                className="form-control"
                value={city}
                onBlur={onBlur}
                minLength={2}
                maxLength={100}
                aria-label="City"
                onChange={(e) => {
                  setCity(e.target.value);
                  setFormHasError(false);
                  setFormHasChanged(true);
                }}
                autoComplete="off"
              />
              <div id="city_text_error" className="invalid-feedback">
                Please enter a valid City
              </div>
            </div>
          </div>
          <div className="row" style={{ paddingTop: "20px" }}>
            <div className="col">
              <h4>State / Province{stateMandatory ? "*" : ""}</h4>

              <DropdownButton
                id="province_id_opt"
                type="default"
                variant="secondary"
                title={state}
                selected={false}
                autoComplete="new-address"
                onSelect={(key, event) => {
                  event.target.id = "province_id_opt";
                  event.target.value = key;
                  handleChange(event);
                }}
                onBlur={onBlur}
                onClick={clickFunc}
                onFocus={onFocusState}
              >
                <Dropdown.Item eventKey={""} id="state_option"></Dropdown.Item>
                <Dropdown.Item eventKey={"PR"} id="state_option">
                  PR - Puerto Rico
                </Dropdown.Item>
                <Dropdown.Item eventKey={"AL"} id="state_option">
                  AL - Alabama
                </Dropdown.Item>
                <Dropdown.Item eventKey={"AK"} id="state_option">
                  AK - Alaska
                </Dropdown.Item>
                <Dropdown.Item eventKey={"AZ"} id="state_option">
                  AZ - Arizona
                </Dropdown.Item>
                <Dropdown.Item eventKey={"AR"} id="state_option">
                  AR - Arkansas
                </Dropdown.Item>
                <Dropdown.Item eventKey={"CA"} id="state_option">
                  CA - California
                </Dropdown.Item>
                <Dropdown.Item eventKey={"CO"} id="state_option">
                  CO - Colorodo
                </Dropdown.Item>
                <Dropdown.Item eventKey={"CT"} id="state_option">
                  CT - Connecticut
                </Dropdown.Item>
                <Dropdown.Item eventKey={"DE"} id="state_option">
                  DE - Delaware
                </Dropdown.Item>
                <Dropdown.Item eventKey={"DC"} id="state_option">
                  DC - District of Columbia
                </Dropdown.Item>
                <Dropdown.Item eventKey={"FL"} id="state_option">
                  FL - Florida
                </Dropdown.Item>
                <Dropdown.Item eventKey={"GA"} id="state_option">
                  GA - Georgia
                </Dropdown.Item>
                <Dropdown.Item eventKey={"HI"} id="state_option">
                  HI - Hawaii
                </Dropdown.Item>
                <Dropdown.Item eventKey={"ID"} id="state_option">
                  ID - Idaho
                </Dropdown.Item>
                <Dropdown.Item eventKey={"IL"} id="state_option">
                  IL - Illinois
                </Dropdown.Item>
                <Dropdown.Item eventKey={"IN"} id="state_option">
                  IN - Indiana
                </Dropdown.Item>
                <Dropdown.Item eventKey={"IA"} id="state_option">
                  IA - Iowa
                </Dropdown.Item>
                <Dropdown.Item eventKey={"KS"} id="state_option">
                  KS - Kansas
                </Dropdown.Item>
                <Dropdown.Item eventKey={"KY"} id="state_option">
                  KY - Kentucky
                </Dropdown.Item>
                <Dropdown.Item eventKey={"LA"} id="state_option">
                  LA - Louisiana
                </Dropdown.Item>
                <Dropdown.Item eventKey={"ME"} id="state_option">
                  ME - Maine
                </Dropdown.Item>
                <Dropdown.Item eventKey={"MD"} id="state_option">
                  MD - Maryland
                </Dropdown.Item>
                <Dropdown.Item eventKey={"MA"} id="state_option">
                  MA - Massachusetts
                </Dropdown.Item>
                <Dropdown.Item eventKey={"MI"} id="state_option">
                  MI - Michigan
                </Dropdown.Item>
                <Dropdown.Item eventKey={"MN"} id="state_option">
                  MN - Minnesota
                </Dropdown.Item>
                <Dropdown.Item eventKey={"MS"} id="state_option">
                  MS - Mississippi
                </Dropdown.Item>
                <Dropdown.Item eventKey={"MO"} id="state_option">
                  MO - Missouri
                </Dropdown.Item>
                <Dropdown.Item eventKey={"MT"} id="state_option">
                  MT - Montana
                </Dropdown.Item>
                <Dropdown.Item eventKey={"NE"} id="state_option">
                  NE - Nebraska
                </Dropdown.Item>
                <Dropdown.Item eventKey={"NV"} id="state_option">
                  NV - Nevada
                </Dropdown.Item>
                <Dropdown.Item eventKey={"NH"} id="state_option">
                  NH - New Hampshire
                </Dropdown.Item>
                <Dropdown.Item eventKey={"NJ"} id="state_option">
                  NJ - New Jersey
                </Dropdown.Item>
                <Dropdown.Item eventKey={"NM"} id="state_option">
                  NM - New Mexico
                </Dropdown.Item>
                <Dropdown.Item eventKey={"NY"} id="state_option">
                  NY - New York
                </Dropdown.Item>
                <Dropdown.Item eventKey={"NC"} id="state_option">
                  NC - North Carolina
                </Dropdown.Item>
                <Dropdown.Item eventKey={"ND"} id="state_option">
                  ND - North Dakota
                </Dropdown.Item>
                <Dropdown.Item eventKey={"OH"} id="state_option">
                  OH - Ohio
                </Dropdown.Item>
                <Dropdown.Item eventKey={"OK"} id="state_option">
                  OK - Oklahoma
                </Dropdown.Item>
                <Dropdown.Item eventKey={"OR"} id="state_option">
                  OR - Oregon
                </Dropdown.Item>
                <Dropdown.Item eventKey={"PA"} id="state_option">
                  PA - Pennsylvania
                </Dropdown.Item>
                <Dropdown.Item eventKey={"RI"} id="state_option">
                  RI - Rhode Island
                </Dropdown.Item>
                <Dropdown.Item eventKey={"SC"} id="state_option">
                  SC - South Carolina
                </Dropdown.Item>
                <Dropdown.Item eventKey={"SD"} id="state_option">
                  SD - South Dakota
                </Dropdown.Item>
                <Dropdown.Item eventKey={"TN"} id="state_option">
                  TN - Tennessee
                </Dropdown.Item>
                <Dropdown.Item eventKey={"TX"} id="state_option">
                  TX - Texas
                </Dropdown.Item>
                <Dropdown.Item eventKey={"UT"} id="state_option">
                  UT - Utah
                </Dropdown.Item>
                <Dropdown.Item eventKey={"VT"} id="state_option">
                  VT - Vermont
                </Dropdown.Item>
                <Dropdown.Item eventKey={"VA"} id="state_option">
                  VA - Virginia
                </Dropdown.Item>
                <Dropdown.Item eventKey={"WA"} id="state_option">
                  WA - Washington
                </Dropdown.Item>
                <Dropdown.Item eventKey={"WV"} id="state_option">
                  WV - West Virginia
                </Dropdown.Item>
                <Dropdown.Item eventKey={"WI"} id="state_option">
                  WI - Wisconsin
                </Dropdown.Item>
                <Dropdown.Item eventKey={"WY"} id="state_option">
                  WY - Wyoming
                </Dropdown.Item>
              </DropdownButton>
              <div id={"province_id_opt_error"} className="invalid-feedback">
                Please select a valid State/Province
              </div>
            </div>
          </div>
          <div style={{ paddingTop: "20px" }}>
            <h4>ZIP Code*</h4>
            <div>
              <input
                id="zip_text"
                className="form-control"
                value={zip}
                onBlur={onBlur}
                minLength={5}
                maxLength={10}
                autoComplete="off"
                onChange={(e) => {
                  setZip(e.target.value.replace(/[^\w\s]/gi, ""));
                  setFormHasError(false);
                  setFormHasChanged(true);
                }}
              />
              <div id="zip_error" className="invalid-feedback">
                Please enter a valid Zip Code
              </div>
            </div>
          </div>
          <div style={{ paddingTop: "20px" }}>
            <h4>Country*</h4>

            <DropdownButton
              id="country_id_opt"
              type="default"
              variant="secondary"
              title={country}
              selected={false}
              autoComplete="new-address"
              onSelect={(key, event) => {
                event.target.id = "country_id_opt";
                event.target.value = key;
                handleChange(event);
              }}
              onBlur={onBlur}
              onClick={clickFunc}
              onFocus={onFocusState}
              // disabled={props.busAddrCheck === true}
            >
              {COUNTRIES.map((country, index) => {
                return (
                  <div key={index}>
                    <Dropdown.Item eventKey={country.code} id="country_option">
                      {country.code + " - " + country.name}
                    </Dropdown.Item>
                  </div>
                );
              })}
            </DropdownButton>
            <div id={"country_text_error"} className="invalid-feedback">
              Please select a valid Country
            </div>
          </div>
          <div style={{ paddingTop: "20px" }}>
            <h4>What is the nature of this business?*</h4>
            <div>
              <input
                id="business_nature_text"
                className="form-control col-md-4"
                value={businessNature}
                onBlur={onBlur}
                minLength={2}
                maxLength={100}
                autoComplete="off"
                onChange={(e) => {
                  setBusinessNature(e.target.value);
                  setFormHasError(false);
                  setFormHasChanged(true);
                }}
              />
              <div id="business_nature_text_error" className="invalid-feedback">
                Please enter the nature of this business
              </div>
            </div>
          </div>
          <div style={{ paddingTop: "20px" }}>
            <h4>What is your position or title?*</h4>
            <div>
              <input
                id="position_text"
                className="form-control col-md-4"
                value={title}
                onBlur={onBlur}
                minLength={2}
                autoComplete="off"
                maxLength={100}
                onChange={(e) => {
                  setTitle(e.target.value);
                  setFormHasError(false);
                  setFormHasChanged(true);
                }}
              />
              <div id="position_text_error" className="invalid-feedback">
                Please enter your position or title
              </div>
            </div>
          </div>
          <div style={{ paddingTop: "20px" }}>
            <h4>What is your relationship with this business?*</h4>
            <div>
              <textarea
                id="business_relationship_text"
                className="form-control"
                value={relationshipWithBusiness}
                rows={3}
                onBlur={onBlur}
                placeholder="Please explain in a few sentences."
                minLength={2}
                maxLength={500}
                autoComplete="off"
                onChange={(e) => {
                  setRelationShipWithBusiness(e.target.value);
                  setFormHasError(false);
                  setFormHasChanged(true);
                }}
                required
              />
              <div
                id="business_relationship_text_error"
                className="invalid-feedback"
              >
                Please enter your relationship with this business
              </div>
            </div>
          </div>
          <div style={{ paddingTop: "20px" }}>
            <h4>The start date of your relationship*</h4>
            <div>
              <InputMask
                id="start_date_text"
                mask="99/9999"
                maskChar="_"
                className="form-control"
                placeholder="MM/YYYY"
                autoComplete="new-password"
                value={startDate}
                onBlur={onBlur}
                onChange={(e) => {
                  setStartDate(e.target.value);
                  setFormHasError(false);
                  setFormHasChanged(true);
                }}
                // disabled={this.state.is_current_address}
              />
              <div id="start_date_text_error" className="invalid-feedback">
                Please enter a valid date
              </div>
            </div>
          </div>
          <div style={{ paddingTop: "20px" }}>
            <h4>
              What is the approximate number of hours/month you devoted to this
              business?*
            </h4>

            <input
              id="number_of_hour_text"
              className="form-control col-md-4"
              value={numberOfHours}
              autoComplete="off"
              onBlur={onBlur}
              minLength={1}
              maxLength={30}
              onChange={(e) => {
                setNumberOfHours(e.target.value);
                setFormHasError(false);
                setFormHasChanged(true);
              }}
            />
            <div id="number_of_hour_text_error" className="invalid-feedback">
              Please enter how many hours/month you devote to this business
            </div>
          </div>
          <div style={{ paddingTop: "20px" }}>
            <h4>
              What are the number of hours you devote to the other business
              during securities trading hours?*
            </h4>

            <input
              id="number_of_trading_text"
              className="form-control col-md-4"
              value={numberOfHoursTrading}
              onBlur={onBlur}
              autoComplete="off"
              minLength={1}
              maxLength={10}
              onChange={(e) => {
                setNumberOfHoursTrading(e.target.value);
                setFormHasError(false);
                setFormHasChanged(true);
              }}
            />
            <div id="number_of_trading_text_error" className="invalid-feedback">
              Please enter how many hours you devote to the other business
              during securities trading hours
            </div>
          </div>
          <div style={{ paddingTop: "20px" }}>
            <h4>
              Briefly describe your duties relating to the other business*
            </h4>
            <textarea
              id="duties_text"
              className="form-control"
              value={duties}
              autoComplete="off"
              rows={3}
              onBlur={onBlur}
              placeholder="Please explain in a few sentences."
              minLength={1}
              maxLength={500}
              onChange={(e) => {
                setDuties(e.target.value);
                setFormHasError(false);
                setFormHasChanged(true);
              }}
            />
            <div id="duties_text_error" className="invalid-feedback">
              Please describe your duties relating to the other business
            </div>
          </div>
        </div>
      )}

      <div
        className="row"
        style={{ paddingTop: "20px", paddingBottom: "20px" }}
      >
        <div
          className="col-md-12"
          style={{ float: "right", textAlign: "right" }}
        >
          <span
            onClick={handleShowBackModal}
            style={{
              marginRight: "25px",
              color: "#3174af",
              cursor: "pointer",
            }}
          >
            <strong> Back </strong>
          </span>
          <Button label="Next" onClick={() => handleNext("nextclick")} />
        </div>
      </div>
      <BackModal
        isOpen={showBackModal}
        closeBackModal={handleCloseBackModal}
        backClick={handleBackClick}
      />
    </>
  );
};

export default OtherBusinessForm;
