import { fetchWrapper } from "../../helpers";
import { RequestMethodTypes } from "../../helpers/constants";

export const insertTerminationDRP = async (oktasubid, data) => {
  const {
    data: { ccbdrpid },
  } = await fetchWrapper({
    method: RequestMethodTypes.PUT,
    url: `drp/${oktasubid}/termination`,
    data,
  });

  return ccbdrpid;
};
export const updateTerminationDRP = async (oktasubid, data) => {
  const { ccbdrpid } = await fetchWrapper({
    method: RequestMethodTypes.POST,
    url: `drp/${oktasubid}/termination`,
    data,
  });
  return ccbdrpid;
};

/**
 * PUT /v1/candidate/{oktaSubId}/ccb14/drp/{drpTypeId}/cleanup
 * @param {string} oktaSubId oktaSubId
 * @param {string} drpTypeId drpTypeId
 * @body { data: { ind: [qk1, qk2], org: [qk1, qk3] }}
 * @returns {Promise<Object>}
 */
export const cleanupCCB14Drp = async ({ oktaSubId, drpTypeId, data }) => {
  const ccb = await fetchWrapper({
    method: RequestMethodTypes.PUT,
    url: `${oktaSubId}/ccb14/drp/${drpTypeId}/cleanup`,
    data: data,
  });

  return ccb?.data;
};

export const getTerminationDRP = async (oktasubid) => {
  const response = await fetchWrapper({
    method: RequestMethodTypes.GET,
    url: `drp/${oktasubid}/termination`,
  });

  return response;
};

export const deleteTerminationDRP = async (oktasubid, ccbdrpid) => {
  const response = await fetchWrapper({
    method: RequestMethodTypes.POST,
    url: `drp/${oktasubid}/deleteterminationdrp`,
    data: {
      ccbdrpid,
    },
  });

  return response;
};
