import { RequestMethodTypes } from "../../helpers/constants";
import { fetchWrapper } from "../../helpers";

/**
 * GET /v1/candidate/{oktaSubId}/ccb14/drp/{drpTypeId}:
 * @param {string} oktaSubId oktaSubId
 * @param {string} drpTypeId drpTypeId
 * @returns {Promise<Object>}
 */
export const getCCB14DRP = async ({ oktaSubId, drpTypeId }) => {
  const ccb = await fetchWrapper({
    method: RequestMethodTypes.GET,
    url: `${oktaSubId}/ccb14/drp/${drpTypeId}`,
  });

  return ccb?.data;
};

/**
 * PUT /v1/candidate/{oktaSubId}/ccb14/drp/{drpTypeId}:
 * @param {string} oktaSubId oktaSubId
 * @param {string} drpTypeId drpTypeId
 * @body { data: { groupKey, isNewData, isDraft, data }}
 * @returns {Promise<Object>}
 */
export const insertUpdateCCB14DRP = ({ oktaSubId, drpTypeId, data }) =>
  fetchWrapper({
    method: RequestMethodTypes.PUT,
    url: `${oktaSubId}/ccb14/drp/${drpTypeId}`,
    data,
  });

/**
 * PUT /v1/candidate/{oktaSubId}/ccb14/drp/{drpTypeId}/cleanup
 * @param {string} oktaSubId oktaSubId
 * @param {string} drpTypeId drpTypeId
 * @body { data: { ind: [qk1, qk2], org: [qk1, qk3] }}
 * @returns {Promise<Object>}
 */
export const cleanupCCB14Drp = async ({ oktaSubId, drpTypeId, data }) => {
  const ccb = await fetchWrapper({
    method: RequestMethodTypes.PUT,
    url: `${oktaSubId}/ccb14/drp/${drpTypeId}/cleanup`,
    data,
  });

  return ccb?.data;
};

/**
 * GET /v1/candidate/{oktaSubId}/ccb14/drp/{drpTypeId}/reg:
 * @param {string} oktaSubId oktaSubId
 * @param {string} drpTypeId drpTypeId
 * @returns {Promise<Object>} { 
       ind: [{ qK, index, isDraft, description, date, orgName }, ...], 
       org: [{...}, ...]
    }
 */
export const getCCBDRPRegList = async ({ oktaSubId, drpTypeId }) => {
  const ccb = await fetchWrapper({
    method: RequestMethodTypes.GET,
    url: `${oktaSubId}/ccb14/drp/${drpTypeId}/reg`,
  });

  return ccb?.data;
};

/**
 * PUT /v1/candidate/{oktaSubId}/ccb14/drp/{drpTypeId}/reg/:section/:index:
 * @param {string} oktaSubId oktaSubId
 * @param {string} drpTypeId drpTypeId
 * @param {string} section section
 * @param {number} index index
 * @returns {Promise<Object>}
 */
export const updateSingleCCB14DrpReg = async ({
  oktaSubId,
  drpTypeId,
  data,
  section,
  index,
}) => {
  const ccb = await fetchWrapper({
    method: RequestMethodTypes.PUT,
    url: `${oktaSubId}/ccb14/drp/${drpTypeId}/reg/${section}/${index}`,
    data,
  });

  return ccb?.data;
};

/**
 * DELETE /v1/candidate/{oktaSubId}/ccb14/drp/{drpTypeId}/reg/:section/:questionKey/:index:
 * @param {string} oktaSubId oktaSubId
 * @param {string} drpTypeId drpTypeId
 * @param {string} section section
 * @param {number} index index
 * @param {string} questionKey questionKey
 * @returns {Promise<Object>}
 */
export const deleteSingleCCB14DrpReg = async ({
  oktaSubId,
  drpTypeId,
  section,
  index,
  questionKey,
}) =>
  fetchWrapper({
    method: RequestMethodTypes.DELETE,
    url: `${oktaSubId}/ccb14/drp/${drpTypeId}/reg/${section}/${questionKey}/${index}`,
  });

/**
 * GET /v1/candidate/{oktaSubId}/ccb14/drp/{drpTypeId}/reg/:section/:qKey/:index:
 * @param {string} oktaSubId oktaSubId
 * @param {string} drpTypeId drpTypeId
 * @param {string} section section
 * @param {string} qKey qKey
 * @param {number} index index
 * @returns {Promise<Object>}
 */
export const getSingleCCBDRPRegByQkey = async ({
  oktaSubId,
  section,
  qKey,
  drpTypeId,
  index,
}) => {
  const ccb = await fetchWrapper({
    method: RequestMethodTypes.GET,
    url: `${oktaSubId}/ccb14/drp/${drpTypeId}/reg/${section}/${qKey}/${index}`,
  });

  return ccb?.data;
};
