import { RequestMethodTypes } from "./helpers/constants";
import { fetchWrapper } from "./helpers";

/**
 * GET /v1/candidate/{oktasubid}/residentialhistory/{residentialhistoryid}:
 * @param {number} oktaSubId Okta Sub Id
 * @param {number} residentialHistoryId Residential History Id
 * @returns {Promise<Object>}
 */
export const getResidentialHistoryById = async ({
  oktaSubId,
  residentialHistoryId,
}) => {
  const resHistory = await fetchWrapper({
    method: RequestMethodTypes.GET,
    url: `${oktaSubId}/residentialhistory/${residentialHistoryId}`,
  });

  return resHistory?.data;
};

/**
 * GET /v1/candidate/{oktasubid}/residentialhistory:
 * @param {number} oktaSubId Okta Sub Id
 * @returns {Promise<Object>}
 */
export const getResidentialHistory = async (oktaSubId) => {
  const url = `${oktaSubId}/residentialhistory`;

  const empHistInfo = await fetchWrapper({
    method: RequestMethodTypes.GET,
    url,
  });

  return empHistInfo?.data;
};

/**
 * POST /v1/candidate/{oktasubid}/residentialhistory:
 * @param {number} oktaSubId Candidate Id
 * @param {Object} data Body Object
 * @returns {Promise<void>}
 */
export const insertResidentialHistory = async (oktaSubId, data) => {
  const url = `${oktaSubId}/residentialhistory`;
  const rawResponse = await fetchWrapper({
    method: RequestMethodTypes.POST,
    url,
    data,
  });

  return rawResponse?.data?.payload[0]?.residentialhistoryid;
};

/**
 * PUT /v1/candidate/{candidateid}/residentialhistory/{residentialhistoryid}:
 * @param {number} candidateId Okta Sub Id
 * @param {number} residentialHistoryId Residential History Id
 * @returns {Promise<Object>}
 */
export const updateResidentialHistory = async (
  candidateId,
  residentialHistoryId,
  data
) => {
  const url = `${candidateId}/residentialhistory/${residentialHistoryId}`;
  const rawResponse = await fetchWrapper({
    method: RequestMethodTypes.PUT,
    url,
    data,
  });

  if (rawResponse?.ok) {
    return rawResponse;
  }
};
/**
 * DELETE /v1/candidate/{candidateid}/residentialhistory/{residentialhistoryid}:
 * @param {number} oktaSubId Okta Sub Id
 * @param {number} residentialHistoryId Residential History Id
 * @returns {Promise<Object>}
 */
export const deleteResidentialHistory = async (
  oktaSubId,
  residentialHistoryId
) => {
  const url = `${oktaSubId}/residentialhistory/${residentialHistoryId}`;

  await fetchWrapper({
    method: RequestMethodTypes.DELETE,
    url,
  });
};
