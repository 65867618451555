import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cleanupCCB14Drp } from "../../../../../../api/CCB/DRP/customerComplaintDisclosureDRP";
import { DRP_TYPES } from "../../../../../../api/helpers/constants";
import { FORM_ID } from "../../../../../../constants/Forms";
import { ccbActions, logoutActions } from "../../../../../../store/actions";
import {
  fetchCCB,
  postCCB,
  putCCB,
  putCCBStatus,
} from "../../../../../../store/middlewares";
import { loadingStatus } from "../../../../../../store/state";
import NavigationButtons from "../../../../../SharedComponents/NavigationButtons";
import BackButtonModal from "../../../../Forms/BackButtonModal";
import CustomCard from "../../common/CustomCard";
import DeleteModal from "../../common/DeleteModal";
import Toast from "../../common/Toast";
import {
  INTRO_REV,
  INTRO_TEXT,
  KEYS,
  RADIO,
  SECTION,
  TOAST_MESSAGE,
} from "../../constants";
import {
  getValues,
  isChecked,
  isEachChecked,
  isSomeChecked,
} from "../../helpers";
import { useClickOutside } from "../../hooks/useClickOutside";
import { headerText } from "../constants";
import {
  customerComplaintArbitrationCivilLitigation,
  initialState,
} from "../state";
import CustomSubCategoryCard from "./CustomSubCategoryCard";
import "./CustomerComplaintForm.scss";

const CustomerComplaintForm = ({
  toggleForm,
  oktaSubId,
  header,
  updateCCBForm,
  backFormId,
  nextFormId,
  updateCCB14Step,
  updateCCB14CompletedInd,
  customerComplaintDRPs,
  custCompl,
  setData,
  unchecked,
  setIsLoadingProp,
  isFetchingDRPs = true,
}) => {
  const dispatch = useDispatch();

  const toastRef = useRef(null);
  const containerRef = useRef(null);

  const { error, loading, isFirstLoad, data } = useSelector(
    (state) => state.ccb14
  );

  const [isPageChanged, setIsPageChanged] = useState(false);
  const [isShowDRPCleanupModal, setShowDRPCleanupModal] = useState(false);
  const [selectedSection, setSelectedSection] = useState(null);
  const [drpCleanupList, setDrpCleanupList] = useState(null);

  const isLoading = loading === loadingStatus.PENDING || isFetchingDRPs;
  let isError = error || loading === loadingStatus.FAILED;

  const [isRequired, setIsRequired] = useState(false);
  const [isCardOpen, setIsCardOpen] = useState({
    [SECTION.customerComplaintArbitrationCivilLitigation]: false,
  });
  const [showBackModal, setShowBackModal] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const [isEdit, setIsEdit] = useState({
    [SECTION.customerComplaintArbitrationCivilLitigation]: false,
  });
  const [isSaved, setIsSaved] = useState({
    [SECTION.customerComplaintArbitrationCivilLitigation]: false,
  });

  const valuesA = useMemo(
    () => getValues(data, KEYS.customerComplaintArbitrationCivilLitigation),
    [data]
  );

  const isSaveDisabledA = isEachChecked(valuesA);

  const updateStatus = async (data) => {
    try {
      await dispatch(
        putCCBStatus({ oktaSubId, data: { custcomplaintstatus: data } })
      );
    } catch (err) {
      console.log(err);
    }
  };

  const toggleLeftNav = () => {
    //If all "No" values, update green checkmark on save. If not, keep as 1/2
    const isYes = (currentValue) => currentValue === true;
    const isNo = (currentValue) => currentValue === false;
    let isNoValues = valuesA.every(isNo);
    const isYesValues = valuesA.some(isYes);

    if (isNoValues) {
      updateCCB14CompletedInd({ custCompDisclosureCompleteInd: true });
      updateStatus(null);
    } else if (isYesValues) {
      updateCCB14Step({ custCompDisclosureCompleteStep: "1/2" });
      updateCCB14CompletedInd({ custCompDisclosureCompleteInd: false });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  useEffect(() => {
    try {
      const initData = async () => {
        const { payload } = await dispatch(fetchCCB(oktaSubId));

        if (payload) {
          const valuesA = getValues(
            payload,
            KEYS.customerComplaintArbitrationCivilLitigation
          );
          const a = valuesA.some(isChecked);

          setIsSaved({
            [SECTION.customerComplaintArbitrationCivilLitigation]: a,
          });

          setIsEdit({
            [SECTION.customerComplaintArbitrationCivilLitigation]: !a,
          });
        } else {
          if (!isSaveDisabledA) {
            setIsCardOpen((prevState) => ({
              ...prevState,
              [SECTION.customerComplaintArbitrationCivilLitigation]: true,
            }));
          }
        }
      };

      const a = valuesA.some(isChecked);

      if (loading !== loadingStatus.SUCCEDED) {
        initData();
      } else {
        setIsSaved({
          [SECTION.customerComplaintArbitrationCivilLitigation]: a,
        });

        setIsEdit({
          [SECTION.customerComplaintArbitrationCivilLitigation]: !a,
        });

        if (!a) {
          setIsCardOpen((prevState) => ({
            ...prevState,
            [SECTION.customerComplaintArbitrationCivilLitigation]: true,
          }));
        }

        if (a) {
          setIsCardOpen({ ...initialState });
        }
      }
    } catch (error) {
      console.log({ error });
    }

    return () => {
      const a = valuesA.some(isChecked);

      setIsSaved({
        [SECTION.customerComplaintArbitrationCivilLitigation]: a,
      });

      setIsEdit({
        [SECTION.customerComplaintArbitrationCivilLitigation]: !a,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPageChanged]);

  const [formHasChanged, setFormHasChanged] = useState(false);

  useEffect(() => {
    if (formHasChanged) {
      dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: true }));
    }
  }, [formHasChanged, dispatch]);

  const handleChangeChecked = (e) => {
    const { name, value } = e.target;
    let newValue = value;

    if (isSaved[SECTION.customerComplaintArbitrationCivilLitigation]) {
      updateCCB14Step({ custCompDisclosureCompleteStep: "1/2" });
      updateCCB14CompletedInd({ custCompDisclosureCompleteInd: false });
    }

    setFormHasChanged(true);

    switch (value) {
      case RADIO.yes:
        newValue = true;
        break;
      case RADIO.no:
        newValue = false;
        break;
      default:
        newValue = null;
        break;
    }

    dispatch(ccbActions.setChecked({ [name]: newValue }));
  };

  const handleOnEditClick = (section) => {
    setIsEdit((prevState) => ({ ...prevState, [section]: true }));
  };

  const closeCleanupModalHandler = async () => {
    setShowDRPCleanupModal(false);
  };

  const onCleanupModalHandler = async () => {
    if (selectedSection === null) {
      return;
    }
    setIsLoadingProp(true);

    await cleanupCCB14Drp({
      oktaSubId,
      drpTypeId: DRP_TYPES.Customer,
      data: {
        data: {
          custCompl: unchecked,
        },
      },
    });

    setData();
    await handleOnSaveClick(selectedSection);
    setIsLoadingProp(false);
    setShowDRPCleanupModal(false);
  };

  const handleDeleteOnSave = async (section) => {
    handleCloseToast();
    setSelectedSection(section);
    setDrpCleanupList("");
    let questionKeys = [];

    for (let i in custCompl) {
      if (!questionKeys.includes(custCompl[i].key)) {
        questionKeys.push(custCompl[i].key);
      }
    }

    let deleteKeys = [];
    for (let i in customerComplaintDRPs) {
      for (let j in customerComplaintDRPs[i].complaintdrp
        .drpSelectedCheckList) {
        if (
          !questionKeys.includes(
            customerComplaintDRPs[i].complaintdrp.drpSelectedCheckList[j]
          )
        ) {
          deleteKeys.push({
            qkey: customerComplaintDRPs[i].complaintdrp.drpSelectedCheckList[j],
            isDraft: "No",
            types:
              [customerComplaintDRPs[i].complaintdrp.statusDetailsMatter] || [],
            date:
              [customerComplaintDRPs[i].complaintdrp.custComplaintStatusDate] ||
              [],
            status:
              customerComplaintDRPs[i].complaintdrp
                .custComplaintStatusOption !== ""
                ? customerComplaintDRPs[i].complaintdrp
                    .custComplaintStatusOption
                : "Pending",
            dataIndex: customerComplaintDRPs[i].ccbdrpid,
          });
        }
      }
    }

    if (deleteKeys.length > 0) {
      setDrpCleanupList(deleteKeys);
      setShowDRPCleanupModal(true);
    } else {
      handleOnSaveClick(section);
    }
  };

  const handleOnSaveClick = async (section) => {
    handleCloseToast();

    if (
      isSaveDisabledA &&
      section === SECTION.customerComplaintArbitrationCivilLitigation
    ) {
      setIsCardOpen((prevState) => ({
        ...prevState,
        [SECTION.customerComplaintArbitrationCivilLitigation]: true,
      }));
    }

    setFormHasChanged(false);

    setIsCardOpen((prevState) => ({ ...prevState, [section]: false }));

    if (isFirstLoad) {
      try {
        await dispatch(postCCB({ oktaSubId, data }));
      } catch (err) {
        console.log(err);
        isError = `${err}`;
      }

      setIsSaved((prevState) => ({ ...prevState, [section]: true }));
    } else {
      try {
        await dispatch(putCCB({ oktaSubId, data }));
      } catch (err) {
        console.log(err);
        isError = `${err}`;
      }

      setIsSaved((prevState) => ({ ...prevState, [section]: true }));
    }

    toggleLeftNav();
    setIsEdit((prevState) => ({ ...prevState, [section]: false }));
    setIsCardOpen({
      [SECTION.customerComplaintArbitrationCivilLitigation]: false,
    });
    setShowToast(true);

    window.scrollTo(0, 0);
  };

  const handleCloseToast = () => setShowToast(false);

  useEffect(() => {
    let handler = (e) => {
      if (!toastRef?.current?.contains(e.target)) {
        handleCloseToast();
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const handleCloseBackModal = () => setShowBackModal(false);

  const cleanup = (isFetch = false) => {
    dispatch(ccbActions.setChecked({ ...initialState }));
    setIsRequired(false);
    handleCloseToast();
    setFormHasChanged(false);
    setIsCardOpen({
      [SECTION.customerComplaintArbitrationCivilLitigation]: false,
    });
    setIsEdit({ [SECTION.customerComplaintArbitrationCivilLitigation]: false });

    if (!isFetch) {
      setIsPageChanged((prev) => !prev);
    }
  };

  const handleShowBackModal = () => {
    if (
      ((isSomeChecked(valuesA) &&
        isEdit[SECTION.customerComplaintArbitrationCivilLitigation]) ||
        (isSomeChecked(valuesA) &&
          !isSaved[SECTION.customerComplaintArbitrationCivilLitigation])) &&
      formHasChanged
    ) {
      setShowBackModal(true);
    } else {
      dispatch(ccbActions.setIsBack(true));
      toggleForm(backFormId);
      setIsPageChanged((prev) => !prev);
    }
  };

  const handleBackClick = async () => {
    cleanup();
    toggleForm(backFormId);

    if (isSaved[SECTION.customerComplaintArbitrationCivilLitigation]) {
      updateCCB14CompletedInd({ custCompDisclosureCompleteInd: true });
    }

    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: false }));
    dispatch(ccbActions.setIsBack(true));
    handleCloseBackModal();
  };

  const handleNext = async () => {
    dispatch(ccbActions.setIsBack(false));
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: false }));
    cleanup(true);

    const isYes = (currentValue) => currentValue === true;
    let isYesValues = valuesA.some(isYes);
    if (loading === loadingStatus.IDLE || loading === loadingStatus.SUCCEDED) {
      const { payload } = await dispatch(fetchCCB(oktaSubId));

      isYesValues = getValues(
        payload,
        KEYS.customerComplaintArbitrationCivilLitigation
      ).some(isYes);
    }

    if (isYesValues) {
      updateCCBForm(nextFormId);
    } else {
      updateCCB14CompletedInd({ custCompDisclosureCompleteInd: true });
      toggleForm(FORM_ID.TERMINATION_DISCLOSURE);
    }
  };

  const renderSubcategory = (category, section) => {
    const disabledSave =
      (isSaved[SECTION.customerComplaintArbitrationCivilLitigation] &&
        !isEdit[SECTION.customerComplaintArbitrationCivilLitigation] &&
        section === SECTION.customerComplaintArbitrationCivilLitigation) ||
      (!isSaveDisabledA &&
        section === SECTION.customerComplaintArbitrationCivilLitigation);

    return (
      <CustomSubCategoryCard
        data={data}
        category={category}
        handleOnEditClick={handleOnEditClick}
        handleChangeChecked={handleChangeChecked}
        handleOnSaveClick={handleDeleteOnSave}
        section={section}
        isSaved={isSaved}
        isLoading={isLoading}
        isRequired={isRequired}
        disabledSave={disabledSave}
        ref={containerRef}
      />
    );
  };

  useClickOutside(toastRef, containerRef, showToast, handleCloseToast);

  return (
    <div className="cust-comp-form-wrapper" ref={containerRef}>
      <Toast
        showToast={showToast}
        closeToast={handleCloseToast}
        text={TOAST_MESSAGE}
        ref={toastRef}
      />

      <div className="row">
        <div className="col-md-12">
          <div className="eyebrow">
            <strong id="ccb_eyebrow">Character, Credit, and Bonding</strong>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <h1 id="ccb_header">{header}</h1>
          <h2 id="ccb_intro_rev" style={{ color: "black" }}>
            {INTRO_REV}
          </h2>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <p id="ccb_intro_text">{INTRO_TEXT}</p>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <CustomCard
            isOpen={
              isCardOpen[SECTION.customerComplaintArbitrationCivilLitigation]
            }
            setIsOpen={setIsCardOpen}
            headerText={headerText.CUST_COMP_ARB_CIV_LIT}
            isCompleted={
              isSaved[SECTION.customerComplaintArbitrationCivilLitigation]
            }
            index={SECTION.customerComplaintArbitrationCivilLitigation}
          >
            {renderSubcategory(
              customerComplaintArbitrationCivilLitigation(
                isSaved[SECTION.customerComplaintArbitrationCivilLitigation] &&
                  !isEdit[SECTION.customerComplaintArbitrationCivilLitigation]
              ),
              SECTION.customerComplaintArbitrationCivilLitigation
            )}
          </CustomCard>
        </div>
      </div>
      <NavigationButtons
        onBack={handleShowBackModal}
        onNext={handleNext}
        nxtbtnid={"custcmpDisNext"}
        bckbtnid={"custcmpDisBack"}
        isLoading={isLoading}
        isDisabled={
          !isSaved[SECTION.customerComplaintArbitrationCivilLitigation] ||
          !!isError ||
          !!isLoading
        }
      />
      {isShowDRPCleanupModal && (
        <DeleteModal
          isOpen={isShowDRPCleanupModal}
          showSpinner={false}
          isDRP
          type={headerText.MAIN_HEADER}
          list={drpCleanupList}
          description="You had previously provided a DRP(s) associated with this
          question. Changing your response will delete the following
          DRP(s). Are you sure you want to proceed?"
          message="Are You Sure You Want To Proceed?"
          closeClick={closeCleanupModalHandler}
          deleteClick={onCleanupModalHandler}
        />
      )}

      <BackButtonModal
        isOpen={showBackModal}
        closeBackModal={handleCloseBackModal}
        backClick={handleBackClick}
      />
    </div>
  );
};

export default CustomerComplaintForm;
