/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import "./DRPSummaryListingCard.scss";
import { Button } from "react-blueprint-library";
import DRPSummaryList from "../DRPSummaryList";
import svgs, { svgType } from "../../../../../../assets/svgs";

const DRPSummaryListingCard = ({
  questionKey,
  mainQuestion,
  question,
  index,
  length = 0,
  list = [],
  handleChange,
  drpCheckList = [],
  isCheckbox,
  deleteDRP,
  isSummaryPage,
  editDRP,
  isLoading,
  section,
}) => {
  const [show, setShow] = useState(false);
  const showHideText = `${show ? `Hide` : `Show`} Question`;
  const qKeyId = questionKey !== undefined ? questionKey.replace(/\s/g, "") : 0;

  return (
    <>
      {questionKey !== undefined && (
        <div className="drp-summary-container" key={`${section}_${qKeyId}`}>
          <div className="content">
            {isCheckbox ? (
              <div className="card-checkbox">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    onChange={handleChange}
                    id={`drpCheckbox-${qKeyId}`}
                    value={questionKey}
                    checked={!!drpCheckList?.find((d) => d === questionKey)}
                    aria-checked={
                      !!drpCheckList?.find((d) => d === questionKey)
                    }
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={`drpCheckbox-${questionKey}`}
                    id={`drpCheckbox-${qKeyId}-id`}
                  />
                </div>
              </div>
            ) : null}

            <div className="left">
              <label
                className={`${isCheckbox ? "drp" : ""}`}
                htmlFor={`drpCheckbox-${questionKey}`}
                id={`drpCheckbox-${qKeyId}-id`}
              >
                {questionKey}
              </label>
            </div>

            <div className="right">
              <div className="show-question">
                <div className={`header ${isCheckbox ? "drp" : ""}`}>
                  <Button
                    id="drp-show-question-btn"
                    className="drp-show-question-btn"
                    variant="link"
                    onClick={() => setShow((prev) => !prev)}
                  >
                    <p id={`show-text-${qKeyId}`}>{showHideText}</p>
                  </Button>

                  {isSummaryPage && questionKey && !list.length ? (
                    <div className="drp-error">
                      {isLoading ? (
                        <span className="mm-dots-loading" />
                      ) : (
                        <>
                          {svgs[svgType.danger]}
                          <span>Please add at least 1 DRP</span>
                        </>
                      )}
                    </div>
                  ) : null}
                </div>

                {show && (
                  <div className={`questions ${isCheckbox ? "drp" : ""}`}>
                    <div className="main">
                      <span id={`main-question-${qKeyId}`}>{mainQuestion}</span>
                    </div>
                    <div
                      className={`sub ${
                        questionKey.includes("14H(2)") && !isCheckbox
                          ? "indent"
                          : ""
                      }`}
                    >
                      <span id={`question-text-${qKeyId}`}>{question}</span>
                    </div>
                  </div>
                )}
              </div>

              {list.length ? (
                <DRPSummaryList
                  list={list}
                  deleteDRP={deleteDRP}
                  editDRP={editDRP}
                  section={section}
                  isLoading={isLoading}
                />
              ) : null}
            </div>
          </div>

          {index + 1 < length ? <hr /> : null}
        </div>
      )}
    </>
  );
};

export default DRPSummaryListingCard;
