export const initialState = {
  termviolation: null,
  termfraud: null,
  termfailuretosupervise: null,
};

const termDiscl = {
  mainQuestion:
    "Have you ever voluntarily resigned, been discharged or permitted to resign after allegations were made that accused you of:",
  questionA: `(1) violating investment-related statutes, regulations, rules, or industry standards of conduct?*`,
  questionB: `(2) fraud or wrongful taking of property?*`,
  questionC: `(3) failure to supervise in connection with investment-related statutes, regulations, rules or industry standards of conduct?*`,
};

export const DRPSummaryTerminationDisclosure = {
  termviolation: {
    key: "14J(1)",
    mainQuestion: termDiscl.mainQuestion,
    question: termDiscl.questionA,
  },
  termfraud: {
    key: "14J(2)",
    mainQuestion: termDiscl.mainQuestion,
    question: termDiscl.questionB,
  },
  termfailuretosupervise: {
    key: "14J(3)",
    mainQuestion: termDiscl.mainQuestion,
    question: termDiscl.questionC,
  },
};

export const terminationDisclosure = (disabled) => [
  {
    header: `14J  ${termDiscl.mainQuestion}`,
    subHeader: "",
    questionA: termDiscl.questionA,
    questionB: termDiscl.questionB,
    questionC: termDiscl.questionC,
    optionsA: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "termviolation-y",
      idTwo: "termviolation-n",
      name: "termviolation",
      disabled: disabled,
    },
    optionsB: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "termfraud-y",
      idTwo: "termfraud-n",
      name: "termfraud",
      disabled: disabled,
    },
    optionsC: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "termfailuretosupervise-y",
      idTwo: "termfailuretosupervise-n",
      name: "termfailuretosupervise",
      disabled: disabled,
    },
  },
];
