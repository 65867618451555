import { fetchWrapper, getHeaders } from "../../helpers";
import { RequestMethodTypes } from "../../helpers/constants";

export const insertJudgmentLienDRP = async (oktasubid, data) => {
  const {
    data: { ccbdrpid },
  } = await fetchWrapper({
    method: RequestMethodTypes.PUT,
    url: `drp/${oktasubid}/judgmentlien`,
    data,
  });

  return ccbdrpid;
};
export const updateJudgmentLienDRP = async (oktasubid, data) => {
  const { ccbdrpid } = await fetchWrapper({
    method: RequestMethodTypes.POST,
    url: `drp/${oktasubid}/judgmentlien`,
    headers: getHeaders(),
    data,
  });

  return ccbdrpid;
};

export const getJudgmentLienDRP = async (oktasubid) => {
  const response = await fetchWrapper({
    method: RequestMethodTypes.GET,
    url: `drp/${oktasubid}/judgmentlien`,
  });

  return response;
};

export const deleteJudgmentLienDRP = async (oktasubid, ccbdrpid) => {
  const response = await fetchWrapper({
    method: RequestMethodTypes.POST,
    url: `drp/${oktasubid}/deletejudgmentliendrp`,
    data: {
      ccbdrpid,
    },
  });

  return response;
};
