import React from "react";
import { /*Button,*/ Modal } from "react-blueprint-library";
import "../../../assets/stylesheets/BackButtonModal.scss";
//import { getBlankContract } from "../../../api/makeAPIRequest";

export default function BlankContractModal(props) {
  const contractURL = URL.createObjectURL(
    new Blob([props.blankContractBlob], { type: "application/pdf" })
  );

  const disclosureURL = URL.createObjectURL(
    new Blob([props.blankDisclosureBlob], { type: "application/pdf" })
  );

  return (
    <>
      <div>
        <div id="backModal"></div>

        <div>
          <Modal
            id="ShowContractModal"
            show={props.isOpen}
            className="modal-md"
            title=""
          >
            <div>
              <div className="modal-body">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close modal dialog"
                        onClick={props.closeBackModal}
                      />
                    </div>
                  </div>
                  <embed src={contractURL} width="600" height="500"></embed>
                  <embed src={disclosureURL} width="600" height="500"></embed>

                  <div className="row">
                    <div className="col-md-12"></div>
                  </div>
                  {/*
                  <div className="row">
                    <div
                      className="col-md-12"
                      style={{
                        paddingTop: "20px",
                        paddingBottom: "20px",
                        display: "inline",
                      }}
                    >
                      <Button
                        label="No, Stay on this page"
                        onClick={props.closeBackModal}
                        style={{
                          padding: "5px",
                          float: "left",
                          width: "239px",
                          fontSize: "1.3rem",
                        }}
                      ></Button>
                      <button
                        type="button"
                        className="btn btn-link"
                        //onClick={props.backClick}
                        style={{
                          width: "175px",
                          textAlign: "left",
                          fontSize: "1.4rem",
                          padding: "4px 0px",
                        }}
                      >
                        Yes, proceed without saving
                      </button>
                    </div>
                  </div>
 */}
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
}
