/* eslint-disable jsx-a11y/label-has-for */
import React, { useState } from "react";
import InputMask from "react-input-mask";
import {
  onlyNumeric,
  pasteCommaDelimited,
} from "../../../../../../../common/Functions";
import { eventStatusDateType } from "../../../constants";

const DateMonetaryPenaltyFinePaid = ({
  amountPaid,
  handleOnAmountPaidChange,
  dateMonetaryPenaltyPaidError,
  dateMonetaryPenaltyPaid,
  onDateMonetaryPenaltyPaidOnChangeHandler,
  onDateMonetaryPenaltyPaidBlurHandler,
  dateMonetaryPenaltyPaidRadio,
  handleDateMonetaryPenaltyPaidChecked,
  onDateMonetaryPenaltyPaidExplanationChange,
  dateMonetaryPenaltyPaidExpError,
  dateMonetaryPenaltyPaidErrorMsg,
  onBlurDateMonetaryPenaltyPaidExpHandler,
  dateMonetaryPenaltyPaidExp,
  onAmountPaidBlurHandler,
  amountPaidError,
  amountPaidErrorMsg,
}) => {
  const [amountFocus, setAmountFocus] = useState(false);

  return (
    <div className="event-status-date-explanation-container">
      <label
        htmlFor="monetaryPenaltyExplanation"
        className="event-status-date-exp"
        id="monetaryPenaltyExplanationLabelId"
      >
        Amount Paid: *
      </label>
      <div
        className="input-group amount-paid-input-group"
        id="amount-paid-input-group-id"
      >
        <div
          className={`input-group-prepend ${
            amountPaidError || (amountFocus && amountPaidError)
              ? "amount-error"
              : ""
          }`}
          id="amount-paind-dollar-sign-wrapper"
        >
          <span
            className={`input-group-text input-group-text-pad-right ${
              amountFocus && amountPaidError ? "is-invalid" : ""
            }`}
            id="amount-paid-dollar-sign-id"
          >
            $
          </span>
        </div>
        <input
          type="text"
          id="monetaryPenaltyExplanation"
          className={`input-pad-left ${
            amountPaidError ? "form-control is-invalid" : "form-control"
          }`}
          style={{ borderRightStyle: "inset", borderRadius: "0 .4rem .4rem 0" }}
          spellCheck="false"
          maxLength={11}
          autoComplete="off"
          onFocus={() => {
            setAmountFocus(true);
          }}
          value={`${amountPaid}`.replaceAll("-", "").trim()}
          onBlur={(e) => {
            setAmountFocus(false);
            onAmountPaidBlurHandler(e);
          }}
          onChange={handleOnAmountPaidChange}
          onKeyPress={onlyNumeric}
          onPaste={pasteCommaDelimited}
        />
        <div id="amountPaidError" className="invalid-feedback">
          {amountPaidErrorMsg?.length
            ? amountPaidErrorMsg
            : "Please enter Amount Paid"}
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 event-status-date-wrapper">
          <label
            id="dateMonetaryPenaltyLabelPaiId"
            htmlFor="dateMonetaryPenaltyPaiId"
          >
            <strong id="dateMonetaryPenaltyLabel">
              Date monetary/penalty fine paid: *
            </strong>
          </label>
          <InputMask
            id="dateMonetaryPenaltyPaiId"
            mask="99/99/9999"
            maskChar="_"
            className={`${
              dateMonetaryPenaltyPaidError
                ? "form-control is-invalid"
                : "form-control"
            } event-status-date-input`}
            placeholder="MM/DD/YYYY"
            autoComplete="off"
            required
            value={dateMonetaryPenaltyPaid}
            onChange={onDateMonetaryPenaltyPaidOnChangeHandler}
            onBlur={onDateMonetaryPenaltyPaidBlurHandler}
          />
          <div id="dateMonetaryPenaltyPaidError" className="invalid-feedback">
            {dateMonetaryPenaltyPaidErrorMsg?.length
              ? dateMonetaryPenaltyPaidErrorMsg
              : "Please enter a valid Date monetary/penalty fine paid"}
          </div>
        </div>

        <div className="row event-status-date-radio-group">
          <div className="custom-control">
            <div className="col-12 copy-md-8 form-group">
              <div className="custom-control custom-radio">
                <input
                  name="radio-guide-dateMonetaryPenaltyPaidExactId"
                  type="radio"
                  id="dateMonetaryPenaltyPaidExactId"
                  className="custom-control-input"
                  value={eventStatusDateType.EXACT}
                  checked={
                    dateMonetaryPenaltyPaidRadio === eventStatusDateType.EXACT
                  }
                  onChange={handleDateMonetaryPenaltyPaidChecked}
                />
                <label
                  htmlFor="dateMonetaryPenaltyPaidExactId"
                  className="custom-control-label"
                  id="label_exactDate"
                >
                  {eventStatusDateType.EXACT}
                </label>
              </div>
              <div className="custom-control custom-radio">
                <input
                  name="radio-guide-dateMonetaryPenaltyPaidExplanationId"
                  type="radio"
                  id="dateMonetaryPenaltyPaidExplanationId"
                  className="custom-control-input"
                  value={eventStatusDateType.EXPLANATION}
                  checked={
                    dateMonetaryPenaltyPaidRadio ===
                    eventStatusDateType.EXPLANATION
                  }
                  onChange={handleDateMonetaryPenaltyPaidChecked}
                />
                <label
                  htmlFor="dateMonetaryPenaltyPaidExplanationId"
                  className="custom-control-label"
                  id="label_explanation"
                >
                  {eventStatusDateType.EXPLANATION}
                </label>
              </div>
              {dateMonetaryPenaltyPaidRadio ===
              eventStatusDateType.EXPLANATION ? (
                <div className="event-status-date-explanation-container">
                  <label
                    htmlFor="dateMonetaryPenaltyPaidExplanation"
                    className="event-status-date-exp"
                    id="dateMonetaryPenaltyPaidExplanationLabelId"
                  >
                    If not exact, provide explanation *
                  </label>
                  <textarea
                    id="dateMonetaryPenaltyPaidExplanation"
                    onChange={onDateMonetaryPenaltyPaidExplanationChange}
                    className={
                      dateMonetaryPenaltyPaidExpError
                        ? "form-control form-control-15 is-invalid"
                        : "form-control form-control-15"
                    }
                    maxLength={500}
                    minLength={2}
                    onBlur={onBlurDateMonetaryPenaltyPaidExpHandler}
                    rows="3"
                    cols={500}
                    value={dateMonetaryPenaltyPaidExp}
                  />
                  <div
                    id="dateMonetaryPenaltyPaidExpError"
                    className="invalid-feedback"
                  >
                    Please enter an Explanation
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateMonetaryPenaltyFinePaid;
