import { FORM_ID } from "../../../../../constants/Forms";

function ReviewBrokerDealer(props) {
  const toggleForm = () => {
    props.toggleForm(FORM_ID.BROKER_DEALER);
  };
  return (
    <div id="brkr-dlr">
      <div className="row">
        <div className="col-8" id="dealer-heading">
          <h4>Broker Dealer Information</h4>
        </div>
        <div className="col-4" id="brkr-edt-btn">
          <span className="btn btn-link float-right" onClick={toggleForm}>
            Edit
          </span>
        </div>
      </div>

      <div className="row">
        <div className="col-3" id="delr-rgstrd">
          <h5>Are you registered with a Broker Dealer?</h5>
        </div>
        <div id="registered" className="col-3 data">
          {props.brokerDealerInformation.brokdealbool ? "Yes" : "No"}
        </div>
        {props.brokerDealerInformation.brokdealbool && (
          <>
            <div className="col-3" id="anti-money-laundering">
              <h5 id="aml">
                Have you completed Anti-Money Laundering training through your
                Broker Dealer?
              </h5>
            </div>
            <div id="completed" className="col-3 data">
              {props.brokerDealerInformation.amltraining ? "Yes" : "No"}
            </div>
            <div className="col-3" id="dealer-name">
              <h5>Broker Dealer Name</h5>
            </div>
            <div id="broker_name" className="col-3 data">
              {props.brokerDealerInformation.brokdealname}
            </div>
            {props.brokerDealerInformation.amltraining ? (
              <>
                <div className="col-3" id="training">
                  <h5 id="trng-dte">
                    Anti-Money Laundering Training Completion Date
                  </h5>
                </div>
                <div id="training_date" className="col-3 data">
                  {props.brokerDealerInformation.amltrainingdate}
                </div>
              </>
            ) : (
              <>
                <div className="col-3"></div>
                <div className="col-3 data"></div>
              </>
            )}

            <div className="col-3" id="crd">
              <h5 id="pcrdn">
                Personal Central Registration Depository Number (CRD)
              </h5>
            </div>
            <div id="CRD" className="col-3 data">
              {props.brokerDealerInformation.crdnumber}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ReviewBrokerDealer;
