import "../../../assets/stylesheets/StartApplication/CAS/AddedAddressToast.scss";

function AddedAddressToast(props) {
  const closeToast = () => {
    //console.log("close");
    props.closeToast();
  };
  return (
    <>
      <div
        id="address_added_alert"
        className={
          props.showToast === true
            ? "show col-md-6 alert-item"
            : "d-none col-md-6 alert-item"
        }
      >
        <div className="mm-toast-container">
          <div
            className="toast"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
          >
            <div className="wrapper">
              <div className="toast-header">
                <strong>
                  Your residence has been {props.edit ? "saved" : "added"}{" "}
                  successfully.
                </strong>

                <button
                  className="icon-close"
                  type="button"
                  data-dismiss="toast"
                  aria-label="Close"
                  onClick={closeToast}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddedAddressToast;
