export const customerComplaintActionType = {
  CUSTOMER_COMPLAINT: "Customer Complaint",
  ARBITRATION_CFTC: "Arbitration/CFTC reparation",
  CIVIL_LITIGATION: "Civil Litigation",
};

export const exactExplanationOptions = {
  EXACT: "Exact",
  EXPLANATION: "Explanation",
};

export const yesNoOptions = {
  YES: "Yes",
  NO: "No",
};

export const custComplaintStatusOptions = [
  {
    option: "Closed/No Action",
  },
  { option: "Withdrawn" },
  { option: "Denied" },
  { option: "Settled" },
  {
    option: "Arbitration Award/ Monetary Judgement (for claimants/plaintiffs)",
  },
  {
    option:
      "Arbitration Award/ Monetary Judgement (for respondents/defendants)",
  },
  {
    option: "Evolved into Arbitration/CFTC reparation (you are a named party)",
  },
  {
    option: "Evolved into Civil litigation (you are a named party)",
  },
];

export const dispositionStatusOptions = [
  {
    option: "Award to Applicant (Agent/Representative)",
  },
  { option: "Judgment (other than monetary)" },
  { option: "Award to Customer" },
  { option: "No Action" },
  { option: "Denied" },
  { option: "Dismissed" },
  { option: "Settled" },
  { option: "Withdrawn" },
  { option: "Other" },
];

export const civLitStatusOptions = [
  { option: "Denied" },
  { option: "Dismissed" },
  { option: "Judgment (other than monetary)" },
  { option: "Monetary Judgement to Applicant (Agent/Representative)" },
  { option: "Monetary Judgement to Customer" },
  { option: "No Action" },
  { option: "Settled" },
  { option: "Withdrawn" },
  { option: "Other" },
];

export const courtType = {
  FEDERAL: "Federal Court",
  STATE: "State Court",
  FOREIGN: "Foreign Court",
  MILITARY: "Military Court",
  OTHER: "Other",
};
