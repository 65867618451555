import { FORM_ID } from "../../../../../constants/Forms";

function ReviewEOC(props) {
  const toggleForm = () => {
    props.toggleForm(FORM_ID.ERRORS_OMISSIONS);
  };
  return (
    <div id="view-eoc">
      <div className="row">
        <div className="col-8" id="eoc-hding">
          <h4>Errors and Omissions Coverage</h4>
        </div>
        <div className="col-4" id="edt-btn">
          <span className="btn btn-link float-right" onClick={toggleForm}>
            Edit
          </span>
        </div>
      </div>

      <div className="row">
        <div className="col-3" id="status">
          <h5 id="sts">Status</h5>
        </div>
        <div id="eoc_status" className="col-9 data">
          {props.errorsOmissionsCoverage.eoindgreatermil === true &&
            "I currently have an individual E&O coverage policy that is equal to or greater than $1 million"}
          {props.errorsOmissionsCoverage.eoindlessmil === true &&
            "I currently have an individual E&O coverage policy that is less than $1 million"}
          {props.errorsOmissionsCoverage.eogroup === true &&
            "I am currently covered under a Group E&O policy"}
        </div>
      </div>
    </div>
  );
}

export default ReviewEOC;
