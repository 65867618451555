import React from "react";
import { Button, FormCustomControl } from "react-blueprint-library";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { connect } from "react-redux";
import {
  getBankRoutingInfo,
  putDirectDepositInfo,
} from "../../../api/makeAPIRequest";
import bankAccount from "../../../assets/img/BankAccount.png";
import { clickFunc, onlyNumeric } from "../../../common/Functions";
import { CONTRACT_TYPE_DESCRIPTIONS } from "../../../constants/Contract";
import { FORM_ID } from "../../../constants/Forms";
import { ccbActions } from "../../../store/actions";
import { UtagTracking } from "../../../utils/utag-tracking";
import { isCareerContract } from "../CASForms/CCB/helpers";
import BackButtonModal from "../Forms/BackButtonModal";
import "../StartApplication.scss";

class DirectDepositForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initialState();
    this.handleChecked = this.handleChecked.bind(this);
    this.handleAcknowledgmentChecks =
      this.handleAcknowledgmentChecks.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.getBankName = this.getBankName.bind(this);
    this.clearInputErrors = this.clearInputErrors.bind(this);
    this.copyInputErrors = this.copyInputErrors.bind(this);
    this.handleShowBackModal = this.handleShowBackModal.bind(this);
    this.handleCloseBackModal = this.handleCloseBackModal.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
  }

  initialState() {
    return {
      same_account_checked: false,
      compensation_type: "",
      account_type_mm: "",
      routing_number_mm: "",
      account_number_mm: "",
      confirm_account_number_mm: "",
      account_type_aff: "",
      routing_number_aff: "",
      account_number_aff: "",
      confirm_account_number_aff: "",
      bank_name_mm: "",
      bank_name_aff: "",
      bank_routing_error: "",
      bank_routing_name: "",
      contract_type_id: "",
      ddchecked1: false,
      ddchecked2: false,
      ddchecked3: false,
      is_checkbox_disabled: true,
      showBackModal: false,
      formHasChanged: false,
      candidate_id: "",
      ctrl_with_focus: "",
    };
  }

  async componentDidMount() {
    try {
      const contractNumber = this.props.contractid;
      this.setState({ contract_type_id: contractNumber });

      const directDepositData = this.props.ddData;

      this.setState({
        same_account_checked: false,
        compensation_type:
          directDepositData.compensationtype === null
            ? ""
            : directDepositData.compensationtype === 1
            ? "MassMutual Only"
            : "MassMutual and Affiliated Broker Dealer (MMLIS Registered Business)",
        account_type_mm:
          directDepositData.mmaccounttype == null
            ? ""
            : directDepositData.mmaccounttype === 1
            ? "Checking"
            : "Savings",
        routing_number_mm:
          directDepositData.mmroutingnumber === null
            ? ""
            : directDepositData.mmroutingnumber,
        account_number_mm:
          directDepositData.mmaccountnumber === null
            ? ""
            : directDepositData.mmaccountnumber,
        confirm_account_number_mm:
          directDepositData.mmaccountnumber === null
            ? ""
            : directDepositData.mmaccountnumber,
        account_type_aff:
          directDepositData.brokaccounttype === null
            ? ""
            : directDepositData.brokaccounttype === 1
            ? "Checking"
            : "Savings",
        routing_number_aff:
          directDepositData.brokroutingnumber === null
            ? ""
            : directDepositData.brokroutingnumber,
        account_number_aff:
          directDepositData.brokaccountnumber === null
            ? ""
            : directDepositData.brokaccountnumber,
        confirm_account_number_aff:
          directDepositData.brokaccountnumber === null
            ? ""
            : directDepositData.brokaccountnumber,
        ddchecked1:
          directDepositData.participationrules === null
            ? false
            : directDepositData.participationrules,
        ddchecked2:
          directDepositData.netcompensation === null
            ? false
            : directDepositData.netcompensation,
        ddchecked3:
          directDepositData.executorpayment === null
            ? false
            : directDepositData.executorpayment,
        candidate_id: directDepositData.candidateid,
      });
      if (
        directDepositData.compensationtype !== null &&
        directDepositData.mmaccountnumber !== null &&
        directDepositData.mmaccounttype !== null &&
        directDepositData.mmroutingnumber !== null
      ) {
        this.props.isFormComplete("DirectDepositCompleteInd", true);
      }
      let bankNameMM = "";
      if (
        directDepositData.mmroutingnumber !== null &&
        directDepositData.mmroutingnumber !== ""
      )
        bankNameMM = await getBankRoutingInfo(
          directDepositData.mmroutingnumber
        );

      let bankNameAFF = "";
      if (
        directDepositData.brokroutingnumber !== null &&
        directDepositData.brokaccountnumber !== ""
      )
        bankNameAFF = await getBankRoutingInfo(
          directDepositData.brokroutingnumber
        );

      this.setState({
        bank_name_mm:
          bankNameMM === "" ? bankNameMM : bankNameMM.agreements[0].bankName,
        bank_name_aff:
          bankNameAFF === "" ? bankNameAFF : bankNameAFF.agreements[0].bankName,
      });
      if (
        this.state.routing_number_mm !== "" &&
        this.state.bank_name_mm !== "" &&
        this.state.account_number_mm !== "" &&
        this.state.confirm_account_number_mm !== "" &&
        this.state.account_type_mm !== ""
      ) {
        this.setState({ is_checkbox_disabled: false });
      }

      if (
        this.state.routing_number_mm === this.state.routing_number_aff &&
        this.state.account_number_mm === this.state.account_number_aff &&
        this.state.confirm_account_number_mm ===
          this.state.confirm_account_number_aff &&
        this.state.account_type_mm === this.state.account_type_aff
      ) {
        this.setState({ same_account_checked: true });
      }
      UtagTracking.sendPageNameView(
        "directdepositform",
        this.props.candidateId,
        this.props.agencynumber,
        this.props.contracttypeid
      );
    } catch (err) {
      console.log(err);
    }
  }
  async handleChecked() {
    this.props.isFormComplete("DirectDepositCompleteInd", false);

    let currentCheck = this.state.same_account_checked;
    await this.setState({
      same_account_checked: !currentCheck,
    });
    if (this.state.same_account_checked === true) {
      await this.setState({
        account_type_aff: this.state.account_type_mm,
        routing_number_aff: this.state.routing_number_mm,
        account_number_aff: this.state.account_number_mm,
        confirm_account_number_aff: this.state.confirm_account_number_mm,
        bank_name_aff: this.state.bank_name_mm,
      });
      this.copyInputErrors();
    } else {
      await this.setState({
        account_type_aff: "",
        routing_number_aff: "",
        account_number_aff: "",
        confirm_account_number_aff: "",
        bank_name_aff: "",
      });
      this.clearInputErrors();
    }

    this.clearInputErrors();
    this.setState({ formHasChanged: true });
    this.handleNext("checked");
  }

  async handleAcknowledgmentChecks(event) {
    this.props.isFormComplete("DirectDepositCompleteInd", false);
    this.setState({ formHasChanged: true });

    let currentCheck;
    if (event.target.id.includes("1")) {
      currentCheck = this.state.ddchecked1;
      await this.setState({ ddchecked1: !currentCheck });
      var obj_error = document.getElementById("check_error1");
      obj_error.style.display = "none";
      var ack = document.getElementById("dd_acknowledgement1");
      ack.classList.remove("is-invalid");
    } else if (event.target.id.includes("2")) {
      currentCheck = this.state.ddchecked2;
      await this.setState({ ddchecked2: !currentCheck });
      var obj_error2 = document.getElementById("check_error2");
      obj_error2.style.display = "none";
      var ack2 = document.getElementById("dd_acknowledgement2");
      ack2.classList.remove("is-invalid");
    } else if (event.target.id.includes("3")) {
      currentCheck = this.state.ddchecked3;
      await this.setState({ ddchecked3: !currentCheck });
      var obj_error3 = document.getElementById("check_error3");
      obj_error3.style.display = "none";
      var ack3 = document.getElementById("dd_acknowledgement3");
      ack3.classList.remove("is-invalid");
    }
    this.setState({ formHasChanged: true });
    this.handleNext("acknowledgements");
  }

  async handleChange(event) {
    if (event.target.id.includes("comp_option")) {
      await this.setState({ compensation_type: event.target.value });
    } else if (event.target.id.includes("mm_option")) {
      await this.setState({ account_type_mm: event.target.value });
    } else if (event.target.id.includes("aff_option")) {
      await this.setState({ account_type_aff: event.target.value });
    } else {
      await this.setState({ [event.target.id]: event.target.value });
    }
    this.setState({ formHasChanged: true });
    this.props.isFormComplete("DirectDepositCompleteInd", false);
    this.props.hasCurrentFormChanged(true);

    if (event.target.id.includes("comp_option")) {
      // clear both forms
      await this.setState({ bank_name_mm: "" });
      await this.setState({ account_type_mm: "" });
      await this.setState({ routing_number_mm: "" });
      await this.setState({ account_number_mm: "" });
      await this.setState({ confirm_account_number_mm: "" });
      await this.setState({ account_type_aff: "" });
      await this.setState({ routing_number_aff: "" });
      await this.setState({ account_number_aff: "" });
      await this.setState({ confirm_account_number_aff: "" });
      await this.setState({ bank_name_mm: "" });
      await this.setState({ bank_name_aff: "" });
      await this.setState({ bank_routing_error: "" });
      await this.setState({ bank_routing_name: "" });
      await this.setState({ same_account_checked: false });

      this.clearInputErrors("all");
      this.setState({ is_checkbox_disabled: true });

      this.handleNext("change");
    }

    if (this.state.same_account_checked === true) {
      if (event.target.id.includes("mm")) {
        this.setState({
          [event.target.id.replace("mm", "aff")]: event.target.value,
        });
      }
    }
  }
  onFocus() {
    this.setState({ ctrl_with_focus: "" });

    var inputs = document.querySelectorAll("button, a");
    try {
      for (var i = 0; i < inputs.length; ++i) {
        inputs[i].addEventListener("focusout", (event) => {
          if (event.relatedTarget == null) {
            this.setState({ ctrl_with_focus: "" });
          } else {
            this.setState({ ctrl_with_focus: event.relatedTarget.id });
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  }
  async onBlur(event) {
    var obj_error;
    if (
      this.state.ctrl_with_focus.includes("option") &&
      this.state.ctrl_with_focus !== event.target.id
    ) {
      //do nothing
    } else if (event.target.id === "routing_number_mm") {
      var routingNumberError;
      obj_error = document.getElementById(event.target.id + "_error");
      await this.setState({ bank_name_mm: "" });

      if (event.target.value.trim(" ").length === 0) {
        obj_error.innerText = "Please enter a valid Routing Number";
        routingNumberError = true;
      } else if (event.target.value.length < 9) {
        obj_error.innerText =
          "Bank not recognized. Please re-enter your routing number";
        routingNumberError = true;
      } else {
        await this.getBankName(event.target.value);

        if (this.state.bank_routing_error.includes("WARNING")) {
          obj_error.innerText =
            "Bank not recognized. Please re-enter your routing number";
          routingNumberError = true;
        } else {
          await this.setState({ bank_name_mm: this.state.bank_routing_name });
        }
      }

      if (routingNumberError) {
        obj_error.style.display = "block";
        event.target.style.border = "1px solid #e21224";
        event.target.style.color = "#e21224";
      } else {
        obj_error.style.display = "none";
        event.target.removeAttribute("style");
      }

      if (this.state.same_account_checked && routingNumberError) {
        var affRoutingNumEvent = new Event("blur");
        Object.defineProperty(affRoutingNumEvent, "target", {
          writable: false,
          value: document.getElementById("routing_number_aff"),
        });
        await this.onBlur(affRoutingNumEvent);
      }
    } else if (event.target.id === "routing_number_aff") {
      var routingNumberError2;
      obj_error = document.getElementById(event.target.id + "_error");
      await this.setState({ bank_name_aff: "" });

      if (event.target.value.trim(" ").length === 0) {
        obj_error.innerText = "Please enter a valid Routing Number";
        routingNumberError2 = true;
      } else if (event.target.value.length < 9) {
        obj_error.innerText =
          "Bank not recognized. Please re-enter your routing number";
        routingNumberError2 = true;
      } else {
        await this.getBankName(event.target.value);

        if (this.state.bank_routing_error.includes("WARNING")) {
          obj_error.innerText =
            "Bank not recognized. Please re-enter your routing number";
          routingNumberError2 = true;
        } else {
          await this.setState({ bank_name_aff: this.state.bank_routing_name });
        }
      }

      if (routingNumberError2) {
        obj_error.style.display = "block";
        event.target.style.border = "1px solid #e21224";
        event.target.style.color = "#e21224";
      } else {
        obj_error.style.display = "none";
        event.target.removeAttribute("style");
      }
    } else if (event.target.id === "account_number_mm") {
      obj_error = document.getElementById(event.target.id + "_error");
      var hasErrorAcctMM = false;

      if (
        event.target.value.trim(" ").length === 0 ||
        event.target.value.length < 8
      ) {
        obj_error.innerText = "Please enter a valid Account Number";
        hasErrorAcctMM = true;
      } else {
        if (
          this.state.account_number_mm !==
            this.state.confirm_account_number_mm &&
          this.state.confirm_account_number_mm.length > 0
        ) {
          obj_error.innerText =
            "Account Number does not match Confirmation Number";
          hasErrorAcctMM = true;
        } else if (this.state.confirm_account_number_mm.length > 0) {
          var obj_error_confirm_mm = document.getElementById(
            "confirm_account_number_mm_error"
          );
          obj_error_confirm_mm.style.display = "none";
          var obj_account_mm = document.getElementById(
            "confirm_account_number_mm"
          );
          obj_account_mm.removeAttribute("style");
        }
      }

      if (hasErrorAcctMM) {
        obj_error.style.display = "block";
        event.target.style.border = "1px solid #e21224";
        event.target.style.color = "#e21224";
      } else {
        obj_error.style.display = "none";
        event.target.removeAttribute("style");
      }
      if (this.state.same_account_checked && hasErrorAcctMM) {
        var affAcctNumEvent = new Event("blur");
        Object.defineProperty(affAcctNumEvent, "target", {
          writable: false,
          value: document.getElementById("account_number_aff"),
        });
        await this.onBlur(affAcctNumEvent);
      }
    } else if (event.target.id === "account_number_aff") {
      obj_error = document.getElementById(event.target.id + "_error");
      var hasErrorAcctAff = false;

      if (
        event.target.value.trim(" ").length === 0 ||
        event.target.value.length < 8
      ) {
        obj_error.innerText = "Please enter a valid Account Number";
        hasErrorAcctAff = true;
      } else {
        if (
          this.state.account_number_aff !==
            this.state.confirm_account_number_aff &&
          this.state.confirm_account_number_aff.length > 0
        ) {
          obj_error.innerText =
            "Account Number does not match Confirmation Number";
          hasErrorAcctAff = true;
        } else if (this.state.confirm_account_number_aff.length > 0) {
          var obj_error_confirm_aff = document.getElementById(
            "confirm_account_number_aff_error"
          );
          obj_error_confirm_aff.style.display = "none";
          var obj_account_aff = document.getElementById(
            "confirm_account_number_aff"
          );
          obj_account_aff.removeAttribute("style");
        }
      }

      if (hasErrorAcctAff) {
        obj_error.style.display = "block";
        event.target.style.border = "1px solid #e21224";
        event.target.style.color = "#e21224";
      } else {
        obj_error.style.display = "none";
        event.target.removeAttribute("style");
      }
    } else if (event.target.id === "confirm_account_number_mm") {
      obj_error = document.getElementById(event.target.id + "_error");
      var hasError = false;

      if (
        event.target.value.trim(" ").length === 0 ||
        event.target.value.length < 8
      ) {
        obj_error.innerText = "Please enter a valid Account Number";
        hasError = true;
      } else {
        if (
          this.state.account_number_mm !== this.state.confirm_account_number_mm
        ) {
          obj_error.innerText =
            "Confirmation Number does not match Account Number";
          hasError = true;
        } else {
          var obj_error_account_mm = document.getElementById(
            "account_number_mm_error"
          );
          obj_error_account_mm.style.display = "none";
          var obj_account_num_mm = document.getElementById("account_number_mm");
          obj_account_num_mm.removeAttribute("style");
        }
      }

      if (hasError) {
        obj_error.style.display = "block";
        event.target.style.border = "1px solid #e21224";
        event.target.style.color = "#e21224";
      } else {
        obj_error.style.display = "none";
        event.target.removeAttribute("style");
      }
      if (this.state.same_account_checked && hasError) {
        var affConfirmAcctNumEvent = new Event("blur");
        Object.defineProperty(affConfirmAcctNumEvent, "target", {
          writable: false,
          value: document.getElementById("confirm_account_number_aff"),
        });
        await this.onBlur(affConfirmAcctNumEvent);
      }
    } else if (event.target.id === "confirm_account_number_aff") {
      obj_error = document.getElementById(event.target.id + "_error");
      var hasError2 = false;

      if (
        event.target.value.trim(" ").length === 0 ||
        event.target.value.length < 8
      ) {
        obj_error.innerText = "Please enter a valid Account Number";
        hasError2 = true;
      } else {
        if (
          this.state.account_number_aff !==
          this.state.confirm_account_number_aff
        ) {
          obj_error.innerText =
            "Confirmation Number does not match Account Number";
          hasError2 = true;
        } else {
          var obj_error_account_aff = document.getElementById(
            "account_number_aff_error"
          );
          obj_error_account_aff.style.display = "none";
          var obj_account_num_aff =
            document.getElementById("account_number_aff");
          obj_account_num_aff.removeAttribute("style");
        }
      }

      if (hasError2) {
        obj_error.style.display = "block";
        event.target.style.border = "1px solid #e21224";
        event.target.style.color = "#e21224";
      } else {
        obj_error.style.display = "none";
        event.target.removeAttribute("style");
      }
    } else if (event.target.id.includes("compensation_type")) {
      obj_error = document.getElementById(event.target.id + "_error");

      if (event.target.innerText.trim(" ").length === 0) {
        event.target.style.border = "1px solid #e21224";
        obj_error.style.display = "block";
      } else {
        obj_error.style.display = "none";
        if (event.target.className !== "dropdown-item") {
          event.target.removeAttribute("style");
        } else {
          var compensationCtrl = document.getElementById("compensation_type");
          compensationCtrl.style.border = "1px solid #6b748e";
        }
      }
    } else if (
      event.target.id.includes("comp_option") &&
      !this.state.ctrl_with_focus.includes("comp_option")
    ) {
      obj_error = document.getElementById("compensation_type_error");
      var ctrlToValidate = document.getElementById("compensation_type");
      if (this.state.compensation_type.length === 0) {
        ctrlToValidate.style.border = "1px solid #e21224";
        obj_error.style.display = "block";
      } else {
        obj_error.style.display = "none";
        if (event.target.className !== "dropdown-item") {
          event.target.removeAttribute("style");
        } else {
          var compensationCtrl2 = document.getElementById("compensation_type");
          compensationCtrl2.style.border = "1px solid #6b748e";
        }
      }
    } else if (
      event.target.id.includes("mm_option") ||
      event.target.id === "account_type_mm"
    ) {
      obj_error = document.getElementById("account_type_mm_error");

      if (event.target.innerText.trim(" ").length === 0) {
        event.target.style.border = "1px solid #e21224";
        obj_error.style.display = "block";
      } else {
        obj_error.style.display = "none";
        if (event.target.className !== "dropdown-item") {
          event.target.removeAttribute("style");
        } else {
          var accountTypeMMCtrl = document.getElementById("account_type_mm");
          accountTypeMMCtrl.style.border = "1px solid #6b748e";
        }
      }
    } else if (
      event.target.id.includes("mm_option") &&
      !this.state.ctrl_with_focus.includes("mm_option")
    ) {
      obj_error = document.getElementById("account_type_mm_error");
      var ctrlToValidateMM = document.getElementById("account_type_mm");
      if (ctrlToValidateMM.value.length === 0) {
        ctrlToValidateMM.style.border = "1px solid #e21224";
        obj_error.style.display = "block";
      } else {
        obj_error.style.display = "none";
        if (event.target.className !== "dropdown-item") {
          event.target.removeAttribute("style");
        } else {
          var acctTypeCtrl = document.getElementById("account_type_mm");
          acctTypeCtrl.style.border = "1px solid #6b748e";
        }
      }
    } else if (
      event.target.id.includes("aff_option") ||
      event.target.id === "account_type_aff"
    ) {
      obj_error = document.getElementById("account_type_aff_error");

      if (event.target.innerText.trim(" ").length === 0) {
        event.target.style.border = "1px solid #e21224";
        obj_error.style.display = "block";
      } else {
        obj_error.style.display = "none";
        if (event.target.className !== "dropdown-item") {
          event.target.removeAttribute("style");
        } else {
          var accountTypeAffCtrl = document.getElementById("account_type_aff");
          accountTypeAffCtrl.style.border = "1px solid #6b748e";
        }
      }
    } else if (
      event.target.id.includes("aff_option") &&
      !this.state.ctrl_with_focus.includes("aff_option")
    ) {
      obj_error = document.getElementById("account_type_aff_error");
      var ctrlToValidateAFF = document.getElementById("account_type_aff");
      if (ctrlToValidateAFF.value.length === 0) {
        ctrlToValidateAFF.style.border = "1px solid #e21224";
        obj_error.style.display = "block";
      } else {
        obj_error.style.display = "none";
        if (event.target.className !== "dropdown-item") {
          event.target.removeAttribute("style");
        } else {
          var acctTypeCtrlAFF = document.getElementById("account_type_aff");
          acctTypeCtrlAFF.style.border = "1px solid #6b748e";
        }
      }
    }

    if (
      this.state.compensation_type ===
        "MassMutual and Affiliated Broker Dealer (MMLIS Registered Business)" &&
      this.state.account_type_mm !== "" &&
      this.state.routing_number_mm !== "" &&
      this.state.bank_name_mm !== "" &&
      this.state.account_number_mm !== "" &&
      this.state.confirm_account_number_mm !== "" &&
      this.state.account_number_mm === this.state.confirm_account_number_mm
    ) {
      this.setState({ is_checkbox_disabled: false });
    } else {
      this.setState({ is_checkbox_disabled: true });
    }

    this.handleNext("onBlur");
  }

  onKeyDown() {
    this.props.isFormComplete("DirectDepositCompleteInd", false);
    this.props.hasCurrentFormChanged(true);
  }

  async handleNext(source) {
    this.props.setIsBack(false);
    let allFilled = false;
    if (source === "nextclick") {
      var compTypeEvent = new Event("blur");
      Object.defineProperty(compTypeEvent, "target", {
        writable: false,
        value: document.getElementById("compensation_type"),
      });
      await this.onBlur(compTypeEvent);

      if (this.state.compensation_type !== "") {
        if (this.state.ddchecked1 === false) {
          var obj_error = document.getElementById("check_error1");
          obj_error.style.display = "block";
          var ack = document.getElementById("dd_acknowledgement1");
          ack.classList.add("is-invalid");
          var acklabel = document.getElementById("dd_acknowledgement1")
            .labels[0];
          acklabel.style.color = "#555";
        }
        if (this.state.ddchecked2 === false) {
          var obj_error2 = document.getElementById("check_error2");
          obj_error2.style.display = "block";
          var ack2 = document.getElementById("dd_acknowledgement2");
          ack2.classList.add("is-invalid");
          var acklabel2 = document.getElementById("dd_acknowledgement2")
            .labels[0];
          acklabel2.style.color = "#555";
        }
        if (this.state.ddchecked3 === false) {
          var obj_error3 = document.getElementById("check_error3");
          obj_error3.style.display = "block";
          var ack3 = document.getElementById("dd_acknowledgement3");
          ack3.classList.add("is-invalid");
          var acklabel3 = document.getElementById("dd_acknowledgement3")
            .labels[0];
          acklabel3.style.color = "#555";
        }
        var mmAccountTypeEvent = new Event("blur");
        Object.defineProperty(mmAccountTypeEvent, "target", {
          writable: false,
          value: document.getElementById("account_type_mm"),
        });
        await this.onBlur(mmAccountTypeEvent);

        var mmRoutingNumEvent = new Event("blur");
        Object.defineProperty(mmRoutingNumEvent, "target", {
          writable: false,
          value: document.getElementById("routing_number_mm"),
        });
        await this.onBlur(mmRoutingNumEvent);

        var mmAcctNumEvent = new Event("blur");
        Object.defineProperty(mmAcctNumEvent, "target", {
          writable: false,
          value: document.getElementById("account_number_mm"),
        });
        await this.onBlur(mmAcctNumEvent);

        var mmConfirmAcctNumEvent = new Event("blur");
        Object.defineProperty(mmConfirmAcctNumEvent, "target", {
          writable: false,
          value: document.getElementById("confirm_account_number_mm"),
        });
        await this.onBlur(mmConfirmAcctNumEvent);

        if (
          this.state.compensation_type ===
          "MassMutual and Affiliated Broker Dealer (MMLIS Registered Business)"
        ) {
          var affAccountTypeEvent = new Event("blur");
          Object.defineProperty(affAccountTypeEvent, "target", {
            writable: false,
            value: document.getElementById("account_type_aff"),
          });
          await this.onBlur(affAccountTypeEvent);

          var affRoutingNumEvent = new Event("blur");
          Object.defineProperty(affRoutingNumEvent, "target", {
            writable: false,
            value: document.getElementById("routing_number_aff"),
          });
          await this.onBlur(affRoutingNumEvent);

          var affAcctNumEvent = new Event("blur");
          Object.defineProperty(affAcctNumEvent, "target", {
            writable: false,
            value: document.getElementById("account_number_aff"),
          });
          await this.onBlur(affAcctNumEvent);

          var affConfirmAcctNumEvent = new Event("blur");
          Object.defineProperty(affConfirmAcctNumEvent, "target", {
            writable: false,
            value: document.getElementById("confirm_account_number_aff"),
          });
          await this.onBlur(affConfirmAcctNumEvent);
        }
      }
    }

    // the mm account, confirm account and routing errors could be showing even though the field passes length validation
    let account_error_mm = document.getElementById("account_number_mm_error");
    let confirm_error_mm = document.getElementById(
      "confirm_account_number_mm_error"
    );
    let routing_error_mm = document.getElementById("routing_number_mm_error");

    // the aff account, confirm account and routing errors could be showing even though the field passes length validation
    let account_error_aff = document.getElementById("account_number_aff_error");
    let confirm_error_aff = document.getElementById(
      "confirm_account_number_aff_error"
    );
    let routing_error_aff = document.getElementById("routing_number_aff_error");

    if (
      this.state.compensation_type === "MassMutual Only" &&
      this.state.account_type_mm !== "" &&
      this.state.routing_number_mm !== "" &&
      this.state.account_number_mm !== "" &&
      this.state.confirm_account_number_mm !== "" &&
      this.state.account_number_mm === this.state.confirm_account_number_mm &&
      confirm_error_mm.style.display !== "block" &&
      account_error_mm.style.display !== "block" &&
      routing_error_mm.style.display !== "block" &&
      this.state.ddchecked1 === true &&
      this.state.ddchecked2 === true &&
      this.state.ddchecked3 === true
    ) {
      allFilled = true;
    } else if (
      this.state.compensation_type ===
        "MassMutual and Affiliated Broker Dealer (MMLIS Registered Business)" &&
      this.state.account_type_mm !== "" &&
      this.state.routing_number_mm !== "" &&
      this.state.account_number_mm !== "" &&
      this.state.confirm_account_number_mm !== "" &&
      this.state.account_number_mm === this.state.confirm_account_number_mm &&
      this.state.account_type_aff !== "" &&
      this.state.routing_number_aff !== "" &&
      this.state.account_number_aff !== "" &&
      this.state.confirm_account_number_aff !== "" &&
      this.state.account_number_aff === this.state.confirm_account_number_aff &&
      confirm_error_mm.style.display !== "block" &&
      account_error_mm.style.display !== "block" &&
      routing_error_mm.style.display !== "block" &&
      confirm_error_aff.style.display !== "block" &&
      account_error_aff.style.display !== "block" &&
      routing_error_aff.style.display !== "block" &&
      this.state.ddchecked1 === true &&
      this.state.ddchecked2 === true &&
      this.state.ddchecked3 === true
    ) {
      allFilled = true;
    }
    //console.log("source: " + source);
    if (
      source === "onBlur" ||
      source === "acknowledgements" ||
      source === "checked" ||
      source === "onload" ||
      source === "change" ||
      source === "next"
    ) {
      //this.setState({ nextDisabled: !allFilled });
    }

    if (
      allFilled &&
      source !== "onBlur" &&
      source !== "acknowledgements" &&
      source !== "checked"
    ) {
      let data = {
        candidateid: this.state.candidate_id,
        compensationtype:
          this.state.compensation_type ===
          "MassMutual and Affiliated Broker Dealer (MMLIS Registered Business)"
            ? 2
            : 1,
        mmaccounttype: this.state.account_type_mm === "Checking" ? 1 : 2,
        mmroutingnumber: this.state.routing_number_mm,
        mmaccountnumber: this.state.account_number_mm,
        contractid: this.state.contract_type_id,
        brokaccounttype:
          this.state.account_type_aff === ""
            ? null
            : this.state.account_type_aff === "Checking"
            ? 1
            : 2,
        brokroutingnumber:
          this.state.routing_number_aff === ""
            ? null
            : this.state.routing_number_aff,
        brokaccountnumber:
          this.state.account_number_aff === ""
            ? null
            : this.state.account_number_aff,
        participationrules: this.state.ddchecked1,
        netcompensation: this.state.ddchecked2,
        executorpayment: this.state.ddchecked3,
      };
      console.log(data);
      if (source !== "onload") {
        putDirectDepositInfo(this.props.subId, data);
      }
      this.setState({ hasCurrentFormChanged: false });
      this.props.saveDirectDepositInfo("directDepositInformation", {
        brokaccountnumber:
          this.state.account_number_aff === ""
            ? null
            : this.state.account_number_aff,
        brokaccounttype:
          this.state.account_type_aff === ""
            ? null
            : this.state.account_type_aff === "Checking"
            ? 1
            : 2,
        brokroutingnumber:
          this.state.routing_number_aff === ""
            ? null
            : this.state.routing_number_aff,
        candidateid: this.state.candidate_id,
        compensationtype:
          this.state.compensation_type ===
          "MassMutual and Affiliated Broker Dealer (MMLIS Registered Business)"
            ? 2
            : 1,
        executorpayment: this.state.ddchecked3,
        mmaccountnumber: this.state.account_number_mm,
        mmaccounttype: this.state.account_type_mm === "Checking" ? 1 : 2,
        mmroutingnumber: this.state.routing_number_mm,
        netcompensation: this.state.ddchecked2,
        oktasubid: this.props.subId,
        participationrules: this.state.ddchecked1,
      });
      this.props.isFormComplete("DirectDepositCompleteInd", allFilled);
      this.props.hasCurrentFormChanged(false);
      this.setState({ formHasChanged: false });
      console.log("MMLIS", this.props.isMMLIS);
      isCareerContract(this.props.contractType) && this.props.isMMLIS
        ? this.props.toggleForm(FORM_ID.MMLIS_INTRO)
        : this.props.toggleForm(FORM_ID.REVIEW_SIGN_1);
    }
  }

  async getBankName(routingNumber) {
    try {
      const result = await getBankRoutingInfo(routingNumber);
      if (result !== null) {
        await this.setState({
          bank_routing_error: result.messages[0].msgType,
        });
        if (result.messages[0].msgType === "WARNING") {
          await this.setState({ bank_routing_name: "" });
        } else {
          await this.setState({
            bank_routing_name: result.agreements[0].bankName,
          });
        }
      } else {
        await this.setState({
          bank_routing_error: "WARNING",
        });
      }
    } catch (err) {
      await this.setState({
        bank_routing_name: "",
        bank_routing_error: "WARNING",
      });
    }
  }

  clearInputErrors(type) {
    let accountTypeInput = document.getElementById("account_type_aff");
    let routingInput = document.getElementById("routing_number_aff");
    let accountInput = document.getElementById("account_number_aff");
    let accountConfirmInput = document.getElementById(
      "confirm_account_number_aff"
    );

    let accountTypeErrorMessageBox = document.getElementById(
      accountTypeInput.id + "_error"
    );
    let routingErrorMessageBox = document.getElementById(
      routingInput.id + "_error"
    );
    let accountErrorMessageBox = document.getElementById(
      accountInput.id + "_error"
    );
    let accountConfirmErrorMessageBox = document.getElementById(
      accountConfirmInput.id + "_error"
    );

    accountTypeInput.style = "1px solid #6b748e";
    routingInput.style = "1px solid #6b748e";
    accountInput.style = "1px solid #6b748e";
    accountConfirmInput.style = "1px solid #6b748e";

    accountTypeErrorMessageBox.style.display = "none";
    routingErrorMessageBox.style.display = "none";
    accountErrorMessageBox.style.display = "none";
    accountConfirmErrorMessageBox.style.display = "none";

    if (type === "all") {
      let accountTypeInputMM = document.getElementById("account_type_mm");
      let routingInputMM = document.getElementById("routing_number_mm");
      let accountInputMM = document.getElementById("account_number_mm");
      let accountConfirmInputMM = document.getElementById(
        "confirm_account_number_mm"
      );

      let accountTypeErrorMessageBoxMM = document.getElementById(
        accountTypeInputMM.id + "_error"
      );
      let routingErrorMessageBoxMM = document.getElementById(
        routingInputMM.id + "_error"
      );
      let accountErrorMessageBoxMM = document.getElementById(
        accountInputMM.id + "_error"
      );
      let accountConfirmErrorMessageBoxMM = document.getElementById(
        accountConfirmInputMM.id + "_error"
      );

      accountTypeInputMM.style = "1px solid #6b748e";
      routingInputMM.style = "1px solid #6b748e";
      accountInputMM.style = "1px solid #6b748e";
      accountConfirmInputMM.style = "1px solid #6b748e";

      accountTypeErrorMessageBoxMM.style.display = "none";
      routingErrorMessageBoxMM.style.display = "none";
      accountErrorMessageBoxMM.style.display = "none";
      accountConfirmErrorMessageBoxMM.style.display = "none";
    }
  }

  copyInputErrors() {
    let accountTypeInputMM = document.getElementById("account_type_mm");
    let accountTypeErrorMessageBoxMM = document.getElementById(
      accountTypeInputMM.id + "_error"
    );
    if (accountTypeErrorMessageBoxMM.style.display === "block") {
      let accountTypeInputAFF = document.getElementById("account_type_aff");
      let accountTypeErrorMessageBoxAFF = document.getElementById(
        accountTypeInputAFF.id + "_error"
      );
      accountTypeInputAFF.style.border = "1px solid #e21224";
      accountTypeInputAFF.style.display = "block";
      accountTypeErrorMessageBoxAFF.innerHTML =
        accountTypeErrorMessageBoxMM.innerHTML;
      accountTypeErrorMessageBoxAFF.style.display = "block";
    }

    let routingInputMM = document.getElementById("routing_number_mm");
    let routingErrorMessageBoxMM = document.getElementById(
      routingInputMM.id + "_error"
    );
    if (routingErrorMessageBoxMM.style.display === "block") {
      let routingInputAFF = document.getElementById("routing_number_aff");
      let routingErrorMessageBoxAFF = document.getElementById(
        routingInputAFF.id + "_error"
      );
      routingInputAFF.style.border = "1px solid #e21224";
      routingInputAFF.style.display = "block";
      routingErrorMessageBoxAFF.innerHTML = routingErrorMessageBoxMM.innerHTML;
      routingErrorMessageBoxAFF.style.display = "block";
    }

    let accountInputMM = document.getElementById("account_number_mm");
    let accountErrorMessageBoxMM = document.getElementById(
      accountInputMM.id + "_error"
    );
    if (accountErrorMessageBoxMM.style.display === "block") {
      let accountInputAFF = document.getElementById("account_number_aff");
      let accountErrorMessageBoxAFF = document.getElementById(
        accountInputAFF.id + "_error"
      );
      accountInputAFF.style.border = "1px solid #e21224";
      accountInputAFF.style.display = "block";
      accountErrorMessageBoxAFF.innerHTML = accountErrorMessageBoxMM.innerHTML;
      accountErrorMessageBoxAFF.style.display = "block";
    }

    let accountConfirmInputMM = document.getElementById(
      "confirm_account_number_mm"
    );
    let accountConfirmErrorMessageBoxMM = document.getElementById(
      accountConfirmInputMM.id + "_error"
    );
    if (accountConfirmErrorMessageBoxMM.style.display === "block") {
      let accountConfirmInputAFF = document.getElementById(
        "confirm_account_number_aff"
      );
      let accountConfirmErrorMessageBoxAFF = document.getElementById(
        accountConfirmInputAFF.id + "_error"
      );
      accountConfirmInputAFF.style.border = "1px solid #e21224";
      accountConfirmInputAFF.style.display = "block";
      accountConfirmErrorMessageBoxAFF.innerHTML =
        accountConfirmErrorMessageBoxMM.innerHTML;
      accountConfirmErrorMessageBoxAFF.style.display = "block";
    }
  }

  handleCloseBackModal() {
    this.setState({ showBackModal: false });
  }

  handleBackClick() {
    this.props.setIsBack(true);
    this.setState({ formHasChanged: false });
    this.handleCloseBackModal();
    //this.props.toggleForm(8);
    if (this.props.contractType.includes("Career")) {
      this.props.toggleForm(FORM_ID.FINANCIAL_DISCLOSURE);
    } else {
      this.props.toggleForm(FORM_ID.ERRORS_OMISSIONS);
    }
  }

  handleShowBackModal() {
    //show back modal if anything on the form has changed
    if (this.state.formHasChanged) {
      this.setState({ showBackModal: true });
    } else {
      this.props.setIsBack(true);
      if (
        this.props.contractType.includes("Career") ||
        this.props.contractType ===
          CONTRACT_TYPE_DESCRIPTIONS.ALTERNATE_AGENT ||
        this.props.contractType === CONTRACT_TYPE_DESCRIPTIONS.COLLEGE_INTERN
      ) {
        this.props.toggleForm(FORM_ID.FINANCIAL_DISCLOSURE);
      } else {
        this.props.toggleForm(FORM_ID.ERRORS_OMISSIONS);
      }
    }
  }
  render() {
    return (
      <>
        <div className="row">
          <div className="col-md-12">
            <h1 id="dd_header">Direct Deposit Information</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <span id="dd_required_text" className="required-field-text">
              * = Required Field
            </span>
          </div>
        </div>
        <div id="dd_provide_text" className="row">
          <div className="col-md-12">
            <h3 style={{ paddingTop: "15px" }}>
              Provide your compensation type and bank or financial institution
              information to set up direct deposit.
            </h3>
          </div>
        </div>
        <div id="dd_fastcash_text" className="row">
          <div className="col-md-12" style={{ paddingTop: "15px" }}>
            FastCash is MassMutual’s direct deposit program for producers.
            FastCash electronically transmits a record of your pay to a
            financial institution of your choice. Only authorized transactions
            can be made to your account, as FastCash does not enable MassMutual
            or your Affiliated Broker Dealer to gain access to information about
            your account. <sup>1</sup>
          </div>
        </div>

        <div className="row" style={{ marginTop: "20px" }}>
          <div className="col-md-12">
            <label id="dd_compensation_header" htmlFor="compensation_type">
              <strong>Compensation Type*</strong>
            </label>
            <DropdownButton
              id="compensation_type"
              type="default"
              variant="secondary"
              title={this.state.compensation_type}
              selected={false}
              autoComplete="new-password"
              aria-label="aria_compensation_type"
              onSelect={(key, event) => {
                //event.target.id = "compensation_type";
                event.target.value = key;
                this.handleChange(event);
                this.onKeyDown();
              }}
              onBlur={this.onBlur}
              onClick={clickFunc}
              onFocus={this.onFocus}
            >
              <Dropdown.Item eventKey={"MassMutual Only"} id="comp_option1">
                MassMutual Only
              </Dropdown.Item>
              <Dropdown.Item
                eventKey={
                  "MassMutual and Affiliated Broker Dealer (MMLIS Registered Business)"
                }
                id="comp_option2"
              >
                MassMutual and Affiliated Broker Dealer (MMLIS Registered
                Business)
              </Dropdown.Item>
            </DropdownButton>
            <div id="compensation_type_error" className="invalid-feedback">
              Please select an option for your Compensation Type
            </div>
          </div>
        </div>
        <div className="row" style={{ marginTop: "5px" }}>
          <div id="dd_netpay_text" className="col-md-12">
            <span style={{ fontSize: "14px" }}>
              MassMutual and your affiliated broker dealer’s net pay can be
              direct deposited to the same checking or savings accounts or
              separate bank accounts.
            </span>
          </div>
        </div>

        <div
          id="mm"
          className={
            this.state.compensation_type ===
              "MassMutual and Affiliated Broker Dealer (MMLIS Registered Business)" ||
            this.state.compensation_type === "MassMutual Only"
              ? "d-block"
              : "d-none"
          }
          style={{ paddingBottom: "10px" }}
        >
          <div id="dd_comp_header2" className="row">
            <div className="col-md-12" style={{ paddingTop: "20px" }}>
              <h3>MassMutual Compensation</h3>
            </div>
          </div>

          <div className="row" style={{ marginTop: "20px" }}>
            <div id="dd_account_type" className="col-md-12">
              <label id="mmAccountTypeLabel" htmlFor="account_type_mm">
                <strong>Account Type*</strong>
              </label>
              <DropdownButton
                id="account_type_mm"
                type="default"
                variant="secondary"
                title={this.state.account_type_mm}
                selected={false}
                autoComplete="off"
                aria-label="aria_account_type_mm"
                onSelect={(key, event) => {
                  //event.target.id = "account_type_mm";
                  event.target.value = key;
                  this.handleChange(event);
                  this.onKeyDown();
                }}
                onBlur={this.onBlur}
                onClick={clickFunc}
                onFocus={this.onFocus}
              >
                <Dropdown.Item eventKey={"Checking"} id="mm_option1">
                  Checking
                </Dropdown.Item>
                <Dropdown.Item eventKey={"Savings"} id="mm_option2">
                  Savings
                </Dropdown.Item>
              </DropdownButton>
              <div id="account_type_mm_error" className="invalid-feedback">
                Please select an option for your Account Type
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: "20px" }}>
            <div className="col-md-12">
              <label id="mmRoutingNumberLabel" htmlFor="routing_number_mm">
                <strong>Routing Number*</strong>
              </label>
              <input
                type="text"
                id="routing_number_mm"
                className="form-control"
                maxLength={9}
                value={this.state.routing_number_mm}
                onBlur={this.onBlur}
                onChange={this.handleChange}
                onKeyDown={this.onKeyDown}
                onKeyPress={onlyNumeric}
                autoComplete="off"
              />
              <div id="routing_number_mm_error" className="invalid-feedback">
                Please enter a valid Routing Number
              </div>
              <div
                id="mmBankName"
                className={
                  this.state.bank_name_mm !== "" ? "d-block" : "d-none"
                }
              >
                <span style={{ fontSize: "14px", color: "#555555" }}>
                  {this.state.bank_name_mm}
                </span>
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: "20px" }}>
            <div className="col-md-12">
              <label id="mmAccountNumberLabel" htmlFor="account_number_mm">
                <strong>Account Number*</strong>
              </label>
              <input
                type="text"
                id="account_number_mm"
                className="form-control"
                maxLength={17}
                value={this.state.account_number_mm}
                onBlur={this.onBlur}
                onChange={this.handleChange}
                onKeyDown={this.onKeyDown}
                onKeyPress={onlyNumeric}
                autoComplete="off"
              />
              <div id="account_number_mm_error" className="invalid-feedback">
                Please enter a valid Account Number
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: "20px" }}>
            <div className="col-md-12">
              <label
                id="mmAccountNumberConfirmLabel"
                htmlFor="confirm_account_number_mm"
              >
                <strong>Confirm Account Number*</strong>
              </label>
              <input
                type="text"
                id="confirm_account_number_mm"
                className="form-control"
                maxLength={17}
                value={this.state.confirm_account_number_mm}
                onBlur={this.onBlur}
                onChange={this.handleChange}
                onKeyDown={this.onKeyDown}
                onKeyPress={onlyNumeric}
                autoComplete="off"
              />
              <div
                id="confirm_account_number_mm_error"
                className="invalid-feedback"
              >
                Please enter a valid Account Number
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: "25px" }}>
            <div className="col-md-12">
              <img alt="account" src={bankAccount} height="90" />
            </div>
          </div>
        </div>

        <div
          id="affiliated"
          className={
            this.state.compensation_type ===
            "MassMutual and Affiliated Broker Dealer (MMLIS Registered Business)"
              ? "d-block"
              : "d-none"
          }
          style={{ paddingBottom: "10px" }}
        >
          <div id="dd_ABDC" className="row">
            <div className="col-md-12" style={{ paddingTop: "20px" }}>
              <h3>
                Affiliated Broker Dealer Compensation (MMLIS Registered
                Business)
              </h3>
            </div>
          </div>

          <div
            id="same_account"
            className={
              this.state.compensation_type ===
              "MassMutual and Affiliated Broker Dealer (MMLIS Registered Business)"
                ? "d-block"
                : "d-none"
            }
            style={{ paddingBottom: "10px" }}
          >
            <div className="row">
              <div className="col-md-12" style={{ paddingTop: "20px" }}>
                <FormCustomControl
                  id="same_account_checked"
                  inputType="checkbox"
                  label="Use the same account used for MassMutual compensation"
                  onChange={this.handleChecked}
                  checked={this.state.same_account_checked}
                  disabled={this.state.is_checkbox_disabled}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <label id="affAccountTypeLabel" htmlFor="account_type_aff">
                <strong>Account Type*</strong>
              </label>
              <DropdownButton
                id="account_type_aff"
                type="default"
                variant="secondary"
                title={this.state.account_type_aff}
                selected={false}
                autoComplete="off"
                onSelect={(key, event) => {
                  //event.target.id = "account_type_aff";
                  event.target.value = key;
                  this.handleChange(event);
                  this.onKeyDown();
                }}
                onBlur={this.onBlur}
                onClick={clickFunc}
                onFocus={this.onFocus}
                disabled={this.state.same_account_checked}
              >
                <Dropdown.Item eventKey={"Checking"} id="aff_option1">
                  Checking
                </Dropdown.Item>
                <Dropdown.Item eventKey={"Savings"} id="aff_option2">
                  Savings
                </Dropdown.Item>
              </DropdownButton>
              <div id="account_type_aff_error" className="invalid-feedback">
                Please select an option for your Account Type
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: "20px" }}>
            <div className="col-md-12">
              <label id="affRoutingNumberLabel" htmlFor="routing_number_aff">
                <strong>Routing Number*</strong>
              </label>
              <input
                type="text"
                id="routing_number_aff"
                className="form-control"
                maxLength={9}
                value={this.state.routing_number_aff}
                onBlur={this.onBlur}
                onChange={this.handleChange}
                onKeyDown={this.onKeyDown}
                onKeyPress={onlyNumeric}
                disabled={this.state.same_account_checked}
                autoComplete="off"
              />
              <div id="routing_number_aff_error" className="invalid-feedback">
                Please enter a valid Routing Number
              </div>
              <div
                id="affBankName"
                className={
                  this.state.bank_name_aff !== "" &&
                  this.state.same_account_checked === false
                    ? "d-block"
                    : "d-none"
                }
              >
                <span style={{ fontSize: "14px", color: "#555555" }}>
                  {this.state.bank_name_aff}
                </span>
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: "20px" }}>
            <div className="col-md-12">
              <label id="affAccountNumberLabel" htmlFor="account_number_aff">
                <strong>Account Number*</strong>
              </label>
              <input
                type="text"
                id="account_number_aff"
                className="form-control"
                maxLength={17}
                value={this.state.account_number_aff}
                onBlur={this.onBlur}
                onChange={this.handleChange}
                onKeyDown={this.onKeyDown}
                onKeyPress={onlyNumeric}
                disabled={this.state.same_account_checked}
                autoComplete="off"
              />
              <div id="account_number_aff_error" className="invalid-feedback">
                Please enter a valid Account Number
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: "20px" }}>
            <div className="col-md-12">
              <label
                id="affAccountNumberConfirmLabel"
                htmlFor="confirm_account_number_aff"
              >
                <strong>Confirm Account Number*</strong>
              </label>
              <input
                type="text"
                id="confirm_account_number_aff"
                className="form-control"
                maxLength={17}
                value={this.state.confirm_account_number_aff}
                onBlur={this.onBlur}
                onChange={this.handleChange}
                onKeyDown={this.onKeyDown}
                onKeyPress={onlyNumeric}
                disabled={this.state.same_account_checked}
                autoComplete="off"
              />
              <div
                id="confirm_account_number_aff_error"
                className="invalid-feedback"
              >
                Please enter a valid Account Number
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: "25px" }}>
            <div className="col-md-12">
              <img alt="account" src={bankAccount} height="90" />
            </div>
          </div>
        </div>

        <div
          id="blurb"
          className={this.state.compensation_type !== "" ? "d-block" : "d-none"}
          style={{ paddingBottom: "10px" }}
        >
          <div id="dd_acknowledge_heading" className="row">
            <div className="col-md-12" style={{ paddingTop: "20px" }}>
              <h3>Acknowledgements*</h3>
            </div>
          </div>
          <div className="row" style={{ paddingTop: "20px", marginLeft: "0" }}>
            <FormCustomControl
              id="dd_acknowledgement1"
              inputType="checkbox"
              label="I acknowledge that MassMutual and my Affiliated Broker Dealer
              may at any time change the rules for participation in this
              program and that as a participant in the program MassMutual and
              my Affiliated Broker Dealer shall notify me of the changed rules
              and I shall have the option to discontinue the program if I do
              not want to be bound under the new rules."
              onChange={this.handleAcknowledgmentChecks}
              checked={this.state.ddchecked1}
            />
            <div
              id="check_error1"
              className="invalid-feedback"
              style={{ paddingBottom: "20px" }}
            >
              Please check the box to confirm your acknowledgement.
            </div>
          </div>
          <div className="row" style={{ marginLeft: "0" }}>
            <FormCustomControl
              id="dd_acknowledgement2"
              inputType="checkbox"
              label="I authorize MassMutual and my Affiliated Broker Dealer to
              deposit my net compensation into my account listed above. I also
              authorize MassMutual and my Affiliated Broker Dealer to initiate
              debits to the bank account for payments made to me in error and
              the financial institution named above to debit my account and
              refund any such payments to MassMutual and my Affiliated Broker
              Dealer. Payments made under this agreement shall fully satisfy
              MassMutual and my Affiliated Broker Dealer’s obligation to make
              payments to me."
              onChange={this.handleAcknowledgmentChecks}
              checked={this.state.ddchecked2}
            />
            <div
              id="check_error2"
              className="invalid-feedback"
              style={{ paddingBottom: "20px" }}
            >
              Please check the box to confirm your acknowledgement.
            </div>
          </div>
          <div className="row" style={{ marginLeft: "0" }}>
            <FormCustomControl
              id="dd_acknowledgement3"
              inputType="checkbox"
              label="Upon my death and upon written request by MassMutual, my
              executors or administrators shall pay MassMutual or my
              Affiliated Broker Dealer, if applicable, from my estate, the
              amount of any payments to my account after my death to which I
              was not entitled."
              onChange={this.handleAcknowledgmentChecks}
              checked={this.state.ddchecked3}
            />
            <div
              id="check_error3"
              className="invalid-feedback"
              style={{ paddingBottom: "20px" }}
            >
              Please check the box to confirm your acknowledgement.
            </div>
          </div>
        </div>

        <div className="row">
          <div
            className="col-md-12"
            style={{ textAlign: "right", paddingTop: "30px" }}
          >
            <span
              id="dd_back"
              onClick={() => {
                this.handleShowBackModal();
              }}
              style={{
                marginRight: "25px",
                color: "#3174af",
                cursor: "pointer",
              }}
            >
              <strong> Back </strong>
            </span>

            <Button
              id="dd_next"
              style={{ marginRight: "10px" }}
              onClick={() => {
                this.handleNext("nextclick");
              }}
            >
              Next
            </Button>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12" style={{ paddingTop: "20px" }}>
            <span
              style={{
                fontSize: "12px",
                color: "rgb(0,0,0,0.85)",
                fontWeight: "390",
                lineHeight: "18px",
              }}
            >
              1 If you have any questions about FastCash, please call us at
              1-800-767-1000, ext 48850, 8 am to 6 pm ET, Monday through Friday.
            </span>
          </div>
        </div>

        <BackButtonModal
          isOpen={this.state.showBackModal}
          closeBackModal={this.handleCloseBackModal}
          backClick={this.handleBackClick}
        />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setIsBack: (bool) => dispatch(ccbActions.setIsBack(bool)),
  };
};

export default connect(null, mapDispatchToProps)(DirectDepositForm);
