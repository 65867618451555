import React, { Component, Fragment } from "react";
import MMLISContributionDeleteModal from "./MMLISContributionDeleteModal";
import Moment from "moment";

class MMLISContributionTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show_modal: false,
      delete_index: -1,
      show_spinner: false,
      contributions: "",
    };

    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
    this.deleteContribution = this.deleteContribution.bind(this);
    this.handleReviewEditClick = this.handleReviewEditClick.bind(this);
    this.showMenu = this.showMenu.bind(this);
    this.hideMenu = this.hideMenu.bind(this);
    this.loadData = this.loadData.bind(this);
    this.showSpinner = this.showSpinner.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.loadData();
    }
  }

  async loadData() {
    this.setState({ contributions: this.props.data });
  }

  showMenu(element) {
    var dropdowns = document.getElementsByClassName("dropdown-content");
    var i;
    for (i = 0; i < dropdowns.length; i++) {
      var openDropdown = dropdowns[i];
      if (openDropdown.classList.contains("show")) {
        openDropdown.classList.remove("show");
      }
    }
    element.target.parentNode.children[1].className = "dropdown-content show";
  }

  hideMenu(element) {
    element.target.parentNode.children[1].className = "dropdown-content";
  }

  async handleDeleteClick(item) {
    await this.setState({ delete_index: item });
    this.setState({ show_modal: true });
  }

  async closeDeleteModal() {
    await this.setState({ show_modal: false });
  }

  async deleteContribution() {
    this.showSpinner(true);
    await this.props.deleteData(this.state.delete_index);
    this.showSpinner(false);
    await this.setState({ show_modal: false });
  }

  async handleReviewEditClick(job) {
    this.props.reviewEditClick(job);
  }

  async showSpinner(value) {
    this.setState({ show_spinner: value });
  }

  render() {
    return (
      <div id="mmlisContribTable" className="col-md-8">
        <div className="row" style={{ paddingTop: "20px" }}>
          <div className="col-md-12">
            {this.state.contributions.length > 0 &&
              this.state.contributions[0].contributionsmanageid !== null &&
              this.state.contributions[0].contributionsgeneralid !== null && (
                <>
                  {Array.from(this.state.contributions).map((contrib, key) => {
                    let contribDate = Moment.utc(contrib.contribdate).format(
                      "MM/DD/YYYY"
                    );

                    var commaNumber = contrib.contribamt.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      ","
                    );

                    return (
                      <Fragment key={key}>
                        <hr style={{ marginRight: "25%" }}></hr>
                        <div className="custom-table-row">
                          <div className="col-md-3">
                            <span className="timeline-date-text">
                              Contribution Details
                            </span>
                          </div>
                          <div className="col-md-2">
                            <span className="timeline-date-text">
                              {contribDate}
                            </span>
                          </div>
                          <div className="col-md-3">
                            <span className="timeline-date-text">
                              ${commaNumber.replace(".00", "")}
                            </span>
                          </div>
                          <div className="col-md-1">
                            <div className="mmlis-quick-action-wrapper">
                              <button
                                id="dropbtn"
                                className="icon-md icon-quick-actions"
                                aria-label="Review/Edit show menu"
                                onClick={this.showMenu}
                              />
                              <div
                                id={"mnuDropDown" + key}
                                className="dropdown-content"
                                style={{ left: "20px", right: "0px" }}
                              >
                                <span
                                  role="presentation"
                                  id="mnuLinkReview"
                                  onClick={() =>
                                    this.handleReviewEditClick(contrib)
                                  }
                                  className="menu-item-hover"
                                >
                                  Review/Edit
                                </span>
                                <span
                                  role="presentation"
                                  id="mnuLinkDelete"
                                  onClick={() =>
                                    this.handleDeleteClick(contrib)
                                  }
                                  style={{ textAlign: "left" }}
                                  className="menu-item-hover"
                                >
                                  Delete
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    );
                  })}
                  <hr style={{ marginRight: "25%" }}></hr>

                  <MMLISContributionDeleteModal
                    message={
                      " Are you sure you'd like to delete this contribution?"
                    }
                    isOpen={this.state.show_modal}
                    closeClick={this.closeDeleteModal}
                    deleteClick={this.deleteContribution}
                    show_spinner={this.state.show_spinner}
                    showSpinner={this.showSpinner}
                  />
                </>
              )}
          </div>
        </div>
      </div>
    );
  }
}

export default MMLISContributionTable;
