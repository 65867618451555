import React from "react";
import AuthorizationFormModal from "../Forms/AuthorizationFormModal";
import BackButtonModal from "../Forms/BackButtonModal";
import { Button } from "react-blueprint-library";
import "../StartApplication.scss";
import { putAuthorizationToWork } from "../../../api/makeAPIRequest";
import { FORM_ID } from "../../../constants/Forms";
import NavigationButtons from "../../SharedComponents/NavigationButtons";
import { UtagTracking } from "../../../utils/utag-tracking";
import { CONTRACT_TYPE_DESCRIPTIONS } from "../../../constants/Contract";

class AuthorizationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initialState();
    this.handleAuthorizedChange = this.handleAuthorizedChange.bind(this);
    this.handleSponsorshipChange = this.handleSponsorshipChange.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleContactAgency = this.handleContactAgency.bind(this);
    this.handleShowBackModal = this.handleShowBackModal.bind(this);
    this.handleCloseBackModal = this.handleCloseBackModal.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
  }

  async componentDidMount() {
    try {
      const { authToWorkData } = this.props;

      let isAuthorized =
        authToWorkData.legallyauthorized === null
          ? ""
          : authToWorkData.legallyauthorized;
      let needSponsorship =
        authToWorkData.sponsorreq === null ? "" : authToWorkData.sponsorreq;
      let candidateId =
        authToWorkData.candidateid === null ? "" : authToWorkData.candidateid;

      if (isAuthorized === true) {
        isAuthorized = "Y";
      }
      if (isAuthorized === false) {
        isAuthorized = "N";
      }
      if (needSponsorship === true) {
        needSponsorship = "Y";
      }
      if (needSponsorship === false) {
        needSponsorship = "N";
      }
      await this.setState({
        authorized: isAuthorized,
        sponsorship: needSponsorship,
        candidate_id: candidateId,
      });

      UtagTracking.sendPageNameView(
        "authorizationform",
        this.props.candidateId,
        this.props.agencynumber,
        this.props.contracttypeid
      );

      if (needSponsorship === "N" && isAuthorized === "Y") {
        this.setState({ is_form_saved: true });
      }
    } catch (err) {
      console.log(err);
    }
  }

  initialState() {
    return {
      authorized: "",
      sponsorship: "",
      showModal: false,
      showBackModal: false,
      formHasChanged: false,
      candidate_id: "",
      is_form_saved: false,
    };
  }

  async handleAuthorizedChange(event) {
    await this.setState({
      authorized: event.target.value,
      is_form_saved: false,
      formHasChanged: true,
    });
    this.props.isFormComplete("AuthorizationCompleteInd", false);
    this.props.hasCurrentFormChanged(true);

    if (event.target.value === "" && this.state.sponsorship === "N") {
      //do nothing
    } else if (event.target.value === "N" || this.state.sponsorship === "Y") {
      this.setState({ showModal: true });
    }
    //hide error stuff
    var obj_error = document.getElementById("authorized_error");
    obj_error.style.display = "none";
    var authorized_yes = document.getElementById("legally_authorized_yes");
    authorized_yes.classList.remove("is-invalid");
    var authorized_no = document.getElementById("legally_authorized_no");
    authorized_no.classList.remove("is-invalid");
  }

  async handleSponsorshipChange(event) {
    await this.setState({
      sponsorship: event.target.value,
      is_form_saved: false,
      formHasChanged: true,
    });
    this.props.isFormComplete("AuthorizationCompleteInd", false);

    this.props.hasCurrentFormChanged(true);

    if (event.target.value === "N" && this.state.authorized === "Y") {
      //do nothing
    } else if (event.target.value === "Y" || this.state.authorized === "N") {
      this.setState({ showModal: true });
    }
    var obj_error2 = document.getElementById("sponsorship_error");
    obj_error2.style.display = "none";
    var sponsorship_yes = document.getElementById("sponsorship_yes");
    sponsorship_yes.classList.remove("is-invalid");
    var sponsorship_no = document.getElementById("sponsorship_no");
    sponsorship_no.classList.remove("is-invalid");
  }

  async handleNext(source) {
    if (
      (this.state.authorized === "" || this.state.sponsorship === "") &&
      source !== "onload"
    ) {
      if (this.state.authorized === "") {
        //show errors
        var obj_error = document.getElementById("authorized_error");
        obj_error.style.display = "block";
        var authorized_yes = document.getElementById("legally_authorized_yes");
        authorized_yes.classList.add("is-invalid");
        var authorized_no = document.getElementById("legally_authorized_no");
        authorized_no.classList.add("is-invalid");
        var label_authorized_yes = document.getElementById(
          "label_authorized_yes"
        );
        label_authorized_yes.style.color = "#555";
        var label_authorized_no = document.getElementById(
          "label_authorized_no"
        );
        label_authorized_no.style.color = "#555";
      }
      if (this.state.sponsorship === "") {
        //show errors
        var obj_error2 = document.getElementById("sponsorship_error");
        obj_error2.style.display = "block";
        var sponsorship_yes = document.getElementById("sponsorship_yes");
        sponsorship_yes.classList.add("is-invalid");
        var sponsorship_no = document.getElementById("sponsorship_no");
        sponsorship_no.classList.add("is-invalid");
        var label_sponsorship_yes = document.getElementById(
          "label_sponsorship_yes"
        );
        label_sponsorship_yes.style.color = "#555";
        var label_sponsorship_no = document.getElementById(
          "label_sponsorship_no"
        );
        label_sponsorship_no.style.color = "#555";
      }
    } else if (
      this.state.authorized === "N" ||
      this.state.sponsorship === "Y"
    ) {
      this.setState({ showModal: true });
    } else if (
      this.state.authorized === "Y" &&
      this.state.sponsorship === "N" &&
      source !== "onload"
    ) {
      let data = {
        candidateid: this.state.candidate_id,
        legallyauthorized: this.state.authorized === "Y" ? true : false,
        sponsorreq: this.state.sponsorship === "Y" ? true : false,
      };
      putAuthorizationToWork(this.props.subId, data);
      await this.setState({ is_form_saved: true, formHasChanged: false });
      this.props.hasCurrentFormChanged(false);
      this.props.isFormComplete("AuthorizationCompleteInd", true);
      this.props.toggleForm(
        this.props.contractType === CONTRACT_TYPE_DESCRIPTIONS.MEMBER_CORP
          ? FORM_ID.MEMBER_CORPORATE_INFO
          : FORM_ID.PERSONAL_INFO
      );
      this.props.saveReleaseInfoAuthToWork("releaseInfoAuthToWork", {
        ...this.props.authToWorkData,
        legallyauthorized: true,
        sponsorreq: false,
      });
    } else if (
      this.state.authorized === "Y" &&
      this.state.sponsorship === "Y"
    ) {
      this.props.isFormComplete("AuthorizationCompleteInd", true);
    }
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  handleCloseBackModal() {
    this.setState({ showBackModal: false });
  }

  handleContactAgency() {
    this.handleCloseModal();
    window.location = "/";
  }

  handleBackClick() {
    this.setState({ formHasChanged: false });
    this.handleCloseBackModal();
    this.props.toggleForm(FORM_ID.RELEASE_OF_INFO);
  }

  handleShowBackModal() {
    //show back modal if anything on the form has changed
    if (this.state.formHasChanged) {
      this.setState({ showBackModal: true });
    } else {
      this.props.toggleForm(FORM_ID.RELEASE_OF_INFO);
    }
  }

  render() {
    return (
      <>
        {(this.props.contractType === CONTRACT_TYPE_DESCRIPTIONS.BROKER_CORP ||
          this.props.contractType ===
            CONTRACT_TYPE_DESCRIPTIONS.MEMBER_CORP) && (
          <div className="row">
            <div className="col-md-12">
              <div className="eyebrow">
                <strong>Member of Corporation</strong>
              </div>
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-md-12">
            <h1>Authorization to Work in the US</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <span className="required-field-text">* = Required Field</span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <h3
              id="auth_question_legal"
              style={{
                paddingBottom: "5px",
                paddingTop: "10px",
              }}
            >
              Are you legally authorized to work in the United States?*
            </h3>
            <div className="custom-control custom-radio">
              <input
                type="radio"
                id="legally_authorized_yes"
                name="authorized"
                className="custom-control-input"
                checked={this.state.authorized === "Y"}
                value="Y"
                onChange={this.handleAuthorizedChange}
              />
              <label
                htmlFor="legally_authorized_yes"
                className="custom-control-label"
                id="label_authorized_yes"
              >
                Yes
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                type="radio"
                id="legally_authorized_no"
                name="authorized"
                className="custom-control-input"
                checked={this.state.authorized === "N"}
                value="N"
                onChange={this.handleAuthorizedChange}
              />
              <label
                htmlFor="legally_authorized_no"
                className="custom-control-label"
                id="label_authorized_no"
              >
                No
              </label>
            </div>
            <div id="authorized_error" className="invalid-feedback">
              Please choose a response.
            </div>
          </div>

          <div className="col-md-12">
            <h3
              id="auth_question_sponsor"
              style={{
                paddingBottom: "5px",
                paddingTop: "10px",
              }}
            >
              Will you now or in the future require sponsorship for employment
              visas status?*
            </h3>
            <div className="custom-control custom-radio">
              <input
                type="radio"
                id="sponsorship_yes"
                name="sponsorship"
                className="custom-control-input"
                checked={this.state.sponsorship === "Y"}
                value="Y"
                onChange={this.handleSponsorshipChange}
              />
              <label
                htmlFor="sponsorship_yes"
                className="custom-control-label"
                id="label_sponsorship_yes"
              >
                Yes
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                type="radio"
                id="sponsorship_no"
                name="sponsorship"
                className="custom-control-input"
                checked={this.state.sponsorship === "N"}
                value="N"
                onChange={this.handleSponsorshipChange}
              />
              <label
                htmlFor="sponsorship_no"
                className="custom-control-label"
                id="label_sponsorship_no"
              >
                No
              </label>
            </div>
            <div id="sponsorship_error" className="invalid-feedback">
              Please choose a response.
            </div>
          </div>
        </div>

        <NavigationButtons
          onBack={this.handleShowBackModal}
          onNext={this.handleNext}
          nxtbtnid={"auth_next"}
          bckbtnid={"auth_back"}
        />

        <AuthorizationFormModal
          isOpen={this.state.showModal}
          closeModal={this.handleCloseModal}
          contactAgency={this.handleContactAgency}
        />

        <BackButtonModal
          isOpen={this.state.showBackModal}
          closeBackModal={this.handleCloseBackModal}
          backClick={this.handleBackClick}
        />
      </>
    );
  }
}

export default AuthorizationForm;
