import "./CustomCard.scss";
import svgs, { svgType } from "../../../../../../assets/svgs";

const CustomCard = ({
  headerText = "",
  isCompleted = false,
  children = null,
  isOpen = false,
  setIsOpen,
  index,
}) => {
  const onShow = () =>
    setIsOpen((prevState) => ({ ...prevState, [index]: !prevState[index] }));

  return (
    <div
      key={index}
      id={`container-${index}`}
      className="custom-card-container"
      onClick={onShow}
    >
      <div id={`header-${index}`} className="header">
        <div className="left">
          <strong id={`footerLink-${index}`} className="footer-link">
            {headerText}
          </strong>
        </div>
        <div className="right">
          <div className="complete">
            {isCompleted && (
              <span>
                {svgs[svgType.iconCheckCircle]}
                <span id={`complete-${index}`}>Complete</span>
              </span>
            )}
            <span className={`indicator ${isOpen ? "show" : ""}`}>
              {svgs[svgType.iconCaret]}
            </span>
          </div>
        </div>
      </div>

      {isOpen ? (
        <div
          className={`body ${isOpen ? "show" : ""}`}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="custom-card-container sub-card">{children}</div>
        </div>
      ) : null}
    </div>
  );
};
export default CustomCard;
