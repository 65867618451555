import React, { useState } from "react";

import "./NewRequalificationList.scss";
import ActionDropdown from "../ActionDropdown";
import DeleteModal from "../DeleteModal";

const NewRequalificationList = ({
  list,
  setNewChargesList,
  setEditChargeDetailsList,
  setShowAddNewChargeModal,
}) => {
  const [show, setShow] = useState(false);
  const [actionIndex, setActionIndex] = useState(null);

  const handleDeleteAction = (index) => {
    if (index === null) {
      return;
    }

    const newList = list.filter((_, i) => i !== index);

    setNewChargesList(newList);
    setShow(false);
  };

  const handleEditAction = (index) => {
    if (index === null) {
      return;
    }

    setEditChargeDetailsList({
      list: list.find((_, i) => i === index),
      index,
    });

    setShowAddNewChargeModal(true);
  };

  return (
    <div className="new-charges-list">
      <hr className="hr-top" />
      <div className="list-header">
        <div className="left">
          <div id="regReqTypeTableId">Requalification Type</div>
          <div id="regCondSatisfiedTableId">Condition Satisfied?</div>
        </div>
        <div id="reqActionTableId" className="action">
          Action
        </div>
      </div>

      <hr />

      <div className="rows">
        {list.map(({ requalificationType, satisfiedCondition }, index) => (
          <div key={`${index}-${requalificationType}`} className="rows-wrapper">
            <div className="drp-list-row">
              <div className="left">
                <div id={`reqType-${index}`}>{requalificationType}</div>
                <div id={`satisfiedCond-${index}`}>
                  {satisfiedCondition ? "Yes" : "No"}
                </div>
              </div>

              <div className="action">
                <ActionDropdown
                  id={`reqAction=${index}`}
                  key={index}
                  editAction={() => {
                    handleEditAction(index);
                  }}
                  showDelete={show}
                  deleteAction={() => {
                    setActionIndex(index);
                    setShow(true);
                  }}
                />
              </div>
            </div>

            <div>
              <hr />
            </div>
          </div>
        ))}
      </div>

      <DeleteModal
        isOpen={show}
        showSpinner={false}
        closeClick={() => setShow(false)}
        deleteClick={() => handleDeleteAction(actionIndex)}
      />
    </div>
  );
};

export default NewRequalificationList;
