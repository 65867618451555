import { useEffect, useCallback } from "react";

export const useClickOutside = (
  { current: buttonCurrent },
  { current: dropdownCurrent },
  dropdownIsVisible,
  callback
) => {
  const handleClickOutside = useCallback(
    ({ target }) => {
      if (
        dropdownIsVisible &&
        target &&
        dropdownCurrent &&
        !dropdownCurrent.contains(target) &&
        buttonCurrent &&
        !buttonCurrent.contains(target) &&
        callback
      ) {
        callback();
      }
    },
    [dropdownCurrent, buttonCurrent, dropdownIsVisible, callback]
  );

  useEffect(() => {
    if (dropdownIsVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownIsVisible, handleClickOutside]);
};
