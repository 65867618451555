export const CONTRACT_STATUS_ID = {
  APPROVED_BY_HOME_OFFICE: 1,
  DECLINED_BY_HOME_OFFICE: 2,
  APPLICATION_SUBMITTED: 3,
  APPLICATION_IN_PROGRESS: 4,
  INVITATION_SENT: 5,
  AGENCY_REVIEW: 6,
  RETURNED_TO_CANDIDATE: 7,
  HOME_OFFICE_REVIEW: 8,
  PENDING_COMPLIANCE: 9,
  NEEDS_REVISION: 10,
  ARCHIVE_INVITATION_CANCELLED: 11,
  ARCHIVE_APPLICATION_WITHDRAWN: 12,
  ARCHIVE_DECLINED_BY_AGENCY: 13,
  RETURNED_TO_AGENCY: 14,
  PENDING_ADDITIONAL_INFO_CANDIDATE: 15,
  SUBMITTED_TO_HOME_OFFICE: 16,
  BACKGROUND_CHECK_INITIATED: 17,
  BACKGROUND_CHECK_PENDING: 18,
  BACKGROUND_CHECK_RECEIVED: 19,
  PENDING_ADDITIONAL_INFO_AGENCY: 20,
  PENDING_ADDITIONAL_INFO_ANOTHER_DEPT: 21,
  PENDING_COMPLIANCE_RSD: 22,
  ACKNOWLEDGED_APPROVED: 23,
  ACKNOWLEDGED_DECLINED: 24,
};

export const CONTRACT_STATUS_DESC = {
  APPROVED_BY_HOME_OFFICE: "Approved by Home Office",
  DECLINED_BY_HOME_OFFICE: "Declined by Home Office",
  APPLICATION_SUBMITTED: "Application Submitted",
  APPLICATION_IN_PROGRESS: "Application in Progress",
  INVITATION_SENT: "Invitation Sent",
  AGENCY_REVIEW: "Agency Review",
  RETURNED_TO_CANDIDATE: "Returned to Candidate",
  HOME_OFFICE_REVIEW: "Home Office Review",
  PENDING_COMPLIANCE: "Pending Compliance",
  NEEDS_REVISION: "Needs Revision",
  ARCHIVE_INVITATION_CANCELLED: "Archive - Invitation Cancelled",
  ARCHIVE_APPLICATION_WITHDRAWN: "Archive - Application Withdrawn",
  ARCHIVE_DECLINED_BY_AGENCY: "Archive - Declined by Agency",
  RETURNED_TO_AGENCY: "Returned to Agency",
  PENDING_ADDITIONAL_INFO_CANDIDATE: "Pending - Additional Info Candidate",
  SUBMITTED_TO_HOME_OFFICE: "Submitted to Home Office",
  BACKGROUND_CHECK_INITIATED: "Background Check Initiated",
  BACKGROUND_CHECK_PENDING: "Background Check Pending",
  BACKGROUND_CHECK_RECEIVED: "Background Check Received",
  PENDING_ADDITIONAL_INFO_AGENCY: "Pending - Additional Info Agency",
  PENDING_ADDITIONAL_INFO_ANOTHER_DEPT:
    "Pending - Additional Info Another Dept.",
  PENDING_COMPLIANCE_RSD: "Pending - Compliance/RSD",
  ACKNOWLEDGED_APPROVED: "Acknowledged - Approved",
  ACKNOWLEDGED_DECLINED: "Acknowledged - Declined",
  MMLIS_Pending: "MMLIS Pending",
};

export const CONTRACT_TYPE_DESCRIPTIONS = {
  INDIVIDUAL_BROKER: "Individual Broker Contract",
  CAREER_AGENT: "Career Agent Contract",
  CAREER_SAMPLING: "Career Sampling Contract",
  COLLEGE_INTERN: "College Intern Contract",
  BROKER_CORP: "Broker Corporation Contract",
  MEMBER_CORP: "Member of Corporation Contract",
  ALTERNATE_AGENT: "Alternate Agent Contract",
  MMLIS: "MMLIS Only Contract",
  FINANCING_ONLY: "Financing Only",
};

export const isArchived = (status) => {
  return (
    status === CONTRACT_STATUS_DESC.APPROVED_BY_HOME_OFFICE ||
    status === CONTRACT_STATUS_DESC.DECLINED_BY_HOME_OFFICE ||
    status === CONTRACT_STATUS_DESC.ACKNOWLEDGED_APPROVED ||
    status === CONTRACT_STATUS_DESC.ACKNOWLEDGED_DECLINED ||
    status === CONTRACT_STATUS_DESC.ARCHIVE_INVITATION_CANCELLED ||
    status === CONTRACT_STATUS_DESC.ARCHIVE_APPLICATION_WITHDRAWN ||
    status === CONTRACT_STATUS_DESC.ARCHIVE_DECLINED_BY_AGENCY
  );
};

export const isPending = (status) => {
  return (
    status === CONTRACT_STATUS_DESC.PENDING_ADDITIONAL_INFO_AGENCY ||
    status === CONTRACT_STATUS_DESC.PENDING_ADDITIONAL_INFO_CANDIDATE ||
    status === CONTRACT_STATUS_DESC.PENDING_ADDITIONAL_INFO_ANOTHER_DEPT ||
    status === CONTRACT_STATUS_DESC.PENDING_COMPLIANCE ||
    status === CONTRACT_STATUS_DESC.PENDING_COMPLIANCE_RSD
  );
};

export const isBackgroundCheck = (status) => {
  return (
    status === CONTRACT_STATUS_DESC.BACKGROUND_CHECK_INITIATED ||
    status === CONTRACT_STATUS_DESC.BACKGROUND_CHECK_PENDING ||
    status === CONTRACT_STATUS_DESC.BACKGROUND_CHECK_RECEIVED
  );
};

export const isSubmitted = (status) => {
  return (
    status === CONTRACT_STATUS_DESC.APPLICATION_SUBMITTED ||
    status === CONTRACT_STATUS_DESC.AGENCY_REVIEW ||
    status === CONTRACT_STATUS_DESC.SUBMITTED_TO_HOME_OFFICE ||
    status === CONTRACT_STATUS_DESC.HOME_OFFICE_REVIEW ||
    status === CONTRACT_STATUS_DESC.RETURNED_TO_AGENCY
  );
};

export const isDeclined = (status) => {
  return (
    status === CONTRACT_STATUS_DESC.DECLINED_BY_HOME_OFFICE ||
    status === CONTRACT_STATUS_DESC.ACKNOWLEDGED_DECLINED ||
    status === CONTRACT_STATUS_DESC.ARCHIVE_APPLICATION_WITHDRAWN ||
    status === CONTRACT_STATUS_DESC.ARCHIVE_DECLINED_BY_AGENCY
  );
};
