export const mainPath = `${window.REACT_APP_CANDIDATE_API_URL}/v1/candidate`;
export const contractPath = `${window.REACT_APP_CANDIDATE_API_URL}/v1/contract`;
export const ubiqPath = `${window.REACT_APP_CANDIDATE_API_URL}/v1/ubiqtest`;

export const TOKEN_STORAGE = "okta-token-storage";

export const RequestMethodTypes = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
};

export const DRP_TYPES = {
  Bankruptcy: 1,
  Bond: 2,
  CivilJudicial: 3,
  Criminal: 4,
  Customer: 5,
  Investgation: 6,
  Judgement: 7,
  Regulatory: 8,
  Termination: 9,
};
