import React from "react";
import MMLISAddContributionModal2 from "./MMLISAddContributionModal2";
import BackButtonModal from "../Forms/BackButtonModal";
import "../StartApplication.scss";
import "../../../assets/stylesheets/StartApplication/MMLIS/MMLIS.scss";
import {
  updateCandidateMMLIS,
  getMMLISGeneralContributions,
  deleteMMLISGeneralContribution,
} from "../../../api/makeAPIRequest";
import { FORM_ID } from "../../../constants/Forms";
import NavigationButtons from "../../SharedComponents/NavigationButtons";
import { UtagTracking } from "../../../utils/utag-tracking";
import MMLISContributionTable from "./MMLISContributionTable";
import AddedMMLISContributionToast from "./AddedMMLISContributionToast";
import DeletedMMLISContributionToast from "./DeletedMMLISContributionToast";

class MMLISPayToPlayForm2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initialState();
    this.handlePACChange = this.handlePACChange.bind(this);
    this.handleAddContribution = this.handleAddContribution.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleShowBackModal = this.handleShowBackModal.bind(this);
    this.handleCloseBackModal = this.handleCloseBackModal.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.closeAddModal = this.closeAddModal.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
    this.reviewEditClick = this.reviewEditClick.bind(this);
    this.deleteData = this.deleteData.bind(this);
    this.showDeletedAlert = this.showDeletedAlert.bind(this);
    this.closeDeletedAlert = this.closeDeletedAlert.bind(this);
    this.getContributionData = this.getContributionData.bind(this);
  }

  async componentDidMount() {
    try {
      if (this.props.mmlisData[0].contributebool === true) {
        this.setState({ pac: "Y" });
      } else if (this.props.mmlisData[0].contributebool === false) {
        this.setState({ pac: "N" });
      }
      this.setState({
        candidate_id: this.props.candidateId,
      });

      this.getContributionData();

      UtagTracking.sendPageNameView(
        "mmlispaytoplaygeneralform",
        this.props.candidateId,
        this.props.agencynumber,
        this.props.contracttypeid
      );
    } catch (err) {
      console.log(err);
    }
  }

  initialState() {
    return {
      pac: "",
      next_disabled: true,
      show_add_contributions_link: false,
      show_modal: false,
      showBackModal: false,
      formHasChanged: false,
      candidate_id: "",
      is_form_saved: false,
      show_mmlis_added_alert: false,
      show_experience_deleted_alert: false,
      added_saved: "",
      mmlis_contrib_data: "",
      mmlis_contrib_data_delete: "",
      reviewEditData: "",
    };
  }

  async getContributionData() {
    let result = await getMMLISGeneralContributions(this.props.subId);

    result.sort(function (a, b) {
      return a.contribdate.localeCompare(b.contribdate);
    });

    this.setState({ mmlis_contrib_data: result });

    if (
      result.length > 0 &&
      result[0].contributionsgeneralid !== null &&
      this.state.pac === "Y"
    ) {
      this.setState({
        show_add_contributions_link: true,
      });

      if (result[0].contributionsgeneralid !== null) {
        this.setState({ next_disabled: false });
      } else {
        this.setState({ next_disabled: true });
      }
    } else if (this.state.pac === "Y") {
      this.setState({ show_add_contributions_link: true, next_disabled: true });
    } else {
      this.setState({ show_add_contributions_link: false });

      if (this.state.pac === "N") {
        this.setState({ next_disabled: false });
      }
    }
  }

  async showAlert() {
    var toast_banner = document.getElementById(
      "mmlis_contribution_added_alert"
    );
    toast_banner.classList.add("show");
    toast_banner.classList.remove("d-none");
    await this.setState({ show_mmlis_added_alert: true });
    let myRef = document.getElementById("startAppHeader");
    myRef.scrollIntoView();
  }

  async closeAlert() {
    var toast_banner = document.getElementById(
      "mmlis_contribution_added_alert"
    );
    toast_banner.classList.remove("show");
    toast_banner.classList.add("d-none");
    await this.setState({
      show_mmlis_added_alert: false,
      added_saved: "added",
    });
  }

  async closeAddModal(data) {
    await this.setState({ show_modal: false, reviewEditData: "" });
    this.getContributionData();
  }

  async handlePACChange(event) {
    await this.setState({ formHasChanged: true });
    this.props.hasCurrentFormChanged(true);
    this.props.isFormComplete("MMLISPage2CompleteInd", false);

    if (event.target.value === "Y") {
      this.setState({
        pac: "Y",
        next_disabled: true,
        show_add_contributions_link: true,
      });
    }

    if (event.target.value === "N") {
      await this.setState({
        mmlis_contrib_data_delete: this.state.mmlis_contrib_data,
      });
      await this.setState({ mmlis_contrib_data: "" });

      this.setState({
        pac: "N",
        next_disabled: false,
        show_add_contributions_link: false,
      });

      //delete any contributions that may have been created
      for (let i = 0; i < this.state.mmlis_contrib_data_delete.length; i++) {
        if (
          this.state.mmlis_contrib_data_delete[i].contributionsgeneralid !==
          null
        ) {
          await deleteMMLISGeneralContribution(
            this.props.subId,
            this.state.mmlis_contrib_data_delete[i].contributionsgeneralid
          );
        }
      }
      await this.getContributionData();
      await this.setState({ mmlis_contrib_data_delete: "" });
    }

    if (
      this.state.mmlis_contrib_data.length > 0 &&
      this.state.mmlis_contrib_data[0].contributionsgeneralid !== null
    ) {
      this.setState({ next_disabled: false });
    }
  }

  async handleAddContribution() {
    this.setState({ show_modal: true });
  }

  async handleNext() {
    if (this.state.formHasChanged === true) {
      let contributebool = "";
      if (this.state.pac === "Y") {
        contributebool = true;
      } else {
        contributebool = false;
      }

      let data = {
        candidateid: this.state.candidate_id,
        ptpgeneral: contributebool,
      };

      await updateCandidateMMLIS(this.props.subId, data);
      this.props.saveMMLISInfo();
    }

    await this.setState({ is_form_saved: true, formHasChanged: false });
    this.props.hasCurrentFormChanged(false);
    this.props.isFormComplete("MMLISPage2CompleteInd", true);
    this.props.toggleForm(FORM_ID.MMLIS_OBA_CRIA);
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  handleCloseBackModal() {
    this.setState({ showBackModal: false });
  }

  handleBackClick() {
    this.setState({ formHasChanged: false });
    this.handleCloseBackModal();
    this.props.toggleForm(FORM_ID.MMLIS_PAY_TO_PLAY_1);
  }

  handleShowBackModal() {
    //show back modal if anything on the form has changed
    if (this.state.formHasChanged) {
      this.setState({ showBackModal: true });
    } else {
      this.props.toggleForm(FORM_ID.MMLIS_PAY_TO_PLAY_1);
    }
  }

  async reviewEditClick(contrib) {
    await this.setState({
      reviewEditData: contrib,
      added_saved: "saved",
      show_modal: true,
    });
    //hide popup menu
    let myRef = document.getElementById("top_element");
    myRef.click();
  }

  async deleteData(item) {
    await deleteMMLISGeneralContribution(
      this.props.subId,
      item.contributionsgeneralid
    );

    await this.getContributionData();
    await this.showDeletedAlert();
    window.scrollTo(0, 0);
  }

  async showDeletedAlert() {
    var toast_banner = document.getElementById(
      "mmlis_contribution_delete_alert"
    );
    toast_banner.classList.add("show");
    toast_banner.classList.remove("d-none");
    await this.setState({ show_experience_deleted_alert: true });
  }

  async closeDeletedAlert() {
    var toast_banner = document.getElementById(
      "mmlis_contribution_delete_alert"
    );
    toast_banner.classList.remove("show");
    toast_banner.classList.add("d-none");
    await this.setState({ show_contribution_deleted_alert: false });
  }

  render() {
    return (
      <>
        <AddedMMLISContributionToast
          showToast={this.state.show_mmlis_added_alert}
          closeToast={this.closeAlert}
          addedSaved={this.state.added_saved}
        />
        <DeletedMMLISContributionToast
          showToast={this.state.show_contribution_deleted_alert}
          closeDeletedToast={this.closeDeletedAlert}
        />

        <div className="row">
          <div className="col-md-12">
            <h1>MML Investors Services</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <span className="required-field-text">* = Required Field</span>
          </div>
        </div>
        <div
          className="row"
          style={{ marginTop: "25px", marginBottom: "20px" }}
        >
          <div className="col-md-12">
            <h3>Pay to Play Compliance Disclosure Form (MMLIS)</h3>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <p
              id="pac_ppc"
              style={{
                paddingTop: "10px",
              }}
            >
              This information is being requested to ensure compliance with
              state and Federal Pay to Play laws, and for no other purpose.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <p
              id="pac_ppc"
              style={{
                paddingBottom: "5px",
                paddingTop: "10px",
              }}
            >
              Have you, your spouse/domestic partner, dependent child, or
              business entity made any contributions to state or local
              officials, candidates, PACs, PPCs, or any other political
              organization directly or indirectly, including through a third
              party, within two years of today's date? *
            </p>
            <div
              className="custom-control custom-radio"
              style={{ marginLeft: "20px" }}
            >
              <input
                type="radio"
                id="pac_yes"
                name="pac"
                className="custom-control-input"
                checked={this.state.pac === "Y"}
                value="Y"
                onChange={this.handlePACChange}
              />
              <label
                htmlFor="pac_yes"
                className="custom-control-label"
                id="label_pac_yes"
              >
                Yes
              </label>
            </div>
            <div
              className="custom-control custom-radio"
              style={{ marginLeft: "20px" }}
            >
              <input
                type="radio"
                id="pac_no"
                name="pac"
                className="custom-control-input"
                checked={this.state.pac === "N"}
                value="N"
                onChange={this.handlePACChange}
              />
              <label
                htmlFor="pac_no"
                className="custom-control-label"
                id="label_pac_no"
              >
                No
              </label>
            </div>
            <div id="pac_error" className="invalid-feedback">
              Please choose a response.
            </div>
          </div>
        </div>

        {this.state.show_add_contributions_link === true && (
          <>
            <div className="row">
              <div className="col-md-12">
                <hr></hr>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <h3 style={{ paddingBottom: "10px" }}>
                  Contribution Details to State/Local Political Parties or PACs
                  or PPCs
                </h3>
                <p style={{ paddingBottom: "15px" }}>
                  If you answered "Yes", you must add contribution details.
                </p>
                <span
                  onClick={this.handleAddContribution}
                  id="addContribution"
                  className="add-contribution-btn"
                >
                  <span className="icon-add"></span>
                  <strong>Add Contribution Details</strong>
                </span>
              </div>
            </div>

            <MMLISContributionTable
              data={this.state.mmlis_contrib_data}
              showAddModal={this.showAddModalFromTable}
              deleteData={this.deleteData}
              reviewEditClick={this.reviewEditClick}
            />
          </>
        )}

        <NavigationButtons
          onBack={this.handleShowBackModal}
          onNext={this.handleNext}
          nxtbtnid={"mmlis_pay_to_play_next"}
          bckbtnid={"mmlis_pay_to_play_back"}
          isDisabled={this.state.next_disabled}
        />

        <MMLISAddContributionModal2
          isOpen={this.state.show_modal}
          actionCancel={this.closeAddModal}
          subId={this.props.subId}
          candidateId={this.state.candidate_id}
          showAlert={this.showAlert}
          showDeletedAlert={this.showDeletedAlert}
          reviewEditData={this.state.reviewEditData}
        />

        <BackButtonModal
          isOpen={this.state.showBackModal}
          closeBackModal={this.handleCloseBackModal}
          backClick={this.handleBackClick}
        />
      </>
    );
  }
}

export default MMLISPayToPlayForm2;
