import React from "react";
import { Button, Modal, SimpleTable } from "react-blueprint-library";

import svgs, { svgType } from "../../../../../../assets/svgs";
import { headerText as civ } from "../../CivilJudicialForm/constants";
import { headerText as crim } from "../../CriminalDisclosureForm/constants";
import { headerText as cust } from "../../CustomerComplaintForm/constants";
import { headerText as fin } from "../../FinancialDisclosureForm/constants";
import { headerText as reg } from "../../RegulatoryActionForm/constants";
import { headerText as term } from "../../TerminationDisclosureForm/constants";
import "./DeleteModal.scss";

const DEFAULT_DELETE_MESSAGE =
  "Are you sure you'd like to delete this information?";

const ModalTable = ({ header, data }) => {
  return (
    <div className="mt-6 modal-table">
      <SimpleTable>
        <thead>
          <tr>
            {header?.map((column, index) => (
              <th key={`header-${index}`}>{column?.name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.length &&
            data.map((row, index) => (
              <tr key={`row-${index}`}>
                {header?.map((column) => {
                  let value = row[column.value] ?? "N/A";

                  if (Array.isArray(value)) {
                    if (value.length === 1) {
                      value = value[0] ?? "N/A";
                    } else {
                      value = value.map((valueRow, index) => (
                        <React.Fragment key={`row-${valueRow}-${index + 1}`}>
                          {index > 0 && <br />}
                          {valueRow}
                        </React.Fragment>
                      ));
                    }
                  }
                  return <td>{value}</td>;
                })}
              </tr>
            ))}
        </tbody>
      </SimpleTable>
    </div>
  );
};

const DeleteModal = ({
  isOpen,
  showSpinner,
  isDRP,
  type = "",
  list = [],
  description = "",
  message = DEFAULT_DELETE_MESSAGE,
  closeClick,
  deleteClick,
}) => {
  const isNameOption = !!list.find((x) => x?.orgName)?.orgName?.length;
  const isInv = !!list.find((x) => x?.isInv)?.isInv;
  const isStatusOption = !!list.find((x) => x?.status)?.status?.length;

  const buildHeader = (type) => {
    let header = [];
    switch (type) {
      case crim.HEADER:
        header = [
          {
            name: "Date First Charged",
            show: true,
            value: "date",
          },
          {
            name: "Org Name",
            show: isNameOption,
            value: "orgName",
          },
          {
            name: "Description",
            show: true,
            value: "types",
          },
        ];
        break;
      case reg.HEADER:
        header = [
          {
            name: isInv ? "Notice Date" : "Date Initiated",
            show: true,
            value: "date",
          },
          {
            name: isInv ? "Notice received from:" : "Action Initiated by",
            show: true,
            value: "types",
          },
          {
            name: "Status",
            show: isStatusOption,
            value: "status",
          },
        ];
        break;
      case civ.HEADER:
        header = [
          {
            name: "Filing Date",
            show: true,
            value: "date",
          },
          {
            name: "Action Initiated by",
            show: true,
            value: "types",
          },
          {
            name: "Status",
            show: isStatusOption,
            value: "status",
          },
        ];
        break;
      case cust.MAIN_HEADER:
        header = [
          {
            name: "Date received",
            show: true,
            value: "date",
          },
          {
            name: "Reporting Type",
            show: true,
            value: "types",
          },
          {
            name: "Status",
            show: true,
            value: "status",
          },
        ];
        break;
      case term.HEADER:
        header = [
          {
            name: "Termination Date",
            show: true,
            value: "date",
          },
          {
            name: "Termination Type",
            show: true,
            value: "types",
          },
          {
            name: "Status",
            show: isStatusOption,
            value: "status",
          },
        ];
        break;
      case fin.PERS_BANKRUPTCY:
        header = [
          {
            name: "Action Date",
            show: true,
            value: "date",
          },
          {
            name: "Action Type",
            show: true,
            value: "types",
          },
          {
            name: "Disposition",
            show: true,
            value: "status",
          },
        ];
        break;
      case fin.ORG_BANKRUPTCY:
        header = [
          {
            name: "Action Date",
            show: true,
            value: "date",
          },
          {
            name: "Org Name",
            show: true,
            value: "orgName",
          },
          {
            name: "Action Type",
            show: true,
            value: "types",
          },
          {
            name: "Disposition",
            show: true,
            value: "status",
          },
        ];
        break;
      case fin.BOND_DISCL:
        header = [
          {
            name: "Firm Name",
            show: true,
            value: "date",
          },
          {
            name: "Disposition Type",
            show: true,
            value: "types",
          },
        ];
        break;
      case fin.JUDG_LIEN_DISCL:
        header = [
          {
            name: "Date filed with Court",
            show: true,
            value: "date",
          },
          {
            name: "Judgment/Lien",
            show: true,
            value: "types",
          },
          {
            name: "Judgment/Lien Holder",
            show: true,
            value: "orgName",
          },
        ];
        break;
      default:
        header = [
          {
            name: "Date",
            show: true,
          },
          {
            name: "Name",
            show: true,
          },
          {
            name: "Type",
            show: true,
          },
          {
            name: "Status",
            show: true,
          },
        ];
    }

    return header.filter((collumn) => collumn.show);
  };

  const renderContent = () => (
    <div className="modal-content-container">
      {showSpinner === false && !isDRP && (
        <div>
          <div className="modal-body" id="top_delete_element">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h2>
                    <div className="message">{message}</div>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close modal dialog"
                      onClick={closeClick}
                    />
                  </h2>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 left-btn-container">
                  <button
                    type="button"
                    className="btn btn-link no-btn"
                    onClick={closeClick}
                  >
                    No, Never Mind
                  </button>
                  <Button id="add_button" label="Yes" onClick={deleteClick} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showSpinner === false && isDRP && (
        <div>
          <div className="modal-body" id="top_delete_element">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h2>
                    <div className="header-container">
                      <div className="message">{message}</div>
                      <div>
                        <button
                          type="button"
                          style={{
                            border: "none",
                            background: "none",
                            cursor: "pointer",
                            display: "flex",
                          }}
                          data-dismiss="modal"
                          aria-label="Close modal dialog"
                          onClick={closeClick}
                        >
                          {svgs[svgType.close]}
                        </button>
                      </div>
                    </div>
                  </h2>
                </div>
              </div>

              {description && <div className="description">{description}</div>}

              {list?.length ? (
                <ModalTable header={buildHeader(type)} data={list} />
              ) : (
                <></>
              )}

              <div className="row">
                <div
                  className="col-md-12 left-btn-container"
                  style={{ marginTop: "24px" }}
                >
                  <button
                    type="button"
                    className="btn btn-link no-btn close-drp-btn"
                    onClick={closeClick}
                  >
                    No, Go back
                  </button>

                  <Button
                    id="add_button drp"
                    className="drp-add-btn"
                    label="yes, delete and Proceed"
                    onClick={deleteClick}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showSpinner && (
        <>
          <div className="row" style={{ marginTop: "15px", height: "150px" }}>
            <div
              id="emp_hist_delete_loading"
              className="mm-dots-loading-xl"
              style={{ marginLeft: "225px", marginTop: "50px" }}
            />
          </div>
        </>
      )}
    </div>
  );

  return (
    <div className="delete-modal-container">
      <div id="backModal"></div>

      <div>
        <Modal
          id="common-drp-delete-modal"
          show={isOpen}
          className="modal-md"
          title=""
        >
          {renderContent()}
        </Modal>
      </div>
    </div>
  );
};

export default DeleteModal;
