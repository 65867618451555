import React, { useEffect, useRef, useState } from "react";
import { FORM_ID } from "../../../../../constants/Forms";
import CivilJudicialForm from "./CivilJudicialForm";
import { CCB_FORM_ID, civJudDesc, headerText, initialState } from "./constants";
import DisclosureReportingPageLayout from "../common/DRPLayout";
import CivilJudicialDRPSummary from "./CivilJudicialDRPSummary";
import { useSelector } from "react-redux";
import { isDrpSummaryPage } from "./utils";
import { UtagTracking } from "../../../../../utils/utag-tracking";
import { DRP_TYPES } from "../../../../../api/helpers/constants";

import {
  getCCBDRPCivJudList,
  insertUpdateCCB14DRP,
  updateSingleCCB14DrpCivJud,
} from "../../../../../api/CCB/DRP/civilJudDRP";
import { convertGroupKey } from "../helpers";
import AddDRPFormLayout from "../common/AddDRPFormLayout";
import CivJudActionDetailsForm from "./AddDRPForms/CivJudActionDetailsForm";
import CivJudDetails from "./AddDRPForms/CivJudDetails";
import CivJudStatusDetails, {
  eventStatus,
} from "./AddDRPForms/CivJudStatusDetails";
import CivJudResDetailsForm from "./AddDRPForms/CivJudResDetailsForm";
import CivJudSanctionDetailsForm from "./AddDRPForms/CivJudSanctionDetailsForm";
import CivJudCommentsForm from "./AddDRPForms/CivJudCommentsForm";

const CivilJudicial = ({
  toggleForm,
  subId: oktaSubId,
  updateCCB14CompletedInd,
  updateCCB14Step,
  candidateId,
  agencynumber,
  contracttypeid,
}) => {
  const { data: ccbData, isBack } = useSelector((state) => state.ccb14);
  const [data, setData] = useState(initialState);
  const [values, setValues] = useState(null);
  const [currentFormId, setCurrentFormId] = useState(
    CCB_FORM_ID.CIVIL_DISCLOSURE
  );
  const [isDraft, setIsDraft] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetch, setIsDataRefetch] = useState();

  const [drpList, setDrpList] = useState({
    civ: [],
  });

  const [skipResAndSanctDetails, setSkipResAndSanctDetails] = useState(false);

  const [isDRPNextDisabled, setIsDRPNextDisabled] = useState(false);
  const [isNewData, setIsNewData] = useState(false);
  const [dataIndex, setDataIndex] = useState(null);

  // eslint-disable-next-line
  const [sectionState, setSectionState] = useState(headerText.CIV_JUD_ACT);

  const [isDRPCompleted, setIsDRPCompleted] = useState(false);

  const { civ, uncheckedCiv } = isDrpSummaryPage(ccbData);

  useEffect(() => {
    if (civ?.length && isBack) {
      setCurrentFormId(CCB_FORM_ID.DRP_SUMMARY);
    }
    UtagTracking.sendPageNameView(
      "civiljudicialform",
      candidateId,
      agencynumber,
      contracttypeid
    );
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const isMounted = useRef(false);

  const fetchCCB14DRP = async () => {
    try {
      setIsLoading(true);
      const list = await getCCBDRPCivJudList({
        oktaSubId,
        drpTypeId: DRP_TYPES.CivilJudicial,
      });

      setDrpList({
        civ: list.civ,
      });

      isMounted.current = false;
      setIsLoading(false);
      setIsDataRefetch(false);
    } catch (err) {
      setIsDataRefetch(false);

      isMounted.current = false;
      if (err) {
        console.error(err);
      }
    }
  };

  useEffect(
    () => {
      isMounted.current = true;

      setIsLoading(true);

      if (isMounted.current) {
        fetchCCB14DRP();
      }

      return () => {
        isMounted.current = false;
        window.onbeforeunload = null;
        setDrpList({ civ: [] });
        setIsDataRefetch(false);
      };
    } /* eslint-disable-next-line */,
  	[]
  );

  useEffect(
    () => {
      if (isRefetch) {
        fetchCCB14DRP();
      }
    } /* eslint-disable-next-line */,
  	[isRefetch]
  );

  const updateCCBForm = (formId, options = null) => {
    if (options?.section) {
      setSectionState(options?.section);
    }

    if (options?.values) {
      setValues(options?.values);
    }

    if (options?.isReset) {
      setIsDataRefetch(true);
      setIsNewData(true);
      setDataIndex(null);
      setData(initialState);
    }

    if (options?.isEdit) {
      if (options.data && options.section) {
        const { dataIndex: dI, isDraft, ...rest } = options.data;
        setDataIndex(dI);
        setIsDraft(isDraft === false ? false : null);
        setData({ [convertGroupKey(options.section)]: rest });
        setIsNewData(false);
      }
    }

    if (options?.isDelete) {
      if (typeof options.index === "number" && options.index >= 0) {
        setDataIndex(options.index);
        setIsNewData(false);
      }
    }

    if (!isDRPCompleted && options?.isBackClick) {
      updateCCB14Step({ civilJudDisclosureCompleteStep: "1/2" });
      updateCCB14CompletedInd({ civilJudDisclosureCompleteInd: false });
    }
    setCurrentFormId(formId);
  };

  const sectionHandler = (section) => {
    let s = null;

    switch (section) {
      case headerText.CIV_JUD_ACT:
        s = "civ";
        break;
      default:
        break;
    }

    return s;
  };

  const getSubmitDataValues = (newData, name, completed = false) => {
    let groupKey = "";

    switch (name) {
      case headerText.CIV_JUD_ACT:
        groupKey = convertGroupKey(headerText.CIV_JUD_ACT);
        break;

      default:
        break;
    }

    const result = { ...data[groupKey], ...newData };

    const submitData = async () => {
      setIsLoading(true);

      try {
        await insertUpdateCCB14DRP({
          oktaSubId,
          drpTypeId: DRP_TYPES.CivilJudicial,
          data: {
            groupKey,
            data: result,
            isNewData,
            isDraft: !completed,
          },
        });
        if (completed) {
          setIsLoading(true);
          setIsDataRefetch(true);
        } else {
          setIsLoading(false);
        }
      } catch (err) {
        setIsLoading(false);
        if (err) {
          console.error(err);
        }
      }
    };

    const updateData = async () => {
      try {
        await updateSingleCCB14DrpCivJud({
          oktaSubId,
          drpTypeId: DRP_TYPES.CivilJudicial,
          data: {
            groupKey,
            data: result,
            isNewData,
            isDraft: isDraft === false ? isDraft : !completed,
          },
          section: sectionHandler(name),
          index: dataIndex,
        });
        if (completed) {
          setIsLoading(true);
          setIsDataRefetch(true);
        } else {
          setIsLoading(false);
        }
      } catch (err) {
        setIsLoading(false);
        if (err) {
          console.error(err);
        }
      }
    };

    if (Object.keys(newData) && dataIndex === null && completed) {
      setIsLoading(true);
      submitData();
      setIsNewData(false);
    } else {
      if (typeof dataIndex === "number" && completed) {
        setIsLoading(true);
        updateData();
      }
    }

    setData((prevData) => ({
      ...prevData,
      [groupKey]: { ...prevData[groupKey], ...newData },
    }));
  };

  const renderForm = (id) => {
    switch (id) {
      case CCB_FORM_ID.CIVIL_DISCLOSURE:
        return (
          <CivilJudicialForm
            header={headerText.HEADER}
            toggleForm={toggleForm}
            oktaSubId={oktaSubId}
            updateCCBForm={updateCCBForm}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            uncheckedCiv={uncheckedCiv}
            backFormId={FORM_ID.REGULATORY_ACTION_DISCLOSURE}
            nextFormId={CCB_FORM_ID.DRP_SUMMARY}
            updateCCB14CompletedInd={updateCCB14CompletedInd}
            updateCCB14Step={updateCCB14Step}
            setIsDataRefetch={setIsDataRefetch}
          />
        );
      case CCB_FORM_ID.DRP_SUMMARY:
        return (
          <DisclosureReportingPageLayout
            header={headerText.HEADER}
            backFormId={CCB_FORM_ID.CIVIL_DISCLOSURE}
            nextFormId={FORM_ID.CUSTOMER_COMPLAINT_DISCLOSURE}
            updateCCBForm={updateCCBForm}
            ind="civilJudDisclosureCompleteInd"
            isNextDisabled={isDRPNextDisabled || isLoading}
            updateCCB14CompletedInd={updateCCB14CompletedInd}
            toggleForm={toggleForm}
            isLoading={isLoading}
          >
            <CivilJudicialDRPSummary
              oktaSubId={oktaSubId}
              setIsDRPNextDisabled={setIsDRPNextDisabled}
              updateCCB14CompletedInd={updateCCB14CompletedInd}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              data={drpList}
              updateCCB14Step={updateCCB14Step}
              setIsDRPCompleted={setIsDRPCompleted}
              updateCCBForm={updateCCBForm}
              civ={civ}
              setIsDataRefetch={setIsDataRefetch}
            />
          </DisclosureReportingPageLayout>
        );
      case CCB_FORM_ID.CIV_JUD_ACT_DETAILS:
        return (
          <AddDRPFormLayout
            updateCCBForm={updateCCBForm}
            header={headerText.HEADER}
            activeStep={1}
            steps={6}
            details="Action Details"
            description={civJudDesc.DEF_DESCRIPTION}
          >
            <CivJudActionDetailsForm
              data={data[convertGroupKey(headerText.CIV_JUD_ACT)]?.actDetails}
              values={values}
              groupKey="actDetails"
              updateCCBForm={updateCCBForm}
              backFormId={CCB_FORM_ID.DRP_SUMMARY}
              nextFormId={CCB_FORM_ID.CIV_JUD_DETAILS}
              headerText={headerText.CIV_JUD_ACT}
              getSubmitDataValues={getSubmitDataValues}
            />
          </AddDRPFormLayout>
        );
      case CCB_FORM_ID.CIV_JUD_DETAILS:
        return (
          <AddDRPFormLayout
            updateCCBForm={updateCCBForm}
            header={headerText.HEADER}
            section={headerText.REG_FORM_INV}
            activeStep={2}
            steps={6}
            details="Details"
          >
            <CivJudDetails
              data={data[convertGroupKey(headerText.CIV_JUD_ACT)]?.details}
              groupKey="details"
              updateCCBForm={updateCCBForm}
              backFormId={CCB_FORM_ID.CIV_JUD_ACT_DETAILS}
              nextFormId={CCB_FORM_ID.CIV_JUD_STATUS_DETAILS}
              headerText={headerText.CIV_JUD_ACT}
              getSubmitDataValues={getSubmitDataValues}
            />
          </AddDRPFormLayout>
        );
      case CCB_FORM_ID.CIV_JUD_STATUS_DETAILS:
        return (
          <AddDRPFormLayout
            updateCCBForm={updateCCBForm}
            header={headerText.HEADER}
            activeStep={3}
            steps={6}
            details="Status Details"
          >
            <CivJudStatusDetails
              data={
                data[convertGroupKey(headerText.CIV_JUD_ACT)]?.statusDetails
              }
              setSkipResAndSanctDetails={setSkipResAndSanctDetails}
              groupKey="statusDetails"
              updateCCBForm={updateCCBForm}
              backFormId={CCB_FORM_ID.CIV_JUD_DETAILS}
              nextFormId={CCB_FORM_ID.CIV_JUD_RES_DETAILS}
              skipFormId={CCB_FORM_ID.CIV_JUD_COMMENTS}
              headerText={headerText.CIV_JUD_ACT}
              getSubmitDataValues={getSubmitDataValues}
            />
          </AddDRPFormLayout>
        );
      case CCB_FORM_ID.CIV_JUD_RES_DETAILS:
        return (
          <AddDRPFormLayout
            updateCCBForm={updateCCBForm}
            header={headerText.HEADER}
            activeStep={4}
            steps={6}
            details="Resolution Details"
          >
            <CivJudResDetailsForm
              data={data[convertGroupKey(headerText.CIV_JUD_ACT)]?.resDetails}
              groupKey="resDetails"
              updateCCBForm={updateCCBForm}
              backFormId={CCB_FORM_ID.CIV_JUD_STATUS_DETAILS}
              nextFormId={CCB_FORM_ID.CIV_JUD_SANCT_DETAILS}
              headerText={headerText.CIV_JUD_ACT}
              getSubmitDataValues={getSubmitDataValues}
            />
          </AddDRPFormLayout>
        );
      case CCB_FORM_ID.CIV_JUD_SANCT_DETAILS:
        return (
          <AddDRPFormLayout
            updateCCBForm={updateCCBForm}
            header={headerText.HEADER}
            activeStep={5}
            steps={6}
            details="Sanction Details"
          >
            <CivJudSanctionDetailsForm
              updateCCBForm={updateCCBForm}
              backFormId={CCB_FORM_ID.CIV_JUD_RES_DETAILS}
              nextFormId={CCB_FORM_ID.CIV_JUD_COMMENTS}
              groupKey="sanctionDetails"
              getSubmitDataValues={getSubmitDataValues}
              data={
                data[convertGroupKey(headerText.CIV_JUD_ACT)]?.sanctionDetails
              }
              headerText={headerText.CIV_JUD_ACT}
            />
          </AddDRPFormLayout>
        );
      case CCB_FORM_ID.CIV_JUD_COMMENTS:
        return (
          <AddDRPFormLayout
            updateCCBForm={updateCCBForm}
            header={headerText.HEADER}
            activeStep={6}
            steps={6}
            details="Comments"
          >
            <CivJudCommentsForm
              updateCCBForm={updateCCBForm}
              backFormId={
                skipResAndSanctDetails === true ||
                data[convertGroupKey(headerText.CIV_JUD_ACT)]?.statusDetails
                  ?.status === eventStatus.PENDING
                  ? CCB_FORM_ID.CIV_JUD_STATUS_DETAILS
                  : CCB_FORM_ID.CIV_JUD_SANCT_DETAILS
              }
              nextFormId={CCB_FORM_ID.DRP_SUMMARY}
              getSubmitDataValues={getSubmitDataValues}
              groupKey="comments"
              data={data[convertGroupKey(headerText.CIV_JUD_ACT)]?.comments}
              headerText={headerText.CIV_JUD_ACT}
            />
          </AddDRPFormLayout>
        );

      default:
        break;
    }
  };

  return (
    <div className="civil-judicial-wrapper">{renderForm(currentFormId)}</div>
  );
};

export default CivilJudicial;
