import React, { Component } from "react";
import { ProgressBar } from "react-bootstrap";
import { Button, Collapse } from "react-blueprint-library";
import ExitModal from "./Forms/BackButtonModal";
import { FORM_ID } from "../../constants/Forms";
import "./StartApplicationNav.scss";
//import { CONTRACT_TYPE_DESCRIPTIONS } from "../../constants/Contract";

class StartApplicationNavBROKERCORP extends Component {
  constructor(props) {
    super(props);
    this.handleShowModal = this.handleShowModal.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleExitClick = this.handleExitClick.bind(this);
    this.setProgress = this.setProgress.bind(this);
    this.state = {
      keyA: "corp1",
      keyB: "member1",
      showModal: false,
      disableReviewSignBtn: true,
      progress: 0,
    };
  }

  handleItemClick(key) {
    if (key === this.state.keyA) {
      if (this.state.keyA === "corp1") {
        this.setState({ keyA: "corp0" });
      } else {
        this.setState({ keyA: "corp1" });
      }
    }

    if (key === this.state.keyB) {
      if (this.state.keyB === "member1") {
        this.setState({ keyB: "member0" });
      } else {
        this.setState({ keyB: "member1" });
      }
    }
  }

  handleExitClick() {
    window.location = "/";
  }

  handleModalClose() {
    this.setState({ showModal: false });
  }

  handleShowModal() {
    if (this.props.currentFormChanged === true || this.props.showLogoutModal) {
      this.setState({ showModal: true });
    } else {
      this.handleExitClick();
    }
  }

  moreInfoClick = (e) => {
    e.stopPropagation();
  };

  componentDidMount() {
    if (
      this.props.currentForm === FORM_ID.CORPORATE_INFO ||
      this.props.currentForm === FORM_ID.CORPORATE_BUSINESS_ADDRESS ||
      this.props.currentForm === FORM_ID.CORPORATE_CCB ||
      this.props.currentForm === FORM_ID.CORPORATE_ERRORS_OMISSIONS ||
      this.props.currentForm === FORM_ID.CORPORATE_DIRECT_DEPOSIT
    ) {
      if (this.state.keyA === "corp1") {
        document.getElementById("collapse_emp").click();
      }
      if (this.state.keyB === "member0") {
        document.getElementById("collapse_ccb").click();
      }
    }
    if (
      this.props.currentForm === FORM_ID.RELEASE_OF_INFO ||
      this.props.currentForm === FORM_ID.AUTHORIZATION ||
      this.props.currentForm === FORM_ID.PERSONAL_INFO ||
      this.props.currentForm === FORM_ID.RESIDENTIAL_ADDRESS ||
      this.props.currentForm === FORM_ID.BUSINESS_INFO ||
      this.props.currentForm === FORM_ID.BROKER_DEALER ||
      this.props.currentForm === FORM_ID.CCB ||
      this.props.currentForm === FORM_ID.ERRORS_OMISSIONS
    ) {
      if (this.state.keyA === "corp0") {
        document.getElementById("collapse_emp").click();
      }
      if (this.state.keyB === "member1") {
        document.getElementById("collapse_ccb").click();
      }
    }

    this.setProgress();
  }

  //Opens dropdown when navigated to
  componentDidUpdate(prevProps) {
    if (prevProps.currentForm !== this.props.currentForm) {
      if (
        this.props.currentForm === FORM_ID.CORPORATE_INFO ||
        this.props.currentForm === FORM_ID.CORPORATE_BUSINESS_ADDRESS ||
        this.props.currentForm === FORM_ID.CORPORATE_CCB ||
        this.props.currentForm === FORM_ID.CORPORATE_ERRORS_OMISSIONS ||
        this.props.currentForm === FORM_ID.CORPORATE_DIRECT_DEPOSIT
      ) {
        if (this.state.keyA === "corp1") {
          document.getElementById("collapse_emp").click();
        }
        if (this.state.keyB === "member0") {
          document.getElementById("collapse_ccb").click();
        }
      }
      if (
        this.props.currentForm === FORM_ID.RELEASE_OF_INFO ||
        this.props.currentForm === FORM_ID.AUTHORIZATION ||
        this.props.currentForm === FORM_ID.PERSONAL_INFO ||
        this.props.currentForm === FORM_ID.RESIDENTIAL_ADDRESS ||
        this.props.currentForm === FORM_ID.BUSINESS_INFO ||
        this.props.currentForm === FORM_ID.BROKER_DEALER ||
        this.props.currentForm === FORM_ID.CCB ||
        this.props.currentForm === FORM_ID.ERRORS_OMISSIONS
      ) {
        if (this.state.keyA === "corp0") {
          document.getElementById("collapse_emp").click();
        }
        if (this.state.keyB === "member1") {
          document.getElementById("collapse_ccb").click();
        }
      }
    }
    if (prevProps !== this.props) {
      this.setProgress();
    }
  }

  setProgress() {
    //const { contracttypedesc, includePSR } = this.props;

    let trueCount = 0;
    let numberOfIndicators = 0;
    let passedInProps = this.props;

    for (let item in passedInProps) {
      if (item.includes("Ind")) {
        numberOfIndicators += 1;
        if (passedInProps[item]) {
          trueCount += 1;
        }
      }
    }

    const progress = Math.round(
      100 - ((numberOfIndicators - trueCount) / numberOfIndicators) * 100
    );

    this.setState({
      progress: progress,
      disableReviewSignBtn: !(progress === 100),
    });
  }
  render() {
    return (
      <div id="startAppNavCAS">
        <div className="progress-bar-section">
          <div className="row">
            <div className="col-12">
              <h3 id="startAppNavProgressHeader">Application Progress</h3>
            </div>
          </div>
          <div className="row">
            <div
              id="startAppNavProgressBar"
              aria-label="Progress Bar Div BrokerCorp"
              className="col-7"
            >
              <ProgressBar
                id="progressBar"
                aria-label="Progress Bar BrokerCorp"
                now={this.state.progress}
              />
            </div>

            <div id="startAppNavProgressBarLabel" className="col-5">
              <strong>
                <span id="progressComplete">
                  {this.state.progress}% Complete
                </span>
              </strong>
            </div>
          </div>
        </div>
        <div className="nav-items">
          <Collapse id="empAccordion">
            <Collapse.Group>
              <Collapse.Toggle
                id="collapse_emp"
                eventKey="corp1"
                onClick={() => this.handleItemClick(this.state.keyA)}
              >
                <h3 id="sidebar_emp">Corporation</h3>
              </Collapse.Toggle>
              <Collapse.Collapse eventKey="corp1">
                <div className="row">
                  <div className="col-12">
                    <div id="nav1" className="nav-button">
                      {this.props.currentForm === FORM_ID.CORPORATE_INFO ? (
                        <>
                          <span id="current1" className="current-indicator">
                            CURRENT
                          </span>
                          <br />
                          <div className="nav-name">
                            <strong>Corporation Information</strong>
                          </div>
                        </>
                      ) : (
                        <div className="nav-name">Corporation Information</div>
                      )}
                      {this.props.corpInfoCompleteInd ? (
                        <div id="check1">
                          <span className="icon-answer-correct icon-sm"></span>
                        </div>
                      ) : (
                        <div id="summary1">
                          <span
                            className="nav-summary-text"
                            id="summary_text_1"
                          >
                            0/1
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div id="nav2" className="nav-button">
                      {this.props.currentForm ===
                      FORM_ID.CORPORATE_BUSINESS_ADDRESS ? (
                        <>
                          <span id="current2" className="current-indicator">
                            CURRENT
                          </span>
                          <br />
                          <div className="nav-name">
                            <strong>Business Information</strong>
                          </div>
                        </>
                      ) : (
                        <div className="nav-name">Business Information</div>
                      )}
                      {this.props.corpbusAddrCompleteInd ? (
                        <div id="check2">
                          <span className="icon-answer-correct icon-sm"></span>
                        </div>
                      ) : (
                        <div id="summary2">
                          <span
                            className="nav-summary-text"
                            id="summary_text_2"
                          >
                            0/1
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div id="nav3" className="nav-button">
                      {this.props.currentForm === FORM_ID.CORPORATE_CCB ? (
                        <>
                          <span id="current3" className="current-indicator">
                            CURRENT
                          </span>
                          <br />
                          <div className="nav-name">
                            <strong>
                              Corporation Character, Credit, and Bonding
                            </strong>
                          </div>
                        </>
                      ) : (
                        <div className="nav-name">
                          Corporation Character, Credit, and Bonding
                        </div>
                      )}
                      {this.props.corpCCBCompleteInd ? (
                        <div id="check3">
                          <span className="icon-answer-correct icon-sm"></span>
                        </div>
                      ) : (
                        <div id="summary3">
                          <span
                            className="nav-summary-text"
                            id="summary_text_3"
                          >
                            0/1
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div id="nav4" className="nav-button">
                      {this.props.currentForm ===
                      FORM_ID.CORPORATE_ERRORS_OMISSIONS ? (
                        <>
                          <span id="current4" className="current-indicator">
                            CURRENT
                          </span>
                          <br />
                          <div className="nav-name">
                            <strong>
                              Corporation Errors & Omissions (E&O) Coverage,
                              Group Insurance
                            </strong>
                          </div>
                        </>
                      ) : (
                        <div className="nav-name">
                          Corporation Errors & Omissions (E&O) Coverage, Group
                          Insurance
                        </div>
                      )}
                      {this.props.corpEOCompleteInd ? (
                        <div id="check4">
                          <span className="icon-answer-correct icon-sm"></span>
                        </div>
                      ) : (
                        <div id="summary4">
                          <span
                            className="nav-summary-text"
                            id="summary_text_4"
                          >
                            0/1
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div id="nav5" className="nav-button">
                      {this.props.currentForm ===
                      FORM_ID.CORPORATE_DIRECT_DEPOSIT ? (
                        <>
                          <span id="current5" className="current-indicator">
                            CURRENT
                          </span>
                          <br />
                          <div className="nav-name">
                            <strong>Direct Deposit Information</strong>
                          </div>
                        </>
                      ) : (
                        <div className="nav-name">
                          Direct Deposit Information
                        </div>
                      )}
                      {this.props.corpDirectDepositCompleteInd ? (
                        <div id="check5">
                          <span className="icon-answer-correct icon-sm"></span>
                        </div>
                      ) : (
                        <div id="summary5">
                          <span
                            className="nav-summary-text"
                            id="summary_text_5"
                          >
                            0/1
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <hr />
                </div>
              </Collapse.Collapse>
            </Collapse.Group>
          </Collapse>

          <Collapse id="ccbAccordion">
            <Collapse.Group>
              <Collapse.Toggle
                id="collapse_ccb"
                eventKey="member1"
                onClick={() => this.handleItemClick(this.state.keyB)}
              >
                <h3 id="sidebar_ccb">Member of Corporation</h3>
              </Collapse.Toggle>
              <Collapse.Collapse eventKey="member1">
                <div className="row">
                  <div className="col-12">
                    <div id="nav6" className="nav-button">
                      {this.props.currentForm === FORM_ID.RELEASE_OF_INFO ? (
                        <>
                          <span id="current6" className="current-indicator">
                            CURRENT
                          </span>
                          <br />
                          <div className="nav-name">
                            <strong>Release of Information</strong>
                          </div>
                        </>
                      ) : (
                        <div className="nav-name">Release of Information</div>
                      )}
                      {this.props.rInfoCompleteInd === true && (
                        <div id="check6">
                          <span className="icon-answer-correct icon-sm"></span>
                        </div>
                      )}
                      {this.props.rInfoCompleteInd === false && (
                        <div id="summary6">
                          <span
                            className="nav-summary-text"
                            id="summary_text_6"
                          >
                            0/1
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div id="nav7" className="nav-button">
                      {this.props.currentForm === FORM_ID.AUTHORIZATION ? (
                        <>
                          <span id="current2" className="current-indicator ">
                            CURRENT
                          </span>
                          <br />
                          <div className="nav-name">
                            <strong>Authorization to Work in the US</strong>
                          </div>
                        </>
                      ) : (
                        <div className="nav-name">
                          Authorization to Work in the US
                        </div>
                      )}
                      {this.props.authorizationCompleteInd === true && (
                        <div id="check7">
                          <span className="icon-answer-correct icon-sm"></span>
                        </div>
                      )}
                      {this.props.authorizationCompleteInd === false && (
                        <div id="summary7">
                          <span
                            className="nav-summary-text"
                            id="summary_text_7"
                          >
                            0/1
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div id="nav8" className="nav-button">
                      {this.props.currentForm === FORM_ID.PERSONAL_INFO ? (
                        <>
                          <span id="current8" className="current-indicator ">
                            CURRENT
                          </span>
                          <br />
                          <div className="nav-name">
                            <strong>Personal Information</strong>
                          </div>
                        </>
                      ) : (
                        <div className="nav-name">Personal Information</div>
                      )}
                      {this.props.pInfoCompleteInd === true && (
                        <div id="check8">
                          <span className="icon-answer-correct icon-sm"></span>
                        </div>
                      )}
                      {this.props.pInfoCompleteInd === false && (
                        <div id="summary8">
                          <span
                            className="nav-summary-text"
                            id="summary_text_8"
                          >
                            0/1
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div id="nav9" className="nav-button">
                      {this.props.currentForm ===
                      FORM_ID.RESIDENTIAL_ADDRESS ? (
                        <>
                          <span id="current9" className="current-indicator">
                            CURRENT
                          </span>
                          <br />
                          <div className="nav-name">
                            <strong>Residential Address</strong>
                          </div>
                        </>
                      ) : (
                        <div className="nav-name">Residential Address</div>
                      )}
                      {this.props.resAddrCompleteInd === true && (
                        <div id="check9">
                          <span className="icon-answer-correct icon-sm"></span>
                        </div>
                      )}
                      {this.props.resAddrCompleteInd === false && (
                        <div id="summary9">
                          <span
                            className="nav-summary-text"
                            id="summary_text_9"
                          >
                            0/1
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div id="nav5" className="nav-button">
                      {this.props.currentForm === FORM_ID.BUSINESS_INFO ? (
                        <>
                          <span id="current5" className="current-indicator">
                            CURRENT
                          </span>
                          <br />
                          <div className="nav-name">
                            <strong>Business Information</strong>
                          </div>
                        </>
                      ) : (
                        <div className="nav-name">Business Information</div>
                      )}
                      {this.props.busAddrCompleteInd === true && (
                        <div id="check5">
                          <span className="icon-answer-correct icon-sm"></span>
                        </div>
                      )}
                      {this.props.busAddrCompleteInd === false && (
                        <div id="summary5">
                          <span
                            className="nav-summary-text"
                            id="summary_text_5"
                          >
                            0/1
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div id="nav10" className="nav-button">
                      {this.props.currentForm === FORM_ID.BROKER_DEALER ? (
                        <>
                          <span id="current10" className="current-indicator ">
                            CURRENT
                          </span>
                          <br />
                          <div className="nav-name">
                            <strong>Broker Dealer Information</strong>
                          </div>
                        </>
                      ) : (
                        <div className="nav-name">
                          Broker Dealer Information
                        </div>
                      )}
                      {this.props.brokerCompleteInd === true && (
                        <div id="check10">
                          <span className="icon-answer-correct icon-sm"></span>
                        </div>
                      )}
                      {this.props.brokerCompleteInd === false && (
                        <div id="summary10">
                          <span
                            className="nav-summary-text"
                            id="summary_text_10"
                          >
                            0/1
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div id="nav11" className="nav-button">
                      {this.props.currentForm === FORM_ID.CCB ? (
                        <>
                          <span id="current11" className="current-indicator">
                            CURRENT
                          </span>
                          <br />
                          <div className="nav-name">
                            <strong> Character, Credit, and Bonding</strong>
                          </div>
                        </>
                      ) : (
                        <div className="nav-name">
                          Character, Credit, and Bonding
                        </div>
                      )}
                      {this.props.ccbCompleteInd === true && (
                        <div id="check11">
                          <span className="icon-answer-correct icon-sm"></span>
                        </div>
                      )}
                      {this.props.ccbCompleteInd === false && (
                        <div id="summary11">
                          <span
                            className="nav-summary-text"
                            id="summary_text_11"
                          >
                            0/1
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div id="nav12" className="nav-button">
                      {this.props.currentForm === FORM_ID.ERRORS_OMISSIONS ? (
                        <>
                          <span id="current12" className="current-indicator">
                            CURRENT
                          </span>
                          <br />
                          <div className="nav-name">
                            <strong>
                              Errors & Omissions (E&O) Coverage, Group Insurance
                            </strong>
                          </div>
                        </>
                      ) : (
                        <div className="nav-name">
                          Errors & Omissions (E&O) Coverage, Group Insurance
                        </div>
                      )}
                      {this.props.EOCompleteInd === true && (
                        <div id="check12">
                          <span className="icon-answer-correct icon-sm"></span>
                        </div>
                      )}
                      {this.props.EOCompleteInd === false && (
                        <div id="summary12">
                          <span
                            className="nav-summary-text"
                            id="summary_text_12"
                          >
                            0/1
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <hr />
                </div>
              </Collapse.Collapse>
            </Collapse.Group>
          </Collapse>

          <div className="row">
            <div className="col-12">
              <div>
                <Button
                  id="reviewAndSignBtn"
                  variant="secondary"
                  disabled={this.state.disableReviewSignBtn}
                  onClick={() => this.props.toggleForm(FORM_ID.REVIEW_SIGN_1)}
                >
                  Review & Sign
                </Button>
              </div>
            </div>
          </div>
          <div className="col-12 btn-link-wrapper">
            <div>
              <Button
                id="exitBtn"
                variant="link"
                onClick={() => {
                  this.handleShowModal();
                }}
              >
                <strong>Exit</strong>
              </Button>
            </div>
          </div>

          <ExitModal
            isOpen={this.state.showModal}
            closeBackModal={this.handleModalClose}
            handleClose={this.handleModalClose}
            backClick={this.handleExitClick}
          ></ExitModal>
        </div>
      </div>
    );
  }
}
export default StartApplicationNavBROKERCORP;
