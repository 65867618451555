import { FORM_ID } from "../../../../../constants/Forms";

function ReviewDirectDeposit(props) {
  const {
    brokaccountnumber,
    brokaccounttype,
    brokroutingnumber,
    compensationtype,
    mmaccountnumber,
    mmaccounttype,
    mmroutingnumber,
  } = props.directDepositInformation;

  const isSameAccount =
    mmaccountnumber === brokaccountnumber &&
    mmaccounttype === brokaccounttype &&
    mmroutingnumber === brokroutingnumber;

  const toggleForm = () => {
    props.toggleForm(FORM_ID.DIRECT_DEPOSIT);
  };
  return (
    <div id="direct_deposit">
      <div className="row">
        <div className="col-8" id="ddi-hding">
          <h4>Direct Deposit Information</h4>
        </div>
        <div className="col-4" id="d-di-edit">
          <span className="btn btn-link float-right" onClick={toggleForm}>
            Edit
          </span>
        </div>
      </div>
      {compensationtype === 1 && (
        <>
          <div className="row">
            <div className="col-3" id="cmpnstn-type">
              <h5>Compensation Type</h5>
            </div>
            <div id="compensation_type" className="col-3 data">
              MassMutual Only
            </div>
            <div className="col-3" id="rtng-nmbr">
              <h5>Routing Number</h5>
            </div>
            <div id="nmbr_mm" className="col-3 data">
              {mmroutingnumber}
            </div>
          </div>

          <div className="row">
            <div className="col-3" id="accnt-ty">
              <h5>Account Type</h5>
            </div>
            <div id="account_type_mm" className="col-3 data">
              {mmaccounttype === 1 && "Checking"}
              {mmaccounttype === 2 && "Savings"}
            </div>
            <div className="col-3" id="accont-nbr">
              <h5>Account Number</h5>
            </div>
            <div id="nber" className="col-3 data">
              {mmaccountnumber}
            </div>
          </div>
        </>
      )}
      {compensationtype === 2 && (
        <>
          <div className="row">
            <div className="col-3" id="cmpntn">
              <h5>Compensation Type</h5>
            </div>
            <div
              id="compensation_type"
              className={
                isSameAccount ? "margin-bottom-20px col-3 data" : "col-3 data"
              }
            >
              {isSameAccount === true ? (
                <>
                  MassMutual and Affiliated Broker Dealer (MMLIS Registered
                  Business)
                </>
              ) : (
                <>MassMutual</>
              )}
            </div>
            {isSameAccount === false && (
              <>
                <div className="col-3" id="ddi-compensation-type-heading">
                  <h5>Compensation Type</h5>
                </div>
                <div id="sationtype" className="col-3 data margin-bottom-20px">
                  Affiliated Broker Dealer (MMLIS Registered Business)
                </div>
              </>
            )}
            <div className="col-3">
              {isSameAccount === true ? (
                <h5 id="trueroutingnumber">Routing Number</h5>
              ) : (
                <h5 id="accntype">Account Type</h5>
              )}
            </div>
            <div
              id={
                isSameAccount === true ? "routing_number_mm" : "account_type_mm"
              }
              className="col-3 data"
            >
              {isSameAccount === true ? (
                <>{mmroutingnumber}</>
              ) : (
                <>
                  {mmaccounttype === 1 && "Checking"}
                  {mmaccounttype === 2 && "Savings"}
                </>
              )}
            </div>
            {isSameAccount === false && (
              <>
                <div className="col-3" id="acc-type">
                  <h5>Account Type</h5>
                </div>
                <div id="account_type_aff" className="col-3 data">
                  {brokaccounttype === 1 && "Checking"}
                  {brokaccounttype === 2 && "Savings"}
                </div>
              </>
            )}
            <div className="col-3">
              {isSameAccount === true ? (
                <h5 id="ac-count-type">Account Type</h5>
              ) : (
                <h5 id="r-tngnr">Routing Number</h5>
              )}
            </div>
            <div
              id={
                isSameAccount === true ? "account_type_mm" : "routing_number_mm"
              }
              className="col-3 data"
            >
              {isSameAccount === true ? (
                <>
                  {mmaccounttype === 1 && "Checking"}
                  {mmaccounttype === 2 && "Savings"}
                </>
              ) : (
                <>{mmroutingnumber}</>
              )}
            </div>
            {isSameAccount === false && (
              <>
                <div className="col-3" id="acc-ount-type">
                  <h5>Routing Number</h5>
                </div>
                <div id="routing_number_aff" className="col-3 data">
                  {brokroutingnumber}
                </div>
              </>
            )}
            <div className="col-3">
              <h5 id="accntnr">Account Number</h5>
            </div>
            <div id="accom" className="col-3 data">
              {mmaccountnumber}
            </div>
            {isSameAccount === false && (
              <>
                <div className="col-3" id="nber">
                  <h5>Account Number</h5>
                </div>
                <div id="account_number_aff" className="col-3 data">
                  {brokaccountnumber}
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default ReviewDirectDeposit;
