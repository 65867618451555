import React from "react";
import { Button, Modal } from "react-blueprint-library";
import "../../../assets/stylesheets/EmploymentHistoryWarnModal.scss";

export default function EmploymentHistoryDeleteWarnModal(props) {
  return (
    <>
      <div>
        <div id="backModal"></div>

        <div>
          <Modal
            id="EmploymentHistoryWarn"
            show={props.isOpen}
            className="modal-md"
            title=""
          >
            {props.show_spinner === false && (
              <div>
                <div className="modal-body" id="top_delete_element">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12">
                        <h2>
                          <div>{props.message}</div>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close modal dialog"
                            onClick={props.closeClick}
                            style={{
                              marginRight: "30px",
                              marginBottom: "55px",
                            }}
                          />
                        </h2>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className="col-md-12"
                        style={{
                          paddingTop: "20px",
                          paddingBottom: "20px",
                        }}
                      >
                        <button
                          type="button"
                          className="btn btn-link"
                          onClick={props.closeClick}
                          style={{
                            fontSize: "1.4rem",
                            padding: "4px 0px 0px 0px",
                            marginLeft: "250px",
                          }}
                        >
                          No, Nevermind
                        </button>
                        <Button
                          id="add_button"
                          label="Yes, Delete"
                          onClick={props.deleteClick}
                          style={{
                            padding: "5px",
                            fontSize: "1.3rem",
                          }}
                        ></Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {props.show_spinner && (
              <>
                <div
                  className="row"
                  style={{ marginTop: "15px", height: "150px" }}
                >
                  <div
                    id="emp_hist_delete_loading"
                    className="mm-dots-loading-xl"
                    style={{ marginLeft: "225px", marginTop: "50px" }}
                  />
                </div>
              </>
            )}
          </Modal>
        </div>
      </div>
    </>
  );
}
