import { FORM_ID } from "../../../../../constants/Forms";

function ReviewBusInfo(props) {
  const toggleForm = () => {
    props.toggleForm(FORM_ID.BUSINESS_INFO);
  };

  const { businessAddress } = props;

  const busAddress = getBusinessAddress(businessAddress);

  return (
    <div id="busnss-info">
      <div className="row">
        <div className="col-8" id="info-hdng">
          <h4>Business Information</h4>
        </div>
        <div className="col-4" id="editbutton">
          <span className="btn btn-link float-right" onClick={toggleForm}>
            Edit
          </span>
        </div>
      </div>

      <div className="row">
        <div className="col-3" id="ad">
          <h5 id="ads">Address</h5>
        </div>
        <div id="business_address" className="col-9 data">
          {busAddress}
        </div>
      </div>

      <div className="row">
        <div className="col-3" id="phone-number">
          <h5>Business Phone Number</h5>
        </div>
        <div id="businessphone" className="col-9 data">
          {props.businessAddress.businessphone.slice(-12)}
        </div>
      </div>

      {props.businessAddress.businessfax !== "" &&
        props.businessAddress.businessfax !== undefined &&
        props.businessAddress.businessfax !== "1-___-___-____" && (
          <div className="row">
            <div className="col-3" id="fax">
              <h5>Business Fax Number</h5>
            </div>
            <div id="business_fax" className="col-9 data">
              {props.businessAddress.businessfax.slice(-12)}
            </div>
          </div>
        )}
    </div>
  );
}

function getBusinessAddress(businessAddress) {
  let busAddress = "";
  const isThereBusUrbanization =
    businessAddress.busresurbanization !== undefined &&
    businessAddress.busresurbanization !== "" &&
    businessAddress.busresurbanization !== null;
  busAddress = `
    ${
      businessAddress.busstate === "PR" && isThereBusUrbanization
        ? `${businessAddress.busresurbanization}, `
        : ""
    }
    ${businessAddress.busaddress1}, ${
    businessAddress.busaddress2 === "" ? "" : `${businessAddress.busaddress2}, `
  }${businessAddress.buscity}, ${businessAddress.busstate}, ${
    businessAddress.buszip
  }`;

  return busAddress;
}

export default ReviewBusInfo;
