/* eslint-disable jsx-a11y/label-has-for */
import React, { forwardRef, useState } from "react";
import { Button } from "react-blueprint-library";
import { SECTION } from "../../constants";
import { parseOlString } from "../../helpers";
import { logoutActions } from "../../../../../../store/actions";
import { useDispatch } from "react-redux";

const CustomSubCategoryCard = forwardRef(
  (
    {
      data,
      category,
      handleOnEditClick,
      handleChangeChecked,
      handleOnSaveClick,
      section,
      isSaved,
      isRequired,
      isLoading,
      disabledSave,
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const [isEditHidden, setIsEditHidden] = useState(false);

    const onEditChange = () => {
      handleOnEditClick(section);
      setIsEditHidden((prev) => !prev);
    };

    const handleOnSave = () => {
      dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: false }));

      return !disabledSave && handleOnSaveClick(section);
    };

    return (
      <div className="sub-card-container" ref={ref}>
        <div className="save-edit-btn-wrapper">
          {isSaved[section] && !isEditHidden && disabledSave ? (
            <Button
              id="reg-action-edit"
              variant="link"
              className="edit"
              onClick={onEditChange}
            >
              <strong id={`edit-${section}`}>Edit</strong>
            </Button>
          ) : null}
        </div>

        <>
          {category.map(
            (
              {
                header,
                subHeader,
                questionA,
                questionB = null,
                questionC = null,
                questionD = null,
                questionE = null,
                questionF = null,
                questionG = null,
                questionH = null,
                optionsA,
                optionsB,
                optionsC,
                optionsD,
                optionsE,
                optionsF,
                optionsG,
                optionsH,
              },
              i
            ) => {
              const isAChecked =
                (optionsA?.valueOne &&
                  data[optionsA?.name] === optionsA?.valueOne) ||
                (optionsA?.valueTwo &&
                  data[optionsA?.name] === optionsA?.valueTwo);

              const isBChecked =
                (optionsB?.valueOne &&
                  data[optionsB?.name] === optionsB?.valueOne) ||
                (optionsB?.valueTwo &&
                  data[optionsB?.name] === optionsB?.valueTwo);

              const id = `${i * 1}-${header?.length}`;

              return (
                <div className="category" key={`${i * 1}_category_${header}`}>
                  <div id={`header-${section}-${id}`} className="header">
                    {header}
                  </div>
                  <div
                    className={`sub-category ${
                      header.includes("14D") ? "indent" : ""
                    }`}
                  >
                    <span
                      id={`subHeader-${section}-${id}`}
                      className={`${subHeader.includes("14G.") ? "bold" : ""}`}
                    >
                      {subHeader}
                    </span>
                    <div
                      className={`sub-category ${
                        header.includes("14F") ? "without-header" : ""
                      }`}
                    >
                      <span id={`reg-action-questionA-${section}-${id}`}>
                        {parseOlString(questionA)}
                      </span>
                      <div className="options">
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            name={optionsA.name}
                            id={optionsA.idOne}
                            className={`custom-control-input ${
                              isRequired && !isAChecked ? "is-invalid" : ""
                            }`}
                            checked={data[optionsA.name] === optionsA.valueOne}
                            value={optionsA.valueOne}
                            onChange={handleChangeChecked}
                            disabled={optionsA.disabled}
                          />
                          <label
                            id={`reg-action-${optionsA.idOne}-${optionsA.valueOne}`}
                            className="custom-control-label"
                            htmlFor={optionsA.idOne}
                          >
                            {optionsA.labelOne}
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            name={optionsA.name}
                            id={optionsA.idTwo}
                            className={`custom-control-input ${
                              isRequired && !isAChecked ? "is-invalid" : ""
                            }`}
                            checked={data[optionsA.name] === optionsA.valueTwo}
                            value={optionsA.valueTwo}
                            onChange={handleChangeChecked}
                            disabled={optionsA.disabled}
                          />
                          <label
                            id={`reg-action-${optionsA.idTwo}-${optionsA.valueTwo}`}
                            className="custom-control-label"
                            htmlFor={optionsA.idTwo}
                          >
                            {optionsA.labelTwo}
                          </label>
                        </div>
                      </div>
                      {questionB && (
                        <>
                          <span id={`reg-action-questionB-${section}-${id}`}>
                            {parseOlString(questionB)}
                          </span>
                          <div className="options">
                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                name={optionsB.name}
                                id={optionsB.idOne}
                                className={`custom-control-input ${
                                  isRequired && !isBChecked ? "is-invalid" : ""
                                }`}
                                checked={
                                  data[optionsB.name] === optionsB?.valueOne
                                }
                                value={optionsB?.valueOne}
                                onChange={handleChangeChecked}
                                disabled={optionsB.disabled}
                              />
                              <label
                                id={`reg-action-${optionsB.idOne}-${optionsB.valueTwo}`}
                                className="custom-control-label"
                                htmlFor={optionsB.idOne}
                              >
                                {optionsB.labelOne}
                              </label>
                            </div>

                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                name={optionsB.name}
                                id={optionsB.idTwo}
                                className={`custom-control-input ${
                                  isRequired && !isBChecked ? "is-invalid" : ""
                                }`}
                                checked={
                                  data[optionsB.name] === optionsB.valueTwo
                                }
                                value={optionsB.valueTwo}
                                onChange={handleChangeChecked}
                                disabled={optionsB.disabled}
                              />
                              <label
                                id={`reg-action-${optionsB.idTwo}-${optionsB.valueTwo}`}
                                className="custom-control-label"
                                htmlFor={optionsB.idTwo}
                              >
                                {optionsB.labelTwo}
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                      {questionC && (
                        <>
                          <span id={`reg-action-questionC-${section}-${id}`}>
                            {parseOlString(questionC)}
                          </span>
                          <div className="options">
                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                name={optionsC.name}
                                id={optionsC.idOne}
                                className={`custom-control-input ${
                                  isRequired && !isBChecked ? "is-invalid" : ""
                                }`}
                                checked={
                                  data[optionsC.name] === optionsC?.valueOne
                                }
                                value={optionsC?.valueOne}
                                onChange={handleChangeChecked}
                                disabled={optionsC.disabled}
                              />
                              <label
                                id={`reg-action-${optionsC.idOne}-${optionsC.valueOne}`}
                                className="custom-control-label"
                                htmlFor={optionsC.idOne}
                              >
                                {optionsC.labelOne}
                              </label>
                            </div>

                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                name={optionsC.name}
                                id={optionsC.idTwo}
                                className={`custom-control-input ${
                                  isRequired && !isBChecked ? "is-invalid" : ""
                                }`}
                                checked={
                                  data[optionsC.name] === optionsC.valueTwo
                                }
                                value={optionsC.valueTwo}
                                onChange={handleChangeChecked}
                                disabled={optionsC.disabled}
                              />
                              <label
                                id={`reg-action-${optionsC.idTwo}-${optionsC.valueTwo}`}
                                className="custom-control-label"
                                htmlFor={optionsC.idTwo}
                              >
                                {optionsC.labelTwo}
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                      {questionD && (
                        <>
                          <span id={`reg-action-questionD-${section}-${id}`}>
                            {parseOlString(questionD)}
                          </span>
                          <div className="options">
                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                name={optionsD.name}
                                id={optionsD.idOne}
                                className={`custom-control-input ${
                                  isRequired && !isBChecked ? "is-invalid" : ""
                                }`}
                                checked={
                                  data[optionsD.name] === optionsD?.valueOne
                                }
                                value={optionsD?.valueOne}
                                onChange={handleChangeChecked}
                                disabled={optionsD.disabled}
                              />
                              <label
                                id={`reg-action-${optionsD.idOne}-${optionsD.valueOne}`}
                                className="custom-control-label"
                                htmlFor={optionsD.idOne}
                              >
                                {optionsD.labelOne}
                              </label>
                            </div>

                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                name={optionsD.name}
                                id={optionsD.idTwo}
                                className={`custom-control-input ${
                                  isRequired && !isBChecked ? "is-invalid" : ""
                                }`}
                                checked={
                                  data[optionsD.name] === optionsD.valueTwo
                                }
                                value={optionsD.valueTwo}
                                onChange={handleChangeChecked}
                                disabled={optionsD.disabled}
                              />
                              <label
                                id={`reg-action-${optionsD.idTwo}-${optionsD.valueTwo}`}
                                className="custom-control-label"
                                htmlFor={optionsD.idTwo}
                              >
                                {optionsD.labelTwo}
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                      {questionE && (
                        <>
                          <span id={`reg-action-questionE-${section}-${id}`}>
                            {parseOlString(questionE)}
                          </span>
                          <div className="options">
                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                name={optionsE.name}
                                id={optionsE.idOne}
                                className={`custom-control-input ${
                                  isRequired && !isBChecked ? "is-invalid" : ""
                                }`}
                                checked={
                                  data[optionsE.name] === optionsE?.valueOne
                                }
                                value={optionsE?.valueOne}
                                onChange={handleChangeChecked}
                                disabled={optionsE.disabled}
                              />
                              <label
                                id={`reg-action-${optionsE.idOne}-${optionsE.valueOne}`}
                                className="custom-control-label"
                                htmlFor={optionsE.idOne}
                              >
                                {optionsE.labelOne}
                              </label>
                            </div>

                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                name={optionsE.name}
                                id={optionsE.idTwo}
                                className={`custom-control-input ${
                                  isRequired && !isBChecked ? "is-invalid" : ""
                                }`}
                                checked={
                                  data[optionsE.name] === optionsE.valueTwo
                                }
                                value={optionsE.valueTwo}
                                onChange={handleChangeChecked}
                                disabled={optionsE.disabled}
                              />
                              <label
                                id={`reg-action-${optionsE.idTwo}-${optionsE.valueTwo}`}
                                className="custom-control-label"
                                htmlFor={optionsE.idTwo}
                              >
                                {optionsE.labelTwo}
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                      {questionF && (
                        <>
                          <span id={`reg-action-questionF-${section}-${id}`}>
                            {parseOlString(questionF)}
                          </span>
                          <div className="options">
                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                name={optionsF.name}
                                id={optionsF.idOne}
                                className={`custom-control-input ${
                                  isRequired && !isBChecked ? "is-invalid" : ""
                                }`}
                                checked={
                                  data[optionsF.name] === optionsF?.valueOne
                                }
                                value={optionsF?.valueOne}
                                onChange={handleChangeChecked}
                                disabled={optionsF.disabled}
                              />
                              <label
                                id={`reg-action-${optionsF.idOne}-${optionsE.valueOne}`}
                                className="custom-control-label"
                                htmlFor={optionsF.idOne}
                              >
                                {optionsF.labelOne}
                              </label>
                            </div>

                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                name={optionsF.name}
                                id={optionsF.idTwo}
                                className={`custom-control-input ${
                                  isRequired && !isBChecked ? "is-invalid" : ""
                                }`}
                                checked={
                                  data[optionsF.name] === optionsF.valueTwo
                                }
                                value={optionsF.valueTwo}
                                onChange={handleChangeChecked}
                                disabled={optionsF.disabled}
                              />
                              <label
                                id={`reg-action-${optionsF.idTwo}-${optionsF.valueTwo}`}
                                className="custom-control-label"
                                htmlFor={optionsF.idTwo}
                              >
                                {optionsF.labelTwo}
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                      {questionG && (
                        <>
                          <span id={`reg-action-questionG-${section}-${id}`}>
                            {parseOlString(questionG)}
                          </span>
                          <div className="options">
                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                name={optionsG.name}
                                id={optionsG.idOne}
                                className={`custom-control-input ${
                                  isRequired && !isBChecked ? "is-invalid" : ""
                                }`}
                                checked={
                                  data[optionsG.name] === optionsG?.valueOne
                                }
                                value={optionsG?.valueOne}
                                onChange={handleChangeChecked}
                                disabled={optionsG.disabled}
                              />
                              <label
                                id={`reg-action-${optionsG.idOne}-${optionsG.valueOne}`}
                                className="custom-control-label"
                                htmlFor={optionsG.idOne}
                              >
                                {optionsG.labelOne}
                              </label>
                            </div>

                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                name={optionsG.name}
                                id={optionsG.idTwo}
                                className={`custom-control-input ${
                                  isRequired && !isBChecked ? "is-invalid" : ""
                                }`}
                                checked={
                                  data[optionsG.name] === optionsG.valueTwo
                                }
                                value={optionsG.valueTwo}
                                onChange={handleChangeChecked}
                                disabled={optionsG.disabled}
                              />
                              <label
                                id={`reg-action-${optionsG.idTwo}-${optionsG.valueTwo}`}
                                className="custom-control-label"
                                htmlFor={optionsG.idTwo}
                              >
                                {optionsG.labelTwo}
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                      {questionH && (
                        <>
                          <span id={`reg-action-questionH-${section}-${id}`}>
                            {parseOlString(questionH)}
                          </span>
                          <div className="options">
                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                name={optionsH.name}
                                id={optionsH.idOne}
                                className={`custom-control-input ${
                                  isRequired && !isBChecked ? "is-invalid" : ""
                                }`}
                                checked={
                                  data[optionsH.name] === optionsH?.valueOne
                                }
                                value={optionsH?.valueOne}
                                onChange={handleChangeChecked}
                                disabled={optionsH.disabled}
                              />
                              <label
                                id={`reg-action-${optionsH.idOne}-${optionsH.valueOne}`}
                                className="custom-control-label"
                                htmlFor={optionsH.idOne}
                              >
                                {optionsH.labelOne}
                              </label>
                            </div>

                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                name={optionsH.name}
                                id={optionsH.idTwo}
                                className={`custom-control-input ${
                                  isRequired && !isBChecked ? "is-invalid" : ""
                                }`}
                                checked={
                                  data[optionsH.name] === optionsH.valueTwo
                                }
                                value={optionsH.valueTwo}
                                onChange={handleChangeChecked}
                                disabled={optionsH.disabled}
                              />
                              <label
                                id={`reg-action-${optionsH.idTwo}-${optionsH.valueTwo}`}
                                className="custom-control-label"
                                htmlFor={optionsH.idTwo}
                              >
                                {optionsH.labelTwo}
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                      {isRequired && !(isBChecked && isAChecked) ? (
                        <div id="is_required_text_error" className="isRequired">
                          Please choose an answer
                        </div>
                      ) : null}
                    </div>
                    {i + 1 < category.length &&
                    section === SECTION.regulatoryActionOtherRegulators ? (
                      <hr />
                    ) : null}
                  </div>
                </div>
              );
            }
          )}
        </>

        <div className="save-edit-btn-wrapper">
          {isEditHidden || !isSaved[section] || !disabledSave ? (
            <Button
              id={`reg-action-save-and-continue-${section}`}
              variant="link"
              className={`save ${disabledSave ? "disabled" : ""}`}
              disabled={disabledSave}
              onClick={handleOnSave}
            >
              <strong id={`save-continue-${section}`}>Save And Continue</strong>

              {isLoading && <span className="mm-dots-loading save"></span>}
            </Button>
          ) : null}
        </div>
      </div>
    );
  }
);

export default CustomSubCategoryCard;
