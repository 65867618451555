import { convertGroupKey } from "../../helpers";

export const headerText = {
  HEADER: "Civil Judicial Disclosure",
  CIV_JUD_ACT: "Civil Judicial Actions",
};

export const initialState = {
  [convertGroupKey(headerText.CIV_JUD_ACT)]: {
    actDetails: null,
    details: null,
    statusDetails: null,
    resDetails: null,
    sanctionDetails: null,
    comments: null,
  },
};

export const civJudDesc = {
  DEF_DESCRIPTION: `Use only one DRP to report details related to the same event. Unrelated civil judicial actions must be reported on separate DRPs.`,
};

export const CCB_FORM_ID = {
  CIVIL_DISCLOSURE: 1,
  DRP_SUMMARY: 2,
  CIV_JUD_ACT_DETAILS: 31,
  CIV_JUD_DETAILS: 32,
  CIV_JUD_STATUS_DETAILS: 33,
  CIV_JUD_RES_DETAILS: 34,
  CIV_JUD_SANCT_DETAILS: 35,
  CIV_JUD_COMMENTS: 36,
};
