export const civilTaxOptions = {
  Civil: "Civil",
  Tax: "Tax",
};

export const dateOptions = {
  EXACT: "Exact date",
  EXPLANATION: "Explanation",
};

export const yesNoOptions = {
  YES: "Yes",
  NO: "No",
};

export const courtActionTypes = {
  FEDERAL: "Federal Court",
  STATE: "State Court",
  FOREIGN: "Foreign Court",
  OTHER: "Other",
};

export const matterResolvedOptions = {
  DISCHARGED: "Discharged",
  RELEASED: "Released",
  REMOVED: "Removed",
  SATISFIED: "Satisfied",
};
