import React, { Component } from "react";
import { ProgressBar } from "react-bootstrap";
import { Button, Collapse } from "react-blueprint-library";
import ExitModal from "./Forms/BackButtonModal";
import { FORM_ID } from "../../constants/Forms";
import "./StartApplicationNav.scss";
import { CONTRACT_TYPE_DESCRIPTIONS } from "../../constants/Contract";

class StartApplicationNavMMLIS extends Component {
  constructor(props) {
    super(props);
    this.handleShowModal = this.handleShowModal.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleExitClick = this.handleExitClick.bind(this);
    this.setProgress = this.setProgress.bind(this);
    this.state = {
      keyA: "emp1",
      keyB: "ccb1",
      showModal: false,
      disableReviewSignBtn: true,
      progress: 0,
    };
  }

  handleItemClick(key) {
    if (key === this.state.keyA) {
      if (this.state.keyA === "emp1") {
        this.setState({ keyA: "emp0" });
      } else {
        this.setState({ keyA: "emp1" });
      }
    }

    if (key === this.state.keyB) {
      if (this.state.keyB === "ccb1") {
        this.setState({ keyB: "ccb0" });
      } else {
        this.setState({ keyB: "ccb1" });
      }
    }
  }

  handleExitClick() {
    window.location = "/";
  }

  handleModalClose() {
    this.setState({ showModal: false });
  }

  handleShowModal() {
    if (this.props.currentFormChanged === true || this.props.showLogoutModal) {
      this.setState({ showModal: true });
    } else {
      this.handleExitClick();
    }
  }

  moreInfoClick = (e) => {
    e.stopPropagation();
  };
  componentDidMount() {
    this.setProgress();
  }

  //Opens dropdown when navigated to
  componentDidUpdate(prevProps) {
    if (prevProps.currentForm !== this.props.currentForm) {
      if (
        this.props.currentForm === FORM_ID.PROFESSIONAL_DESIGNATION &&
        prevProps.currentForm === FORM_ID.EMPLOYMENT_HISTORY
      ) {
        if (this.state.keyA === "emp0") {
          document.getElementById("collapse_emp").click();
        }
      }
      if (
        this.props.currentForm === FORM_ID.EMPLOYMENT_HISTORY &&
        prevProps.currentForm === FORM_ID.PROFESSIONAL_DESIGNATION
      ) {
        if (this.state.keyA === "emp1") {
          document.getElementById("collapse_emp").click();
        }
      }

      if (
        (this.props.currentForm === FORM_ID.EMPLOYMENT_HISTORY ||
          this.props.currentForm === FORM_ID.PERSONAL_SALES_RECORD ||
          this.props.currentForm === FORM_ID.PERSONAL_SALES_RECORD_CHARTS) &&
        prevProps.currentForm === FORM_ID.CCB_INTRO
      ) {
        if (this.state.keyA === "emp1") {
          document.getElementById("collapse_emp").click();
        }
        if (this.state.keyB === "ccb0") {
          document.getElementById("collapse_ccb").click();
        }
      }

      if (
        this.props.currentForm === FORM_ID.CCB_INTRO &&
        (prevProps.currentForm === FORM_ID.EMPLOYMENT_HISTORY ||
          prevProps.currentForm === FORM_ID.PERSONAL_SALES_RECORD ||
          prevProps.currentForm === FORM_ID.PERSONAL_SALES_RECORD_CHARTS)
      ) {
        if (this.state.keyA === "emp0") {
          document.getElementById("collapse_emp").click();
        }
        if (this.state.keyB === "ccb1") {
          document.getElementById("collapse_ccb").click();
        }
      }

      if (
        this.props.currentForm === FORM_ID.FINANCIAL_DISCLOSURE &&
        prevProps.currentForm === FORM_ID.DIRECT_DEPOSIT
      ) {
        if (this.state.keyB === "ccb1") {
          document.getElementById("collapse_ccb").click();
        }
      }

      if (
        this.props.currentForm === FORM_ID.DIRECT_DEPOSIT &&
        prevProps.currentForm === FORM_ID.FINANCIAL_DISCLOSURE
      ) {
        if (this.state.keyB === "ccb0") {
          document.getElementById("collapse_ccb").click();
        }
      }
    } else {
      if (
        this.props.currentForm === FORM_ID.EMPLOYMENT_HISTORY ||
        this.props.currentForm === FORM_ID.PERSONAL_SALES_RECORD ||
        this.props.currentForm === FORM_ID.PERSONAL_SALES_RECORD_CHARTS
      ) {
        if (this.state.keyA === "emp1") {
          document.getElementById("collapse_emp").click();
        }
      }
      if (
        this.props.currentForm === FORM_ID.CCB_INTRO ||
        this.props.currentForm === FORM_ID.CRIMINAL_DISCLOSURE ||
        this.props.currentForm === FORM_ID.REGULATORY_ACTION_DISCLOSURE ||
        this.props.currentForm === FORM_ID.CIVIL_JUDICIAL_DISCLOSURE ||
        this.props.currentForm === FORM_ID.CUSTOMER_COMPLAINT_DISCLOSURE ||
        this.props.currentForm === FORM_ID.TERMINATION_DISCLOSURE ||
        this.props.currentForm === FORM_ID.FINANCIAL_DISCLOSURE
      ) {
        if (this.state.keyB === "ccb1") {
          document.getElementById("collapse_ccb").click();
        }
      }
    }
    if (prevProps !== this.props) {
      this.setProgress();
    }
  }

  setProgress() {
    const { contracttypedesc, includePSR } = this.props;

    let trueCount = 0;
    let numberOfIndicators = 0;

    //Remove PSRIndicator if there is no PSR
    let passedInProps = this.props;

    if (includePSR === false) {
      passedInProps = Object.fromEntries(
        Object.entries(passedInProps).filter(
          ([key]) => key !== "psrCompleteInd"
        )
      );
    }

    if (CONTRACT_TYPE_DESCRIPTIONS.COLLEGE_INTERN === contracttypedesc) {
      passedInProps = Object.fromEntries(
        Object.entries(passedInProps).filter(
          ([key]) => key !== "busAddrCompleteInd"
        )
      );
    }

    for (let item in passedInProps) {
      if (item.includes("Ind")) {
        numberOfIndicators += 1;
        if (passedInProps[item]) {
          if (item.includes("Disclosure")) {
            if (passedInProps[item].ind) {
              trueCount += 1;
            }
          } else {
            trueCount += 1;
          }
        }
      }
    }

    //Broker

    const progress = Math.round(
      100 - ((numberOfIndicators - trueCount) / numberOfIndicators) * 100
    );

    this.setState({
      progress: progress,
      disableReviewSignBtn: !(progress === 100),
    });
  }
  render() {
    return (
      <div id="startAppNavCAS">
        <div className="progress-bar-section">
          <div className="row">
            <div className="col-12">
              <h3 id="startAppNavProgressHeader">Application Progress</h3>
            </div>
          </div>
          <div className="row">
            <div
              id="startAppNavProgressBar"
              aria-label="Progress Bar Div NavMMLIS"
              className="col-7"
            >
              <ProgressBar
                id="progressBar"
                aria-label="Progress Bar NavMMLIS"
                now={this.state.progress}
              />
            </div>

            <div id="startAppNavProgressBarLabel" className="col-5">
              <strong>
                <span id="progressComplete">
                  {this.state.progress}% Complete
                </span>
              </strong>
            </div>
          </div>
        </div>
        <div className="nav-items">
          <div className="row">
            <div className="col-12">
              <div id="nav1" className="nav-button">
                {this.props.currentForm === FORM_ID.RELEASE_OF_INFO ? (
                  <>
                    <span id="current1" className="current-indicator">
                      CURRENT
                    </span>
                    <br />
                    <div className="nav-name">
                      <strong>Release of Information</strong>
                    </div>
                  </>
                ) : (
                  <div className="nav-name">Release of Information</div>
                )}
                {this.props.rInfoCompleteInd ? (
                  <div id="check1">
                    <span className="icon-answer-correct icon-sm"></span>
                  </div>
                ) : (
                  <div id="summary1">
                    <span className="nav-summary-text" id="summary_text_1">
                      0/1
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div id="nav2" className="nav-button">
                {this.props.currentForm === FORM_ID.AUTHORIZATION ? (
                  <>
                    <span id="current2" className="current-indicator">
                      CURRENT
                    </span>
                    <br />
                    <div className="nav-name">
                      <strong>Authorization to Work in the US</strong>
                    </div>
                  </>
                ) : (
                  <div className="nav-name">
                    Authorization to Work in the US
                  </div>
                )}
                {this.props.authorizationCompleteInd ? (
                  <div id="check2">
                    <span className="icon-answer-correct icon-sm"></span>
                  </div>
                ) : (
                  <div id="summary2">
                    <span className="nav-summary-text" id="summary_text_2">
                      0/1
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div id="nav3" className="nav-button">
                {this.props.currentForm === FORM_ID.PERSONAL_INFO ||
                this.props.currentForm ===
                  FORM_ID.PERSONAL_INFO_ADDITIONAL_INFO ? (
                  <>
                    <span id="current3" className="current-indicator">
                      CURRENT
                    </span>
                    <br />
                    <div className="nav-name">
                      <strong>Personal Information</strong>
                    </div>
                  </>
                ) : (
                  <div className="nav-name">Personal Information</div>
                )}
                {this.props.pInfoCompleteInd &&
                this.props.pAddlInfoCompleteInd ? (
                  <div id="check3">
                    <span className="icon-answer-correct icon-sm"></span>
                  </div>
                ) : (
                  <>
                    {this.props.currentForm !== FORM_ID.PERSONAL_INFO &&
                      this.props.currentForm !==
                        FORM_ID.PERSONAL_INFO_ADDITIONAL_INFO && (
                        <div id="summary3">
                          <span
                            className="nav-summary-text"
                            id="summary_text_3"
                          >
                            0/2
                          </span>
                        </div>
                      )}
                    {this.props.currentForm === FORM_ID.PERSONAL_INFO && (
                      <div id="summary3">
                        <span className="nav-summary-text" id="summary_text_3">
                          1/2
                        </span>
                      </div>
                    )}
                    {this.props.currentForm ===
                      FORM_ID.PERSONAL_INFO_ADDITIONAL_INFO && (
                      <div id="summary3">
                        <span className="nav-summary-text" id="summary_text_3">
                          2/2
                        </span>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div id="nav4" className="nav-button">
                {this.props.currentForm === FORM_ID.RESIDENTIAL_HISTORY ? (
                  <>
                    <span id="current4" className="current-indicator">
                      CURRENT
                    </span>
                    <br />
                    <div className="nav-name">
                      <strong>Residential History</strong>
                    </div>
                  </>
                ) : (
                  <div className="nav-name">Residential History</div>
                )}
                {this.props.resHistoryCompleteInd ? (
                  <div id="check4">
                    <span className="icon-answer-correct icon-sm"></span>
                  </div>
                ) : (
                  <div id="summary4">
                    <span className="nav-summary-text" id="summary_text_4">
                      0/1
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          {this.props.contracttypedesc !==
            CONTRACT_TYPE_DESCRIPTIONS.COLLEGE_INTERN && (
            <div className="row">
              <div className="col-12">
                <div id="nav5" className="nav-button">
                  {this.props.currentForm === FORM_ID.BUSINESS_INFO ? (
                    <>
                      <span id="current5" className="current-indicator">
                        CURRENT
                      </span>
                      <br />
                      <div className="nav-name">
                        <strong>Business Information</strong>
                      </div>
                    </>
                  ) : (
                    <div className="nav-name">Business Information</div>
                  )}
                  {this.props.busAddrCompleteInd ? (
                    <div id="check5">
                      <span className="icon-answer-correct icon-sm"></span>
                    </div>
                  ) : (
                    <div id="summary5">
                      <span className="nav-summary-text" id="summary_text_5">
                        0/1
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          <Collapse id="employmentHistoryAccordion">
            <Collapse.Group>
              <Collapse.Toggle
                id="collapse_emp"
                eventKey="emp1"
                onClick={() => this.handleItemClick(this.state.keyA)}
              >
                <h3 id="sidebar_emphistory">Employment History</h3>
              </Collapse.Toggle>
              <Collapse.Collapse eventKey="emp1">
                <div className="row">
                  <div className="col-12">
                    <div id="nav17" className="nav-button">
                      {this.props.currentForm === FORM_ID.EMPLOYMENT_HISTORY ? (
                        <>
                          <span id="current17" className="current-indicator">
                            CURRENT
                          </span>
                          <br />
                          <div className="nav-name">
                            <strong>Employment History</strong>
                          </div>
                        </>
                      ) : (
                        <div className="nav-name">Employment History</div>
                      )}
                      {this.props.empHistCompleteInd ? (
                        <div id="check17">
                          <span className="icon-answer-correct icon-sm"></span>
                        </div>
                      ) : (
                        <div id="summary17">
                          <span
                            className="nav-summary-text"
                            id="summary_text_17"
                          >
                            0/1
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <hr />
                </div>

                {/* {this.props.psrData > 0 && (
                  <div className="row">
                    <div className="col-12">
                      <div id="nav18" className="nav-button">
                        {this.props.currentForm ===
                          FORM_ID.PERSONAL_SALES_RECORD ||
                        this.props.currentForm ===
                          FORM_ID.PERSONAL_SALES_RECORD_CHARTS ? (
                          <>
                            <span id="current18" className="current-indicator">
                              CURRENT
                            </span>
                            <br />
                            <div className="nav-name">
                              {this.props.hasNYRegs === false ? (
                                <strong>Personal Sales Record</strong>
                              ) : (
                                <strong>Personal Production Record</strong>
                              )}
                            </div>
                          </>
                        ) : (
                          <div className="nav-name">
                            {this.props.hasNYRegs === false ? (
                              <>Personal Sales Record</>
                            ) : (
                              <>Personal Production Record</>
                            )}
                          </div>
                        )}
                        {this.props.psrCompleteInd ? (
                          <div id="check18">
                            <span className="icon-answer-correct icon-sm"></span>
                          </div>
                        ) : (
                          <div id="summary18">
                            <span
                              className="nav-summary-text"
                              id="summary_text_18"
                            >
                              0/1
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    <hr />
                  </div>
                )} */}
              </Collapse.Collapse>
            </Collapse.Group>
          </Collapse>
          <Collapse id="ccbAccordion">
            <Collapse.Group>
              <Collapse.Toggle
                id="collapse_ccb"
                eventKey="ccb1"
                onClick={() => this.handleItemClick(this.state.keyB)}
              >
                <h3 id="sidebar_ccb">Character, Credit, and Bonding</h3>
              </Collapse.Toggle>
              <Collapse.Collapse eventKey="ccb1">
                <div className="row">
                  <div className="col-12">
                    <div id="nav19" className="nav-button">
                      {this.props.currentForm === FORM_ID.CRIMINAL_DISCLOSURE ||
                      this.props.currentForm === FORM_ID.CCB_INTRO ? (
                        <>
                          <span id="current19" className="current-indicator">
                            CURRENT
                          </span>
                          <br />
                          <div className="nav-name">
                            <strong>Criminal Disclosure</strong>
                          </div>
                        </>
                      ) : (
                        <div className="nav-name">Criminal Disclosure</div>
                      )}
                      {this.props.crimDisclosureCompleteInd.ind ? (
                        <div id="check19">
                          <span className="icon-answer-correct icon-sm"></span>
                        </div>
                      ) : (
                        <div id="summary19">
                          <span
                            className="nav-summary-text"
                            id="summary_text_19"
                          >
                            {this.props.crimDisclosureCompleteInd.step}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div id="nav20" className="nav-button">
                      {this.props.currentForm ===
                      FORM_ID.REGULATORY_ACTION_DISCLOSURE ? (
                        <>
                          <span id="current20" className="current-indicator">
                            CURRENT
                          </span>
                          <br />
                          <div className="nav-name">
                            <strong>Regulatory Action Disclosure</strong>
                          </div>
                        </>
                      ) : (
                        <div className="nav-name">
                          Regulatory Action Disclosure
                        </div>
                      )}
                      {this.props.regActionDisclosureCompleteInd.ind ? (
                        <div id="check20">
                          <span className="icon-answer-correct icon-sm"></span>
                        </div>
                      ) : (
                        <div id="summary20">
                          <span
                            className="nav-summary-text"
                            id="summary_text_20"
                          >
                            {this.props.regActionDisclosureCompleteInd.step}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div id="nav21" className="nav-button">
                      {this.props.currentForm ===
                      FORM_ID.CIVIL_JUDICIAL_DISCLOSURE ? (
                        <>
                          <span id="current21" className="current-indicator">
                            CURRENT
                          </span>
                          <br />
                          <div className="nav-name">
                            <strong>Civil Judicial Disclosure</strong>
                          </div>
                        </>
                      ) : (
                        <div className="nav-name">
                          Civil Judicial Disclosure
                        </div>
                      )}
                      {this.props.civilJudDisclosureCompleteInd.ind ? (
                        <div id="check21">
                          <span className="icon-answer-correct icon-sm"></span>
                        </div>
                      ) : (
                        <div id="summary21">
                          <span
                            className="nav-summary-text"
                            id="summary_text_21"
                          >
                            {this.props.civilJudDisclosureCompleteInd.step}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div id="nav22" className="nav-button">
                      {this.props.currentForm ===
                      FORM_ID.CUSTOMER_COMPLAINT_DISCLOSURE ? (
                        <>
                          <span id="current22" className="current-indicator">
                            CURRENT
                          </span>
                          <br />
                          <div className="nav-name">
                            <strong>Customer Complaint Disclosure</strong>
                          </div>
                        </>
                      ) : (
                        <div className="nav-name">
                          Customer Complaint Disclosure
                        </div>
                      )}
                      {this.props.custCompDisclosureCompleteInd.ind ? (
                        <div id="check22">
                          <span className="icon-answer-correct icon-sm"></span>
                        </div>
                      ) : (
                        <div id="summary22">
                          <span
                            className="nav-summary-text"
                            id="summary_text_22"
                          >
                            {this.props.custCompDisclosureCompleteInd.step}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div id="nav23" className="nav-button">
                      {this.props.currentForm ===
                      FORM_ID.TERMINATION_DISCLOSURE ? (
                        <>
                          <span id="current23" className="current-indicator">
                            CURRENT
                          </span>
                          <br />
                          <div className="nav-name">
                            <strong>Termination Disclosure</strong>
                          </div>
                        </>
                      ) : (
                        <div className="nav-name">Termination Disclosure</div>
                      )}
                      {this.props.termDisclosureCompleteInd.ind ? (
                        <div id="check23">
                          <span className="icon-answer-correct icon-sm"></span>
                        </div>
                      ) : (
                        <div id="summary23">
                          <span
                            className="nav-summary-text"
                            id="summary_text_23"
                          >
                            {this.props.termDisclosureCompleteInd.step}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div id="nav24" className="nav-button">
                      {this.props.currentForm ===
                      FORM_ID.FINANCIAL_DISCLOSURE ? (
                        <>
                          <span id="current24" className="current-indicator">
                            CURRENT
                          </span>
                          <br />
                          <div className="nav-name">
                            <strong>Financial Disclosure</strong>
                          </div>
                        </>
                      ) : (
                        <div className="nav-name">Financial Disclosure</div>
                      )}
                      {this.props.finDisclosureCompleteInd.ind ? (
                        <div id="check24">
                          <span className="icon-answer-correct icon-sm"></span>
                        </div>
                      ) : (
                        <div id="summary24">
                          <span
                            className="nav-summary-text"
                            id="summary_text_24"
                          >
                            {this.props.finDisclosureCompleteInd.step}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <hr />
                </div>
              </Collapse.Collapse>
            </Collapse.Group>
          </Collapse>

          <div className="row">
            <div className="col-12">
              <div id="nav32" className="nav-button">
                {this.props.currentForm === FORM_ID.MMLIS_INTRO ||
                this.props.currentForm === FORM_ID.MMLIS_PAY_TO_PLAY_1 ||
                this.props.currentForm === FORM_ID.MMLIS_PAY_TO_PLAY_2 ||
                this.props.currentForm === FORM_ID.MMLIS_OBA_CRIA ? (
                  <>
                    <span id="current32" className="current-indicator">
                      CURRENT
                    </span>
                    <br />
                    <div className="nav-name">
                      <strong>MMLIS</strong>
                    </div>
                  </>
                ) : (
                  <div className="nav-name">MMLIS</div>
                )}
                {this.props.mmlisPage1CompleteInd === true &&
                this.props.mmlisPage2CompleteInd === true &&
                this.props.mmlisOBACRIACompleteInd === true ? (
                  <div id="check32">
                    <span className="icon-answer-correct icon-sm"></span>
                  </div>
                ) : (
                  <>
                    {this.props.currentForm !== FORM_ID.MMLIS_PAY_TO_PLAY_1 &&
                      this.props.currentForm !== FORM_ID.MMLIS_PAY_TO_PLAY_2 &&
                      this.props.currentForm !== FORM_ID.MMLIS_OBA_CRIA && (
                        <div id="summary32">
                          <span
                            className="nav-summary-text"
                            id="summary_text_32"
                          >
                            0/3
                          </span>
                        </div>
                      )}
                    {this.props.currentForm === FORM_ID.MMLIS_PAY_TO_PLAY_1 && (
                      <div id="summary32">
                        <span className="nav-summary-text" id="summary_text_32">
                          1/3
                        </span>
                      </div>
                    )}
                    {this.props.currentForm === FORM_ID.MMLIS_PAY_TO_PLAY_2 && (
                      <div id="summary32">
                        <span className="nav-summary-text" id="summary_text_32">
                          2/3
                        </span>
                      </div>
                    )}
                    {this.props.currentForm === FORM_ID.MMLIS_OBA_CRIA && (
                      <div id="summary32">
                        <span className="nav-summary-text" id="summary_text_32">
                          3/3
                        </span>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div>
                <Button
                  id="reviewAndSignBtn"
                  variant="secondary"
                  disabled={this.state.disableReviewSignBtn}
                  onClick={() => this.props.toggleForm(FORM_ID.REVIEW_SIGN_1)}
                >
                  Review & Sign
                </Button>
              </div>
            </div>
          </div>
          <div className="col-12 btn-link-wrapper">
            <div>
              <Button
                id="exitBtn"
                variant="link"
                onClick={() => {
                  this.handleShowModal();
                }}
              >
                <strong>Exit</strong>
              </Button>
            </div>
          </div>

          <ExitModal
            isOpen={this.state.showModal}
            closeBackModal={this.handleModalClose}
            handleClose={this.handleModalClose}
            backClick={this.handleExitClick}
          ></ExitModal>
        </div>
      </div>
    );
  }
}
export default StartApplicationNavMMLIS;
