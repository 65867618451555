/* eslint-disable jsx-a11y/label-has-for */
import React, { forwardRef, useState } from "react";
import { Button } from "react-blueprint-library";
import { SECTION } from "../../constants";
import { parseOlString } from "../../helpers";
import { useDispatch } from "react-redux";
import { logoutActions } from "../../../../../../store/actions";

const CustomSubCategoryCard = forwardRef(
  (
    {
      data,
      category,
      handleOnEditClick,
      handleChangeChecked,
      handleOnSaveClick,
      section,
      isSaved,
      isRequired,
      isLoading,
      disabledSave,
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const [isEditHidden, setIsEditHidden] = useState(false);

    const onEditChange = () => {
      handleOnEditClick(section);
      setIsEditHidden((prev) => !prev);
    };

    const handleOnSave = () => {
      dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: false }));

      return !disabledSave && handleOnSaveClick(section);
    };

    return (
      <div className="sub-card-container" ref={ref}>
        <div className="save-edit-btn-wrapper">
          {isSaved[section] && !isEditHidden && disabledSave ? (
            <Button
              id="finance-edit"
              variant="link"
              className="edit"
              onClick={onEditChange}
            >
              <strong id={`edit-${section}`}>Edit</strong>
            </Button>
          ) : null}
        </div>

        <>
          {category.map(
            (
              {
                header,
                subHeader,
                questionA,
                questionB = null,
                optionsA,
                optionsB,
              },
              i
            ) => {
              const isAChecked =
                (optionsA?.valueOne &&
                  data[optionsA?.name] === optionsA?.valueOne) ||
                (optionsA?.valueTwo &&
                  data[optionsA?.name] === optionsA?.valueTwo);

              const isBChecked =
                (optionsB?.valueOne &&
                  data[optionsB?.name] === optionsB?.valueOne) ||
                (optionsB?.valueTwo &&
                  data[optionsB?.name] === optionsB?.valueTwo);

              const id = `${i * 1}-${header?.length}`;

              return (
                <div
                  id={`category-${section}-${id}`}
                  className="category"
                  key={`${i * 1}_category_${header}`}
                >
                  <div id={`header-${section}-${id}`} className="header">
                    {header}
                  </div>
                  <div className="sub-category">
                    <span id={`subheader-${section}-${id}`}>{subHeader}</span>
                    <div className="sub-category">
                      <span id={`finance-questionA-${section}-${id}`}>
                        {parseOlString(questionA)}
                      </span>
                      <div className="options">
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            name={optionsA.name}
                            id={optionsA.idOne}
                            className={`custom-control-input ${
                              isRequired && !isAChecked ? "is-invalid" : ""
                            }`}
                            checked={data[optionsA.name] === optionsA.valueOne}
                            value={optionsA.valueOne}
                            onChange={handleChangeChecked}
                            disabled={optionsA.disabled}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={optionsA.idOne}
                            id={`finance-${optionsA.idOne}-${optionsA.valueOne}`}
                          >
                            {optionsA.labelOne}
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            name={optionsA.name}
                            id={optionsA.idTwo}
                            className={`custom-control-input ${
                              isRequired && !isAChecked ? "is-invalid" : ""
                            }`}
                            checked={data[optionsA.name] === optionsA.valueTwo}
                            value={optionsA.valueTwo}
                            onChange={handleChangeChecked}
                            disabled={optionsA.disabled}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={optionsA.idTwo}
                            id={`finance-${optionsA.idTwo}-${optionsA.valueTwo}`}
                          >
                            {optionsA.labelTwo}
                          </label>
                        </div>
                      </div>

                      {questionB && (
                        <>
                          <span id={`finance-questionB-${section}-${id}`}>
                            {parseOlString(questionB)}
                          </span>
                          <div className="options">
                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                name={optionsB.name}
                                id={optionsB.idOne}
                                className={`custom-control-input ${
                                  isRequired && !isBChecked ? "is-invalid" : ""
                                }`}
                                checked={
                                  data[optionsB.name] === optionsB?.valueOne
                                }
                                value={optionsB?.valueOne}
                                onChange={handleChangeChecked}
                                disabled={optionsB.disabled}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={optionsB.idOne}
                                id={`finance-${optionsB.idOne}-${optionsB.valueOne}`}
                              >
                                {optionsB.labelOne}
                              </label>
                            </div>

                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                name={optionsB.name}
                                id={optionsB.idTwo}
                                className={`custom-control-input ${
                                  isRequired && !isBChecked ? "is-invalid" : ""
                                }`}
                                checked={
                                  data[optionsB.name] === optionsB.valueTwo
                                }
                                value={optionsB.valueTwo}
                                onChange={handleChangeChecked}
                                disabled={optionsB.disabled}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={optionsB.idTwo}
                                id={`finance-${optionsB.idTwo}-${optionsB.valueTwo}`}
                              >
                                {optionsB.labelTwo}
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {isRequired && !(isBChecked && isAChecked) ? (
                        <div id="is_required_text_error" className="isRequired">
                          Please choose an answer
                        </div>
                      ) : null}
                    </div>

                    {i + 1 < category.length &&
                    section === SECTION.regulatoryActionOtherRegulators ? (
                      <hr />
                    ) : null}
                  </div>
                </div>
              );
            }
          )}
        </>

        <div className="save-edit-btn-wrapper">
          {isEditHidden || !isSaved[section] || !disabledSave ? (
            <Button
              id={`fin-action-save-and-continue-${section}`}
              variant="link"
              className={`save ${disabledSave ? "disabled" : ""}`}
              disabled={disabledSave}
              onClick={handleOnSave}
            >
              <strong id={`save-continue-${section}`}>Save And Continue</strong>

              {isLoading && <span className="mm-dots-loading save"></span>}
            </Button>
          ) : null}
        </div>
      </div>
    );
  }
);

export default CustomSubCategoryCard;
