/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "react-blueprint-library";
import BackButtonModal from "../../../../../Forms/BackButtonModal";
import InputMask from "react-input-mask";
import moment from "moment";

import svgs, { svgType } from "../../../../../../../assets/svgs";
import { RADIO, dateInputMask, eventStatusDateType } from "../../../constants";
import { logoutActions } from "../../../../../../../store/actions";

import DateFirstCharged from "./DateFirstCharged";
import DateOfDisposition from "./DateOfDisposition";
import DateMonetaryPenaltyFinePaid from "./DateMonetaryPenaltyFinePaid";
import AddNewChargeModal from "./AddNewChargeModal";
import AddNewChargeContent from "./AddNewChargeModal/AddNewChargeContent";
import NewChargesList from "../../../common/NewChargesList";

import "./EventDispDetailsForm.scss";

const AMOUNT_CUSTOM_ERR = "Please enter a value greater than zero";

const initialEditState = { list: [], index: null };

const CriminalDisclosureAddDRPEventDispDetailsForm = ({
  updateCCBForm,
  backFormId,
  nextFormId,
  getSubmitDataValues,
  data,
  groupKey,
  headerText,
}) => {
  const dispatch = useDispatch();
  const [formHasChanged, setFormHasChanged] = useState(false);
  const [showBackModal, setShowBackModal] = useState(false);
  const [showAddNewChargeModal, setShowAddNewChargeModal] = useState(false);
  const [amountPaid, setAmountPaid] = useState(data?.amountPaid || "");
  const [amountPaidError, setAmountPaidError] = useState(false);
  const [amountPaidErrorMsg, setAmountPaidErrorMsg] = useState("");

  const [dateErrorMsg, setDateOfDispErrorMsg] = useState("");
  const [dateFirstChargedErrorMsg, setDateFirstChargedErrorMsg] = useState("");
  const [startDatePenaltyErrorMsg, setStartDatePenaltyErrorMsg] = useState("");
  const [endDatePenaltyErrorMsg, setEndDatePenaltyErrorMsg] = useState("");
  const [dateMonetaryPenaltyPaidErrorMsg, setDateMonetaryPenaltyPaidErrorMsg] =
    useState("");
  const [isModalUpdates, setIsModalUpdates] = useState(false);

  const [newChargeDetailsList, setNewChargeDetailsList] = useState(
    data?.newChargeDetailsList || []
  );

  const [editChargeDetailsList, setEditChargeDetailsList] = useState({
    ...initialEditState,
  });

  const [dateFirstCharged, setDateFirstCharged] = useState(
    data?.dateFirstCharged || ""
  );
  const [dateFirstChargedError, setDateFirstChargedError] = useState(false);
  const [dateFirstChargedRadio, setDateFirstChargedRadio] = useState(
    data?.dateFirstChargedRadio || ""
  );
  const [dateFirstChargedExp, setDateFirstChargedExp] = useState(
    data?.dateFirstChargedExp || ""
  );
  const [dateFirstChargedExpError, setDateFirstChargedExpError] =
    useState(false);

  const [dateOfDisp, setDateOfDisp] = useState(data?.dateOfDisp || "");
  const [dateOfDispError, setDateOfDispError] = useState(false);
  const [dateOfDispRadio, setDateOfDispRadio] = useState(
    data?.dateOfDispRadio || ""
  );
  const [dateOfDispExp, setDateOfDispExp] = useState(data?.dateOfDispExp || "");
  const [dateOfDispExpError, setDateOfDispExpError] = useState(false);

  const [dateMonetaryPenaltyPaid, setDateMonetaryPenaltyPaid] = useState(
    data?.dateMonetaryPenaltyPaid || ""
  );
  const [dateMonetaryPenaltyPaidError, setDateMonetaryPenaltyPaidError] =
    useState(false);
  const [dateMonetaryPenaltyPaidRadio, setDateMonetaryPenaltyPaidRadio] =
    useState(data?.dateMonetaryPenaltyPaidRadio || "");
  const [dateMonetaryPenaltyPaidExp, setDateMonetaryPenaltyPaidExp] = useState(
    data?.dateMonetaryPenaltyPaidExp || ""
  );
  const [dateMonetaryPenaltyPaidExpError, setDateMonetaryPenaltyPaidExpError] =
    useState(false);

  const [startDatePenalty, setStartDatePenalty] = useState(
    data?.startDatePenalty || ""
  );
  const [startDatePenaltyError, setStartDatePenaltyError] = useState(false);
  const [endDatePenalty, setEndDatePenalty] = useState(
    data?.startDatePenalty || ""
  );
  const [endDatePenaltyError, setEndDatePenaltyError] = useState(false);

  const [monetaryPenalty, setMonetaryPenalty] = useState(
    typeof data?.monetaryPenalty == "boolean" ? data?.monetaryPenalty : null
  );

  const [isStartDateNextDisabled, setIsStartDateNextDisabled] = useState(false);
  const [isEndDateNextDisabled, setIsEndDateNextDisabled] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  const getAddNewChargeValues = (val, index) => {
    const isEdit = index !== null;

    if (Object.values(val)) {
      if (isEdit) {
        setNewChargeDetailsList((prevState) => {
          let newState = prevState;

          newState[index] = val;

          return newState;
        });
      } else {
        setNewChargeDetailsList((prevState) => [...prevState, val]);
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  const isError =
    dateFirstChargedError ||
    dateFirstChargedExpError ||
    dateOfDispError ||
    dateOfDispExpError ||
    amountPaidError ||
    dateMonetaryPenaltyPaidError ||
    dateMonetaryPenaltyPaidExpError ||
    startDatePenaltyError ||
    endDatePenaltyError;

  useEffect(() => {
    if (isError) {
      setFormHasChanged(true);
    }
  }, [isError]);

  const isDateFirstChargedDisabled =
    !dateFirstCharged?.length ||
    dateFirstCharged.includes("_") ||
    dateFirstCharged === dateInputMask.EMPTY ||
    (!dateFirstCharged?.length && !dateFirstChargedRadio?.length) ||
    !dateFirstChargedRadio?.length ||
    !dateFirstCharged?.length ||
    (dateFirstCharged?.length &&
      dateFirstChargedRadio?.length &&
      dateFirstChargedRadio === eventStatusDateType.EXPLANATION &&
      !dateFirstChargedExp?.length) ||
    (dateFirstChargedExp?.length && dateFirstChargedExp?.length < 2);

  const isDateOfDispDisabled =
    !dateOfDisp?.length ||
    dateOfDisp.includes("_") ||
    dateOfDisp === dateInputMask.EMPTY ||
    (!dateOfDisp?.length && !dateOfDispRadio?.length) ||
    !dateOfDispRadio?.length ||
    (dateOfDisp?.length &&
      dateOfDispRadio?.length &&
      dateOfDispRadio === eventStatusDateType.EXPLANATION &&
      !dateOfDispExp?.length) ||
    (dateOfDispExp?.length && dateOfDispExp?.length < 2);

  const isDateMonetaryPenaltyPaidDisabled =
    monetaryPenalty &&
    (!dateMonetaryPenaltyPaid?.length ||
      dateMonetaryPenaltyPaid.includes("_") ||
      dateMonetaryPenaltyPaid === dateInputMask.EMPTY ||
      (!dateMonetaryPenaltyPaid?.length &&
        !dateMonetaryPenaltyPaidRadio?.length) ||
      !dateMonetaryPenaltyPaidRadio?.length ||
      (dateMonetaryPenaltyPaid?.length &&
        dateMonetaryPenaltyPaidRadio?.length &&
        dateMonetaryPenaltyPaidRadio === eventStatusDateType.EXPLANATION &&
        !dateMonetaryPenaltyPaidExp?.length) ||
      (dateMonetaryPenaltyPaidExp?.length &&
        dateMonetaryPenaltyPaidExp?.length < 2));

  const isStartDateDisabled =
    !startDatePenalty?.length ||
    startDatePenalty.includes("_") ||
    startDatePenalty === dateInputMask.EMPTY;
  const isEndDateDisabled =
    monetaryPenalty === null ||
    !endDatePenalty?.length ||
    endDatePenalty.includes("_") ||
    endDatePenalty === dateInputMask.EMPTY;

  const isChargeDetailsList = !newChargeDetailsList?.length;

  const isNextBtnDisabled = !!(
    isStartDateNextDisabled ||
    isEndDateNextDisabled ||
    isDateFirstChargedDisabled ||
    isDateOfDispDisabled ||
    isDateMonetaryPenaltyPaidDisabled ||
    isStartDateDisabled ||
    isEndDateDisabled ||
    isChargeDetailsList ||
    (monetaryPenalty && !amountPaid?.length) ||
    (monetaryPenalty && Number.parseInt(amountPaid) === 0) ||
    isError
  );

  const handleChangeDateFirstChargedChecked = (e) => {
    const { value } = e.target;
    let newValue = value;

    setFormHasChanged(true);
    setDateFirstChargedExpError(false);

    if (
      dateFirstChargedExp?.length &&
      dateFirstChargedRadio !== eventStatusDateType.EXPLANATION
    ) {
      setDateFirstChargedExp("");
    }

    setDateFirstChargedRadio(newValue);
  };

  const onDateFirstChargedChangeHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setDateFirstCharged(value);

    if (
      value?.length === 10 &&
      !value.includes("_") &&
      !dateFirstChargedError
    ) {
      const today = moment();
      const date = moment(value, "MM/DD/YYYY", true);
      const past = moment(moment().subtract(100, "years").format("MM/DD/YYYY"));
      if (value !== "" && !value.includes("_")) {
        if (moment(value) > today) {
          setIsStartDateNextDisabled(true);
        } else {
          setIsStartDateNextDisabled(false);
        }
      } else {
        setIsStartDateNextDisabled(false);
      }

      if (
        date._i.length === 10 &&
        !date._i.includes("_") &&
        date.isValid() &&
        !value.includes("0000")
      ) {
        if (date > today || date < past) {
          setIsStartDateNextDisabled(true);
        } else {
          setIsStartDateNextDisabled(false);
        }
      } else {
        setIsStartDateNextDisabled(true);
      }
    }
  };

  const onDateFirstChargedExplanationChange = ({ target: { value } }) => {
    setFormHasChanged(true);
    setDateFirstChargedExp(value);
  };

  const onBlurDateFirstChargedExpHandler = ({ target: { value } }) => {
    return value.trim()?.length < 2 || value.trim()?.length > 500
      ? setDateFirstChargedExpError(true)
      : setDateFirstChargedExpError(false);
  };

  const onDateFirstChargedBlurHandler = ({ target: { value } }) => {
    const today = moment();
    const date = moment(value, "MM/DD/YYYY", true);
    const past = moment(moment().subtract(100, "years").format("MM/DD/YYYY"));

    setIsStartDateNextDisabled(false);

    if (value !== "" && !value.includes("_")) {
      if (moment(value) > today) {
        setDateFirstChargedError(true);
      } else {
        setDateFirstChargedError(false);
      }
    } else {
      setDateFirstChargedError(false);
    }

    if (
      date._i.length === 10 &&
      !date._i.includes("_") &&
      date.isValid() &&
      !value.includes("0000")
    ) {
      if (date > today) {
        setDateFirstChargedErrorMsg("Date cannot be a future date");
        setDateFirstChargedError(true);
      } else if (date < past) {
        setDateFirstChargedErrorMsg("Date cannot be more than 100 years ago");
        setDateFirstChargedError(true);
      } else {
        setDateFirstChargedError(false);
      }
    } else {
      setDateFirstChargedErrorMsg("Please enter a valid date");
      setDateFirstChargedError(true);
    }
  };

  const handleDateOfDispChecked = (e) => {
    const { value } = e.target;
    let newValue = value;

    setDateOfDispExpError(false);
    setFormHasChanged(true);

    if (
      dateOfDispExp?.length &&
      dateOfDispRadio !== eventStatusDateType.EXPLANATION
    ) {
      setDateOfDispExp("");
    }

    setDateOfDispRadio(newValue);
  };

  const onDateOfDispChangeHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setDateOfDisp(value);

    if (value?.length === 10 && !value.includes("_") && !dateOfDispError) {
      const today = moment();
      const date = moment(value, "MM/DD/YYYY", true);
      const past = moment(moment().subtract(100, "years").format("MM/DD/YYYY"));
      if (value !== "" && !value.includes("_")) {
        if (moment(value) > today) {
          setIsEndDateNextDisabled(true);
        } else {
          setIsEndDateNextDisabled(false);
        }
      } else {
        setIsEndDateNextDisabled(false);
      }

      if (
        date._i.length === 10 &&
        !date._i.includes("_") &&
        date.isValid() &&
        !value.includes("0000")
      ) {
        if (date > today || date < past) {
          setIsEndDateNextDisabled(true);
        } else {
          setIsEndDateNextDisabled(false);
        }
      } else {
        setIsEndDateNextDisabled(true);
      }
    }
  };

  const onDateOfDispExplanationChange = ({ target: { value } }) => {
    setFormHasChanged(true);
    setDateOfDispExp(value);
  };

  const onBlurDateOfDispExpHandler = ({ target: { value } }) => {
    return value.trim()?.length < 2 || value.trim()?.length > 500
      ? setDateOfDispExpError(true)
      : setDateOfDispExpError(false);
  };

  const onAmountPaidBlurHandler = ({ target: { value } }) => {
    setAmountPaidError(value?.length === 0);

    setAmountPaidErrorMsg("");

    const tempNumber = value.replace(/,/gi, "");
    const commaSeparatedNumber = tempNumber.split(/(?=(?:\d{3})+$)/).join(",");

    if (
      (commaSeparatedNumber?.length &&
        typeof +commaSeparatedNumber === "number" &&
        (+commaSeparatedNumber === 0 || +commaSeparatedNumber < 1)) ||
      Number.parseInt(commaSeparatedNumber) === 0 ||
      commaSeparatedNumber === "-" ||
      commaSeparatedNumber.includes("-")
    ) {
      setAmountPaidErrorMsg(AMOUNT_CUSTOM_ERR);

      return setAmountPaidError(true);
    }
  };

  const onDateOfDispBlurHandler = ({ target: { value } }) => {
    const today = moment();
    const date = moment(value, "MM/DD/YYYY", true);
    const past = moment(moment().subtract(100, "years").format("MM/DD/YYYY"));

    setIsEndDateNextDisabled(false);

    if (value !== "" && !value.includes("_")) {
      if (moment(value) > today) {
        setDateOfDispError(true);
      } else {
        setDateOfDispError(false);
      }
    } else {
      setDateOfDispError(false);
    }

    if (
      date._i.length === 10 &&
      !date._i.includes("_") &&
      date.isValid() &&
      !value.includes("0000")
    ) {
      if (date > today) {
        setDateOfDispErrorMsg("Date cannot be a future date");
        setDateOfDispError(true);
      } else if (date < past) {
        setDateOfDispErrorMsg("Date cannot be more than 100 years ago");
        setDateOfDispError(true);
      } else {
        setDateOfDispError(false);
      }
    } else {
      setDateOfDispErrorMsg("Please enter a valid date");
      setDateOfDispError(true);
    }
  };

  const handleDateMonetaryPenaltyPaidChecked = (e) => {
    const { value } = e.target;
    let newValue = value;

    setDateMonetaryPenaltyPaidExpError(false);
    setFormHasChanged(true);

    if (
      dateMonetaryPenaltyPaidExp.length &&
      dateMonetaryPenaltyPaidRadio !== eventStatusDateType.EXPLANATION
    ) {
      setDateMonetaryPenaltyPaidExp("");
    }

    setDateMonetaryPenaltyPaidRadio(newValue);
  };

  const onDateMonetaryPenaltyPaidOnChangeHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setDateMonetaryPenaltyPaid(value);
  };

  const onDateMonetaryPenaltyPaidExplanationChange = ({
    target: { value },
  }) => {
    setFormHasChanged(true);
    setDateMonetaryPenaltyPaidExp(value);
  };

  const onBlurDateMonetaryPenaltyPaidExpHandler = ({ target: { value } }) => {
    return value.trim()?.length < 2 || value.trim()?.length > 500
      ? setDateMonetaryPenaltyPaidExpError(true)
      : setDateMonetaryPenaltyPaidExpError(false);
  };

  const onDateMonetaryPenaltyPaidBlurHandler = ({ target: { value } }) => {
    const today = moment();
    const date = moment(value, "MM/DD/YYYY", true);
    const past = moment(moment().subtract(100, "years").format("MM/DD/YYYY"));

    if (value !== "" && !value.includes("_")) {
      if (moment(value) > today) {
        setDateMonetaryPenaltyPaidError(true);
      } else {
        setDateMonetaryPenaltyPaidError(false);
      }
    } else {
      setDateMonetaryPenaltyPaidError(false);
    }

    if (
      date._i.length === 10 &&
      !date._i.includes("_") &&
      date.isValid() &&
      !value.includes("0000")
    ) {
      if (date < past) {
        setDateMonetaryPenaltyPaidErrorMsg(
          "Date cannot be more than 100 years ago"
        );
        setDateMonetaryPenaltyPaidError(true);
      } else {
        setDateMonetaryPenaltyPaidError(false);
      }
    } else {
      setDateMonetaryPenaltyPaidErrorMsg("Please enter a valid date");
      setDateMonetaryPenaltyPaidError(true);
    }
  };

  const handleChangeChecked = (e) => {
    const { value } = e.target;
    let newValue = value;

    setFormHasChanged(true);

    switch (value) {
      case RADIO.yes:
        newValue = true;
        break;
      case RADIO.no:
        newValue = false;
        break;
      default:
        newValue = null;
        break;
    }

    if (newValue === false) {
      setAmountPaid("");
      setAmountPaidError(false);
      setAmountPaidErrorMsg("");
      setDateMonetaryPenaltyPaid("");
      setDateMonetaryPenaltyPaidRadio("");
      setDateMonetaryPenaltyPaidExp("");
    }

    setDateMonetaryPenaltyPaidError(false);

    setMonetaryPenalty(newValue);
  };

  const onStartDatePenaltyOnChangeHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setStartDatePenalty(value);
  };

  const onStartDatePenaltyBlurHandler = ({ target: { value } }) => {
    const today = moment();
    const date = moment(value, "MM/DD/YYYY", true);
    const past = moment(moment().subtract(100, "years").format("MM/DD/YYYY"));

    if (value !== "" && !value.includes("_")) {
      if (moment(value) > today) {
        setStartDatePenaltyError(true);
      } else {
        setStartDatePenaltyError(false);
      }
    } else {
      setStartDatePenaltyError(false);
    }

    if (
      date._i.length === 10 &&
      !date._i.includes("_") &&
      date.isValid() &&
      !value.includes("0000")
    ) {
      if (date > today) {
        setStartDatePenaltyErrorMsg("Date cannot be a future date");
        setStartDatePenaltyError(true);
      } else if (date < past) {
        setStartDatePenaltyErrorMsg("Date cannot be more than 100 years ago");
        setStartDatePenaltyError(true);
      } else {
        setStartDatePenaltyError(false);
      }
    } else {
      setStartDatePenaltyErrorMsg("Please enter a valid date");
      setStartDatePenaltyError(true);
    }
  };

  const onEndDatePenaltyOnChangeHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setEndDatePenalty(value);
  };

  const onEndDatePenaltyBlurHandler = ({ target: { value } }) => {
    const today = moment();
    const date = moment(value, "MM/DD/YYYY", true);
    const past = moment(moment().subtract(100, "years").format("MM/DD/YYYY"));

    if (value !== "" && !value.includes("_")) {
      if (moment(value) > today) {
        setEndDatePenaltyError(true);
      } else {
        setEndDatePenaltyError(false);
      }
    } else {
      setEndDatePenaltyError(false);
    }

    if (
      date._i.length === 10 &&
      !date._i.includes("_") &&
      date.isValid() &&
      !value.includes("0000")
    ) {
      if (date < past) {
        setEndDatePenaltyErrorMsg("Date cannot be more than 100 years ago");
        setEndDatePenaltyError(true);
      } else {
        setEndDatePenaltyError(false);
      }
    } else {
      setEndDatePenaltyErrorMsg("Please enter a valid date");
      setEndDatePenaltyError(true);
    }
  };

  const handleOnAmountPaidChange = ({ target: { value } }) => {
    const tempNumber = value.replace(/,/gi, "");
    const commaSeparatedNumber = tempNumber.split(/(?=(?:\d{3})+$)/).join(",");

    setFormHasChanged(true);

    setAmountPaid(commaSeparatedNumber);
  };

  const handleCloseBackModal = () => setShowBackModal(false);

  const isUpdates = formHasChanged || isError;

  useEffect(() => {
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: true }));
  }, [isUpdates, dispatch]);

  const handleShowBackModal = () => {
    if (formHasChanged) {
      setShowBackModal(true);
    } else {
      if (
        (data !== null && data && !Object.keys(data).length) ||
        data === null
      ) {
        getSubmitDataValues({ eventDispDetails: null }, headerText);
      }
      updateCCBForm(backFormId);
    }
  };

  const handleBackClick = () => {
    if ((data !== null && data && !Object.keys(data).length) || data === null) {
      getSubmitDataValues({ eventDispDetails: null }, headerText);
    }
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: false }));
    updateCCBForm(backFormId);
    handleCloseBackModal();
  };

  const handleNext = useCallback(() => {
    const submitData = {
      dateFirstCharged,
      dateFirstChargedRadio,
      dateFirstChargedExp,
      dateOfDisp,
      dateOfDispRadio,
      dateOfDispExp,
      startDatePenalty,
      endDatePenalty,
      dateMonetaryPenaltyPaid,
      dateMonetaryPenaltyPaidRadio,
      dateMonetaryPenaltyPaidExp,
      monetaryPenalty,
      amountPaid,
      newChargeDetailsList,
    };

    getSubmitDataValues({ [`${groupKey}`]: submitData }, headerText);

    updateCCBForm(nextFormId);
  }, [
    updateCCBForm,
    nextFormId,
    getSubmitDataValues,
    dateFirstCharged,
    dateFirstChargedRadio,
    dateFirstChargedExp,
    dateOfDisp,
    dateOfDispRadio,
    dateOfDispExp,
    startDatePenalty,
    headerText,
    endDatePenalty,
    dateMonetaryPenaltyPaid,
    dateMonetaryPenaltyPaidRadio,
    dateMonetaryPenaltyPaidExp,
    monetaryPenalty,
    groupKey,
    amountPaid,
    newChargeDetailsList,
  ]);

  return (
    <div className="criminal-disclosure-event-disp-details-form-wrapper">
      <div className="row">
        <div className="add-new-charge-desctiption-label">Charge Details *</div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <label className="add-new-charge-label" id="addNewChargeLabelId">
            Complete every field for each charge:
          </label>
        </div>
      </div>

      {newChargeDetailsList.length ? (
        <div className="new-charges-list">
          <NewChargesList
            list={newChargeDetailsList}
            setNewChargesList={setNewChargeDetailsList}
            setEditChargeDetailsList={setEditChargeDetailsList}
            setShowAddNewChargeModal={setShowAddNewChargeModal}
          />
        </div>
      ) : null}

      <div className="add-drp-charge-btn-wrapper">
        <Button
          id="add-drp-charge"
          variant="secondary"
          className="add-drp-charge-btn"
          onClick={() => {
            setEditChargeDetailsList({ ...initialEditState });
            setShowAddNewChargeModal(true);
          }}
        >
          <div className="btn-content">
            <div className="plus-sign">
              <span className="indicator">{svgs[svgType.iconAddDRP]}</span>
            </div>
            <div className="text-wrapper">
              <strong id="add-new-charge-text">Add New Charge</strong>
            </div>
          </div>
        </Button>
      </div>

      <hr className="custom-hr" />

      <DateFirstCharged
        dateFirstChargedError={dateFirstChargedError}
        dateFirstChargedErrorMsg={dateFirstChargedErrorMsg}
        dateFirstCharged={dateFirstCharged}
        onDateFirstChargedChangeHandler={onDateFirstChargedChangeHandler}
        onDateFirstChargedBlurHandler={onDateFirstChargedBlurHandler}
        dateFirstChargedRadio={dateFirstChargedRadio}
        handleChangeDateFirstChargedChecked={
          handleChangeDateFirstChargedChecked
        }
        onDateFirstChargedExplanationChange={
          onDateFirstChargedExplanationChange
        }
        dateFirstChargedExpError={dateFirstChargedExpError}
        onBlurDateFirstChargedExpHandler={onBlurDateFirstChargedExpHandler}
        dateFirstChargedExp={dateFirstChargedExp}
      />

      <DateOfDisposition
        dateOfDispError={dateOfDispError}
        dateErrorMsg={dateErrorMsg}
        dateOfDisp={dateOfDisp}
        onDateOfDispChangeHandler={onDateOfDispChangeHandler}
        onDateOfDispBlurHandler={onDateOfDispBlurHandler}
        dateOfDispRadio={dateOfDispRadio}
        handleDateOfDispChecked={handleDateOfDispChecked}
        onDateOfDispExplanationChange={onDateOfDispExplanationChange}
        dateOfDispExpError={dateOfDispExpError}
        onBlurDateOfDispExpHandler={onBlurDateOfDispExpHandler}
        dateOfDispExp={dateOfDispExp}
      />
      <div className="sentence-hr">
        <hr />
        <div className="eyebrow-text-wrapper">
          <strong className="eyebrow">Sentence/Penalty</strong>
        </div>
        <hr />
      </div>
      <div className="row">
        <div className="add-new-charge-desctiption-label">
          Duration (If Suspension, Probation, Etc)
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 event-status-date-wrapper">
          <label id="startDatePenaltyLabelId" htmlFor="startDatePenaltyId">
            <strong id="startDatePenaltyLabel">Start Date of Penalty: *</strong>
          </label>
          <InputMask
            id="startDatePenaltyId"
            mask="99/99/9999"
            maskChar="_"
            className={`${
              startDatePenaltyError ? "form-control is-invalid" : "form-control"
            } event-status-date-input`}
            placeholder="MM/DD/YYYY"
            autoComplete="off"
            required
            value={startDatePenalty}
            onChange={onStartDatePenaltyOnChangeHandler}
            onBlur={onStartDatePenaltyBlurHandler}
          />
          <div id="startDatePenaltyError" className="invalid-feedback">
            {startDatePenaltyErrorMsg?.length
              ? startDatePenaltyErrorMsg
              : "Please enter a valid Start Date Penalty"}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 event-status-date-wrapper">
          <label id="endDatePenaltyLabelId" htmlFor="endDatePenaltyId">
            <strong id="endDatePenaltyLabel">End Date of Penalty: *</strong>
          </label>
          <InputMask
            id="endDatePenaltyId"
            mask="99/99/9999"
            maskChar="_"
            className={`${
              endDatePenaltyError ? "form-control is-invalid" : "form-control"
            } event-status-date-input`}
            placeholder="MM/DD/YYYY"
            autoComplete="off"
            required
            value={endDatePenalty}
            onChange={onEndDatePenaltyOnChangeHandler}
            onBlur={onEndDatePenaltyBlurHandler}
          />
          <div id="endDatePenaltyError" className="invalid-feedback">
            {endDatePenaltyErrorMsg?.length
              ? endDatePenaltyErrorMsg
              : "Please enter a valid End Date Penalty"}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <label className="inv-business" id="monetaryPenaltyLabelId">
            Were you charged a monetary penalty/fine?
          </label>
        </div>
      </div>
      <div className="row">
        <div className="drp-radio-input-wrapper">
          <div className="col-12 copy-md-4 form-group">
            <div className="custom-control custom-radio">
              <input
                name="monetaryPenalty"
                type="radio"
                id="monetaryPenalty_yes"
                className="custom-control-input"
                value={true}
                checked={monetaryPenalty === true}
                onChange={handleChangeChecked}
              />
              <label
                htmlFor="monetaryPenalty_yes"
                className="custom-control-label"
                id="monetaryPenaltyYesText"
              >
                Yes
              </label>
            </div>

            <div className="custom-control custom-radio">
              <input
                name="monetaryPenalty"
                type="radio"
                id="monetaryPenalty_no"
                className="custom-control-input"
                value={false}
                checked={monetaryPenalty === false}
                onChange={handleChangeChecked}
              />
              <label
                htmlFor="monetaryPenalty_no"
                className="custom-control-label"
                id="monetaryPenaltyNoText"
              >
                No
              </label>
            </div>
            <div id="radio_error" className="invalid-feedback">
              Please choose a response.
            </div>
          </div>
        </div>
      </div>

      {monetaryPenalty === true ? (
        <DateMonetaryPenaltyFinePaid
          amountPaid={amountPaid}
          onAmountPaidBlurHandler={onAmountPaidBlurHandler}
          amountPaidError={amountPaidError}
          amountPaidErrorMsg={amountPaidErrorMsg}
          handleOnAmountPaidChange={handleOnAmountPaidChange}
          dateMonetaryPenaltyPaidError={dateMonetaryPenaltyPaidError}
          dateMonetaryPenaltyPaidErrorMsg={dateMonetaryPenaltyPaidErrorMsg}
          dateMonetaryPenaltyPaid={dateMonetaryPenaltyPaid}
          onDateMonetaryPenaltyPaidOnChangeHandler={
            onDateMonetaryPenaltyPaidOnChangeHandler
          }
          onDateMonetaryPenaltyPaidBlurHandler={
            onDateMonetaryPenaltyPaidBlurHandler
          }
          dateMonetaryPenaltyPaidRadio={dateMonetaryPenaltyPaidRadio}
          handleDateMonetaryPenaltyPaidChecked={
            handleDateMonetaryPenaltyPaidChecked
          }
          onDateMonetaryPenaltyPaidExplanationChange={
            onDateMonetaryPenaltyPaidExplanationChange
          }
          dateMonetaryPenaltyPaidExpError={dateMonetaryPenaltyPaidExpError}
          onBlurDateMonetaryPenaltyPaidExpHandler={
            onBlurDateMonetaryPenaltyPaidExpHandler
          }
          dateMonetaryPenaltyPaidExp={dateMonetaryPenaltyPaidExp}
        />
      ) : null}

      {showAddNewChargeModal && (
        <AddNewChargeModal
          showAddNewChargeModal={showAddNewChargeModal}
          setShowAddNewChargeModal={setShowAddNewChargeModal}
          isUpdates={isModalUpdates}
        >
          <AddNewChargeContent
            data={editChargeDetailsList.list}
            dataIndex={editChargeDetailsList.index}
            getSubmitDataValues={getAddNewChargeValues}
            setShowAddNewChargeModal={setShowAddNewChargeModal}
            setEditChargeDetailsList={setEditChargeDetailsList}
            setIsModalUpdates={setIsModalUpdates}
            headerText={headerText}
          />
        </AddNewChargeModal>
      )}
      <div className="backbutton-drp-crim row">
        <div className="col-md-12 buttons">
          <span role="presentation" onClick={handleShowBackModal}>
            <strong id="crimDisclBack">Back</strong>
          </span>
          <Button
            label="Next"
            onClick={handleNext}
            disabled={!!isNextBtnDisabled}
          />
          {false && <span className="mm-dots-loading"></span>} {/* loading */}
        </div>
      </div>

      <BackButtonModal
        isOpen={showBackModal}
        closeBackModal={handleCloseBackModal}
        backClick={handleBackClick}
      />
    </div>
  );
};

export default CriminalDisclosureAddDRPEventDispDetailsForm;
