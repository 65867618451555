/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useCallback, useEffect, useState } from "react";
import { Button } from "react-blueprint-library";
import { useDispatch } from "react-redux";
import { RADIO } from "../../../constants";
import DRPSummaryListingCardCheck from "../../../common/DRPSummaryListingCard";
import DRPCardNoButton from "../../../common/DRPCard/DRPCardNoButton";
import BackButtonModal from "../../../../../Forms/BackButtonModal";

import "./OrganizationDetailsForm.scss";
import { logoutActions } from "../../../../../../../store/actions";

const CriminalDisclosureAddDRPOrganizationDetailsForm = ({
  updateCCBForm,
  backFormId,
  groupKey,
  nextFormId,
  values,
  getSubmitDataValues,
  data,
  headerText,
}) => {
  const dispatch = useDispatch();
  const [drpCheckList, setDRPCheckList] = useState(
    data?.drpCheckList?.length ? data?.drpCheckList : []
  );
  const [orgName, setOrgName] = useState(data?.orgName || "");
  const [posTitleRel, setPosTitleRel] = useState(data?.posTitleRel || "");
  const [invRelBusiness, setInvRelBusiness] = useState(
    typeof data?.invRelBusiness == "boolean" ? data?.invRelBusiness : null
  );
  const [formHasChanged, setFormHasChanged] = useState(false);

  const [orgNameError, setOrgNameError] = useState(false);
  const [posTitleRelError, setPosTitleRelError] = useState(false);

  const [showBackModal, setShowBackModal] = useState(false);

  const isError = orgNameError || posTitleRelError;

  useEffect(() => {
    if (isError) {
      setFormHasChanged(true);
    }
  }, [isError]);

  const isNextBtnDisabled =
    !!(
      !orgName.length ||
      (orgName.length && orgName.length < 2) ||
      !posTitleRel.length ||
      (posTitleRel.length && posTitleRel.length < 2) ||
      invRelBusiness === null ||
      !drpCheckList?.length
    ) || isError;

  const handleCloseBackModal = () => setShowBackModal(false);

  useEffect(() => {
    if (values?.length && values?.length === 1) {
      setDRPCheckList((drp) => [...drp, ...values.map((v) => v.key)]);
    }
  }, []);

  const isUpdates = formHasChanged || isError;

  useEffect(() => {
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: isUpdates }));
  }, [isUpdates, dispatch]);

  const handleShowBackModal = () => {
    if (isUpdates) {
      setShowBackModal(true);
    } else {
      if (
        (data !== null && data && !Object.keys(data).length) ||
        data === null
      ) {
        getSubmitDataValues({ orgDetails: null }, headerText);
      }
      updateCCBForm(backFormId);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  const handleBackClick = () => {
    if ((data !== null && data && !Object.keys(data).length) || data === null) {
      getSubmitDataValues({ orgDetails: null }, headerText);
    }
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: false }));
    updateCCBForm(backFormId);
    handleCloseBackModal();
  };

  const handleNext = useCallback(
    () => {
      const submitData = {
        drpCheckList: [...new Set(drpCheckList)],
        orgName,
        invRelBusiness,
        posTitleRel,
      };

      getSubmitDataValues({ [`${groupKey}`]: submitData }, headerText, false, [
        ...new Set(drpCheckList),
      ]);

      updateCCBForm(nextFormId);
    } /* eslint-disable-next-line react-hooks/exhaustive-deps */,
    [
      updateCCBForm,
      drpCheckList,
      orgName,
      invRelBusiness,
      posTitleRel,
      headerText,
      groupKey,
      nextFormId,
      getSubmitDataValues,
    ]
  );

  const handleChangeChecked = (e) => {
    const { value } = e.target;
    let newValue = value;

    setFormHasChanged(true);

    switch (value) {
      case RADIO.yes:
        newValue = true;
        break;
      case RADIO.no:
        newValue = false;
        break;
      default:
        newValue = null;
        break;
    }

    setInvRelBusiness(newValue);
  };

  const handleChange = ({ target: { checked, value } }) => {
    const list = [];

    if (checked) {
      list.push(value);

      setDRPCheckList((drp) => [...new Set([...drp, ...list])]);
    }

    setFormHasChanged(true);

    if (!checked) {
      setDRPCheckList((drp) => [
        ...new Set(drp?.filter((item) => item !== value)),
      ]);
    }
  };

  const onBlurOrgNameHandler = ({ target: { value } }) => {
    return value.trim().length < 2 || value.trim().length > 200
      ? setOrgNameError(true)
      : setOrgNameError(false);
  };

  const onChangeOrgNameHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setOrgName(value);
  };

  const onBlurPosTitleHandler = ({ target: { value } }) => {
    return value.trim().length < 2 || value.trim().length > 200
      ? setPosTitleRelError(true)
      : setPosTitleRelError(false);
  };

  const onChangePosTitleHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setPosTitleRel(value);
  };

  return (
    <div className="criminal-disclosure-org-details-form-wrapper">
      <div className="crim-discl-drp-summary">
        {values.length && values.length > 1 ? (
          <DRPCardNoButton
            header={"Check the question(s) you are responding to:"}
            isDrp={values?.length > 4}
          >
            {values.map(({ key, mainQuestion, question }, index) => (
              <DRPSummaryListingCardCheck
                key={`${index}_${key}`}
                questionKey={key}
                mainQuestion={mainQuestion}
                question={question}
                index={index}
                length={values.length}
                handleChange={handleChange}
                drpCheckList={drpCheckList}
                isCheckbox
              />
            ))}
          </DRPCardNoButton>
        ) : null}
      </div>

      <div className="row">
        <div className="org-desctiption-label">
          If charge(s) were brought against an organization over which you
          exercise(d) control:
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <label className="org-name" htmlFor="orgName" id="orgNameLabelId">
            Organization Name *
          </label>
          <input
            type="text"
            id="orgName"
            className={
              orgNameError
                ? "form-control form-control-15 is-invalid"
                : "form-control form-control-15"
            }
            maxLength={200}
            minLength={2}
            autoComplete="off"
            value={orgName}
            onChange={onChangeOrgNameHandler}
            onBlur={onBlurOrgNameHandler}
          />
          <div id="orgNameError" className="invalid-feedback">
            Please enter Organization Name
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <label className="inv-business" id="inv-business-label">
            Investment related business? *
          </label>
        </div>
      </div>
      <div className="row">
        <div className="drp-radio-input-wrapper">
          <div className="col-12 copy-md-4 form-group">
            <div className="custom-control custom-radio">
              <input
                name="invRelBusiness"
                type="radio"
                id="invRelBusiness_yes"
                className="custom-control-input"
                value={true}
                checked={invRelBusiness === true}
                onChange={handleChangeChecked}
              />
              <label
                htmlFor="invRelBusiness_yes"
                className="custom-control-label"
                id="invRelBusiness_yes_label"
              >
                Yes
              </label>
            </div>

            <div className="custom-control custom-radio">
              <input
                name="invRelBusiness"
                type="radio"
                id="invRelBusiness_no"
                className="custom-control-input"
                value={false}
                checked={invRelBusiness === false}
                onChange={handleChangeChecked}
              />
              <label
                htmlFor="invRelBusiness_no"
                className="custom-control-label"
                id="invRelBusiness_no_label"
              >
                No
              </label>
            </div>
            <div id="radio_error" className="invalid-feedback">
              Please choose a response.
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <label
            className="pos-title-rel"
            htmlFor="posTitleRel"
            id="posTitleRelLabelId"
          >
            Position, Title, or Relationship *
          </label>
          <input
            type="text"
            id="posTitleRel"
            className={
              posTitleRelError
                ? "form-control form-control-15 is-invalid"
                : "form-control form-control-15"
            }
            maxLength={200}
            minLength={2}
            autoComplete="off"
            value={posTitleRel}
            onChange={onChangePosTitleHandler}
            onBlur={onBlurPosTitleHandler}
          />
          <div id="posTitleRelError" className="invalid-feedback">
            Please enter Position, Title, or Relationship
          </div>
        </div>
      </div>

      <div className="backbutton-drp-crim row">
        <div className="col-md-12 buttons">
          <span role="presentation" onClick={handleShowBackModal}>
            <strong id="crimDisclBack">Back</strong>
          </span>
          <Button
            label="Next"
            onClick={handleNext}
            disabled={!!isNextBtnDisabled}
          />
          {false && <span className="mm-dots-loading"></span>} {/* loading */}
        </div>
      </div>

      <BackButtonModal
        isOpen={showBackModal}
        closeBackModal={handleCloseBackModal}
        backClick={handleBackClick}
      />
    </div>
  );
};

export default CriminalDisclosureAddDRPOrganizationDetailsForm;
