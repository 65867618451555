import React, { Component } from "react";
import { withOktaAuth } from "@okta/okta-react";
import Header from "../SharedComponents/Header";
import Footer from "../SharedComponents/Footer";
import "../../assets/stylesheets/HomePages.scss";
import { Button } from "react-blueprint-library";
//import axios from "axios";
import {
  getUbiqInformation,
  putUbiqInformation,
} from "../../api/makeAPIRequest";
class UBIQ extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState();
    this.commitToDBClick = this.commitToDBClick.bind(this);
    this.decryptClick = this.decryptClick.bind(this);
    this.clearClick = this.clearClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  initialState() {
    return {
      first_name: "",
      last_name: "",
      ssn: "",
      phone_number: "",
      email_address: "",
      comments: "",
      encrypted_first_name: "",
      encrypted_last_name: "",
      encrypted_ssn: "",
      encrypted_phone_number: "",
      encrypted_email_address: "",
      encrypted_comments: "",
      decrypted_first_name: "",
      decrypted_last_name: "",
      decrypted_ssn: "",
      decrypted_phone_number: "",
      decrypted_email_address: "",
      decrypted_comments: "",
      ubiq_id: 0,
    };
  }

  async handleChange(event) {
    await this.setState({
      [event.target.id]: event.target.value,
    });
  }

  //async componentDidMount() {}

  async clearClick() {
    await this.setState({
      first_name: "",
      last_name: "",
      ssn: "",
      phone_number: "",
      email_address: "",
      comments: "",
      encrypted_first_name: "",
      encrypted_last_name: "",
      encrypted_ssn: "",
      encrypted_phone_number: "",
      encrypted_email_address: "",
      encrypted_comments: "",
      decrypted_first_name: "",
      decrypted_last_name: "",
      decrypted_ssn: "",
      decrypted_phone_number: "",
      decrypted_email_address: "",
      decrypted_comments: "",
      ubiq_id: 0,
    });
  }

  async commitToDBClick() {
    let data = {
      firstname: this.state.first_name,
      lastname: this.state.last_name,
      ssn: this.state.ssn,
      phonenumber: this.state.phone_number,
      emailaddress: this.state.email_address,
      comments: this.state.comments,
    };

    let response = await putUbiqInformation(data);
    await this.setState({ ubiq_id: response.ubiqid });
    await this.setState({ encrypted_first_name: response.firstname });
    await this.setState({ encrypted_last_name: response.lastname });
    await this.setState({ encrypted_ssn: response.ssn });
    await this.setState({
      encrypted_phone_number: response.phonenumber,
    });
    await this.setState({
      encrypted_email_address: response.emailaddress,
    });
    await this.setState({
      encrypted_comments: response.comments,
    });
  }

  async decryptClick() {
    let response = await getUbiqInformation(this.state.ubiq_id);
    await this.setState({ decrypted_first_name: response.firstname2 });
    await this.setState({ decrypted_last_name: response.lastname2 });
    await this.setState({ decrypted_ssn: response.ssn2 });
    await this.setState({
      decrypted_phone_number: response.phonenumber2,
    });
    await this.setState({
      decrypted_email_address: response.emailaddress2,
    });
    await this.setState({
      decrypted_comments: response.comments2,
    });
  }

  render() {
    return (
      <div>
        <>
          <Header />
          <div className="container">
            <h1>UBIQ POC</h1>
            <hr></hr>
            <div className="row" style={{ marginTop: "10px" }}>
              <div className="col-md-4">
                <h2>Form</h2>
                <label htmlFor="first_name">
                  <strong>Legal First Name*</strong>
                </label>
                <input
                  type="text"
                  id="first_name"
                  className="form-control"
                  spellCheck="false"
                  maxLength={35}
                  value={this.state.first_name}
                  onChange={this.handleChange}
                />
                <br></br>
                <label htmlFor="last_name">
                  <strong>Legal Last Name*</strong>
                </label>
                <input
                  type="text"
                  id="last_name"
                  className="form-control"
                  spellCheck="false"
                  maxLength={35}
                  value={this.state.last_name}
                  onChange={this.handleChange}
                />
                <br></br>
                <label htmlFor="ssn">
                  <strong>Social Security Number*</strong>
                </label>
                <input
                  type="text"
                  id="ssn"
                  className="form-control"
                  spellCheck="false"
                  maxLength={11}
                  value={this.state.ssn}
                  onChange={this.handleChange}
                />
                <br></br>
                <label htmlFor="phone_number">
                  <strong>Telephone Number*</strong>
                </label>
                <input
                  type="text"
                  id="phone_number"
                  className="form-control"
                  spellCheck="false"
                  maxLength={14}
                  value={this.state.phone_number}
                  onChange={this.handleChange}
                />
                <br></br>
                <label htmlFor="email_address">
                  <strong>Email Address*</strong>
                </label>
                <input
                  type="text"
                  id="email_address"
                  className="form-control"
                  spellCheck="false"
                  maxLength={255}
                  value={this.state.email_address}
                  onChange={this.handleChange}
                />
                <br></br>
                <label htmlFor="comments">
                  <strong>Comments*</strong>
                </label>
                <textarea
                  rows="5"
                  id="comments"
                  className="form-control"
                  spellCheck="false"
                  value={this.state.comments}
                  onChange={this.handleChange}
                ></textarea>
                <br></br>
                <Button
                  onClick={this.commitToDBClick}
                  disabled={this.state.ubiq_id !== 0}
                >
                  Commit to DB
                </Button>
              </div>
              <div className="col-md-4">
                <h2>Encrypted (Values in DB)</h2>
                <label htmlFor="encrypted_first_name">
                  <strong>Legal First Name</strong>
                </label>
                <input
                  type="text"
                  id="encrypted_first_name"
                  className="form-control"
                  spellCheck="false"
                  maxLength={35}
                  autoComplete="new-password"
                  value={this.state.encrypted_first_name}
                  onChange={this.handleChange}
                  readOnly={true}
                />
                <br></br>
                <label htmlFor="encrypted_last_name">
                  <strong>Legal Last Name</strong>
                </label>
                <input
                  type="text"
                  id="encrypted_last_name"
                  className="form-control"
                  spellCheck="false"
                  maxLength={35}
                  autoComplete="new-password"
                  value={this.state.encrypted_last_name}
                  onChange={this.handleChange}
                  readOnly={true}
                />
                <br></br>
                <label htmlFor="encrypted_ssn">
                  <strong>Social Security Number</strong>
                </label>
                <input
                  type="text"
                  id="encrypted_ssn"
                  className="form-control"
                  spellCheck="false"
                  maxLength={11}
                  autoComplete="new-password"
                  value={this.state.encrypted_ssn}
                  onChange={this.handleChange}
                  readOnly={true}
                />
                <br></br>
                <label htmlFor="encrypted_phone_number">
                  <strong>Telephone Number</strong>
                </label>
                <input
                  type="text"
                  id="encrypted_phone_number"
                  className="form-control"
                  spellCheck="false"
                  maxLength={14}
                  autoComplete="new-password"
                  value={this.state.encrypted_phone_number}
                  onChange={this.handleChange}
                  readOnly={true}
                />
                <br></br>
                <label htmlFor="encrypted_email_address">
                  <strong>Email Address</strong>
                </label>
                <input
                  type="text"
                  id="encrypted_email_address"
                  className="form-control"
                  spellCheck="false"
                  maxLength={255}
                  autoComplete="new-password"
                  value={this.state.encrypted_email_address}
                  onChange={this.handleChange}
                  readOnly={true}
                />
                <br></br>
                <label htmlFor="encrypted_comments">
                  <strong>Comments</strong>
                </label>
                <textarea
                  rows="5"
                  id="encrypted_comments"
                  className="form-control"
                  autoComplete="new-password"
                  value={this.state.encrypted_comments}
                  onChange={this.handleChange}
                  readOnly={true}
                ></textarea>
                <br></br>
                <Button
                  onClick={this.decryptClick}
                  disabled={this.state.encrypted_comments === ""}
                >
                  Decrypt
                </Button>
              </div>
              <div className="col-md-4">
                <h2>Decrypted Values</h2>
                <label htmlFor="decrypted_first_name">
                  <strong>Legal First Name</strong>
                </label>
                <input
                  type="text"
                  id="decrypted_first_name"
                  className="form-control"
                  spellCheck="false"
                  maxLength={35}
                  autoComplete="new-password"
                  value={this.state.decrypted_first_name}
                  onChange={this.handleChange}
                  readOnly={true}
                />
                <br></br>
                <label htmlFor="decrypted_last_name">
                  <strong>Legal Last Name</strong>
                </label>
                <input
                  type="text"
                  id="decrypted_last_name"
                  className="form-control"
                  spellCheck="false"
                  maxLength={35}
                  autoComplete="new-password"
                  value={this.state.decrypted_last_name}
                  onChange={this.handleChange}
                  readOnly={true}
                />
                <br></br>
                <label htmlFor="decrypted_ssn">
                  <strong>Social Security Number</strong>
                </label>
                <input
                  type="text"
                  id="decrypted_ssn"
                  className="form-control"
                  spellCheck="false"
                  maxLength={11}
                  autoComplete="new-password"
                  value={this.state.decrypted_ssn}
                  onChange={this.handleChange}
                  readOnly={true}
                />
                <br></br>
                <label htmlFor="decrypted_phone_number">
                  <strong>Telephone Number</strong>
                </label>
                <input
                  type="text"
                  id="decrypted_phone_number"
                  className="form-control"
                  spellCheck="false"
                  maxLength={14}
                  autoComplete="new-password"
                  value={this.state.decrypted_phone_number}
                  onChange={this.handleChange}
                  readOnly={true}
                />
                <br></br>
                <label htmlFor="decrypted_email_address">
                  <strong>Email Address</strong>
                </label>
                <input
                  type="text"
                  id="decrypted_email_address"
                  className="form-control"
                  spellCheck="false"
                  maxLength={255}
                  autoComplete="new-password"
                  value={this.state.decrypted_email_address}
                  onChange={this.handleChange}
                  readOnly={true}
                />
                <br></br>
                <label htmlFor="decrypted_comments">
                  <strong>Comments</strong>
                </label>
                <textarea
                  rows="5"
                  id="decrypted_comments"
                  className="form-control"
                  autoComplete="new-password"
                  value={this.state.decrypted_comments}
                  onChange={this.handleChange}
                  readOnly={true}
                ></textarea>
                <br></br>
                <Button onClick={this.clearClick}>Clear</Button>
              </div>
            </div>
          </div>
          <div className="container">
            <Footer />
          </div>
        </>
      </div>
    );
  }
}

export default withOktaAuth(UBIQ);
