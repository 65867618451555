/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";
import { Button } from "react-blueprint-library";
import { useDispatch } from "react-redux";
import BackButtonModal from "../../../../../Forms/BackButtonModal";
import { logoutActions } from "../../../../../../../store/actions";
import InputMask from "react-input-mask";
import "./ArbitrationCFTC.scss";
import {
  exactExplanationOptions,
  dispositionStatusOptions,
  yesNoOptions,
} from "../constants";
import { Dropdown, DropdownButton } from "react-bootstrap";
import {
  clickFunc,
  dateValidator,
  formatMoney,
  onlyNumeric,
  pasteCommaDelimited,
} from "../../../../../../../common/Functions";

const CustomerComplaintAddDRPArbitrationCFTCForm = ({
  updateCCBForm,
  backFormId,
  nextFormId,
  tempDRP,
  updateTempCustComplDRP,
}) => {
  const dispatch = useDispatch();
  const [showBackModal, setShowBackModal] = useState(false);
  /********************************
   * Initialize User Input States *
   ********************************/
  const [arbReparationClaim, setArbReparationClaim] = useState(
    tempDRP?.arbReparationClaim || ""
  );
  const [arbDocketOrCase, setArbDocketOrCase] = useState(
    tempDRP?.arbDocketOrCase || ""
  );
  const [arbNoticeServedDate, setArbNoticeServedDate] = useState(
    tempDRP?.arbNoticeServedDate || ""
  );
  const [arbNoticeServedDateExplanation, setArbNoticeServedDateExplanation] =
    useState(tempDRP?.arbNoticeServedDateExplanation || "");
  const [arbNoticeServedDateRadio, setArbNoticeServedDateRadio] = useState(
    tempDRP?.arbNoticeServedDateRadio || ""
  );
  const [arbIsPending, setArbIsPending] = useState(tempDRP?.arbIsPending || "");
  const [arbDispositionDate, setArbDispositionDate] = useState(
    tempDRP?.arbDispositionDate || ""
  );
  const [arbDispositionDateRadio, setArbDispositionDateRadio] = useState(
    tempDRP?.arbDispositionDateRadio || ""
  );
  const [arbDispositionDateExplanation, setArbDispositionDateExplanation] =
    useState(tempDRP?.arbDispositionDateExplanation || "");
  const [arbStatusOption, setArbStatusOption] = useState(
    tempDRP?.arbStatusOption || ""
  );
  const [arbStatusOptionExplanation, setArbStatusOptionExplanation] = useState(
    tempDRP?.arbStatusOptionExplanation || ""
  );
  const [arbMonetaryComp, setArbMonetaryComp] = useState(
    tempDRP?.arbMonetaryComp || ""
  );
  const [arbContribAmount, setArbContribAmount] = useState(
    tempDRP?.arbContribAmount || ""
  );

  /*********************************
   * Initialize Input Error States *
   *********************************/
  const [arbReparationClaimError, setArbReparationClaimError] = useState(false);
  const [arbDocketOrCaseError, setArbDocketOrCaseError] = useState(false);
  const [arbNoticeServedDateError, setArbNoticeServedDateError] =
    useState(false);
  const [
    arbNoticeServedDateExplanationError,
    setArbNoticeServedDateExplanationError,
  ] = useState(false);
  const [arbNoticeServedDateErrorMsg, setArbNoticeServedDateErrorMsg] =
    useState("");
  const [arbNoticeServedDateRadioError, setArbNoticeServedDateRadioError] =
    useState(false);
  const [arbDispositionDateError, setArbDispositionDateError] = useState(false);
  const [arbDispositionDateErrorMsg, setArbDispositionDateErrorMsg] =
    useState("");
  const [arbDispositionDateRadioError, setArbDispositionDateRadioError] =
    useState(false);
  const [
    arbDispositionDateExplanationError,
    setArbDispositionDateExplanationError,
  ] = useState(false);
  const [arbStatusOptionError, setArbStatusOptionError] = useState(false);
  const [arbStatusOptionExplanationError, setArbStatusOptionExplanationError] =
    useState(false);
  const [arbMonetaryCompError, setArbMonetaryCompError] = useState(false);
  const [arbMonetaryCompErrorMsg, setArbMonetaryCompErrorMsg] = useState("");
  const [arbContribAmountError, setArbContribAmountError] = useState(false);
  const [arbContribAmountErrorMsg, setArbContribAmountErrorMsg] = useState("");

  /*************************************************************************
   * isError & isNextBtnDisabled are used to determine if user can proceed *
   *************************************************************************/
  const isError =
    arbReparationClaimError ||
    arbDocketOrCaseError ||
    arbNoticeServedDateError ||
    arbNoticeServedDateExplanationError ||
    arbNoticeServedDateRadioError ||
    arbDispositionDateError ||
    arbDispositionDateRadioError ||
    arbDispositionDateExplanationError ||
    arbStatusOptionError ||
    arbStatusOptionExplanationError ||
    arbMonetaryCompError ||
    arbContribAmountError;

  const isNextBtnDisabled =
    arbReparationClaim?.length < 2 ||
    arbDocketOrCase?.length < 2 ||
    arbNoticeServedDate === "" ||
    arbNoticeServedDate.includes("_") ||
    dateValidator(arbNoticeServedDate).isInvalid ||
    arbNoticeServedDateRadio?.length < 2 ||
    (arbNoticeServedDateRadio === exactExplanationOptions.EXPLANATION &&
      arbNoticeServedDateExplanation?.length < 2) ||
    arbIsPending === "" ||
    (arbIsPending === yesNoOptions.NO &&
      (arbStatusOption === "" ||
        ((arbStatusOption === "Settled" || arbStatusOption.includes("Award")) &&
          arbStatusOption !== "Other" &&
          (arbMonetaryComp?.length < 1 || arbContribAmount?.length < 1)) ||
        (arbStatusOption === "Other" &&
          arbStatusOptionExplanation?.length < 2))) ||
    arbDispositionDate === "" ||
    arbDispositionDate.includes("_") ||
    dateValidator(arbDispositionDate).isInvalid ||
    arbDispositionDateRadio?.length < 2 ||
    (arbDispositionDateRadio === exactExplanationOptions.EXPLANATION &&
      arbDispositionDateExplanation?.length < 2) ||
    isError;

  const handleCloseBackModal = () => setShowBackModal(false);

  const isUpdates =
    arbReparationClaim?.length ||
    arbDocketOrCase?.length ||
    arbNoticeServedDate?.length ||
    arbNoticeServedDateRadio?.length ||
    arbNoticeServedDateExplanation?.length ||
    arbIsPending?.length ||
    arbDispositionDate?.length ||
    arbDispositionDateRadio?.length ||
    isError;

  useEffect(() => {
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: true }));
  }, []);

  const handleShowBackModal = () => {
    if (isUpdates) {
      setShowBackModal(true);
    } else {
      updateCCBForm(backFormId);
    }
  };

  const handleBackClick = () => {
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: false }));
    updateCCBForm(backFormId);
    handleCloseBackModal();
  };

  const handleNext = () => {
    const submitData = {
      ...tempDRP,
      arbReparationClaim,
      arbDocketOrCase,
      arbNoticeServedDate,
      arbNoticeServedDateRadio,
      arbNoticeServedDateExplanation,
      arbIsPending,
      arbStatusOption,
      arbMonetaryComp,
      arbContribAmount,
      arbStatusOptionExplanation,
      arbDispositionDate,
      arbDispositionDateRadio,
      arbDispositionDateExplanation,
    };

    updateTempCustComplDRP(submitData);
    updateCCBForm(nextFormId);
  };

  /***************************************************
   * onChangeTextInput: updates state with userInput *
   ***************************************************/
  const onChangeTextInput = ({ target: { id, value } }) => {
    switch (id) {
      case "arbReparationClaim":
        return setArbReparationClaim(value);
      case "arbDocketOrCase":
        return setArbDocketOrCase(value);
      case "arbNoticeServedDate":
        return setArbNoticeServedDate(value);
      case "arbNoticeServedDateExplanation":
        return setArbNoticeServedDateExplanation(value);
      case "arbDispositionDate":
        return setArbDispositionDate(value);
      case "arbDispositionDateExplanation":
        return setArbDispositionDateExplanation(value);
      case "arbStatusOptionExplanation":
        return setArbStatusOptionExplanation(value);

      default:
        break;
    }
  };

  const onChangeMoneyInput = ({ target: { id, value } }) => {
    const moneyValue = formatMoney(value);
    switch (id) {
      case "arbMonetaryComp":
        return setArbMonetaryComp(moneyValue);
      case "arbContribAmount":
        return setArbContribAmount(moneyValue);
      default:
        break;
    }
  };

  /******************************************
   * onBlurTextInput: validates text fields *
   ******************************************/
  const onBlurTextInput = ({ target: { id, value } }) => {
    const isInvalid = value?.trim()?.length < 2;
    switch (id) {
      case "arbReparationClaim":
        return setArbReparationClaimError(isInvalid);
      case "arbDocketOrCase":
        return setArbDocketOrCaseError(isInvalid);
      case "arbNoticeServedDateExplanation":
        return setArbNoticeServedDateExplanationError(isInvalid);
      case "arbDispositionDateExplanation":
        return setArbDispositionDateExplanationError(isInvalid);
      case "arbStatusOptionExplanation":
        return setArbStatusOptionExplanationError(isInvalid);

      default:
        break;
    }
  };
  /******************************************
   * onBlurDateInput: validates date fields *
   ******************************************/
  const onBlurDateInput = ({ target: { id, value } }) => {
    const { isInvalid, errMessage } = dateValidator(value);
    switch (id) {
      case "arbNoticeServedDate":
        setArbNoticeServedDateError(isInvalid);
        setArbNoticeServedDateErrorMsg(errMessage);
        break;
      case "arbDispositionDate":
        setArbDispositionDateError(isInvalid);
        setArbDispositionDateErrorMsg(errMessage);
        break;
      default:
        break;
    }
  };

  const onBlurMoneyInput = ({ target: { id, value } }) => {
    const isMoneyError =
      parseInt(value?.replaceAll(",", "")) > 100000000 || value?.length < 1;

    let moneyErrMessage;
    switch (id) {
      case "arbMonetaryComp":
        moneyErrMessage =
          parseInt(value?.replaceAll(",", "")) > 100000000
            ? "Please enter a valid number"
            : value?.length < 1
            ? "Please enter Monetary Compensation Details"
            : "";
        setArbMonetaryCompErrorMsg(moneyErrMessage);
        setArbMonetaryCompError(isMoneyError);
        break;
      case "arbContribAmount":
        moneyErrMessage =
          parseInt(value?.replaceAll(",", "")) > 100000000
            ? "Please enter a valid number"
            : value?.length < 1
            ? "Please enter your Contribution Amount"
            : "";
        setArbContribAmountErrorMsg(moneyErrMessage);
        setArbContribAmountError(isMoneyError);
        break;

      default:
        break;
    }
  };

  /************************
   * Radio Button Changes *
   ************************/
  const handleNoticeServedDateRadioCheckChange = ({ target: { value } }) => {
    if (value === exactExplanationOptions.EXACT) {
      setArbNoticeServedDateExplanation("");
      setArbNoticeServedDateExplanationError(false);
    }
    setArbNoticeServedDateRadioError(false);
    setArbNoticeServedDateRadio(value);
  };

  const handleIsPendingChangeChecked = ({ target: { value } }) => {
    if (value === "Yes") {
      setArbStatusOption("");
      setArbStatusOptionError(false);
      setArbMonetaryComp("");
      setArbMonetaryCompError(false);
      setArbContribAmount("");
      setArbContribAmountError(false);
    }
    setArbIsPending(value);
  };

  const handleDispositionDateRadioCheckChange = ({ target: { value } }) => {
    if (value === exactExplanationOptions.EXACT) {
      setArbDispositionDateExplanation("");
      setArbDispositionDateExplanationError(false);
    }
    setArbDispositionDateRadioError(false);
    setArbDispositionDateRadio(value);
  };

  const onChangeStatusOptionHandler = ({ target: { value } }) => {
    setArbContribAmount("");
    setArbContribAmountError(false);
    setArbMonetaryComp("");
    setArbMonetaryCompError(false);
    setArbStatusOptionExplanation("");
    setArbStatusOptionExplanationError(false);
    setArbStatusOption(value);
  };
  const onBlurArbStateHandler = ({ target: { value, innerText } }) => {
    return innerText === "Status"
      ? setArbStatusOptionError(true)
      : setArbStatusOptionError(false);
  };
  return (
    <div className="cust-complaint-arbitration-form-wrapper">
      <div className="row">
        <div className="col-md-9" style={{ minWidth: "430px" }}>
          <label
            className="customer-complaint-label"
            htmlFor="arbReparationClaim"
            id="arbReparationClaimLabelId"
          >
            Arbitration/CFTC reparation claim filed with (FINRA, AAA, CFTC,
            etc)*
          </label>
          <input
            type="text"
            id="arbReparationClaim"
            className={
              arbReparationClaimError
                ? "form-control form-control-15 is-invalid"
                : "form-control form-control-15"
            }
            maxLength={100}
            minLength={2}
            autoComplete="off"
            value={arbReparationClaim}
            onChange={onChangeTextInput}
            onBlur={onBlurTextInput}
          />
          <div id="arbReparationClaimError" className="invalid-feedback">
            Please enter a response
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-9" style={{ minWidth: "430px" }}>
          <label
            className="customer-complaint-label"
            htmlFor="arbDocketOrCase"
            id="arbDocketOrCaseLabelId"
          >
            Docket/Case #: *
          </label>
          <input
            type="text"
            id="arbDocketOrCase"
            className={
              arbDocketOrCaseError
                ? "form-control form-control-15 is-invalid"
                : "form-control form-control-15"
            }
            maxLength={100}
            minLength={2}
            autoComplete="off"
            value={arbDocketOrCase}
            onChange={onChangeTextInput}
            onBlur={onBlurTextInput}
          />
          <div id="arbDocketOrCaseError" className="invalid-feedback">
            Please enter Docket/Case #
          </div>
        </div>
      </div>
      <div className="row date-wrapper">
        <div className="col-md-5">
          <label
            className="customer-complaint-label"
            htmlFor="arbNoticeServedDate"
            id="arbNoticeServedDateLabelId"
          >
            Date notice/process was served: *
          </label>
          <InputMask
            id="arbNoticeServedDate"
            mask="99/99/9999"
            maskChar="_"
            className={
              arbNoticeServedDateError
                ? "form-control form-control-15 is-invalid"
                : "form-control form-control-15"
            }
            placeholder="MM/DD/YYYY"
            autoComplete="off"
            value={arbNoticeServedDate}
            onChange={onChangeTextInput}
            onBlur={onBlurDateInput}
          />
          <div id="custComplaintDateReceivedError" className="invalid-feedback">
            {arbNoticeServedDateErrorMsg}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="drp-radio-input-wrapper">
          <div className="col-12 copy-md-4">
            <div className="custom-control custom-radio">
              <input
                name="arbNoticeServedDateRadioExact"
                type="radio"
                id="arbNoticeServedDateRadioExact"
                className="custom-control-input"
                value={exactExplanationOptions.EXACT}
                checked={
                  arbNoticeServedDateRadio === exactExplanationOptions.EXACT
                }
                onChange={handleNoticeServedDateRadioCheckChange}
              />
              <label
                htmlFor="arbNoticeServedDateRadioExact"
                className="custom-control-label"
                id="arbNoticeServedDateRadioExactLabelId"
              >
                {exactExplanationOptions.EXACT} date
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                name="arbNoticeServedDateRadioExplanation"
                type="radio"
                id="arbNoticeServedDateRadioExplanation"
                className="custom-control-input"
                value={exactExplanationOptions.EXPLANATION}
                checked={
                  arbNoticeServedDateRadio ===
                  exactExplanationOptions.EXPLANATION
                }
                onChange={handleNoticeServedDateRadioCheckChange}
              />
              <label
                htmlFor="arbNoticeServedDateRadioExplanation"
                className="custom-control-label"
                id="arbNoticeServedDateRadioExplanationLabelId"
              >
                {exactExplanationOptions.EXPLANATION}
              </label>
            </div>
          </div>
        </div>
      </div>
      {arbNoticeServedDateRadio === exactExplanationOptions.EXPLANATION && (
        <div className="row" style={{ marginLeft: "35px" }}>
          <div className="col-md-9">
            <label
              className="customer-complaint-label"
              htmlFor="arbNoticeServedDateExplanation"
              id="arbNoticeServedDateExplanationLabelId"
            >
              If not exact, provide explanation *
            </label>
            <textarea
              type="text"
              id="arbNoticeServedDateExplanation"
              className={
                arbNoticeServedDateExplanationError
                  ? "form-control form-control-15 is-invalid"
                  : "form-control form-control-15"
              }
              maxLength={500}
              minLength={2}
              autoComplete="off"
              value={arbNoticeServedDateExplanation}
              onChange={onChangeTextInput}
              onBlur={onBlurTextInput}
            />

            <div
              id="arbNoticeServedDateExplanationError"
              className="invalid-feedback"
            >
              Please enter an Explanation
            </div>
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-md-12">
          <label className="customer-complaint-label" id="customerPendingText">
            Is the arbitration/CFTC reparation pending? *
          </label>
        </div>
      </div>
      <div className="row">
        <div className="drp-radio-input-wrapper">
          <div className="col-12 copy-md-4">
            <div className="custom-control custom-radio">
              <input
                name="arbIsPendingYes"
                type="radio"
                id="arbIsPendingYes"
                className="custom-control-input"
                value={yesNoOptions.YES}
                checked={arbIsPending === yesNoOptions.YES}
                onChange={handleIsPendingChangeChecked}
              />
              <label
                htmlFor="arbIsPendingYes"
                className="custom-control-label"
                id="arbIsPendingYesLabelId"
              >
                {yesNoOptions.YES}
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                name="arbIsPendingNo"
                type="radio"
                id="arbIsPendingNo"
                className="custom-control-input"
                value={yesNoOptions.NO}
                checked={arbIsPending === yesNoOptions.NO}
                onChange={handleIsPendingChangeChecked}
              />
              <label
                htmlFor="arbIsPendingNo"
                className="custom-control-label"
                id="arbIsPendingNoLabelId"
              >
                {yesNoOptions.NO}
              </label>
            </div>
          </div>
        </div>
      </div>
      {arbIsPending === yesNoOptions.NO && (
        <>
          <div className="row" style={{ marginLeft: "35px" }}>
            <div className="col-md-9" style={{ minWidth: "430px" }}>
              <label
                className="customer-complaint-label"
                htmlFor="arbStatusOption"
                id="arbStatusOptionLabelId"
              >
                If the arbitration/CFTC reparation is not pending, what was the
                disposition? *
              </label>
              <div>
                <DropdownButton
                  id="arbStatusOption"
                  type="default"
                  variant="secondary"
                  title={arbStatusOption === "" ? "Status" : arbStatusOption}
                  className={
                    arbStatusOptionError
                      ? `is-invalid`
                      : arbStatusOption === ""
                      ? "status-placeholder"
                      : ""
                  }
                  selected={false}
                  autoComplete="new-address"
                  onSelect={(key, event) => {
                    event.target.id = "arbStatusOption";
                    event.target.value = key;
                    onChangeStatusOptionHandler(event);
                  }}
                  onBlur={onBlurArbStateHandler}
                  onClick={clickFunc}
                >
                  {dispositionStatusOptions.map(({ option }, key) => {
                    return (
                      <Dropdown.Item
                        key={key}
                        eventKey={option}
                        id={`arbStatusOption${key}`}
                      >
                        {option}
                      </Dropdown.Item>
                    );
                  })}
                </DropdownButton>
              </div>
              <div
                id="arbStatusOptionError"
                className={
                  arbStatusOptionError
                    ? "show invalid-feedback"
                    : "invalid-feedback"
                }
              >
                Please select a response
              </div>
            </div>
          </div>
          {arbStatusOption === "Other" && (
            <div className="row" style={{ marginLeft: "35px" }}>
              <div className="col-md-9">
                <label
                  className="customer-complaint-label"
                  htmlFor="arbStatusOptionExplanation"
                  id="arbStatusOptionExplanationLabelId"
                >
                  Please provide explanation *
                </label>
                <textarea
                  type="text"
                  id="arbStatusOptionExplanation"
                  className={
                    arbStatusOptionExplanationError
                      ? "form-control form-control-15 is-invalid"
                      : "form-control form-control-15"
                  }
                  maxLength={500}
                  minLength={2}
                  autoComplete="off"
                  value={arbStatusOptionExplanation}
                  onChange={onChangeTextInput}
                  onBlur={onBlurTextInput}
                />
                <div
                  id="arbStatusOptionExplanationError"
                  className="invalid-feedback"
                >
                  Please enter a response
                </div>
              </div>
            </div>
          )}

          {arbStatusOption !== "Other" &&
            (arbStatusOption === "Settled" ||
              arbStatusOption.includes("Award")) && (
              <>
                <div className="row" style={{ marginLeft: "35px" }}>
                  <div className="col-md-10">
                    <label
                      className="customer-complaint-label"
                      htmlFor="arbMonetaryComp"
                      id="arbMonetaryCompLabelId"
                    >
                      Monetary Compensation Details (award settlement,
                      reparation amount) *
                    </label>
                  </div>
                </div>
                <div className="row" style={{ marginLeft: "35px" }}>
                  <div className="col-md-5">
                    <div
                      className={
                        arbMonetaryCompError
                          ? "input-group is-invalid"
                          : "input-group"
                      }
                      id="alleged_damage_amount_input"
                    >
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text input-group-text-pad-right"
                          id="alleged_damage_amount_input_$"
                        >
                          $
                        </span>
                      </div>
                      <input
                        type="text"
                        id="arbMonetaryComp"
                        className="form-control form-control-15"
                        maxLength={11}
                        value={arbMonetaryComp}
                        onChange={onChangeMoneyInput}
                        onBlur={onBlurMoneyInput}
                        onKeyPress={onlyNumeric}
                        onPaste={pasteCommaDelimited}
                        autoComplete="off"
                      />
                    </div>
                    <div
                      id="arbMonetaryCompError"
                      className={
                        arbMonetaryCompError
                          ? "show invalid-feedback"
                          : "invalid-feedback"
                      }
                    >
                      {arbMonetaryCompErrorMsg}
                    </div>
                  </div>
                </div>
                <div className="row" style={{ marginLeft: "35px" }}>
                  <div className="col-md-5">
                    <label
                      className="customer-complaint-label"
                      htmlFor="arbContribAmount"
                      id="arbContribAmountLabelId"
                    >
                      Your Contribution Amount *
                    </label>
                  </div>
                </div>
                <div className="row" style={{ marginLeft: "35px" }}>
                  <div className="col-md-5">
                    <div
                      className={
                        arbContribAmountError
                          ? "input-group is-invalid"
                          : "input-group"
                      }
                      id="alleged_damage_amount_input"
                    >
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text input-group-text-pad-right"
                          id="alleged_damage_amount_input_$"
                        >
                          $
                        </span>
                      </div>
                      <input
                        type="text"
                        id="arbContribAmount"
                        className="form-control form-control-15"
                        maxLength={11}
                        value={arbContribAmount}
                        onChange={onChangeMoneyInput}
                        onBlur={onBlurMoneyInput}
                        onKeyPress={onlyNumeric}
                        onPaste={pasteCommaDelimited}
                        autoComplete="off"
                      />
                    </div>
                    <div
                      id="arbContribAmountError"
                      className={
                        arbContribAmountError
                          ? "show invalid-feedback"
                          : "invalid-feedback"
                      }
                    >
                      {arbContribAmountErrorMsg}
                    </div>
                  </div>
                </div>
              </>
            )}
        </>
      )}

      <div className="row date-wrapper">
        <div className="col-md-9">
          <label
            className="customer-complaint-label"
            htmlFor="arbDispositionDate"
            id="arbDispositionDateLabelId"
          >
            Disposition Date: *
          </label>
          <InputMask
            id="arbDispositionDate"
            mask="99/99/9999"
            maskChar="_"
            className={
              arbDispositionDateError
                ? "form-control form-control-15 is-invalid"
                : "form-control form-control-15"
            }
            placeholder="MM/DD/YYYY"
            autoComplete="off"
            value={arbDispositionDate}
            onChange={onChangeTextInput}
            onBlur={onBlurDateInput}
          />
          <div id="arbDispositionDateError" className="invalid-feedback">
            {arbDispositionDateErrorMsg}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="drp-radio-input-wrapper">
          <div className="col-12 copy-md-4">
            <div className="custom-control custom-radio">
              <input
                name="arbDispositionDateRadioExact"
                type="radio"
                id="arbDispositionDateRadioExact"
                className="custom-control-input"
                value={exactExplanationOptions.EXACT}
                checked={
                  arbDispositionDateRadio === exactExplanationOptions.EXACT
                }
                onChange={handleDispositionDateRadioCheckChange}
              />
              <label
                htmlFor="arbDispositionDateRadioExact"
                className="custom-control-label"
                id="arbDispositionDateRadioExactLabelId"
              >
                {exactExplanationOptions.EXACT} date
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                name="arbDispositionDateRadioExplanation"
                type="radio"
                id="arbDispositionDateRadioExplanation"
                className="custom-control-input"
                value={exactExplanationOptions.EXPLANATION}
                checked={
                  arbDispositionDateRadio ===
                  exactExplanationOptions.EXPLANATION
                }
                onChange={handleDispositionDateRadioCheckChange}
              />
              <label
                htmlFor="arbDispositionDateRadioExplanation"
                className="custom-control-label"
                id="arbDispositionDateRadioExplanationLabelId"
              >
                {exactExplanationOptions.EXPLANATION}
              </label>
            </div>
          </div>
        </div>
      </div>
      {arbDispositionDateRadio === exactExplanationOptions.EXPLANATION && (
        <div className="row" style={{ marginLeft: "35px" }}>
          <div className="col-md-9">
            <label
              className="customer-complaint-label"
              htmlFor="arbDispositionDateExplanation"
              id="arbDispositionDateExplanationLabelId"
            >
              If not exact, provide explanation *
            </label>
            <textarea
              type="text"
              id="arbDispositionDateExplanation"
              className={
                arbDispositionDateExplanationError
                  ? "form-control form-control-15 is-invalid"
                  : "form-control form-control-15"
              }
              maxLength={500}
              minLength={2}
              autoComplete="off"
              value={arbDispositionDateExplanation}
              onChange={onChangeTextInput}
              onBlur={onBlurTextInput}
            />
            <div
              id="arbDispositionDateExplanationError"
              className="invalid-feedback"
            >
              Please enter an Explanation
            </div>
          </div>
        </div>
      )}
      <div className="backbutton-drp-arb row">
        <div className="col-md-12 buttons">
          <span onClick={handleShowBackModal}>
            <strong id="crimDisclBack">Back</strong>
          </span>
          <Button
            label="Next"
            onClick={handleNext}
            disabled={!!isNextBtnDisabled}
          />
          {false && <span className="mm-dots-loading"></span>} {/* loading */}
        </div>
      </div>

      <BackButtonModal
        isOpen={showBackModal}
        closeBackModal={handleCloseBackModal}
        backClick={handleBackClick}
      />
    </div>
  );
};

export default CustomerComplaintAddDRPArbitrationCFTCForm;
