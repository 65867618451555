/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from "react";
import "../../../assets/stylesheets/ApplicationConfirmation.scss";
import { Button } from "react-blueprint-library";
import StartApplicationHeader from "../../StartApplication/StartApplicationHeader";
import Footer from "../../SharedComponents/Footer";
import {
  getContract,
  getBIGFICRADisclosure,
  getStartAppDashboard,
  getMMLISAddOnContract,
} from "../../../api/makeAPIRequest";
import { useOktaAuth } from "@okta/okta-react";
import { withLDProvider, useFlags } from "launchdarkly-react-client-sdk";
import Loader from "../../SharedComponents/Loader";
import OOSPage from "../OOSPage";
import moment from "moment";
import { CONTRACT_TYPE_DESCRIPTIONS } from "../../../constants/Contract";

function getDate() {
  const currentDate = moment().format("MMM. D, YYYY, h:mm a");
  return currentDate;
}

function ApplicationConfirmation() {
  //OKTA
  const {
    authState: {
      idToken: {
        claims: { name, sub: oktasubid },
      },
    },
  } = useOktaAuth();
  const can_name = name.split(", ");

  //LAUNCH DARKLY FLAGS
  const { candidateGlobalOos } = useFlags();

  //SET STATE
  const [agencynumber, updateAgencyNumber] = useState("");
  const [agencyphonenumber, updateAgencyPhoneNumber] = useState("");
  const [contracttypedesc, updateContractTypeDesc] = useState("");
  const [mmlis, updateMmlis] = useState(false);
  const [financing, updateFinancing] = useState(false);
  const [candidate_endorsed_contract_url, updateContractURL] = useState("");
  const [background_disclosures_url, updateDisclosuresURL] = useState("");
  const [mmlis_add_on_url, updateMMLISAddOnUrl] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const baltoURL = window.REACT_APP_CANDIDATE_BALTO_URL;
    const script = document.createElement("script");
    script.id = "baltoApp";
    script.src = baltoURL;
    script.async = true;

    document.head.appendChild(script);

    const queryParams = new URLSearchParams(window.location.search);
    const contract = queryParams.get("contract");
    if (contract) {
      loadData(parseInt(contract));
    } else {
      window.location = "/";
    }
  }, []);

  const loadData = async (contract) => {
    setLoading(true);

    const contractData = await getStartAppDashboard(oktasubid, contract);
    await getContractValues(contractData);

    const loadedData = contractData?.mmlis
      ? await Promise.all([
          getContract(oktasubid, contract),
          getBIGFICRADisclosure(oktasubid, contract),
          getMMLISAddOnContract(oktasubid, contract),
        ])
      : await Promise.all([
          getContract(oktasubid, contract),
          getBIGFICRADisclosure(oktasubid, contract),
        ]);
    await setPDFs(loadedData[0], loadedData[1], loadedData[2]);
    setLoading(false);
  };
  const setPDFs = async (contractPDF, disclosurePDF, mmlisAddOnPdf) => {
    try {
      if (typeof contractPDF !== "string" && contractPDF.byteLength > 10000) {
        const contractURL = URL.createObjectURL(
          new Blob([contractPDF], { type: "application/pdf" })
        );
        updateContractURL(contractURL);
      }
    } catch (error) {
      console.log(error);
    }
    try {
      if (
        typeof disclosurePDF !== "string" &&
        disclosurePDF.byteLength > 10000
      ) {
        const disclosureURL = URL.createObjectURL(
          new Blob([disclosurePDF], { type: "application/pdf" })
        );
        updateDisclosuresURL(disclosureURL);
      }
    } catch (error) {
      console.log(error);
    }

    if (mmlisAddOnPdf) {
      try {
        if (
          mmlisAddOnPdf &&
          typeof mmlisAddOnPdf !== "string" &&
          mmlisAddOnPdf.byteLength > 10000
        ) {
          const mmlisAddOnUrl = URL.createObjectURL(
            new Blob([mmlisAddOnPdf], { type: "application/pdf" })
          );
          updateMMLISAddOnUrl(mmlisAddOnUrl);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const getContractValues = async (contractData) => {
    updateAgencyNumber(contractData?.agencynumber || "");
    updateAgencyPhoneNumber(contractData?.phonenumber || "");
    updateContractTypeDesc(contractData.contracttypedesc || "");
    updateMmlis(contractData?.mmlis);
    updateFinancing(contractData?.financingonly);
  };

  //OPEN CONTRACT FORMS
  const openContract = () => {
    try {
      if (candidate_endorsed_contract_url !== "")
        window.open(candidate_endorsed_contract_url, "_blank");
    } catch (e) {
      console.log(e);
    }
  };
  const openDisclosure = () => {
    try {
      if (background_disclosures_url !== "")
        window.open(background_disclosures_url, "_blank");
    } catch (e) {
      console.log(e);
    }
  };
  const openMMLISAddOn = () => {
    try {
      if (mmlis_add_on_url !== "") window.open(mmlis_add_on_url, "_blank");
    } catch (e) {
      console.log(e);
    }
  };
  const openConsumerNotificationRights = () => {
    try {
      window.open(
        window.REACT_APP_CONSUMER_AND_NOTIFICATION_RIGHTS_URL,
        "_blank"
      );
    } catch (e) {
      console.log(e);
    }
  };
  const downloadAll = async () => {
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const delay = (ms) => new Promise((res) => setTimeout(res, ms));
    var link = document.createElement("a");

    if (
      candidate_endorsed_contract_url !== "" &&
      contracttypedesc !== CONTRACT_TYPE_DESCRIPTIONS.MEMBER_CORP
    ) {
      link.download = `${can_name[1]}_${can_name[0]}_Endorsed_Contract.pdf`;
      link.href = candidate_endorsed_contract_url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    //Add Delay to Safari because Safari does not allow multiple file download
    if (isSafari) {
      await delay(750);
    }

    if (background_disclosures_url !== "") {
      link.download = `${can_name[1]}_${can_name[0]}_Endorsed_BIG_FICRA_Disclosure.pdf`;
      link.href = background_disclosures_url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    //Add Delay to Safari because Safari does not allow multiple file download
    if (isSafari) {
      await delay(750);
    }
    if (mmlis) {
      if (mmlis_add_on_url !== "") {
        link.download = `${can_name[1]}_${can_name[0]}_MMLIS_Add_On_Contract.pdf`;
        link.href = mmlis_add_on_url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      //Add Delay to Safari because Safari does not allow multiple file download
      if (isSafari) {
        await delay(750);
      }
    }

    if (
      window.REACT_APP_CONSUMER_AND_NOTIFICATION_RIGHTS_URL !== null &&
      typeof window.REACT_APP_CONSUMER_AND_NOTIFICATION_RIGHTS_URL !==
        "undefined" &&
      window.REACT_APP_CONSUMER_AND_NOTIFICATION_RIGHTS_URL !== ""
    ) {
      link.setAttribute("download", "");
      link.href = window.REACT_APP_CONSUMER_AND_NOTIFICATION_RIGHTS_URL;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  //RETURN TO DASHBOARD
  const returnToDashboard = () => {
    window.location = "/";
  };

  return (
    <>
      {candidateGlobalOos === true && <OOSPage />}
      {(candidateGlobalOos === false ||
        typeof candidateGlobalOos === "undefined") && (
        <div id="appconfirmation">
          <Loader customClass={loading ? "" : "hidden"} />

          <StartApplicationHeader />
          <div className="container">
            <h1 id="confirmation-heading">
              <span className="mm-illustration-buffer illustration-confetti"></span>
              Confirmation
            </h1>
            <p id="confirmation-heading-text">
              Thank you! Your application, contract and disclosures have been
              submitted. As a reminder, contracts will not become effective
              until they are executed by the appropriate parties. If you need
              assistance or have any outstanding questions, please contact your
              Agency Licensing Coordinator or Agency Supervisory Officer at
              {` Agency #${agencynumber}`} at {agencyphonenumber}.
            </p>
            <hr></hr>
            {loading ? (
              <div className="mm-skeleton--tall" style={{ height: "376px" }}>
                <span className="sr-only" aria-busy="true" aria-live="polite">
                  Content is loading
                </span>
              </div>
            ) : (
              <div id="contract-info">
                <div>
                  <strong id="confirmation-contract-label">
                    <span>Contract: </span>
                  </strong>
                  <span id="confirmation-contract-value">
                    {contracttypedesc}
                  </span>
                </div>
                <div>
                  <strong id="confirmation-addon-label">
                    <span>Add-on Options: </span>
                  </strong>

                  <span id="confirmation-addon-value">
                    {mmlis === true && financing === true
                      ? "MMLIS, Financing"
                      : mmlis === true && financing === false
                      ? "MMLIS"
                      : mmlis === false && financing === true
                      ? "Financing"
                      : "None"}
                  </span>
                </div>
                <div>
                  <strong>
                    <span id="confirmation-submitted-label">Submitted: </span>
                  </strong>
                  <span id="confirmation-submitted-value">{getDate()}</span>
                </div>
                <div>
                  <strong id="confirmation-forms-label">
                    <span>Forms: </span>
                  </strong>
                </div>
                <div id="contract-forms">
                  {contracttypedesc !==
                    CONTRACT_TYPE_DESCRIPTIONS.MEMBER_CORP && (
                    <>
                      <Button
                        id="CandidateEndorsedContractBtn"
                        variant="link"
                        onClick={openContract}
                      >
                        Candidate Endorsed Contract
                      </Button>
                      <br />
                    </>
                  )}

                  <Button
                    id="BackgroundDisclosuresBtn"
                    variant="link"
                    onClick={openDisclosure}
                  >
                    Background Disclosures
                  </Button>
                  <br />
                  <Button
                    id="ConsumerNotificationRightsBtn"
                    variant="link"
                    onClick={openConsumerNotificationRights}
                  >
                    Consumer Notification and Rights
                  </Button>
                  {mmlis && (
                    <>
                      <br />
                      <Button
                        id="MMLISAddOnBtn"
                        variant="link"
                        onClick={openMMLISAddOn}
                      >
                        MMLIS Contract
                      </Button>
                    </>
                  )}
                </div>

                <div
                  id="DownloadAllBtn"
                  onClick={downloadAll}
                  className="col-2"
                >
                  <span className="icon-download"></span>
                  <span id="dnall"> Download All</span>
                </div>
              </div>
            )}

            <div id="return-to-dashboard-wrapper">
              <Button
                onClick={returnToDashboard}
                variant="primary"
                id="return-to-dashboard-button"
              >
                Return to Dashboard
              </Button>
            </div>
            <Footer />
          </div>
        </div>
      )}
    </>
  );
}

export default withLDProvider({
  clientSideID: window.REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
})(ApplicationConfirmation);
