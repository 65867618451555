import { FORM_ID } from "../../../../../constants/Forms";

function ReviewResAddress(props) {
  let resAddress = getResAddress(props.residentialAddress);

  const toggleForm = () => {
    props.toggleForm(FORM_ID.RESIDENTIAL_ADDRESS);
  };
  return (
    <div id="residential-address">
      <div className="row">
        <div className="col-8" id="resaddr-heading">
          <h4 id="ra">Residential Address</h4>
        </div>
        <div className="col-4" id="resaddr-edit">
          <span className="btn btn-link float-right" onClick={toggleForm}>
            Edit
          </span>
        </div>
      </div>

      <div className="row">
        <div className="col-3" id="add">
          <h5 id="adrs">Address</h5>
        </div>

        <div id="residential_address" className="col-9 data">
          {resAddress}
        </div>
      </div>
    </div>
  );
}

function getResAddress(residentialAddress) {
  let resAddress = "";
  const { address1, address2, city, state, zip, resurbanization } =
    residentialAddress;
  const isThereUrbanization =
    resurbanization !== undefined &&
    resurbanization !== "" &&
    resurbanization !== null;
  resAddress = `
    ${state === "PR" && isThereUrbanization ? `${resurbanization}, ` : ""}
    ${address1}, ${
    address2 === "" ? "" : `${address2}, `
  }${city}, ${state}, ${zip}`;

  return resAddress;
}

export default ReviewResAddress;
