import React, { Component, Fragment } from "react";
import { Card } from "react-blueprint-library";
import { Accordion, AccordionButton } from "react-bootstrap";
import AccordionBody from "react-bootstrap/esm/AccordionBody";

function formatValue(value) {
  if (value === null) {
    value = 0;
  }
  return value;
}
class PersonalSalesRecordFinancialPlans extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <Card>
            <Accordion>
              <div className="mm-collapse">
                <AccordionButton
                  id="psr_financial_plans"
                  className="btn-collapse"
                >
                  <table width="100%">
                    <tbody>
                      <tr className="psr-first-row">
                        <td width="3%">&nbsp;</td>
                        <td width="22%">
                          <strong className="footer-link psr-accordion">
                            <p>Financial Plans:</p>
                          </strong>
                        </td>
                        <td width="72%">
                          <strong className="footer-link psr-accordion">
                            <p>{this.props.data.totalFinPlans}</p>
                          </strong>
                        </td>
                        <td className="psr-chevron-padding">
                          <span className="indicator psr-chevron"></span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </AccordionButton>
                <AccordionBody>
                  <section className="row">
                    <div className="col-md-12 align-self-stretch">
                      <div style={{ width: "100%" }}>
                        <br></br>
                        <table width="100%" cellPadding={8} cellSpacing={8}>
                          <tbody>
                            <tr className="psr-row">
                              <td width="20%" className="psr-cell">
                                <strong>Financial Plans</strong>
                              </td>
                              {Array.from(this.props.financialData).map(
                                (psr, key) => {
                                  return (
                                    <Fragment key={key}>
                                      {key === 0 && (
                                        <td
                                          width="13%"
                                          className="psr-cell-center"
                                        >
                                          <strong>
                                            Current Year<br></br>
                                            {this.props.financialData[key][0]}
                                          </strong>
                                        </td>
                                      )}
                                      {key > 0 && (
                                        <td
                                          width="13%"
                                          className="psr-cell-center"
                                        >
                                          <strong>
                                            Year {key}
                                            <br></br>
                                            {this.props.financialData[key][0]}
                                          </strong>
                                        </td>
                                      )}
                                    </Fragment>
                                  );
                                }
                              )}
                            </tr>

                            <tr className="psr-row">
                              <td width="20%" className="psr-cell">
                                Number Sold
                              </td>
                              {Array.from(this.props.financialData).map(
                                (psr, key) => {
                                  return (
                                    <Fragment key={key}>
                                      <td
                                        width="13%"
                                        className="psr-cell-center"
                                      >
                                        {formatValue(
                                          this.props.financialData[key][1]
                                        )}
                                      </td>
                                    </Fragment>
                                  );
                                }
                              )}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </section>
                </AccordionBody>
              </div>
            </Accordion>
          </Card>
        </div>
      </div>
    );
  }
}

export default PersonalSalesRecordFinancialPlans;
