import { fetchWrapper } from "../../helpers";
import { RequestMethodTypes } from "../../helpers/constants";

export const insertBondDRP = async (oktasubid, data) => {
  const {
    data: { ccbdrpid },
  } = await fetchWrapper({
    method: RequestMethodTypes.PUT,
    url: `drp/${oktasubid}/bond`,
    data,
  });

  return ccbdrpid;
};

export const updateBondDRP = async (oktasubid, data) => {
  const { ccbdrpid } = await fetchWrapper({
    method: RequestMethodTypes.POST,
    url: `drp/${oktasubid}/bond`,
    data,
  });

  return ccbdrpid;
};

export const getBondDRP = async (oktasubid) => {
  const response = await fetchWrapper({
    method: RequestMethodTypes.GET,
    url: `drp/${oktasubid}/bond`,
  });

  return response;
};

export const deleteBondDRP = async (oktasubid, ccbdrpid) => {
  const response = await fetchWrapper({
    method: RequestMethodTypes.POST,
    url: `drp/${oktasubid}/deletebonddrp`,
    data: {
      ccbdrpid,
    },
  });

  return response;
};
