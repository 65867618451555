import React, { useEffect, useState } from "react";
import { groupArrayByKey } from "../../helpers";
import DRPCard from "../../common/DRPCard";

import "./TerminationDisclosureDRPSummary.scss";
import { CCB_FORM_ID, headerText } from "../constants";
import DRPSummaryListingCard from "../../common/DRPSummaryListingCard";
import { SECTION } from "../../constants";
import { putCCBStatus } from "../../../../../../store/middlewares";
import { useDispatch } from "react-redux";

const TerminationDisclosureDRPSummary = ({
  updateCCBForm,
  oktaSubId,
  termDiscl,
  getDRPList,
  terminationDisclosureDRPs,
  deleteTerminationDisclosureDRPs,
  editTerminationDisclosureDRPs,
  isDRPNextDisabled,
  setIsDRPNextDisabled,
  updateCCB14CompletedInd,
  loading,
  updateCCB14Step,
  setIsDRPCompleted,
}) => {
  const dispatch = useDispatch();
  const [groupedList, setGroupedList] = useState("");

  const func = () => {
    let list = getDRPList(terminationDisclosureDRPs);
    setGroupedList(groupArrayByKey(list, "questionKey"));
  };

  const existingTermDisclKeys = termDiscl?.flatMap((x) => x.key);
  const currTermDisclKeys = [
    ...new Set(
      terminationDisclosureDRPs?.flatMap((x) => x?.terminationdrp?.drpList)
    ),
  ];

  const isDisabled = currTermDisclKeys.length !== existingTermDisclKeys?.length;

  useEffect(() => {
    if (groupedList === "") {
      func();
    }
  }, []);

  const updateStatus = async (data) => {
    try {
      await dispatch(
        putCCBStatus({ oktaSubId, data: { terminationdisclstatus: data } })
      );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setIsDRPNextDisabled(isDRPNextDisabled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDRPNextDisabled]);

  useEffect(() => {
    if (!loading) {
      setIsDRPNextDisabled(isDisabled);

      if (!!isDisabled) {
        updateCCB14Step({ termDisclosureCompleteStep: "2/2" });
        updateCCB14CompletedInd({ termDisclosureCompleteInd: false });
        setIsDRPCompleted(false);
      } else {
        updateCCB14CompletedInd({ termDisclosureCompleteInd: true });
        setIsDRPCompleted(true);
      }

      updateStatus(isDisabled ? "1/2" : null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisabled, loading]);

  return (
    <div className="term-disclosure-drp-summary">
      {termDiscl?.length ? (
        <DRPCard
          header={headerText.TERM_DISCL}
          onClick={() => updateCCBForm(CCB_FORM_ID.ADD_DRP)}
        >
          {termDiscl?.map(({ key, mainQuestion, question }, index) => (
            <DRPSummaryListingCard
              key={`${index}_${key}`}
              questionKey={key}
              mainQuestion={mainQuestion}
              question={question}
              index={index}
              length={termDiscl?.length}
              list={groupedList[key]}
              isSummaryPage
              deleteDRP={deleteTerminationDisclosureDRPs}
              editDRP={editTerminationDisclosureDRPs}
              section={SECTION.terminationDisclosures}
              isLoading={loading}
            />
          ))}
        </DRPCard>
      ) : null}
    </div>
  );
};

export default TerminationDisclosureDRPSummary;
