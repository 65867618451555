export const initialState = {
  enjoined: null,
  foundinvolvedviolation: null,
  dismissedcivilaction: null,
  namedinpendingcivilaction: null,
};

const civJudAct = {
  mainQuestion: "Has any domestic or foreign court ever:",
  questionA: `(a) enjoined you in connection with any investment-related activity?`,
  questionB: `(b) found that you were involved in a violation of any investment-related statute(s) or regulation(s)?`,
  questionC: `(c) dismissed, pursuant to a settlement agreement, an investment-related civil action brought against you by a state or foreign financial regulatory authority?`,
};

const civJudActPend = {
  mainQuestion: civJudAct.mainQuestion,
  questionA: `Are you named in any pending investment-related civil action that could result in a "yes" answer to any part of 14H(1)?`,
};

export const DRPSummaryCivilJudicialActions = {
  enjoined: {
    key: "14H(1)(a)",
    mainQuestion: civJudAct.mainQuestion,
    question: civJudAct.questionA,
  },
  foundinvolvedviolation: {
    key: "14H(1)(b)",
    mainQuestion: civJudAct.mainQuestion,
    question: civJudAct.questionB,
  },
  dismissedcivilaction: {
    key: "14H(1)(c)",
    mainQuestion: civJudAct.mainQuestion,
    question: civJudAct.questionC,
  },
  namedinpendingcivilaction: {
    key: "14H(2)",
    mainQuestion: "",
    question: civJudActPend.questionA,
  },
};

export const civilJudicialActions = (disabled) => [
  {
    header: `14H (1). ${civJudAct.mainQuestion}`,
    subHeader: "",
    questionA: civJudAct.questionA,
    questionB: civJudAct.questionB,
    questionC: civJudAct.questionC,
    optionsA: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "enjoined-y",
      idTwo: "enjoined-n",
      name: "enjoined",
      disabled: disabled,
    },
    optionsB: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "foundinvolvedviolation-y",
      idTwo: "foundinvolvedviolation-n",
      name: "foundinvolvedviolation",
      disabled: disabled,
    },
    optionsC: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "dismissedcivilaction-y",
      idTwo: "dismissedcivilaction-n",
      name: "dismissedcivilaction",
      disabled: disabled,
    },
  },
  {
    header: "",
    subHeader: "(2) " + civJudActPend.questionA,
    questionA: ``,
    optionsA: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "namedinpendingcivilaction-y",
      idTwo: "namedinpendingcivilaction-n",
      name: "namedinpendingcivilaction",
      disabled: disabled,
    },
  },
];
