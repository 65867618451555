/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useCallback, useEffect, useState } from "react";
import { Button } from "react-blueprint-library";
import { useDispatch } from "react-redux";

import BackButtonModal from "../../../../../Forms/BackButtonModal";
import { logoutActions } from "../../../../../../../store/actions";

import "./CivJudSanctionDetailsForm.scss";
import CheckboxList from "../../../common/CheckboxList";
import {
  DRPCheckboxListValuesSanctionsOrderedReliefGranted,
  DRPSanctionsOrderedReliefGrantedCheckList,
} from "../../../constants";

import svgs, { svgType } from "../../../../../../../assets/svgs";

import AddNewInjunctionModal from "./AddNewInjunctionModal";
import AddNewInjunctionContent from "./AddNewInjunctionModal/AddNewInjunctionContent";
import NewInjunctionList from "../../../common/NewInjunctionList";

import AddNewMonetaryRelatedSanctionModal from "./AddNewMonetaryRelatedSanctionModal";
import AddNewMonetaryRelatedSanctionContent from "./AddNewMonetaryRelatedSanctionModal/AddNewMonetaryRelatedSanctionContent";
import NewMonetaryRelatedSanctionList from "../../../common/NewMonetaryRelatedSanctionList";

const initialEditSanctionState = { list: [], index: null };
const initialEditMonetaryRelatedSanctionState = { list: [], index: null };

const CivJudSanctionDetailsForm = ({
  updateCCBForm,
  backFormId,
  nextFormId,
  getSubmitDataValues,
  data,
  groupKey,
  headerText,
}) => {
  const dispatch = useDispatch();
  const [showBackModal, setShowBackModal] = useState(false);
  const [formHasChanged, setFormHasChanged] = useState(false);

  const [otherSanctionsDes, setOtherSanctionsDes] = useState(
    data?.otherSanctionsDes || ""
  );
  const [otherSanctionsDesError, setOtherSanctionsDesError] = useState(false);

  const [otherOrderedReliefGrantedText, setOtherOrderedReliefGrantedText] =
    useState(data?.otherOrderedReliefGrantedText || "");
  const [selectedOrderedReliefGranted, setSelectedOrderedReliefGranted] =
    useState(data?.selectedOrderedReliefGranted || []);
  const [
    otherOrderedReliefGrantedTextError,
    setOtherOrderedReliefGrantedTextError,
  ] = useState(false);

  const [showAddNewInjunctionModal, setShowAddNewInjunctionModal] =
    useState(false);
  const [editInjunctionDetailsList, setEditInjunctionDetailsList] = useState({
    ...initialEditSanctionState,
  });
  const [newInjunctionDetailsList, setNewInjunctionDetailsList] = useState(
    data?.newInjunctionDetailsList || []
  );
  const [isInjunctionModalUpdates, setIsInjunctionModalUpdates] =
    useState(false);

  const [
    showAddNewMonetaryRelatedSanctionModal,
    setShowAddNewMonetaryRelatedSanctionModal,
  ] = useState(false);
  const [
    newMonetaryRelatedSanctionDetailsList,
    setNewMonetaryRelatedSanctionDetailsList,
  ] = useState(data?.newMonetaryRelatedSanctionDetailsList || []);
  const [
    isMonetaryRelatedSanctionModalUpdates,
    setIsMonetaryRelatedSanctionModalUpdates,
  ] = useState(false);
  const [
    editMonetaryRelatedSanctionDetailsList,
    setEditMonetaryRelatedSanctionDetailsList,
  ] = useState({
    ...initialEditMonetaryRelatedSanctionState,
  });

  const handleCloseBackModal = () => setShowBackModal(false);

  const isError = otherSanctionsDesError;

  useEffect(() => {
    if (isError) {
      setFormHasChanged(true);
    }
  }, [isError]);

  const isUpdates = formHasChanged || isError;

  const isInj = !!selectedOrderedReliefGranted.find(
    (value) => value === DRPCheckboxListValuesSanctionsOrderedReliefGranted.INJ
  );

  const checkIsDisgOrRest = (v) =>
    [
      DRPCheckboxListValuesSanctionsOrderedReliefGranted.DISGORG,
      DRPCheckboxListValuesSanctionsOrderedReliefGranted.RESTITUTION,
      DRPCheckboxListValuesSanctionsOrderedReliefGranted.CIVIL_ADMIN,
    ].includes(v);

  const isDisgOrRest = !!selectedOrderedReliefGranted.find((value) =>
    checkIsDisgOrRest(value)
  );

  useEffect(() => {
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: true }));
  }, [isUpdates, dispatch]);

  const onChangeCheckedOrderedReliefGranted = ({
    target: { value, checked },
  }) => {
    setFormHasChanged(true);

    if (checked) {
      setSelectedOrderedReliefGranted((prev) => [...prev, value]);
    } else {
      let data = [];
      setSelectedOrderedReliefGranted((prev) => {
        data = [...prev.filter((d) => d !== value)];

        if (prev.includes(value) && !data.find((v) => checkIsDisgOrRest(v))) {
          setNewMonetaryRelatedSanctionDetailsList([]);
        }

        return data;
      });

      if (value === DRPCheckboxListValuesSanctionsOrderedReliefGranted.INJ) {
        setNewInjunctionDetailsList([]);
      }
    }
  };

  const onOtherOrderedReliefGrantedChangeHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setOtherOrderedReliefGrantedText(value);
  };

  const onBlurOrderedReliefGrantedOtherHandler = ({ target: { value } }) => {
    return value.trim().length < 2 || value.trim().length > 500
      ? setOtherOrderedReliefGrantedTextError(true)
      : setOtherOrderedReliefGrantedTextError(false);
  };

  const onOtherSanctionsDesChangeHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setOtherSanctionsDes(value);
  };
  const onBlurOtherSanctionsDesHandler = ({ target: { value } }) => {
    return value.trim().length >= 1 &&
      (value.trim().length < 2 || value.trim().length > 500)
      ? setOtherSanctionsDesError(true)
      : setOtherSanctionsDesError(false);
  };

  const getAddNewInjunctionValues = (val, index) => {
    const isEdit = index !== null;

    if (Object.values(val)) {
      if (isEdit) {
        setNewInjunctionDetailsList((prevState) => {
          let newState = prevState;

          newState[index] = val;

          return newState;
        });
      } else {
        setNewInjunctionDetailsList((prevState) => [...prevState, val]);
      }
    }
  };

  const getAddNewMonetaryRelatedSanctionValues = (val, index) => {
    const isEdit = index !== null;

    if (Object.values(val)) {
      if (isEdit) {
        setNewMonetaryRelatedSanctionDetailsList((prevState) => {
          let newState = prevState;

          newState[index] = val;

          return newState;
        });
      } else {
        setNewMonetaryRelatedSanctionDetailsList((prevState) => [
          ...prevState,
          val,
        ]);
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  const handleShowBackModal = () => {
    if (formHasChanged) {
      setShowBackModal(true);
    } else {
      if (
        (data !== null && data && !Object.keys(data).length) ||
        data === null
      ) {
        getSubmitDataValues({ [groupKey]: null }, headerText);
      }
      updateCCBForm(backFormId);
    }
  };

  const handleBackClick = () => {
    if ((data !== null && data && !Object.keys(data).length) || data === null) {
      getSubmitDataValues({ [groupKey]: null }, headerText);
    }
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: false }));
    updateCCBForm(backFormId);
    handleCloseBackModal();
  };

  const handleNext = useCallback(() => {
    const submitData = {
      selectedOrderedReliefGranted,
      otherSanctionsDes,
      newInjunctionDetailsList,
      newMonetaryRelatedSanctionDetailsList,
    };
    setFormHasChanged(false);
    getSubmitDataValues({ [`${groupKey}`]: submitData }, headerText);

    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: false }));

    updateCCBForm(nextFormId);
  }, [
    updateCCBForm,
    nextFormId,
    selectedOrderedReliefGranted,
    getSubmitDataValues,
    dispatch,
    groupKey,
    newInjunctionDetailsList,
    newMonetaryRelatedSanctionDetailsList,
    otherSanctionsDes,
    headerText,
  ]);

  const isNextBtnDisabled =
    !!(
      (otherSanctionsDes?.length && otherSanctionsDes?.length < 2) ||
      selectedOrderedReliefGranted?.filter((x) => typeof x === "string")
        ?.length === 0 ||
      (isInj && !newInjunctionDetailsList?.length) ||
      (isDisgOrRest && !newMonetaryRelatedSanctionDetailsList?.length)
    ) || isError;

  return (
    <div className="civ-jud-sanc-details-form-wrapper">
      <CheckboxList
        isScroll={false}
        selectedItems={selectedOrderedReliefGranted}
        list={DRPSanctionsOrderedReliefGrantedCheckList}
        header="Were any of the following Sanctions Ordered or Relief Granted? (select all that apply): *"
        onChangeChecked={onChangeCheckedOrderedReliefGranted}
        onOtherChangeHandler={onOtherOrderedReliefGrantedChangeHandler}
        onBlurOtherHandler={onBlurOrderedReliefGrantedOtherHandler}
        otherTextError={otherOrderedReliefGrantedTextError}
        otherText={otherOrderedReliefGrantedText}
      />

      <div className="crim-comments-container">
        <label
          htmlFor="otherSanctionsDes"
          className="comments-label"
          id="otherSanctionsDesLabelId"
        >
          Other sanctions: (optional)
        </label>
        <textarea
          id="otherSanctionsDes"
          onChange={onOtherSanctionsDesChangeHandler}
          onBlur={onBlurOtherSanctionsDesHandler}
          className={
            otherSanctionsDesError
              ? "form-control form-control-15 is-invalid"
              : "form-control form-control-15"
          }
          minLength={2}
          maxLength={500}
          rows="3"
          cols={500}
          value={otherSanctionsDes}
        />
        <div id="otherSanctionsDesError" className="invalid-feedback">
          Please enter a response
        </div>
      </div>

      {isInj && (
        <div className="sanc-list-container">
          <div className="sanc-list-container">
            <hr className="custom-hr" />

            <div className="row">
              <div
                className="add-new-charge-desctiption-label"
                id="injunctionsId"
              >
                Injunctions *
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <label
                  className="add-new-charge-label"
                  id="addNewInjunctionLabelId"
                >
                  If enjoined, provide at least one new “injunction” item below.
                </label>
              </div>
            </div>
          </div>

          {newInjunctionDetailsList.length ? (
            <div className="new-charges-list">
              <NewInjunctionList
                list={newInjunctionDetailsList}
                setNewChargesList={setNewInjunctionDetailsList}
                setEditChargeDetailsList={setEditInjunctionDetailsList}
                setShowAddNewChargeModal={setShowAddNewInjunctionModal}
              />
            </div>
          ) : null}

          <div className="add-drp-charge-btn-wrapper">
            <Button
              id="add-drp-charge"
              variant="secondary"
              className="add-drp-charge-btn"
              onClick={() => {
                setEditInjunctionDetailsList({ ...initialEditSanctionState });
                setShowAddNewInjunctionModal(true);
              }}
            >
              <div className="btn-content">
                <div className="plus-sign">
                  <span className="indicator">{svgs[svgType.iconAddDRP]}</span>
                </div>
                <div className="text-wrapper">
                  <strong id="add-new-injunction-text">
                    Add New Injunction
                  </strong>
                </div>
              </div>
            </Button>
          </div>
        </div>
      )}

      {isDisgOrRest && (
        <div className="sanc-list-container">
          <div className="sanc-list-container">
            <hr className="custom-hr" />

            <div className="row">
              <div className="add-new-charge-desctiption-label" id="monetaryId">
                Monetary Related Sanction *
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <label
                  className="add-new-charge-label"
                  id="addNewMonetaryLabelId"
                >
                  If disposition resulted in a fine, penalty, restitution,
                  disgorgement or monetary compensation, provide at least one
                  sanction details.
                </label>
              </div>
            </div>
          </div>

          {newMonetaryRelatedSanctionDetailsList.length ? (
            <div className="new-charges-list">
              <NewMonetaryRelatedSanctionList
                list={newMonetaryRelatedSanctionDetailsList}
                setNewChargesList={setNewMonetaryRelatedSanctionDetailsList}
                setEditChargeDetailsList={
                  setEditMonetaryRelatedSanctionDetailsList
                }
                setShowAddNewChargeModal={
                  setShowAddNewMonetaryRelatedSanctionModal
                }
              />
            </div>
          ) : null}

          <div className="add-drp-charge-btn-wrapper">
            <Button
              id="add-drp-charge"
              variant="secondary"
              className="add-drp-charge-btn"
              style={{ maxWidth: "315px" }}
              onClick={() => {
                setEditMonetaryRelatedSanctionDetailsList({
                  ...initialEditMonetaryRelatedSanctionState,
                });
                setShowAddNewMonetaryRelatedSanctionModal(true);
              }}
            >
              <div className="btn-content">
                <div className="plus-sign">
                  <span className="indicator">{svgs[svgType.iconAddDRP]}</span>
                </div>
                <div className="text-wrapper">
                  <strong id="add-new-monetary-text">
                    Add New Monetary Related Sanction
                  </strong>
                </div>
              </div>
            </Button>
          </div>
        </div>
      )}

      {showAddNewInjunctionModal && (
        <AddNewInjunctionModal
          showAddNewChargeModal={showAddNewInjunctionModal}
          setShowAddNewChargeModal={setShowAddNewInjunctionModal}
          isUpdates={isInjunctionModalUpdates}
        >
          <AddNewInjunctionContent
            data={editInjunctionDetailsList.list}
            dataIndex={editInjunctionDetailsList.index}
            getSubmitDataValues={getAddNewInjunctionValues}
            setShowAddNewChargeModal={setShowAddNewInjunctionModal}
            setEditChargeDetailsList={setEditInjunctionDetailsList}
            setIsModalUpdates={setIsInjunctionModalUpdates}
            headerText={headerText}
          />
        </AddNewInjunctionModal>
      )}

      {showAddNewMonetaryRelatedSanctionModal && (
        <AddNewMonetaryRelatedSanctionModal
          showAddNewChargeModal={showAddNewMonetaryRelatedSanctionModal}
          setShowAddNewChargeModal={setShowAddNewMonetaryRelatedSanctionModal}
          isUpdates={isMonetaryRelatedSanctionModalUpdates}
        >
          <AddNewMonetaryRelatedSanctionContent
            data={editMonetaryRelatedSanctionDetailsList.list}
            dataIndex={editMonetaryRelatedSanctionDetailsList.index}
            getSubmitDataValues={getAddNewMonetaryRelatedSanctionValues}
            setShowAddNewChargeModal={setShowAddNewMonetaryRelatedSanctionModal}
            setEditChargeDetailsList={setEditMonetaryRelatedSanctionDetailsList}
            setIsModalUpdates={setIsMonetaryRelatedSanctionModalUpdates}
            headerText={headerText}
          />
        </AddNewMonetaryRelatedSanctionModal>
      )}

      <div className="backbutton-drp-crim row">
        <div className="col-md-12 buttons">
          <span role="presentation" onClick={handleShowBackModal}>
            <strong>Back</strong>
          </span>
          <Button
            label="Next"
            onClick={handleNext}
            disabled={!!isNextBtnDisabled}
          />
          {false && <span className="mm-dots-loading"></span>} {/* loading */}
        </div>
      </div>
      <BackButtonModal
        isOpen={showBackModal}
        closeBackModal={handleCloseBackModal}
        backClick={handleBackClick}
      />
    </div>
  );
};

export default CivJudSanctionDetailsForm;
