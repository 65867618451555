import React from "react";

export default (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="display/danger">
      <g id="Vector">
        <path
          d="M8.66696 10C8.66696 10.3682 8.36849 10.6667 8.0003 10.6667C7.63211 10.6667 7.33363 10.3682 7.33363 10V7.33334C7.33363 6.96515 7.63211 6.66668 8.0003 6.66668C8.36849 6.66668 8.66696 6.96515 8.66696 7.33334L8.66696 10Z"
          fill="#A72D1A"
        />
        <path
          d="M8.66696 12C8.66696 12.3682 8.36849 12.6667 8.0003 12.6667C7.63211 12.6667 7.33363 12.3682 7.33363 12C7.33363 11.6318 7.63211 11.3333 8.0003 11.3333C8.36849 11.3333 8.66696 11.6318 8.66696 12Z"
          fill="#A72D1A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.0003 3.6481L2.82434 14H13.1763L8.0003 3.6481ZM8.59658 1.85925C8.3509 1.36788 7.64969 1.36788 7.40401 1.85925L1.14937 14.3685C0.927733 14.8118 1.25006 15.3333 1.74565 15.3333H14.2549C14.7505 15.3333 15.0729 14.8118 14.8512 14.3685L8.59658 1.85925Z"
          fill="#A72D1A"
        />
      </g>
    </g>
  </svg>
);
