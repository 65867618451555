export const initialState = {
  convictedfelony: null,
  chargedfelony: null,
  convictedmisdemeanor: null,
  chargedmisdemeanor: null,
  orgconvictedfelony: null,
  orgchargedfelony: null,
  orgconvictedmisdemeanor: null,
  orgchargedmisdemeanor: null,
};

const felonyInd = {
  mainQuestion: "Have you ever:",
  questionA: `(a) been convicted of or pled guilty or nolo contendere ("no
  contest") in a domestic, foreign, or military court to any felony?`,
  questionB: `(b) been charged with any felony?`,
};

const misdemeanorInd = {
  mainQuestion: "Have you ever:",
  questionA: `(a) been convicted of or pled guilty or nolo contendere ("no
  contest") in a domestic, foreign or military court to a misdemeanor
  involving: investments or an investment-related business or any
  fraud, false statements or omissions, wrongful taking of property,
  bribery, perjury, forgery, counterfeiting, extortion, or a
  conspiracy to commit any of these offenses?`,
  questionB: `(b) been charged with a misdemeanor specified in 14B(1)(a)?`,
};

export const DRPSummaryIndividualCriminalDisclosure = {
  convictedfelony: {
    key: "14A(1)(a)",
    mainQuestion: felonyInd.mainQuestion,
    question: felonyInd.questionA,
  },
  chargedfelony: {
    key: "14A(1)(b)",
    mainQuestion: felonyInd.mainQuestion,
    question: felonyInd.questionB,
  },
  convictedmisdemeanor: {
    key: "14B(1)(a)",
    mainQuestion: misdemeanorInd.mainQuestion,
    question: misdemeanorInd.questionA,
  },
  chargedmisdemeanor: {
    key: "14B(1)(b)",
    mainQuestion: misdemeanorInd.mainQuestion,
    question: misdemeanorInd.questionB,
  },
};

const felonyOrg = {
  mainQuestion:
    "Based upon activities that occurred while you exercised control over it, has an organization ever:",
  questionA: `(a) been convicted of or pled guilty or nolo contendere ("no
  contest") in a domestic or foreign court to any felony?`,
  questionB: `(b) been charged with any felony?`,
};

const misdemeanorOrg = {
  mainQuestion: `Based upon activities that occurred while you exercised
  control over it, has an organization ever:`,
  questionA: `(a) been convicted of or pled guilty or nolo contendere ("no
  contest") in a domestic or foreign court to a misdemeanor specified
  in 14B(1)(a)?`,
  questionB: `(b) been charged with a misdemeanor specified in 14B(1)(a)?`,
};

export const DRPSummaryOrganizationCriminalDisclosure = {
  orgconvictedfelony: {
    key: "14A(2)(a)",
    mainQuestion: felonyOrg.mainQuestion,
    question: `(a) been convicted of or pled guilty or nolo contendere ("no
    contest") in a domestic or foreign court to any felony?`,
  },
  orgchargedfelony: {
    key: "14A(2)(b)",
    mainQuestion: felonyOrg.mainQuestion,
    question: `(b) been charged with any felony?`,
  },
  orgconvictedmisdemeanor: {
    key: "14B(2)(a)",
    mainQuestion: misdemeanorOrg.mainQuestion,
    question: `(a) been convicted of or pled guilty or nolo contendere ("no
    contest") in a domestic or foreign court to a misdemeanor specified
    in 14B(1)(a)?`,
  },
  orgchargedmisdemeanor: {
    key: "14B(2)(b)",
    mainQuestion: misdemeanorOrg.mainQuestion,
    question: `(b) been charged with a misdemeanor specified in 14B(1)(a)?`,
  },
};

export const individualCriminalDisclosure = (disabled) => [
  {
    header: "Felony",
    subHeader: `14A. (1) ${felonyInd.mainQuestion}`,
    questionA: felonyInd.questionA,
    questionB: felonyInd.questionB,
    optionsA: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "convictedfelony-y",
      idTwo: "convictedfelony-n",
      name: "convictedfelony",
      disabled: disabled,
    },
    optionsB: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "chargedfelony-y",
      idTwo: "chargedfelony-n",
      name: "chargedfelony",
      disabled: disabled,
    },
  },
  {
    header: "Misdemeanor",
    subHeader: `14B. (1) ${misdemeanorInd.mainQuestion}`,
    questionA: misdemeanorInd.questionA,
    questionB: misdemeanorInd.questionB,
    optionsA: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "convictedmisdemeanor-y",
      idTwo: "convictedmisdemeanor-n",
      name: "convictedmisdemeanor",
      disabled: disabled,
    },
    optionsB: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "chargedmisdemeanor-y",
      idTwo: "chargedmisdemeanor-n",
      name: "chargedmisdemeanor",
      disabled: disabled,
    },
  },
];

export const organizationCriminalDisclosure = (disabled) => [
  {
    header: "Felony",
    subHeader: `14A. (2) ${felonyOrg.mainQuestion}`,
    questionA: felonyOrg.questionA,
    questionB: felonyOrg.questionB,
    optionsA: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "orgconvictedfelony-y",
      idTwo: "orgconvictedfelony-n",
      name: "orgconvictedfelony",
      disabled: disabled,
    },
    optionsB: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "orgchargedfelony-y",
      idTwo: "orgchargedfelony-n",
      name: "orgchargedfelony",
      disabled: disabled,
    },
  },
  {
    header: "Misdemeanor",
    subHeader: `14B. (2) ${misdemeanorOrg.mainQuestion}`,
    questionA: misdemeanorOrg.questionA,
    questionB: misdemeanorOrg.questionB,
    optionsA: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "orgconvictedmisdemeanor-y",
      idTwo: "orgconvictedmisdemeanor-n",
      name: "orgconvictedmisdemeanor",
      disabled: disabled,
    },
    optionsB: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "orgchargedmisdemeanor-y",
      idTwo: "orgchargedmisdemeanor-n",
      name: "orgchargedmisdemeanor",
      disabled: disabled,
    },
  },
];
