/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { Button } from "react-blueprint-library";
import BackButtonModal from "../../../../../../Forms/BackButtonModal";
import { logoutActions } from "../../../../../../../../store/actions";
import { useDispatch } from "react-redux";
import "./BondDisclosureCommentsForm.scss";

const BondAddDRPComments = ({
  updateCCBForm,
  backFormId,
  nextFormId,
  tempBondDRP,
  updateTempDRP,
  updateBondDisclosureDRPs,
}) => {
  const dispatch = useDispatch();
  const [bondComments, setBondComments] = useState(
    tempBondDRP?.bondComments || ""
  );
  const [showBackModal, toggleShowBackModal] = useState(false);
  const [currentFormChanged, hasCurrentFormChanged] = useState(false);

  const handleChange = ({ target: { id, value } }) => {
    setBondComments(value);
    hasCurrentFormChanged(true);
  };
  const isUpdated = bondComments?.length;
  useEffect(() => {
    if (isUpdated) {
      dispatch(
        logoutActions.setShowLogoutModal({ showLogoutModal: isUpdated })
      );
    }
  }, [isUpdated, dispatch]);

  const handleNext = async () => {
    const newTempDRP = {
      ...tempBondDRP,
      bondComments: bondComments,
    };
    updateBondDisclosureDRPs(newTempDRP);
    updateTempDRP({});
    updateCCBForm(nextFormId);
    hasCurrentFormChanged(false);
  };

  const handleBackClick = async () => {
    handleCloseBackModal();
    hasCurrentFormChanged(false);
    updateCCBForm(backFormId);
  };

  const handleShowBackModal = () => {
    currentFormChanged === true ? toggleShowBackModal(true) : handleBackClick();
  };

  const handleCloseBackModal = () => toggleShowBackModal(false);

  return (
    <>
      <div className="bond-comments-form-wrapper">
        <div className="row">
          <div className="col-md-12">
            <span id="bondAddDrpText" className="comments-label">
              You may use this field to provide a brief summary of the
              circumstances leading to the action as well as the current status
              or final disposition. (Optional)
            </span>
          </div>
        </div>
        <div style={{ paddingTop: "20px" }}>
          <textarea
            id="bondComments"
            className="form-control comments-label"
            value={bondComments}
            rows="3"
            maxLength={500}
            onChange={handleChange}
          ></textarea>
        </div>
        <div className="row backbutton-drp-bond">
          <div className="col-md-12 buttons">
            <span
              id="bondBack"
              className="back-button"
              onClick={handleShowBackModal}
            >
              <strong id="bondBack">Back</strong>
            </span>
            <Button id="bondAddDRPNext" onClick={handleNext}>
              Complete DRP
            </Button>
          </div>
        </div>
        <BackButtonModal
          isOpen={showBackModal}
          closeBackModal={handleCloseBackModal}
          backClick={handleBackClick}
        />
      </div>
    </>
  );
};
export default BondAddDRPComments;
