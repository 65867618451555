import React, { useState } from "react";
import { FORM_ID } from "../../../constants/Forms";
import {
  Dropdown,
  DropdownButton,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import { useEffect } from "react";
import BackModal from "../Forms/BackButtonModal";
import { clickFunc } from "../../../common/Functions";
import { insertAdditionalInformation } from "../../../api/makeAPIRequest";
import NavigationButtons from "../../SharedComponents/NavigationButtons";
import { UtagTracking } from "../../../utils/utag-tracking";

const PersonalInfoAddl = (props) => {
  const [formHasError, setFormHasError] = useState(false);
  const [formHasChanged, setFormHasChanged] = useState(false);
  const [currentlyMilitary, setCurrentlyMilitary] = useState("");
  const [finraRegistered, setFinraRegistered] = useState("");
  const [finraRegisteredPrincipal, setFinraRegisteredPrincipal] = useState("");
  const [crd, setCrd] = useState("");
  const [firmRegisteredWith, setFirmRegisteredWith] = useState("");
  const [relatedToAssociates, setRelatedToAssociates] = useState("");
  const [relatedName, setRelatedName] = useState("");
  const [theirRelationship, setTheirRelationship] = useState("");
  const [nonCompeteAgreement, setNonCompeteAgreement] = useState("");
  const [nonCompeteAgreementYes, setNonCompeteAgreementYes] = useState("");

  const [cntrlWithFocus, setCntrlWithFocus] = useState("");
  const [showBackModal, setShowBackModal] = useState(false);
  const [loading, setLoading] = useState(false);

  var hasError = false;
  var hasChange = false;

  const handleMilitary = (event) => {
    hasChange = true;
    setCurrentlyMilitary(event);
    setFormHasError(false);
    setFormHasChanged(true);
    props.isFormComplete(false);
    props.hasCurrentFormChanged(true);
    let currentlyMilitary1 = document.getElementById(
      "currently_military_error"
    );
    currentlyMilitary1.style.display = "none";
    let currentlyMilitaryYes = document.getElementById(
      "currently_military_yes"
    );
    currentlyMilitaryYes.classList.remove("is-invalid");
    let currentlyMilitaryNo = document.getElementById("currently_military_no");
    currentlyMilitaryNo.classList.remove("is-invalid");
  };

  const handleFinraRegistered = (event) => {
    hasChange = true;
    setFinraRegistered(event);
    setFormHasError(false);
    setFormHasChanged(true);
    props.isFormComplete(false);
    props.hasCurrentFormChanged(true);
    let finraRegistered_error = document.getElementById(
      "finraRegistered_error"
    );
    finraRegistered_error.style.display = "none";
    let label_finraRegistered_yes = document.getElementById(
      "finraRegistered_yes"
    );
    label_finraRegistered_yes.classList.remove("is-invalid");
    let label_finraRegistered_no =
      document.getElementById("finraRegistered_no");
    label_finraRegistered_no.classList.remove("is-invalid");
    if (finraRegistered === "N") {
      setFinraRegisteredPrincipal("");
      setCrd("");
      setFirmRegisteredWith("");
    }
  };

  const handleFinraRegisteredPrincipal = (event) => {
    hasChange = true;
    setFinraRegisteredPrincipal(event);
    setFormHasError(false);
    setFormHasChanged(true);
    props.isFormComplete(false);
    props.hasCurrentFormChanged(true);
    let finraRegisteredPrincipal = document.getElementById(
      "finraRegisteredPrincipal_error"
    );
    finraRegisteredPrincipal.style.display = "none";
    let finraRegisteredPrincipal_yes = document.getElementById(
      "finraRegisteredPrincipal_yes"
    );
    finraRegisteredPrincipal_yes.classList.remove("is-invalid");
    let finraRegisteredPrincipal_no = document.getElementById(
      "finraRegisteredPrincipal_no"
    );
    finraRegisteredPrincipal_no.classList.remove("is-invalid");
  };

  const handleCRD = (event) => {
    hasChange = true;
    setFormHasError(false);
    setCrd(event.replace(/[^\w\s]/gi, ""));

    setFormHasChanged(true);
    props.isFormComplete(false);
    props.hasCurrentFormChanged(true);
  };

  const handleFirmRegisteredWith = (event) => {
    hasChange = true;
    setFirmRegisteredWith(event);
    setFormHasError(false);
    setFormHasChanged(true);
    props.isFormComplete(false);
    props.hasCurrentFormChanged(true);
  };
  const handleRelatedMM = (event) => {
    hasChange = true;
    setFormHasError(false);
    setRelatedToAssociates(event);
    setFormHasChanged(true);
    props.isFormComplete(false);
    props.hasCurrentFormChanged(true);
    let relatedMM_error = document.getElementById("relatedMM_error");
    relatedMM_error.style.display = "none";
    let relatedMM_yes = document.getElementById("relatedMM_yes");
    relatedMM_yes.classList.remove("is-invalid");
    let relatedMM_no = document.getElementById("relatedMM_no");
    relatedMM_no.classList.remove("is-invalid");
    if (relatedToAssociates === "N") {
      setRelatedName("");
      setTheirRelationship("");
    }
  };

  const handleRelatedName = (event) => {
    hasChange = true;
    setFormHasError(false);
    setRelatedName(event);
    setFormHasChanged(true);
    props.isFormComplete(false);
    props.hasCurrentFormChanged(true);
  };

  const handleTheirRelationship = (event) => {
    hasChange = true;
    setFormHasError(false);
    setFormHasChanged(true);
    props.isFormComplete(false);
    props.hasCurrentFormChanged(true);
    setTheirRelationship(event.target.value);
    let obj_error = document.getElementById(
      "theirRelationship_option_id_error"
    );
    let ctrlToValidate = document.getElementById("theirRelationship_id");
    ctrlToValidate.removeAttribute("style");
    obj_error.style.display = "none";
  };

  const handleNonCompeteAgreement = (event) => {
    hasChange = true;
    setFormHasError(false);
    setNonCompeteAgreement(event);
    setFormHasChanged(true);
    props.isFormComplete(false);
    props.hasCurrentFormChanged(true);
    let nonCompeteAgreement_error = document.getElementById(
      "nonCompeteAgreement_error"
    );
    nonCompeteAgreement_error.style.display = "none";
    let nonCompeteAgreement_yes = document.getElementById(
      "nonCompeteAgreement_yes"
    );
    nonCompeteAgreement_yes.classList.remove("is-invalid");
    let nonCompeteAgreement_no = document.getElementById(
      "nonCompeteAgreement_no"
    );
    nonCompeteAgreement_no.classList.remove("is-invalid");
    if (nonCompeteAgreement === "N") {
      setNonCompeteAgreementYes("");
    }
  };

  const handleNonCompeteAgreementYes = (event) => {
    hasChange = true;
    setFormHasError(false);
    setNonCompeteAgreementYes(event);
    setFormHasChanged(true);
    props.isFormComplete(false);
    props.hasCurrentFormChanged(true);
    let nonCompeteAgreementYes_text_error = document.getElementById(
      "nonCompeteAgreementYes_text_error"
    );
    nonCompeteAgreementYes_text_error.style.nonCompeteAgreementYes_text_error =
      "none";
  };

  const handleNext = async () => {
    setFormHasError(false);
    if (currentlyMilitary === "" || undefined || null) {
      hasError = true;
      setFormHasError(true);
      let currentlyMilitary = document.getElementById(
        "currently_military_error"
      );
      currentlyMilitary.style.display = "block";
      let currentlyMilitaryYes = document.getElementById(
        "currently_military_yes"
      );
      currentlyMilitaryYes.classList.add("is-invalid");
      let currentlyMilitaryNo = document.getElementById(
        "currently_military_no"
      );
      currentlyMilitaryNo.classList.add("is-invalid");
      let label_yes = document.getElementById("label_currently_military_yes");
      label_yes.style.color = "#555";
      let label_no = document.getElementById("label_currently_military_no");
      label_no.style.color = "#555";
    }
    if (finraRegistered === "" || undefined || null) {
      hasError = true;
      setFormHasError(true);
      let finraRegistered_error = document.getElementById(
        "finraRegistered_error"
      );
      finraRegistered_error.style.display = "block";
      let label_finraRegistered_yes = document.getElementById(
        "finraRegistered_yes"
      );
      label_finraRegistered_yes.classList.add("is-invalid");
      let label_finraRegistered_no =
        document.getElementById("finraRegistered_no");
      label_finraRegistered_no.classList.add("is-invalid");
      let label_yes = document.getElementById("label_finraRegistered_yes");
      label_yes.style.color = "#555";
      let label_no = document.getElementById("label_finraRegistered_no");
      label_no.style.color = "#555";
    }
    if (finraRegistered === "Y") {
      if (finraRegisteredPrincipal === "") {
        hasError = true;
        setFormHasError(true);
        let finraRegisteredPrincipal = document.getElementById(
          "finraRegisteredPrincipal_error"
        );
        finraRegisteredPrincipal.style.display = "block";
        let finraRegisteredPrincipal_yes = document.getElementById(
          "finraRegisteredPrincipal_yes"
        );
        finraRegisteredPrincipal_yes.classList.add("is-invalid");
        let finraRegisteredPrincipal_no = document.getElementById(
          "finraRegisteredPrincipal_no"
        );
        finraRegisteredPrincipal_no.classList.add("is-invalid");
        let label_yes = document.getElementById(
          "label_finraRegisteredPrincipal_yes"
        );
        label_yes.style.color = "#555";
        let label_no = document.getElementById(
          "label_finraRegisteredPrincipal_no"
        );
        label_no.style.color = "#555";
      }
    }
    if (finraRegistered === "Y" && crd.length === 0) {
      hasError = true;
      await setFormHasError(true);
      let finraReg = new Event("blur");
      Object.defineProperty(finraReg, "target", {
        writable: false,
        value: document.getElementById("crd_text"),
      });
      await onBlur(finraReg);
    }
    if (finraRegistered === "Y" && firmRegisteredWith === "") {
      hasError = true;
      await setFormHasError(true);

      let finraRegWith = new Event("blur");
      Object.defineProperty(finraRegWith, "target", {
        writable: false,
        value: document.getElementById("firmRegisteredWith_text"),
      });
      await onBlur(finraRegWith);
    }
    if (relatedToAssociates === "") {
      hasError = true;
      setFormHasError(true);
      let relatedMM_error = document.getElementById("relatedMM_error");
      relatedMM_error.style.display = "block";
      let relatedMM_yes = document.getElementById("relatedMM_yes");
      relatedMM_yes.classList.add("is-invalid");
      let relatedMM_no = document.getElementById("relatedMM_no");
      relatedMM_no.classList.add("is-invalid");
      let label_yes = document.getElementById("label_relatedMM_yes");
      label_yes.style.color = "#555";
      let label_no = document.getElementById("label_relatedMM_no");
      label_no.style.color = "#555";
    }
    if (relatedToAssociates === "Y" && relatedName === "") {
      hasError = true;
      await setFormHasError(true);
      let relatedNameErr = new Event("blur");
      Object.defineProperty(relatedNameErr, "target", {
        writable: false,
        value: document.getElementById("relatedName_text"),
      });
      await onBlur(relatedNameErr);
    }
    if (relatedToAssociates === "Y" && theirRelationship === "") {
      hasError = true;
      setFormHasError(true);

      let theirRelationshipErr = new Event("blur");
      Object.defineProperty(theirRelationshipErr, "target", {
        writable: false,
        value: document.getElementById("theirRelationship_id"),
      });
      onBlur(theirRelationshipErr);
    }
    if (nonCompeteAgreement === "") {
      hasError = true;
      setFormHasError(true);
      let nonCompeteAgreement_error = document.getElementById(
        "nonCompeteAgreement_error"
      );
      nonCompeteAgreement_error.style.display = "block";
      let nonCompeteAgreement_yes = document.getElementById(
        "nonCompeteAgreement_yes"
      );
      nonCompeteAgreement_yes.classList.add("is-invalid");
      let nonCompeteAgreement_no = document.getElementById(
        "nonCompeteAgreement_no"
      );
      nonCompeteAgreement_no.classList.add("is-invalid");
      let label_yes = document.getElementById("label_nonCompeteAgreement_yes");
      label_yes.style.color = "#555";
      let label_no = document.getElementById("label_nonCompeteAgreement_no");
      label_no.style.color = "#555";
    }
    if (nonCompeteAgreement === "Y" && nonCompeteAgreementYes === "") {
      hasError = true;
      await setFormHasError(true);

      let nonCompeteAgreementYesErr = new Event("blur");
      Object.defineProperty(nonCompeteAgreementYesErr, "target", {
        writable: false,
        value: document.getElementById("nonCompeteAgreementYes_text"),
      });
      await onBlur(nonCompeteAgreementYesErr);
    }

    if (formHasChanged === true) {
      await call();
    }

    if (!hasError && !hasChange) {
      props.toggleForm(FORM_ID.RESIDENTIAL_HISTORY);
      props.isFormComplete(true);
      props.hasCurrentFormChanged(false);
    }
  };

  // useEffect(() => {
  //   if (formHasError === false && formHasChanged === false) {
  //     props.toggleForm(FORM_ID.RESIDENTIAL_HISTORY);
  //   }
  // }, [formHasError, formHasChanged]);

  const call = async () => {
    setLoading(true);
    if (formHasChanged === true) {
      setFormHasChanged(false);
    }

    let data = {
      candidateid: props.candidateId,
      militaryservice: currentlyMilitary === "Y" ? true : false,
      finrareg: finraRegistered === "Y" ? true : false,
      finraregprinc:
        finraRegistered === "Y"
          ? finraRegisteredPrincipal === "Y"
            ? true
            : false
          : null,
      crdnumber: finraRegistered === "Y" ? crd : "",
      finraregprincfirm: finraRegistered === "Y" ? firmRegisteredWith : "",
      relatedmmemps: relatedToAssociates === "Y" ? true : false,
      relatedempname: relatedToAssociates === "Y" ? relatedName : "",
      employeerelationid:
        relatedToAssociates === "Y"
          ? theirRelationship === "Spouse"
            ? 1
            : theirRelationship === "Parent"
            ? 2
            : theirRelationship === "Sibling"
            ? 3
            : theirRelationship === "Child"
            ? 4
            : theirRelationship === "Other"
            ? 5
            : null
          : null,
      noncompete: nonCompeteAgreement === "Y" ? true : false,
      finraregfirm: nonCompeteAgreement === "Y" ? nonCompeteAgreementYes : "",
    };

    props.savePersonalInfoAdditionalInfo([
      {
        ...data,
        oktasubid: props.subId,
      },
    ]);
    insertAdditionalInformation(props.subId, data);
    setLoading(false);
    hasChange = false;
  };

  const fetch = async () => {
    const data = props?.pInfoAddlData;

    if (data?.length > 0 && data !== undefined) {
      props.isFormComplete(true);

      let val = data[0];
      setCurrentlyMilitary(val.militaryservice ? "Y" : "N");
      setFinraRegistered(val.finrareg ? "Y" : "N");
      setFinraRegisteredPrincipal(val.finraregprinc ? "Y" : "N");
      setCrd(val.crdnumber);
      setFirmRegisteredWith(val.finraregprincfirm);
      setRelatedToAssociates(val.relatedmmemps ? "Y" : "N");
      setRelatedName(val.relatedempname);
      switch (val.employeerelationid) {
        case 1:
          setTheirRelationship("Spouse");
          break;
        case 2:
          setTheirRelationship("Parent");
          break;
        case 3:
          setTheirRelationship("Sibling");
          break;
        case 4:
          setTheirRelationship("Child");
          break;
        case 5:
          setTheirRelationship("Other");
          break;
        default:
          setTheirRelationship("");
          break;
      }
      setNonCompeteAgreement(val.noncompete ? "Y" : "N");
      setNonCompeteAgreementYes(val.finraregfirm);
    }
  };

  useEffect(() => {
    fetch();
    UtagTracking.sendPageNameView(
      "personalinfoaddform",
      props.candidateId,
      props.agencynumber,
      props.contracttypeid
    );
  }, []);

  const onKeyDown = () => {
    hasChange = true;
    setFormHasChanged(true);
    props.isFormComplete(false);
  };

  const onBlur = async (event) => {
    hasError = false;
    if (event.target.id.includes("crd_text") && finraRegistered === "Y") {
      if (
        event.target.value.trim(" ").length === 0 ||
        event.target.value === ""
      ) {
        hasError = true;
        await setFormHasError(true);
        let crd_text_error = document.getElementById("crd_text_error");
        crd_text_error.innerText = "Please enter a valid CRD number";
        event.target.style.border = "1px solid #e21224";
        event.target.style.color = "#e21224";
        crd_text_error.style.display = "block";
      } else {
        setFormHasError(false);
        let crd_text_error = document.getElementById("crd_text_error");
        event.target.style.border = "1px solid #6b748e";
        crd_text_error.style.display = "none";
        event.target.removeAttribute("style");
      }
    } else if (
      event.target.id.includes("firmRegisteredWith_text") &&
      finraRegistered === "Y"
    ) {
      if (
        firmRegisteredWith === "" ||
        /^\s/.test(firmRegisteredWith) === true
      ) {
        hasError = true;
        setFormHasError(true);
        let firmRegisteredWith_text_error = document.getElementById(
          "firmRegisteredWith_text_error"
        );
        event.target.style.border = "1px solid #e21224";
        event.target.style.color = "#e21224";
        firmRegisteredWith_text_error.style.display = "block";
      } else {
        setFormHasError(false);
        let firmRegisteredWith_text_error = document.getElementById(
          "firmRegisteredWith_text_error"
        );
        event.target.removeAttribute("style");
        firmRegisteredWith_text_error.style.display = "none";
      }
    } else if (
      relatedToAssociates === "Y" &&
      event.target.id.includes("relatedName_text")
    ) {
      if (relatedName === "" || /^\s/.test(relatedName) === true) {
        hasError = true;
        setFormHasError(true);
        let relatedName_text_error = document.getElementById(
          "relatedName_text_error"
        );
        event.target.style.border = "1px solid #e21224";
        relatedName_text_error.style.display = "block";
        event.target.style.color = "#e21224";
      } else {
        setFormHasError(false);
        let relatedName_text_error = document.getElementById(
          "relatedName_text_error"
        );
        event.target.removeAttribute("style");
        relatedName_text_error.style.display = "none";
      }
    } else if (
      relatedToAssociates === "Y" &&
      event.target.id.includes("theirRelationship_id")
    ) {
      if (
        event.target.id.includes("id") &&
        cntrlWithFocus.includes("theirRelationship_option")
      ) {
        //do nothing
      } else if (
        theirRelationship === "" ||
        event.target.innerText.trim(" ").length === 0
      ) {
        let obj_error = document.getElementById(
          "theirRelationship_option_id_error"
        );
        let ctrlToValidate = document.getElementById("theirRelationship_id");
        if (theirRelationship.length === 0) {
          ctrlToValidate.style.border = "1px solid #e21224";
          event.target.style.border = "1px solid #e21224";
          obj_error.style.display = "block";
          event.target.style.color = "#e21224";
          setFormHasError(true);
          hasError = true;
        } else {
          setFormHasError(false);
          event.target.style.border = "1px solid #6b748e";
          event.target.style.color = "#6b748e";
          obj_error.style.display = "none";
          if (ctrlToValidate.className !== "dropdown-item") {
            ctrlToValidate.removeAttribute("style");
            event.target.style.border = "1px solid #6b748e";
          } else {
            let genderCtrl2 = document.getElementById("degree_name");
            genderCtrl2.style.border = "1px solid #6b748e";
            event.target.style.border = "1px solid #6b748e";
          }
        }
      }
    } else if (
      nonCompeteAgreement === "Y" &&
      event.target.id.includes("nonCompeteAgreementYes_text")
    ) {
      if (
        nonCompeteAgreementYes === "" ||
        /^\s/.test(nonCompeteAgreementYes) === true
      ) {
        hasError = true;
        setFormHasError(true);
        let nonCompeteAgreementYes_text_error = document.getElementById(
          "nonCompeteAgreementYes_text_error"
        );
        event.target.style.border = "1px solid #e21224";
        event.target.style.color = "#e21224";
        nonCompeteAgreementYes_text_error.style.display = "block";
      } else {
        setFormHasError(false);
        let nonCompeteAgreementYes_text_error = document.getElementById(
          "nonCompeteAgreementYes_text_error"
        );
        nonCompeteAgreementYes_text_error.style.display = "none";
        event.target.removeAttribute("style");
      }
    }
    if (hasError) {
      setFormHasError(true);
    } else {
      setFormHasError(false);
    }
  };

  const handleCloseBackModal = () => {
    setShowBackModal(false);
  };

  const handleBackClick = () => {
    handleCloseBackModal();
    props.toggleForm(FORM_ID.PERSONAL_INFO);
  };

  const handleShowBackModal = () => {
    if (formHasChanged) {
      setShowBackModal(true);
    } else {
      props.toggleForm(FORM_ID.PERSONAL_INFO);
    }
  };

  function onlyNumeric(event) {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
    document
      .querySelector("#crd_text")
      .addEventListener("keypress", function (evt) {
        if (evt.which < 48 || evt.which > 57) {
          evt.preventDefault();
        }
      });
    var phone = document.getElementById("crd_text"),
      cleanPhoneNumber;

    cleanPhoneNumber = function (e) {
      e.preventDefault();
      var pastedText = "";
      if (window.clipboardData && window.clipboardData.getData) {
        // IE
        pastedText = window.clipboardData.getData("Text");
      } else if (e.clipboardData && e.clipboardData.getData) {
        pastedText = e.clipboardData.getData("text/plain");
      }
      this.value = pastedText.replace(/\D/g, "");
    };

    phone.onpaste = cleanPhoneNumber;
  }

  const handlePaste = (e) => {
    e.preventDefault();
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData("text");
    const numericValue = pastedData.replace(/[^0-9]/g, "");
    if (numericValue <= 16 && crd.length === 0) {
      setCrd(numericValue);
    } else if (numericValue > 16 && crd.length === 0) {
      let val = numericValue.toString();
      let updatedVal = parseInt(val.substring(0, 16));
      setCrd(updatedVal);
    } else if (numericValue > 16 && crd.length > 0) {
      let val = numericValue.toString() + crd.toString();
      setCrd(parseInt(val.substring(0, 16)));
    }
  };

  const onFocusState = () => {
    setCntrlWithFocus("");
    let inputs = document.querySelectorAll("button, a");
    try {
      for (let i = 0; i < inputs.length; ++i) {
        inputs[i].addEventListener("focusout", (event) => {
          if (event.relatedTarget == null) {
            setCntrlWithFocus("");
          } else {
            setCntrlWithFocus(event.relatedTarget.id);
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12" id="addtnl-info">
          <h1>Additional Information</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <span className="required-field-text">* = Required Field</span>
        </div>
      </div>
      <>
        <div style={{ paddingTop: "10px" }} id="mltry">
          <h3>Are you currently in or have you served in the military?*</h3>
        </div>
        <div className="custom-control">
          <div
            className="custom-control custom-radio"
            style={{ paddingTop: "10px" }}
            id="mltyoptn"
          >
            <input
              type="radio"
              name="currently_military"
              id="currently_military_yes"
              className="custom-control-input"
              checked={currentlyMilitary === "Y"}
              value="Y"
              onChange={(e) => handleMilitary(e.target.value)}
            />
            <label
              htmlFor="currently_military_yes"
              className="custom-control-label"
              id="label_currently_military_yes"
            >
              Yes
            </label>
          </div>
          <div className="custom-control custom-radio">
            <input
              type="radio"
              name="currently_military"
              className="custom-control-input"
              id="currently_military_no"
              checked={currentlyMilitary === "N"}
              value="N"
              onChange={(e) => handleMilitary(e.target.value)}
            />
            <label
              htmlFor="currently_military_no"
              className="custom-control-label"
              id="label_currently_military_no"
            >
              No
            </label>
          </div>
          <div id="currently_military_error" className="invalid-feedback">
            Please choose a response
          </div>
        </div>
        <div id="rgstrdfnra">
          <h3>
            Have you ever been, or are you currently, registered with FINRA?*{" "}
            <OverlayTrigger
              trigger={["hover", "focus"]}
              placement="top"
              overlay={
                <Popover
                  id="popover-basic"
                  style={{
                    padding: "10px",
                    backgroundColor: "#555",

                    borderRadius: "10px",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  Individuals registered with FINRA typically hold a series 6,
                  7, 63 or other securities license to sell securities products
                  to consumers.
                </Popover>
              }
            >
              <button
                variant="link"
                className="btn btn-link"
                id="sales_button"
                style={{ paddingTop: "1px" }}
              >
                <span className="icon-info"></span>
              </button>
            </OverlayTrigger>
          </h3>
        </div>
        <div className="custom-control" style={{ paddingTop: "10px" }}>
          <div className="custom-control custom-radio" id="finra">
            <input
              type="radio"
              name="finraRegistered"
              id="finraRegistered_yes"
              className="custom-control-input"
              checked={finraRegistered === "Y"}
              value="Y"
              onChange={(e) => handleFinraRegistered(e.target.value)}
              onKeyDown={onKeyDown}
            />
            <label
              htmlFor="finraRegistered_yes"
              className="custom-control-label"
              id="label_finraRegistered_yes"
            >
              Yes
            </label>
          </div>
          <div className="custom-control custom-radio">
            <input
              type="radio"
              name="finraRegistered"
              className="custom-control-input"
              id="finraRegistered_no"
              checked={finraRegistered === "N"}
              value="N"
              onChange={(e) => handleFinraRegistered(e.target.value)}
            />
            <label
              htmlFor="finraRegistered_no"
              className="custom-control-label"
              id="label_finraRegistered_no"
            >
              No
            </label>
          </div>
          <div id="finraRegistered_error" className="invalid-feedback">
            Please choose a response
          </div>
        </div>
        {finraRegistered === "Y" && (
          <div>
            <div>
              <h3>Are you a FINRA registered principal?*</h3>
            </div>
            <div className="custom-control" style={{ paddingTop: "10px" }}>
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  name="finraRegisteredPrincipal"
                  id="finraRegisteredPrincipal_yes"
                  className="custom-control-input"
                  checked={finraRegisteredPrincipal === "Y"}
                  value="Y"
                  onChange={(e) =>
                    handleFinraRegisteredPrincipal(e.target.value)
                  }
                />
                <label
                  htmlFor="finraRegisteredPrincipal_yes"
                  className="custom-control-label"
                  id="label_finraRegisteredPrincipal_yes"
                >
                  Yes
                </label>
              </div>
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  name="finraRegisteredPrincipal"
                  className="custom-control-input"
                  id="finraRegisteredPrincipal_no"
                  checked={finraRegisteredPrincipal === "N"}
                  value="N"
                  onChange={(e) =>
                    handleFinraRegisteredPrincipal(e.target.value)
                  }
                />
                <label
                  htmlFor="finraRegisteredPrincipal_no"
                  className="custom-control-label"
                  id="label_finraRegisteredPrincipal_no"
                >
                  No
                </label>
              </div>
              <div
                id="finraRegisteredPrincipal_error"
                className="invalid-feedback"
              >
                Please choose a response
              </div>
            </div>
            {finraRegistered === "Y" && (
              <>
                <div>
                  <h3>
                    What is your Personal Central Registration Depository Number
                    (CRD)?*
                  </h3>
                  <div style={{ paddingTop: "10px" }}>
                    <input
                      maxLength={16}
                      onKeyPress={onlyNumeric}
                      id="crd_text"
                      className="form-control"
                      value={crd}
                      onBlur={onBlur}
                      autoComplete="off"
                      minLength={1}
                      placeholder="CRD number"
                      onChange={(e) => handleCRD(e.target.value)}
                      onPaste={handlePaste}
                    />
                    <div id="crd_text_error" className="invalid-feedback">
                      Please enter a valid CRD number
                    </div>
                    Forgot your CRD number? Look it up using first and last name
                    at{" "}
                    <a
                      target="_blank"
                      href={"http://brokercheck.finra.org/"}
                      rel="noopener noreferrer"
                      className="link-underline"
                    >
                      {"brokercheck.finra.org"}
                    </a>
                  </div>
                </div>
                <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                  <h3>What firm are/were you registered with?*</h3>
                  <div style={{ paddingTop: "10px" }}>
                    <input
                      type="text"
                      id="firmRegisteredWith_text"
                      className="form-control"
                      value={firmRegisteredWith}
                      maxLength={320}
                      placeholder="Name of firm"
                      onChange={(e) => handleFirmRegisteredWith(e.target.value)}
                      onBlur={onBlur}
                      autoComplete="off"
                    />

                    <div
                      id="firmRegisteredWith_text_error"
                      className="invalid-feedback"
                    >
                      Please enter Name of firm
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
        <div>
          <div>
            <h3>Are you related to any current MassMutual associates?*</h3>
          </div>
          <div className="custom-control" style={{ paddingTop: "10px" }}>
            <div className="custom-control custom-radio">
              <input
                type="radio"
                name="relatedMM"
                id="relatedMM_yes"
                className="custom-control-input"
                checked={relatedToAssociates === "Y"}
                value="Y"
                onChange={(e) => handleRelatedMM(e.target.value)}
              />
              <label
                htmlFor="relatedMM_yes"
                className="custom-control-label"
                id="label_relatedMM_yes"
              >
                Yes
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                type="radio"
                name="relatedMM"
                className="custom-control-input"
                id="relatedMM_no"
                checked={relatedToAssociates === "N"}
                value="N"
                onChange={(e) => handleRelatedMM(e.target.value)}
              />
              <label
                htmlFor="relatedMM_no"
                className="custom-control-label"
                id="label_relatedMM_no"
              >
                No
              </label>
            </div>
            <div id="relatedMM_error" className="invalid-feedback">
              Please choose a response
            </div>
          </div>
        </div>
        {relatedToAssociates === "Y" && (
          <>
            <div>
              <h3>If yes, please provide their name?*</h3>
              <div style={{ paddingTop: "10px" }}>
                <input
                  type="text"
                  id="relatedName_text"
                  className="form-control"
                  value={relatedName}
                  maxLength={320}
                  placeholder="Name of employee"
                  onBlur={onBlur}
                  autoComplete="off"
                  onChange={(e) => handleRelatedName(e.target.value)}
                />

                <div id="relatedName_text_error" className="invalid-feedback">
                  Please enter name of MassMutual relative
                </div>
              </div>
            </div>
            <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
              <h3>What is their relationship to you?*</h3>
              <div style={{ paddingTop: "10px" }}>
                <DropdownButton
                  id="theirRelationship_id"
                  type="default"
                  variant="secondary"
                  title={theirRelationship}
                  selected={false}
                  onBlur={onBlur}
                  autoComplete="new-address"
                  onSelect={(key, event) => {
                    event.target.id = "theirRelationship_id";
                    event.target.value = key;
                    handleTheirRelationship(event);
                  }}
                  // onBlur={onBlur}
                  onClick={clickFunc}
                  onFocus={onFocusState}
                  required
                >
                  <Dropdown.Item
                    eventKey={"Child"}
                    id="theirRelationship_option"
                  >
                    Child
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey={"Parent"}
                    id="theirRelationship_option"
                  >
                    Parent
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey={"Sibling"}
                    id="theirRelationship_option"
                  >
                    Sibling
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey={"Spouse"}
                    id="theirRelationship_option"
                  >
                    Spouse
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey={"Other"}
                    id="theirRelationship_option"
                  >
                    Other
                  </Dropdown.Item>
                </DropdownButton>
              </div>

              <div
                id={"theirRelationship_option_id_error"}
                className="invalid-feedback"
              >
                Please select relationship
              </div>
            </div>
          </>
        )}
        <div>
          <div>
            <h3>
              If you become a MassMutual career agent, will you be subject to a
              non-compete agreement that will limit your sales and/or recruiting
              activities for a period of time?*
            </h3>
          </div>
          <div className="custom-control" style={{ paddingTop: "10px" }}>
            <div className="custom-control custom-radio">
              <input
                type="radio"
                name="nonCompeteAgreement"
                id="nonCompeteAgreement_yes"
                className="custom-control-input"
                checked={nonCompeteAgreement === "Y"}
                value="Y"
                onChange={(e) => handleNonCompeteAgreement(e.target.value)}
              />
              <label
                htmlFor="nonCompeteAgreement_yes"
                className="custom-control-label"
                id="label_nonCompeteAgreement_yes"
              >
                Yes
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                type="radio"
                name="nonCompeteAgreement"
                className="custom-control-input"
                id="nonCompeteAgreement_no"
                checked={nonCompeteAgreement === "N"}
                value="N"
                onChange={(e) => handleNonCompeteAgreement(e.target.value)}
              />
              <label
                htmlFor="nonCompeteAgreement_no"
                className="custom-control-label"
                id="label_nonCompeteAgreement_no"
              >
                No
              </label>
            </div>
            <div id="nonCompeteAgreement_error" className="invalid-feedback">
              Please choose a response
            </div>
          </div>
          {nonCompeteAgreement === "Y" && (
            <div style={{ paddingBottom: "10px" }}>
              <h3>If yes, with whom?*</h3>
              <div style={{ paddingTop: "10px" }}>
                <input
                  type="text"
                  id="nonCompeteAgreementYes_text"
                  className="form-control"
                  value={nonCompeteAgreementYes}
                  maxLength={320}
                  onBlur={onBlur}
                  placeholder="Name of firm"
                  autoComplete="off"
                  onChange={(e) => handleNonCompeteAgreementYes(e.target.value)}
                />

                <div
                  id="nonCompeteAgreementYes_text_error"
                  className="invalid-feedback"
                >
                  Please enter the name of the firm that you have a non-compete
                  agreement with
                </div>
              </div>
            </div>
          )}
        </div>
        {/* <div>
          <div>
            <h3>
              Were any recruiting activities, including interviews, conducted in
              New York City, or is the job for which you seek a contract located
              in New York City?*
            </h3>
          </div>
          <div className="custom-control" style={{ paddingTop: "10px" }}>
            <div className="custom-control custom-radio">
              <input
                type="radio"
                name="recruitingActivities"
                id="recruitingActivities_yes"
                className="custom-control-input"
                checked={recruitingActivities === "Y"}
                value="Y"
                onChange={(e) => handleRecruitingActivities(e.target.value)}
              />
              <label
                htmlFor="recruitingActivities_yes"
                className="custom-control-label"
                id="label_recruitingActivities_yes"
              >
                Yes
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                type="radio"
                name="recruitingActivities"
                className="custom-control-input"
                id="recruitingActivities_no"
                checked={recruitingActivities === "N"}
                value="N"
                onChange={(e) => handleRecruitingActivities(e.target.value)}
              />
              <label
                htmlFor="recruitingActivities_no"
                className="custom-control-label"
                id="label_recruitingActivities_no"
              >
                No
              </label>
            </div>
            <div id="recruitingActivities_error" className="invalid-feedback">
              Please choose a response
            </div>
          </div>
          {recruitingActivities === "Y" && (
            <div>
              <h3>Do you currently have any employees?*</h3>
              <div className="custom-control" style={{ paddingTop: "10px" }}>
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    name="currentlyEmployees"
                    id="currentlyEmployees_yes"
                    className="custom-control-input"
                    checked={currentlyEmployees === "Y"}
                    value="Y"
                    onChange={(e) => handleCurrentlyEmployees(e.target.value)}
                  />
                  <label
                    htmlFor="currentlyEmployees_yes"
                    className="custom-control-label"
                    id="label_currentlyEmployees_yes"
                  >
                    Yes
                  </label>
                </div>
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    name="currentlyEmployees"
                    className="custom-control-input"
                    id="currentlyEmployees_no"
                    checked={currentlyEmployees === "N"}
                    value="N"
                    onChange={(e) => handleCurrentlyEmployees(e.target.value)}
                  />
                  <label
                    htmlFor="currentlyEmployees_no"
                    className="custom-control-label"
                    id="label_currentlyEmployees_no"
                  >
                    No
                  </label>
                </div>{" "}
                <div id="currentlyEmployees_error" className="invalid-feedback">
                  Please choose a response
                </div>
              </div>
            </div>
          )}
        </div> */}
      </>

      <NavigationButtons
        onBack={handleShowBackModal}
        onNext={handleNext}
        isLoading={loading}
        nxtbtnid={"nxtaddtlbtn"}
        bckbtnid={"bckaddlbtn"}
      />

      <BackModal
        isOpen={showBackModal}
        closeBackModal={handleCloseBackModal}
        backClick={handleBackClick}
      />
    </>
  );
};

export default PersonalInfoAddl;
