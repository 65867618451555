import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cleanupCCB14Drp } from "../../../../../../api/CCB/DRP/terminationDisclosureDRP";
import { DRP_TYPES } from "../../../../../../api/helpers/constants";
import { CONTRACT_TYPE_DESCRIPTIONS } from "../../../../../../constants/Contract";
import { FORM_ID } from "../../../../../../constants/Forms";
import { ccbActions, logoutActions } from "../../../../../../store/actions";
import {
  fetchCCB,
  postCCB,
  putCCB,
  putCCBStatus,
} from "../../../../../../store/middlewares";
import { loadingStatus } from "../../../../../../store/state";
import NavigationButtons from "../../../../../SharedComponents/NavigationButtons";
import BackButtonModal from "../../../../Forms/BackButtonModal";
import CustomCard from "../../common/CustomCard";
import DeleteModal from "../../common/DeleteModal";
import Toast from "../../common/Toast";
import {
  INTRO_REV,
  INTRO_TEXT,
  KEYS,
  RADIO,
  SECTION,
  TOAST_MESSAGE,
} from "../../constants";
import {
  getSpecificObjByKeys,
  getValues,
  isChecked,
  isEachChecked,
  isSomeChecked,
} from "../../helpers";
import { useClickOutside } from "../../hooks/useClickOutside";
import { headerText } from "../constants";
import {
  financialDisclosureBondDisclosures,
  financialDisclosureJudgementLienDisclosures,
  financialDisclosureOrgBankruptcy,
  financialDisclosurePersBankruptcy,
  initialState,
} from "../state";
import CustomSubCategoryCard from "./CustomSubCategoryCard";
import "./FinancialDisclosureForm.scss";

const FinancialDisclosureForm = ({
  header,
  toggleForm,
  oktaSubId,
  updateCCBForm,
  backFormId,
  nextFormId,
  updateCCB14Step,
  updateCCB14CompletedInd,
  contractType,
  persBankruptcy,
  orgBankruptcy,
  bondDiscl,
  judgLienDisclosures,
  bondList,
  judgmentLienList,
  bankruptcyList,
  LoadingProp,
  setLoadingProp,
  setData,
  unchecked,
}) => {
  const dispatch = useDispatch();

  const toastRef = useRef(null);
  const containerRef = useRef(null);

  const { error, loading, isFirstLoad, data } = useSelector(
    (state) => state.ccb14
  );

  const [isPageChanged, setIsPageChanged] = useState(false);
  const [isShowDRPCleanupModal, setShowDRPCleanupModal] = useState(false);
  const [selectedSection, setSelectedSection] = useState(null);
  const [drpCleanupList, setDrpCleanupList] = useState(null);
  const [drpCleanupType, setDrpCleanupType] = useState(null);

  const isLoading = loading === loadingStatus.PENDING;
  let isError = error || loading === loadingStatus.FAILED;

  const initializeSectionStates = {
    [SECTION.personalBankruptcy]: false,
    [SECTION.organizationBankruptcy]: false,
    [SECTION.bondDisclosures]: false,
    [SECTION.judgementLienDisclosures]: false,
  };

  const [isRequired, setIsRequired] = useState(false);
  const [isCardOpen, setIsCardOpen] = useState({
    ...initializeSectionStates,
  });
  const [showBackModal, setShowBackModal] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const [isEdit, setIsEdit] = useState({
    ...initializeSectionStates,
  });

  const [isSaved, setIsSaved] = useState({
    ...initializeSectionStates,
  });

  const valuesA = useMemo(
    () => getValues(data, KEYS.personalBankruptcy),
    [data]
  );
  const valuesB = useMemo(
    () => getValues(data, KEYS.organizationBankruptcy),
    [data]
  );
  const valuesC = useMemo(() => getValues(data, KEYS.bondDisclosures), [data]);
  const valuesD = useMemo(
    () => getValues(data, KEYS.judgementLienDisclosures),
    [data]
  );

  const isSaveDisabledA = isEachChecked(valuesA);
  const isSaveDisabledB = isEachChecked(valuesB);
  const isSaveDisabledC = isEachChecked(valuesC);
  const isSaveDisabledD = isEachChecked(valuesD);

  const updateStatus = async (data) => {
    try {
      await dispatch(
        putCCBStatus({ oktaSubId, data: { financialdisclstatus: data } })
      );
    } catch (err) {
      console.log(err);
    }
  };

  const toggleLeftNav = () => {
    //If all "No" values, update green checkmark on save. If not, keep as 1/2
    const isNo = (currentValue) => currentValue === false;
    const isYes = (currentValue) => currentValue === true;

    let isNoValues =
      valuesA.every(isNo) &&
      valuesB.every(isNo) &&
      valuesC.every(isNo) &&
      valuesD.every(isNo);

    const isYesValues =
      valuesA.some(isYes) ||
      valuesB.some(isYes) ||
      valuesC.some(isYes) ||
      valuesD.some(isYes);

    if (isNoValues) {
      updateCCB14CompletedInd({ finDisclosureCompleteInd: true });
      updateStatus(null);
    } else if (isYesValues) {
      updateCCB14Step({ finDisclosureCompleteStep: "1/2" });
      updateCCB14CompletedInd({ finDisclosureCompleteInd: false });
    }
  };

  const submitData = useMemo(() => {
    return {
      [SECTION.personalBankruptcy]: getSpecificObjByKeys(
        data,
        KEYS.personalBankruptcy
      ),
      [SECTION.organizationBankruptcy]: getSpecificObjByKeys(
        data,
        KEYS.organizationBankruptcy
      ),
      [SECTION.bondDisclosures]: getSpecificObjByKeys(
        data,
        KEYS.bondDisclosures
      ),
      [SECTION.judgementLienDisclosures]: getSpecificObjByKeys(
        data,
        KEYS.judgementLienDisclosures
      ),
    };
  }, [data]);

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  useEffect(() => {
    try {
      const initData = async () => {
        const { payload } = await dispatch(fetchCCB(oktaSubId));
        if (payload) {
          const valuesA = getValues(payload, KEYS.personalBankruptcy);
          const valuesB = getValues(payload, KEYS.organizationBankruptcy);
          const valuesC = getValues(payload, KEYS.bondDisclosures);
          const valuesD = getValues(payload, KEYS.judgementLienDisclosures);

          const a = valuesA.some(isChecked);
          const b = valuesB.some(isChecked);
          const c = valuesC.some(isChecked);
          const d = valuesD.some(isChecked);

          setIsSaved({
            [SECTION.personalBankruptcy]: a,
            [SECTION.organizationBankruptcy]: b,
            [SECTION.bondDisclosures]: c,
            [SECTION.judgementLienDisclosures]: d,
          });

          setIsEdit({
            [SECTION.personalBankruptcy]: !a,
            [SECTION.organizationBankruptcy]: !b,
            [SECTION.bondDisclosures]: !c,
            [SECTION.judgementLienDisclosures]: !d,
          });
        }
      };

      if (loading !== loadingStatus.SUCCEDED) {
        initData();
      } else {
        const a = valuesA.some(isChecked);
        const b = valuesB.some(isChecked);
        const c = valuesC.some(isChecked);
        const d = valuesD.some(isChecked);

        setIsSaved({
          [SECTION.personalBankruptcy]: a,
          [SECTION.organizationBankruptcy]: b,
          [SECTION.bondDisclosures]: c,
          [SECTION.judgementLienDisclosures]: d,
        });

        setIsEdit({
          [SECTION.personalBankruptcy]: !a,
          [SECTION.organizationBankruptcy]: !b,
          [SECTION.bondDisclosures]: !c,
          [SECTION.judgementLienDisclosures]: !d,
        });

        const optionsArr = [a, b, c, d];
        const sectionStartNr = SECTION.personalBankruptcy;

        const firstIncompleteSection =
          optionsArr.findIndex((data) => !data) > -1
            ? optionsArr.findIndex((data) => !data) + sectionStartNr
            : null;

        if (firstIncompleteSection !== null) {
          setIsCardOpen({
            ...isCardOpen,
            [firstIncompleteSection]: true,
          });
        }
      }
    } catch (err) {
      console.error({ err });
    }

    return () => {
      const a = valuesA.some(isChecked);
      const b = valuesB.some(isChecked);
      const c = valuesC.some(isChecked);
      const d = valuesD.some(isChecked);

      setIsSaved({
        [SECTION.personalBankruptcy]: a,
        [SECTION.organizationBankruptcy]: b,
        [SECTION.bondDisclosures]: c,
        [SECTION.judgementLienDisclosures]: d,
      });

      setIsEdit({
        [SECTION.personalBankruptcy]: !a,
        [SECTION.organizationBankruptcy]: !b,
        [SECTION.bondDisclosures]: !c,
        [SECTION.judgementLienDisclosures]: !d,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPageChanged]);

  const [formHasChanged, setFormHasChanged] = useState(false);

  useEffect(() => {
    if (formHasChanged) {
      dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: true }));
    }
  }, [formHasChanged, dispatch]);

  const handleChangeChecked = (e) => {
    const { name, value } = e.target;
    let newValue = value;

    setFormHasChanged(true);
    dispatch(ccbActions.setIsBack(false));

    if (
      isSaved[SECTION.personalBankruptcy] &&
      isSaved[SECTION.organizationBankruptcy] &&
      isSaved[SECTION.bondDisclosures] &&
      isSaved[SECTION.judgementLienDisclosures]
    ) {
      updateCCB14Step({ finDisclosureCompleteStep: "1/2" });
      updateCCB14CompletedInd({ finDisclosureCompleteInd: false });
    }

    switch (value) {
      case RADIO.yes:
        newValue = true;
        break;
      case RADIO.no:
        newValue = false;
        break;
      default:
        newValue = null;
        break;
    }

    dispatch(ccbActions.setChecked({ [name]: newValue }));
  };

  const handleOnEditClick = (section) => {
    setIsEdit((prevState) => ({ ...prevState, [section]: true }));
  };

  const closeCleanupModalHandler = async () => {
    setShowDRPCleanupModal(false);
  };

  const onCleanupModalHandler = async () => {
    if (selectedSection === null) {
      return;
    }
    setLoadingProp(true);

    await cleanupCCB14Drp({
      oktaSubId,
      drpTypeId: [DRP_TYPES.Judgement, DRP_TYPES.Bond, DRP_TYPES.Bankruptcy],
      data: {
        data: {
          fin: unchecked,
        },
      },
    });

    setData();
    await handleOnSaveClick(selectedSection);
    setShowDRPCleanupModal(false);
    setLoadingProp(false);
  };

  const qKeyAdd = (drpList) => {
    let keys = [];
    for (let i in drpList) {
      if (!(drpList[i].key in keys)) {
        keys.push(drpList[i].key);
      }
    }
    return keys;
  };

  const delKeyAdd = (drpList, questionKeys, drpType) => {
    let keys = [];
    let qVal;
    if (drpType === "orgbankruptcy") {
      for (let i in drpList) {
        if (Object.keys(drpList[i].bankruptcydrp).includes("drpList")) {
          for (let j in drpList[i].bankruptcydrp.drpList) {
            qVal = drpList[i].bankruptcydrp.drpList[j];

            if (
              !questionKeys.includes(qVal) &&
              !keys.includes(drpList[i].ccbdrpid)
            ) {
              keys.push({
                qKey: qVal,
                isDraft: "No",
                orgName: [drpList[i].bankruptcydrp.orgName] || [],
                status:
                  [
                    drpList[i].bankruptcydrp.dispositionType !== ""
                      ? drpList[i].bankruptcydrp.dispositionType
                      : "N/A",
                  ] || [],
                types:
                  [
                    drpList[i].bankruptcydrp.actionType !== "Compromise"
                      ? drpList[i].bankruptcydrp.actionType
                      : drpList[i].bankruptcydrp.actionType +
                        "; " +
                        drpList[i].bankruptcydrp.compCreditorName,
                  ] || [],
                date: [drpList[i].bankruptcydrp.actionDate] || [],
                dataIndex: drpList[i].ccbdrpid,
                section: headerText.ORG_BANKRUPTCY,
              });
            }
          }
        }
      }
    } else if (drpType === "persbankruptcy") {
      for (let i in drpList) {
        if (!Object.keys(drpList[i].bankruptcydrp).includes("drpList")) {
          qVal = "14K(1)";
          if (
            !questionKeys.includes(qVal) &&
            !keys.includes(drpList[i].ccbdrpid)
          ) {
            keys.push({
              qKey: qVal,
              isDraft: "No",
              types:
                [
                  drpList[i].bankruptcydrp.actionType !== "Compromise"
                    ? drpList[i].bankruptcydrp.actionType
                    : drpList[i].bankruptcydrp.actionType +
                      "; " +
                      drpList[i].bankruptcydrp.compCreditorName,
                ] || [],
              date: [drpList[i].bankruptcydrp.actionDate] || [],
              status:
                [
                  drpList[i].bankruptcydrp.dispositionType !== ""
                    ? drpList[i].bankruptcydrp.dispositionType
                    : "N/A",
                ] || [],
              dataIndex: drpList[i].ccbdrpid,
              section: headerText.PERS_BANKRUPTCY,
            });
          }
        }
      }
    } else {
      for (let i in drpList) {
        if (drpType === "bond") {
          qVal = "14L";
        } else {
          qVal = "14M";
        }
        if (
          !questionKeys.includes(qVal) &&
          !keys.includes(drpList[i].ccbdrpid) &&
          drpType === "bond"
        ) {
          keys.push({
            qKey: qVal,
            isDraft: "No",
            types: [drpList[i].bonddrp.dispositionType] || [],
            date: [drpList[i].bonddrp.firmName] || [],
            dataIndex: drpList[i].ccbdrpid,
            section: headerText.BOND_DISCL,
          });
        } else if (
          !questionKeys.includes(qVal) &&
          !keys.includes(drpList[i].ccbdrpid) &&
          drpType === "judgmentlien"
        ) {
          keys.push({
            qKey: qVal,
            isDraft: "No",
            types:
              [
                drpList[i].judgmentliendrp.judgmentLienDetails.judgmentLienType,
              ] || [],
            date:
              [
                drpList[i].judgmentliendrp.judgmentLienDetails
                  .dateFiledWithCourt,
              ] || [],
            orgName:
              drpList[i].judgmentliendrp.judgmentLienDetails.judgmentLienHolder,
            dataIndex: drpList[i].ccbdrpid,
            section: headerText.JUDG_LIEN_DISCL,
          });
        }
      }
    }

    return keys;
  };

  const handleDeleteOnSave = async (section) => {
    handleCloseToast();
    setSelectedSection(section);
    let questionKeys = [];

    questionKeys.push(...qKeyAdd(persBankruptcy));
    questionKeys.push(...qKeyAdd(orgBankruptcy));
    questionKeys.push(...qKeyAdd(bondDiscl));
    questionKeys.push(...qKeyAdd(judgLienDisclosures));

    let deleteKeys = [];
    if (section === 11) {
      deleteKeys.push(
        ...delKeyAdd(bankruptcyList, questionKeys, "persbankruptcy")
      );
    } else if (section === 12) {
      deleteKeys.push(
        ...delKeyAdd(bankruptcyList, questionKeys, "orgbankruptcy")
      );
    }
    if (section === 13) {
      deleteKeys.push(...delKeyAdd(bondList, questionKeys, "bond"));
    }
    if (section === 14) {
      deleteKeys.push(
        ...delKeyAdd(judgmentLienList, questionKeys, "judgmentlien")
      );
    }

    setDrpCleanupList(deleteKeys);

    if (deleteKeys.length > 0) {
      setDrpCleanupList(deleteKeys);
      setDrpCleanupType(deleteKeys[0].section);
      setShowDRPCleanupModal(true);
    } else {
      handleOnSaveClick(section);
    }
  };

  const handleOnSaveClick = async (section) => {
    const a = valuesA.some(isChecked);
    const b = valuesB.some(isChecked);
    const c = valuesC.some(isChecked);
    const d = valuesD.some(isChecked);

    const optionsArr = [a, b, c, d];
    const sectionStartNr = SECTION.personalBankruptcy;

    const firstIncompleteSection =
      optionsArr.findIndex((data) => !data) > -1
        ? optionsArr.findIndex((data) => !data) + sectionStartNr
        : null;

    if (firstIncompleteSection !== null) {
      setIsCardOpen({
        ...isCardOpen,
        [firstIncompleteSection]: true,
      });
    }

    setFormHasChanged(false);

    setIsCardOpen((prevState) => ({ ...prevState, [section]: false }));

    if (isFirstLoad) {
      try {
        await dispatch(postCCB({ oktaSubId, data: submitData[section] }));
      } catch (err) {
        console.log(err);
        isError = `${err}`;
      }

      setIsSaved((prevState) => ({ ...prevState, [section]: true }));
    } else {
      try {
        await dispatch(putCCB({ oktaSubId, data: submitData[section] }));
      } catch (err) {
        console.log(err);
        isError = `${err}`;
      }

      setIsSaved((prevState) => ({ ...prevState, [section]: true }));
    }

    toggleLeftNav();
    setIsEdit((prevState) => ({ ...prevState, [section]: false }));
    setShowToast(true);

    window.scrollTo(0, 0);
  };

  const handleCloseToast = () => setShowToast(false);

  useEffect(() => {
    let handler = (e) => {
      if (!toastRef?.current?.contains(e.target)) {
        handleCloseToast();
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const cleanup = (isFetch = false) => {
    dispatch(ccbActions.setChecked({ ...initialState }));
    setIsRequired(false);
    setFormHasChanged(false);
    handleCloseToast();

    setIsCardOpen({
      ...initializeSectionStates,
    });
    setIsEdit({
      ...initializeSectionStates,
    });

    if (!isFetch) {
      setIsPageChanged((prev) => !prev);
    }
  };

  const handleCloseBackModal = () => setShowBackModal(false);

  const handleShowBackModal = () => {
    if (
      ((isSomeChecked(valuesA) && isEdit[SECTION.personalBankruptcy]) ||
        (isSomeChecked(valuesB) && isEdit[SECTION.organizationBankruptcy]) ||
        (isSomeChecked(valuesC) && isEdit[SECTION.bondDisclosures]) ||
        (isSomeChecked(valuesD) && isEdit[SECTION.judgementLienDisclosures]) ||
        (isSomeChecked(valuesA) && !isSaved[SECTION.personalBankruptcy]) ||
        (isSomeChecked(valuesB) && !isSaved[SECTION.organizationBankruptcy]) ||
        (isSomeChecked(valuesC) && !isSaved[SECTION.bondDisclosures]) ||
        (isSomeChecked(valuesD) &&
          !isSaved[SECTION.judgementLienDisclosures])) &&
      formHasChanged
    ) {
      setShowBackModal(true);
    } else {
      dispatch(ccbActions.setIsBack(true));
      toggleForm(backFormId);
      setIsPageChanged((prev) => !prev);
    }
  };

  const handleBackClick = async () => {
    cleanup();
    toggleForm(backFormId);

    if (
      isSaved[SECTION.personalBankruptcy] &&
      isSaved[SECTION.organizationBankruptcy] &&
      isSaved[SECTION.bondDisclosures] &&
      isSaved[SECTION.judgementLienDisclosures]
    ) {
      updateCCB14CompletedInd({ finDisclosureCompleteInd: true });
    }

    dispatch(ccbActions.setIsBack(true));
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: true }));
    handleCloseBackModal();
  };

  const handleNext = async () => {
    dispatch(ccbActions.setIsBack(false));
    cleanup(true);
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: true }));
    const isYes = (currentValue) => currentValue === true;

    let isYesValues =
      valuesA.some(isYes) ||
      valuesB.some(isYes) ||
      valuesC.some(isYes) ||
      valuesD.some(isYes);

    if (loading === loadingStatus.IDLE || loading === loadingStatus.SUCCEDED) {
      const { payload } = await dispatch(fetchCCB(oktaSubId));

      isYesValues =
        getValues(payload, KEYS.personalBankruptcy).some(isYes) ||
        getValues(payload, KEYS.organizationBankruptcy).some(isYes) ||
        getValues(payload, KEYS.bondDisclosures).some(isYes) ||
        getValues(payload, KEYS.judgementLienDisclosures).some(isYes);
    }

    if (isYesValues) {
      updateCCBForm(nextFormId);
    } else {
      updateCCB14CompletedInd({ finDisclosureCompleteInd: true });
      if (contractType === CONTRACT_TYPE_DESCRIPTIONS.MMLIS) {
        toggleForm(FORM_ID.MMLIS_INTRO);
      } else {
        toggleForm(FORM_ID.DIRECT_DEPOSIT);
      }
    }
  };

  const renderSubcategory = (category, section) => {
    const isPersonalBankruptcyDisabled =
      isSaved[SECTION.personalBankruptcy] &&
      !isEdit[SECTION.personalBankruptcy] &&
      section === SECTION.personalBankruptcy;

    const isOrganizationBankruptcyDisabled =
      isSaved[SECTION.organizationBankruptcy] &&
      !isEdit[SECTION.organizationBankruptcy] &&
      section === SECTION.organizationBankruptcy;

    const isBondDisclosuresDisabled =
      isSaved[SECTION.bondDisclosures] &&
      !isEdit[SECTION.bondDisclosures] &&
      section === SECTION.bondDisclosures;

    const isJudgementLienDisclosuresDisabled =
      isSaved[SECTION.judgementLienDisclosures] &&
      !isEdit[SECTION.judgementLienDisclosures] &&
      section === SECTION.judgementLienDisclosures;

    const disabledSave =
      isPersonalBankruptcyDisabled ||
      isOrganizationBankruptcyDisabled ||
      isBondDisclosuresDisabled ||
      isJudgementLienDisclosuresDisabled ||
      (!isSaveDisabledA && section === SECTION.personalBankruptcy) ||
      (!isSaveDisabledB && section === SECTION.organizationBankruptcy) ||
      (!isSaveDisabledC && section === SECTION.bondDisclosures) ||
      (!isSaveDisabledD && section === SECTION.judgementLienDisclosures);

    return (
      <CustomSubCategoryCard
        data={data}
        category={category}
        handleOnEditClick={handleOnEditClick}
        handleChangeChecked={handleChangeChecked}
        handleOnSaveClick={handleDeleteOnSave}
        section={section}
        isSaved={isSaved}
        isLoading={isLoading}
        isRequired={isRequired}
        disabledSave={disabledSave}
        ref={containerRef}
      />
    );
  };

  useClickOutside(toastRef, containerRef, showToast, handleCloseToast);

  return (
    <div className="financial-disclosure-form-wrapper" ref={containerRef}>
      <Toast
        showToast={showToast}
        closeToast={handleCloseToast}
        text={TOAST_MESSAGE}
        ref={toastRef}
      />

      <div className="row">
        <div className="col-md-12">
          <div className="eyebrow">
            <strong id={`eyebrow-${header.substring(0, 3)}`}>
              Character, Credit, and Bonding
            </strong>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <h1 id={`header-${header.substring(0, 3)}`}>{header}</h1>
          <h2
            id={`intro-rev-${header.substring(0, 3)}`}
            style={{ color: "black" }}
          >
            {INTRO_REV}
          </h2>
        </div>
      </div>

      <div className="row" ref={containerRef}>
        <div className="col-md-12">
          <p id={`intro-text-${header.substring(0, 3)}`}>{INTRO_TEXT}</p>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <CustomCard
            isOpen={isCardOpen[SECTION.personalBankruptcy]}
            setIsOpen={setIsCardOpen}
            headerText={headerText.PERS_BANKRUPTCY}
            isCompleted={isSaved[SECTION.personalBankruptcy]}
            index={SECTION.personalBankruptcy}
          >
            {renderSubcategory(
              financialDisclosurePersBankruptcy(
                isSaved[SECTION.personalBankruptcy] &&
                  !isEdit[SECTION.personalBankruptcy]
              ),
              SECTION.personalBankruptcy
            )}
          </CustomCard>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <CustomCard
            isOpen={isCardOpen[SECTION.organizationBankruptcy]}
            setIsOpen={setIsCardOpen}
            headerText={headerText.ORG_BANKRUPTCY}
            isCompleted={isSaved[SECTION.organizationBankruptcy]}
            index={SECTION.organizationBankruptcy}
          >
            {renderSubcategory(
              financialDisclosureOrgBankruptcy(
                isSaved[SECTION.organizationBankruptcy] &&
                  !isEdit[SECTION.organizationBankruptcy]
              ),
              SECTION.organizationBankruptcy
            )}
          </CustomCard>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <CustomCard
            isOpen={isCardOpen[SECTION.bondDisclosures]}
            setIsOpen={setIsCardOpen}
            headerText={headerText.BOND_DISCL}
            isCompleted={isSaved[SECTION.bondDisclosures]}
            index={SECTION.bondDisclosures}
          >
            {renderSubcategory(
              financialDisclosureBondDisclosures(
                isSaved[SECTION.bondDisclosures] &&
                  !isEdit[SECTION.bondDisclosures]
              ),
              SECTION.bondDisclosures
            )}
          </CustomCard>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <CustomCard
            isOpen={isCardOpen[SECTION.judgementLienDisclosures]}
            setIsOpen={setIsCardOpen}
            headerText={headerText.JUDG_LIEN_DISCL}
            isCompleted={isSaved[SECTION.judgementLienDisclosures]}
            index={SECTION.judgementLienDisclosures}
          >
            {renderSubcategory(
              financialDisclosureJudgementLienDisclosures(
                isSaved[SECTION.judgementLienDisclosures] &&
                  !isEdit[SECTION.judgementLienDisclosures]
              ),
              SECTION.judgementLienDisclosures
            )}
          </CustomCard>
        </div>
      </div>
      <NavigationButtons
        onBack={handleShowBackModal}
        onNext={handleNext}
        nxtbtnid={"finDisNext"}
        bckbtnid={"finDisBack"}
        isLoading={isLoading}
        isDisabled={
          !(
            isSaved[SECTION.personalBankruptcy] &&
            isSaved[SECTION.organizationBankruptcy] &&
            isSaved[SECTION.bondDisclosures] &&
            isSaved[SECTION.judgementLienDisclosures]
          ) ||
          !!isError ||
          !!isLoading
        }
      />

      {isShowDRPCleanupModal && (
        <DeleteModal
          isOpen={isShowDRPCleanupModal}
          showSpinner={false}
          isDRP
          type={drpCleanupType}
          list={drpCleanupList}
          description="You had previously provided a DRP(s) associated with this
          question. Changing your response will delete the following
          DRP(s). Are you sure you want to proceed?"
          message="Are You Sure You Want To Proceed?"
          closeClick={closeCleanupModalHandler}
          deleteClick={onCleanupModalHandler}
        />
      )}

      <BackButtonModal
        isOpen={showBackModal}
        closeBackModal={handleCloseBackModal}
        backClick={handleBackClick}
      />
    </div>
  );
};

export default FinancialDisclosureForm;
