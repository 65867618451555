import { KEYS } from "../../constants";
import {
  getDRPSummaryQuestions,
  getDRPSummaryUncheckedQuestionKeys,
  getSpecificObjByKeys,
} from "../../helpers";
import { DRPSummaryTerminationDisclosure } from "../state";

export const isDrpSummaryPage = (data) => {
  const terminationDisclosureObj = getSpecificObjByKeys(
    data,
    KEYS.terminationDisclosures
  );

  const termDiscl = getDRPSummaryQuestions(
    DRPSummaryTerminationDisclosure,
    terminationDisclosureObj
  );

  const unchecked = getDRPSummaryUncheckedQuestionKeys(
    DRPSummaryTerminationDisclosure,
    terminationDisclosureObj
  );

  return { termDiscl, unchecked };
};
