import React from "react";
import { BrowserRouter as Router, Route, withRouter } from "react-router-dom";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { LoginCallback, Security, SecureRoute } from "@okta/okta-react";
import Profile from "./Components/SharedComponents/Profile";
import StartApplicationMain from "./Components/StartApplication/StartApplicationMain";
import ApplicationConfirmation from "./Components/LandingPages/ApplicationConfirmation";
import HomePage from "./Components/LandingPages/HomePage";
import UBIQ from "./Components/LandingPages/UBIQ";
import "./assets/stylesheets/App.scss";
import "../node_modules/blueprint-css/mm-business/mm-business.bundle.css";
import { useDispatch } from "react-redux";
import { authActions } from "./store/actions";
import ErrorPage from "./Components/SharedComponents/ErrorPage";

function App(props) {
  const dispatch = useDispatch();

  const oktaAuth = new OktaAuth({
    issuer: window.REACT_APP_CANDIDATE_OKTA_ISSUER,
    clientId: window.REACT_APP_CANDIDATE_OKTA_CLIENTID,
    redirectUri: window.location.origin + "/login/callback",
  });

  // Adding Tealium Scripts to Header and Body
  const scripth = document.createElement("script");
  scripth.type = "text/javascript";
  scripth.src = window.REACT_APP_CANDIDATE_TEALIUM_HEAD;
  document.head.appendChild(scripth);

  const scriptb = document.createElement("script");
  scriptb.type = "text/javascript";
  scriptb.innerHTML =
    " (function(a,b,c,d){ a='" +
    window.REACT_APP_CANDIDATE_TEALIUM_BODY +
    "'; b=document; c='script'; d=b.createElement(c); d.src=a; d.type='text/java'+c; d.async=true; a=b.getElementsByTagName(c)[0]; a.parentNode.insertBefore(d,a); })(); ";
  document.body.appendChild(scriptb);

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    const newURI = toRelativeUrl(originalUri || "/", window.location.origin);
    const decodedURI = decodeURIComponent(newURI);
    props.history.replace(decodedURI);
    window.location.replace(decodedURI);
  };

  oktaAuth.tokenManager.get("accessToken").then(function (data) {
    if (data) {
      dispatch(authActions.setAuthenticators(data));
    } else {
      console.log("token not found!!!");
    }
  });

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Route path="/login/callback" component={LoginCallback} />
      <SecureRoute path="/startapplication" component={StartApplicationMain} />
      <SecureRoute
        path="/applicationconfirmation"
        component={ApplicationConfirmation}
      />
      <SecureRoute path="/errorPage" exact={true} component={ErrorPage} />
      <SecureRoute path="/" exact={true} component={HomePage} />
      <SecureRoute path="/UBIQ" exact={true} component={UBIQ} />
      <SecureRoute path="/profile" component={Profile} />
    </Security>
  );
}

const AppWithRouterAccess = withRouter(App);

function RouterApp() {
  return (
    <Router>
      <AppWithRouterAccess />
    </Router>
  );
}

export default RouterApp;
