import React, { useEffect, useMemo } from "react";
import { groupArrayByKey } from "../../helpers";
import DRPCard from "../../common/DRPCard";

import "./CriminalDisclosureDRPSummary.scss";

import { CCB_FORM_ID, headerText } from "../constants";
import DRPSummaryListingCard from "../../common/DRPSummaryListingCard";
import { useDispatch } from "react-redux";
import { logoutActions } from "../../../../../../store/actions";
import {
  deleteSingleCCB14DrpOrg,
  getSingleCCBDRPIndOrgByQkey,
} from "../../../../../../api/CCB/DRP/criminalDisclosureDRP";
import { DRP_TYPES } from "../../../../../../api/helpers/constants";
import { SECTION } from "../../constants";
import { putCCBStatus } from "../../../../../../store/middlewares";

const CriminalDisclosureDRPSummary = ({
  updateCCBForm,
  oktaSubId,
  setIsDRPNextDisabled,
  updateCCB14Step,
  updateCCB14CompletedInd,
  setIsDataRefetch,
  setIsDRPCompleted,
  isLoading,
  setIsLoading,
  ind = [],
  org = [],
  data,
}) => {
  const dispatch = useDispatch();

  const resetShowBackModal = () =>
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: false }));

  const existingIndKeys = ind?.flatMap((x) => x.key);
  const currIndKeys = [...new Set(data?.ind.flatMap((x) => x.qKey))];
  const currOrgKeys = org?.flatMap((x) => x.key);
  const existingOrgKeys = [...new Set(data?.org.flatMap((x) => x.qKey))];

  const indDraft = data?.ind.find((x) => x.isDraft === true);
  const orgDraft = data?.org.find((x) => x.isDraft === true);

  const isDisabled = !!(
    existingOrgKeys.length !== currOrgKeys.length ||
    currIndKeys.length !== existingIndKeys.length ||
    indDraft ||
    orgDraft
  );

  const updateStatus = async (data) => {
    try {
      await dispatch(
        putCCBStatus({ oktaSubId, data: { criminaldisclosurestatus: data } })
      );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!isLoading) {
      setIsDRPNextDisabled(isDisabled);

      if (!!isDisabled) {
        updateCCB14Step({ crimDisclosureCompleteStep: "2/2" });
        updateCCB14CompletedInd({ crimDisclosureCompleteInd: false });
        setIsDRPCompleted(false);
      } else {
        updateCCB14CompletedInd({ crimDisclosureCompleteInd: true });
        setIsDRPCompleted(true);
      }

      updateStatus(isDisabled ? "1/2" : null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisabled, isLoading]);

  const handleOnClickIND = () => {
    resetShowBackModal();

    updateCCBForm(CCB_FORM_ID.ADD_DRP_IND_COURT_DETAILS, {
      section: headerText.IND_CRIM_DISCL,
      values: ind,
      isReset: true,
    });
  };

  const handleOnClickORG = () => {
    resetShowBackModal();

    updateCCBForm(CCB_FORM_ID.ADD_DRP_ORG_DETAILS, {
      section: headerText.ORG_CRIM_DISCL,
      values: org,
      isReset: true,
    });
  };

  const listInd = useMemo(() => groupArrayByKey(data?.ind, "qKey"), [data.ind]);
  const listOrg = useMemo(() => groupArrayByKey(data?.org, "qKey"), [data.org]);

  const sectionHandler = (section) => {
    let s = null;

    switch (section) {
      case SECTION.individualCriminalDisclosure:
        s = "ind";
        break;
      case SECTION.organizationCriminalDisclosure:
        s = "org";
        break;
      default:
        break;
    }

    return s;
  };

  const editDRPOrg = async ({ section, questionKey, dataIndex, isDraft }) => {
    setIsLoading(true);
    const data = await getSingleCCBDRPIndOrgByQkey({
      oktaSubId,
      section: sectionHandler(section),
      qKey: questionKey,
      drpTypeId: DRP_TYPES.Criminal,
      index: dataIndex,
    });
    setIsLoading(false);

    updateCCBForm(CCB_FORM_ID.ADD_DRP_ORG_DETAILS, {
      section: headerText.ORG_CRIM_DISCL,
      values: org,
      isEdit: true,
      data: data,
      isDraft,
    });
  };

  const editDRPInd = async ({ section, questionKey, dataIndex, isDraft }) => {
    setIsLoading(true);
    const data = await getSingleCCBDRPIndOrgByQkey({
      oktaSubId,
      section: sectionHandler(section),
      qKey: questionKey,
      drpTypeId: DRP_TYPES.Criminal,
      index: dataIndex,
    });
    setIsLoading(false);

    updateCCBForm(CCB_FORM_ID.ADD_DRP_IND_COURT_DETAILS, {
      section: headerText.IND_CRIM_DISCL,
      values: ind,
      isEdit: true,
      data: data,
      isDraft,
    });
  };

  const deleteDRPIndOrg = async ({ section, questionKey, dataIndex }) => {
    setIsLoading(true);

    await deleteSingleCCB14DrpOrg({
      oktaSubId,
      section: sectionHandler(section),
      questionKey,
      index: dataIndex,
      drpTypeId: DRP_TYPES.Criminal,
    });

    setIsLoading(false);

    updateCCBForm(CCB_FORM_ID.DRP_SUMMARY, {
      isDelete: true,
      index: dataIndex,
    });

    setIsDataRefetch(true);
  };

  return (
    <div className="criminal-disclosure-drp-summary">
      {ind?.length ? (
        <DRPCard header={headerText.IND_CRIM_DISCL} onClick={handleOnClickIND}>
          {ind?.map(({ key, mainQuestion, question }, index) => (
            <DRPSummaryListingCard
              key={`${index + 1}_${key}`}
              questionKey={key}
              mainQuestion={mainQuestion}
              question={question}
              index={index}
              length={ind?.length}
              list={listInd[key]}
              isSummaryPage
              isLoading={isLoading}
              editDRP={editDRPInd}
              deleteDRP={deleteDRPIndOrg}
              section={SECTION.individualCriminalDisclosure}
            />
          ))}
        </DRPCard>
      ) : null}

      {org?.length ? (
        <DRPCard header={headerText.ORG_CRIM_DISCL} onClick={handleOnClickORG}>
          {org?.map(({ key, mainQuestion, question }, index) => (
            <DRPSummaryListingCard
              key={`${index}_${key}`}
              questionKey={key}
              mainQuestion={mainQuestion}
              question={question}
              index={index}
              length={org?.length}
              list={listOrg[key]}
              editDRP={editDRPOrg}
              deleteDRP={deleteDRPIndOrg}
              isSummaryPage
              isLoading={isLoading}
              section={SECTION.organizationCriminalDisclosure}
            />
          ))}
        </DRPCard>
      ) : null}
    </div>
  );
};

export default CriminalDisclosureDRPSummary;
