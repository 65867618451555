import { KEYS } from "../../constants";
import {
  getDRPSummaryQuestions,
  getDRPSummaryUncheckedQuestionKeys,
  getSpecificObjByKeys,
} from "../../helpers";
import { DRPSummaryCustomerComplaintArbitrationCivilLitigationObj } from "../state";

export const isDrpSummaryPage = (data) => {
  const customerComplaintArbitrationCivilLitigationObj = getSpecificObjByKeys(
    data,
    KEYS.customerComplaintArbitrationCivilLitigation
  );

  const custCompl = getDRPSummaryQuestions(
    DRPSummaryCustomerComplaintArbitrationCivilLitigationObj,
    customerComplaintArbitrationCivilLitigationObj
  );

  const unchecked = getDRPSummaryUncheckedQuestionKeys(
    DRPSummaryCustomerComplaintArbitrationCivilLitigationObj,
    customerComplaintArbitrationCivilLitigationObj
  );

  return { custCompl, unchecked };
};
