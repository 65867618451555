import React, { Component } from "react";
import { ProgressBar } from "react-bootstrap";
import { Button } from "react-blueprint-library";
import ExitModal from "./Forms/BackButtonModal";
import { FORM_ID } from "../../constants/Forms";
import "./StartApplicationNav.scss";
import { UtagTracking } from "../../utils/utag-tracking";

class StartApplicationNavBROKER extends Component {
  constructor(props) {
    super(props);
    this.handleShowModal = this.handleShowModal.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleExitClick = this.handleExitClick.bind(this);
    this.setProgress = this.setProgress.bind(this);
    this.state = { showModal: false, disableReviewSignBtn: true, progress: 0 };
  }

  handleExitClick() {
    window.location = "/";
  }

  handleModalClose() {
    this.setState({ showModal: false });
  }

  handleShowModal() {
    if (this.props.currentFormChanged === true) {
      this.setState({ showModal: true });
    } else {
      this.handleExitClick();
    }
  }

  componentDidMount() {
    this.setProgress();
    UtagTracking.sendPageNameView(
      "startapplicationnavbroker",
      this.props.candidateId,
      this.props.agencynumber,
      this.props.contracttypeid
    );
  }
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setProgress();
    }
  }

  setProgress() {
    let trueCount = 0;
    let numberOfIndicators = 0;

    for (let item in this.props) {
      if (item.includes("Ind")) {
        numberOfIndicators += 1;
        if (this.props[item]) {
          trueCount += 1;
        }
      }
    }

    const progress = Math.round(
      100 - ((numberOfIndicators - trueCount) / numberOfIndicators) * 100
    );

    this.setState({
      progress: progress,
      disableReviewSignBtn: !(progress === 100),
    });
  }
  render() {
    return (
      <div id="startAppNavBroker">
        <div className="progress-bar-section">
          <div className="row">
            <div className="col-12">
              <h3 id="startAppNavProgressHeader">Application Progress</h3>
            </div>
          </div>
          <div className="row">
            <div
              id="startAppNavProgressBar"
              aria-label="Progress Bar Div NavBroker"
              className="col-7"
            >
              <ProgressBar
                id="progressBar"
                now={parseInt(this.state.progress)}
                aria-label="Progress Bar NavBroker"
              />
            </div>
            <div id="startAppNavProgressBarLabel" className="col-5">
              <strong>
                <span id="progressComplete">
                  {this.state.progress}% Complete
                </span>
              </strong>
            </div>
          </div>
        </div>
        <div className="nav-items">
          <div className="row">
            <div className="col-12">
              <div id="nav1" className="nav-button">
                {this.props.currentForm === FORM_ID.RELEASE_OF_INFO ? (
                  <>
                    <span id="current1" className="current-indicator">
                      CURRENT
                    </span>
                    <br />
                    <div className="nav-name">
                      <strong>Release of Information</strong>
                    </div>
                  </>
                ) : (
                  <div className="nav-name">Release of Information</div>
                )}
                {this.props.rInfoCompleteInd === true && (
                  <div id="check1">
                    <span className="icon-answer-correct icon-sm"></span>
                  </div>
                )}
                {this.props.rInfoCompleteInd === false && (
                  <div id="summary1">
                    <span className="nav-summary-text" id="summary_text_1">
                      0/1
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div id="nav2" className="nav-button">
                {this.props.currentForm === FORM_ID.AUTHORIZATION ? (
                  <>
                    <span id="current2" className="current-indicator ">
                      CURRENT
                    </span>
                    <br />
                    <div className="nav-name">
                      <strong>Authorization to Work in the US</strong>
                    </div>
                  </>
                ) : (
                  <div className="nav-name">
                    Authorization to Work in the US
                  </div>
                )}
                {this.props.authorizationCompleteInd === true && (
                  <div id="check2">
                    <span className="icon-answer-correct icon-sm"></span>
                  </div>
                )}
                {this.props.authorizationCompleteInd === false && (
                  <div id="summary2">
                    <span className="nav-summary-text" id="summary_text_2">
                      0/1
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div id="nav3" className="nav-button">
                {this.props.currentForm === FORM_ID.PERSONAL_INFO ? (
                  <>
                    <span id="current3" className="current-indicator ">
                      CURRENT
                    </span>
                    <br />
                    <div className="nav-name">
                      <strong>Personal Information</strong>
                    </div>
                  </>
                ) : (
                  <div className="nav-name">Personal Information</div>
                )}
                {this.props.pInfoCompleteInd === true && (
                  <div id="check3">
                    <span className="icon-answer-correct icon-sm"></span>
                  </div>
                )}
                {this.props.pInfoCompleteInd === false && (
                  <div id="summary3">
                    <span className="nav-summary-text" id="summary_text_3">
                      0/1
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div id="nav4" className="nav-button">
                {this.props.currentForm === FORM_ID.RESIDENTIAL_ADDRESS ? (
                  <>
                    <span id="current4" className="current-indicator">
                      CURRENT
                    </span>
                    <br />
                    <div className="nav-name">
                      <strong>Residential Address</strong>
                    </div>
                  </>
                ) : (
                  <div className="nav-name">Residential Address</div>
                )}
                {this.props.resAddrCompleteInd === true && (
                  <div id="check4">
                    <span className="icon-answer-correct icon-sm"></span>
                  </div>
                )}
                {this.props.resAddrCompleteInd === false && (
                  <div id="summary4">
                    <span className="nav-summary-text" id="summary_text_4">
                      0/1
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div id="nav5" className="nav-button">
                {this.props.currentForm === FORM_ID.BUSINESS_INFO ? (
                  <>
                    <span id="current5" className="current-indicator">
                      CURRENT
                    </span>
                    <br />
                    <div className="nav-name">
                      <strong>Business Information</strong>
                    </div>
                  </>
                ) : (
                  <div className="nav-name">Business Information</div>
                )}
                {this.props.busAddrCompleteInd === true && (
                  <div id="check5">
                    <span className="icon-answer-correct icon-sm"></span>
                  </div>
                )}
                {this.props.busAddrCompleteInd === false && (
                  <div id="summary5">
                    <span className="nav-summary-text" id="summary_text_5">
                      0/1
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div id="nav6" className="nav-button">
                {this.props.currentForm === FORM_ID.BROKER_DEALER ? (
                  <>
                    <span id="current6" className="current-indicator ">
                      CURRENT
                    </span>
                    <br />
                    <div className="nav-name">
                      <strong>Broker Dealer Information</strong>
                    </div>
                  </>
                ) : (
                  <div className="nav-name">Broker Dealer Information</div>
                )}
                {this.props.brokerCompleteInd === true && (
                  <div id="check6">
                    <span className="icon-answer-correct icon-sm"></span>
                  </div>
                )}
                {this.props.brokerCompleteInd === false && (
                  <div id="summary6">
                    <span className="nav-summary-text" id="summary_text_6">
                      0/1
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div id="nav7" className="nav-button">
                {this.props.currentForm === FORM_ID.CCB ? (
                  <>
                    <span id="current7" className="current-indicator">
                      CURRENT
                    </span>
                    <br />
                    <div className="nav-name">
                      <strong> Character, Credit, and Bonding</strong>
                    </div>
                  </>
                ) : (
                  <div className="nav-name">Character, Credit, and Bonding</div>
                )}
                {this.props.ccbCompleteInd === true && (
                  <div id="check7">
                    <span className="icon-answer-correct icon-sm"></span>
                  </div>
                )}
                {this.props.ccbCompleteInd === false && (
                  <div id="summary7">
                    <span className="nav-summary-text" id="summary_text_7">
                      0/1
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div id="nav8" className="nav-button">
                {this.props.currentForm === FORM_ID.ERRORS_OMISSIONS ? (
                  <>
                    <span id="current8" className="current-indicator">
                      CURRENT
                    </span>
                    <br />
                    <div className="nav-name">
                      <strong>
                        Errors & Omissions (E&O) Coverage, Group Insurance
                      </strong>
                    </div>
                  </>
                ) : (
                  <div className="nav-name">
                    Errors & Omissions (E&O) Coverage, Group Insurance
                  </div>
                )}
                {this.props.EOCompleteInd === true && (
                  <div id="check8">
                    <span className="icon-answer-correct icon-sm"></span>
                  </div>
                )}
                {this.props.EOCompleteInd === false && (
                  <div id="summary8">
                    <span className="nav-summary-text" id="summary_text_8">
                      0/1
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div id="nav9" className="nav-button">
                {this.props.currentForm === FORM_ID.DIRECT_DEPOSIT ? (
                  <>
                    <span id="current9" className="current-indicator">
                      CURRENT
                    </span>
                    <br />
                    <div className="nav-name">
                      <strong>Direct Deposit Information</strong>
                    </div>
                  </>
                ) : (
                  <div className="nav-name">Direct Deposit Information</div>
                )}
                {this.props.directDepositCompleteInd === true && (
                  <div id="check9">
                    <span className="icon-answer-correct icon-sm"></span>
                  </div>
                )}
                {this.props.directDepositCompleteInd === false && (
                  <div id="summary9">
                    <span className="nav-summary-text" id="summary_text_9">
                      0/1
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div>
                <Button
                  id="reviewAndSignBtn"
                  variant="secondary"
                  disabled={this.state.disableReviewSignBtn}
                  onClick={() => this.props.toggleForm(FORM_ID.REVIEW_SIGN_1)}
                  style={{ width: "100%" }}
                >
                  Review & Sign
                </Button>
              </div>
            </div>
          </div>
          <div className="col-12 btn-link-wrapper">
            <div>
              <Button
                id="exitBtn"
                variant="link"
                onClick={() => {
                  this.handleShowModal();
                }}
              >
                <strong>Exit</strong>
              </Button>
            </div>
          </div>

          <ExitModal
            isOpen={this.state.showModal}
            closeBackModal={this.handleModalClose}
            handleClose={this.handleModalClose}
            backClick={this.handleExitClick}
          ></ExitModal>
        </div>
      </div>
    );
  }
}
export default StartApplicationNavBROKER;
