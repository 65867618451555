import React, { useState } from "react";
import { Modal } from "react-blueprint-library";
import BackButtonModal from "../../../../../../Forms/BackButtonModal";

import "./AddNewRequalificationModal.scss";

const AddNewRequalificationModal = ({
  showAddNewChargeModal,
  setShowAddNewChargeModal,
  isUpdates,
  children,
}) => {
  const [showBackModal, setShowBackModal] = useState(false);

  const handleCloseBackModal = () => {
    setShowBackModal(false);
    setShowAddNewChargeModal(true);
  };

  const handleShowBackModal = () => {
    if (isUpdates) {
      setShowBackModal(true);
    } else {
      setShowAddNewChargeModal(false);
    }
  };

  const handleBackClick = () => {
    handleCloseBackModal();
    setShowAddNewChargeModal(false);
  };

  return (
    <div className="drp-add-new-charge-wrapper">
      <Modal
        show={showAddNewChargeModal}
        className="modal-md drp-add-new-charge-modal-wrapper"
        title=""
        id="add-new-charge-modal"
      >
        <div className="col-md-1 drp-modal-header-wrapper">
          <h2 id="new-requalification-modal-text">Add New Requalification</h2>
          <button
            id="add-new-charge-modal-close"
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close modal dialog"
            onClick={handleShowBackModal}
          />
        </div>
        <div className="req-field">
          <span id="required-modal-text">* = Required Field</span>
        </div>
        <div className="req-field-sub" />

        <div className="children-container">{children}</div>
      </Modal>

      <BackButtonModal
        isOpen={showBackModal}
        closeBackModal={handleCloseBackModal}
        backClick={handleBackClick}
      />
    </div>
  );
};

export default AddNewRequalificationModal;
