import React, { useState } from "react";

import "./NewChargesList.scss";
import ActionDropdown from "../ActionDropdown";
import DeleteModal from "../DeleteModal";

const NewChargesList = ({
  list,
  setNewChargesList,
  setEditChargeDetailsList,
  setShowAddNewChargeModal,
}) => {
  const [show, setShow] = useState(false);
  const [actionIndex, setActionIndex] = useState(null);

  const handleDeleteAction = (index) => {
    if (index === null) {
      return;
    }

    const newList = list.filter((_, i) => i !== index);

    setNewChargesList(newList);
    setShow(false);
  };

  const handleEditAction = (index) => {
    if (index === null) {
      return;
    }

    setEditChargeDetailsList({
      list: list.find((_, i) => i === index),
      index,
    });

    setShowAddNewChargeModal(true);
  };

  return (
    <div className="new-charges-list">
      <hr className="hr-top" />
      <div className="list-header">
        <div className="left">
          <div>Number of Counts</div>
          <div>Charge Type</div>
        </div>
        <div className="action">Action</div>
      </div>

      <hr />

      <div className="rows">
        {list.map(({ nrOfCounts, felonyOrMisdemeanor }, index) => (
          <div key={`${index}-${felonyOrMisdemeanor}`} className="rows-wrapper">
            <div className="drp-list-row">
              <div className="left">
                <div>{nrOfCounts}</div>
                <div>{felonyOrMisdemeanor}</div>
              </div>

              <div className="action">
                <ActionDropdown
                  key={index}
                  editAction={() => {
                    handleEditAction(index);
                  }}
                  showDelete={show}
                  deleteAction={() => {
                    setActionIndex(index);
                    setShow(true);
                  }}
                />
              </div>
            </div>

            <div>
              <hr />
            </div>
          </div>
        ))}
      </div>

      <DeleteModal
        isOpen={show}
        message="Are you sure you'd like to delete this charge?"
        showSpinner={false}
        closeClick={() => setShow(false)}
        deleteClick={() => handleDeleteAction(actionIndex)}
      />
    </div>
  );
};

export default NewChargesList;
