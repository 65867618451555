/* eslint-disable jsx-a11y/label-has-for */
import React, { forwardRef, useState } from "react";
import { Button } from "react-blueprint-library";
import { SUB_QUEST_IV } from "../state";
import { logoutActions } from "../../../../../../store/actions";
import { useDispatch } from "react-redux";

const CustomSubCategoryCard = forwardRef(
  (
    {
      data,
      category,
      handleOnEditClick,
      handleChangeChecked,
      handleOnSaveClick,
      section,
      isSaved,
      isRequired,
      isLoading,
      disabledSave,
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const [isEditHidden, setIsEditHidden] = useState(false);

    const onEditChange = () => {
      handleOnEditClick(section);
      setIsEditHidden((prev) => !prev);
    };

    const handleOnSave = () => {
      dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: false }));

      return !disabledSave && handleOnSaveClick(section);
    };

    return (
      <div className="sub-card-container" ref={ref}>
        <div className="save-edit-btn-wrapper">
          {isSaved[section] && !isEditHidden && disabledSave ? (
            <Button
              id="cust-comp-edit"
              variant="link"
              className="edit"
              onClick={onEditChange}
            >
              <strong id={`edit-${section}`}>Edit</strong>
            </Button>
          ) : null}
        </div>

        <>
          {category.map(
            (
              {
                header,
                subHeader,
                questionA,
                questionB = null,
                questionC = null,
                questionD = null,
                optionsA,
                optionsB,
                optionsC,
                optionsD,
              },
              i
            ) => {
              const isAChecked =
                (optionsA.valueOne &&
                  data[optionsA.name] === optionsA.valueOne) ||
                (optionsA.valueTwo &&
                  data[optionsA.name] === optionsA.valueTwo);

              const isBChecked =
                questionB &&
                ((optionsB.valueOne &&
                  data[optionsB.name] === optionsB.valueOne) ||
                  (optionsB.valueTwo &&
                    data[optionsB.name] === optionsB.valueTwo));

              const id = `${i * 1}-${header?.length}`;

              return (
                <div className="category" key={`${i * 1}_category_${header}`}>
                  <div
                    id={`customer-header-${section}-${id}`}
                    className="header"
                  >
                    {header}
                  </div>
                  <div
                    className={`sub-category ${
                      !header ? "without-header" : ""
                    } ${subHeader === SUB_QUEST_IV ? "with-padding" : ""} ${
                      i !== 0 ? "indent" : ""
                    }`}
                  >
                    <span
                      id={`customer-subheader-${subHeader.substring(0, 3)}`}
                      className={i === 0 && !header ? "is-first" : ""}
                    >
                      {subHeader}
                    </span>
                    <div
                      className={`sub-category ${
                        i !== 0 ? "reduced-padding" : ""
                      }`}
                    >
                      <span id={`cstmr-questionA-${subHeader.substring(0, 3)}`}>
                        {questionA}
                      </span>
                      <div className="options">
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            name={optionsA.name}
                            id={optionsA.idOne}
                            className={`custom-control-input ${
                              isRequired && !isAChecked ? "is-invalid" : ""
                            }`}
                            checked={data[optionsA.name] === optionsA?.valueOne}
                            value={optionsA?.valueOne}
                            onChange={handleChangeChecked}
                            disabled={optionsA.disabled}
                          />
                          <label
                            id={`customer-radio-${optionsA.idOne}-${optionsA.valueOne}`}
                            className="custom-control-label"
                            htmlFor={optionsA.idOne}
                          >
                            {optionsA.labelOne}
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            name={optionsA.name}
                            id={optionsA.idTwo}
                            className={`custom-control-input ${
                              isRequired && !isAChecked ? "is-invalid" : ""
                            }`}
                            checked={data[optionsA.name] === optionsA.valueTwo}
                            value={optionsA.valueTwo}
                            onChange={handleChangeChecked}
                            disabled={optionsA.disabled}
                          />
                          <label
                            id={`customer-radio-${optionsA.idTwo}-${optionsA.valueTwo}`}
                            className="custom-control-label"
                            htmlFor={optionsA.idTwo}
                          >
                            {optionsA.labelTwo}
                          </label>
                        </div>
                      </div>
                      {questionB && (
                        <>
                          <span
                            id={`cstmr-questionB-${subHeader.substring(0, 3)}`}
                          >
                            {questionB}
                          </span>
                          <div className="options">
                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                name={optionsB.name}
                                id={optionsB.idOne}
                                className={`custom-control-input ${
                                  isRequired && !isBChecked ? "is-invalid" : ""
                                }`}
                                checked={
                                  data[optionsB.name] === optionsB?.valueOne
                                }
                                value={optionsB?.valueOne}
                                onChange={handleChangeChecked}
                                disabled={optionsB.disabled}
                              />
                              <label
                                id={`customer-radio-${optionsB.idOne}-${optionsB.valueOne}`}
                                className="custom-control-label"
                                htmlFor={optionsB.idOne}
                              >
                                {optionsB.labelOne}
                              </label>
                            </div>

                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                name={optionsB.name}
                                id={optionsB.idTwo}
                                className={`custom-control-input ${
                                  isRequired && !isBChecked ? "is-invalid" : ""
                                }`}
                                checked={
                                  data[optionsB.name] === optionsB.valueTwo
                                }
                                value={optionsB.valueTwo}
                                onChange={handleChangeChecked}
                                disabled={optionsB.disabled}
                              />
                              <label
                                id={`customer-radio-${optionsB.idTwo}-${optionsB.valueTwo}`}
                                className="custom-control-label"
                                htmlFor={optionsB.idTwo}
                              >
                                {optionsB.labelTwo}
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                      {questionC && (
                        <>
                          <span
                            id={`cstmr-questionC-${subHeader.substring(0, 3)}`}
                          >
                            {questionC}
                          </span>
                          <div className="options">
                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                name={optionsC.name}
                                id={optionsC.idOne}
                                className={`custom-control-input ${
                                  isRequired && !isBChecked ? "is-invalid" : ""
                                }`}
                                checked={
                                  data[optionsC.name] === optionsC?.valueOne
                                }
                                value={optionsC?.valueOne}
                                onChange={handleChangeChecked}
                                disabled={optionsC.disabled}
                              />
                              <label
                                id={`customer-radio-${optionsC.idOne}-${optionsC.valueOne}`}
                                className="custom-control-label"
                                htmlFor={optionsC.idOne}
                              >
                                {optionsC.labelOne}
                              </label>
                            </div>

                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                name={optionsC.name}
                                id={optionsC.idTwo}
                                className={`custom-control-input ${
                                  isRequired && !isBChecked ? "is-invalid" : ""
                                }`}
                                checked={
                                  data[optionsC.name] === optionsC.valueTwo
                                }
                                value={optionsC.valueTwo}
                                onChange={handleChangeChecked}
                                disabled={optionsC.disabled}
                              />
                              <label
                                id={`customer-radio-${optionsC.idTwo}-${optionsC.valueTwo}`}
                                className="custom-control-label"
                                htmlFor={optionsC.idTwo}
                              >
                                {optionsC.labelTwo}
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                      {questionD && (
                        <>
                          <span
                            id={`cstmr-questionD-${subHeader.substring(0, 3)}`}
                          >
                            {questionD}
                          </span>
                          <div className="options">
                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                name={optionsD.name}
                                id={optionsD.idOne}
                                className={`custom-control-input ${
                                  isRequired && !isBChecked ? "is-invalid" : ""
                                }`}
                                checked={
                                  data[optionsD.name] === optionsD?.valueOne
                                }
                                value={optionsD?.valueOne}
                                onChange={handleChangeChecked}
                                disabled={optionsD.disabled}
                              />
                              <label
                                id={`customer-radio-${optionsD.idOne}-${optionsD.valueOne}`}
                                className="custom-control-label"
                                htmlFor={optionsD.idOne}
                              >
                                {optionsD.labelOne}
                              </label>
                            </div>

                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                name={optionsD.name}
                                id={optionsD.idTwo}
                                className={`custom-control-input ${
                                  isRequired && !isBChecked ? "is-invalid" : ""
                                }`}
                                checked={
                                  data[optionsD.name] === optionsD.valueTwo
                                }
                                value={optionsD.valueTwo}
                                onChange={handleChangeChecked}
                                disabled={optionsD.disabled}
                              />
                              <label
                                id={`customer-radio-${optionsD.idTwo}-${optionsD.valueTwo}`}
                                className="custom-control-label"
                                htmlFor={optionsD.idTwo}
                              >
                                {optionsD.labelTwo}
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                      {isRequired && !(isBChecked && isAChecked) ? (
                        <div id="is_required_text_error" className="isRequired">
                          Please choose an answer
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {i + 1 < category.length && i + 1 < category.length - 1 ? (
                    <hr />
                  ) : null}
                </div>
              );
            }
          )}
        </>

        <div className="save-edit-btn-wrapper">
          {isEditHidden || !isSaved[section] || !disabledSave ? (
            <Button
              id={`cstmr-save-button-${section}`}
              variant="link"
              className={`save ${disabledSave ? "disabled" : ""}`}
              disabled={disabledSave}
              onClick={handleOnSave}
            >
              <strong id={`save-continue-${section}`}>Save And Continue</strong>

              {isLoading && <span className="mm-dots-loading save"></span>}
            </Button>
          ) : null}
        </div>
      </div>
    );
  }
);

export default CustomSubCategoryCard;
