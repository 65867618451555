import React, { useEffect, useMemo, useRef, useState } from "react";
import { FORM_ID } from "../../../../../../constants/Forms";
import "./CriminalDisclosureForm.scss";
import CustomCard from "../../common/CustomCard";
import {
  initialState,
  individualCriminalDisclosure,
  organizationCriminalDisclosure,
} from "../state";
import BackButtonModal from "../../../../Forms/BackButtonModal";
import Toast from "../../common/Toast";
import { useDispatch, useSelector } from "react-redux";
import {
  INTRO_REV,
  INTRO_TEXT,
  KEYS,
  RADIO,
  SECTION,
  TOAST_MESSAGE,
} from "../../constants";
import { ccbActions, logoutActions } from "../../../../../../store/actions";
import { loadingStatus } from "../../../../../../store/state";
import {
  getSpecificObjByKeys,
  getValues,
  isChecked,
  isEachChecked,
  isSomeChecked,
} from "../../helpers";
import {
  fetchCCB,
  postCCB,
  putCCB,
  putCCBStatus,
} from "../../../../../../store/middlewares";
import CustomSubCategoryCard from "./CustomSubCategoryCard";
import { useClickOutside } from "../../hooks/useClickOutside";
import { headerText } from "../constants";
import NavigationButtons from "../../../../../SharedComponents/NavigationButtons";
import {
  cleanupCCB14Drp,
  getCCB14DRP,
} from "../../../../../../api/CCB/DRP/criminalDisclosureDRP";
import { DRP_TYPES } from "../../../../../../api/helpers/constants";
import DeleteModal from "../../common/DeleteModal";
import { filterByUnchecked } from "../utils";

const CriminalDisclosureForm = ({
  header,
  toggleForm,
  oktaSubId,
  setIsLoading,
  updateCCBForm,
  backFormId,
  nextFormId,
  updateCCB14CompletedInd,
  updateCCB14Step,
  isLoading: isLoadingProp,
  uncheckedInd,
  uncheckedOrg,
  setIsDataRefetch,
}) => {
  const dispatch = useDispatch();

  const toastRef = useRef(null);
  const containerRef = useRef(null);

  const { error, loading, isFirstLoad, data } = useSelector(
    (state) => state.ccb14
  );

  const [isPageChanged, setIsPageChanged] = useState(false);
  const [isShowDRPCleanupModal, setShowDRPCleanupModal] = useState(false);
  const [selectedSection, setSelectedSection] = useState(null);
  const [drpCleanupList, setDrpCleanupList] = useState(null);

  const isLoading = loading === loadingStatus.PENDING || isLoadingProp;
  let isError = error || loading === loadingStatus.FAILED;

  const initializeSectionStates = {
    [SECTION.individualCriminalDisclosure]: false,
    [SECTION.organizationCriminalDisclosure]: false,
  };

  const [isRequired, setIsRequired] = useState(false);
  const [isCardOpen, setIsCardOpen] = useState({
    ...initializeSectionStates,
  });
  const [showBackModal, setShowBackModal] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const [isEdit, setIsEdit] = useState({
    ...initializeSectionStates,
  });

  const [isSaved, setIsSaved] = useState({
    ...initializeSectionStates,
  });

  const valuesA = useMemo(
    () => getValues(data, KEYS.individualCriminalDisclosure),
    [data]
  );
  const valuesB = useMemo(
    () => getValues(data, KEYS.organizationCriminalDisclosure),
    [data]
  );

  const [formHasChanged, setFormHasChanged] = useState(false);

  useEffect(() => {
    if (formHasChanged) {
      dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: true }));
    }
  }, [formHasChanged, dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  const isSaveDisabledA = isEachChecked(valuesA);
  const isSaveDisabledB = isEachChecked(valuesB);

  const submitData = useMemo(() => {
    return {
      [SECTION.individualCriminalDisclosure]: getSpecificObjByKeys(
        data,
        KEYS.individualCriminalDisclosure
      ),
      [SECTION.organizationCriminalDisclosure]: getSpecificObjByKeys(
        data,
        KEYS.organizationCriminalDisclosure
      ),
    };
  }, [data]);

  useEffect(() => {
    try {
      const initData = async () => {
        const { payload } = await dispatch(fetchCCB(oktaSubId));

        if (payload) {
          const valuesA = getValues(payload, KEYS.individualCriminalDisclosure);
          const valuesB = getValues(
            payload,
            KEYS.organizationCriminalDisclosure
          );

          const a = valuesA.some(isChecked);
          const b = valuesB.some(isChecked);

          setIsSaved({
            [SECTION.individualCriminalDisclosure]: a,
            [SECTION.organizationCriminalDisclosure]: b,
          });

          setIsEdit({
            [SECTION.individualCriminalDisclosure]: !a,
            [SECTION.organizationCriminalDisclosure]: !b,
          });
        } else {
          if (!isSaveDisabledA && !isSaveDisabledB) {
            setIsCardOpen({
              ...isCardOpen,
              [SECTION.individualCriminalDisclosure]: true,
            });
          }
        }
      };

      const a = valuesA.some(isChecked);
      const b = valuesB.some(isChecked);

      if (loading !== loadingStatus.SUCCEDED) {
        initData();
      } else {
        setIsSaved({
          [SECTION.individualCriminalDisclosure]: a,
          [SECTION.organizationCriminalDisclosure]: b,
        });

        setIsEdit({
          [SECTION.individualCriminalDisclosure]: !a,
          [SECTION.organizationCriminalDisclosure]: !b,
        });

        if (!a) {
          setIsCardOpen({
            ...isCardOpen,
            [SECTION.individualCriminalDisclosure]: true,
          });
        }

        if (a && !b) {
          setIsCardOpen({
            ...isCardOpen,
            [SECTION.organizationCriminalDisclosure]: true,
          });
        }

        if (a && b) {
          setIsCardOpen({ ...initialState });
        } else if (!a && !b) {
          setIsCardOpen({
            ...isCardOpen,
            [SECTION.individualCriminalDisclosure]: true,
          });
        }
      }
    } catch (error) {
      console.log({ error });
    }

    return () => {
      const a = valuesA.some(isChecked);
      const b = valuesB.some(isChecked);

      setIsSaved({
        [SECTION.individualCriminalDisclosure]: a,
        [SECTION.organizationCriminalDisclosure]: b,
      });

      setIsEdit({
        [SECTION.individualCriminalDisclosure]: !a,
        [SECTION.organizationCriminalDisclosure]: !b,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPageChanged]);

  const handleCloseToast = () => setShowToast(false);

  const handleChangeChecked = (e) => {
    const { name, value } = e.target;
    let newValue = value;

    setFormHasChanged(true);

    if (
      isSaved[SECTION.individualCriminalDisclosure] &&
      isSaved[SECTION.organizationCriminalDisclosure]
    ) {
      updateCCB14Step({ crimDisclosureCompleteStep: "1/2" });
      updateCCB14CompletedInd({ crimDisclosureCompleteInd: false });
    }

    switch (value) {
      case RADIO.yes:
        newValue = true;
        break;
      case RADIO.no:
        newValue = false;
        break;
      default:
        newValue = null;
        break;
    }

    dispatch(ccbActions.setChecked({ [name]: newValue }));
  };

  const updateStatus = async (data) => {
    try {
      await dispatch(
        putCCBStatus({ oktaSubId, data: { criminaldisclosurestatus: data } })
      );
    } catch (err) {
      console.log(err);
    }
  };

  const handleOnEditClick = (section) => {
    handleCloseToast();
    setIsEdit((prevState) => ({ ...prevState, [section]: true }));
  };

  const saveHandler = async (section) => {
    if (
      isSaveDisabledA &&
      !isSaveDisabledB &&
      section === SECTION.individualCriminalDisclosure
    ) {
      setIsCardOpen((prevState) => ({
        ...prevState,
        [SECTION.organizationCriminalDisclosure]: true,
      }));
    }

    if (
      isSaveDisabledB &&
      !isSaveDisabledA &&
      section === SECTION.organizationCriminalDisclosure
    ) {
      setIsCardOpen((prevState) => ({
        ...prevState,
        [SECTION.individualCriminalDisclosure]: true,
      }));
    }

    setFormHasChanged(false);

    setIsCardOpen((prevState) => ({ ...prevState, [section]: false }));

    if (isFirstLoad) {
      try {
        setIsLoading(true);
        await dispatch(postCCB({ oktaSubId, data: submitData[section] }));
        setIsLoading(false);
      } catch (err) {
        console.log(err);
        isError = `${err}`;
      }

      setIsSaved((prevState) => ({ ...prevState, [section]: true }));
    } else {
      try {
        setIsLoading(true);
        await dispatch(putCCB({ oktaSubId, data: submitData[section] }));
        setIsLoading(false);
      } catch (err) {
        console.log(err);
        isError = `${err}`;
      }
      setIsSaved((prevState) => ({ ...prevState, [section]: true }));
    }

    const isNo = (currentValue) => currentValue === false;
    const isYes = (currentValue) => currentValue === false;
    const isNoValues = valuesA.every(isNo) && valuesB.every(isNo);
    const isYesValues = valuesA.some(isYes) || valuesB.some(isYes);

    if (isNoValues) {
      updateCCB14CompletedInd({ crimDisclosureCompleteInd: true });
      updateStatus(null);
    } else if (isYesValues) {
      updateCCB14Step({ crimDisclosureCompleteStep: "1/2" });
      updateCCB14CompletedInd({ crimDisclosureCompleteInd: false });
    }

    setIsEdit((prevState) => ({ ...prevState, [section]: false }));
    setShowToast(true);
  };

  const handleOnSaveClick = async (section) => {
    handleCloseToast();

    setSelectedSection(section);

    const { drpvalues } = await getCCB14DRP({
      oktaSubId,
      drpTypeId: DRP_TYPES.Criminal,
    });

    const drpList = filterByUnchecked(
      section,
      section === SECTION.individualCriminalDisclosure
        ? uncheckedInd
        : uncheckedOrg,
      drpvalues
    );

    if (drpList.length) {
      setDrpCleanupList(drpList);
      setShowDRPCleanupModal(true);
    } else {
      saveHandler(section);
    }

    window.scrollTo(0, 0);
  };

  const closeCleanupModalHandler = async () => {
    setShowDRPCleanupModal(false);
  };

  const onCleanupModalHandler = async () => {
    if (selectedSection === null) {
      return;
    }

    setIsLoading(true);

    await cleanupCCB14Drp({
      oktaSubId,
      drpTypeId: DRP_TYPES.Criminal,
      data: {
        data: {
          ind: uncheckedInd,
          org: uncheckedOrg,
        },
      },
    });

    setIsLoading(false);

    await saveHandler(selectedSection);

    setIsDataRefetch(true);

    setShowDRPCleanupModal(false);
  };

  useEffect(() => {
    let handler = (e) => {
      if (!toastRef?.current?.contains(e.target)) {
        handleCloseToast();
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const cleanup = (isFetch = false) => {
    dispatch(ccbActions.setChecked({ ...initialState }));
    setIsRequired(false);
    handleCloseToast();
    setFormHasChanged(false);
    setIsCardOpen({
      ...initializeSectionStates,
    });
    setIsEdit({
      ...initializeSectionStates,
    });

    if (!isFetch) {
      setIsPageChanged((prev) => !prev);
    }
  };

  const handleCloseBackModal = () => setShowBackModal(false);

  const handleShowBackModal = () => {
    if (
      ((isSomeChecked(valuesA) &&
        isEdit[SECTION.individualCriminalDisclosure]) ||
        (isSomeChecked(valuesB) &&
          isEdit[SECTION.organizationCriminalDisclosure]) ||
        (isSomeChecked(valuesA) &&
          !isSaved[SECTION.individualCriminalDisclosure]) ||
        (isSomeChecked(valuesB) &&
          !isSaved[SECTION.organizationCriminalDisclosure])) &&
      formHasChanged
    ) {
      setShowBackModal(true);
    } else {
      toggleForm(FORM_ID.CCB_INTRO);
      setIsPageChanged((prev) => !prev);
    }
  };

  const handleBackClick = async () => {
    cleanup();
    toggleForm(backFormId);

    if (
      isSaved[SECTION.individualCriminalDisclosure] &&
      isSaved[SECTION.organizationCriminalDisclosure]
    ) {
      updateCCB14CompletedInd({ crimDisclosureCompleteInd: true });
    }

    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: false }));
    handleCloseBackModal();
  };

  const handleNext = async () => {
    dispatch(ccbActions.setIsBack(false));
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: false }));
    cleanup(true);

    const isYes = (currentValue) => currentValue === true;
    let isYesValues = valuesA.some(isYes) || valuesB.some(isYes);

    if (loading === loadingStatus.IDLE || loading === loadingStatus.SUCCEDED) {
      const { payload } = await dispatch(fetchCCB(oktaSubId));

      isYesValues =
        getValues(payload, KEYS.individualCriminalDisclosure).some(isYes) ||
        getValues(payload, KEYS.organizationCriminalDisclosure).some(isYes);
    }

    if (isYesValues) {
      updateCCBForm(nextFormId);
    } else {
      updateCCB14CompletedInd({ crimDisclosureCompleteInd: true });
      updateStatus(null);
      toggleForm(FORM_ID.REGULATORY_ACTION_DISCLOSURE);
    }
  };

  const renderSubcategory = (category, section) => {
    const isDisabledIndividualCriminalDisclosure =
      isSaved[SECTION.individualCriminalDisclosure] &&
      !isEdit[SECTION.individualCriminalDisclosure] &&
      section === SECTION.individualCriminalDisclosure;

    const isDisabledOrganizationCriminalDisclosure =
      isSaved[SECTION.organizationCriminalDisclosure] &&
      !isEdit[SECTION.organizationCriminalDisclosure] &&
      section === SECTION.organizationCriminalDisclosure;

    const disabledSave =
      isDisabledIndividualCriminalDisclosure ||
      isDisabledOrganizationCriminalDisclosure ||
      (!isSaveDisabledA && section === SECTION.individualCriminalDisclosure) ||
      (!isSaveDisabledB && section === SECTION.organizationCriminalDisclosure);

    return (
      <CustomSubCategoryCard
        data={data}
        category={category}
        handleOnEditClick={handleOnEditClick}
        handleChangeChecked={handleChangeChecked}
        handleOnSaveClick={handleOnSaveClick}
        section={section}
        isSaved={isSaved}
        isLoading={isLoading}
        isRequired={isRequired}
        disabledSave={disabledSave}
        ref={containerRef}
      />
    );
  };

  useClickOutside(toastRef, containerRef, showToast, handleCloseToast);

  return (
    <div className="criminal-disclosure-form-wrapper" ref={containerRef}>
      <Toast
        showToast={showToast}
        closeToast={handleCloseToast}
        text={TOAST_MESSAGE}
        ref={toastRef}
      />

      <div className="row">
        <div className="col-md-12">
          <div className="eyebrow">
            <strong id="ccb_eyebrow">Character, Credit, and Bonding</strong>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <h1 id="ccb_header_criminal">{header}</h1>
          <h2 id="ccb_intro_rev_criminal" style={{ color: "black" }}>
            {INTRO_REV}
          </h2>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <p id="criminalDRPIntroText">{INTRO_TEXT}</p>
        </div>
      </div>

      <div className="row">
        <div id="individualCDSection" className="col-md-12">
          <CustomCard
            id="individualCDContainer"
            isOpen={isCardOpen[SECTION.individualCriminalDisclosure]}
            setIsOpen={setIsCardOpen}
            headerText={headerText.IND_CRIM_DISCL}
            isCompleted={isSaved[SECTION.individualCriminalDisclosure]}
            index={SECTION.individualCriminalDisclosure}
          >
            {renderSubcategory(
              individualCriminalDisclosure(
                isSaved[SECTION.individualCriminalDisclosure] &&
                  !isEdit[SECTION.individualCriminalDisclosure]
              ),
              SECTION.individualCriminalDisclosure
            )}
          </CustomCard>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <CustomCard
            isOpen={isCardOpen[SECTION.organizationCriminalDisclosure]}
            setIsOpen={setIsCardOpen}
            headerText={headerText.ORG_CRIM_DISCL}
            isCompleted={isSaved[SECTION.organizationCriminalDisclosure]}
            index={SECTION.organizationCriminalDisclosure}
          >
            {renderSubcategory(
              organizationCriminalDisclosure(
                isSaved[SECTION.organizationCriminalDisclosure] &&
                  !isEdit[SECTION.organizationCriminalDisclosure]
              ),
              SECTION.organizationCriminalDisclosure
            )}
          </CustomCard>
        </div>
      </div>
      <NavigationButtons
        onBack={handleShowBackModal}
        onNext={handleNext}
        isDisabled={
          !(
            isSaved[SECTION.individualCriminalDisclosure] &&
            isSaved[SECTION.organizationCriminalDisclosure]
          ) ||
          !!isError ||
          !!isLoading
        }
        isLoading={isLoading}
        nxtbtnid={"crmnldsclr_next"}
        bckbtnid={"crmnldsclr_back"}
      />

      {isShowDRPCleanupModal && (
        <DeleteModal
          isOpen={isShowDRPCleanupModal}
          showSpinner={false}
          isDRP
          type={headerText.HEADER}
          list={drpCleanupList}
          description="You had previously provided a DRP(s) associated with this
          question. Changing your response will delete the following
          DRP(s). Are you sure you want to proceed?"
          message="Are You Sure You Want To Proceed?"
          closeClick={closeCleanupModalHandler}
          deleteClick={onCleanupModalHandler}
        />
      )}

      <BackButtonModal
        isOpen={showBackModal}
        closeBackModal={handleCloseBackModal}
        backClick={handleBackClick}
      />
    </div>
  );
};

export default CriminalDisclosureForm;
