import React, { useEffect, useState } from "react";
import { Button } from "react-blueprint-library";
import "../StartApplication.scss";
import InputMask from "react-input-mask";
import BackButtonModal from "../Forms/BackButtonModal";
import {
  insertCorporation,
  updateCorporation,
  insertCandidateCorp,
} from "../../../api/makeAPIRequest";
import { FORM_ID } from "../../../constants/Forms";
import { onlyNumeric } from "../../../common/Functions";
import { UtagTracking } from "../../../utils/utag-tracking";
import NotificationModal from "../Forms/NotificationModal";
import NavigationButtons from "../../SharedComponents/NavigationButtons";

const MOCInformationForm = (props) => {
  const [corp, setCorp] = useState({
    corp_id: 0,
    corporation_name: "",
    corporation_taxid: "",
  });
  const [showBackModal, setShowBackModal] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [showContactAgencyModal, setShowContactAgencyModal] = useState(false);
  const [formHasChanged, setFormHasChanged] = useState(false);
  const [loading, setIsLoading] = useState(false);

  var formHasError = false;
  useEffect(() => {
    try {
      const result = props.corporationData;

      if (props.corpid !== 0) {
        setCorp({
          corp_id: props.corpid,
          corporation_name: result.corpname,
          corporation_taxid: result.corptaxid,
        });
        setIsComplete(true);

        props.isFormComplete("CorpInfoCompleteInd", true);
      }
      UtagTracking.sendPageNameView(
        "corporationform",
        props.candidateId,
        props.agencynumber,
        props.contracttypeid
      );
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleNext = async (source) => {
    formHasError = false;
    var corpnameEvent = new Event("blur");
    Object.defineProperty(corpnameEvent, "target", {
      writable: false,
      value: document.getElementById("corporation_name"),
    });
    await onBlur(corpnameEvent);

    var corptaxidEvent = new Event("blur");
    Object.defineProperty(corptaxidEvent, "target", {
      writable: false,
      value: document.getElementById("corporation_taxid"),
    });
    await onBlur(corptaxidEvent);

    if (formHasError === false) {
      setIsLoading(true);
      var result = "";
      saveCorporation();
      let data = {
        corpid: corp.corp_id,
        corpname: corp.corporation_name,
        corptaxid: corp.corporation_taxid,
      };
      if (data.corpid === 0) {
        result = await insertCorporation(props.subId, data);

        let corpid = result.corpid;
        let data2 = {
          candidateid: props.candidateId,
          contractid: props.contractid,
          corpid: corpid,
        };
        if (corpid !== undefined) {
          await insertCandidateCorp(props.subId, data2);
        }
      } else {
        if (formHasChanged) {
          result = await updateCorporation(props.subId, data);
        }
      }

      if (result !== undefined && result.message === "Duplicate Tax ID found") {
        await setShowContactAgencyModal(true);
      } else {
        setIsComplete(true);
        await props.updateCorpID();
        props.hasCurrentFormChanged(false);
        props.isFormComplete("CorpInfoCompleteInd", true);
        setFormHasChanged(false);
        props.toggleForm(FORM_ID.PERSONAL_INFO);
      }
      setIsLoading(false);
    }
    props.isFormComplete("CorpInfoCompleteInd", !formHasError);
  };

  const onBlur = async (event) => {
    if (typeof event.target.value !== "undefined") {
      var obj_error;
      obj_error = document.getElementById(event.target.id + "_error");

      if (event.target.id === "corporation_name") {
        if (event.target.value.trim().length < 2) {
          obj_error.style.display = "block";
          event.target.classList.add("is-invalid");
          formHasError = true;
        } else {
          obj_error.style.display = "none";
          event.target.classList.remove("is-invalid");
        }
      } else if (event.target.id === "corporation_taxid") {
        if (
          event.target.value.trim().length === 0 ||
          event.target.value.includes("_")
        ) {
          obj_error.style.display = "block";
          event.target.classList.add("is-invalid");
          formHasError = true;
        } else {
          obj_error.style.display = "none";
          event.target.classList.remove("is-invalid");
        }
      }
    }
  };

  const handleShowBackModal = () => {
    //show back modal if anything on the form has changed
    if (formHasChanged) {
      setShowBackModal(true);
    } else {
      props.toggleForm(FORM_ID.AUTHORIZATION);
    }
  };

  const handleChange = (event) => {
    setFormHasChanged(true);
    props.isFormComplete("CorpInfoCompleteInd", false);
    props.hasCurrentFormChanged(true);

    setIsComplete(false);
    setCorp((prevState) => ({
      ...prevState,
      [event.target.id]: event.target.value,
    }));
  };

  const saveCorporation = () => {
    props.saveCorporationInfo("corporationData", {
      ...props.corporationData,
      corpid: corp.corp_id,
      corpname: corp.corporation_name,
      corptaxid: corp.corporation_taxid,
    });
  };

  const onKeyDown = () => {
    props.isFormComplete("CorpInfoCompleteInd", false);
    props.hasCurrentFormChanged(true);
    setIsComplete(false);
  };

  const handleCloseBackModal = () => {
    setShowBackModal(false);
  };
  return (
    <>
      <div id="brokercorpcorporationform">
        <div className="row">
          <div className="col-md-12">
            <div className="eyebrow">
              <strong>MEMBER OF CORPORATION</strong>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <h1 id="broker_corp_corporation_header">Corporation Information</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12" style={{ paddingBottom: "20px" }}>
            <span
              id="corporation_required_text"
              className="required-field-text"
            >
              * = Required Field
            </span>
          </div>
        </div>

        <div id="corporation_name_row" className="row">
          <div className="col-md-12">
            <label id="corporation_name_label" htmlFor="corporationt_name">
              <strong>Legal Registered Corporation Name*</strong>
            </label>
            <input
              type="text"
              id="corporation_name"
              className="form-control"
              spellCheck="false"
              maxLength={100}
              autoComplete="off"
              value={corp.corporation_name}
              onBlur={onBlur}
              onChange={handleChange}
              onKeyDown={onKeyDown}
              disabled={true}
            />
            <div id="corporation_name_error" className="invalid-feedback">
              Please enter a valid Name
            </div>
          </div>
        </div>

        <div
          id="corporation_taxid_row"
          className="row"
          style={{ marginTop: "10px" }}
        >
          <div className="col-md-12">
            <label id="corporation_taxid_label" htmlFor="corporation_taxid">
              <strong>Tax ID*</strong>
            </label>
            <InputMask
              id="corporation_taxid"
              mask={"99-9999999"}
              maskChar="_"
              className="form-control"
              placeholder="__-______"
              //   maxLength={9}
              autoComplete="off"
              value={corp.corporation_taxid}
              onBlur={onBlur}
              onChange={handleChange}
              onKeyDown={onKeyDown}
              style={{ width: "15%" }}
              disabled={true}
            />

            <div id="corporation_taxid_error" className="invalid-feedback">
              Please enter a valid Tax ID
            </div>
          </div>
        </div>
        <BackButtonModal
          isOpen={showBackModal}
          closeBackModal={handleCloseBackModal}
          backClick={() => {
            props.toggleForm(FORM_ID.AUTHORIZATION);
            props.isFormComplete(true);
          }}
        />

        <NotificationModal
          isOpen={showContactAgencyModal}
          closeModal={() => {
            setShowContactAgencyModal(false);
            setIsLoading(false);
          }}
          exitApplication={() => {
            window.location = "/";
          }}
          source={"PersonalInformation"}
        />
        <NavigationButtons
          onBack={handleShowBackModal}
          onNext={handleNext}
          isLoading={loading}
          nxtbtnid={"moci_next"}
          bckbtnid={"moci_back"}
        />
      </div>
    </>
  );
};

export default MOCInformationForm;
