import "../DRPSummaryListingCard/DRPSummaryListingCard.scss";

const DRPCheckboxList = ({
  handleChange,
  handleShowOtherDiv,
  onBlur,
  otherText,
  otherTextError,
  productTypes,
}) => {
  let array1 = [
    { id: 1, name: "No Product" },
    { id: 2, name: "Annuity-Charitable" },
    { id: 3, name: "Annuity-Fixed" },
    { id: 4, name: "Annuity-Variable" },
    { id: 5, name: "Banking Product (other than CD)" },
    { id: 6, name: "CD" },
    { id: 7, name: "Commodity Option" },
    { id: 8, name: "Debt-Asset Backed" },
    { id: 9, name: "Debt-Corporate" },
    { id: 10, name: "Debt-Government" },
    { id: 11, name: "Debt-Municipal" },
    { id: 12, name: "Derivative" },
    { id: 13, name: "Direct Investment-DPP & LP Interest" },
    { id: 14, name: "Equipment Leasing" },
    { id: 15, name: "Equity Listed (Common and Preferred Stock)" },
    { id: 16, name: "Equity-OTC" },
    { id: 17, name: "Futures Commodity" },
    { id: 18, name: "Futures-Financial" },
    { id: 19, name: "Index Option" },
    { id: 20, name: "Insurance" },
    { id: 21, name: "Investment Contract" },
    { id: 22, name: "Money Market Fund" },
    { id: 23, name: "Mutual Fund" },
    { id: 24, name: "Oil & Gas" },
    { id: 25, name: "Options" },
    { id: 26, name: "Penny Stock" },
    { id: 27, name: "Promissory Note" },
    { id: 28, name: "Real Estate Security" },
    { id: 29, name: "Security Futures" },
    { id: 30, name: "Unit Investment Trust" },
    { id: 31, name: "Viatical Settlement" },
  ];
  return (
    <>
      <div>
        <div className="checkbox-container">
          <div
            id="typeList"
            style={{ paddingTop: "20px" }}
            className="custom-control designations"
          >
            {array1.map(({ id, name }) => (
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  onChange={handleChange}
                  id={`product-${id}-${name}`}
                  value={name}
                  checked={productTypes.includes(name)}
                />
                <label
                  className="custom-control-label"
                  htmlFor={`product-${id}-${name}`}
                  id={`product-label-${name}`}
                >
                  <strong id={`termDiscl${name}`}>{name}</strong>
                </label>
              </div>
            ))}
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                onChange={handleChange}
                onClick={handleShowOtherDiv}
                id="productOther"
                value="Other"
                checked={productTypes.includes("Other")}
              />
              <label
                className="custom-control-label"
                htmlFor="productOther"
                id="product-label-other"
              >
                <strong id="termDisclOther">Other</strong>
              </label>
            </div>
            <div id="othertext" style={{ display: "none" }}>
              <div className="col-md-8">
                <input
                  id="othertextbox"
                  style={{ width: "100%" }}
                  type="text"
                  className={
                    otherTextError
                      ? "form-control form-control-15 is-invalid"
                      : "form-control form-control-15"
                  }
                  maxLength={500}
                  minLength={2}
                  value={otherText}
                  onChange={handleChange}
                  onBlur={onBlur}
                  autoComplete="off"
                />
                <div id="othertextboxError" className="invalid-feedback">
                  Please enter a response
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DRPCheckboxList;
