import React, { useEffect, useState } from "react";
import DRPCard from "../../common/DRPCard";
import { CCB_FORM_ID, headerText } from "../constants";
import DRPSummaryListingCard from "../../common/DRPSummaryListingCard";
import { groupArrayByKey } from "../../helpers";

import "./FinancialDisclosureDRPSummary.scss";
import { SECTION } from "../../constants";
import { putCCBStatus } from "../../../../../../store/middlewares";
import { useDispatch } from "react-redux";

const FinancialDisclosureDRPSummary = ({
  updateCCBForm,
  oktaSubId,
  updateCCB14CompletedInd,
  persBankruptcy = [],
  orgBankruptcy = [],
  bankruptcyList = [],
  bondDiscl = [],
  bondList = [],
  judgLienDisclosures = [],
  judgmentLienList = [],
  getDRPList,
  getPersBankruptcyDRPList,
  getOrgBankruptcyDRPList,
  deleteBondDisclosureDRP,
  editBondDisclosureDRP,
  deleteJudgmentLienDisclosureDRP,
  editJudgmentLienDisclosureDRP,
  deleteBankruptcyDisclosureDRP,
  editBankruptcyDisclosureDRP,
  isDRPNextDisabled,
  setIsDRPNextDisabled,
  loading,
  updateCCB14Step,
  setIsDRPCompleted,
}) => {
  const dispatch = useDispatch();
  const [orgBankGroupedList, setOrgBankGroupedList] = useState("");

  const existingBondDisclKeys = bondDiscl?.flatMap((x) => x.key);
  const existingJudgLienDisclKeys = judgLienDisclosures?.flatMap((x) => x.key);
  const existingBankruptcyKeys = [...persBankruptcy, ...orgBankruptcy]?.flatMap(
    (x) => x.key
  );

  const currBondDisclKeys = bondList?.length ? ["14L"] : [];
  const currJudgmentLienKeys = judgmentLienList?.length ? ["14M"] : [];
  const currBankruptcyKeys = [
    ...new Set(bankruptcyList?.flatMap((x) => x?.bankruptcydrp?.drpList)),
  ];

  const isDisabled =
    existingBondDisclKeys.length !== currBondDisclKeys?.length ||
    existingJudgLienDisclKeys.length !== currJudgmentLienKeys?.length ||
    existingBankruptcyKeys.length !== currBankruptcyKeys?.length;

  const func = () => {
    let list = getOrgBankruptcyDRPList(bankruptcyList);
    setOrgBankGroupedList(groupArrayByKey(list, "qKey"));
  };

  useEffect(
    () => {
      if (orgBankGroupedList === "") {
        func();
      }
    } /* eslint-disable-next-line react-hooks/exhaustive-deps */,
    [orgBankGroupedList]
  );

  useEffect(
    () => {
      func();
    } /* eslint-disable-next-line react-hooks/exhaustive-deps */,
    [bankruptcyList]
  );

  const updateStatus = async (data) => {
    try {
      await dispatch(
        putCCBStatus({ oktaSubId, data: { financialdisclstatus: data } })
      );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!loading) {
      setIsDRPNextDisabled(isDisabled);

      if (!!isDisabled) {
        updateCCB14Step({ finDisclosureCompleteStep: "2/2" });
        updateCCB14CompletedInd({ finDisclosureCompleteInd: false });
        setIsDRPCompleted(false);
      } else {
        updateCCB14CompletedInd({ finDisclosureCompleteInd: true });
        setIsDRPCompleted(true);
      }

      updateStatus(isDisabled ? "1/2" : null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisabled, loading]);

  useEffect(
    () => {
      setIsDRPNextDisabled(isDRPNextDisabled);
    } /* eslint-disable-next-line react-hooks/exhaustive-deps */,
    [isDRPNextDisabled]
  );

  return (
    <div className="financial-disclosure-drp-summary">
      {persBankruptcy?.length ? (
        <DRPCard
          header={headerText.PERS_BANKRUPTCY}
          onClick={() => updateCCBForm(CCB_FORM_ID.ADD_BANKRUPTCY_DRP_PAGE_1)}
          style={{ overflow: "auto" }}
        >
          {persBankruptcy?.map(({ key, mainQuestion, question }, index) => (
            <DRPSummaryListingCard
              key={`${index + 1}_${key}_persBankruptcy`}
              questionKey={key}
              mainQuestion={mainQuestion}
              question={question}
              index={index}
              length={persBankruptcy?.length}
              list={getPersBankruptcyDRPList(bankruptcyList)}
              isSummaryPage
              section={SECTION.personalBankruptcy}
              deleteDRP={deleteBankruptcyDisclosureDRP}
              editDRP={editBankruptcyDisclosureDRP}
            />
          ))}
        </DRPCard>
      ) : null}

      {orgBankruptcy?.length ? (
        <DRPCard
          header={headerText.ORG_BANKRUPTCY}
          onClick={() => updateCCBForm(CCB_FORM_ID.ADD_BANKRUPTCY_ORG)}
          style={{ overflow: "auto" }}
        >
          {orgBankruptcy?.map(({ key, mainQuestion, question }, index) => (
            <DRPSummaryListingCard
              key={`${index + 1}_${key}_orgBankruptcy`}
              questionKey={key}
              mainQuestion={mainQuestion}
              question={question}
              index={index}
              isSummaryPage
              length={orgBankruptcy?.length}
              list={orgBankGroupedList[key]}
              section={SECTION.organizationBankruptcy}
              deleteDRP={deleteBankruptcyDisclosureDRP}
              editDRP={editBankruptcyDisclosureDRP}
            />
          ))}
        </DRPCard>
      ) : null}

      {bondDiscl?.length ? (
        <DRPCard
          header={headerText.BOND_DISCL}
          onClick={() => updateCCBForm(CCB_FORM_ID.ADD_BOND_DRP_PAGE_1)}
          style={{ overflow: "auto" }}
        >
          {bondDiscl?.map(({ key, mainQuestion, question }, index) => (
            <DRPSummaryListingCard
              key={`${index + 1}_${key}_bondDiscl`}
              questionKey={key}
              mainQuestion={mainQuestion}
              question={question}
              index={index}
              isSummaryPage
              length={bondDiscl?.length}
              list={getDRPList(bondList, "bond")}
              section={SECTION.bondDisclosures}
              deleteDRP={deleteBondDisclosureDRP}
              editDRP={editBondDisclosureDRP}
            />
          ))}
        </DRPCard>
      ) : null}

      {judgLienDisclosures?.length ? (
        <DRPCard
          header={headerText.JUDG_LIEN_DISCL}
          onClick={() =>
            updateCCBForm(CCB_FORM_ID.ADD_JUDGMENT_LIEN_DRP_PAGE_1)
          }
          style={{ overflow: "auto" }}
        >
          {judgLienDisclosures?.map(
            ({ key, mainQuestion, question }, index) => (
              <DRPSummaryListingCard
                key={`${index + 1}_${key}_judgLienDisclosures`}
                questionKey={key}
                mainQuestion={mainQuestion}
                question={question}
                index={index}
                isSummaryPage
                length={judgLienDisclosures?.length}
                list={getDRPList(judgmentLienList, "judgment/lien")}
                section={SECTION.judgementLienDisclosures}
                deleteDRP={deleteJudgmentLienDisclosureDRP}
                editDRP={editJudgmentLienDisclosureDRP}
              />
            )
          )}
        </DRPCard>
      ) : null}
    </div>
  );
};

export default FinancialDisclosureDRPSummary;
