/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useCallback, useState, useEffect } from "react";
import { Button } from "react-blueprint-library";
import BackButtonModal from "../../../../../../Forms/BackButtonModal";
import { RADIO } from "../../../../constants";

const initialValues = {
  satisfiedCondition: null,
  typeOfExam: null,
  requalificationType: null,
  explanation: null,
  lengthOfTime: null,
  lengthOfTimeRadio: null,
  lengthOfTimeExp: null,
  requalificationTypeOther: null,
};

const lengthOfTimeTypes = {
  EXACT: "Exact",
  EXP: "Explanation",
};

const requalificationTypes = {
  REQ: "Requalification by Exam",
  RE_TRAIN: "Re-Training",
  OTHER: "Other",
};

const formalChargeStyle = {
  height: "80px",
  minHeight: "80px",
};

const AddNewRequalificationContent = ({
  data,
  getSubmitDataValues,
  setShowAddNewChargeModal,
  setIsModalUpdates,
  dataIndex,
  headerText,
}) => {
  const [formHasChanged, setFormHasChanged] = useState(false);

  const [requalificationType, setRequalificationType] = useState(
    data?.requalificationType || ""
  );
  const [requalificationTypeOther, setRequalificationTypeOther] = useState(
    data?.requalificationTypeOther || ""
  );
  const [requalificationTypeOtherError, setRequalificationTypeOtherError] =
    useState(false);

  const [lengthOfTime, setLengthOfTime] = useState(data?.lengthOfTime || "");
  const [lengthOfTimeError, setLengthOfTimeError] = useState(false);

  const [lengthOfTimeRadio, setLengthOfTimeRadio] = useState(
    data?.lengthOfTimeRadio || ""
  );
  const [lengthOfTimeExp, setLengthOfTimeExp] = useState(
    data?.lengthOfTimeExp || ""
  );
  const [lengthOfTimeExpError, setLengthOfTimeExpError] = useState(false);

  const [typeOfExam, setTypeOfExam] = useState(data?.typeOfExam || "");
  const [typeOfExamError, setTypeOfExamError] = useState(false);

  const [satisfiedCondition, setSatisfiedCondition] = useState(
    typeof data?.satisfiedCondition == "boolean"
      ? data?.satisfiedCondition
      : null
  );

  const [explanation, setExplanation] = useState(data?.explanation || "");
  const [explanationError, setExplanationError] = useState(false);

  const [showBackModal, setShowBackModal] = useState(false);

  const onChangeCourtActionHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setRequalificationTypeOther(value);
  };
  const onBlurCourtActionHandler = ({ target: { value } }) => {
    return value?.trim()?.length < 2 || value.trim()?.length > 500
      ? setRequalificationTypeOtherError(true)
      : setRequalificationTypeOtherError(false);
  };

  const handleChangeReducedChecked = (e) => {
    const { value } = e.target;
    setFormHasChanged(true);
    let newValue = value;

    setRequalificationTypeOther("");
    setRequalificationTypeOtherError(false);

    setRequalificationType(newValue);
  };

  const onChangeLengthOfTimeHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setLengthOfTime(value);
  };
  const onBlurLengthOfTimeHandler = ({ target: { value } }) => {
    return value.trim().length < 2 || value.trim().length > 100
      ? setLengthOfTimeError(true)
      : setLengthOfTimeError(false);
  };

  const handleChangeLengthOfTimeChecked = (e) => {
    const { value } = e.target;
    let newValue = value;

    setLengthOfTimeExpError(false);
    setFormHasChanged(true);
    setLengthOfTimeExp("");

    setLengthOfTimeRadio(newValue);
  };

  const onLengthOfTimeExplanationChange = ({ target: { value } }) => {
    setFormHasChanged(true);
    setLengthOfTimeExp(value);
  };

  const onBlurLengthOfTimeExpHandler = ({ target: { value } }) => {
    return value.trim()?.length < 2 || value.trim()?.length > 500
      ? setLengthOfTimeExpError(true)
      : setLengthOfTimeExpError(false);
  };

  const onChargeDescriptionChangeHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setExplanation(value);
  };
  const onBlurChargeDescriptionHandler = ({ target: { value } }) => {
    return value.trim().length > 100
      ? setExplanationError(true)
      : setExplanationError(false);
  };

  const handleChangeChecked = (e) => {
    const { value } = e.target;
    let newValue = value;

    setFormHasChanged(true);

    switch (value) {
      case RADIO.yes:
        newValue = true;
        break;
      case RADIO.no:
        newValue = false;
        break;
      default:
        newValue = null;
        break;
    }

    setSatisfiedCondition(newValue);
  };

  const handleCloseBackModal = () => {
    setShowBackModal(false);
    setShowAddNewChargeModal(true);
  };

  const isError =
    requalificationTypeOtherError ||
    lengthOfTimeExpError ||
    lengthOfTimeError ||
    typeOfExamError ||
    explanationError;

  useEffect(() => {
    if (isError) {
      setFormHasChanged(true);
    }
  }, [isError]);

  const isLengthOfTime =
    lengthOfTimeRadio.length ||
    (lengthOfTimeRadio.length &&
      lengthOfTimeRadio === lengthOfTimeTypes.EXP &&
      lengthOfTimeExp.length);

  const isUpdates =
    (data?.length === 0 &&
      (isLengthOfTime ||
        satisfiedCondition !== null ||
        requalificationTypeOther.length ||
        requalificationType?.length ||
        explanation.length)) ||
    formHasChanged ||
    isError;

  useEffect(() => {
    if (isUpdates || isError) {
      setIsModalUpdates(true);
    } else {
      setIsModalUpdates(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdates, isError]);

  const isNextDisabledFirstForm =
    !requalificationType?.length ||
    !typeOfExam?.length ||
    (typeOfExam.length && typeOfExam.length < 2);

  const isNextDisabled = !!(
    !lengthOfTime.length ||
    (lengthOfTime.length &&
      lengthOfTimeTypes.EXP === lengthOfTimeRadio &&
      !lengthOfTimeExp.length) ||
    (requalificationType?.length &&
      requalificationType === requalificationTypes.OTHER &&
      !requalificationTypeOther?.length) ||
    (requalificationTypeOther?.length &&
      requalificationTypeOther?.length < 2) ||
    !lengthOfTime?.length ||
    !lengthOfTimeRadio?.length ||
    (lengthOfTime?.length && lengthOfTime?.length < 2) ||
    (lengthOfTime?.length &&
      lengthOfTimeRadio?.length &&
      lengthOfTimeRadio === lengthOfTimeTypes.EXP &&
      !lengthOfTimeExp?.length) ||
    satisfiedCondition === null ||
    isNextDisabledFirstForm ||
    isError
  );

  const handleShowBackModal = () => {
    if (isUpdates || isError) {
      setShowBackModal(true);
      setFormHasChanged(isUpdates);
    } else {
      getSubmitDataValues(
        {
          ...initialValues,
        },
        headerText
      );

      setShowAddNewChargeModal(false);
    }
  };

  const handleBackClick = () => {
    getSubmitDataValues({ ...initialValues }, headerText);
    handleCloseBackModal();
    setShowAddNewChargeModal(false);
  };

  const onChangeTypeOfExamHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setTypeOfExam(value);
  };
  const onBlurTypeOfExamHandler = ({ target: { value } }) => {
    return value.trim().length < 2 || value.trim().length > 100
      ? setTypeOfExamError(true)
      : setTypeOfExamError(false);
  };

  const handleAddRequaification = useCallback(() => {
    const submitData = {
      satisfiedCondition,
      typeOfExam,
      requalificationType,
      requalificationTypeOther,
      explanation,
      lengthOfTime,
      lengthOfTimeRadio,
      lengthOfTimeExp,
    };

    getSubmitDataValues({ ...submitData }, dataIndex);

    setFormHasChanged(false);

    setShowAddNewChargeModal(false);
  }, [
    getSubmitDataValues,
    typeOfExam,
    satisfiedCondition,
    requalificationType,
    requalificationTypeOther,
    explanation,
    setShowAddNewChargeModal,
    dataIndex,
    lengthOfTime,
    lengthOfTimeRadio,
    lengthOfTimeExp,
  ]);

  return (
    <div className="add-new-charge-children-wrapper">
      <div className="row">
        <div className="col-md-12">
          <label className="req-type-label" id="regReqTypeLabelId">
            Requalification Type: *
          </label>
        </div>
      </div>
      <div className="row">
        <div className="drp-radio-input-wrapper">
          <div className="col-12 copy-md-4 form-group">
            <div className="custom-control custom-radio">
              <input
                name="reqExamId"
                type="radio"
                id="reqExamId"
                className="custom-control-input"
                value={requalificationTypes.REQ}
                checked={requalificationType === requalificationTypes.REQ}
                onChange={handleChangeReducedChecked}
              />
              <label
                htmlFor="reqExamId"
                className="custom-control-label"
                id="reqExamLabelId"
              >
                {requalificationTypes.REQ}
              </label>
            </div>

            <div className="custom-control custom-radio">
              <input
                name="reqRetrainId"
                type="radio"
                id="reqRetrainId"
                className="custom-control-input"
                value={requalificationTypes.RE_TRAIN}
                checked={requalificationType === requalificationTypes.RE_TRAIN}
                onChange={handleChangeReducedChecked}
              />
              <label
                htmlFor="reqRetrainId"
                className="custom-control-label"
                id="reqRetrainLabelId"
              >
                {requalificationTypes.RE_TRAIN}
              </label>
            </div>

            <div className="custom-control custom-radio">
              <input
                name="otherReducedRadio"
                type="radio"
                id="otherReducedRadioId"
                className="custom-control-input"
                value={requalificationTypes.OTHER}
                checked={requalificationType === requalificationTypes.OTHER}
                onChange={handleChangeReducedChecked}
              />
              <label
                htmlFor="otherReducedRadioId"
                className="custom-control-label"
                id="otherReducedRadioLabelId"
              >
                {requalificationTypes.OTHER}
              </label>
            </div>

            {requalificationType === requalificationTypes.OTHER ? (
              <div className="court-action-other">
                <input
                  type="text"
                  id="otherText"
                  className={
                    requalificationTypeOtherError
                      ? "form-control form-control-15 is-invalid"
                      : "form-control form-control-15"
                  }
                  maxLength={500}
                  minLength={2}
                  autoComplete="off"
                  value={requalificationTypeOther}
                  onChange={onChangeCourtActionHandler}
                  onBlur={onBlurCourtActionHandler}
                />
                <div
                  id="requalificationTypeOtherError"
                  className="invalid-feedback"
                >
                  Please enter a response
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div
        className="col-md-12 event-status-date-wrapper"
        style={{ margin: 0 }}
      >
        <label id="lengthOfTimeLabelId" htmlFor="lengthOfTimeId">
          <strong id="lengthOfTimeText">
            Length of time given to requalify/retrain: *
          </strong>
        </label>
        <input
          type="text"
          id="lengthOfTimeId"
          className={
            lengthOfTimeError
              ? "form-control form-control-15 is-invalid"
              : "form-control form-control-15"
          }
          style={{ width: "275px" }}
          maxLength={100}
          minLength={2}
          autoComplete="off"
          value={lengthOfTime}
          onChange={onChangeLengthOfTimeHandler}
          onBlur={onBlurLengthOfTimeHandler}
        />
        <div id="lengthOfTimeError" className="invalid-feedback">
          Please enter a response
        </div>
      </div>

      <div className="row event-status-date-radio-group">
        <div className="custom-control">
          <div className="col-12 copy-md-8 form-group">
            <div className="custom-control custom-radio">
              <input
                name="radio-guide-lengthOfTimeExactId"
                type="radio"
                id="lengthOfTimeExactId"
                className="custom-control-input"
                value={lengthOfTimeTypes.EXACT}
                checked={lengthOfTimeRadio === lengthOfTimeTypes.EXACT}
                onChange={handleChangeLengthOfTimeChecked}
              />
              <label
                htmlFor="lengthOfTimeExactId"
                className="custom-control-label"
                id="lengthOfTimeExactLabelId"
              >
                {lengthOfTimeTypes.EXACT}
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                name="radio-guide-lengthOfTimeExplanationId"
                type="radio"
                id="lengthOfTimeExplanationId"
                className="custom-control-input"
                value={lengthOfTimeTypes.EXP}
                checked={lengthOfTimeRadio === lengthOfTimeTypes.EXP}
                onChange={handleChangeLengthOfTimeChecked}
              />
              <label
                htmlFor="lengthOfTimeExplanationId"
                className="custom-control-label"
                id="lengthOfTimeExplanationLabelId"
              >
                {lengthOfTimeTypes.EXP}
              </label>
            </div>

            {lengthOfTimeRadio === lengthOfTimeTypes.EXP ? (
              <div className="event-status-date-explanation-container">
                <label
                  htmlFor="lengthOfTimeExplanation"
                  className="event-status-date-exp"
                  id="lengthOfTimeExplanationTextLabelId"
                >
                  If not exact, provide explanation *
                </label>
                <textarea
                  id="lengthOfTimeExplanation"
                  onChange={onLengthOfTimeExplanationChange}
                  className={
                    lengthOfTimeExpError
                      ? "form-control form-control-15 is-invalid"
                      : "form-control form-control-15"
                  }
                  maxLength={500}
                  minLength={2}
                  onBlur={onBlurLengthOfTimeExpHandler}
                  rows="3"
                  cols={500}
                  value={lengthOfTimeExp}
                />
                <div id="lengthOfTimeExpError" className="invalid-feedback">
                  Please enter an Explanation
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div className="row" style={{ marginTop: "-10px" }}>
        <div className="col-md-12">
          <label
            className="plea-each-charge"
            htmlFor="typeOfExam"
            id="typeOfExamLabelId"
          >
            Type of exam required: *
          </label>
          <input
            type="text"
            id="typeOfExam"
            className={
              typeOfExamError
                ? "form-control form-control-15 is-invalid"
                : "form-control form-control-15"
            }
            maxLength={100}
            minLength={2}
            autoComplete="off"
            value={typeOfExam}
            onChange={onChangeTypeOfExamHandler}
            onBlur={onBlurTypeOfExamHandler}
          />
          <div id="typeOfExamError" className="invalid-feedback">
            Please enter a response
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <label className="inv-business" id="satisfiedConditionLabelId">
            Has condition been satisfied? *
          </label>
        </div>
      </div>
      <div className="row">
        <div className="drp-radio-input-wrapper">
          <div className="col-12 copy-md-4 form-group">
            <div className="custom-control custom-radio">
              <input
                name="satisfiedCondition"
                type="radio"
                id="satisfiedCondition_yes"
                className="custom-control-input"
                value={true}
                checked={satisfiedCondition === true}
                onChange={handleChangeChecked}
              />
              <label
                htmlFor="satisfiedCondition_yes"
                className="custom-control-label"
                id="satisfiedCondition_yes_label"
              >
                Yes
              </label>
            </div>

            <div className="custom-control custom-radio">
              <input
                name="satisfiedCondition"
                type="radio"
                id="satisfiedCondition_no"
                className="custom-control-input"
                value={false}
                checked={satisfiedCondition === false}
                onChange={handleChangeChecked}
              />
              <label
                htmlFor="satisfiedCondition_no"
                className="custom-control-label"
                id="satisfiedCondition_no_label"
              >
                No
              </label>
            </div>
            <div id="radio_error" className="invalid-feedback">
              Please choose a response.
            </div>
          </div>
        </div>
      </div>

      <div className="formal-charge-container">
        <label
          htmlFor="formalChargeId"
          id="satisfiedExplanationLabelId"
          className="formal-charge-label"
        >
          Explanation: (Optional)
        </label>
        <textarea
          id="satisfiedExplanationTextLabelId"
          style={{ ...formalChargeStyle }}
          onChange={onChargeDescriptionChangeHandler}
          className={
            explanationError
              ? "form-control form-control-15 is-invalid"
              : "form-control form-control-15 formal-charge-id"
          }
          minLength={0}
          maxLength={100}
          rows="3"
          cols={500}
          onBlur={onBlurChargeDescriptionHandler}
          value={explanation}
        />
        <div id="explanationError" className="invalid-feedback">
          Please enter an Explanation
        </div>
      </div>

      <div className="row drp-add-new-charge-btns">
        <div className="col-md-12 buttons">
          <span
            onClick={handleShowBackModal}
            style={{
              marginRight: "25px",
              color: "#3174af",
              cursor: "pointer",
            }}
            role="presentation"
          >
            <strong>Cancel</strong>
          </span>

          <Button
            className="addChargeButton"
            label={dataIndex !== null ? "Save" : "Add Requaification"}
            onClick={handleAddRequaification}
            disabled={isNextDisabled}
          />

          {false && <span className="mm-dots-loading"></span>}
        </div>
      </div>

      <BackButtonModal
        isOpen={showBackModal}
        closeBackModal={handleCloseBackModal}
        backClick={handleBackClick}
      />
    </div>
  );
};

export default AddNewRequalificationContent;
