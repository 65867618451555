import "../../../assets/stylesheets/StartApplication/CAS/AddedEmpHistToast.scss";

function AddedEmpHistToast(props) {
  const closeToast = () => {
    props.closeToast();
  };
  return (
    <>
      <div
        id="emphist_added_alert"
        className={
          props.showToast === true
            ? "show col-md-6 alert-item"
            : "d-none col-md-6 alert-item"
        }
        style={{ float: "right" }}
      >
        <div className="mm-toast-container">
          <div
            className="toast"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
          >
            <div className="wrapper">
              <div className="toast-header">
                <strong>
                  Your experience has been {props.addedSaved} successfully.
                </strong>
                <button
                  className="icon-close"
                  type="button"
                  data-dismiss="toast"
                  aria-label="Close"
                  onClick={closeToast}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddedEmpHistToast;
