import { KEYS, SECTION } from "../../constants";
import {
  getDRPSummaryQuestions,
  getDRPSummaryUncheckedQuestionKeys,
  getSpecificObjByKeys,
} from "../../helpers";
import {
  DRPSummaryIndividualCriminalDisclosure,
  DRPSummaryOrganizationCriminalDisclosure,
} from "../state";

export const filterByUnchecked = (section, uncheckedList, data) => {
  const sectionKey =
    section === SECTION.individualCriminalDisclosure
      ? `individualcriminaldisclosure`
      : `organizationcriminaldisclosure`;

  const sectionData = data[sectionKey];
  let results = [];

  uncheckedList?.forEach((uncheckedKey) => {
    if (sectionData?.hasOwnProperty(uncheckedKey)) {
      const entries = sectionData[uncheckedKey];

      if (section === SECTION.individualCriminalDisclosure) {
        entries.forEach((entry) => {
          if (entry?.courtDetails?.drpCheckList.includes(uncheckedKey)) {
            const types =
              entry?.eventDispDetails?.newChargeDetailsList?.map((detail) => {
                const firstSection = `${detail?.felonyOrMisdemeanor}`;
                const secondSection = `${detail?.nrOfCounts} count${
                  detail?.nrOfCounts > 1 ? "s" : ""
                }`;

                return `${firstSection}; ${secondSection} of ${detail?.formalChargeDescription}`;
              }) || null;

            results.push({
              qkey: uncheckedKey,
              isDraft: entry.isDraft,
              types: types || [],
              date: entry?.eventDispDetails?.dateFirstCharged || null,
              dataIndex: entry.dataIndex,
            });
          }
        });
      } else {
        entries.forEach((entry) => {
          if (entry?.orgDetails?.drpCheckList.includes(uncheckedKey)) {
            const types =
              entry?.eventDispDetails?.newChargeDetailsList?.map((detail) => {
                const firstSection = `${detail?.felonyOrMisdemeanor}`;
                const secondSection = `${detail?.nrOfCounts} count${
                  detail?.nrOfCounts > 1 ? "s" : ""
                }`;

                return `${firstSection}; ${secondSection} of ${detail?.formalChargeDescription}`;
              }) || null;

            const orgName = entry?.orgDetails?.orgName || null;

            results.push({
              qkey: uncheckedKey,
              isDraft: entry.isDraft,
              types: types || [],
              orgName,
              date: entry?.eventDispDetails?.dateFirstCharged || null,
              dataIndex: entry.dataIndex,
            });
          }
        });
      }
    }
  });

  return results;
};

export const isDrpSummaryPage = (data) => {
  const indCriminalDisclosureObj = getSpecificObjByKeys(
    data,
    KEYS.individualCriminalDisclosure
  );

  const orgCriminalDisclosureObj = getSpecificObjByKeys(
    data,
    KEYS.organizationCriminalDisclosure
  );

  const ind = getDRPSummaryQuestions(
    DRPSummaryIndividualCriminalDisclosure,
    indCriminalDisclosureObj
  );

  const org = getDRPSummaryQuestions(
    DRPSummaryOrganizationCriminalDisclosure,
    orgCriminalDisclosureObj
  );

  const uncheckedInd = getDRPSummaryUncheckedQuestionKeys(
    DRPSummaryIndividualCriminalDisclosure,
    indCriminalDisclosureObj
  );

  const uncheckedOrg = getDRPSummaryUncheckedQuestionKeys(
    DRPSummaryOrganizationCriminalDisclosure,
    orgCriminalDisclosureObj
  );

  return {
    ind,
    org,
    uncheckedInd,
    uncheckedOrg,
  };
};
