import { fetchWrapper } from "./helpers";
import { RequestMethodTypes } from "./helpers/constants";

export const getUSPSAddress = async (data) => {
  const response = await fetchWrapper({
    method: RequestMethodTypes.POST,
    url: "uspsaddress",
    data,
  });

  return response;
};
