import { KEYS, SECTION } from "../../constants";
import {
  convertGroupKey,
  getDRPSummaryQuestions,
  getDRPSummaryUncheckedQuestionKeys,
  getSpecificObjByKeys,
} from "../../helpers";
import { headerText } from "../constants";
import { DRPSummaryCivilJudicialActions } from "../state";

export const filterByUnchecked = (section, uncheckedList, data) => {
  const sectionKey =
    section === SECTION.civilJudicialActions
      ? convertGroupKey(headerText.CIV_JUD_ACT)
      : null;

  if (!sectionKey) {
    return [];
  }

  const sectionData = data[sectionKey];
  let results = [];

  uncheckedList?.forEach((uncheckedKey) => {
    if (sectionData?.hasOwnProperty(uncheckedKey)) {
      const entries = sectionData[uncheckedKey];

      if (section === SECTION.civilJudicialActions) {
        entries.forEach((entry) => {
          if (entry?.actDetails?.drpCheckList.includes(uncheckedKey)) {
            const types = [entry?.actDetails?.courtAction] || null;
            const status = entry?.statusDetails?.status;

            results.push({
              qkey: uncheckedKey,
              isDraft: entry.isDraft,
              types: types || [],
              status,
              date: entry?.details?.filingDate || null,
              dataIndex: entry.dataIndex,
            });
          }
        });
      }
    }
  });

  return results;
};

export const isDrpSummaryPage = (data) => {
  const civilJudicialActionsObj = getSpecificObjByKeys(
    data,
    KEYS.civilJudicialActions
  );

  const civ = getDRPSummaryQuestions(
    DRPSummaryCivilJudicialActions,
    civilJudicialActionsObj
  );

  const uncheckedCiv = getDRPSummaryUncheckedQuestionKeys(
    DRPSummaryCivilJudicialActions,
    civilJudicialActionsObj
  );

  return { civ, uncheckedCiv };
};
