import React, { useEffect, useMemo } from "react";
import DRPCard from "../../common/DRPCard";

import "./CivilJudicialDRPSummary.scss";

import { CCB_FORM_ID, headerText } from "../constants";
import DRPSummaryListingCard from "../../common/DRPSummaryListingCard";
import { logoutActions } from "../../../../../../store/actions";
import { useDispatch } from "react-redux";
import { putCCBStatus } from "../../../../../../store/middlewares";
import { groupArrayByKey } from "../../helpers";
import { DRP_TYPES } from "../../../../../../api/helpers/constants";
import {
  deleteSingleCCB14DrpCivJud,
  getSingleCCBDRPCivJudByQkey,
} from "../../../../../../api/CCB/DRP/civilJudDRP";

const CivilJudicialDRPSummary = ({
  updateCCBForm,
  oktaSubId,
  setIsDRPNextDisabled,
  updateCCB14Step,
  updateCCB14CompletedInd,
  setIsDRPCompleted,
  isLoading,
  setIsLoading,
  civ = [],
  data,
  setIsDataRefetch,
}) => {
  const dispatch = useDispatch();

  const resetShowBackModal = () =>
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: false }));

  const existingCivKeys = civ?.flatMap((x) => x.key);
  const currCivKeys = [...new Set(data?.civ.flatMap((x) => x.qKey))];

  const civDraft = data?.civ.find((x) => x.isDraft === true);

  const isDisabled = currCivKeys.length !== existingCivKeys.length || civDraft;

  const updateStatus = async (data) => {
    try {
      await dispatch(
        putCCBStatus({ oktaSubId, data: { civiljudstatus: data } })
      );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!isLoading) {
      setIsDRPNextDisabled(isDisabled);

      if (!!isDisabled) {
        updateCCB14Step({ civilJudDisclosureCompleteStep: "2/2" });
        updateCCB14CompletedInd({ civilJudDisclosureCompleteInd: false });
        setIsDRPCompleted(false);
      } else {
        updateCCB14CompletedInd({ civilJudDisclosureCompleteInd: true });
        setIsDRPCompleted(true);
      }

      updateStatus(isDisabled ? "1/2" : null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisabled, isLoading]);

  const handleOnClickCivJud = () => {
    resetShowBackModal();

    updateCCBForm(CCB_FORM_ID.CIV_JUD_ACT_DETAILS, {
      section: headerText.CIV_JUD_ACT,
      values: civ,
      isReset: true,
    });
  };

  const listCiv = useMemo(() => groupArrayByKey(data?.civ, "qKey"), [data.civ]);

  const editDRPCivJud = async ({ questionKey, dataIndex, isDraft }) => {
    setIsLoading(true);

    const data = await getSingleCCBDRPCivJudByQkey({
      oktaSubId,
      section: "civ",
      qKey: questionKey,
      drpTypeId: DRP_TYPES.CivilJudicial,
      index: dataIndex,
    });
    setIsLoading(false);

    updateCCBForm(CCB_FORM_ID.CIV_JUD_ACT_DETAILS, {
      section: headerText.CIV_JUD_ACT,
      values: civ,
      isEdit: true,
      data: data,
      isDraft,
    });
  };

  const deleteDRPCivJud = async ({ questionKey, dataIndex }) => {
    setIsLoading(true);

    await deleteSingleCCB14DrpCivJud({
      oktaSubId,
      section: "civ",
      questionKey,
      index: dataIndex,
      drpTypeId: DRP_TYPES.CivilJudicial,
    });

    setIsLoading(false);

    updateCCBForm(CCB_FORM_ID.DRP_SUMMARY, {
      isDelete: true,
      index: dataIndex,
    });

    setIsDataRefetch(true);
  };

  return (
    <div className="civil-jud-act-drp-summary">
      {civ?.length ? (
        <DRPCard header={headerText.CIV_JUD_ACT} onClick={handleOnClickCivJud}>
          {civ?.map(({ key, mainQuestion, question }, index) => (
            <DRPSummaryListingCard
              key={`${index}_${key}`}
              questionKey={key}
              mainQuestion={mainQuestion}
              question={question}
              index={index}
              length={civ?.length}
              list={listCiv[key]}
              isSummaryPage
              isLoading={isLoading}
              editDRP={editDRPCivJud}
              deleteDRP={deleteDRPCivJud}
              section={headerText.CIV_JUD_ACT}
            />
          ))}
        </DRPCard>
      ) : null}
    </div>
  );
};

export default CivilJudicialDRPSummary;
