import React from "react";
import Footer from "../SharedComponents/Footer";
import Header from "./ErrorHeader";
import "../../assets/stylesheets/ErrorPage.scss";

function ErrorPage() {
  return (
    <div id="ErrorPage">
      <Header />

      <div id="ErrorBody" className="container">
        <div
          className="mm-illustration-buffer illustration-error_500 col-md-11"
          id="ErrorImg"
          role="img"
        ></div>
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6">
            <h2>
              <br />
              <br />
              There was an error processing your request; <br /> please try
              again later.
            </h2>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
export default ErrorPage;
