import { FORM_ID } from "../../../../../constants/Forms";

function ReviewPersonalInfo(props) {
  const toggleForm = () => {
    props.toggleForm(FORM_ID.PERSONAL_INFO);
  };
  return (
    <div id="psnl-info">
      <div className="row">
        <div className="col-8" id="personal-info">
          <h4 id="pi">Personal Information</h4>
        </div>
        <div className="col-4" id="edit-button">
          <span className="btn btn-link float-right" onClick={toggleForm}>
            Edit
          </span>
        </div>
      </div>

      <div className="row">
        <div className="col-3" id="frst-name">
          <h5>Legal First Name</h5>
        </div>
        <div id="firstname" className="col-3 data">
          {props.personalInformation.firstname}
        </div>
        {props.personalInformation.middlename !== "" && (
          <>
            <div className="col-3" id="middle-name">
              <h5>Middle Name</h5>
            </div>
            <div id="middlename" className="col-3 data">
              {props.personalInformation.middlename}
            </div>
          </>
        )}

        <div className="col-3" id="lst-name">
          <h5>Legal Last Name</h5>
        </div>
        <div id="lastname" className="col-3 data">
          {props.personalInformation.lastname}
        </div>

        {props.personalInformation.suffix !== "" && (
          <>
            <div className="col-3" id="suffx">
              <h5>Suffix</h5>
            </div>
            <div id="suffix" className="col-3 data">
              {props.personalInformation.suffix}
            </div>
          </>
        )}

        {props.personalInformation.preferredfirstname !== "" &&
          props.personalInformation.preferredfirstname !== null && (
            <>
              <>
                <div className="col-3" id="prfrred-fst-nme">
                  <h5>Preferred First Name</h5>
                </div>
                <div id="preferredfirstname" className="col-3 data">
                  {props.personalInformation.preferredfirstname}
                </div>
              </>
              {props.personalInformation.preferredlastname !== "" &&
                props.personalInformation.preferredlastname !== null && (
                  <>
                    <div className="col-3" id="prferrd-lst-name">
                      <h5>Preferred Last Name</h5>
                    </div>
                    <div id="preferredlastname" className="col-3 data">
                      <>{props.personalInformation.preferredlastname}</>
                    </div>
                  </>
                )}
            </>
          )}
        <>
          <div className="col-3" id="ssn-no">
            <h5>Social Security Number</h5>
          </div>
          <div id="ssn" className="col-3 data">
            {props.candidatePresentationMode
              ? `XXX-XX-${props.personalInformation.ssn.slice(-4)}`
              : props.personalInformation.ssn}
          </div>
        </>
        <>
          <div className="col-3" id="d-o-b">
            <h5>Date of Birth</h5>
          </div>
          <div id="dateofbirth" className="col-3 data">
            {props.personalInformation.dateofbirth}
          </div>
        </>
        <>
          <div className="col-3" id="gndr">
            <h5>Gender</h5>
          </div>
          <div id="gender" className="col-3 data">
            {props.personalInformation.genderdesc}
          </div>
        </>
        <>
          <div className="col-3" id="phn">
            <h5>Phone Number</h5>
          </div>
          <div id="phonenumber" className="col-3 data">
            {props.personalInformation.phonenumber.slice(-12)}
          </div>
        </>
        <>
          <div className="col-3" id="eml">
            <h5>Email</h5>
          </div>
          <div id="emailaddress" className="col-3 data">
            {props.personalInformation.emailaddress}
          </div>
        </>
      </div>
    </div>
  );
}

export default ReviewPersonalInfo;
