import { RequestMethodTypes } from "./helpers/constants";
import { fetchWrapper } from "./helpers";

export async function saveOtherBusinessData(oktaSubId, data) {
  const result = fetchWrapper({
    method: RequestMethodTypes.PUT,
    url: `${oktaSubId}/otherbusiness`,
    data: data,
  });

  return result;
}

export const getOtherBusiness = async (oktaSubId) => {
  const otherbusiness = await fetchWrapper({
    method: RequestMethodTypes.GET,
    url: `${oktaSubId}/otherbusiness`,
  });

  return otherbusiness?.data;
};
