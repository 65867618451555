import React from "react";
import "../DRPCard.scss";

const DRPCardNoButton = ({ header, children, isDrp }) => {
  return (
    <div className={`drp-main-card ${isDrp ? "is-drp" : ""}`}>
      <div className="header-wrapper">
        <div id="drp-card-header" className="header">
          {header}
        </div>
      </div>
      <div className="drp-container">{children}</div>
    </div>
  );
};

export default DRPCardNoButton;
