/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { withOktaAuth } from "@okta/okta-react";
//import UserName from "./UserName";
//import logo from "../assets/img/MM_FullMark_Reg_White.svg";
import logo from "../../assets/img/Orbit_logo.svg";
import "../../assets/stylesheets/Header.scss";

export default withOktaAuth(
  class Header extends Component {
    constructor(props) {
      super(props);
      this.logout = this.logout.bind(this);
    }

    async logout() {
      await this.props.oktaAuth.signOut();
    }

    render() {
      return (
        <>
          <nav role="navigation" className="mm-navbar--simple">
            <div className="navbar navbar-expand-xl">
              <div className="container" id="mm-onboarding-logo">
                <a
                  href="/"
                  rel="noopener noreferer"
                  className="navbar-brand"
                  id="mm-onboarding-custom-logo"
                >
                  <img alt="MassMutual" src={logo} id="mm-logo" />
                </a>
                <div className="navbar-nav" style={{ flexDirection: "row" }}>
                  {/* <div className="nav-item">
                    <span className="username">
                      <UserName />
                    </span>
                  </div> */}
                  <div className="nav-item">
                    <a
                      style={{ color: "#ffffff", cursor: "pointer" }}
                      data-rb-event-key="logout"
                      className="logout nav-link"
                      role="button"
                      onClick={this.logout}
                    >
                      <span className="icon-logout-white"></span>{" "}
                      <span style={{ color: "white" }}>Log Out</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </>
      );
    }
  }
);
