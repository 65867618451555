import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FORM_ID } from "../../../../../../constants/Forms";
import CustomCard from "../../common/CustomCard";
import {
  initialState,
  regulatoryActionSECorCFTC,
  regulatoryActionOtherRegulators,
  regulatoryActionSRO,
  professionalSuspension,
  formalPendingActionInvestigation,
} from "../state";
import BackButtonModal from "../../../../Forms/BackButtonModal";
import Toast from "../../common/Toast";
import {
  INTRO_REV,
  INTRO_TEXT,
  KEYS,
  RADIO,
  SECTION,
  TOAST_MESSAGE,
} from "../../constants";
import { ccbActions, logoutActions } from "../../../../../../store/actions";
import { loadingStatus } from "../../../../../../store/state";
import {
  getSpecificObjByKeys,
  getValues,
  isChecked,
  isEachChecked,
  isSomeChecked,
} from "../../helpers";
import {
  fetchCCB,
  postCCB,
  putCCB,
  putCCBStatus,
} from "../../../../../../store/middlewares";
import "./RegulatoryActionForm.scss";
import CustomSubCategoryCard from "./CustomSubCategoryCard";
import { useClickOutside } from "../../hooks/useClickOutside";
import { headerText } from "../constants";
import NavigationButtons from "../../../../../SharedComponents/NavigationButtons";
import {
  cleanupCCB14Drp,
  getCCB14DRP,
} from "../../../../../../api/CCB/DRP/regulatoryDRP";
import { DRP_TYPES } from "../../../../../../api/helpers/constants";
import DeleteModal from "../../common/DeleteModal";
import { filterByUnchecked } from "../utils";

const RegulatoryActionForm = ({
  header,
  toggleForm,
  oktaSubId,
  updateCCBForm,
  setIsLoading,
  backFormId,
  nextFormId,
  updateCCB14CompletedInd,
  updateCCB14Step,
  isLoading: isLoadingProp,
  uncheckedSecCFTC,
  uncheckedActReg,
  uncheckedActSRO,
  uncheckedProSusp,
  uncheckedRegFormActInv,
  setIsDataRefetch,
}) => {
  const dispatch = useDispatch();

  const toastRef = useRef(null);
  const containerRef = useRef(null);

  const { error, loading, isFirstLoad, data } = useSelector(
    (state) => state.ccb14
  );

  const [isPageChanged, setIsPageChanged] = useState(false);

  const isLoading = loading === loadingStatus.PENDING || isLoadingProp;
  let isError = error || loading === loadingStatus.FAILED;

  const initializeSectionStates = {
    [SECTION.regulatoryActionSECorCFTC]: false,
    [SECTION.regulatoryActionOtherRegulators]: false,
    [SECTION.regulatoryActionSRO]: false,
    [SECTION.professionalSuspension]: false,
    [SECTION.formalPendingActionInvestigation]: false,
  };

  const [isShowDRPCleanupModal, setShowDRPCleanupModal] = useState(false);
  const [selectedSection, setSelectedSection] = useState(null);
  const [drpCleanupList, setDrpCleanupList] = useState(null);

  const [isRequired, setIsRequired] = useState(false);
  const [isCardOpen, setIsCardOpen] = useState({
    ...initializeSectionStates,
  });
  const [showBackModal, setShowBackModal] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const [isEdit, setIsEdit] = useState({
    ...initializeSectionStates,
  });

  const [isSaved, setIsSaved] = useState({
    ...initializeSectionStates,
  });

  const valuesA = useMemo(
    () => getValues(data, KEYS.regulatoryActionSECorCFTC),
    [data]
  );
  const valuesB = useMemo(
    () => getValues(data, KEYS.regulatoryActionOtherRegulators),
    [data]
  );
  const valuesC = useMemo(
    () => getValues(data, KEYS.regulatoryActionSRO),
    [data]
  );
  const valuesD = useMemo(
    () => getValues(data, KEYS.professionalSuspension),
    [data]
  );
  const valuesE = useMemo(
    () => getValues(data, KEYS.formalPendingActionInvestigation),
    [data]
  );

  const isSaveDisabledA = isEachChecked(valuesA);
  const isSaveDisabledB = isEachChecked(valuesB);
  const isSaveDisabledC = isEachChecked(valuesC);
  const isSaveDisabledD = isEachChecked(valuesD);
  const isSaveDisabledE = isEachChecked(valuesE);

  const submitData = useMemo(() => {
    return {
      [SECTION.regulatoryActionSECorCFTC]: getSpecificObjByKeys(
        data,
        KEYS.regulatoryActionSECorCFTC
      ),
      [SECTION.regulatoryActionOtherRegulators]: getSpecificObjByKeys(
        data,
        KEYS.regulatoryActionOtherRegulators
      ),
      [SECTION.regulatoryActionSRO]: getSpecificObjByKeys(
        data,
        KEYS.regulatoryActionSRO
      ),
      [SECTION.professionalSuspension]: getSpecificObjByKeys(
        data,
        KEYS.professionalSuspension
      ),
      [SECTION.formalPendingActionInvestigation]: getSpecificObjByKeys(
        data,
        KEYS.formalPendingActionInvestigation
      ),
    };
  }, [data]);

  const [formHasChanged, setFormHasChanged] = useState(false);

  useEffect(() => {
    if (formHasChanged) {
      dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: true }));
    }
  }, [formHasChanged, dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  useEffect(() => {
    try {
      const initData = async () => {
        const { payload } = await dispatch(fetchCCB(oktaSubId));

        if (payload) {
          const valuesA = getValues(payload, KEYS.regulatoryActionSECorCFTC);
          const valuesB = getValues(
            payload,
            KEYS.regulatoryActionOtherRegulators
          );
          const valuesC = getValues(payload, KEYS.regulatoryActionSRO);
          const valuesD = getValues(payload, KEYS.professionalSuspension);
          const valuesE = getValues(
            payload,
            KEYS.formalPendingActionInvestigation
          );

          const a = valuesA.some(isChecked);
          const b = valuesB.some(isChecked);
          const c = valuesC.some(isChecked);
          const d = valuesD.some(isChecked);
          const e = valuesE.some(isChecked);

          setIsSaved({
            [SECTION.regulatoryActionSECorCFTC]: a,
            [SECTION.regulatoryActionOtherRegulators]: b,
            [SECTION.regulatoryActionSRO]: c,
            [SECTION.professionalSuspension]: d,
            [SECTION.formalPendingActionInvestigation]: e,
          });

          setIsEdit({
            [SECTION.regulatoryActionSECorCFTC]: !a,
            [SECTION.regulatoryActionOtherRegulators]: !b,
            [SECTION.regulatoryActionSRO]: !c,
            [SECTION.professionalSuspension]: !d,
            [SECTION.formalPendingActionInvestigation]: !e,
          });
        }
      };

      if (loading !== loadingStatus.SUCCEDED) {
        initData();
      } else {
        const a = valuesA.some(isChecked);
        const b = valuesB.some(isChecked);
        const c = valuesC.some(isChecked);
        const d = valuesD.some(isChecked);
        const e = valuesE.some(isChecked);

        setIsSaved({
          [SECTION.regulatoryActionSECorCFTC]: a,
          [SECTION.regulatoryActionOtherRegulators]: b,
          [SECTION.regulatoryActionSRO]: c,
          [SECTION.professionalSuspension]: d,
          [SECTION.formalPendingActionInvestigation]: e,
        });

        setIsEdit({
          [SECTION.regulatoryActionSECorCFTC]: !a,
          [SECTION.regulatoryActionOtherRegulators]: !b,
          [SECTION.regulatoryActionSRO]: !c,
          [SECTION.professionalSuspension]: !d,
          [SECTION.formalPendingActionInvestigation]: !e,
        });

        const optionsArr = [a, b, c, d, e];
        const sectionStartNr = SECTION.regulatoryActionSECorCFTC;

        const firstIncompleteSection =
          optionsArr.findIndex((data) => !data) > -1
            ? optionsArr.findIndex((data) => !data) + sectionStartNr
            : null;

        if (firstIncompleteSection !== null) {
          setIsCardOpen({
            ...isCardOpen,
            [firstIncompleteSection]: true,
          });
        }
      }
    } catch (err) {
      console.error({ err });
    }

    return () => {
      setIsSaved({
        [SECTION.regulatoryActionSECorCFTC]: valuesA.some(isChecked),
        [SECTION.regulatoryActionOtherRegulators]: valuesB.some(isChecked),
        [SECTION.regulatoryActionSRO]: valuesC.some(isChecked),
        [SECTION.professionalSuspension]: valuesD.some(isChecked),
        [SECTION.formalPendingActionInvestigation]: valuesE.some(isChecked),
      });

      setIsEdit({
        [SECTION.regulatoryActionSECorCFTC]: !valuesA.some(isChecked),
        [SECTION.regulatoryActionOtherRegulators]: !valuesB.some(isChecked),
        [SECTION.regulatoryActionSRO]: !valuesC.some(isChecked),
        [SECTION.professionalSuspension]: !valuesD.some(isChecked),
        [SECTION.formalPendingActionInvestigation]: !valuesE.some(isChecked),
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPageChanged]);

  const handleChangeChecked = (e) => {
    const { name, value } = e.target;
    let newValue = value;

    if (
      isSaved[SECTION.regulatoryActionSECorCFTC] &&
      isSaved[SECTION.regulatoryActionOtherRegulators] &&
      isSaved[SECTION.regulatoryActionSRO] &&
      isSaved[SECTION.professionalSuspension] &&
      isSaved[SECTION.formalPendingActionInvestigation]
    ) {
      updateCCB14Step({ regActionDisclosureCompleteStep: "1/2" });
      updateCCB14CompletedInd({ regActionDisclosureCompleteInd: false });
    }

    setFormHasChanged(true);

    switch (value) {
      case RADIO.yes:
        newValue = true;
        break;
      case RADIO.no:
        newValue = false;
        break;
      default:
        newValue = null;
        break;
    }

    dispatch(ccbActions.setChecked({ [name]: newValue }));
  };

  const handleOnEditClick = (section) => {
    setIsEdit((prevState) => ({ ...prevState, [section]: true }));
  };

  const updateStatus = async (data) => {
    try {
      await dispatch(
        putCCBStatus({ oktaSubId, data: { regulatorystatus: data } })
      );
    } catch (err) {
      console.log(err);
    }
  };

  const saveHandler = async (section) => {
    const a = valuesA.some(isChecked);
    const b = valuesB.some(isChecked);
    const c = valuesC.some(isChecked);
    const d = valuesD.some(isChecked);
    const e = valuesE.some(isChecked);

    const optionsArr = [a, b, c, d, e];
    const sectionStartNr = SECTION.regulatoryActionSECorCFTC;

    const firstIncompleteSection =
      optionsArr.findIndex((data) => !data) > -1
        ? optionsArr.findIndex((data) => !data) + sectionStartNr
        : null;

    if (firstIncompleteSection !== null) {
      setIsCardOpen({
        ...isCardOpen,
        [firstIncompleteSection]: true,
      });
    }

    setFormHasChanged(false);

    setIsCardOpen((prevState) => ({ ...prevState, [section]: false }));

    if (isFirstLoad) {
      try {
        await dispatch(postCCB({ oktaSubId, data: submitData[section] }));
      } catch (err) {
        console.log(err);
        isError = `${err}`;
      }

      setIsSaved((prevState) => ({ ...prevState, [section]: true }));
    } else {
      try {
        await dispatch(putCCB({ oktaSubId, data: submitData[section] }));
      } catch (err) {
        console.log(err);
        isError = `${err}`;
      }

      setIsSaved((prevState) => ({ ...prevState, [section]: true }));
    }

    const isNo = (currentValue) => currentValue === false;
    const isYes = (currentValue) => currentValue === true;
    const isNoValues =
      valuesA.every(isNo) &&
      valuesB.every(isNo) &&
      valuesC.every(isNo) &&
      valuesD.every(isNo) &&
      valuesE.every(isNo);
    const isYesValues =
      valuesA.some(isYes) ||
      valuesB.some(isYes) ||
      valuesC.some(isYes) ||
      valuesD.some(isYes) ||
      valuesE.some(isYes);

    if (isNoValues) {
      updateCCB14CompletedInd({ regActionDisclosureCompleteInd: true });
      updateStatus(null);
    } else if (isYesValues) {
      updateCCB14Step({ regActionDisclosureCompleteStep: "1/2" });
      updateCCB14CompletedInd({ regActionDisclosureCompleteInd: false });
    }

    setIsEdit((prevState) => ({ ...prevState, [section]: false }));
    setShowToast(true);
  };

  const handleOnSaveClick = async (section) => {
    handleCloseToast();
    setSelectedSection(section);

    const { drpvalues } = await getCCB14DRP({
      oktaSubId,
      drpTypeId: DRP_TYPES.Regulatory,
    });

    let uncheckedList = null;

    switch (section) {
      case SECTION.regulatoryActionSECorCFTC:
        uncheckedList = uncheckedSecCFTC;
        break;
      case SECTION.regulatoryActionOtherRegulators:
        uncheckedList = uncheckedActReg;
        break;
      case SECTION.regulatoryActionSRO:
        uncheckedList = uncheckedActSRO;
        break;
      case SECTION.professionalSuspension:
        uncheckedList = uncheckedProSusp;
        break;
      case SECTION.formalPendingActionInvestigation:
        uncheckedList = uncheckedRegFormActInv;
        break;

      default:
        break;
    }

    if (uncheckedList === null) {
      return;
    }

    const drpList = filterByUnchecked(section, uncheckedList, drpvalues);

    if (drpList.length) {
      setDrpCleanupList(drpList);
      setShowDRPCleanupModal(true);
    } else {
      saveHandler(section);
    }

    window.scrollTo(0, 0);
  };

  const handleCloseToast = () => setShowToast(false);

  const closeCleanupModalHandler = async () => {
    setShowDRPCleanupModal(false);
  };

  const onCleanupModalHandler = async () => {
    if (selectedSection === null) {
      return;
    }

    setIsLoading(true);

    await cleanupCCB14Drp({
      oktaSubId,
      drpTypeId: DRP_TYPES.Regulatory,
      data: {
        data: {
          secCFTC: uncheckedSecCFTC,
          actReg: uncheckedActReg,
          actSRO: uncheckedActSRO,
          proSusp: uncheckedProSusp,
          regFormActInv: uncheckedRegFormActInv || [],
        },
      },
    });

    setIsLoading(false);

    await saveHandler(selectedSection);

    setIsDataRefetch(true);

    setShowDRPCleanupModal(false);
  };

  useEffect(() => {
    let handler = (e) => {
      if (!toastRef?.current?.contains(e.target)) {
        handleCloseToast();
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const cleanup = (isFetch = false) => {
    dispatch(ccbActions.setChecked({ ...initialState }));
    setIsRequired(false);
    handleCloseToast();
    setFormHasChanged(false);

    setIsCardOpen({
      ...initializeSectionStates,
    });
    setIsEdit({
      ...initializeSectionStates,
    });

    if (!isFetch) {
      setIsPageChanged((prev) => !prev);
    }
  };

  const handleCloseBackModal = () => setShowBackModal(false);

  const handleShowBackModal = () => {
    if (
      ((isSomeChecked(valuesA) && isEdit[SECTION.regulatoryActionSECorCFTC]) ||
        (isSomeChecked(valuesB) &&
          isEdit[SECTION.regulatoryActionOtherRegulators]) ||
        (isSomeChecked(valuesC) && isEdit[SECTION.regulatoryActionSRO]) ||
        (isSomeChecked(valuesD) && isEdit[SECTION.professionalSuspension]) ||
        (isSomeChecked(valuesE) &&
          isEdit[SECTION.formalPendingActionInvestigation]) ||
        (isSomeChecked(valuesA) &&
          !isSaved[SECTION.regulatoryActionSECorCFTC]) ||
        (isSomeChecked(valuesB) &&
          !isSaved[SECTION.regulatoryActionOtherRegulators]) ||
        (isSomeChecked(valuesC) && !isSaved[SECTION.regulatoryActionSRO]) ||
        (isSomeChecked(valuesD) && !isSaved[SECTION.professionalSuspension]) ||
        (isSomeChecked(valuesE) &&
          !isSaved[SECTION.formalPendingActionInvestigation])) &&
      formHasChanged
    ) {
      setShowBackModal(true);
    } else {
      dispatch(ccbActions.setIsBack(true));
      toggleForm(backFormId);
      setIsPageChanged((prev) => !prev);
    }
  };

  const handleBackClick = async () => {
    cleanup();
    toggleForm(backFormId);

    if (
      isSaved[SECTION.regulatoryActionSECorCFTC] &&
      isSaved[SECTION.regulatoryActionOtherRegulators] &&
      isSaved[SECTION.regulatoryActionSRO] &&
      isSaved[SECTION.professionalSuspension] &&
      isSaved[SECTION.formalPendingActionInvestigation]
    ) {
      updateCCB14CompletedInd({ regActionDisclosureCompleteInd: true });
    }

    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: false }));
    dispatch(ccbActions.setIsBack(true));
    handleCloseBackModal();
  };

  const handleNext = async () => {
    cleanup(true);
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: false }));
    dispatch(ccbActions.setIsBack(false));
    const isYes = (currentValue) => currentValue === true;

    let isYesValues =
      valuesA.some(isYes) ||
      valuesB.some(isYes) ||
      valuesC.some(isYes) ||
      valuesD.some(isYes) ||
      valuesE.some(isYes);

    if (loading === loadingStatus.IDLE || loading === loadingStatus.SUCCEDED) {
      const { payload } = await dispatch(fetchCCB(oktaSubId));

      isYesValues =
        getValues(payload, KEYS.regulatoryActionSECorCFTC).some(isYes) ||
        getValues(payload, KEYS.regulatoryActionOtherRegulators).some(isYes) ||
        getValues(payload, KEYS.regulatoryActionSRO).some(isYes) ||
        getValues(payload, KEYS.professionalSuspension).some(isYes) ||
        getValues(payload, KEYS.formalPendingActionInvestigation).some(isYes);
    }

    if (isYesValues) {
      updateCCBForm(nextFormId);
    } else {
      updateCCB14CompletedInd({ regActionDisclosureCompleteInd: true });
      updateStatus(null);
      toggleForm(FORM_ID.CIVIL_JUDICIAL_DISCLOSURE);
    }
  };

  const renderSubcategory = (category, section) => {
    const isRegulatoryActionSECorCFTCDisabled =
      isSaved[SECTION.regulatoryActionSECorCFTC] &&
      !isEdit[SECTION.regulatoryActionSECorCFTC] &&
      section === SECTION.regulatoryActionSECorCFTC;

    const isRegulatoryActionOtherRegulatorsDisabled =
      isSaved[SECTION.regulatoryActionOtherRegulators] &&
      !isEdit[SECTION.regulatoryActionOtherRegulators] &&
      section === SECTION.regulatoryActionOtherRegulators;

    const isRegulatoryActionSRODisabled =
      isSaved[SECTION.regulatoryActionSRO] &&
      !isEdit[SECTION.regulatoryActionSRO] &&
      section === SECTION.regulatoryActionSRO;

    const isProfessionalSuspensionDisabled =
      isSaved[SECTION.professionalSuspension] &&
      !isEdit[SECTION.professionalSuspension] &&
      section === SECTION.professionalSuspension;

    const isFormalPendingActionInvestigationDisabled =
      isSaved[SECTION.formalPendingActionInvestigation] &&
      !isEdit[SECTION.formalPendingActionInvestigation] &&
      section === SECTION.formalPendingActionInvestigation;

    const disabledSave =
      isRegulatoryActionSECorCFTCDisabled ||
      isRegulatoryActionOtherRegulatorsDisabled ||
      isRegulatoryActionSRODisabled ||
      isProfessionalSuspensionDisabled ||
      isFormalPendingActionInvestigationDisabled ||
      (!isSaveDisabledA && section === SECTION.regulatoryActionSECorCFTC) ||
      (!isSaveDisabledB &&
        section === SECTION.regulatoryActionOtherRegulators) ||
      (!isSaveDisabledC && section === SECTION.regulatoryActionSRO) ||
      (!isSaveDisabledD && section === SECTION.professionalSuspension) ||
      (!isSaveDisabledE &&
        section === SECTION.formalPendingActionInvestigation);

    return (
      <CustomSubCategoryCard
        data={data}
        category={category}
        handleOnEditClick={handleOnEditClick}
        handleChangeChecked={handleChangeChecked}
        handleOnSaveClick={handleOnSaveClick}
        section={section}
        isSaved={isSaved}
        isLoading={isLoading}
        isRequired={isRequired}
        disabledSave={disabledSave}
        ref={containerRef}
      />
    );
  };

  useClickOutside(toastRef, containerRef, showToast, handleCloseToast);

  return (
    <div
      className="regulatory-action-disclosure-form-wrapper"
      ref={containerRef}
    >
      <Toast
        showToast={showToast}
        closeToast={handleCloseToast}
        text={TOAST_MESSAGE}
        ref={toastRef}
      />

      <div className="row">
        <div className="col-md-12">
          <div className="eyebrow">
            <strong id="reg-action-eyebrow">
              Character, Credit, and Bonding
            </strong>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <h1 id="reg-action-header">{header}</h1>
          <h2 id="ccb_intro_rev_reg_action" style={{ color: "black" }}>
            {INTRO_REV}
          </h2>
        </div>
      </div>

      <div className="row" ref={containerRef}>
        <div className="col-md-12">
          <p id="reg-action-intro-text">{INTRO_TEXT}</p>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <CustomCard
            isOpen={isCardOpen[SECTION.regulatoryActionSECorCFTC]}
            setIsOpen={setIsCardOpen}
            headerText={headerText.REG_SEC_CFTC}
            isCompleted={isSaved[SECTION.regulatoryActionSECorCFTC]}
            index={SECTION.regulatoryActionSECorCFTC}
          >
            {renderSubcategory(
              regulatoryActionSECorCFTC(
                isSaved[SECTION.regulatoryActionSECorCFTC] &&
                  !isEdit[SECTION.regulatoryActionSECorCFTC]
              ),
              SECTION.regulatoryActionSECorCFTC
            )}
          </CustomCard>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <CustomCard
            isOpen={isCardOpen[SECTION.regulatoryActionOtherRegulators]}
            setIsOpen={setIsCardOpen}
            headerText={headerText.REG_ACT_REG}
            isCompleted={isSaved[SECTION.regulatoryActionOtherRegulators]}
            index={SECTION.regulatoryActionOtherRegulators}
          >
            {renderSubcategory(
              regulatoryActionOtherRegulators(
                isSaved[SECTION.regulatoryActionOtherRegulators] &&
                  !isEdit[SECTION.regulatoryActionOtherRegulators]
              ),
              SECTION.regulatoryActionOtherRegulators
            )}
          </CustomCard>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <CustomCard
            isOpen={isCardOpen[SECTION.regulatoryActionSRO]}
            setIsOpen={setIsCardOpen}
            headerText={headerText.REG_ACT_SRO}
            isCompleted={isSaved[SECTION.regulatoryActionSRO]}
            index={SECTION.regulatoryActionSRO}
          >
            {renderSubcategory(
              regulatoryActionSRO(
                isSaved[SECTION.regulatoryActionSRO] &&
                  !isEdit[SECTION.regulatoryActionSRO]
              ),
              SECTION.regulatoryActionSRO
            )}
          </CustomCard>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <CustomCard
            isOpen={isCardOpen[SECTION.professionalSuspension]}
            setIsOpen={setIsCardOpen}
            headerText={headerText.REG_PRO_SUSP}
            isCompleted={isSaved[SECTION.professionalSuspension]}
            index={SECTION.professionalSuspension}
          >
            {renderSubcategory(
              professionalSuspension(
                isSaved[SECTION.professionalSuspension] &&
                  !isEdit[SECTION.professionalSuspension]
              ),
              SECTION.professionalSuspension
            )}
          </CustomCard>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <CustomCard
            isOpen={isCardOpen[SECTION.formalPendingActionInvestigation]}
            setIsOpen={setIsCardOpen}
            headerText={headerText.REG_FORM_ACT_INV}
            isCompleted={isSaved[SECTION.formalPendingActionInvestigation]}
            index={SECTION.formalPendingActionInvestigation}
          >
            {renderSubcategory(
              formalPendingActionInvestigation(
                isSaved[SECTION.formalPendingActionInvestigation] &&
                  !isEdit[SECTION.formalPendingActionInvestigation]
              ),
              SECTION.formalPendingActionInvestigation
            )}
          </CustomCard>
        </div>
      </div>
      <NavigationButtons
        onBack={handleShowBackModal}
        onNext={handleNext}
        nxtbtnid={"regActDisHistoryNext"}
        bckbtnid={"regActDisBack"}
        isLoading={isLoading}
        isDisabled={
          !(
            isSaved[SECTION.regulatoryActionSECorCFTC] &&
            isSaved[SECTION.regulatoryActionOtherRegulators] &&
            isSaved[SECTION.regulatoryActionSRO] &&
            isSaved[SECTION.professionalSuspension] &&
            isSaved[SECTION.formalPendingActionInvestigation]
          ) ||
          !!isError ||
          !!isLoading
        }
      />

      {isShowDRPCleanupModal && (
        <DeleteModal
          isOpen={isShowDRPCleanupModal}
          showSpinner={false}
          isDRP
          type={headerText.HEADER}
          list={drpCleanupList}
          description="You had previously provided a DRP(s) associated with this
          question. Changing your response will delete the following
          DRP(s). Are you sure you want to proceed?"
          message="Are You Sure You Want To Proceed?"
          closeClick={closeCleanupModalHandler}
          deleteClick={onCleanupModalHandler}
        />
      )}

      <BackButtonModal
        isOpen={showBackModal}
        closeBackModal={handleCloseBackModal}
        backClick={handleBackClick}
      />
    </div>
  );
};

export default RegulatoryActionForm;
