import {
  TEALIUM_SITE,
  TEALIUM_USER_ROLE,
  CONTENT_TYPE,
} from "../constants/common-constants";

export class UtagTracking extends Object {
  /**
   * Method to register view utag event
   * @param te
   * @param category
   * @param pageType
   * @param pageName
   */

  static viewEvent = (pageType, pageName, userId, agencyid, contracttypeid) => {
    if (typeof utag !== "undefined") {
      // eslint-disable-next-line no-undef
      utag.view({
        content_type: CONTENT_TYPE,
        page_type: pageType,
        page_name: pageName,
        page_category: contracttypeid,
        site_id: TEALIUM_SITE,
        user_role: TEALIUM_USER_ROLE,
        _ccustid: userId,
        agency_id: agencyid,
      });
    }
  };

  /**
   * Method to register utag view event for status of page
   * @param pageType
   * @param pageStatus
   */
  static sendPageStatusView = (pageType, pageStatus, agencyid) => {
    const te = "orbit_" + pageType + "_page_view";
    UtagTracking.viewEvent(te, pageType, pageStatus);
  };

  /**
   * Method to register utag view for page
   * @param pageName
   */
  static sendPageNameView = (pageName, userId, agencyid, contracttypeid) => {
    const pageType = pageName.toLowerCase() + "_page";
    UtagTracking.viewEvent(
      pageType,
      pageName,
      userId.toString(),
      agencyid,
      contracttypeid.toString()
    );
  };

  /**
   * Method to register link event
   * @param name as string
   * @param category as string
   * @param label as string
   * @param property as string or object
   */
  static sendDetailLink = (name, category, label, userId) => {
    if (typeof utag !== "undefined") {
      // eslint-disable-next-line no-undef
      utag.link({
        event_name: name,
        event_category: category,
        event_label: label,
        event_action: "click",
        event_value: 1,
        _ccustid: userId,
      });
    }
  };
}
