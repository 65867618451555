/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "react-blueprint-library";
import InputMask from "react-input-mask";
import moment from "moment";

import { logoutActions } from "../../../../../../../store/actions";
import BackButtonModal from "../../../../../Forms/BackButtonModal";

import "./CivJudStatusDetails.scss";
import { dateInputMask, eventStatusDateType } from "../../../constants";

export const eventStatus = {
  PENDING: "Pending",
  APPEAL: "On Appeal",
  FINAL: "Final",
};

const CivJudStatusDetails = ({
  updateCCBForm,
  backFormId,
  nextFormId,
  getSubmitDataValues,
  setSkipResAndSanctDetails,
  skipFormId,
  data,
  groupKey,
  headerText,
}) => {
  const dispatch = useDispatch();

  const [showBackModal, setShowBackModal] = useState(false);
  const [formHasChanged, setFormHasChanged] = useState(false);

  const [status, setStatus] = useState(data?.status || "");

  const [pendingDetDes, setPendingDetDes] = useState(data?.pendingDetDes || "");
  const [pendingDetDesError, setPendingDetDesError] = useState(false);

  const [actionAppealed, setActionAppealed] = useState(
    data?.actionAppealed || ""
  );
  const [actionAppealedError, setActionAppealedError] = useState(false);

  const [locationCourt, setLocationCourt] = useState(data?.locationCourt || "");
  const [locationCourtError, setLocationCourtError] = useState(false);

  const [docketOrCase, setDocketOrCase] = useState(data?.docketOrCase || "");
  const [docketOrCaseError, setDocketOrCaseError] = useState(false);

  const [dateAppealFiled, setDateAppealFiled] = useState(
    data?.dateAppealFiled || ""
  );
  const [dateAppealFiledError, setDateAppealFiledError] = useState(false);
  const [dateAppealFiledErrorMsg, setDateAppealFiledErrorMsg] = useState("");
  const [dateAppealFiledRadio, setDateAppealFiledRadio] = useState(
    data?.dateAppealFiledRadio || ""
  );
  const [dateAppealFiledExp, setDateAppealFiledExp] = useState(
    data?.dateAppealFiledExp || ""
  );
  const [dateAppealFiledExpError, setDateAppealFiledExpError] = useState(false);

  const [appealDetDes, setAppealDetDes] = useState(data?.appealDetDes || "");
  const [appealDetDesError, setAppealDetDesError] = useState(false);

  const [appealLimitDes, setAppealLimitDes] = useState(
    data?.appealLimitDes || ""
  );
  const [appealLimitDesError, setAppealLimitDesError] = useState(false);

  const [isNotDateNextDisabled, setIsNotDateNextDisabled] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  useEffect(() => {
    if (data !== null && data && !Object.keys(data).length) {
      setFormHasChanged(true);
    }
  }, []);

  const isError =
    !!(pendingDetDes?.length && pendingDetDesError) ||
    actionAppealedError ||
    locationCourtError ||
    docketOrCaseError ||
    appealDetDesError ||
    appealLimitDesError ||
    dateAppealFiledError ||
    dateAppealFiledExpError;

  useEffect(() => {
    if (isError) {
      setFormHasChanged(true);
    }
  }, [isError]);

  const isDateFirstChargedDisabled =
    !dateAppealFiledRadio.length ||
    !dateAppealFiled.length ||
    dateAppealFiled.includes("_") ||
    dateAppealFiled === dateInputMask.EMPTY ||
    (!dateAppealFiled.length && !dateAppealFiledRadio.length) ||
    !dateAppealFiled.length ||
    (dateAppealFiled.length &&
      dateAppealFiledRadio.length &&
      dateAppealFiledRadio === eventStatusDateType.EXPLANATION &&
      !dateAppealFiledExp?.length &&
      dateAppealFiledExp?.length < 2) ||
    (dateAppealFiled.length &&
      dateAppealFiledRadio.length &&
      dateAppealFiledRadio === eventStatusDateType.EXPLANATION &&
      dateAppealFiledExp?.length &&
      dateAppealFiledExp?.length < 2);

  const isNextBtnDisabled =
    !!(
      isNotDateNextDisabled ||
      (status.length &&
        status !== eventStatus.FINAL &&
        status === eventStatus.PENDING &&
        pendingDetDes?.length &&
        pendingDetDes?.length < 1) ||
      (status.length &&
        status !== eventStatus.FINAL &&
        status === eventStatus.APPEAL &&
        (isDateFirstChargedDisabled ||
          (appealLimitDes?.length && appealLimitDes?.length < 1) ||
          !appealDetDes.length ||
          (appealDetDes?.length && appealDetDes?.length < 1) ||
          !docketOrCase?.length ||
          (docketOrCase?.length && docketOrCase?.length < 2) ||
          !locationCourt?.length ||
          (locationCourt?.length && locationCourt?.length < 2) ||
          !actionAppealed?.length ||
          (actionAppealed?.length && actionAppealed?.length < 1)))
    ) ||
    isError ||
    !status.length;

  const handleChangeChecked = (e) => {
    const { value } = e.target;
    let newValue = value;

    setFormHasChanged(true);

    cleanupHandler();

    setStatus(newValue);
  };

  const handleCloseBackModal = () => setShowBackModal(false);

  const isUpdates = formHasChanged || isError;

  useEffect(() => {
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: true }));
  }, [isUpdates, dispatch]);

  const cleanupHandler = () => {
    setPendingDetDes("");
    setPendingDetDesError(false);
    setActionAppealed("");
    setActionAppealedError(false);
    setLocationCourt("");
    setLocationCourtError(false);
    setDocketOrCase("");
    setDocketOrCaseError(false);
    setDateAppealFiled("");
    setDateAppealFiledError(false);
    setDateAppealFiledErrorMsg("");
    setDateAppealFiledRadio("");
    setDateAppealFiledExp("");
    setDateAppealFiledExpError(false);
    setAppealDetDes("");
    setAppealDetDesError(false);
    setAppealLimitDes("");
    setAppealLimitDesError(false);
  };

  const onChangeActionAppealedHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setActionAppealed(value);
  };

  const onBlurActionAppealedHandler = ({ target: { value } }) => {
    return value.trim().length < 1 || value.trim().length > 500
      ? setActionAppealedError(true)
      : setActionAppealedError(false);
  };

  const onChangeLocationCourtHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setLocationCourt(value);
  };

  const onBlurLocationCourtHandler = ({ target: { value } }) => {
    return value.trim().length < 2 || value.trim().length > 100
      ? setLocationCourtError(true)
      : setLocationCourtError(false);
  };

  const onChangeDocketOrCaseHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setDocketOrCase(value);
  };

  const onBlurDocketOrCaseHandler = ({ target: { value } }) => {
    return value.trim().length < 2 || value.trim().length > 100
      ? setDocketOrCaseError(true)
      : setDocketOrCaseError(false);
  };

  const onPendingDetDesChangeHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setPendingDetDes(value);
  };
  const onBlurPendingDetDesHandler = ({ target: { value } }) => {
    return value.trim().length >= 1 &&
      (value.trim().length < 1 || value.trim().length > 200)
      ? setPendingDetDesError(true)
      : setPendingDetDesError(false);
  };

  const dateValidationCheck = (value) => {
    const today = moment();
    const date = moment(value, "MM/DD/YYYY", true);
    const past = moment(moment().subtract(100, "years").format("MM/DD/YYYY"));

    setIsNotDateNextDisabled(false);

    if (value !== "" && !value.includes("_")) {
      if (moment(value) > today) {
        setDateAppealFiledError(true);
      } else {
        setDateAppealFiledError(false);
      }
    } else {
      setDateAppealFiledError(false);
    }

    if (
      date._i.length === 10 &&
      !date._i.includes("_") &&
      date.isValid() &&
      !value.includes("0000")
    ) {
      if (date > today) {
        setDateAppealFiledErrorMsg("Date cannot be a future date");
        setDateAppealFiledError(true);
      } else if (date < past) {
        setDateAppealFiledErrorMsg("Date cannot be more than 100 years ago");
        setDateAppealFiledError(true);
      } else {
        setDateAppealFiledError(false);
      }
    } else {
      setDateAppealFiledErrorMsg("Please enter a valid date");
      setDateAppealFiledError(true);
    }
  };

  const handleChangeDateFirstChargedChecked = (e) => {
    const { value } = e.target;
    let newValue = value;

    setFormHasChanged(true);

    if (
      dateAppealFiledExp.length &&
      dateAppealFiledRadio !== eventStatusDateType.EXPLANATION
    ) {
      setDateAppealFiledExp("");
    }

    setDateAppealFiledExpError(false);
    setDateAppealFiledRadio(newValue);
  };

  const onDateFirstChargedChangeHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setDateAppealFiled(value);

    if (value?.length === 10 && !value.includes("_") && !dateAppealFiledError) {
      const today = moment();
      const date = moment(value, "MM/DD/YYYY", true);
      const past = moment(moment().subtract(100, "years").format("MM/DD/YYYY"));

      if (value !== "" && !value.includes("_")) {
        if (moment(value) > today) {
          setIsNotDateNextDisabled(true);
        } else {
          setIsNotDateNextDisabled(false);
        }
      } else {
        setIsNotDateNextDisabled(false);
      }

      if (
        date._i.length === 10 &&
        !date._i.includes("_") &&
        date.isValid() &&
        !value.includes("0000")
      ) {
        if (date > today || date < past) {
          setIsNotDateNextDisabled(true);
        } else {
          setIsNotDateNextDisabled(false);
        }
      } else {
        setIsNotDateNextDisabled(true);
      }
    }
  };

  const onDateFirstChargedExplanationChange = ({ target: { value } }) => {
    setFormHasChanged(true);
    setDateAppealFiledExp(value);
  };

  const onBlurDateFirstChargedExpHandler = ({ target: { value } }) => {
    return value.trim().length < 2 || value.trim().length > 500
      ? setDateAppealFiledExpError(true)
      : setDateAppealFiledExpError(false);
  };

  const onDateFirstChargedBlurHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    dateValidationCheck(value);
  };

  const onAppealDetDesChangeHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setAppealDetDes(value);
  };
  const onBlurAppealDetDesHandler = ({ target: { value } }) => {
    return value.trim().length < 1 || value.trim().length > 200
      ? setAppealDetDesError(true)
      : setAppealDetDesError(false);
  };

  const onAppealLimitDesChangeHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setAppealLimitDes(value);
  };
  const onBlurAppealLimitDesHandler = ({ target: { value } }) => {
    return value.trim().length >= 1 &&
      (value.trim().length < 1 || value.trim().length > 200)
      ? setAppealLimitDesError(true)
      : setAppealLimitDesError(false);
  };

  const handleShowBackModal = () => {
    if (formHasChanged) {
      setShowBackModal(true);
    } else {
      if (
        (data !== null && data && !Object.keys(data).length) ||
        data === null
      ) {
        getSubmitDataValues({ [groupKey]: null }, headerText);
      }
      updateCCBForm(backFormId);
    }
  };

  const handleBackClick = () => {
    if ((data !== null && data && !Object.keys(data).length) || data === null) {
      getSubmitDataValues({ [groupKey]: null }, headerText);
    }
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: false }));
    updateCCBForm(backFormId);
    handleCloseBackModal();
  };

  const handleNext = useCallback(() => {
    const submitData = {
      status,
      pendingDetDes,
      actionAppealed,
      locationCourt,
      docketOrCase,
      dateAppealFiled,
      dateAppealFiledRadio,
      dateAppealFiledExp,
      appealDetDes,
      appealLimitDes,
    };

    const isPendingStatus = status === eventStatus.PENDING;

    setSkipResAndSanctDetails(isPendingStatus);

    setFormHasChanged(false);

    let clearData = {};
    let clearNextFormsData = {};

    const initStateAppeal = {
      actionAppealed: "",
      locationCourt: "",
      docketOrCase: "",
      dateAppealFiled: "",
      dateAppealFiledRadio: "",
      dateAppealFiledExp: "",
      appealDetDes: "",
      appealLimitDes: "",
    };

    if (isPendingStatus) {
      clearData = {
        ...initStateAppeal,
      };

      clearNextFormsData = {
        resDetails: null,
        sanctionDetails: null,
      };
    }

    if (status === eventStatus.FINAL) {
      clearData = {
        ...initStateAppeal,
        pendingDetDes: "",
      };
    }

    if (status === eventStatus.APPEAL) {
      clearData = {
        pendingDetDes: "",
      };
    }

    getSubmitDataValues(
      {
        [`${groupKey}`]: { ...submitData, ...clearData },
        ...clearNextFormsData,
      },
      headerText
    );

    updateCCBForm(isPendingStatus ? skipFormId : nextFormId);
  }, [
    updateCCBForm,
    nextFormId,
    getSubmitDataValues,
    appealDetDes,
    headerText,
    pendingDetDes,
    status,
    actionAppealed,
    locationCourt,
    docketOrCase,
    dateAppealFiled,
    dateAppealFiledRadio,
    dateAppealFiledExp,
    groupKey,
    appealLimitDes,
    setSkipResAndSanctDetails,
    skipFormId,
  ]);

  return (
    <div className="civ-jud-status-det-form-wrapper">
      <div className="row">
        <div className="col-md-12">
          <label id="eventStatusLabelId" className="event-status">
            Current status: *
          </label>
        </div>
      </div>

      <div className="row">
        <div className="drp-radio-input-wrapper">
          <div className="col-12 copy-md-4 form-group">
            <div className="custom-control custom-radio">
              <input
                name="pendingStatus"
                type="radio"
                id="pending"
                className="custom-control-input"
                value={eventStatus.PENDING}
                checked={status === eventStatus.PENDING}
                onChange={handleChangeChecked}
              />
              <label
                id="federalLabelId"
                htmlFor="pending"
                className="custom-control-label"
              >
                {eventStatus.PENDING}
              </label>
            </div>

            <div className="custom-control custom-radio">
              <input
                name="appealStatus"
                type="radio"
                id="appealStatusId"
                className="custom-control-input"
                value={eventStatus.APPEAL}
                checked={status === eventStatus.APPEAL}
                onChange={handleChangeChecked}
              />
              <label
                htmlFor="appealStatusId"
                id="appealStatusLabelId"
                className="custom-control-label"
              >
                {eventStatus.APPEAL}
              </label>
            </div>

            <div className="custom-control custom-radio">
              <input
                name="finalStatus"
                type="radio"
                id="finalStatusId"
                className="custom-control-input"
                value={eventStatus.FINAL}
                checked={status === eventStatus.FINAL}
                onChange={handleChangeChecked}
              />
              <label
                htmlFor="finalStatusId"
                id="finalStatusLabelId"
                className="custom-control-label"
              >
                {eventStatus.FINAL}
              </label>
            </div>
          </div>
        </div>
      </div>

      {status === eventStatus.PENDING && (
        <>
          <div className="crim-comments-container">
            <label
              htmlFor="pendingDetDes"
              className="comments-label"
              id="pendingDetDesLabelId"
            >
              If pending and any limitations or restrictions are currently in
              effect, provide details:
            </label>
            <textarea
              id="pendingDetDes"
              onChange={onPendingDetDesChangeHandler}
              onBlur={onBlurPendingDetDesHandler}
              className={
                pendingDetDesError
                  ? "form-control form-control-15 is-invalid"
                  : "form-control form-control-15"
              }
              minLength={1}
              maxLength={200}
              rows="3"
              cols={500}
              value={pendingDetDes}
            />
            <div id="pendingDetDesError" className="invalid-feedback">
              Please enter a response
            </div>
          </div>
        </>
      )}

      {status === eventStatus.APPEAL && (
        <>
          <>
            <div className="sentence-hr">
              <hr />
              <div className="eyebrow-text-wrapper">
                <strong className="eyebrow">Appeal details</strong>
              </div>
              <hr />
            </div>

            <div className="row proc-name">
              <div className="col-md-6">
                <label
                  className="org-name"
                  htmlFor="docketCaseIdAppealed"
                  id="docketCaseIdAppealedLabel"
                >
                  Action appealed to (provide name of court): *
                </label>
                <input
                  type="text"
                  id="docketCaseIdAppealed"
                  className={
                    actionAppealedError
                      ? "form-control form-control-15 is-invalid"
                      : "form-control form-control-15"
                  }
                  minLength={1}
                  maxLength={500}
                  autoComplete="off"
                  value={actionAppealed}
                  onChange={onChangeActionAppealedHandler}
                  onBlur={onBlurActionAppealedHandler}
                />
                <div id="actionAppealedError" className="invalid-feedback">
                  Please enter a response
                </div>
              </div>
            </div>

            <div className="row proc-name">
              <div className="col-md-6">
                <label
                  className="org-name"
                  htmlFor="courtLocationId"
                  id="courtLocationLabelId"
                >
                  Court Location: *
                </label>
                <input
                  type="text"
                  id="courtLocationId"
                  className={
                    locationCourtError
                      ? "form-control form-control-15 is-invalid"
                      : "form-control form-control-15"
                  }
                  minLength={2}
                  maxLength={100}
                  autoComplete="off"
                  value={locationCourt}
                  onChange={onChangeLocationCourtHandler}
                  onBlur={onBlurLocationCourtHandler}
                />
                <div id="locationCourtError" className="invalid-feedback">
                  Please enter Location of court
                </div>
              </div>
            </div>

            <div className="row proc-name">
              <div className="col-md-6">
                <label
                  className="org-name"
                  htmlFor="docketCaseId"
                  id="docketCaseLabelId"
                >
                  Docket/Case #: *
                </label>
                <input
                  type="text"
                  id="docketCaseId"
                  className={
                    docketOrCaseError
                      ? "form-control form-control-15 is-invalid"
                      : "form-control form-control-15"
                  }
                  minLength={2}
                  maxLength={100}
                  autoComplete="off"
                  value={docketOrCase}
                  onChange={onChangeDocketOrCaseHandler}
                  onBlur={onBlurDocketOrCaseHandler}
                />
                <div id="docketOrCaseError" className="invalid-feedback">
                  Please enter Docket/Case #
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 event-status-date-wrapper">
                <label
                  id="dateAppealFilledLabelId"
                  htmlFor="dateAppealFilledId"
                >
                  <strong id="regDateAppealFiled">Date appeal filed: *</strong>
                </label>
                <InputMask
                  id="dateAppealFilledId"
                  mask="99/99/9999"
                  maskChar="_"
                  className={`${
                    dateAppealFiledError
                      ? "form-control is-invalid"
                      : "form-control"
                  } event-status-date-input`}
                  placeholder="MM/DD/YYYY"
                  autoComplete="off"
                  required
                  value={dateAppealFiled}
                  onChange={onDateFirstChargedChangeHandler}
                  onBlur={onDateFirstChargedBlurHandler}
                />
                <div id="dateAppealFiledError" className="invalid-feedback">
                  {dateAppealFiledErrorMsg?.length
                    ? dateAppealFiledErrorMsg
                    : "Please enter an Explanation"}
                </div>
              </div>
              <div className="row event-status-date-radio-group">
                <div className="custom-control">
                  <div className="col-12 copy-md-8 form-group">
                    <div className="custom-control custom-radio">
                      <input
                        name="radio-guide-dateFirstChargedExactId"
                        type="radio"
                        id="dateFirstChargedExactId"
                        className="custom-control-input"
                        value={eventStatusDateType.EXACT}
                        checked={
                          dateAppealFiledRadio === eventStatusDateType.EXACT
                        }
                        onChange={handleChangeDateFirstChargedChecked}
                      />
                      <label
                        htmlFor="dateFirstChargedExactId"
                        className="custom-control-label"
                        id="label_exactDate"
                      >
                        {eventStatusDateType.EXACT}
                      </label>
                    </div>
                    <div className="custom-control custom-radio">
                      <input
                        name="radio-guide-dateFirstChargedExplanationId"
                        type="radio"
                        id="dateFirstChargedExplanationId"
                        className="custom-control-input"
                        value={eventStatusDateType.EXPLANATION}
                        checked={
                          dateAppealFiledRadio ===
                          eventStatusDateType.EXPLANATION
                        }
                        onChange={handleChangeDateFirstChargedChecked}
                      />
                      <label
                        htmlFor="dateFirstChargedExplanationId"
                        className="custom-control-label"
                        id="label_explanation"
                      >
                        {eventStatusDateType.EXPLANATION}
                      </label>
                    </div>
                    {dateAppealFiledRadio ===
                    eventStatusDateType.EXPLANATION ? (
                      <div className="event-status-date-explanation-container">
                        <label
                          htmlFor="dateFilledExplanationId"
                          className="event-status-date-exp"
                          id="dateFilledExplanationLabelId"
                        >
                          If not exact, provide explanation *
                        </label>
                        <textarea
                          id="dateFilledExplanationId"
                          onChange={onDateFirstChargedExplanationChange}
                          className={
                            dateAppealFiledExpError
                              ? "form-control form-control-15 is-invalid"
                              : "form-control form-control-15"
                          }
                          maxLength={500}
                          minLength={2}
                          onBlur={onBlurDateFirstChargedExpHandler}
                          rows="3"
                          cols={500}
                          value={dateAppealFiledExp}
                        />
                        <div
                          id="dateAppealFiledExpError"
                          className="invalid-feedback"
                        >
                          Please enter an Explanation
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <div className="crim-comments-container app-det">
              <label
                htmlFor="appealDetDes"
                className="comments-label"
                id="appealDetDesLabelId"
              >
                Appeal details (including status): *
              </label>
              <textarea
                id="appealDetDes"
                onChange={onAppealDetDesChangeHandler}
                onBlur={onBlurAppealDetDesHandler}
                className={
                  appealDetDesError
                    ? "form-control form-control-15 is-invalid"
                    : "form-control form-control-15"
                }
                minLength={1}
                maxLength={200}
                rows="3"
                cols={500}
                value={appealDetDes}
              />
              <div id="appealDetDesError" className="invalid-feedback">
                Please enter a response
              </div>
            </div>

            <div className="crim-comments-container">
              <label
                htmlFor="appealLimitDes"
                className="comments-label"
                id="appealLimitDesLabelId"
              >
                If on Appeal and any limitations or restrictions are currently
                in effect, provide details:
              </label>
              <textarea
                id="appealLimitDes"
                onChange={onAppealLimitDesChangeHandler}
                onBlur={onBlurAppealLimitDesHandler}
                className={
                  appealLimitDesError
                    ? "form-control form-control-15 is-invalid"
                    : "form-control form-control-15"
                }
                minLength={1}
                maxLength={200}
                rows="3"
                cols={500}
                value={appealLimitDes}
              />
              <div id="appealLimitDesError" className="invalid-feedback">
                Please provide a response
              </div>
            </div>
          </>
        </>
      )}

      <div className="backbutton-drp-crim row">
        <div className="col-md-12 buttons">
          <span role="presentation" onClick={handleShowBackModal}>
            <strong>Back</strong>
          </span>
          <Button
            label="Next"
            onClick={handleNext}
            disabled={!!isNextBtnDisabled}
          />
          {false && <span className="mm-dots-loading"></span>} {/* loading */}
        </div>
      </div>

      <BackButtonModal
        isOpen={showBackModal}
        closeBackModal={handleCloseBackModal}
        backClick={handleBackClick}
      />
    </div>
  );
};

export default CivJudStatusDetails;
