import React from "react";
import { Button } from "react-blueprint-library";
import "../StartApplication.scss";
import BackButtonModal from "../Forms/BackButtonModal";
import { putCCBInfo } from "../../../api/makeAPIRequest";
import { FORM_ID } from "../../../constants/Forms";
import "../../../assets/stylesheets/StartApplication/IndividualBroker/CharacterCreditBonding.scss";
import { UtagTracking } from "../../../utils/utag-tracking";
import { CONTRACT_TYPE_DESCRIPTIONS } from "../../../constants/Contract";

class CCBForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initialState();
    this.handleLawsuitChange = this.handleLawsuitChange.bind(this);
    this.handleCrimeChange = this.handleCrimeChange.bind(this);
    this.handleSuspendedChange = this.handleSuspendedChange.bind(this);
    this.handleBankruptcyChange = this.handleBankruptcyChange.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.handleShowBackModal = this.handleShowBackModal.bind(this);
    this.handleCloseBackModal = this.handleCloseBackModal.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
  }

  async componentDidMount() {
    var lawsuitYN = "";
    var lawsuit = "";
    var failedYN = "";
    var failed = "";
    var licsuspendYN = "";
    var licsuspend = "";
    var convictedYN = "";
    var convicted = "";
    var candidateID = "";

    try {
      const result = this.props.ccbData;

      lawsuitYN = result.invlawsuit === null ? "" : result.invlawsuit;
      lawsuit = result.invlawsuitdesc === null ? "" : result.invlawsuitdesc;
      failedYN = result.failedbusiness === null ? "" : result.failedbusiness;
      failed =
        result.failedbusinessdesc === null ? "" : result.failedbusinessdesc;
      licsuspendYN = result.licsuspend === null ? "" : result.licsuspend;
      licsuspend = result.licsuspenddesc === null ? "" : result.licsuspenddesc;
      convictedYN = result.convictcrime === null ? "" : result.convictcrime;
      convicted =
        result.convictcrimedesc === null ? "" : result.convictcrimedesc;
      candidateID = result.candidateid === null ? "" : result.candidateid;

      if (lawsuitYN === true) {
        lawsuitYN = "Y";
      }
      if (lawsuitYN === false) {
        lawsuitYN = "N";
      }
      if (failedYN === true) {
        failedYN = "Y";
      }
      if (failedYN === false) {
        failedYN = "N";
      }
      if (licsuspendYN === true) {
        licsuspendYN = "Y";
      }
      if (licsuspendYN === false) {
        licsuspendYN = "N";
      }
      if (convictedYN === true) {
        convictedYN = "Y";
      }
      if (convictedYN === false) {
        convictedYN = "N";
      }
      await this.setState({
        lawsuit: lawsuitYN,
        crime: convictedYN,
        suspended: licsuspendYN,
        bankruptcy: failedYN,
        lawsuit_text: lawsuit,
        crime_text: convicted,
        suspended_text: licsuspend,
        bankruptcy_text: failed,
        candidate_id: candidateID,
        is_form_complete: true,
      });
      UtagTracking.sendPageNameView(
        "charactercreditbondingform",
        this.props.candidateId,
        this.props.agencynumber,
        this.props.contracttypeid
      );

      this.props.isFormComplete(
        "CCBCompleteInd",
        this.props.ccbData.convictcrime !== null &&
          this.props.ccbData.failedbusiness !== null &&
          this.props.ccbData.invlawsuit !== null &&
          this.props.ccbData.licsuspend !== null
      );
    } catch (err) {
      console.log(err);
    }
  }

  initialState() {
    return {
      lawsuit: "",
      crime: "",
      suspended: "",
      bankruptcy: "",
      lawsuit_text: "",
      crime_text: "",
      suspended_text: "",
      bankruptcy_text: "",
      candidate_id: "",
      showBackModal: false,
      formHasChanged: false,
      is_form_complete: false,
    };
  }

  async handleLawsuitChange(event) {
    this.props.isFormComplete("CCBCompleteInd", false);
    this.props.hasCurrentFormChanged(true);
    await this.setState({
      lawsuit: event.target.value,
      is_form_complete: false,
      formHasChanged: true,
    });
    if (event.target.value === "N") {
      await this.setState({ lawsuit_text: "" });
      var obj_error = document.getElementById("lawsuit_text_error");
      obj_error.classList.replace("invalid-feedback", "d-none");
      var obj = document.getElementById("lawsuit_text");
      obj.classList.remove("is-invalid");
    }
    var obj_error2 = document.getElementById("lawsuit_error");
    obj_error2.classList.replace("invalid-feedback", "d-none");
    var lawsuit_yes = document.getElementById("lawsuit_yes");
    lawsuit_yes.classList.remove("is-invalid");
    var lawsuit_no = document.getElementById("lawsuit_no");
    lawsuit_no.classList.remove("is-invalid");
  }

  async handleCrimeChange(event) {
    this.props.isFormComplete("CCBCompleteInd", false);
    this.props.hasCurrentFormChanged(true);
    await this.setState({
      crime: event.target.value,
      is_form_complete: false,
      formHasChanged: true,
    });
    if (event.target.value === "N") {
      await this.setState({ crime_text: "" });
      var obj_error = document.getElementById("crime_text_error");
      obj_error.classList.replace("invalid-feedback", "d-none");
      var obj = document.getElementById("crime_text");
      obj.classList.remove("is-invalid");
    }
    var obj_error2 = document.getElementById("crime_error");
    obj_error2.classList.replace("invalid-feedback", "d-none");
    var crime_yes = document.getElementById("crime_yes");
    crime_yes.classList.remove("is-invalid");
    var crime_no = document.getElementById("crime_no");
    crime_no.classList.remove("is-invalid");
  }

  async handleSuspendedChange(event) {
    this.props.isFormComplete("CCBCompleteInd", false);
    this.props.hasCurrentFormChanged(true);
    await this.setState({
      suspended: event.target.value,
      is_form_complete: false,
      formHasChanged: true,
    });
    if (event.target.value === "N") {
      await this.setState({ suspended_text: "" });
      var obj_error = document.getElementById("suspended_text_error");
      obj_error.classList.replace("invalid-feedback", "d-none");
      var obj = document.getElementById("suspended_text");
      obj.classList.remove("is-invalid");
    }
    var obj_error2 = document.getElementById("suspended_error");
    obj_error2.classList.replace("invalid-feedback", "d-none");
    var suspended_yes = document.getElementById("suspended_yes");
    suspended_yes.classList.remove("is-invalid");
    var suspended_no = document.getElementById("suspended_no");
    suspended_no.classList.remove("is-invalid");
  }

  async handleBankruptcyChange(event) {
    this.props.isFormComplete("CCBCompleteInd", false);
    this.props.hasCurrentFormChanged(true);
    await this.setState({
      bankruptcy: event.target.value,
      is_form_complete: false,
      formHasChanged: true,
    });
    if (event.target.value === "N") {
      await this.setState({ bankruptcy_text: "" });
      var obj_error = document.getElementById("bankruptcy_text_error");
      obj_error.classList.replace("invalid-feedback", "d-none");
      var obj = document.getElementById("bankruptcy_text");
      obj.classList.remove("is-invalid");
    }
    var obj_error2 = document.getElementById("bankruptcy_error");
    obj_error2.classList.replace("invalid-feedback", "d-none");
    var bankruptcy_yes = document.getElementById("bankruptcy_yes");
    bankruptcy_yes.classList.remove("is-invalid");
    var bankruptcy_no = document.getElementById("bankruptcy_no");
    bankruptcy_no.classList.remove("is-invalid");
  }

  async handleNext() {
    var allFilled = true;

    if (this.state.lawsuit === "") {
      allFilled = false;
      var obj_error = document.getElementById("lawsuit_error");
      obj_error.classList.replace("d-none", "invalid-feedback");
      var lawsuit_yes = document.getElementById("lawsuit_yes");
      lawsuit_yes.classList.add("is-invalid");
      var lawsuit_no = document.getElementById("lawsuit_no");
      lawsuit_no.classList.add("is-invalid");
    } else if (this.state.lawsuit === "Y") {
      var lawsuitEvent = new Event("blur");
      Object.defineProperty(lawsuitEvent, "target", {
        writable: false,
        value: document.getElementById("lawsuit_text"),
      });
      await this.onBlur(lawsuitEvent);
    }
    if (this.state.crime === "") {
      allFilled = false;
      var obj_error2 = document.getElementById("crime_error");
      obj_error2.classList.replace("d-none", "invalid-feedback");
      var crime_yes = document.getElementById("crime_yes");
      crime_yes.classList.add("is-invalid");
      var crime_no = document.getElementById("crime_no");
      crime_no.classList.add("is-invalid");
    } else if (this.state.crime === "Y") {
      var crimeEvent = new Event("blur");
      Object.defineProperty(crimeEvent, "target", {
        writable: false,
        value: document.getElementById("crime_text"),
      });
      await this.onBlur(crimeEvent);
    }
    if (this.state.suspended === "") {
      allFilled = false;
      var obj_error3 = document.getElementById("suspended_error");
      obj_error3.classList.replace("d-none", "invalid-feedback");
      var suspended_yes = document.getElementById("suspended_yes");
      suspended_yes.classList.add("is-invalid");
      var suspended_no = document.getElementById("suspended_no");
      suspended_no.classList.add("is-invalid");
    } else if (this.state.suspended === "Y") {
      var suspendedEvent = new Event("blur");
      Object.defineProperty(suspendedEvent, "target", {
        writable: false,
        value: document.getElementById("suspended_text"),
      });
      await this.onBlur(suspendedEvent);
    }
    if (this.state.bankruptcy === "") {
      allFilled = false;
      var obj_error4 = document.getElementById("bankruptcy_error");
      obj_error4.classList.replace("d-none", "invalid-feedback");
      var bankruptcy_yes = document.getElementById("bankruptcy_yes");
      bankruptcy_yes.classList.add("is-invalid");
      var bankruptcy_no = document.getElementById("bankruptcy_no");
      bankruptcy_no.classList.add("is-invalid");
    } else if (this.state.bankruptcy === "Y") {
      var bankruptcyEvent = new Event("blur");
      Object.defineProperty(bankruptcyEvent, "target", {
        writable: false,
        value: document.getElementById("bankruptcy_text"),
      });
      await this.onBlur(bankruptcyEvent);
    }

    if (
      this.state.lawsuit === "" ||
      (this.state.lawsuit === "Y" &&
        this.state.lawsuit_text.trim(" ").length < 5)
    ) {
      allFilled = false;
    }

    if (
      this.state.crime === "" ||
      (this.state.crime === "Y" && this.state.crime_text.trim(" ").length < 5)
    ) {
      allFilled = false;
    }

    if (
      this.state.suspended === "" ||
      (this.state.suspended === "Y" &&
        this.state.suspended_text.trim(" ").length < 5)
    ) {
      allFilled = false;
    }

    if (
      this.state.bankruptcy === "" ||
      (this.state.bankruptcy === "Y" &&
        this.state.bankruptcy_text.trim(" ").length < 5)
    ) {
      allFilled = false;
    }
    await this.setState({ is_form_complete: allFilled });
    if (allFilled) {
      this.props.saveCharCreditBonding("characterCreditBonding", {
        ...this.props.ccbData,
        convictcrime: this.state.crime === "Y",
        convictcrimedesc: this.state.crime_text,
        failedbusiness: this.state.bankruptcy === "Y",
        failedbusinessdesc: this.state.bankruptcy_text,
        invlawsuit: this.state.lawsuit === "Y",
        invlawsuitdesc: this.state.lawsuit_text,
        licsuspend: this.state.suspended === "Y",
        licsuspenddesc: this.state.suspended_text,
      });
      if (this.state.is_form_complete === true) {
        let data = {
          candidateid: this.state.candidate_id,
          invlawsuit: this.state.lawsuit,
          invlawsuitexp: this.state.lawsuit_text,
          convcrime: this.state.crime,
          convcrimeexp: this.state.crime_text,
          proflic: this.state.suspended,
          proflicexp: this.state.suspended_text,
          failedbus: this.state.bankruptcy,
          failedbusexp: this.state.bankruptcy_text,
        };
        putCCBInfo(this.props.subId, data);
        this.props.hasCurrentFormChanged(false);
        this.setState({ formHasChanged: false });
        this.props.toggleForm(FORM_ID.ERRORS_OMISSIONS);
      }
    }
    this.props.isFormComplete("CCBCompleteInd", allFilled);
  }

  onKeyDown(event) {
    var obj_error;
    obj_error = document.getElementById(event.target.id + "_error");

    if (event.target.value.length === 500) {
      obj_error.innerText = "You have reached the maximum characters allowed";
      obj_error.classList.replace("d-none", "invalid-feedback");
      event.target.classList.add("is-invalid");
    }
    this.props.isFormComplete("CCBCompleteInd", false);
    this.props.hasCurrentFormChanged(true);
  }

  handleChange(event) {
    this.setState({
      [event.target.id]: event.target.value,
      is_form_complete: false,
      formHasChanged: true,
    });
    this.props.isFormComplete("CCBCompleteInd", false);
    this.props.hasCurrentFormChanged(true);
  }

  onBlur(event) {
    var obj_error;
    obj_error = document.getElementById(event.target.id + "_error");
    obj_error.innerText = "Please enter a valid explanation";

    if (event.target.value.trim(" ").length < 5) {
      obj_error.classList.replace("d-none", "invalid-feedback");
      event.target.classList.add("is-invalid");
    } else {
      obj_error.classList.replace("invalid-feedback", "d-none");
      event.target.classList.remove("is-invalid");
    }
  }

  handleCloseBackModal() {
    this.setState({ showBackModal: false });
  }

  handleBackClick() {
    const { convictcrime, failedbusiness, invlawsuit, licsuspend } =
      this.props.ccbData;
    this.props.isFormComplete(
      "CCBCompleteInd",
      convictcrime !== null &&
        failedbusiness !== null &&
        invlawsuit !== null &&
        licsuspend !== null
    );
    this.setState({ formHasChanged: false });
    this.handleCloseBackModal();
    this.props.toggleForm(FORM_ID.BROKER_DEALER);
  }

  handleShowBackModal() {
    //show back modal if anything on the form has changed
    if (this.state.formHasChanged) {
      this.setState({ showBackModal: true });
    } else {
      //this.props.toggleForm(6);
      this.props.toggleForm(FORM_ID.BROKER_DEALER);
    }
  }

  render() {
    return (
      <div id="charactercreditbonding">
        {(this.props.contractType === CONTRACT_TYPE_DESCRIPTIONS.BROKER_CORP ||
          this.props.contractType ===
            CONTRACT_TYPE_DESCRIPTIONS.MEMBER_CORP) && (
          <div className="row">
            <div className="col-md-12">
              <div className="eyebrow">
                <strong>Member of Corporation</strong>
              </div>
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-md-12">
            <h1 id="ccb_header">Character, Credit, and Bonding</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <span id="ccb_required_text" className="required-field-text">
              * = Required Field
            </span>
          </div>
        </div>

        <div id="ccb_question1" className="row">
          <div className="col-md-12">
            <h3 id="ccbQuestion1Header">
              Are you now or have you in the past been directly or indirectly
              involved in a lawsuit since you have been in the business of
              insurance?*
            </h3>
            <div className="custom-control custom-radio">
              <input
                type="radio"
                id="lawsuit_yes"
                name="lawsuit"
                className="custom-control-input"
                checked={this.state.lawsuit === "Y"}
                value="Y"
                onChange={this.handleLawsuitChange}
              />
              <label
                htmlFor="lawsuit_yes"
                className="custom-control-label"
                id="lawsuitYesLabel"
              >
                Yes
              </label>
            </div>

            <div className="custom-control custom-radio">
              <input
                type="radio"
                id="lawsuit_no"
                name="lawsuit"
                className="custom-control-input"
                checked={this.state.lawsuit === "N"}
                value="N"
                onChange={this.handleLawsuitChange}
              />
              <label
                htmlFor="lawsuit_no"
                className="custom-control-label"
                id="lawsuitNoLabel"
              >
                No
              </label>
            </div>
            <div id="lawsuit_error" className="d-none">
              Please choose a response.
            </div>
            <div
              id="lawsuitreason"
              className={this.state.lawsuit === "Y" ? "d-block" : "d-none"}
            >
              <div className="row">
                <div className="col-md-12">
                  <label
                    id="ccb_question1_explain"
                    className="authQuestions"
                    htmlFor="lawsuit_text"
                  >
                    Please explain your involvement in a lawsuit relative to
                    insurance practice.*
                  </label>
                  <textarea
                    rows="5"
                    id="lawsuit_text"
                    className="form-control"
                    maxLength={500}
                    autoComplete="new-password"
                    value={this.state.lawsuit_text}
                    onBlur={this.onBlur}
                    onChange={this.handleChange}
                    onKeyDown={this.onKeyDown}
                  ></textarea>
                  <div id="lawsuit_text_error" className="d-none">
                    Please enter a valid explanation
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="ccb_question2" className="row">
          <div className="col-md-12">
            <h3 id="ccbQuestion2Header">
              Have you ever been convicted of any crime (felony or
              misdemeanor)?*
            </h3>
            <div className="custom-control custom-radio">
              <input
                type="radio"
                id="crime_yes"
                name="crime"
                className="custom-control-input"
                checked={this.state.crime === "Y"}
                value="Y"
                onChange={this.handleCrimeChange}
              />
              <label
                htmlFor="crime_yes"
                className="custom-control-label"
                id="crimeYesLabels"
              >
                Yes
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                type="radio"
                id="crime_no"
                name="crime"
                className="custom-control-input"
                checked={this.state.crime === "N"}
                value="N"
                onChange={this.handleCrimeChange}
              />
              <label
                htmlFor="crime_no"
                className="custom-control-label"
                id="crimeNoLabel"
              >
                No
              </label>
            </div>
            <div id="crime_error" className="d-none">
              Please choose a response.
            </div>
            <div
              id="crimereason"
              className={this.state.crime === "Y" ? "d-block" : "d-none"}
            >
              <div className="row">
                <div className="col-md-12">
                  <label
                    id="ccb_question2_explain"
                    className="authQuestions"
                    htmlFor="crime_text"
                  >
                    Please explain the crime (felony or misdemeanor) of which
                    you have been convicted.*
                  </label>
                  <textarea
                    rows="5"
                    id="crime_text"
                    className="form-control"
                    maxLength={500}
                    autoComplete="new-password"
                    value={this.state.crime_text}
                    onBlur={this.onBlur}
                    onChange={this.handleChange}
                    onKeyDown={this.onKeyDown}
                  ></textarea>
                  <div id="crime_text_error" className="d-none">
                    Please enter a valid explanation
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="ccb_question3" className="row">
          <div className="col-md-12">
            <h3 id="ccbQuestion3Header">
              Have you ever had a professional license suspended, revoked,
              cancelled, or had your employment or contract 'terminated for
              cause'?*
            </h3>
            <div className="custom-control custom-radio">
              <input
                type="radio"
                id="suspended_yes"
                name="suspended"
                className="custom-control-input"
                checked={this.state.suspended === "Y"}
                value="Y"
                onChange={this.handleSuspendedChange}
              />
              <label
                htmlFor="suspended_yes"
                className="custom-control-label"
                id="suspendedYesLabel"
              >
                Yes
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                type="radio"
                id="suspended_no"
                name="suspended"
                className="custom-control-input"
                checked={this.state.suspended === "N"}
                value="N"
                onChange={this.handleSuspendedChange}
              />
              <label
                htmlFor="suspended_no"
                className="custom-control-label"
                id="suspendedNoLabel"
              >
                No
              </label>
            </div>
            <div id="suspended_error" className="d-none">
              Please choose a response.
            </div>
            <div
              id="suspendedreason"
              className={this.state.suspended === "Y" ? "d-block" : "d-none"}
            >
              <div className="row">
                <div className="col-md-12">
                  <label
                    id="ccb_question3_explain"
                    className="authQuestions"
                    htmlFor="suspended_text"
                  >
                    Please explain why your professional license was suspended,
                    revoked, cancelled, or why your employment or contract was
                    'terminated for cause'.*
                  </label>
                  <textarea
                    rows="5"
                    id="suspended_text"
                    className="form-control"
                    maxLength={500}
                    autoComplete="new-password"
                    value={this.state.suspended_text}
                    onBlur={this.onBlur}
                    onChange={this.handleChange}
                    onKeyDown={this.onKeyDown}
                  ></textarea>
                  <div id="suspended_text_error" className="d-none">
                    Please enter a valid explanation
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="ccb_question4" className="row">
          <div className="col-md-12">
            <h3 id="ccbQuestion4Header">
              Have you ever failed in business, declared bankruptcy, compromised
              with creditors, or had any liens or judgments against you?*
            </h3>
            <div className="custom-control custom-radio">
              <input
                type="radio"
                id="bankruptcy_yes"
                name="bankruptcy"
                className="custom-control-input"
                checked={this.state.bankruptcy === "Y"}
                value="Y"
                onChange={this.handleBankruptcyChange}
              />
              <label
                htmlFor="bankruptcy_yes"
                className="custom-control-label"
                id="bankruptcyYesLabel"
              >
                Yes
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                type="radio"
                id="bankruptcy_no"
                name="bankruptcy"
                className="custom-control-input"
                checked={this.state.bankruptcy === "N"}
                value="N"
                onChange={this.handleBankruptcyChange}
              />
              <label
                htmlFor="bankruptcy_no"
                className="custom-control-label"
                id="bankruptcyNoLabel"
              >
                No
              </label>
            </div>
            <div id="bankruptcy_error" className="d-none">
              Please choose a response.
            </div>
            <div
              id="bankruptcyreason"
              className={this.state.bankruptcy === "Y" ? "d-block" : "d-none"}
            >
              <div className="row">
                <div className="col-md-12">
                  <label className="authQuestions" htmlFor="bankruptcy_text">
                    Please explain if you have ever failed in business, declared
                    bankruptcy, compromised with creditors, or had any liens or
                    judgments against you.*
                  </label>
                  <textarea
                    rows="5"
                    id="bankruptcy_text"
                    className="form-control"
                    maxLength={500}
                    autoComplete="new-password"
                    value={this.state.bankruptcy_text}
                    onBlur={this.onBlur}
                    onChange={this.handleChange}
                    onKeyDown={this.onKeyDown}
                  ></textarea>
                  <div id="bankruptcy_text_error" className="d-none">
                    Please enter a valid explanation
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row" id="actions">
          <div className="col-md-12">
            <span
              id="ccb_back"
              onClick={() => {
                this.handleShowBackModal();
              }}
            >
              <strong> Back </strong>
            </span>
            <Button id="ccb_next" onClick={this.handleNext}>
              Next
            </Button>
          </div>
        </div>

        <BackButtonModal
          isOpen={this.state.showBackModal}
          closeBackModal={this.handleCloseBackModal}
          backClick={this.handleBackClick}
        />
      </div>
    );
  }
}

export default CCBForm;
