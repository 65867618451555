import React from "react";
import { Button } from "react-blueprint-library";
import "../StartApplication.scss";
import InputMask from "react-input-mask";
import BackButtonModal from "../Forms/BackButtonModal";
import {
  insertCorporation,
  updateCorporation,
  insertCandidateCorp,
} from "../../../api/makeAPIRequest";
import { FORM_ID } from "../../../constants/Forms";
import { onlyNumeric } from "../../../common/Functions";
import { UtagTracking } from "../../../utils/utag-tracking";
import NotificationModal from "../Forms/NotificationModal";
import NavigationButtons from "../../SharedComponents/NavigationButtons";
import { CONTRACT_TYPE_DESCRIPTIONS } from "../../../constants/Contract";

class CorporationInformationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initialState();
    this.saveCorporation = this.saveCorporation.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.handleShowBackModal = this.handleShowBackModal.bind(this);
    this.handleCloseBackModal = this.handleCloseBackModal.bind(this);
  }

  async componentDidMount() {
    try {
      const result = this.props.corporationData;

      if (this.props.corpid !== 0) {
        await this.setState({
          corp_id: this.props.corpid,
          corporation_name: result.corpname,
          corporation_officer: result.corpofficername,
          corporation_taxid: result.corptaxid,
          corporation_reps: result.numberofreps,
          corporation_email: result.corpemailaddress,
          is_completed: true,
        });

        if (
          this.state.corporation_officer !== null &&
          this.state.corporation_reps !== null &&
          this.state.corporation_email !== null
        ) {
          this.props.isFormComplete("CorpInfoCompleteInd", true);
        } else {
          this.props.isFormComplete("CorpInfoCompleteInd", false);
        }
      } else {
        this.initialState();
      }
      UtagTracking.sendPageNameView(
        "corporationform",
        this.props.candidateId,
        this.props.agencynumber,
        this.props.contracttypeid
      );
    } catch (err) {
      console.log(err);
    }
  }

  initialState() {
    return {
      corp_id: 0,
      corporation_name: "",
      corporation_officer: "",
      corporation_taxid: "",
      corporation_reps: "",
      corporation_email: "",
      candidate_id: "",
      showBackModal: false,
      formHasChanged: false,
      is_completed: false,
      formHasError: false,
      showContactAgencyModal: false,
    };
  }

  saveCorporation() {
    this.props.saveCorporationInfo("corporationData", {
      ...this.props.corporationData,
      corpid: this.state.corp_id,
      corpname: this.state.corporation_name,
      corpofficername: this.state.corporation_officer,
      corptaxid: this.state.corporation_taxid,
      numberofreps: this.state.corporation_reps,
      corpemailaddress: this.state.corporation_email,
    });
  }

  async handleNext(source) {
    await this.setState({ formHasError: false });

    var corpnameEvent = new Event("blur");
    Object.defineProperty(corpnameEvent, "target", {
      writable: false,
      value: document.getElementById("corporation_name"),
    });
    await this.onBlur(corpnameEvent);

    var corpoffEvent = new Event("blur");
    Object.defineProperty(corpoffEvent, "target", {
      writable: false,
      value: document.getElementById("corporation_officer"),
    });
    await this.onBlur(corpoffEvent);

    var corprepsEvent = new Event("blur");
    Object.defineProperty(corprepsEvent, "target", {
      writable: false,
      value: document.getElementById("corporation_reps"),
    });
    await this.onBlur(corprepsEvent);

    var corpemailEvent = new Event("blur");
    Object.defineProperty(corpemailEvent, "target", {
      writable: false,
      value: document.getElementById("corporation_email"),
    });
    await this.onBlur(corpemailEvent);

    if (this.state.formHasError === false) {
      var result = "";

      this.saveCorporation();
      let data = {
        corpid: this.state.corp_id,
        corpname: this.state.corporation_name,
        corpofficername: this.state.corporation_officer,
        corptaxid: this.state.corporation_taxid,
        numberofreps: this.state.corporation_reps,
        corpemailaddress: this.state.corporation_email,
      };

      if (this.state.corp_id === 0) {
        result = await insertCorporation(this.props.subId, data);

        let corpid = result.corpid;
        let data2 = {
          candidateid: this.props.candidateId,
          contractid: this.props.contractid,
          corpid: corpid,
        };
        if (corpid !== undefined) {
          await insertCandidateCorp(this.props.subId, data2);
        }
      } else {
        if (this.state.formHasChanged === true) {
          result = await updateCorporation(this.props.subId, data);
        }
      }

      if (result !== undefined && result.message === "Duplicate Tax ID found") {
        await this.setState({ showContactAgencyModal: true });
      } else {
        this.setState({ is_completed: true });
        await this.props.updateCorpID();
        this.props.hasCurrentFormChanged(false);
        this.props.isFormComplete("CorpInfoCompleteInd", true);
        this.setState({ formHasChanged: false });
        this.props.toggleForm(FORM_ID.CORPORATE_BUSINESS_ADDRESS);
      }
    }
    this.props.isFormComplete("CorpInfoCompleteInd", !this.state.formHasError);
  }

  onKeyDown() {
    this.props.isFormComplete("CorpInfoCompleteInd", false);
    this.props.hasCurrentFormChanged(true);
    this.setState({ is_completed: false });
  }

  handleChange(event) {
    this.props.isFormComplete("CorpInfoCompleteInd", false);
    this.props.hasCurrentFormChanged(true);
    this.setState({
      formHasChanged: true,
      is_completed: false,
      [event.target.id]: event.target.value,
    });
  }

  async onBlur(event) {
    if (typeof event.target.value !== "undefined") {
      var obj_error;
      obj_error = document.getElementById(event.target.id + "_error");

      if (
        event.target.id === "corporation_name" ||
        event.target.id === "corporation_officer"
      ) {
        if (event.target.value.trim().length < 2) {
          obj_error.style.display = "block";
          event.target.classList.add("is-invalid");
          await this.setState({ formHasError: true });
        } else {
          obj_error.style.display = "none";
          event.target.classList.remove("is-invalid");
        }
      } else if (event.target.id === "corporation_reps") {
        if (event.target.value.trim().length === 0) {
          obj_error.style.display = "block";
          event.target.classList.add("is-invalid");
          await this.setState({ formHasError: true });
        } else {
          obj_error.style.display = "none";
          event.target.classList.remove("is-invalid");
        }
      } else if (event.target.id === "corporation_email") {
        var hasError = false;
        if (event.target.value.trim().length === 0) {
          hasError = true;
        }

        var emailregex =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailregex.test(event.target.value) === false) {
          hasError = true;
        }

        if (hasError) {
          obj_error.style.display = "block";
          event.target.classList.add("is-invalid");
          await this.setState({ formHasError: true });
        } else {
          obj_error.style.display = "none";
          event.target.classList.remove("is-invalid");
        }
      }
    }
  }

  handleCloseBackModal() {
    this.setState({ showBackModal: false });
  }

  handleShowBackModal() {
    //show back modal if anything on the form has changed
    if (this.state.formHasChanged) {
      this.setState({ showBackModal: true });
    } else {
      this.props.toggleForm(FORM_ID.BUSINESS_INFO);
    }
  }

  render() {
    return (
      <div id="brokercorpcorporationform">
        <div className="row">
          <div className="col-md-12">
            <div className="eyebrow">
              <strong>Broker Corporation</strong>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <h1 id="broker_corp_corporation_header">Corporation Information</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12" style={{ paddingBottom: "20px" }}>
            <span
              id="corporation_required_text"
              className="required-field-text"
            >
              * = Required Field
            </span>
          </div>
        </div>

        <div id="corporation_name_row" className="row">
          <div className="col-md-12">
            <label id="corporation_name_label" htmlFor="corporationt_name">
              <strong>Legal Registered Corporation Name*</strong>
            </label>
            <input
              type="text"
              id="corporation_name"
              className="form-control"
              spellCheck="false"
              maxLength={100}
              autoComplete="off"
              value={this.state.corporation_name}
              onBlur={this.onBlur}
              onChange={this.handleChange}
              onKeyDown={this.onKeyDown}
            />
            <div id="corporation_name_error" className="invalid-feedback">
              Please enter a valid Name
            </div>
          </div>
        </div>

        <div
          id="corporation_officer_row"
          className="row"
          style={{ marginTop: "10px" }}
        >
          <div className="col-md-12">
            <label id="corporation_officer_label" htmlFor="corporation_officer">
              <strong>Name of Corporation Officer*</strong>
            </label>
            <input
              type="text"
              id="corporation_officer"
              className="form-control"
              spellCheck="false"
              maxLength={100}
              autoComplete="off"
              value={this.state.corporation_officer}
              onBlur={this.onBlur}
              onChange={this.handleChange}
              onKeyDown={this.onKeyDown}
            />
            <div id="corporation_officer_error" className="invalid-feedback">
              Please enter a valid Name
            </div>
          </div>
        </div>

        <div
          id="corporation_taxid_row"
          className="row"
          style={{ marginTop: "10px" }}
        >
          <div className="col-md-12">
            <label id="corporation_taxid_label" htmlFor="corporation_taxid">
              <strong>Tax ID*</strong>
            </label>
            <InputMask
              id="corporation_taxid"
              mask={"99-9999999"}
              maskChar="_"
              className="form-control"
              placeholder="__-______"
              //maxLength={9}
              autoComplete="off"
              value={this.state.corporation_taxid}
              onBlur={this.onBlur}
              onChange={this.handleChange}
              onKeyDown={this.onKeyDown}
              style={{ width: "15%" }}
              disabled={true}
            />

            <div id="corporation_taxid_error" className="invalid-feedback">
              Please enter a valid Tax ID
            </div>
          </div>
        </div>

        <div
          id="corporation_reps_row"
          className="row"
          style={{ marginTop: "10px" }}
        >
          <div className="col-md-12">
            <label id="corporation_reps_label" htmlFor="corporation_reps">
              <strong>
                Number of Representatives affiliated with Corporation*
              </strong>
            </label>
            <input
              type="text"
              id="corporation_reps"
              className="form-control"
              spellCheck="false"
              maxLength={3}
              autoComplete="off"
              value={this.state.corporation_reps}
              onBlur={this.onBlur}
              onChange={this.handleChange}
              onKeyDown={this.onKeyDown}
              onKeyPress={onlyNumeric}
              style={{ width: "15%" }}
            />
            <div id="corporation_reps_error" className="invalid-feedback">
              Please enter a valid number
            </div>
          </div>
        </div>

        <div
          id="corporation_email_row"
          className="row"
          style={{ marginTop: "10px" }}
        >
          <div className="col-md-12">
            <label id="corporation_email_label" htmlFor="corporation_email">
              <strong>Broker Corporation Email Address*</strong>
            </label>
            <input
              type="text"
              id="corporation_email"
              className="form-control"
              spellCheck="false"
              maxLength={320}
              autoComplete="off"
              value={this.state.corporation_email}
              onBlur={this.onBlur}
              onChange={this.handleChange}
              onKeyDown={this.onKeyDown}
            />
            <div id="corporation_email_error" className="invalid-feedback">
              Please enter a valid email address (e.g. , example@email.com)
            </div>
          </div>
        </div>

        {this.props.contracttypedesc ===
          CONTRACT_TYPE_DESCRIPTIONS.MEMBER_CORP && (
          <NavigationButtons
            onBack={this.handleShowBackModal}
            onNext={this.handleNext}
            //isLoading={this.state.USPSLoading}
            nxtbtnid={"corp_next"}
            bckbtnid={"corp_back"}
          />
        )}

        {this.props.contracttypedesc ===
          CONTRACT_TYPE_DESCRIPTIONS.BROKER_CORP && (
          <Button
            id="corp_next"
            style={{ marginTop: "30px", float: "right" }}
            onClick={() => {
              this.handleNext();
            }}
          >
            Next
          </Button>
        )}
        <BackButtonModal
          isOpen={this.state.showBackModal}
          closeBackModal={this.handleCloseBackModal}
          backClick={this.handleBackClick}
        />

        <NotificationModal
          isOpen={this.state.showContactAgencyModal}
          closeModal={() => {
            this.setState({ showContactAgencyModal: false, isLoading: false });
          }}
          exitApplication={() => {
            window.location = "/";
          }}
          source={"PersonalInformation"}
        />
      </div>
    );
  }
}

export default CorporationInformationForm;
