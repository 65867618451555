import axios from "axios";
import {
  ubiqPath,
  contractPath,
  mainPath,
  RequestMethodTypes,
  TOKEN_STORAGE,
} from "./constants";
import { logError } from "../../utils/errors";

const parsedToken = () => JSON.parse(localStorage.getItem(TOKEN_STORAGE));

const getAccessToken = () => parsedToken()?.accessToken?.accessToken || null;

export const getBearerToken = () => `Bearer ${getAccessToken()}`;

export const getHeaders = (token, isPDF) => {
  const accessToken = getBearerToken() || `Bearer ${token}`;
  const contentType = "application/json";
  const contentTypePDF = "application/pdf";

  return {
    "Content-Type": contentType,
    Accept: isPDF ? contentTypePDF : contentType,
    Authorization: accessToken,
  };
};

export const getUrl = (url, isContractURL) =>
  `${isContractURL ? contractPath : mainPath}/${url}`;

export const getUBIQUrl = (url) => `${ubiqPath}/${url}`;

export const fetchWrapper = async ({
  method = RequestMethodTypes.GET,
  url = "",
  data = null,
  responseType,
  isContractURL = false,
  isUBIQ = false,
  isPDF = false,
  isErrorPage = true,
  token = null,
}) => {
  try {
    let res = null;

    if (
      [RequestMethodTypes.POST, RequestMethodTypes.PUT].includes(method) ||
      url === "bigficradisclosure/blankdisclosure"
    ) {
      if (!responseType) {
        res = await axios({
          method,
          url: isUBIQ ? getUBIQUrl(url) : getUrl(url, isContractURL),
          headers: getHeaders(token, isPDF),
          data: data,
        });
      } else {
        res = await axios({
          method,
          url: isUBIQ ? getUBIQUrl(url) : getUrl(url, isContractURL),
          responseType,
          headers: getHeaders(token, isPDF),
          data: data,
        });
      }

      return res;
    }

    res = await axios({
      method,
      url: isUBIQ ? getUBIQUrl(url) : getUrl(url, isContractURL),
      headers: getHeaders(token, isPDF),
    });

    return res;
  } catch (error) {
    console.error(error);

    logError(error);

    if (isErrorPage) {
      window.location = "./errorPage";
    }
  }
};
