import { forwardRef } from "react";
import "./Toast.scss";

const Toast = forwardRef(({ showToast = false, closeToast, text }, ref) => (
  <div className="toast-wrapper" ref={ref} onBlur={closeToast}>
    <div
      id="ccb_alert"
      className={
        showToast === true
          ? "show col-md-6 alert-item"
          : "d-none col-md-6 alert-item"
      }
      style={{ float: "right" }}
    >
      <div className="mm-toast-container">
        <div
          className="toast"
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
        >
          <div className="wrapper">
            <div className="toast-header">
              <strong>{text}</strong>

              <button
                className="icon-close"
                type="button"
                data-dismiss="toast"
                aria-label="Close"
                onClick={(e) => {
                  e.stopPropagation();
                  closeToast();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
));

export default Toast;
