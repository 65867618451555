import React, { useEffect, useReducer } from "react";
import { Button } from "react-blueprint-library";
import BackButtonModal from "../../../../Forms/BackButtonModal";
import { logoutActions } from "../../../../../../store/actions";
import { useDispatch } from "react-redux";

const TerminationDisclosureCommentsForm = ({
  updateCCBForm,
  backFormId,
  nextFormId,
  tempDRP,
  editIndex,
  updateTempDRP,
  updateTerminationDisclosureDRPs,
  hasCurrentFormChanged,
  currentFormChanged,
  sleep,
}) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case "loadTempData":
        hasCurrentFormChanged(true);
        return {
          ...state,
          terminationComments: tempDRP.terminationComments,
        };
      case "updateShowBackModal":
        return { ...state, showBackModal: action.value };
      case "updateIsFormComplete":
        return { ...state, isFormComplete: action.value };
      case "onChange":
        return { ...state, [action.id]: action.value };
      default:
        return state;
    }
  };
  const initialState = {
    showBackModal: false,
    terminationComments: "",
    loading: false,
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const logoutDispatch = useDispatch();

  const handleChange = (event) => {
    if (event.target.id === "terminationComments") {
      dispatch({
        type: "onChange",
        value: event.target.value,
        id: "terminationComments",
      });
      hasCurrentFormChanged(true);
    }
  };

  useEffect(() => {
    if (
      editIndex === null &&
      state.terminationComments !== undefined &&
      state.terminationComments !== ""
    ) {
      hasCurrentFormChanged(true);
    } else if (
      editIndex !== null &&
      state.terminationComments !== tempDRP.terminationComments
    ) {
      hasCurrentFormChanged(true);
    } else {
      hasCurrentFormChanged(false);
    }
  }, [state.terminationComments, hasCurrentFormChanged, tempDRP]);

  useEffect(() => {
    window.scrollTo(0, 0);
    hasCurrentFormChanged(false);
    logoutDispatch(logoutActions.setShowLogoutModal({ showLogoutModal: true }));
    if (tempDRP !== "") {
      dispatch({ type: "loadTempData" });
    }
  }, []);

  const handleNext = async () => {
    const newTempDRP = {
      allegations: tempDRP.allegations,
      drpList: tempDRP.drpList,
      firmName: tempDRP.firmName,
      firstCheck: tempDRP.firstCheck,
      otherText: tempDRP.otherText,
      productTypes: tempDRP.productTypes,
      termDate: tempDRP.termDate,
      termDateExplanation: tempDRP.termDateExplanation,
      termDateRadio: tempDRP.termDateRadio,
      termType: tempDRP.termType,
      terminationComments: state.terminationComments,
    };
    updateTerminationDisclosureDRPs(newTempDRP);
    updateTempDRP("");
    dispatch({
      type: "onChange",
      value: true,
      id: "loading",
    });
    await sleep(3000);
    dispatch({
      type: "onChange",
      value: false,
      id: "loading",
    });
    updateCCBForm(nextFormId);
    hasCurrentFormChanged(false);
  };

  const handleBackClick = async () => {
    handleCloseBackModal();
    hasCurrentFormChanged(false);
    updateCCBForm(backFormId);
  };

  const handleShowBackModal = () => {
    if (currentFormChanged === true) {
      dispatch({ type: "updateShowBackModal", value: true });
    } else {
      handleBackClick();
    }
  };

  const handleCloseBackModal = () =>
    dispatch({ type: "updateShowBackModal", value: false });

  return (
    <>
      <div className="termination-disclosures-wrapper">
        <div className="row">
          <div className="col-md-12">
            <span id="termAddDrpText">
              You may use this field to provide a brief summary of the
              circumstances leading to the termination. (Optional)
            </span>
          </div>
        </div>
        <div style={{ paddingTop: "20px" }}>
          <textarea
            id="terminationComments"
            onChange={handleChange}
            className="form-control"
            value={state.terminationComments}
            rows="3"
            maxLength={500}
          ></textarea>
        </div>
        <div className="row">
          <div
            className="col-md-12 buttons"
            style={{ textAlign: "right", paddingTop: "30px" }}
          >
            <span
              id="termBack"
              className="back-button"
              onClick={handleShowBackModal}
            >
              <strong id="termBack">Back</strong>
            </span>
            {state.loading ? (
              <>
                <Button
                  id="termAddDRPNext"
                  style={{ marginRight: "10px" }}
                  disabled
                  onClick={handleNext}
                >
                  Complete DRP
                </Button>
                <span className="mm-dots-loading"></span>
              </>
            ) : (
              <Button
                id="termAddDRPNext"
                style={{ marginRight: "10px" }}
                onClick={handleNext}
              >
                Complete DRP
              </Button>
            )}
          </div>
        </div>
        <BackButtonModal
          isOpen={state.showBackModal}
          closeBackModal={handleCloseBackModal}
          backClick={handleBackClick}
        />
      </div>
    </>
  );
};
export default TerminationDisclosureCommentsForm;
