import React, { useState } from "react";
import logo from "../../assets/img/MM_Onboarding_Logo.png";
import { withOktaAuth } from "@okta/okta-react";
import LogoutModal from "../SharedComponents/LogoutModal";
import { useSelector } from "react-redux";

const StartApplicationHeader = (props) => {
  const [showModal, setShowModal] = useState(false);
  const { showLogoutModal } = useSelector((state) => state.logoutModal);

  const logout = async () => {
    await props.oktaAuth.signOut();
  };

  const handleModalOpen = () => {
    if (props.currentFormChanged === true || !!showLogoutModal) {
      setShowModal(true);
    } else {
      logout();
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  return (
    <div className="row" id="startAppHeader">
      <div className="col-2"></div>
      <div className="col-8" id="mm-onboarding-logo">
        <img id="mm-logo" alt="MassMutual" src={logo} />
      </div>
      <div className="col-2">
        <span
          data-rb-event-key="logout"
          className="logout nav-link"
          role="button"
          onClick={handleModalOpen}
        >
          <h3>
            <span className="icon-logout"></span>
            <span className="logout-button-text">Log Out</span>
          </h3>
        </span>
      </div>

      <LogoutModal
        isOpen={showModal}
        handleClose={handleModalClose}
        handleLogOut={logout}
      />
    </div>
  );
};

export default withOktaAuth(StartApplicationHeader);
