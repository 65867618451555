/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import { StepList } from "react-blueprint-library";
import BackButtonModal from "../../../../Forms/BackButtonModal";

import "./AddDRPFormLayout.scss";
import { CCB_FORM_ID } from "../../CriminalDisclosureForm/constants";
import { useSelector } from "react-redux";

const AddDRPFormLayout = ({
  updateCCBForm,
  header,
  section,
  children,
  activeStep,
  steps,
  details,
  description,
}) => {
  const { showLogoutModal } = useSelector((state) => state.logoutModal);
  const [showBackModal, setShowBackModal] = useState(false);

  const handleCloseBackModal = () => setShowBackModal(false);

  const handleBackClick = () => {
    updateCCBForm(CCB_FORM_ID.DRP_SUMMARY, { isReset: true });
    handleCloseBackModal();
  };

  const handleShowBackModal = () => {
    if (showLogoutModal) {
      setShowBackModal(true);
    } else {
      handleBackClick();
    }
  };

  return (
    <>
      <div className="drp-layout-wrapper">
        <div className="row">
          <div className="col-md-12">
            <div className="drp-layout-return">
              <span role="presentation" onClick={handleShowBackModal}>
                <span className="icon-xs icon-caret-spin-left"></span>
                <strong id="drp-return-disclosure">Return To Disclosure</strong>
              </span>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="drp-layout-eyebrow">
              <strong id={`drp-eyebrow-text`}>{header}</strong>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <label>
              <h1 id="drp-header">Disclosure Reporting Page (DRP)</h1>
            </label>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 section-details-container">
            <div className="drp-layout-section">
              <strong id="drp-section">{section}</strong>
            </div>
            <div className={!section ? "guide with-padding" : "guide"}>
              * = Required
            </div>
          </div>
        </div>

        <StepList activeStep={activeStep} steps={steps} subtitle="Details" />

        <div className="drp-layout-details">
          <strong id="drp-steps">
            <span className="mm-desktop-tablet-only">
              Step {activeStep} of {steps} |{" "}
            </span>
            {details}
          </strong>
        </div>

        {description ? (
          <div className="row">
            <div className="col-md-12">
              <span className="drp-layout-description">{description}</span>
            </div>
          </div>
        ) : null}

        {children}

        <BackButtonModal
          isOpen={showBackModal}
          closeBackModal={handleCloseBackModal}
          backClick={handleBackClick}
        />
      </div>
    </>
  );
};

export default AddDRPFormLayout;
