function ReviewSignHeader(props) {
  return (
    <div id={`reviewsignheader`}>
      <div className="row">
        <div className="col-md-12">
          <div className="eyebrow" id={`step-${props.step}`}>
            <strong>{`Step ${props.step} of 2 / ${props.title}`}</strong>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12" id="main-hdng">
          <h1>Review and Sign</h1>
        </div>
      </div>
    </div>
  );
}

export default ReviewSignHeader;
