function SectionTitle(props) {
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <h1 id={props.titleID}>{props.titleName}</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <span className="required-field-text">* = Required Field</span>
        </div>
      </div>
    </>
  );
}

export default SectionTitle;
