export const headerText = {
  HEADER: "Termination Disclosure",
  TERM_DISCL: "Termination Disclosures",
};

export const CCB_FORM_ID = {
  TERMINATION_DISCLOSURE: 1,
  DRP_SUMMARY: 2,
  ADD_DRP: 3,
  ADD_DRP_COMMENT: 4,
};
