import React from "react";
import { Button, Modal } from "react-blueprint-library";
import "../../../assets/stylesheets/NotificationModal.scss";

export default function NotificationModal(props) {
  return (
    <>
      <div>
        <Modal
          id="noficationModal"
          show={props.isOpen}
          className="modal-md"
          title=""
        >
          <div>
            <div className="modal-header">
              <div id="notificationModalHeading" className="container">
                {props.source === "PersonalInformation" ? (
                  <>
                    <h3>
                      Application Error
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close modal dialog"
                        onClick={props.closeModal}
                      />
                    </h3>
                  </>
                ) : (
                  <h2>
                    Consent required for release of information
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close modal dialog"
                      onClick={props.closeModal}
                    />
                  </h2>
                )}
              </div>
            </div>
            <div className="modal-body">
              <div className="container">
                <div id="notificationModalText">
                  {props.source === "PersonalInformation" ? (
                    <>
                      You can not move forward with this application. Please
                      contact your agency for next steps
                    </>
                  ) : (
                    <>
                      You can not move forward in the application without
                      consenting to the consumer report. Please contact your
                      agency for any questions.
                    </>
                  )}
                  <br />
                  <br />
                </div>
              </div>

              <div className="modal-footer">
                <div>
                  <Button
                    id="notificationModalExit"
                    label="Exit Application"
                    onClick={props.exitApplication}
                  ></Button>
                  <span id="notificationModalCancel" onClick={props.closeModal}>
                    <strong> Cancel </strong>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}
