import React from "react";
import AddressComponent from "../../SharedComponents/AddressVerificationForm";
import { FORM_ID } from "../../../constants/Forms";
import "../../../assets/stylesheets/StartApplication/IndividualBroker/ResidentialAddress.scss";
import SectionTitle from "../../SharedComponents/SectionTitle";
import "../../../assets/stylesheets/StartApplication/IndividualBroker/ResidentialAddress.scss";
import { CONTRACT_TYPE_DESCRIPTIONS } from "../../../constants/Contract";

export default function ResidentialAddressForm(props) {
  let nextPage =
    props.dashboardInfo.contracttypedesc ===
      CONTRACT_TYPE_DESCRIPTIONS.CAREER_AGENT ||
    props.dashboardInfo.contracttypedesc ===
      CONTRACT_TYPE_DESCRIPTIONS.CAREER_SAMPLING
      ? FORM_ID.BUSINESS_INFO
      : FORM_ID.BUSINESS_INFO;

  return (
    <div id="residentialaddress">
      {(props.contractType === CONTRACT_TYPE_DESCRIPTIONS.BROKER_CORP ||
        props.contractType === CONTRACT_TYPE_DESCRIPTIONS.MEMBER_CORP) && (
        <div className="row">
          <div className="col-md-12">
            <div className="eyebrow">
              <strong>Member of Corporation</strong>
            </div>
          </div>
        </div>
      )}

      <SectionTitle
        titleID="res_addr_title"
        titleName="Residential Address"
      ></SectionTitle>
      <div id="residential-address-instructions">
        <div className="row">
          <div className="col-12">
            <h3 id="res_addr_provide_text">
              Provide your current residential address.
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <span id="res_addr_po_box_text" className="required-field-text">
              A P.O. Box or private mailbox number (PMB) will not be accepted
              for your residential address.
            </span>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <AddressComponent
            id="residential"
            backFunction={() => props.toggleForm(FORM_ID.PERSONAL_INFO)}
            nextFunction={() => props.toggleForm(nextPage)}
            saveResAddress={props.saveResAddress}
            resAddress={props.residentialAddress}
            isFormComplete={props.isFormComplete}
            toggleFormComplete={props.toggleFormComplete}
            subId={props.subId}
            hasCurrentFormChanged={props.hasCurrentFormChanged}
            contracttypedesc={props.dashboardInfo.contracttypedesc}
            candidateid={props.dashboardInfo.candidateid}
            agencynumber={props.dashboardInfo.agencynumber}
            contracttypeid={props.dashboardInfo.contracttypeid}
          />
        </div>
      </div>
    </div>
  );
}
