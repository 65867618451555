import React from "react";
import InputMask from "react-input-mask";
import { eventStatusDateType } from "../../../constants";

const DateOfDisposition = ({
  dateOfDispError,
  dateErrorMsg,
  dateOfDisp,
  onDateOfDispChangeHandler,
  onDateOfDispBlurHandler,
  dateOfDispRadio,
  handleDateOfDispChecked,
  onDateOfDispExplanationChange,
  dateOfDispExpError,
  onBlurDateOfDispExpHandler,
  dateOfDispExp,
}) => {
  return (
    <div className="row">
      <div className="col-md-12 event-status-date-wrapper date-of-disp">
        <label id="dateOfDispLabelId" htmlFor="dateOfDispId">
          <strong id="dateOfDispLabel">Date of Disposition: *</strong>
        </label>
        <InputMask
          id="dateOfDispId"
          mask="99/99/9999"
          maskChar="_"
          className={`${
            dateOfDispError ? "form-control is-invalid" : "form-control"
          } event-status-date-input`}
          placeholder="MM/DD/YYYY"
          autoComplete="off"
          required
          value={dateOfDisp}
          onChange={onDateOfDispChangeHandler}
          onBlur={onDateOfDispBlurHandler}
        />
        <div id="dateOfDispError" className="invalid-feedback">
          {dateErrorMsg?.length
            ? dateErrorMsg
            : "Please enter a valid Date of Disposition"}
        </div>
      </div>
      <div className="row event-status-date-radio-group">
        <div className="custom-control">
          <div className="col-12 copy-md-8 form-group">
            <div className="custom-control custom-radio">
              <input
                name="radio-guide-dateOfDispExactId"
                type="radio"
                id="dateOfDispExactId"
                className="custom-control-input"
                value={eventStatusDateType.EXACT}
                checked={dateOfDispRadio === eventStatusDateType.EXACT}
                onChange={handleDateOfDispChecked}
              />
              <label
                htmlFor="dateOfDispExactId"
                className="custom-control-label"
                id="label_exactDate"
              >
                {eventStatusDateType.EXACT}
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                name="radio-guide-dateOfDispExplanationId"
                type="radio"
                id="dateOfDispExplanationId"
                className="custom-control-input"
                value={eventStatusDateType.EXPLANATION}
                checked={dateOfDispRadio === eventStatusDateType.EXPLANATION}
                onChange={handleDateOfDispChecked}
              />
              <label
                htmlFor="dateOfDispExplanationId"
                className="custom-control-label"
                id="label_explanation"
              >
                {eventStatusDateType.EXPLANATION}
              </label>
            </div>
            {dateOfDispRadio === eventStatusDateType.EXPLANATION ? (
              <div className="event-status-date-explanation-container">
                <label
                  htmlFor="dateOfDispExplanation"
                  className="event-status-date-exp"
                  id="dateOfDispExplanationLabelId"
                >
                  If not exact, provide explanation *
                </label>
                <textarea
                  id="dateOfDispExplanation"
                  onChange={onDateOfDispExplanationChange}
                  className={
                    dateOfDispExpError
                      ? "form-control form-control-15 is-invalid"
                      : "form-control form-control-15"
                  }
                  maxLength={500}
                  minLength={2}
                  onBlur={onBlurDateOfDispExpHandler}
                  rows="3"
                  cols={500}
                  value={dateOfDispExp}
                />
                <div id="dateOfDispExpError" className="invalid-feedback">
                  Please enter an Explanation
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateOfDisposition;
