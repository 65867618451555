import { createSlice } from "@reduxjs/toolkit";
import {
  authInitialState,
  ccb14InitialState,
  logoutModalInitialState,
} from "../state";
import {
  setCCBCandidateId,
  getCCBdata,
  setChecked,
  setIsBack,
  updateCCBdata,
  insertCCBdata,
} from "../reducers/ccb14";
import { setAuthenticators } from "../reducers/auth";
import { setShowLogoutModal } from "../reducers/logout";

export const authSlice = createSlice({
  name: "authenticators",
  initialState: authInitialState,
  reducers: {
    setAuthenticators,
  },
});

export const logoutSlice = createSlice({
  name: "logout",
  initialState: logoutModalInitialState,
  reducers: {
    setShowLogoutModal,
  },
});

export const ccb14Slice = createSlice({
  name: "ccb14",
  initialState: ccb14InitialState,
  reducers: {
    setCCBCandidateId,
    setChecked,
    setIsBack,
  },
  extraReducers: (builder) => {
    getCCBdata(builder);
    updateCCBdata(builder);
    insertCCBdata(builder);
  },
});
