import React, { Component } from "react";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="mm-footer" aria-label="legal footer">
        <div className="mm-footer-legal">
          <div className="row">
            <div className="col-12">
              <div
                className="mm-logo"
                role="img"
                aria-label="MassMutual Logo"
              ></div>
            </div>
            <div
              className="col-12"
              style={{
                marginBottom: "3px",
              }}
              id="copyright-message"
            >
              <p className="legal-copy">
                &copy;{new Date().getFullYear()} Massachusetts Mutual Life
                Insurance Company (MassMutual&reg;), Springfield, MA 01111-0001.
                All rights reserved.
              </p>
            </div>
            <div
              className="col-12"
              style={{
                height: "20px",
              }}
            >
              <ul className="list-inline legal-copy">
                <li className="list-inline-item" id="footer-link-terms">
                  <a
                    target="_blank"
                    href="https://www.massmutual.com/legal/terms-of-use"
                    rel="noopener noreferrer"
                    className="btn-link footer-link"
                  >
                    Terms of Use
                  </a>
                </li>
                <li
                  className="list-inline-item"
                  id="footer-link-consumer-notification-rights"
                >
                  <a
                    target="_blank"
                    href={window.REACT_APP_CONSUMER_AND_NOTIFICATION_RIGHTS_URL}
                    rel="noopener noreferrer"
                    className="btn-link footer-link"
                    id="cnsm-ntf-rg"
                  >
                    Consumer Notification and Rights
                  </a>
                </li>
                <li className="list-inline-item" id="footer-link-privacy">
                  <a
                    target="_blank"
                    href="https://www.massmutual.com/protecting-your-information"
                    rel="noopener noreferrer"
                    className="btn-link footer-link"
                  >
                    Privacy
                  </a>
                </li>
                {/*<li
                  className="caption list-inline-item"
                  style={{ position: "relative", top: "2px" }}
                  id="footer-crn"
                >
                  CRNXXXXXX-XXXXXX
            </li>*/}
              </ul>
            </div>
            <div className="col-12">
              <ul className="list-inline legal-copy">
                <li className="list-inline-item" id="footer-link-personal-info">
                  <a
                    target="_blank"
                    href="https://www.massmutual.com/protecting-your-information/privacy-policy/do-not-sell-or-share"
                    rel="noopener noreferrer"
                    className="btn-link footer-link"
                  >
                    California Do Not Sell or Share My Personal Information
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
