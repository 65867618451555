export const logError = (error) => {
  try {
    console.log("new relic error being produced");

    // eslint-disable-next-line no-undef
    newrelic.noticeError(error, { MMError: "Orbit Candidate error" });
  } catch {
    throw new Error("something went wrong");
  }
};
