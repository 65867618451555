export const initialState = {
  litigationpending: null,
  litigationaward: null,
  litigationsettledprior: null,
  litigationsettledafter: null,
  complaintsettledprior: null,
  complaintsettledafter: null,
  complaint24monthssalesviolation: null,
  complaint24monthsforgery: null,
  violationover15k: null,
  violationaward: null,
  arbitrationsalesviolation: null,
  arbitrationforgery: null,
};

const custCompl_I = {
  mainQuestion:
    "Have you ever been named as a respondent/defendant in an investment-related, consumer-initiated arbitration or civil litigation which alleged that you were involved in one or more sales practice violations and which:",
  questionA: `(a) is still pending, or;`,
  questionB: `(b) resulted in an arbitration award or civil judgment against you, regardless of amount, or;`,
  questionC: `(c) was settled, prior to 05/18/2009, for an amount of $10,000 or more, or;`,
  questionD: `(d) was settled, on or after 05/18/2009, for an amount of $15,000 or more?`,
};

const custCompl_II = {
  mainQuestion: `Have you ever been the subject of an investment-related, consumer-initiated (written or oral) complaint, which alleged that you were involved in one or more sales practice violations, and which:`,
  questionA: `(a) was settled, prior to 05/18/2009, for an amount of $10,000 or more, or;`,
  questionB: `(b) was settled, on or after 05/18/2009, for an amount of $15,000 or more?`,
};

const custCompl_III = {
  mainQuestion: `Within the past twenty four (24) months, have you been the subject of an investment-related, consumer-initiated, written complaint, not otherwise reported under question 14I(2) above, which:`,
  questionA: `(a) alleged that you were involved in one or more sales practice violations and contained a claim for compensatory damages of $5,000 or more (if no damage amount is alleged, the complaint must be reported unless the firm has made a good faith determination that the damages from the alleged conduct would be less than $5,000), or;`,
  questionB: `(b) alleged that you were involved in forgery, theft, misappropriation or conversion of funds or securities?`,
};

const HEADER_IV = `Answer questions (4) and (5) below only for arbitration claims or civil litigation filed on or after 05/18/2009.`;
export const SUB_QUEST_IV = `Have you ever been the subject of an investment-related, consumer-initiated arbitration claim or civil litigation which alleged that you were involved in one or more sales practice violations, and which:`;

const custCompl_IV = {
  header: HEADER_IV,
  subQuestion: SUB_QUEST_IV,
  mainQuestion: `${SUB_QUEST_IV}`,
  questionA: `(a) was settled for an amount of $15,000 or more, or;`,
  questionB: `(b) resulted in an arbitration award or civil judgment against any named respondent(s)/defendant(s), regardless of amount?`,
};

export const SUB_QUEST_V = `Within the past twenty four (24) months, have you been the subject of an investment-related, consumer-initiated arbitration claim or civil litigation not otherwise reported under question 14I(4) above, which:`;

const custCompl_V = {
  header: custCompl_IV.header,
  subQuestion: SUB_QUEST_V,
  mainQuestion: `${SUB_QUEST_V}`,
  questionA: `(a) alleged that you were involved in one or more sales practice violations and contained a claim for compensatory damages of $5,000 or more (if no damage amount is alleged, the arbitration claim or civil litigation must be reported unless the firm has made a good faith determination that the damages from the alleged conduct would be less than $5,000), or;`,
  questionB: `(b) alleged that you were involved in forgery, theft, misappropriation or conversion of funds or securities?`,
};

export const DRPSummaryCustomerComplaintArbitrationCivilLitigationObj = {
  litigationpending: {
    key: "14I(1)(a)",
    mainQuestion: custCompl_I.mainQuestion,
    question: custCompl_I.questionA,
  },
  litigationaward: {
    key: "14I(1)(b)",
    mainQuestion: custCompl_I.mainQuestion,
    question: custCompl_I.questionB,
  },
  litigationsettledprior: {
    key: "14I(1)(c)",
    mainQuestion: custCompl_I.mainQuestion,
    question: custCompl_I.questionC,
  },
  litigationsettledafter: {
    key: "14I(1)(d)",
    mainQuestion: custCompl_I.mainQuestion,
    question: custCompl_I.questionD,
  },
  complaintsettledprior: {
    key: "14I(2)(a)",
    mainQuestion: custCompl_II.mainQuestion,
    question: custCompl_II.questionA,
  },
  complaintsettledafter: {
    key: "14I(2)(b)",
    mainQuestion: custCompl_II.mainQuestion,
    question: custCompl_II.questionB,
  },
  complaint24monthssalesviolation: {
    key: "14I(3)(a)",
    mainQuestion: custCompl_III.mainQuestion,
    question: custCompl_III.questionA,
  },
  complaint24monthsforgery: {
    key: "14I(3)(b)",
    mainQuestion: custCompl_III.mainQuestion,
    question: custCompl_III.questionB,
  },
  violationover15k: {
    key: "14I(4)(a)",
    mainQuestion: custCompl_IV.mainQuestion,
    question: custCompl_IV.questionA,
  },
  violationaward: {
    key: "14I(4)(b)",
    mainQuestion: custCompl_IV.mainQuestion,
    question: custCompl_IV.questionB,
  },
  arbitrationsalesviolation: {
    key: "14I(5)(a)",
    mainQuestion: custCompl_V.mainQuestion,
    question: custCompl_V.questionA,
  },
  arbitrationforgery: {
    key: "14I(5)(b)",
    mainQuestion: custCompl_V.mainQuestion,
    question: custCompl_V.questionB,
  },
};

export const customerComplaintArbitrationCivilLitigation = (disabled) => [
  {
    header: "",
    subHeader: `14I (1). ${custCompl_I.mainQuestion}`,
    questionA: custCompl_I.questionA,
    questionB: custCompl_I.questionB,
    questionC: custCompl_I.questionC,
    questionD: custCompl_I.questionD,
    optionsA: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "litigationpending-y",
      idTwo: "litigationpending-n",
      name: "litigationpending",
      disabled: disabled,
    },
    optionsB: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "litigationaward-y",
      idTwo: "litigationaward-n",
      name: "litigationaward",
      disabled: disabled,
    },
    optionsC: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "litigationsettledprior-y",
      idTwo: "litigationsettledprior-n",
      name: "litigationsettledprior",
      disabled: disabled,
    },
    optionsD: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "litigationsettledafter-y",
      idTwo: "litigationsettledafter-n",
      name: "litigationsettledafter",
      disabled: disabled,
    },
  },
  {
    header: "",
    subHeader: "(2). " + custCompl_II.mainQuestion,
    questionA: custCompl_II.questionA,
    questionB: custCompl_II.questionB,
    optionsA: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "complaintsettledprior-y",
      idTwo: "complaintsettledprior-n",
      name: "complaintsettledprior",
      disabled: disabled,
    },
    optionsB: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "complaintsettledafter-y",
      idTwo: "complaintsettledafter-n",
      name: "complaintsettledafter",
      disabled: disabled,
    },
  },
  {
    header: "",
    subHeader: "(3). " + custCompl_III.mainQuestion,
    questionA: custCompl_III.questionA,
    questionB: custCompl_III.questionB,
    optionsA: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "complaint24monthssalesviolation-y",
      idTwo: "complaint24monthssalesviolation-n",
      name: "complaint24monthssalesviolation",
      disabled: disabled,
    },
    optionsB: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "complaint24monthsforgery-y",
      idTwo: "complaint24monthsforgery-n",
      name: "complaint24monthsforgery",
      disabled: disabled,
    },
  },
  {
    header: custCompl_IV.header,
    subHeader: "(4). " + custCompl_IV.subQuestion,
    questionA: custCompl_IV.questionA,
    questionB: custCompl_IV.questionB,
    optionsA: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "violationover15k-y",
      idTwo: "violationover15k-n",
      name: "violationover15k",
      disabled: disabled,
    },
    optionsB: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "violationaward-y",
      idTwo: "violationaward-n",
      name: "violationaward",
      disabled: disabled,
    },
  },
  {
    header: "",
    subHeader: "(5). " + custCompl_V.subQuestion,
    questionA: custCompl_V.questionA,
    questionB: custCompl_V.questionB,
    optionsA: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "arbitrationsalesviolation-y",
      idTwo: "arbitrationsalesviolation-n",
      name: "arbitrationsalesviolation",
      disabled: disabled,
    },
    optionsB: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "arbitrationforgery-y",
      idTwo: "arbitrationforgery-n",
      name: "arbitrationforgery",
      disabled: disabled,
    },
  },
];

export const StatusDetailsInitialState = {
  matter: "",
  matterError: false,
  isNamedParty: null,
  isNamedPartyError: false,
  customerNames: "",
  customerNamesError: false,
  customerState: "",
  customerStateError: false,
  customerOtherStates: "",
  customerEmployingFirm: "",
  customerEmployingFirmError: false,
  customerAllegations: "",
  customerAllegationsError: false,
  customerDamageAmount: "",
  customerDamageAmountError: false,
  isAllegedDamageAmountExact: null,
  isAllegedDamageAmountExactError: false,
  customerExplanation: "",
  customerExplanationError: false,
};
