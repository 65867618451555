/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "react-blueprint-library";
import InputMask from "react-input-mask";
import moment from "moment";

import { RADIO, dateInputMask, eventStatusDateType } from "../../../constants";
import { logoutActions } from "../../../../../../../store/actions";
import BackButtonModal from "../../../../../Forms/BackButtonModal";

import "./RegActionInvStatusDetails.scss";

const invResolvedType = {
  CLOSED: "Closed without further action",
  CLOSED_REG: "Closed - Regulatory Action Initiated",
  OTHER: "Other",
};

const RegActionInvStatusDetails = ({
  updateCCBForm,
  backFormId,
  nextFormId,
  getSubmitDataValues,
  data,
  groupKey,
  headerText,
}) => {
  const dispatch = useDispatch();
  const [showBackModal, setShowBackModal] = useState(false);

  const [invResolved, setInvResolved] = useState(data?.invResolved || "");
  const [invResolvedOther, setInvResolvedOther] = useState(
    data?.invResolvedOther || ""
  );
  const [invResolvedError, setInvResolvedError] = useState(false);

  const [dateClosedResolvedErrorMsg, setDateClosedResolvedErrorMsg] =
    useState("");
  const [dateClosedResolved, setDateClosedResolved] = useState(
    data?.dateClosedResolved || ""
  );
  const [dateClosedResolvedError, setDateClosedResolvedError] = useState(false);
  const [dateClosedResolvedRadio, setDateClosedResolvedRadio] = useState(
    data?.dateClosedResolvedRadio || ""
  );
  const [dateClosedResolvedExp, setDateClosedResolvedExp] = useState(
    data?.dateClosedResolvedExp || ""
  );
  const [dateClosedResolvedExpError, setDateClosedResolvedExpError] =
    useState(false);

  const [formHasChanged, setFormHasChanged] = useState(false);

  const [invPending, setInvPending] = useState(
    typeof data?.invPending == "boolean" ? data?.invPending : null
  );

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  const isDateClosedResolvedDisabled =
    !invPending &&
    (!dateClosedResolved?.length ||
      dateClosedResolved.includes("_") ||
      dateClosedResolved === dateInputMask.EMPTY ||
      (!dateClosedResolved?.length && !dateClosedResolvedRadio?.length) ||
      !dateClosedResolvedRadio?.length ||
      (dateClosedResolved?.length &&
        dateClosedResolvedRadio?.length &&
        dateClosedResolvedRadio === eventStatusDateType.EXPLANATION &&
        !dateClosedResolvedExp?.length) ||
      (dateClosedResolvedExp?.length && dateClosedResolvedExp?.length < 2));

  const isError =
    dateClosedResolvedError || dateClosedResolvedExpError || invResolvedError;

  useEffect(() => {
    if (isError) {
      setFormHasChanged(true);
    }
  }, [isError]);

  const isNextBtnDisabled =
    (!!(
      isDateClosedResolvedDisabled ||
      !invResolved?.length ||
      (invResolved.length &&
        invResolved === invResolvedType.OTHER &&
        !invResolvedOther?.length)
    ) &&
      invPending !== true) ||
    isError;

  const handleChangeCheckedInvRes = (e) => {
    const { value } = e.target;
    let newValue = value;

    setFormHasChanged(true);
    setInvResolvedError(false);

    if (value !== invResolvedType.OTHER) {
      setInvResolvedOther("");
    }

    setInvResolved(newValue);
  };

  const handleDateClosedResolvedChecked = (e) => {
    const { value } = e.target;
    let newValue = value;

    setFormHasChanged(true);

    setDateClosedResolvedExpError(false);
    if (
      dateClosedResolvedExp.length &&
      dateClosedResolvedRadio !== eventStatusDateType.EXPLANATION
    ) {
      setDateClosedResolvedExp("");
    }

    setDateClosedResolvedRadio(newValue);
  };

  const onDateClosedResolvedOnChangeHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setDateClosedResolved(value);
  };

  const onDateClosedResolvedExplanationChange = ({ target: { value } }) => {
    setFormHasChanged(true);
    setDateClosedResolvedExp(value);
  };

  const onBlurDateClosedResolvedExpHandler = ({ target: { value } }) => {
    return value.trim()?.length < 2 || value.trim()?.length > 500
      ? setDateClosedResolvedExpError(true)
      : setDateClosedResolvedExpError(false);
  };

  const onDateClosedResolvedBlurHandler = ({ target: { value } }) => {
    const today = moment();
    const date = moment(value, "MM/DD/YYYY", true);
    const past = moment(moment().subtract(100, "years").format("MM/DD/YYYY"));

    if (value !== "" && !value.includes("_")) {
      if (moment(value) > today) {
        setDateClosedResolvedError(true);
      } else {
        setDateClosedResolvedError(false);
      }
    } else {
      setDateClosedResolvedError(false);
    }

    if (
      date._i.length === 10 &&
      !date._i.includes("_") &&
      date.isValid() &&
      !value.includes("0000")
    ) {
      if (date > today) {
        setDateClosedResolvedErrorMsg("Date cannot be a future date");
        setDateClosedResolvedError(true);
      } else if (date < past) {
        setDateClosedResolvedErrorMsg("Date cannot be more than 100 years ago");
        setDateClosedResolvedError(true);
      } else {
        setDateClosedResolvedError(false);
      }
    } else {
      setDateClosedResolvedErrorMsg("Please enter a valid date");
      setDateClosedResolvedError(true);
    }
  };

  const handleChangeCheckedInvPending = (e) => {
    const { value } = e.target;
    let newValue = value;

    setFormHasChanged(true);

    switch (value) {
      case RADIO.yes:
        newValue = true;
        break;
      case RADIO.no:
        newValue = false;
        break;
      default:
        newValue = null;
        break;
    }

    if (newValue === false) {
      setDateClosedResolved("");
      setDateClosedResolvedRadio("");
      setDateClosedResolvedExp("");
    }

    setInvResolved("");
    setInvResolvedOther("");
    setInvResolvedError(false);
    setDateClosedResolvedError(false);

    setInvPending(newValue);
  };

  const handleCloseBackModal = () => setShowBackModal(false);

  const isUpdates = formHasChanged || isError;

  const onChangeInvResolvedHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setInvResolvedOther(value);
  };
  const onBlurInvResolvedHandler = ({ target: { value } }) => {
    return value?.trim()?.length < 2 || value.trim()?.length > 500
      ? setInvResolvedError(true)
      : setInvResolvedError(false);
  };

  useEffect(() => {
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: true }));
  }, [isUpdates, dispatch]);

  const handleShowBackModal = () => {
    if (formHasChanged) {
      setShowBackModal(true);
    } else {
      if (
        (data !== null && data && !Object.keys(data).length) ||
        data === null
      ) {
        getSubmitDataValues({ [groupKey]: null }, headerText);
      }
      updateCCBForm(backFormId);
    }
  };

  const handleBackClick = () => {
    if ((data !== null && data && !Object.keys(data).length) || data === null) {
      getSubmitDataValues({ [groupKey]: null }, headerText);
    }
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: false }));
    updateCCBForm(backFormId);
    handleCloseBackModal();
  };

  const handleNext = useCallback(() => {
    const submitData = {
      dateClosedResolved,
      dateClosedResolvedRadio,
      dateClosedResolvedExp,
      invPending,
      invResolved,
      invResolvedOther,
    };

    setFormHasChanged(false);

    getSubmitDataValues({ [`${groupKey}`]: submitData }, headerText);

    updateCCBForm(nextFormId);
  }, [
    updateCCBForm,
    nextFormId,
    getSubmitDataValues,
    headerText,
    dateClosedResolved,
    dateClosedResolvedRadio,
    dateClosedResolvedExp,
    invPending,
    invResolvedOther,
    invResolved,
    groupKey,
  ]);

  return (
    <div className="reg-action-inv-status-det-form-wrapper">
      <div className="row">
        <div className="col-md-12">
          <label className="inv-business" id="invPendingLabelId">
            Is investigation pending?: *
          </label>
        </div>
      </div>
      <div className="row">
        <div className="drp-radio-input-wrapper">
          <div className="col-12 copy-md-4 form-group">
            <div className="custom-control custom-radio">
              <input
                name="invPending"
                type="radio"
                id="invPending_yes"
                className="custom-control-input"
                value={true}
                checked={invPending === true}
                onChange={handleChangeCheckedInvPending}
              />
              <label htmlFor="invPending_yes" className="custom-control-label">
                Yes
              </label>
            </div>

            <div className="custom-control custom-radio">
              <input
                name="invPending"
                type="radio"
                id="invPending_no"
                className="custom-control-input"
                value={false}
                checked={invPending === false}
                onChange={handleChangeCheckedInvPending}
              />
              <label htmlFor="invPending_no" className="custom-control-label">
                No
              </label>
            </div>
            <div id="radio_error" className="invalid-feedback">
              Please choose a response.
            </div>
          </div>
        </div>
      </div>

      {invPending === false && (
        <>
          <div className="sentence-hr">
            <hr />
            <div className="eyebrow-text-wrapper">
              <strong className="eyebrow">Resolution details</strong>
            </div>
            <hr />
          </div>

          <div className="row">
            <div className="add-new-charge-desctiption-label">
              If investigation is not pending, provide the resolution details
              below:
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 event-status-date-wrapper">
              <label
                id="dateClosedResolvedLabelPaiId"
                htmlFor="dateClosedResolvedId"
              >
                <strong>Date Closed/Resolved: *</strong>
              </label>
              <InputMask
                id="dateClosedResolvedId"
                mask="99/99/9999"
                maskChar="_"
                className={`${
                  dateClosedResolvedError
                    ? "form-control is-invalid"
                    : "form-control"
                } event-status-date-input`}
                placeholder="MM/DD/YYYY"
                autoComplete="off"
                required
                value={dateClosedResolved}
                onChange={onDateClosedResolvedOnChangeHandler}
                onBlur={onDateClosedResolvedBlurHandler}
              />
              <div id="dateClosedResolvedError" className="invalid-feedback">
                {dateClosedResolvedErrorMsg?.length
                  ? dateClosedResolvedErrorMsg
                  : "Please enter a valid Date Closed/Resolved"}
              </div>
            </div>

            <div className="row event-status-date-radio-group">
              <div className="custom-control">
                <div className="col-12 copy-md-8 form-group">
                  <div className="custom-control custom-radio">
                    <input
                      name="radio-guide-dateClosedResolvedExactId"
                      type="radio"
                      id="dateClosedResolvedExactId"
                      className="custom-control-input"
                      value={eventStatusDateType.EXACT}
                      checked={
                        dateClosedResolvedRadio === eventStatusDateType.EXACT
                      }
                      onChange={handleDateClosedResolvedChecked}
                    />
                    <label
                      htmlFor="dateClosedResolvedExactId"
                      className="custom-control-label"
                      id="label_exactDate"
                    >
                      {eventStatusDateType.EXACT}
                    </label>
                  </div>
                  <div className="custom-control custom-radio">
                    <input
                      name="radio-guide-dateClosedResolvedExplanationId"
                      type="radio"
                      id="dateClosedResolvedExplanationId"
                      className="custom-control-input"
                      value={eventStatusDateType.EXPLANATION}
                      checked={
                        dateClosedResolvedRadio ===
                        eventStatusDateType.EXPLANATION
                      }
                      onChange={handleDateClosedResolvedChecked}
                    />
                    <label
                      htmlFor="dateClosedResolvedExplanationId"
                      className="custom-control-label"
                      id="label_explanation"
                    >
                      {eventStatusDateType.EXPLANATION}
                    </label>
                  </div>
                  {dateClosedResolvedRadio ===
                  eventStatusDateType.EXPLANATION ? (
                    <div className="event-status-date-explanation-container">
                      <label
                        htmlFor="dateClosedResolvedExplanation"
                        className="event-status-date-exp"
                        id="dateClosedResolvedExplanationLabelId"
                      >
                        If not exact, provide explanation *
                      </label>
                      <textarea
                        id="dateClosedResolvedExplanation"
                        onChange={onDateClosedResolvedExplanationChange}
                        className={
                          dateClosedResolvedExpError
                            ? "form-control form-control-15 is-invalid"
                            : "form-control form-control-15"
                        }
                        maxLength={500}
                        minLength={2}
                        onBlur={onBlurDateClosedResolvedExpHandler}
                        rows="3"
                        cols={500}
                        value={dateClosedResolvedExp}
                      />
                      <div
                        id="dateClosedResolvedExpError"
                        className="invalid-feedback"
                      >
                        Please enter an Explanation
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <label className="court-action">
                How was investigation resolved? *
              </label>
            </div>
          </div>
          <div className="row">
            <div className="drp-radio-input-wrapper">
              <div className="col-12 copy-md-4 form-group">
                <div className="custom-control custom-radio">
                  <input
                    name="federalCourt"
                    type="radio"
                    id="federal"
                    className="custom-control-input"
                    value={invResolvedType.CLOSED}
                    checked={invResolved === invResolvedType.CLOSED}
                    onChange={handleChangeCheckedInvRes}
                  />
                  <label
                    htmlFor="federal"
                    className="custom-control-label"
                    id="federalLabelId"
                  >
                    {invResolvedType.CLOSED}
                  </label>
                </div>

                <div className="custom-control custom-radio">
                  <input
                    name="stateCourt"
                    type="radio"
                    id="stateCourtId"
                    className="custom-control-input"
                    value={invResolvedType.CLOSED_REG}
                    checked={invResolved === invResolvedType.CLOSED_REG}
                    onChange={handleChangeCheckedInvRes}
                  />
                  <label
                    htmlFor="stateCourtId"
                    className="custom-control-label"
                    id="stateCourtLabelId"
                  >
                    {invResolvedType.CLOSED_REG}
                  </label>
                </div>

                <div className="custom-control custom-radio">
                  <input
                    name="otherRadio"
                    type="radio"
                    id="otherRadioId"
                    className="custom-control-input"
                    value={invResolvedType.OTHER}
                    checked={invResolved === invResolvedType.OTHER}
                    onChange={handleChangeCheckedInvRes}
                  />
                  <label
                    htmlFor="otherRadioId"
                    className="custom-control-label"
                    id="otherRadioLabelId"
                  >
                    {invResolvedType.OTHER}
                  </label>
                </div>

                {invResolved === invResolvedType.OTHER ? (
                  <div className="court-action-other">
                    <input
                      type="text"
                      id="otherText"
                      className={
                        invResolvedError
                          ? "form-control form-control-15 is-invalid"
                          : "form-control form-control-15"
                      }
                      maxLength={500}
                      minLength={2}
                      autoComplete="off"
                      value={invResolvedOther}
                      onChange={onChangeInvResolvedHandler}
                      onBlur={onBlurInvResolvedHandler}
                    />
                    <div id="invResolvedError" className="invalid-feedback">
                      Please enter a response
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </>
      )}

      <div className="backbutton-drp-crim row">
        <div className="col-md-12 buttons">
          <span id="regBack" role="presentation" onClick={handleShowBackModal}>
            <strong id="regBackText">Back</strong>
          </span>
          <Button
            label="Next"
            id="regNext"
            onClick={handleNext}
            disabled={!!isNextBtnDisabled}
          />
          {false && <span className="mm-dots-loading"></span>} {/* loading */}
        </div>
      </div>

      <BackButtonModal
        isOpen={showBackModal}
        closeBackModal={handleCloseBackModal}
        backClick={handleBackClick}
      />
    </div>
  );
};

export default RegActionInvStatusDetails;
