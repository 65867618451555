import { fetchCCB, postCCB, putCCB } from "../middlewares";
import { ccb14InitialState, loadingStatus as loading } from "../state";

export const setCCBCandidateId = (state, action) => {
  state.candidateId = action.payload.candidateId;

  return state;
};

export const setChecked = (state = ccb14InitialState, action) => {
  state.loading = loading.IDLE;
  state.data = { ...state.data, ...action.payload };

  return state;
};

export const setIsBack = (state = ccb14InitialState, action) => {
  state.isBack = action.payload;

  return state;
};

export const getCCBdata = (builder) => {
  builder
    .addCase(fetchCCB.pending, (state = ccb14InitialState, action) => {
      if (state.loading === loading.IDLE) {
        state.loading = loading.PENDING;
        state.currentRequestId = action.meta.requestId;
      }

      return state;
    })
    .addCase(fetchCCB.fulfilled, (state = ccb14InitialState, action) => {
      const { requestId } = action.meta;

      const isChecked = (currentValue) => currentValue !== null;

      const isSomeChecked = (obj) => {
        return Object.values(obj).some(isChecked);
      };

      if (
        state.loading === loading.PENDING &&
        state.currentRequestId === requestId
      ) {
        state.loading = loading.SUCCEDED;

        state.isFirstLoad = !isSomeChecked(state.data);

        if (action?.payload) {
          const { candidate, timeadded, ...rest } = action.payload;

          state.data = { ...state.data, ...rest };

          state.isFirstLoad = !isSomeChecked(rest);
        }

        state.currentRequestId = undefined;
      }

      return state;
    })
    .addCase(fetchCCB.rejected, (state = ccb14InitialState, action) => {
      const { requestId } = action.meta;

      if (
        state.loading === loading.PENDING &&
        state.currentRequestId === requestId
      ) {
        state.loading = loading.FAILED;
        state.error = action.error;
        state.currentRequestId = undefined;
      }

      return state;
    });
};

export const updateCCBdata = (builder) => {
  builder
    .addCase(putCCB.pending, (state = ccb14InitialState, action) => {
      if (state.loading === loading.IDLE) {
        state.loading = loading.PENDING;
        state.currentRequestId = action.meta.requestId;
      }

      return state;
    })
    .addCase(putCCB.fulfilled, (state = ccb14InitialState, action) => {
      const { requestId } = action.meta;

      if (
        state.loading === loading.PENDING &&
        state.currentRequestId === requestId
      ) {
        state.loading = loading.SUCCEDED;

        if (action?.payload) {
          const { candidate, timeadded, ...rest } = action.payload;

          state.data = { ...state.data, ...rest };
        }

        state.currentRequestId = undefined;
      }

      return state;
    })
    .addCase(putCCB.rejected, (state = ccb14InitialState, action) => {
      const { requestId } = action.meta;

      if (
        state.loading === loading.PENDING &&
        state.currentRequestId === requestId
      ) {
        state.loading = loading.FAILED;
        state.error = action.error;
        state.currentRequestId = undefined;
      }

      return state;
    });
};

export const insertCCBdata = (builder) => {
  builder
    .addCase(postCCB.pending, (state = ccb14InitialState, action) => {
      if (state.loading === loading.IDLE) {
        state.loading = loading.PENDING;
        state.currentRequestId = action.meta.requestId;
      }

      return state;
    })
    .addCase(postCCB.fulfilled, (state = ccb14InitialState, action) => {
      const { requestId } = action.meta;

      state.isFirstLoad = false;

      if (
        state.loading === loading.PENDING &&
        state.currentRequestId === requestId
      ) {
        state.loading = loading.SUCCEDED;

        if (action?.payload) {
          const { candidate, timeadded, ...rest } = action.payload;

          state.data = { ...state.data, ...rest };
        }

        state.currentRequestId = undefined;
      }

      return state;
    })
    .addCase(postCCB.rejected, (state = ccb14InitialState, action) => {
      const { requestId } = action.meta;

      if (
        state.loading === loading.PENDING &&
        state.currentRequestId === requestId
      ) {
        state.loading = loading.FAILED;
        state.error = action.error;
        state.currentRequestId = undefined;
      }

      return state;
    });
};
