/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Button, FormCustomControl } from "react-blueprint-library";
import BackButtonModal from "../../../../../../Forms/BackButtonModal";
import "./JudgmentLienCourtDetailsForm.scss";
import { courtActionTypes } from "../../constants";
import { logoutActions } from "../../../../../../../../store/actions";
import { useDispatch } from "react-redux";

const JudgmentLienAddDRPCourtDetailsForm = ({
  backFormId,
  nextFormId,
  updateCCBForm,
  updateTempDRP,
  tempJudgmentLienDRP: {
    judgmentLienDetails = {},
    courtDetails = {},
    dispositionDetails = {},
    comments = "",
  },
}) => {
  const dispatch = useDispatch();
  const [courtActionType, setCourtActionType] = useState(
    courtDetails?.courtActionType || ""
  );
  const [courtName, setCourtName] = useState(courtDetails?.courtName || "");
  const [courtNameError, setCourtNameError] = useState(false);
  const [docketOrCaseNumber, setDocketOrCaseNumber] = useState(
    courtDetails?.docketOrCaseNumber || ""
  );
  const [docketOrCaseNumberError, setDocketOrCaseNumberError] = useState(false);
  const [courtLocation, setCourtLocation] = useState(
    courtDetails?.courtLocation || ""
  );
  const [courtLocationError, setCourtLocationError] = useState(false);
  const [isPrivateNumber, setIsPrivateNumber] = useState(
    courtDetails?.isPrivateNumber || false
  );
  const [courtActionTypeOtherText, setCourtActionTypeOtherText] = useState(
    courtDetails?.courtActionTypeOtherText || ""
  );
  const [courtActionTypeOtherTextError, setCourtActionTypeOtherTextError] =
    useState(false);

  const [showBackModal, toggleShowBackModal] = useState(false);
  const handleToggleBackModal = (value) => {
    toggleShowBackModal(value);
  };
  const handleNext = () => {
    const tmpDRP = {
      judgmentLienDetails: {
        ...judgmentLienDetails,
      },
      courtDetails: {
        courtActionType,
        courtActionTypeOtherText,
        courtName,
        courtLocation,
        docketOrCaseNumber,
        isPrivateNumber,
      },
      dispositionDetails: { ...dispositionDetails },
      comments,
    };
    updateTempDRP(tmpDRP);
    updateCCBForm(nextFormId);
  };
  const handleBack = () => {
    updateCCBForm(backFormId);
  };
  const handleCourtActionTypeChange = ({ target: { id, value } }) => {
    if (value !== courtActionTypes.OTHER) {
      setCourtActionTypeOtherText("");
      setCourtActionTypeOtherTextError(false);
    }
    setCourtActionType(value);
  };
  const handleTextInputChange = ({ target: { id, value } }) => {
    switch (id) {
      case "courtName":
        return setCourtName(value);
      case "courtLocation":
        return setCourtLocation(value);
      case "docketOrCaseNumber":
        return setDocketOrCaseNumber(value);
      case "courtActionTypeOtherText":
        return setCourtActionTypeOtherText(value);
      default:
        break;
    }
  };
  const onBlurTextInput = ({ target: { id, value } }) => {
    switch (id) {
      case "courtName":
        return setCourtNameError(value.length < 2);
      case "courtLocation":
        return setCourtLocationError(value.length < 2);
      case "docketOrCaseNumber":
        return setDocketOrCaseNumberError(value.length < 2);
      case "courtActionTypeOtherText":
        return setCourtActionTypeOtherTextError(value.length < 2);
      default:
        break;
    }
  };
  const handleCheckBoxClick = ({ target: { id, value, checked } }) => {
    setIsPrivateNumber(checked);
  };
  const isError =
    courtNameError ||
    docketOrCaseNumberError ||
    courtLocationError ||
    courtActionTypeOtherTextError;

  const isUpdates =
    courtName?.length ||
    docketOrCaseNumber?.length ||
    courtLocation?.length ||
    courtActionType?.length ||
    courtActionTypeOtherText?.length ||
    isError;
  const isNextDisabled =
    courtName?.length < 2 ||
    docketOrCaseNumber?.length < 2 ||
    courtLocation?.length < 2 ||
    courtActionType?.length < 1 ||
    (courtActionType === courtActionTypes.OTHER &&
      courtActionTypeOtherText?.length < 2) ||
    isError;

  useEffect(() => {
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: true }));
  }, []);
  return (
    <div className="judgment-lien-court-details-form-wrapper">
      <br />
      <div className="row">
        <div className="col-md-12">
          <label id="judgmentLienTypeLabelId" className="field-label">
            Court action brought in: *
          </label>
        </div>
      </div>
      <div className="row" style={{ marginLeft: "15px" }}>
        <div className="drp-radio-input-wrapper">
          <div className="col-12 copy-md-4 form-group">
            <div className="custom-control custom-radio">
              <input
                name="courtActionTypeFederal"
                type="radio"
                id="courtActionTypeFederal"
                className="custom-control-input"
                value={courtActionTypes.FEDERAL}
                checked={courtActionType === courtActionTypes.FEDERAL}
                onChange={handleCourtActionTypeChange}
              />
              <label
                id="courtActionTypeFederalLabel"
                htmlFor="courtActionTypeFederal"
                className="custom-control-label"
              >
                {courtActionTypes.FEDERAL}
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                name="courtActionTypeState"
                type="radio"
                id="courtActionTypeState"
                className="custom-control-input"
                value={courtActionTypes.STATE}
                checked={courtActionType === courtActionTypes.STATE}
                onChange={handleCourtActionTypeChange}
              />
              <label
                id="courtActionTypeStateLabel"
                htmlFor="courtActionTypeState"
                className="custom-control-label"
              >
                {courtActionTypes.STATE}
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                name="courtActionTypeForeign"
                type="radio"
                id="courtActionTypeForeign"
                className="custom-control-input"
                value={courtActionTypes.FOREIGN}
                checked={courtActionType === courtActionTypes.FOREIGN}
                onChange={handleCourtActionTypeChange}
              />
              <label
                id="courtActionTypeForeignLabel"
                htmlFor="courtActionTypeForeign"
                className="custom-control-label"
              >
                {courtActionTypes.FOREIGN}
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                name="courtActionTypeOther"
                type="radio"
                id="courtActionTypeOther"
                className="custom-control-input"
                value={courtActionTypes.OTHER}
                checked={courtActionType === courtActionTypes.OTHER}
                onChange={handleCourtActionTypeChange}
              />
              <label
                id="courtActionTypeOtherLabel"
                htmlFor="courtActionTypeOther"
                className="custom-control-label"
              >
                {courtActionTypes.OTHER}
              </label>
            </div>
            <div id="courtActionTypeError" className="invalid-feedback">
              Please enter a Court Type
            </div>
            {courtActionType === courtActionTypes.OTHER && (
              <div>
                <input
                  id="courtActionTypeOtherText"
                  type="text"
                  className={
                    courtActionTypeOtherTextError
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  onChange={handleTextInputChange}
                  onBlur={onBlurTextInput}
                  value={courtActionTypeOtherText}
                  maxLength={500}
                  autoComplete="off"
                ></input>
                <div
                  id="courtActionTypeOtherTextError"
                  className="invalid-feedback"
                >
                  Please enter a response
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-md-12">
          <label
            id="courtNameLabel"
            className="field-label"
            htmlFor="courtName"
          >
            Name of court (Federal, State, Foreign or Other): *
          </label>
          <input
            id="courtName"
            type="text"
            className={
              courtNameError ? "form-control is-invalid" : "form-control"
            }
            onChange={handleTextInputChange}
            onBlur={onBlurTextInput}
            value={courtName}
            maxLength={100}
            autoComplete="off"
          ></input>
          <div id="courtNameError" className="invalid-feedback">
            Please enter Name of court
          </div>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-md-12">
          <label
            id="courtLocationLabel"
            className="field-label"
            htmlFor="courtLocation"
          >
            Location of court (City or County <span>and</span> State or
            Country): *
          </label>
          <input
            id="courtLocation"
            type="text"
            className={
              courtLocationError ? "form-control is-invalid" : "form-control"
            }
            onChange={handleTextInputChange}
            onBlur={onBlurTextInput}
            value={courtLocation}
            maxLength={100}
            autoComplete="off"
          ></input>
          <div id="courtLocationError" className="invalid-feedback">
            Please enter Location of court
          </div>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-md-12">
          <label
            id="docketOrCaseNumberLabel"
            className="field-label"
            htmlFor="docketOrCaseNumber"
          >
            Docket/Case #: *
          </label>
          <input
            id="docketOrCaseNumber"
            type="text"
            className={
              docketOrCaseNumberError
                ? "form-control is-invalid"
                : "form-control"
            }
            onChange={handleTextInputChange}
            onBlur={onBlurTextInput}
            value={docketOrCaseNumber}
            maxLength={100}
            autoComplete="off"
          ></input>
          <div id="docketOrCaseNumberError" className="invalid-feedback">
            Please enter Docket/Case #
          </div>
        </div>
      </div>
      <br />
      <div
        className="row form-custom-control-wrapper"
        style={{ marginLeft: "0" }}
      >
        <FormCustomControl
          id="isPrivateNumber"
          inputType="checkbox"
          label="Check this box if the Docket/Case # is your SSN, a Bank Card number, or a Personal Identification Number."
          onChange={handleCheckBoxClick}
          checked={isPrivateNumber}
        />
      </div>
      <div className="row backbutton-drp-judgment-lien">
        <div className="col-md-12 buttons">
          <span
            id="judgmentLienAddDRPBack"
            className="back-button"
            onClick={() => {
              isUpdates ? handleToggleBackModal(true) : handleBack();
            }}
          >
            <strong id="judgmentLienBack">Back</strong>
          </span>
          <Button
            disabled={isNextDisabled}
            id="judgmentLienAddDRPNext"
            style={{ marginRight: "10px" }}
            onClick={handleNext}
          >
            Next
          </Button>
        </div>
      </div>
      <BackButtonModal
        isOpen={showBackModal}
        closeBackModal={() => {
          handleToggleBackModal(false);
        }}
        backClick={handleBack}
      />
    </div>
  );
};

export default JudgmentLienAddDRPCourtDetailsForm;
