import Moment from "moment";
import React, { Component } from "react";
import {
  deleteResidentialHistory,
  insertResidentialHistory,
  updateResidentialHistory,
} from "../../../api/residentialHistory";
import "../../../assets/stylesheets/StartApplication/CAS/ResidentialHistory.scss";
import { CONTRACT_TYPE_DESCRIPTIONS } from "../../../constants/Contract";
import { FORM_ID } from "../../../constants/Forms";
import { UtagTracking } from "../../../utils/utag-tracking";
import NavigationButtons from "../../SharedComponents/NavigationButtons";
import AddAddressModal from "./AddResidentialModal";
import AddedAddressToast from "./AddedAddressToast";
import DeletedAddressToast from "./DeletedAddressToast";
import ResidentialHistoryTable from "./ResidentialHistoryTable";

class ResidentialHistoryForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_modal_open: false,
      address_history: [],
      edit_address: "",
      show_toast: false,
      residential_history_total: "",
      is_there_current: false,
      address_history_progress_days: 0,
      edit_mode: false,
      show_experience_deleted_alert: false,
      show_experience_added_alert: false,
      is_next_disabled: true,
      isEditToast: false,
      usps_modal_is_loading: false,
      current_address_id: -1,
    };
    this.handleAddAddress = this.handleAddAddress.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleShowBackModal = this.handleShowBackModal.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.addToAddressHistory = this.addToAddressHistory.bind(this);
    this.editExistingAddress = this.editExistingAddress.bind(this);
    this.handleEditAddress = this.handleEditAddress.bind(this);
    this.handleShowToast = this.handleShowToast.bind(this);
    this.handleHideToast = this.handleHideToast.bind(this);
    this.handleCalculateTimeline = this.handleCalculateTimeline.bind(this);
    this.deleteData = this.deleteData.bind(this);
    this.closeDeletedAlert = this.closeDeletedAlert.bind(this);
    this.showDeletedAlert = this.showDeletedAlert.bind(this);
    this.enableNextButton = this.enableNextButton.bind(this);
    this.organizeData = this.organizeData.bind(this);
  }

  organizeData(_addressHistory) {
    let addressHistory = _addressHistory;

    //sort addresshistory by begindate
    if (addressHistory.length > 0) {
      addressHistory = addressHistory.sort(
        (a, b) => new Date(b.begindate) - new Date(a.begindate)
      );
    }

    //clear overlapping timelines that already exists
    for (let z = 0; z < addressHistory.length; z++) {
      addressHistory[z].overlappingRange = null;
    }

    for (let i = 0; i < addressHistory.length; i++) {
      if (i > 0) {
        // check for overlapping timelines
        for (let o = 0; o < addressHistory.length; o++) {
          if (i !== o) {
            if (
              Moment(addressHistory[i].begindate) >=
                Moment(addressHistory[o].begindate) &&
              Moment(addressHistory[i].enddate) <=
                Moment(addressHistory[o].enddate)
            ) {
              addressHistory[i].overlappingRange = {
                begindate: addressHistory[o].begindate,
                enddate: addressHistory[o].enddate,
              };
            } else {
              if (typeof addressHistory[i].overlappingRange === "undefined") {
                addressHistory[i].overlappingRange = null;
              }
            }
          }
        }

        // check for gaps between each item
        let lastMoveOutDate = Moment(addressHistory[i].enddate);
        let currentMoveInDate = Moment(addressHistory[i - 1].begindate);
        addressHistory[i].istheregap =
          currentMoveInDate.diff(lastMoveOutDate, "days") > 1;
      } else {
        addressHistory[i].istheregap = false;
        addressHistory[i].overlappingRange = null;
      }
    }

    return addressHistory;
  }

  async componentDidMount() {
    let fetchedResHistory = this.props.residentialHistory;
    if (fetchedResHistory !== undefined) {
      let organizedData = this.organizeData(fetchedResHistory);

      await this.setState({ address_history: organizedData });
      this.handleCalculateTimeline(organizedData);
      // await this.handleShowToast();
      await this.enableNextButton();
    }
    UtagTracking.sendPageNameView(
      "residentialhistoryform",
      this.props.candidateId,
      this.props.agencynumber,
      this.props.contracttypeid
    );
  }

  async addToAddressHistory(address) {
    this.setState({ usps_modal_is_loading: true });
    let data = {
      candidateid: this.props.candidateId,
      address1: address.address1,
      address2: address.address2,
      city: address.city,
      state: address.state,
      zipcode: address.zipcode,
      country: address.country,
      currentaddress: address.currentaddress,
      begindate: Moment(address.begindate).format("YYYY/MM/DD"),
      enddate: address.currentaddress
        ? null
        : Moment(address.enddate).format("YYYY/MM/DD"),
      urbanization: address.state === "PR" ? address.urbanization : null,
      county: address.county,
    };

    //saving to db and getting residentialhistoryid back from api
    let residentialhistoryid = await insertResidentialHistory(
      this.props.subId,
      data
    );
    address.residentialhistoryid = residentialhistoryid;

    //did this because this function is having issues defining a variable from state value
    let newAddressHistory = JSON.parse(
      JSON.stringify(this.state.address_history)
    );

    //insert address into the top of addresshistory
    newAddressHistory.unshift(address);

    if (address.currentaddress) {
      this.setState({
        is_there_current: true,
        current_address_id: address.residentialhistoryid,
      });
    }
    let organizedData = this.organizeData(newAddressHistory);

    await this.setState({
      address_history: organizedData,
      isEditToast: false,
    });

    this.props.saveResHistory("residentialHistory", organizedData);

    this.closeModal();
    this.handleCalculateTimeline(newAddressHistory);
    await this.handleShowToast();
    await this.enableNextButton();

    window.scrollTo(0, 0);

    this.setState({ usps_modal_is_loading: false });
  }

  async editExistingAddress(address) {
    this.setState({ usps_modal_is_loading: true });
    let data = {
      residentialhistoryid: address.residentialhistoryid,
      address1: address.address1,
      address2: address.address2,
      city: address.city,
      state: address.state,
      zipcode: address.zipcode,
      country: address.country,
      currentaddress: address.currentaddress,
      begindate: Moment(address.begindate).format("YYYY/MM/DD"),
      enddate: address.currentaddress
        ? null
        : Moment(address.enddate).format("YYYY/MM/DD"),
      urbanization: address.state === "PR" ? address.urbanization : null,
      county: address.county,
    };

    if (address.currentaddress) {
      this.setState({ current_address_id: address.residentialhistoryid });
    }

    await updateResidentialHistory(
      this.props.subId,
      data.residentialhistoryid,
      data
    );

    let stringifiedSearch = JSON.stringify(
      this.state.address_history.find(
        (o) => o.residentialhistoryid === address.residentialhistoryid
      )
    );
    let stringifiedJSON = JSON.stringify(this.state.address_history);
    let replacedStringifiedJSON = stringifiedJSON.replace(
      stringifiedSearch,
      JSON.stringify(address)
    );

    let newAddressHistory = JSON.parse(replacedStringifiedJSON);

    let organizedData = this.organizeData(newAddressHistory);

    this.setState({
      is_there_current: address.currentaddress,
      address_history: organizedData,
      isEditToast: true,
    });

    this.props.saveResHistory("residentialHistory", organizedData);

    this.closeModal();
    if (address.state_is_changed === undefined) {
      this.handleShowToast();
    }

    await this.enableNextButton();
    window.scrollTo(0, 0);

    this.setState({ usps_modal_is_loading: false });
  }
  async deleteData(address, item) {
    if (address.currentaddress) {
      await this.setState({ is_there_current: false, current_address_id: -1 });
    }

    await deleteResidentialHistory(
      this.props.subId,
      address.residentialhistoryid
    );
    let newAddressHistory = this.state.address_history.filter(
      (addressHistItem) => addressHistItem !== address
    );

    let organizedData = this.organizeData(newAddressHistory);

    await this.setState({ address_history: organizedData });
    this.props.saveResHistory("residentialHistory", organizedData);

    await this.showDeletedAlert();
    this.handleCalculateTimeline(newAddressHistory);
    this.enableNextButton();
    window.scrollTo(0, 0);
  }

  async showDeletedAlert() {
    var toast_banner = document.getElementById("emphist_deleted_alert");
    toast_banner.classList.add("show");
    toast_banner.classList.remove("d-none");
    await this.setState({ show_experience_deleted_alert: true });
  }

  async enableNextButton() {
    let addHisButton = document.getElementById("addResidentialHistoryBtn");

    let isTodayFilled = false;

    for (let i = 0; i < this.state.address_history.length; i++) {
      if (this.state.address_history[i].currentaddress === true) {
        isTodayFilled = true;
        //to disble checkbox in add address modal
        this.setState({
          current_address_id:
            this.state.address_history[i].residentialhistoryid,
        });
      }
    }

    let isResHistoryComplete =
      addHisButton === null &&
      this.state.address_history.length > 0 &&
      isTodayFilled;

    await this.setState({ is_next_disabled: !isResHistoryComplete });
    this.props.isFormComplete(isResHistoryComplete);
  }

  async closeDeletedAlert() {
    var toast_banner = document.getElementById("emphist_deleted_alert");
    toast_banner.classList.remove("show");
    toast_banner.classList.add("d-none");
    await this.setState({ show_experience_deleted_alert: false });
  }

  closeModal() {
    this.setState({ is_modal_open: false });
  }

  handleShowBackModal() {
    this.props.toggleForm(FORM_ID.PERSONAL_INFO_ADDITIONAL_INFO);
  }
  handleNext() {
    if (
      this.props.contracttypedesc === CONTRACT_TYPE_DESCRIPTIONS.COLLEGE_INTERN
    ) {
      this.props.toggleForm(FORM_ID.EDUCATION);
    } else {
      this.props.toggleForm(FORM_ID.BUSINESS_INFO);
    }
    this.props.isFormComplete(true);
  }
  async handleShowToast() {
    setTimeout(() => {
      var toast_show_banner = document.getElementById("address_added_alert");
      toast_show_banner.classList.add("show");
      toast_show_banner.classList.remove("d-none");
    }, 100);
    await this.setState({ show_experience_added_alert: true });
  }

  handleHideToast() {
    var toast_banner = document.getElementById("address_added_alert");
    toast_banner.classList.remove("show");
    toast_banner.classList.add("d-none");

    this.setState({ show_experience_added_alert: false });
  }

  handleAddAddress() {
    this.setState({ edit_address: "", is_modal_open: true });
  }

  handleEditAddress(address) {
    this.setState({
      edit_address: address,
      is_modal_open: true,
      edit_mode: true,
    });
  }

  handleCalculateTimeline(addressHistory) {
    let totalDays = 0;

    for (let i = 0; i < addressHistory.length; i++) {
      let moveInDate = Moment(addressHistory[i].begindate);
      let moveOutDate = Moment(addressHistory[i].enddate);
      totalDays = totalDays + moveOutDate.diff(moveInDate, "days");
    }
    this.setState({ address_history_progress_days: totalDays });
    this.props.isFormComplete(totalDays > 1826);
  }

  updateCurrentAddressId = (val) => {
    this.setState({ current_address_id: val });
  };

  render() {
    return (
      <div id="residentialHistoryForm">
        <AddedAddressToast
          edit={this.state.isEditToast}
          showToast={this.state.show_experience_added_alert}
          closeToast={this.handleHideToast}
        ></AddedAddressToast>
        <DeletedAddressToast
          showToast={this.state.show_experience_deleted_alert}
          closeDeletedToast={this.closeDeletedAlert}
        ></DeletedAddressToast>
        <h1 id="residentialHistoryHeader">Residential History</h1>

        <div className="residential-history-instructions">
          <div className="row">
            <div className="col-md-12">
              <h3>
                <strong id="residentialHistoryInstruction1">
                  Please provide your residential history for the last 5 years
                </strong>
              </h3>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10">
              <span id="residentialHistoryInstruction2">
                You will be required to correct any gaps in your history. A P.O.
                Box or private mailbox number (PMB) will not be accepted for
                your residential address.
              </span>
            </div>
          </div>
        </div>
        <div className="row residential-history-grid">
          <div className="col-md-12">
            <div style={{ paddingBottom: "20px" }}>
              <hr />
              <span onClick={this.handleAddAddress} id="initialAddHistory">
                <span className="icon-add"></span>
                <strong>Add Address</strong>
              </span>
            </div>
            <ResidentialHistoryTable
              data={this.state.address_history}
              showAddModal={this.handleAddAddress}
              deleteData={this.deleteData}
              reviewEditClick={this.handleEditAddress}
            ></ResidentialHistoryTable>
          </div>
        </div>

        {this.state.is_modal_open && (
          <AddAddressModal
            isOpen={this.state.is_modal_open}
            closeModal={this.closeModal}
            addToAddressHistory={this.addToAddressHistory}
            editAddress={this.state.edit_address}
            editExistingAddress={this.editExistingAddress}
            isThereCurrent={this.state.is_there_current}
            addresses={this.state.address_history}
            usps_modal_is_loading={this.state.usps_modal_is_loading}
            current_address_id={this.state.current_address_id}
            updateCurrentAddressId={this.updateCurrentAddressId}
          />
        )}

        <NavigationButtons
          onBack={this.handleShowBackModal}
          onNext={this.handleNext}
          isLoading={false}
          isDisabled={this.state.is_next_disabled}
          nxtbtnid={"resHistoryNext"}
          bckbtnid={"resHistoryBack"}
        />
      </div>
    );
  }
}

export default ResidentialHistoryForm;
