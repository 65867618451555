import { fetchWrapper } from "../../helpers";
import { RequestMethodTypes } from "../../helpers/constants";

export const insertCustomerComplaintDRP = async (oktasubid, data) => {
  const {
    data: { ccbdrpid },
  } = await fetchWrapper({
    method: RequestMethodTypes.PUT,
    url: `drp/${oktasubid}/complaint`,
    data,
  });

  return ccbdrpid;
};
export const updateCustomerComplaintDRP = async (oktasubid, data) => {
  const { ccbdrpid } = await fetchWrapper({
    method: RequestMethodTypes.POST,
    url: `drp/${oktasubid}/complaint`,
    data,
  });

  return ccbdrpid;
};

/**
 * PUT /v1/candidate/{oktaSubId}/ccb14/drp/{drpTypeId}/cleanup
 * @param {string} oktaSubId oktaSubId
 * @param {string} drpTypeId drpTypeId
 * @body { data: { ind: [qk1, qk2], org: [qk1, qk3] }}
 * @returns {Promise<Object>}
 */
export const cleanupCCB14Drp = async ({ oktaSubId, drpTypeId, data }) => {
  const ccb = await fetchWrapper({
    method: RequestMethodTypes.PUT,
    url: `${oktaSubId}/ccb14/drp/${drpTypeId}/cleanup`,
    data: data,
  });

  return ccb?.data;
};

export const getCustomerComplaintDRP = async (oktasubid) => {
  const response = await fetchWrapper({
    method: RequestMethodTypes.GET,
    url: `drp/${oktasubid}/complaint`,
  });

  return response;
};

export const deleteCustomerComplaintDRP = async (oktasubid, ccbdrpid) => {
  const response = await fetchWrapper({
    method: RequestMethodTypes.POST,
    url: `drp/${oktasubid}/deletecomplaintdrp`,
    data: {
      ccbdrpid,
    },
  });

  return response;
};
