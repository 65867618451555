import React, { Component } from "react";
import { Button } from "react-blueprint-library";
import "react-step-progress-bar/styles.css";
import { withOktaAuth } from "@okta/okta-react";
import {
  updateContractStatus,
  getDashboardMultiContract,
} from "../../../api/makeAPIRequest";
import {
  CONTRACT_STATUS_ID,
  CONTRACT_TYPE_DESCRIPTIONS,
} from "../../../constants/Contract";

class SentToCandidate extends Component {
  constructor(props) {
    super(props);
    this.getStartedClick = this.getStartedClick.bind(this);
    this.dashboardClick = this.dashboardClick.bind(this);
    this.state = {
      oktaSubId: this.props.authState.accessToken.claims.uid,
      candidate_email: this.props.authState.idToken.claims.email,
      can_name: this.props.authState.idToken.claims.name.split(", "),
      contract_id: this.props.contractid,
      nyregs: this.props.nyregs,
      hasMultiContracts: false,
    };
  }

  async getStartedClick() {
    await updateContractStatus(
      this.state.oktaSubId,
      this.state.contract_id,
      this.state.candidate_email,
      CONTRACT_STATUS_ID.APPLICATION_IN_PROGRESS
    );

    const delay = (ms) => new Promise((res) => setTimeout(res, ms));
    await delay(750);

    //go to application page
    window.location = `/startapplication?contract=${this.state.contract_id}`;
  }

  dashboardClick() {
    window.location = "/";
  }

  render() {
    return (
      <div>
        {(this.props.contract ===
          CONTRACT_TYPE_DESCRIPTIONS.INDIVIDUAL_BROKER ||
          this.props.contract === CONTRACT_TYPE_DESCRIPTIONS.BROKER_CORP) && (
          <div id="IndividualBrokerInvitationSent">
            <div className="row">
              <div className="col-md-9" id="welcome-message">
                <h3 style={{ marginBottom: "14px" }} id="welcome-message-text">
                  Welcome to MassMutual. You have been invited to complete the
                  following application - {this.props.contract} with Agency #
                  {this.props.agencynumber}.
                </h3>
              </div>
            </div>
            {this.props.contract ===
              CONTRACT_TYPE_DESCRIPTIONS.INDIVIDUAL_BROKER && (
              <div
                className="copy-md"
                style={{ marginBottom: "24px", marginTop: "10px" }}
                id="get-started-text"
              >
                Once you get started, your progress will be saved as you move
                through the pages and you will be able to return to complete
                your application where you left off.
              </div>
            )}

            <h4 id="askd-apltn">In the application you'll be asked to:</h4>
            <div className="row">
              <div className="col-md-7">
                <ul id="IndividualBrokerChecklist" className="list-unstyled">
                  <li>
                    <div>
                      <span className="icon-check-circle"></span>
                    </div>
                    <div id="authorize-credit-check">
                      <span className="copy-md" id="bckgrnd-nd-cc">
                        Authorize a background and credit check
                      </span>
                    </div>
                  </li>
                  <li>
                    <div>
                      <span className="icon-check-circle"></span>
                    </div>
                    <div id="personal-info">
                      <span className="copy-md" id="dmgrphc">
                        {this.props.contract ===
                          CONTRACT_TYPE_DESCRIPTIONS.INDIVIDUAL_BROKER && (
                          <>
                            Provide personal information which may include your
                            demographic, contact, residential address, business
                            address, and Broker Dealer registration status
                          </>
                        )}
                        {this.props.contract ===
                          CONTRACT_TYPE_DESCRIPTIONS.BROKER_CORP && (
                          <>
                            Provide personal information which may include your
                            contact, residential address, and Broker Dealer
                            registration status
                          </>
                        )}
                      </span>
                    </div>
                  </li>
                  <li>
                    <div>
                      <span className="icon-check-circle"></span>
                    </div>
                    <div id="ccb-info">
                      <span className="copy-md" id="bndinfo">
                        Provide character, credit, and bonding information
                      </span>
                    </div>
                  </li>
                  <li>
                    <div>
                      <span className="icon-check-circle"></span>
                    </div>
                    <div id="eoc-info">
                      <span className="copy-md" id="omssn-cvrg">
                        Provide your errors and omissions coverage status
                      </span>
                    </div>
                  </li>
                  <li>
                    <div>
                      <span className="icon-check-circle"></span>
                    </div>
                    <div id="banking-info">
                      <span className="copy-md" id="drctdpst">
                        Provide your banking information to set up direct
                        deposit
                      </span>
                    </div>
                  </li>

                  <p style={{ padding: "20px 0px 20px 0px" }}>
                    {this.props.multiContracts > 1 && (
                      <span
                        id="return-to-dashboard-btn"
                        onClick={this.dashboardClick}
                        style={{ paddingRight: "10px", cursor: "pointer" }}
                      >
                        <span className="icon-xs icon-caret-spin-left"></span>
                        <strong
                          id="rtn-to-dashboard-link"
                          className="footer-link"
                        >
                          {" "}
                          Back to Dashboard
                        </strong>
                      </span>
                    )}

                    <Button
                      onClick={this.getStartedClick}
                      id="getstarted-btton"
                    >
                      Get Started
                    </Button>
                  </p>
                </ul>
              </div>
            </div>
          </div>
        )}

        {this.props.contract === CONTRACT_TYPE_DESCRIPTIONS.MEMBER_CORP && (
          <div id="IndividualBrokerInvitationSent">
            <div className="row">
              <div className="col-md-9" id="welcome-message">
                <h3 style={{ marginBottom: "14px" }} id="welcome-message-text">
                  Welcome to MassMutual. You have been invited to complete the
                  following application - {this.props.contract} with Agency #
                  {this.props.agencynumber}.
                </h3>
              </div>
            </div>
            <h4 id="askd-apltn">In the application you'll be asked to:</h4>
            <div className="row">
              <div className="col-md-7">
                <ul id="IndividualBrokerChecklist" className="list-unstyled">
                  <li>
                    <div>
                      <span className="icon-check-circle"></span>
                    </div>
                    <div id="authorize-credit-check">
                      <span className="copy-md" id="bckgrnd-nd-cc">
                        Authorize a background and credit check
                      </span>
                    </div>
                  </li>
                  <li>
                    <div>
                      <span className="icon-check-circle"></span>
                    </div>
                    <div id="personal-info">
                      <span className="copy-md" id="dmgrphc">
                        Provide personal information which may include your
                        contact, residential address, and Broker Dealer
                        registration status
                      </span>
                    </div>
                  </li>
                  <li>
                    <div>
                      <span className="icon-check-circle"></span>
                    </div>
                    <div id="ccb-info">
                      <span className="copy-md" id="bndinfo">
                        Provide character, credit, and bonding information
                      </span>
                    </div>
                  </li>
                  <li>
                    <div>
                      <span className="icon-check-circle"></span>
                    </div>
                    <div id="eoc-info">
                      <span className="copy-md" id="omssn-cvrg">
                        Provide your errors and omissions coverage status
                      </span>
                    </div>
                  </li>

                  <p style={{ padding: "20px 0px 20px 0px" }}>
                    {this.props.multiContracts > 1 && (
                      <span
                        id="return-to-dashboard-btn"
                        onClick={this.dashboardClick}
                        style={{ paddingRight: "10px", cursor: "pointer" }}
                      >
                        <span className="icon-xs icon-caret-spin-left"></span>
                        <strong
                          id="rtn-to-dashboard-link"
                          className="footer-link"
                        >
                          {" "}
                          Back to Dashboard
                        </strong>
                      </span>
                    )}

                    <Button
                      onClick={this.getStartedClick}
                      id="getstarted-btton"
                    >
                      Get Started
                    </Button>
                  </p>
                </ul>
              </div>
            </div>
          </div>
        )}

        {/* Career Agent / Career Sampling / College Intern / Alternate Agent contracts */}
        {(this.props.contract === CONTRACT_TYPE_DESCRIPTIONS.CAREER_AGENT ||
          this.props.contract === CONTRACT_TYPE_DESCRIPTIONS.CAREER_SAMPLING ||
          this.props.contract === CONTRACT_TYPE_DESCRIPTIONS.COLLEGE_INTERN ||
          this.props.contract ===
            CONTRACT_TYPE_DESCRIPTIONS.ALTERNATE_AGENT) && (
          <div id="CareerAgentInvitationSent">
            <div className="row">
              <div className="col-md-9">
                <h3 style={{ marginBottom: "14px" }} id="welcome-mm-text">
                  Welcome to MassMutual. You have been invited to complete the
                  following application - {this.props.contract}{" "}
                  {this.props.mmlis === true &&
                    this.props.financingonly === true && (
                      <>+MMLIS, Financing </>
                    )}
                  {this.props.financingonly === true &&
                    this.props.mmlis === false && <>+Financing </>}
                  {this.props.financingonly === false &&
                    this.props.mmlis === true && <>+MMLIS </>}
                  with Agency #{this.props.agencynumber}
                </h3>
              </div>
            </div>

            <h4 id="mm-app">In the application you'll be asked to:</h4>
            <div className="row">
              <div className="col-md-7">
                <ul id="CareerAgentChecklist" className="list-unstyled">
                  <li>
                    <div>
                      <span className="icon-check-circle"></span>
                    </div>
                    <div id="cas-credit-check">
                      <span className="copy-md" id="crdt">
                        Authorize a background and credit check
                      </span>
                    </div>
                  </li>
                  {this.props.contract ===
                    CONTRACT_TYPE_DESCRIPTIONS.COLLEGE_INTERN && (
                    <>
                      <br></br>
                      <li>
                        <div>
                          <span className="icon-check-circle"></span>
                        </div>
                        <div id="cas-personal-information">
                          <span className="copy-md" id="rsdtlhstry">
                            Provide personal information which may include your
                            contact and 5 years of residential history
                          </span>
                        </div>
                      </li>
                    </>
                  )}
                  {this.props.contract !==
                    CONTRACT_TYPE_DESCRIPTIONS.COLLEGE_INTERN && (
                    <>
                      <br></br>
                      <li>
                        <div>
                          <span className="icon-check-circle"></span>
                        </div>
                        <div id="cas-personal-information">
                          <span className="copy-md" id="rsdtlhstry">
                            Provide personal information which may include your
                            contact and business address as well as 5 years of
                            residential history
                          </span>
                        </div>
                      </li>
                    </>
                  )}

                  <li>
                    <div>
                      <span className="icon-check-circle"></span>
                    </div>
                    <div id="cas-emp-his-psr">
                      <span className="copy-md" id="cas-emp-his">
                        Provide 10 years of employment history as well as your{" "}
                        {this.props.nyregs === false ? (
                          <>personal sales record (PSR)</>
                        ) : (
                          <>personal production record (PPR)</>
                        )}{" "}
                        <ul>
                          <li id="gthr">
                            Gather your{" "}
                            {this.props.nyregs === false ? (
                              <>commissions</>
                            ) : (
                              <>production</>
                            )}{" "}
                            statements before starting this area
                          </li>
                          <li id="his-txt-year">
                            Your last 5 years plus the current year of{" "}
                            {this.props.nyregs === false ? (
                              <>commissions</>
                            ) : (
                              <>production</>
                            )}{" "}
                            for selling Individual Life, Annuity, Disability and
                            Long Term Care are needed for the{" "}
                            {this.props.nyregs === false ? <>PSR</> : <>PPR</>}
                          </li>
                        </ul>
                      </span>
                    </div>
                  </li>
                  <li>
                    <div>
                      <span className="icon-check-circle"></span>
                    </div>
                    <div>
                      <span
                        className="copy-md"
                        style={{ paddingLeft: "5px" }}
                        id="crimnlndregu"
                      >
                        Provide information related to any legal, criminal and
                        regulatory proceedings
                        <ul id="cas-info-court-records">
                          <li>Court records may need to be submitted</li>
                        </ul>
                      </span>
                    </div>
                  </li>
                  <li>
                    <div>
                      <span className="icon-check-circle"></span>
                    </div>
                    <div id="bnkinfo">
                      <span className="copy-md" style={{ paddingLeft: "5px" }}>
                        Provide your banking information to set up direct
                        deposit
                      </span>
                    </div>
                  </li>
                  <p style={{ padding: "20px 0px 20px 0px" }}>
                    {this.props.multiContracts > 1 && (
                      <span
                        id="return-to-dashboard-btn"
                        onClick={this.dashboardClick}
                        style={{ paddingRight: "10px", cursor: "pointer" }}
                      >
                        <span className="icon-xs icon-caret-spin-left"></span>
                        <strong
                          id="rtn-to-dashboard-link"
                          className="footer-link"
                        >
                          {" "}
                          Back to Dashboard
                        </strong>
                      </span>
                    )}

                    <Button
                      onClick={this.getStartedClick}
                      id="getstarted-btton"
                    >
                      Get Started
                    </Button>
                  </p>
                </ul>
              </div>
            </div>
          </div>
        )}

        {this.props.contract === CONTRACT_TYPE_DESCRIPTIONS.MMLIS && (
          <div id="CareerAgentInvitationSent">
            <div className="row">
              <div className="col-md-9">
                <h3 style={{ marginBottom: "14px" }} id="welcome-mm-text">
                  Welcome to MassMutual. You have been invited to complete the
                  following application - {this.props.contract} with Agency #
                  {this.props.agencynumber}
                </h3>
              </div>
            </div>

            <h4 id="mm-app">In the application you'll be asked to:</h4>
            <div className="row">
              <div className="col-md-7">
                <ul id="CareerAgentChecklist" className="list-unstyled">
                  <li>
                    <div>
                      <span className="icon-check-circle"></span>
                    </div>
                    <div id="cas-credit-check">
                      <span className="copy-md" id="crdt">
                        Authorize a background and credit check
                      </span>
                    </div>
                  </li>
                  <li>
                    <div>
                      <span className="icon-check-circle"></span>
                    </div>
                    <div id="cas-personal-information">
                      <span className="copy-md" id="rsdtlhstry">
                        Provide personal information which may include your
                        contact and business address as well as 5 years of
                        residential history
                      </span>
                    </div>
                  </li>
                  <li>
                    <div>
                      <span className="icon-check-circle"></span>
                    </div>
                    <div id="cas-emp-his-psr">
                      <span className="copy-md" id="cas-emp-his">
                        Provide 10 years of employment history
                      </span>
                    </div>
                  </li>
                  <li>
                    <div>
                      <span className="icon-check-circle"></span>
                    </div>
                    <div>
                      <span
                        className="copy-md"
                        style={{ paddingLeft: "5px" }}
                        id="crimnlndregu"
                      >
                        Provide information related to any legal, criminal and
                        regulatory proceedings
                        <ul id="cas-info-court-records">
                          <li>Court records may need to be submitted</li>
                        </ul>
                      </span>
                    </div>
                  </li>
                  <p style={{ padding: "20px 0px 20px 0px" }}>
                    {this.props.multiContracts > 1 && (
                      <span
                        id="return-to-dashboard-btn"
                        onClick={this.dashboardClick}
                        style={{ paddingRight: "10px", cursor: "pointer" }}
                      >
                        <span className="icon-xs icon-caret-spin-left"></span>
                        <strong
                          id="rtn-to-dashboard-link"
                          className="footer-link"
                        >
                          {" "}
                          Back to Dashboard
                        </strong>
                      </span>
                    )}

                    <Button
                      onClick={this.getStartedClick}
                      id="getstarted-btton"
                    >
                      Get Started
                    </Button>
                  </p>
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withOktaAuth(SentToCandidate);
