import {
  CONTRACT_STATUS_DESC,
  CONTRACT_TYPE_DESCRIPTIONS,
} from "../../../../../constants/Contract";

export const isChecked = (currentValue) => currentValue !== null;

export const isSomeChecked = (obj) => {
  return Object.values(obj).some(isChecked);
};

export const isEachChecked = (obj) => {
  return Object.values(obj).every(isChecked);
};

export const convertGroupKey = (text) =>
  `${text}`.toLowerCase().replaceAll(" ", "");

export const getSpecificObjByKeys = (obj, keys) =>
  Object.fromEntries(
    Object.entries(obj).filter(([key]) => [...keys].includes(key))
  );

export const getDRPSummaryQuestions = (allQuestions, obj) =>
  Object.keys(obj).length
    ? Object.keys(obj)
        .filter((key) => obj[key])
        .map((key) => allQuestions[key])
    : null;

export const getDRPSummaryUncheckedQuestionKeys = (allQuestions, obj) =>
  Object.keys(obj).length
    ? Object.keys(obj)
        .filter((key) => !obj[key])
        .map((key) => allQuestions[key].key)
    : null;

export const getValues = (obj, keys) =>
  obj && Object.keys(obj)?.length
    ? Object.values(
        Object.fromEntries(
          Object.entries(obj).filter(([key]) => keys.includes(key))
        )
      )
    : null;

export const groupArrayByKey = (array, key) =>
  array?.reduce((acc, current) => {
    const currentKey = current[key];
    const currentGroup = acc[currentKey] ?? [];

    return {
      ...acc,
      [currentKey]: [...currentGroup, current],
    };
  }, {});

export const parseOlString = (str = "") => {
  const acceptedCharList = [
    "1.",
    "2.",
    "3.",
    "4.",
    "5.",
    "6.",
    "7.",
    "8.",
    "9.",
    "10.",
  ];
  const substr = str.split(" ");
  const substrId = str.replaceAll(" ", "-");

  if (substr.some((s) => acceptedCharList.includes(s))) {
    const [ol, ...rest] = substr;
    const content = rest.join(" ");
    const contentId = content.replaceAll(" ", "-");

    return (
      <div className="question-container">
        <div
          id={`section-${ol}-${contentId?.length + +ol}`}
          className="section"
        >
          {ol}
        </div>
        <div id={`content-${ol}-${contentId?.length + +ol}`}>{content}</div>
      </div>
    );
  }

  return <span id={`contentStr-${substrId}`}>{str}</span>;
};

export const checkboxListCleanupFnx = (mergedArray) => [
  ...mergedArray
    ?.reduce((map, obj) => map.set(obj?.value, obj), new Map())
    .values(),
];

export const isCareerContract = (contract) => {
  return (
    contract === CONTRACT_TYPE_DESCRIPTIONS.CAREER_AGENT ||
    contract === CONTRACT_TYPE_DESCRIPTIONS.CAREER_AGENT ||
    contract === CONTRACT_TYPE_DESCRIPTIONS.CAREER_SAMPLING ||
    contract === CONTRACT_TYPE_DESCRIPTIONS.ALTERNATE_AGENT ||
    contract === CONTRACT_TYPE_DESCRIPTIONS.COLLEGE_INTERN
  );
};
