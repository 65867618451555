export const initialState = {
  secfalsestatement: null,
  secinvolvedviolation: null,
  seccauseinvestrelatedauth: null,
  secorderagainst: null,
  secceaseanddesist: null,
  secviolatedsecuritiesact: null,
  secaidedabettersecuritiesact: null,
  secfailedtosupervise: null,
  fedfalsestatement: null,
  fedinvolvedviolation: null,
  fedcauseinvestrelatedauth: null,
  fedorderagainst: null,
  fedregdenied: null,
  finalorderbarred: null,
  finalorderviolations: null,
  selffalsestatement: null,
  selfinvolvedviolation: null,
  selfcauseinvestrelatedauth: null,
  selfdisciplined: null,
  selfviolatedsecuritiesact: null,
  selfaidedabettersecuritiesact: null,
  selffailedtosupervise: null,
  authrevoked: null,
  notifiedproceeding: null,
  notifiedinvestigation: null,
};

const regActSecCftc = {
  mainQuestion:
    "Has the U.S. Securities and Exchange Commission or the Commodity Futures Trading Commission ever:",
  questionA: `1. found you to have made a false statement or omission?`,
  questionB: `2. found you to have been involved in a violation of its regulations or statutes?`,
  questionC: `3. found you to have been a cause of an investment-related business having its authorization to do business denied, suspended, revoked, or restricted?`,
  questionD: `4. entered an order against you in connection with investment-related activity?`,
  questionE: `5. imposed a civil money penalty on you, or ordered you to cease and desist from any activity?`,
  questionF: `6. found you to have willfully violated any provision of the Securities Act of 1933, the Securities Exchange Act of 1934, the Investment Advisers Act of 1940, the Investment Company Act of 1940, the Commodity Exchange Act, or any rule or regulation under any of such Acts, or any of the rules of the Municipal Securities Rulemaking Board, or found you to have been unable to comply with any provision of such Act, rule or regulation?`,
  questionG: `7. found you to have willfully aided, abetted, counseled, commanded, induced, or procured the violation by any person of any provision of the Securities Act of 1933, the Securities Exchange Act of 1934, the Investment Advisers Act of 1940, the Investment Company Act of 1940, the Commodity Exchange Act, or any rule or regulation under any of such Acts, or any of the rules of the Municipal Securities Rulemaking Board?`,
  questionH: `8. found you to have failed reasonably to supervise another person subject to your supervision, with a view to preventing the violation of any provision of the Securities Act of 1933, the Securities Exchange Act of 1934, the Investment Advisers Act of 1940, the Investment Company Act of 1940, the Commodity Exchange Act, or any rule or regulation under any of such Acts, or any of the rules of the Municipal Securities Rulemaking Board?`,
};

export const DRPSummaryRegulatoryActionSECorCFTC = {
  secfalsestatement: {
    key: "14C(1)",
    mainQuestion: regActSecCftc.mainQuestion,
    question: regActSecCftc.questionA,
  },
  secinvolvedviolation: {
    key: "14C(2)",
    mainQuestion: regActSecCftc.mainQuestion,
    question: regActSecCftc.questionB,
  },
  seccauseinvestrelatedauth: {
    key: "14C(3)",
    mainQuestion: regActSecCftc.mainQuestion,
    question: regActSecCftc.questionC,
  },
  secorderagainst: {
    key: "14C(4)",
    mainQuestion: regActSecCftc.mainQuestion,
    question: regActSecCftc.questionD,
  },
  secceaseanddesist: {
    key: "14C(5)",
    mainQuestion: regActSecCftc.mainQuestion,
    question: regActSecCftc.questionE,
  },
  secviolatedsecuritiesact: {
    key: "14C(6)",
    mainQuestion: regActSecCftc.mainQuestion,
    question: regActSecCftc.questionF,
  },
  secaidedabettersecuritiesact: {
    key: "14C(7)",
    mainQuestion: regActSecCftc.mainQuestion,
    question: regActSecCftc.questionG,
  },
  secfailedtosupervise: {
    key: "14C(8)",
    mainQuestion: regActSecCftc.mainQuestion,
    question: regActSecCftc.questionH,
  },
};

export const regulatoryActionSECorCFTC = (disabled) => [
  {
    header: `14C. ${regActSecCftc.mainQuestion}`,
    subHeader: "",
    questionA: regActSecCftc.questionA,
    questionB: regActSecCftc.questionB,
    questionC: regActSecCftc.questionC,
    questionD: regActSecCftc.questionD,
    questionE: regActSecCftc.questionE,
    questionF: regActSecCftc.questionF,
    questionG: regActSecCftc.questionG,
    questionH: regActSecCftc.questionH,
    optionsA: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "secfalsestatement-y",
      idTwo: "secfalsestatement-n",
      name: "secfalsestatement",
      disabled: disabled,
    },
    optionsB: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "secinvolvedviolation-y",
      idTwo: "secinvolvedviolation-n",
      name: "secinvolvedviolation",
      disabled: disabled,
    },
    optionsC: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "seccauseinvestrelatedauth-y",
      idTwo: "seccauseinvestrelatedauth-n",
      name: "seccauseinvestrelatedauth",
      disabled: disabled,
    },
    optionsD: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "secorderagainst-y",
      idTwo: "secorderagainst-n",
      name: "secorderagainst",
      disabled: disabled,
    },
    optionsE: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "secceaseanddesist-y",
      idTwo: "secceaseanddesist-n",
      name: "secceaseanddesist",
      disabled: disabled,
    },
    optionsF: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "secviolatedsecuritiesact-y",
      idTwo: "secviolatedsecuritiesact-n",
      name: "secviolatedsecuritiesact",
      disabled: disabled,
    },
    optionsG: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "secaidedabettersecuritiesact-y",
      idTwo: "secaidedabettersecuritiesact-n",
      name: "secaidedabettersecuritiesact",
      disabled: disabled,
    },
    optionsH: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "secfailedtosupervise-y",
      idTwo: "secfailedtosupervise-n",
      name: "secfailedtosupervise",
      disabled: disabled,
    },
  },
];

const regActOthRegFed = {
  mainQuestion:
    "Has any other Federal regulatory agency or any state regulatory agency or foreign financial regulatory authority ever:",
  questionA: `(a) found you to have made a false statement or omission or been dishonest, unfair or unethical?`,
  questionB: `(b) found you to have been involved in a violation of investment-related regulation(s) or statute(s)?`,
  questionC: `(c) found you to have been a cause of an investment-related business having its authorization to do business denied, suspended, revoked or restricted?`,
  questionD: `(d) entered an order against you in connection with an investment-related activity?`,
  questionE: `(e) denied, suspended, or revoked your registration or license or otherwise, by order, prevented you from?`,
};

const regActOthRegFin = {
  mainQuestion:
    "Have you been subject to any final order of a state securities commission (or any agency or office performing like functions), state authority that supervises or examines banks, savings associations, or credit unions, state insurance commission (or any agency or office performing like functions), an appropriate federal banking agency, or the National Credit Union Administration, that:",
  questionA: `(a) bars you from association with an entity regulated by such commission, authority, agency, or officer, or from engaging in the business of securities, insurance, banking, savings association activities, or credit union activities; or `,
  questionB: `(b) constitutes a final order based on violations of any laws or regulations that prohibit fraudulent, manipulative, or deceptive conduct?`,
};

export const DRPSummaryRegulatoryActionOtherRegulators = {
  fedfalsestatement: {
    key: "14D(1)(a)",
    mainQuestion: regActOthRegFed.mainQuestion,
    question: regActOthRegFed.questionA,
  },
  fedinvolvedviolation: {
    key: "14D(1)(b)",
    mainQuestion: regActOthRegFed.mainQuestion,
    question: regActOthRegFed.questionB,
  },
  fedcauseinvestrelatedauth: {
    key: "14D(1)(c)",
    mainQuestion: regActOthRegFed.mainQuestion,
    question: regActOthRegFed.questionC,
  },
  fedorderagainst: {
    key: "14D(1)(d)",
    mainQuestion: regActOthRegFed.mainQuestion,
    question: regActOthRegFed.questionD,
  },
  fedregdenied: {
    key: "14D(1)(e)",
    mainQuestion: regActOthRegFed.mainQuestion,
    question: regActOthRegFed.questionE,
  },
  finalorderbarred: {
    key: "14D(2)(a)",
    mainQuestion: regActOthRegFin.mainQuestion,
    question: regActOthRegFin.questionA,
  },
  finalorderviolations: {
    key: "14D(2)(b)",
    mainQuestion: regActOthRegFin.mainQuestion,
    question: regActOthRegFin.questionB,
  },
};

export const regulatoryActionOtherRegulators = (disabled) => [
  {
    header: `14D (1). ${regActOthRegFed.mainQuestion}`,
    subHeader: "",
    questionA: regActOthRegFed.questionA,
    questionB: regActOthRegFed.questionB,
    questionC: regActOthRegFed.questionC,
    questionD: regActOthRegFed.questionD,
    questionE: regActOthRegFed.questionE,
    optionsA: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "fedfalsestatement-y",
      idTwo: "fedfalsestatement-n",
      name: "fedfalsestatement",
      disabled: disabled,
    },
    optionsB: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "fedinvolvedviolation-y",
      idTwo: "fedinvolvedviolation-n",
      name: "fedinvolvedviolation",
      disabled: disabled,
    },
    optionsC: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "fedcauseinvestrelatedauth-y",
      idTwo: "fedcauseinvestrelatedauth-n",
      name: "fedcauseinvestrelatedauth",
      disabled: disabled,
    },
    optionsD: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "fedorderagainst-y",
      idTwo: "fedorderagainst-n",
      name: "fedorderagainst",
      disabled: disabled,
    },
    optionsE: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "fedregdenied-y",
      idTwo: "fedregdenied-n",
      name: "fedregdenied",
      disabled: disabled,
    },
  },
  {
    header: `14D (2). ${regActOthRegFin.mainQuestion}`,
    subHeader: "",
    questionA: regActOthRegFin.questionA,
    questionB: regActOthRegFin.questionB,
    optionsA: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "finalorderbarred-y",
      idTwo: "finalorderbarred-n",
      name: "finalorderbarred",
      disabled: disabled,
    },
    optionsB: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "finalorderviolations-y",
      idTwo: "finalorderviolations-n",
      name: "finalorderviolations",
      disabled: disabled,
    },
  },
];

const regActSro = {
  mainQuestion: "Has any self-regulatory organization ever:",
  questionA: `1. found you to have made a false statement or omission?`,
  questionB: `2. found you to have been involved in a violation of its rules (other than a violation designated as a "minor rule violation" under a plan approved by the U.S. Securities and Exchange Commission)?`,
  questionC: `3. found you to have been the cause of an investment-related business having its authorization to do business denied, suspended, revoked or restricted?`,
  questionD: `4. disciplined you by expelling or suspending you from membership, barring or suspending your association with its members, or restricting your activities?`,
  questionE: `5. found you to have willfully violated any provision of the Securities Act of 1933, the Securities Exchange Act of 1934, the Investment Advisers Act of 1940, the Investment Company Act of 1940, the Commodity Exchange Act, or any rule or regulation under any of such Acts, or any of the rules of the Municipal Securities Rulemaking Board, or found you to have been unable to comply with any provision of such Act, rule or regulation?`,
  questionF: `6. found you to have willfully aided, abetted, counseled, commanded, induced, or procured the violation by any person of any provision of the Securities Act of 1933, the Securities Exchange Act of 1934, the Investment Advisers Act of 1940, the Investment Company Act of 1940, the Commodity Exchange Act, or any rule or regulation under any of such Acts, or any of the rules of the Municipal Securities Rulemaking Board?`,
  questionG: `7. found you to have failed reasonably to supervise another person subject to your supervision, with a view to preventing the violation of any provision of the Securities Act of 1933, the Securities Exchange Act of 1934, the Investment Advisers Act of 1940, the Investment Company Act of 1940, the Commodity Exchange Act, or any rule or regulation under any of such Acts, or any of the rules of the Municipal Securities Rulemaking Board?`,
};

export const DRPSummaryRegulatoryActionSRO = {
  selffalsestatement: {
    key: "14E(1)",
    mainQuestion: regActSro.mainQuestion,
    question: regActSro.questionA,
  },
  selfinvolvedviolation: {
    key: "14E(2)",
    mainQuestion: regActSro.mainQuestion,
    question: regActSro.questionB,
  },
  selfcauseinvestrelatedauth: {
    key: "14E(3)",
    mainQuestion: regActSro.mainQuestion,
    question: regActSro.questionC,
  },
  selfdisciplined: {
    key: "14E(4)",
    mainQuestion: regActSro.mainQuestion,
    question: regActSro.questionD,
  },
  selfviolatedsecuritiesact: {
    key: "14E(5)",
    mainQuestion: regActSro.mainQuestion,
    question: regActSro.questionE,
  },
  selfaidedabettersecuritiesact: {
    key: "14E(6)",
    mainQuestion: regActSro.mainQuestion,
    question: regActSro.questionF,
  },
  selffailedtosupervise: {
    key: "14E(7)",
    mainQuestion: regActSro.mainQuestion,
    question: regActSro.questionG,
  },
};

export const regulatoryActionSRO = (disabled) => [
  {
    header: `14E. ${regActSro.mainQuestion}`,
    subHeader: "",
    questionA: regActSro.questionA,
    questionB: regActSro.questionB,
    questionC: regActSro.questionC,
    questionD: regActSro.questionD,
    questionE: regActSro.questionE,
    questionF: regActSro.questionF,
    questionG: regActSro.questionG,
    optionsA: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "selffalsestatement-y",
      idTwo: "selffalsestatement-n",
      name: "selffalsestatement",
      disabled: disabled,
    },
    optionsB: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "selfinvolvedviolation-y",
      idTwo: "selfinvolvedviolation-n",
      name: "selfinvolvedviolation",
      disabled: disabled,
    },
    optionsC: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "selfcauseinvestrelatedauth-y",
      idTwo: "selfcauseinvestrelatedauth-n",
      name: "selfcauseinvestrelatedauth",
      disabled: disabled,
    },
    optionsD: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "selfdisciplined-y",
      idTwo: "selfdisciplined-n",
      name: "selfdisciplined",
      disabled: disabled,
    },
    optionsE: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "selfviolatedsecuritiesact-y",
      idTwo: "selfviolatedsecuritiesact-n",
      name: "selfviolatedsecuritiesact",
      disabled: disabled,
    },
    optionsF: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "selfaidedabettersecuritiesact-y",
      idTwo: "selfaidedabettersecuritiesact-n",
      name: "selfaidedabettersecuritiesact",
      disabled: disabled,
    },
    optionsG: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "selffailedtosupervise-y",
      idTwo: "selffailedtosupervise-n",
      name: "selffailedtosupervise",
      disabled: disabled,
    },
  },
];

const regProSusp = {
  mainQuestion:
    "Have you ever had an authorization to act as an attorney, accountant or federal contractor that was revoked or suspended?",
};

export const DRPSummaryProfessionalSuspension = {
  authrevoked: {
    key: "14F",
    mainQuestion: regProSusp.mainQuestion,
    question: regProSusp.questionA,
  },
};

export const professionalSuspension = (disabled) => [
  {
    header: `14F. ${regProSusp.mainQuestion}`,
    subHeader: "",
    questionA: "",
    optionsA: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "authrevoked-y",
      idTwo: "authrevoked-n",
      name: "authrevoked",
      disabled: disabled,
    },
  },
];

const regFormPendActReg = {
  mainQuestion:
    "Have you been notified, in writing, that you are now the subject of any:",
  questionA: `1. regulatory complaint or proceeding that could result in a "yes" answer to any part of 14C, D or E? `,
};

const regFormPendActInv = {
  mainQuestion: regFormPendActReg.mainQuestion,
  questionA: `2. investigation that could result in a "yes" answer to any part of 14A, B, C, D or E?`,
};

export const DRPSummaryFormalPendingActionInvestigation = {
  notifiedproceeding: {
    key: "14G(1)",
    mainQuestion: regFormPendActReg.mainQuestion,
    question: regFormPendActReg.questionA,
  },
  notifiedinvestigation: {
    key: "14G(2)",
    mainQuestion: regFormPendActInv.mainQuestion,
    question: regFormPendActInv.questionA,
  },
};

export const formalPendingActionInvestigation = (disabled) => [
  {
    header: "",
    subHeader: `14G. ${regFormPendActReg.mainQuestion}`,
    questionA: regFormPendActReg.questionA,
    optionsA: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "notifiedproceeding-y",
      idTwo: "notifiedproceeding-n",
      name: "notifiedproceeding",
      disabled: disabled,
    },
  },
  {
    header: "Investigation",
    subHeader: "",
    questionA: regFormPendActInv.questionA,
    optionsA: {
      labelOne: "Yes",
      valueOne: true,
      labelTwo: "No",
      valueTwo: false,
      idOne: "notifiedinvestigation-y",
      idTwo: "notifiedinvestigation-n",
      name: "notifiedinvestigation",
      disabled: disabled,
    },
  },
];
