import Header from "../SharedComponents/Header";
import Footer from "../SharedComponents/Footer";
import logo from "../../assets/img/SpringOOSGif.gif";
import "../../assets/stylesheets/OOSPage.scss";

function OOSPage() {
  return (
    <div id="OOSPage">
      <Header />
      <div id="OOSBody" className="container">
        <div className="row">
          <div className="col-sm"></div>
          <h1>Sorry we're not here right now</h1>
          <img src={logo} alt="Out Of Service"></img>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default OOSPage;
