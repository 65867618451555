/* eslint-disable jsx-a11y/label-has-for */
import React, { useEffect, useState } from "react";
import { Button } from "react-blueprint-library";
import { useDispatch } from "react-redux";

import BackButtonModal from "../../../../../Forms/BackButtonModal";
import { logoutActions } from "../../../../../../../store/actions";

import "./CommentsForm.scss";
import { customerComplaintActionType } from "../constants";
import { CCB_FORM_ID } from "../../constants";

const CustomerComplaintAddDRPCommentsForm = ({
  updateCCBForm,
  nextFormId,
  tempDRP,
  updateTempCustComplDRP,
  updateCustComplDisclosureDRPs,
}) => {
  const dispatch = useDispatch();
  const [showBackModal, setShowBackModal] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [comments, setComments] = useState(tempDRP?.comments || "");

  const onCommentsChangeHandler = ({ target: { value } }) => {
    setComments(value);
    setIsUpdated(true);
  };

  const handleCloseBackModal = () => setShowBackModal(false);

  useEffect(() => {
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: true }));
  }, []);

  const handleShowBackModal = () => {
    if (isUpdated) {
      setShowBackModal(true);
    } else {
      const backFormId = getBackFormId(tempDRP);
      updateCCBForm(backFormId);
    }
  };

  const handleBackClick = () => {
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: false }));
    const backFormId = getBackFormId(tempDRP);
    updateCCBForm(backFormId);
    handleCloseBackModal();
  };

  const getBackFormId = ({
    statusDetailsMatter,
    statusDetailsNamedPartyRadio,
  }) => {
    if (
      statusDetailsMatter === customerComplaintActionType.CUSTOMER_COMPLAINT ||
      statusDetailsNamedPartyRadio === "No"
    ) {
      return CCB_FORM_ID.ADD_DRP_CUSTOMER_COMPLAINT;
    } else if (
      statusDetailsMatter === customerComplaintActionType.ARBITRATION_CFTC
    ) {
      return CCB_FORM_ID.ADD_DRP_ARBITRATION_CFTC;
    } else if (
      statusDetailsMatter === customerComplaintActionType.CIVIL_LITIGATION
    ) {
      return CCB_FORM_ID.ADD_DRP_CIVIL_LITIGATION;
    } else {
      return CCB_FORM_ID.ADD_DRP_COMMENTS;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  const handleNext = () => {
    const submitData = {
      ...tempDRP,
      comments,
    };
    updateCustComplDisclosureDRPs(submitData);
    updateTempCustComplDRP({});
    updateCCBForm(nextFormId);
  };

  return (
    <div className="cust-compl-disclosure-comments-form-wrapper">
      <div className="cust-compl-comments-container">
        <label
          htmlFor="custComplDisclCommentsId"
          className="comments-label"
          id="custComplDisclCommentsLabelId"
        >
          You may use this field to provide a brief summary of the circumstances
          leading to the customer complaint, arbitration/CFTC reparation and/or
          civil litigation as well as the current status or final
          disposition(s). (Optional)
        </label>
        <textarea
          id="custComplDisclCommentsId"
          onChange={onCommentsChangeHandler}
          className={"form-control"}
          minLength={0}
          maxLength={500}
          rows="3"
          cols={500}
          value={comments}
        />
      </div>

      <div className="backbutton-drp-cust-compl row">
        <div className="col-md-12 buttons">
          <span role="presentation" onClick={handleShowBackModal}>
            <strong id="custComplDisclBack">Back</strong>
          </span>
          <Button label="Complete DRP" onClick={handleNext} disabled={false} />
          {false && <span className="mm-dots-loading"></span>} {/* loading */}
        </div>
      </div>

      <BackButtonModal
        isOpen={showBackModal}
        closeBackModal={handleCloseBackModal}
        backClick={handleBackClick}
      />
    </div>
  );
};

export default CustomerComplaintAddDRPCommentsForm;
