import React, { Component, Fragment } from "react";
import Moment from "moment";
import { Button } from "react-blueprint-library";

class PersonalSalesRecordTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show_modal: false,
    };

    this.handleReviewEditClick = this.handleReviewEditClick.bind(this);
  }

  async handleReviewEditClick(psr) {
    this.props.reviewEditClick(psr);
  }

  render() {
    return (
      <div id="psrTable">
        <div className="row">
          <div className="col-md-12">
            {this.props.data.length > 0 && (
              <>
                {Array.from(this.props.data).map((psr, key) => {
                  return (
                    <Fragment key={key}>
                      <div
                        className={
                          psr.same_company === "N"
                            ? "psr-table-row psr-company-separator"
                            : "psr-table-row"
                        }
                      >
                        <div className="psr-table-jobtitle-column">
                          {psr.same_company === "N" ? `${psr.jobtitle}` : ""}
                        </div>
                        <div className="psr-table-company-column">
                          {psr.companyname}
                        </div>
                        <div className="psr-table-date-column">
                          {`${Moment(psr.startdate)
                            .utc()
                            .format("MM/DD/YYYY")} - ${psr.enddatetxt}`}
                        </div>
                        {psr.psr_id < 1 && (
                          <div className="psr-add-btn-wrapper">
                            <Button
                              id="add_psr"
                              className="psr-add-button"
                              variant="secondary"
                              onClick={() => this.props.showAddModal(psr)}
                            >
                              <span className="icon-add"></span>{" "}
                              {this.props.hasNYRegs === false ? (
                                <>Add PSR</>
                              ) : (
                                <>Add PPR</>
                              )}
                            </Button>
                          </div>
                        )}
                        {psr.psr_id > 0 && (
                          <div className="psr-table-button-column">
                            <span
                              onClick={() => this.handleReviewEditClick(psr)}
                              style={{}}
                            >
                              <strong>Review/Edit</strong>
                            </span>
                          </div>
                        )}
                      </div>
                    </Fragment>
                  );
                })}

                <hr
                  style={{
                    marginTop: "10px",
                    color: "#c1c9d5",
                  }}
                ></hr>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default PersonalSalesRecordTable;
