/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useCallback, useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { useDispatch } from "react-redux";
import moment from "moment";
import { Button } from "react-blueprint-library";
import BackButtonModal from "../../../../../Forms/BackButtonModal";
import { dateInputMask, eventStatusDateType } from "../../../constants";
import { logoutActions } from "../../../../../../../store/actions";

import "./EventStatusForm.scss";

const eventStatus = {
  PENDING: "Pending",
  APPEAL: "On Appeal",
  FINAL: "Final",
};

const CriminalDisclosureAddDRPEventStatusForm = ({
  updateCCBForm,
  backFormId,
  nextFormId,
  getSubmitDataValues,
  data,
  groupKey,
  headerText,
}) => {
  const dispatch = useDispatch();
  const [formHasChanged, setFormHasChanged] = useState(false);
  const [showBackModal, setShowBackModal] = useState(false);
  const [status, setStatus] = useState(data?.status || "");
  const [eventStatusDate, setEventStatusDate] = useState(
    data?.eventStatusDate || ""
  );
  const [eventStatusDateError, setEventStatusDateError] = useState(false);
  const [eventStatusDateRadio, setEventStatusDateRadio] = useState(
    data?.eventStatusDateRadio || ""
  );
  const [eventStatusDateExp, setEventStatusDateExp] = useState(
    data?.eventStatusDateExp || ""
  );
  const [eventStatusDateExpError, setEventStatusDateExpError] = useState(false);
  const [eventStatusDateErrorMsg, setEventStatusDateErrorMsg] = useState("");

  const isError = eventStatusDateError || eventStatusDateExpError;

  useEffect(() => {
    if (isError) {
      setFormHasChanged(true);
    }
  }, [isError]);

  const isNextBtnDisabled = !!(
    !status.length ||
    (status.length &&
      (status === eventStatus.APPEAL || status === eventStatus.FINAL) &&
      (!eventStatusDate.length ||
        eventStatusDate === dateInputMask.EMPTY ||
        !eventStatusDateRadio.length ||
        eventStatusDateRadio.includes("_") ||
        (eventStatusDateRadio === eventStatusDateType.EXPLANATION &&
          !eventStatusDateExp.length) ||
        (eventStatusDateExp?.length &&
          eventStatusDateExp.trim()?.length < 2))) ||
    isError
  );

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  const handleChangeChecked = (e) => {
    const { value } = e.target;
    let newValue = value;

    setFormHasChanged(true);

    setEventStatusDateExp("");
    setEventStatusDate("");
    setEventStatusDateRadio("");
    setEventStatusDateError(false);
    setEventStatusDateExpError(false);

    setStatus(newValue);
  };

  const handleChangeEventStatusDateChecked = (e) => {
    const { value } = e.target;
    let newValue = value;

    setEventStatusDateExpError(false);
    setFormHasChanged(true);

    if (
      eventStatusDateExp.length &&
      eventStatusDateRadio !== eventStatusDateType.EXPLANATION
    ) {
      setEventStatusDateExp("");
    }

    setEventStatusDateRadio(newValue);
  };

  const onEventStatusOnChangeHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setEventStatusDate(value);
  };

  const onEventStatusDateExplanationChange = ({ target: { value } }) => {
    setFormHasChanged(true);
    setEventStatusDateExp(value);
  };

  const onBlurEventExpDateHandler = ({ target: { value } }) => {
    return value.trim().length < 2 || value.trim().length > 500
      ? setEventStatusDateExpError(true)
      : setEventStatusDateExpError(false);
  };

  const onEventStatusDateBlurHandler = ({ target: { value } }) => {
    const today = moment();
    const date = moment(value, "MM/DD/YYYY", true);
    const past = moment(moment().subtract(100, "years").format("MM/DD/YYYY"));

    if (value !== "" && !value.includes("_")) {
      if (moment(value) > today) {
        setEventStatusDateError(true);
      } else {
        setEventStatusDateError(false);
      }
    } else {
      setEventStatusDateError(false);
    }

    if (
      date._i.length === 10 &&
      !date._i.includes("_") &&
      date.isValid() &&
      !value.includes("0000")
    ) {
      if (date > today) {
        setEventStatusDateErrorMsg("Date cannot be a future date");
        setEventStatusDateError(true);
      } else if (date < past) {
        setEventStatusDateErrorMsg("Date cannot be more than 100 years ago");
        setEventStatusDateError(true);
      } else {
        setEventStatusDateError(false);
      }
    } else {
      setEventStatusDateErrorMsg("Please enter a valid date");
      setEventStatusDateError(true);
    }
  };

  const handleCloseBackModal = () => setShowBackModal(false);

  const isUpdates = formHasChanged || isError;

  useEffect(() => {
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: true }));
  }, [isUpdates, dispatch]);

  const handleShowBackModal = () => {
    if (formHasChanged) {
      setShowBackModal(true);
    } else {
      if (
        (data !== null && data && !Object.keys(data).length) ||
        data === null
      ) {
        getSubmitDataValues({ eventStatus: null }, headerText);
      }
      updateCCBForm(backFormId);
    }
  };

  const handleBackClick = () => {
    if ((data !== null && data && !Object.keys(data).length) || data === null) {
      getSubmitDataValues({ eventStatus: null }, headerText);
    }
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: false }));
    updateCCBForm(backFormId);
    handleCloseBackModal();
  };

  const handleNext = useCallback(() => {
    const submitData = {
      status,
      eventStatusDate,
      eventStatusDateRadio,
      eventStatusDateExp,
    };

    getSubmitDataValues({ [`${groupKey}`]: submitData }, headerText);

    updateCCBForm(nextFormId);
  }, [
    updateCCBForm,
    nextFormId,
    getSubmitDataValues,
    status,
    eventStatusDate,
    eventStatusDateRadio,
    eventStatusDateExp,
    headerText,
    groupKey,
  ]);

  return (
    <div className="criminal-disclosure-event-status-form-wrapper">
      <div className="row">
        <div className="col-md-12">
          <label id="eventStatusLabelId" className="event-status">
            Current Status of the Event: *
          </label>
        </div>
      </div>

      <div className="row">
        <div className="drp-radio-input-wrapper">
          <div className="col-12 copy-md-4 form-group">
            <div className="custom-control custom-radio">
              <input
                name="federalCourt"
                type="radio"
                id="federal"
                className="custom-control-input"
                value={eventStatus.PENDING}
                checked={status === eventStatus.PENDING}
                onChange={handleChangeChecked}
              />
              <label
                id="federalLabelId"
                htmlFor="federal"
                className="custom-control-label"
              >
                {eventStatus.PENDING}
              </label>
            </div>

            <div className="custom-control custom-radio">
              <input
                name="stateCourt"
                type="radio"
                id="stateCourtId"
                className="custom-control-input"
                value={eventStatus.APPEAL}
                checked={status === eventStatus.APPEAL}
                onChange={handleChangeChecked}
              />
              <label
                htmlFor="stateCourtId"
                id="stateCourtLabelId"
                className="custom-control-label"
              >
                {eventStatus.APPEAL}
              </label>
            </div>

            <div className="custom-control custom-radio">
              <input
                name="foreignCourt"
                type="radio"
                id="foreignCourtId"
                className="custom-control-input"
                value={eventStatus.FINAL}
                checked={status === eventStatus.FINAL}
                onChange={handleChangeChecked}
              />
              <label
                htmlFor="foreignCourtId"
                id="foreignCourtLabelId"
                className="custom-control-label"
              >
                {eventStatus.FINAL}
              </label>
            </div>

            {status === eventStatus.APPEAL || status === eventStatus.FINAL ? (
              <div className="row">
                <div className="col-md-12 event-status-date-wrapper">
                  <label
                    id="eventStatusDateLabelId"
                    htmlFor="eventStatusDateId"
                  >
                    <strong id="eventStatusDateText">
                      Event Status Date (complete unless status is pending) *
                    </strong>
                  </label>
                  <InputMask
                    id="eventStatusDateId"
                    mask="99/99/9999"
                    maskChar="_"
                    className={`${
                      eventStatusDateError
                        ? "form-control is-invalid"
                        : "form-control"
                    } event-status-date-input`}
                    placeholder="MM/DD/YYYY"
                    autoComplete="off"
                    required
                    value={eventStatusDate}
                    onChange={onEventStatusOnChangeHandler}
                    onBlur={onEventStatusDateBlurHandler}
                  />
                  <div id="eventStatusDateError" className="invalid-feedback">
                    {eventStatusDateErrorMsg?.length
                      ? eventStatusDateErrorMsg
                      : "Please enter a valid Event Status Date"}
                  </div>
                </div>

                <div className="row event-status-date-radio-group">
                  <div className="custom-control">
                    <div className="col-12 copy-md-8 form-group">
                      <div className="custom-control custom-radio">
                        <input
                          name="radio-guide-2"
                          type="radio"
                          id="eventStatusDateExactId"
                          className="custom-control-input"
                          value={eventStatusDateType.EXACT}
                          checked={
                            eventStatusDateRadio === eventStatusDateType.EXACT
                          }
                          onChange={handleChangeEventStatusDateChecked}
                        />
                        <label
                          htmlFor="eventStatusDateExactId"
                          className="custom-control-label"
                          id="label_exactDate"
                        >
                          {eventStatusDateType.EXACT}
                        </label>
                      </div>
                      <div className="custom-control custom-radio">
                        <input
                          name="radio-guide-2"
                          type="radio"
                          id="eventStatusDateExplanationId"
                          className="custom-control-input"
                          value={eventStatusDateType.EXPLANATION}
                          checked={
                            eventStatusDateRadio ===
                            eventStatusDateType.EXPLANATION
                          }
                          onChange={handleChangeEventStatusDateChecked}
                        />
                        <label
                          htmlFor="eventStatusDateExplanationId"
                          className="custom-control-label"
                          id="label_explanation"
                        >
                          {eventStatusDateType.EXPLANATION}
                        </label>
                      </div>
                      {eventStatusDateRadio ===
                      eventStatusDateType.EXPLANATION ? (
                        <div className="event-status-date-explanation-container">
                          <label
                            htmlFor="eventStatusDateExplanation"
                            className="event-status-date-exp"
                            id="eventStatusDateExplanationLabelId"
                          >
                            If not exact, provide explanation *
                          </label>
                          <textarea
                            id="eventStatusDateExplanation"
                            onChange={onEventStatusDateExplanationChange}
                            className={
                              eventStatusDateExpError
                                ? "form-control form-control-15 is-invalid"
                                : "form-control form-control-15"
                            }
                            maxLength={500}
                            minLength={2}
                            onBlur={onBlurEventExpDateHandler}
                            rows="3"
                            cols={500}
                            value={eventStatusDateExp}
                          />
                          <div
                            id="eventStatusDateExpError"
                            className="invalid-feedback"
                          >
                            Please enter an Explanation
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div className="backbutton-drp-crim row">
        <div className="col-md-12 buttons">
          <span role="presentation" onClick={handleShowBackModal}>
            <strong id="crimDisclBack">Back</strong>
          </span>
          <Button
            label="Next"
            onClick={handleNext}
            disabled={!!isNextBtnDisabled}
          />
          {false && <span className="mm-dots-loading"></span>} {/* loading */}
        </div>
      </div>

      <BackButtonModal
        isOpen={showBackModal}
        closeBackModal={handleCloseBackModal}
        backClick={handleBackClick}
      />
    </div>
  );
};

export default CriminalDisclosureAddDRPEventStatusForm;
