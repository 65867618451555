import iconCheckCircle from "./iconCheckCircle";
import iconCaret from "./iconCaret";
import iconAddDRP from "./iconAddDRP";
import danger from "./danger";
import close from "./close";

export const svgType = {
  iconCheckCircle: "iconCheckCircle",
  iconCaret: "iconCaret",
  iconAddDRP: "iconAddDRP",
  danger: "danger",
  close: "close",
};

const svgs = {
  iconCheckCircle,
  iconCaret,
  iconAddDRP,
  danger,
  close,
};

export default svgs;
