import React, { useEffect, useRef, useState } from "react";

import "./ActionDropdown.scss";
import { useClickOutside } from "../../hooks/useClickOutside";

const ActionDropdown = ({
  questionKey,
  editAction,
  deleteAction,
  isFullWidth,
  showDelete,
  isLoading,
}) => {
  const containerRef = useRef(null);
  const dropdownRef = useRef(null);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  useEffect(() => {
    if (showDelete) {
      setShow(false);
    }
  }, [showDelete]);

  useClickOutside(dropdownRef, containerRef, show, handleClose);

  useEffect(() => {
    let handler = (e) => {
      if (!dropdownRef?.current?.contains(e.target)) {
        handleClose();
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const onClickHandler = () => {
    setShow((prev) => !prev);
  };

  const onEdit = () => {
    editAction();
    setShow(false);
  };

  const onDelete = () => {
    deleteAction();
    setShow(false);
  };

  return (
    <div className="drp-quick-action-wrapper" ref={containerRef}>
      <button
        id="drp-dropbtn"
        className="icon-md icon-quick-actions"
        onClick={onClickHandler}
        disabled={isLoading}
      />

      {show ? (
        <div
          key={`drpDropDown-${questionKey}`}
          className={`drp-dropdown-content-wrapper ${
            isFullWidth ? "full-width" : ""
          }`}
          ref={dropdownRef}
        >
          <div
            role="presentation"
            className="drp-dropdown-edit"
            onClick={onEdit}
          >
            Review/Edit
          </div>

          <div
            role="presentation"
            className="drp-dropdown-delete"
            onClick={onDelete}
            style={{ textAlign: "left" }}
          >
            Delete
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ActionDropdown;
