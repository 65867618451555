import Moment from "moment-timezone";
import React, { Component, Fragment } from "react";
import DeleteModal from "./EmploymentHistoryDeleteModal";

class ResidentialHistoryTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      residentialHistory: this.props.data,
      show_modal: false,
      delete_index: -1,
      delete_address: {},
      hasPresentBeenClicked: false,
      overlappingRanges: "",
    };

    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
    this.deleteHistoryRow = this.deleteHistoryRow.bind(this);
    this.handleReviewEditClick = this.handleReviewEditClick.bind(this);
    this.updateHasPresentBeenClicked =
      this.updateHasPresentBeenClicked.bind(this);
    this.showMenu = this.showMenu.bind(this);
    this.hideMenu = this.hideMenu.bind(this);
    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }
  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.loadData();
    }
  }

  async loadData() {
    let isThereCurrent = false;
    let doesRangeExistAlready = false;
    let overlappingRanges = [];

    for (let i = 0; i < this.props.data.length; i++) {
      if (this.props.data[i].currentaddress) {
        isThereCurrent = true;
      }
      if (overlappingRanges.length === 0) {
        if (this.props.data[i].overlappingRange !== null) {
          overlappingRanges.push({
            begindate: this.props.data[i].overlappingRange.begindate,
            enddate: this.props.data[i].overlappingRange.enddate,
            residentialhistoryid: this.props.data[i].residentialhistoryid,
          });
        }
      } else {
        for (let o = 0; o < overlappingRanges.length; o++) {
          if (this.props.data[i].overlappingRange !== null) {
            if (
              overlappingRanges[o].begindate ===
                this.props.data[i].overlappingRange.begindate &&
              overlappingRanges[o].enddate ===
                this.props.data[i].overlappingRange.enddate
            ) {
              doesRangeExistAlready = true;
            }
          }
        }
        if (
          this.props.data[i].overlappingRange !== null &&
          doesRangeExistAlready === false
        ) {
          overlappingRanges.push({
            begindate: this.props.data[i].overlappingRange.begindate,
            enddate: this.props.data[i].overlappingRange.enddate,
            residentialhistoryid: this.props.data[i].residentialhistoryid,
          });
        }
      }
    }

    this.setState({
      residentialHistory: this.props.data,
      hasPresentBeenClicked: isThereCurrent,
      overlappingRanges: overlappingRanges,
    });
  }

  async handleDeleteClick(address, item) {
    await this.setState({
      delete_index: item,
      delete_address: address,
      show_modal: true,
      overlappingRanges: "",
    });

    //hide popup menu
    let myRef = document.getElementById("top_delete_element");
    myRef.click();
  }
  async closeDeleteModal() {
    await this.setState({ show_modal: false });
  }
  async deleteHistoryRow() {
    this.props.deleteData(this.state.delete_address, this.state.delete_index);
    await this.setState({
      show_modal: false,
      overlappingRanges: "",
    });
  }
  async handleReviewEditClick(residence, key) {
    this.props.reviewEditClick(residence);
    this.hideMenu(key);
  }
  async updateHasPresentBeenClicked(value) {
    await this.setState({ hasPresentBeenClicked: value });
  }

  showMenu(mnuId) {
    var dropdowns = document.getElementsByClassName("dropdown-content");
    var i;
    for (i = 0; i < dropdowns.length; i++) {
      var openDropdown = dropdowns[i];
      if (openDropdown.classList.contains("show")) {
        openDropdown.classList.remove("show");
      }
    }
    document.getElementById("mnuDropDown" + mnuId).classList.toggle("show");
  }

  hideMenu(mnuId) {
    var dropdowns = document.getElementsByClassName("dropdown-content");
    var i;
    for (i = 0; i < dropdowns.length; i++) {
      var openDropdown = dropdowns[i];
      if (openDropdown.classList.contains("show")) {
        openDropdown.classList.remove("show");
      }
    }
    document.getElementById("mnuDropDown" + mnuId).classList.toggle("hide");
  }
  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            {this.state.residentialHistory.length > 0 && (
              <>
                <ul className="mm-vertical-timeline">
                  {Array.from(this.state.residentialHistory).map(
                    (residence, key) => {
                      let todaysDate = Moment();
                      let fiveYearsAgo = Moment(todaysDate)
                        .subtract(5, "years")
                        .startOf("day");

                      let beginDate = Moment(residence.begindate);
                      let highDate = Moment(todaysDate).subtract(5, "years");
                      for (
                        let j = 0;
                        j < this.state.residentialHistory.length;
                        j++
                      ) {
                        let currentDate = Moment(
                          this.state.residentialHistory[j].enddate
                        );
                        if (currentDate > highDate) {
                          highDate = currentDate;
                        }
                      }

                      let gapTopBeginDate = Moment(highDate)
                        .add(1, "days")
                        //commented to fix date -1 issue ORB-5588
                        // .tz("America/New_York")
                        .format("MM/DD/YYYY");
                      let gapBottombeginDate = Moment(residence.begindate)
                        .subtract(1, "days")
                        // .tz("America/New_York")
                        .format("MM/DD/YYYY");

                      let gapBottomEndDate = fiveYearsAgo.format("MM/DD/YYYY");
                      let gapBeginDate = "";
                      let gapEndDate = "";
                      if (key > 0) {
                        gapBeginDate = Moment(residence.enddate)
                          .add(1, "days")
                          // .tz("America/New_York")
                          .format("MM/DD/YYYY");
                        gapEndDate = Moment(
                          this.state.residentialHistory[key - 1].begindate
                        )
                          .subtract(1, "days")
                          // .tz("America/New_York")
                          .format("MM/DD/YYYY");
                      }
                      let showOverLappingDateAdd = false;

                      if (residence.overlappingRange !== null) {
                        gapBeginDate = Moment(
                          residence.overlappingRange.enddate
                        )
                          .add(1, "days")
                          // .tz("America/New_York")
                          .format("MM/DD/YYYY");
                        for (
                          let o = 0;
                          o < this.state.overlappingRanges.length;
                          o++
                        ) {
                          showOverLappingDateAdd =
                            this.state.overlappingRanges[o]
                              .residentialhistoryid ===
                              residence.residentialhistoryid &&
                            Moment(gapEndDate).diff(
                              Moment(gapBeginDate),
                              "days"
                            ) > 0;
                        }
                      }

                      let showAddAddressRowTop =
                        (key === 0 &&
                          (todaysDate.diff(gapTopBeginDate, "days") > 0 ||
                            todaysDate.diff(highDate, "days") > 0) &&
                          this.state.hasPresentBeenClicked === false) ||
                        (residence.istheregap &&
                          residence.overlappingRange === null) ||
                        (residence.istheregap &&
                          residence.overlappingRange !== null &&
                          showOverLappingDateAdd);

                      let showAddAddressRowBottom =
                        key === this.state.residentialHistory.length - 1 &&
                        beginDate.diff(fiveYearsAgo, "days") > 0;

                      return (
                        <Fragment key={key}>
                          {showAddAddressRowTop && (
                            <li
                              id={`preAddAddress${key}`}
                              className="complete timeline-li"
                            >
                              <div className="column-border-top-wrapper">
                                <div className="column-border-top-pad"></div>
                                <div className="column-border-top"></div>
                              </div>
                              <div className="custom-table-row">
                                <div className="custom-table-timeline-dot"></div>
                                <div className="custom-table-date-column">
                                  {key === 0 && (
                                    <>
                                      Present
                                      <br></br>-<br></br>
                                      {Moment(gapTopBeginDate)
                                        // .tz("America/New_York")
                                        .format("MM/DD/YYYY")}
                                    </>
                                  )}
                                  {residence.istheregap && key !== 0 && (
                                    <>
                                      {Moment(gapEndDate)
                                        // .tz("America/New_York")
                                        .format("MM/DD/YYYY")}
                                      <br></br>-<br></br>
                                      {Moment(gapBeginDate)
                                        // .tz("America/New_York")
                                        .format("MM/DD/YYYY")}
                                    </>
                                  )}
                                </div>
                                <div className="custom-table-residence-column">
                                  <span
                                    id="addResidentialHistoryBtn"
                                    className="add-residential-btn"
                                    onClick={() => this.props.showAddModal()}
                                  >
                                    <span className="icon-add"></span>
                                    <strong>Add Address</strong>
                                  </span>
                                </div>
                              </div>
                            </li>
                          )}

                          <li
                            id={`address${key}`}
                            key={`${key}-address`}
                            className="active timeline-li"
                          >
                            <div className="column-border-top-wrapper">
                              <div className="column-border-top-pad"></div>
                              <div className="column-border-top"></div>
                            </div>
                            <div className="custom-table-row">
                              <div className="custom-table-timeline-dot"></div>
                              <div className="custom-table-date-column">
                                <span className="timeline-date-text">
                                  {residence.currentaddress === true
                                    ? `Present`
                                    : Moment(residence.enddate)
                                        // .tz("America/New_York")
                                        .format("MM/DD/YYYY")}
                                  <br></br>-<br></br>
                                  {Moment(residence.begindate)
                                    // .tz("America/New_York")
                                    .format("MM/DD/YYYY")}
                                </span>
                              </div>
                              <div className="custom-table-residence-column">
                                <div className="residential-history-address">
                                  {residence.urbanization !== undefined &&
                                    residence.urbanization !== null &&
                                    residence.urbanization !== "" &&
                                    residence.state === "PR" && (
                                      <>{residence.urbanization}, </>
                                    )}
                                  <>{residence.address1}</>
                                  {residence.address2 !== "" && (
                                    <> {residence.address2}</>
                                  )}
                                  <>, {residence.city}</>
                                  {residence.country === "US" ? (
                                    <>, {residence.state}</>
                                  ) : (
                                    <></>
                                  )}
                                  <>, {residence.zipcode}</>
                                  <>, {residence.country}</>
                                </div>

                                <div className="address-quick-action-wrapper">
                                  <button
                                    id="dropbtn"
                                    className="icon-md icon-quick-actions"
                                    aria-label="Review/Edit show menu"
                                    onClick={() => this.showMenu(key)}
                                  />
                                  <div
                                    id={"mnuDropDown" + key}
                                    className="dropdown-content"
                                  >
                                    <span
                                      id="mnuLinkReview"
                                      role="presentation"
                                      onClick={() =>
                                        this.handleReviewEditClick(
                                          residence,
                                          key
                                        )
                                      }
                                      className="menu-item-hover"
                                    >
                                      Review/Edit
                                    </span>
                                    <span
                                      id="mnuLinkDelete"
                                      role="presentation"
                                      onClick={() =>
                                        this.handleDeleteClick(residence, key)
                                      }
                                      style={{ textAlign: "left" }}
                                      className="menu-item-hover"
                                    >
                                      Delete
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>

                          {showAddAddressRowBottom && (
                            <li
                              id={`postAddAddress${key}`}
                              key={`postAddAddress${key}`}
                              className="complete timeline-li"
                            >
                              <div className="column-border-top-wrapper">
                                <div className="column-border-top-pad"></div>
                                <div className="column-border-top"></div>
                              </div>
                              <div className="custom-table-row">
                                <div className="custom-table-timeline-dot"></div>
                                <div className="custom-table-date-column">
                                  {Moment(gapBottombeginDate)
                                    // .tz("America/New_York")
                                    .format("MM/DD/YYYY")}
                                  <br></br>-<br></br>
                                  {Moment(gapBottomEndDate)
                                    // .tz("America/New_York")
                                    .format("MM/DD/YYYY")}
                                </div>
                                <div className="custom-table-residence-column">
                                  <span
                                    role="presentation"
                                    id="addResidentialHistoryBtn"
                                    className="add-residential-btn"
                                    onClick={() => this.props.showAddModal()}
                                  >
                                    <span className="icon-add"></span>
                                    <strong>Add Address</strong>
                                  </span>
                                </div>
                              </div>
                            </li>
                          )}
                        </Fragment>
                      );
                    }
                  )}
                </ul>

                <DeleteModal
                  message={"Are you sure you'd like to delete this address?"}
                  isOpen={this.state.show_modal}
                  closeClick={this.closeDeleteModal}
                  deleteClick={this.deleteHistoryRow}
                  show_spinner={false}
                />
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ResidentialHistoryTable;
