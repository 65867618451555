import React from "react";
import { Button, Modal } from "react-blueprint-library";
import "../../../assets/stylesheets/AuthorizationFormModal.scss";

export default function AuthModal(props) {
  return (
    <>
      <div id="modal"></div>

      <div>
        <Modal
          id="AuthorizationForm"
          show={props.isOpen}
          className="modal-md"
          title=""
        >
          <div>
            <div className="modal-body">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <h2 id="work-requirement-heading">
                      Work requirements not met
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close modal dialog"
                        onClick={props.closeModal}
                      />
                    </h2>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-md-12"
                    style={{ paddingBottom: "30px" }}
                    id="work-requirement-text"
                  >
                    Based on your answers, we cannot move you forward in the
                    application. Please notify your Agency so they can contact
                    you about next steps.
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-md-12"
                    style={{
                      paddingBottom: "10px",
                      paddingLeft: "0px",
                    }}
                  >
                    <div className="col-md-12">
                      <Button
                        label="Exit Application"
                        onClick={props.contactAgency}
                        id="ext-app-bttn"
                      ></Button>
                      <span
                        onClick={props.closeModal}
                        style={{
                          marginLeft: "20px",
                          color: "#3174af",
                          cursor: "pointer",
                        }}
                        id="cancel-button"
                      >
                        <strong> Cancel </strong>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}
