import React, { Component } from "react";
import { FORM_ID } from "../../../constants/Forms";
import PersonalSalesRecordTable from "./PersonalSalesRecordTable";
import AddPersonalSalesRecordModal from "./AddPersonalSalesRecordModal";
import AddedPersonalSalesRecordToast from "./AddedPersonalSalesRecordToast";
import "../../../assets/stylesheets/StartApplication/CAS/PersonalSalesRecord.scss";
import NavigationButtons from "../../SharedComponents/NavigationButtons";
import { UtagTracking } from "../../../utils/utag-tracking";

class PersonalSalesRecordForm extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState();
    this.getPSRData = this.getPSRData.bind(this);
    this.showAddModal = this.showAddModal.bind(this);
    this.closeAddModal = this.closeAddModal.bind(this);
    this.handleShowBackModal = this.handleShowBackModal.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
    this.reviewEditClick = this.reviewEditClick.bind(this);
    this.getPSRChartData = this.getPSRChartData.bind(this);
  }
  initialState() {
    return {
      show_modal: false,
      show_psr_added_alert: false,
      edited_data: [],
      added_saved: "added",
      is_next_disabled: true,
      oktasubid: "",
      candidateId: 0,
      reviewEditData: {},
      show_spinner: false,
      isHovered: false,
    };
  }
  async componentDidMount() {
    await this.setState({
      oktasubid: this.props.subId,
      candidateId: this.props.candidateId,
    });
    this.getPSRData();
    UtagTracking.sendPageNameView(
      "personalsalesrecordfrom",
      this.props.candidateId,
      this.props.agencynumber,
      this.props.contracttypeid
    );
  }
  async getPSRData() {
    await this.props.refreshPSR();
  }
  handleShowBackModal() {
    this.props.toggleForm(FORM_ID.EMPLOYMENT_HISTORY);
  }
  async getPSRChartData() {
    await this.props.refreshPSRCharts();
  }
  async handleNext() {
    this.setState({ show_spinner: true });
    await this.getPSRChartData();
    this.setState({ show_spinner: false });
    this.props.toggleForm(FORM_ID.PERSONAL_SALES_RECORD_CHARTS);
  }
  async showAddModal(psr) {
    psr.companyname = psr.companyname + " ";
    await this.setState({ show_modal: true, reviewEditData: psr });
  }
  async closeAddModal(data) {
    if (data !== null) {
      //reload data
      this.getPSRData();
    }
    await this.setState({ show_modal: false });
  }
  async showAlert() {
    var toast_banner = document.getElementById("psr_added_alert");
    toast_banner.classList.add("show");
    toast_banner.classList.remove("d-none");
    await this.setState({ show_psr_added_alert: true });
  }
  async closeAlert() {
    var toast_banner = document.getElementById("psr_added_alert");
    toast_banner.classList.remove("show");
    toast_banner.classList.add("d-none");
    await this.setState({ show_psr_added_alert: false });
    await this.setState({ added_saved: "added" });
  }
  async reviewEditClick(psr) {
    await this.setState({ reviewEditData: psr, added_saved: "saved" });
    this.showAddModal(this.state.reviewEditData);
  }

  handleMouseEnter = () => this.setState({ isHovered: true });
  handleMouseLeave = () => this.setState({ isHovered: false });
  render() {
    return (
      <>
        <AddedPersonalSalesRecordToast
          showToast={this.state.show_psr_added_alert}
          closeToast={this.closeAlert}
          addedSaved={this.state.added_saved}
          hasNYRegs={this.props.hasNYRegs}
        ></AddedPersonalSalesRecordToast>
        <>
          <div className="row">
            <div className="col-md-12">
              <div className="eyebrow">
                <strong>Employment History</strong>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              {this.props.hasNYRegs === false ? (
                <h1>Personal Sales Record</h1>
              ) : (
                <h1>Personal Production Record</h1>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              {this.props.hasNYRegs === false ? (
                <h4 id="psr_page_heading">
                  <b>Personal Sales Record (PSR)</b>
                </h4>
              ) : (
                <h4 id="psr_page_heading">Personal Production Record (PPR)</h4>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <p id="psr_intro_text" style={{ paddingRight: "10px" }}>
                The following roles have been recorded as having sold Individual
                Life, Annuity, Disability and/or Long Term Care (LADL). If any
                of the roles listed here were not in that capacity, please{" "}
                <span
                  id="psr_return_to_emp_hist"
                  onClick={this.handleShowBackModal}
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                >
                  return to Employment History
                </span>{" "}
                to make edits.<br></br>
                <br></br>
                {/* {this.props.hasNYRegs === false ? (
                  <span> Commissions </span>
                ) : (
                  <span> Production </span>
                )}
                for selling Individual Life, Annuity, Disability and Long Term
                Care for each year of those roles */}
              </p>
              <p>Here are some helpful tips to get started.</p>
              <ul>
                <li>
                  Your employment has been broken up by company and calendar
                  year
                </li>
                <li>
                  {this.props.hasNYRegs === false
                    ? "Gather your commissions statements for the below roles and years"
                    : "Gather your premium statements for the below roles and years"}
                </li>
                <li>
                  {this.props.hasNYRegs === false
                    ? "You'll be asked for your First Year Commissions & Renewal Comissions for any LADL products that you sold"
                    : "You'll be asked for your First Year Premiums for any LADL products that you sold"}

                  <div className="tooltip-container">
                    <div
                      className="tooltip-target"
                      onMouseEnter={this.handleMouseEnter}
                      onMouseLeave={this.handleMouseLeave}
                    >
                      <span
                        className="icon-info"
                        style={{
                          width: "2rem",
                          height: "2rem",
                        }}
                      >
                        <span className="sr-only">No Wrap Popover</span>
                      </span>
                    </div>
                    {this.state.isHovered && (
                      <div
                        className="tooltip-content"
                        style={{ textAlign: "left" }}
                      >
                        LADL=Individual Life, Annuity, Disability, and Long Term
                        Care Insurance
                      </div>
                    )}
                    <style>{styles}</style>
                  </div>
                </li>
                <li>
                  Then you'll provide information on the time that you sold LADL
                  in those roles
                </li>
                <li>
                  {this.props.hasNYRegs
                    ? "We'll also need information on any Financial plans for each time period"
                    : "We'll also need information on any investment product sales, Fee-based Sales & their Fees, as well as any Financial plans for each time period"}
                </li>
              </ul>
            </div>
          </div>
          <br />
          <PersonalSalesRecordTable
            data={this.props.psrData}
            showAddModal={this.showAddModal}
            reviewEditClick={this.reviewEditClick}
            hasNYRegs={this.props.hasNYRegs}
          ></PersonalSalesRecordTable>
          <NavigationButtons
            onBack={this.handleShowBackModal}
            onNext={this.handleNext}
            isLoading={this.state.show_spinner}
            isDisabled={this.props.psrNextEnabled}
            nxtbtnid={"psr_next_button"}
            bckbtnid={"psr_back_button"}
          />

          <AddPersonalSalesRecordModal
            isOpen={this.state.show_modal}
            actionCancel={this.closeAddModal}
            showAlert={this.showAlert}
            reviewEditData={this.state.reviewEditData}
            candidateId={this.state.candidateId}
            subId={this.state.oktasubid}
            hasNYRegs={this.props.hasNYRegs}
          />
        </>
      </>
    );
  }
}

export default PersonalSalesRecordForm;

const styles = `
.tooltip-container {
  position: relative;
  display: inline-block;
  margin-left: 5px;
}
.tooltip-target {
  cursor: pointer;
}
.tooltip-content {
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #555;
  color: white;
  text-align: center;
  padding: 5px 10px;
  border-radius: 6px;
  font-size: 14px;
  width: 200px;
  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}
.tooltip-container:hover .tooltip-content {
  opacity: 1;
}
.tooltip-content::after {
  content: '';
  position: absolute;
  top: 99%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent; 
}
`;
