/* eslint-disable jsx-a11y/label-has-for */
import React, { useCallback, useEffect, useState } from "react";
import { Button } from "react-blueprint-library";
import { useDispatch } from "react-redux";

import BackButtonModal from "../../../../../Forms/BackButtonModal";
import { logoutActions } from "../../../../../../../store/actions";

import "./RegActionCommentsForm.scss";

const INV_DESC = `You may use this field to provide a brief summary of the circumstances leading to the investigation as well as the current status or final disposition and/or finding(s).`;
const REG_DESC = `You may use this field to provide a brief summary of the circumstances leading to the action as well as the current status or final disposition and/or finding(s).`;

const RegActionCommentsForm = ({
  updateCCBForm,
  backFormId,
  nextFormId,
  getSubmitDataValues,
  data,
  isInv,
  groupKey,
  headerText,
}) => {
  const dispatch = useDispatch();
  const [showBackModal, setShowBackModal] = useState(false);
  const [comments, setComments] = useState(data || "");
  const [formHasChanged, setFormHasChanged] = useState(false);

  const onCommentsChangeHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setComments(value);
  };

  const handleCloseBackModal = () => setShowBackModal(false);

  const isUpdates = formHasChanged;

  useEffect(() => {
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: true }));
  }, [isUpdates, dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  const handleShowBackModal = () => {
    if (formHasChanged) {
      setShowBackModal(true);
    } else {
      if (
        (data !== null && data && !Object.keys(data).length) ||
        data === null
      ) {
        getSubmitDataValues({ [groupKey]: null }, headerText);
      }
      updateCCBForm(backFormId);
    }
  };

  const handleBackClick = () => {
    if (!data) {
      getSubmitDataValues({ [groupKey]: null }, headerText);
    }
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: false }));
    updateCCBForm(backFormId);
    handleCloseBackModal();
  };

  const handleNext = useCallback(() => {
    getSubmitDataValues({ [`${groupKey}`]: comments }, headerText, true);

    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: false }));

    updateCCBForm(nextFormId);
  }, [
    updateCCBForm,
    nextFormId,
    getSubmitDataValues,
    comments,
    dispatch,
    groupKey,
    headerText,
  ]);

  return (
    <div className="reg-action-comments-form-wrapper">
      <div className="crim-comments-container">
        <label
          htmlFor="regActionCommentsId"
          id="regActionCommentsId"
          className="comments-label"
        >
          {isInv ? INV_DESC : REG_DESC}
          <span style={{ paddingLeft: "5px" }}>(Optional)</span>
        </label>
        <textarea
          id="regActionCommentsId"
          onChange={onCommentsChangeHandler}
          className="form-control"
          minLength={0}
          maxLength={500}
          rows="3"
          cols={500}
          value={comments}
        />
      </div>

      <div className="backbutton-drp-crim row">
        <div className="col-md-12 buttons">
          <span id="regBack" role="presentation" onClick={handleShowBackModal}>
            <strong id="regBackText">Back</strong>
          </span>
          <Button
            id="regNext"
            label="Complete DRP"
            onClick={handleNext}
            disabled={false}
          />
          {false && <span className="mm-dots-loading"></span>} {/* loading */}
        </div>
      </div>

      <BackButtonModal
        isOpen={showBackModal}
        closeBackModal={handleCloseBackModal}
        backClick={handleBackClick}
      />
    </div>
  );
};

export default RegActionCommentsForm;
