import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { authSlice, ccb14Slice, logoutSlice } from "./slice";
import thunk from "redux-thunk";

export const store = configureStore({
  reducer: combineReducers({
    authenticators: authSlice.reducer,
    logoutModal: logoutSlice.reducer,
    ccb14: ccb14Slice.reducer,
  }),
  middleware: [thunk],
});

export default store;
