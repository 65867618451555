import React, { useEffect } from "react";
import DRPCard from "../../common/DRPCard";
import "./CustomerComplaintDRPSummary.scss";
import { CCB_FORM_ID, headerText } from "../constants";
import DRPSummaryListingCard from "../../common/DRPSummaryListingCard";
import { SECTION } from "../../constants";
import { useDispatch } from "react-redux";
import { putCCBStatus } from "../../../../../../store/middlewares";

const CustomerComplaintDRPSummary = ({
  updateCCBForm,
  oktaSubId,
  custCompl = [],
  deleteCustComplDisclosureDRP,
  editCustComplDisclosureDRP,
  groupedList = [],
  isSummaryPage,
  data,
  loading,
  setIsDRPNextDisabled,
  updateCCB14Step,
  setIsDRPCompleted,
  updateCCB14CompletedInd,
}) => {
  const dispatch = useDispatch();

  const updateStatus = async (data) => {
    try {
      await dispatch(
        putCCBStatus({ oktaSubId, data: { custcomplaintstatus: data } })
      );
    } catch (err) {
      console.log(err);
    }
  };

  const existingCustComplKeys = custCompl?.flatMap((x) => x.key);
  const currCustComplKeys = [
    ...new Set(data?.flatMap((x) => x?.complaintdrp?.drpSelectedCheckList)),
  ];

  const isDisabled = currCustComplKeys.length !== existingCustComplKeys?.length;

  useEffect(() => {
    if (!loading) {
      setIsDRPNextDisabled(isDisabled);

      if (!!isDisabled) {
        updateCCB14Step({ custCompDisclosureCompleteStep: "2/2" });
        updateCCB14CompletedInd({ custCompDisclosureCompleteInd: false });
        setIsDRPCompleted(false);
      } else {
        updateCCB14CompletedInd({ custCompDisclosureCompleteInd: true });
        setIsDRPCompleted(true);
      }

      updateStatus(isDisabled ? "1/2" : null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisabled, loading]);

  return (
    <div className="cust-comp-drp-summary">
      {custCompl?.length ? (
        <DRPCard
          header={headerText.CUST_COMP_ARB_CIV_LIT}
          onClick={() => updateCCBForm(CCB_FORM_ID.ADD_DRP_STATUS_DETAILS)}
        >
          {custCompl?.map(({ key, mainQuestion, question }, index) => (
            <DRPSummaryListingCard
              key={`${index}_${key}`}
              questionKey={key}
              mainQuestion={mainQuestion}
              question={question}
              index={index}
              length={custCompl?.length}
              list={groupedList[key]}
              section={SECTION.customerComplaintArbitrationCivilLitigation}
              deleteDRP={deleteCustComplDisclosureDRP}
              editDRP={editCustComplDisclosureDRP}
              isSummaryPage={isSummaryPage}
            />
          ))}
        </DRPCard>
      ) : null}
    </div>
  );
};

export default CustomerComplaintDRPSummary;
