import React, { useEffect, useMemo } from "react";
import DRPCard from "../../common/DRPCard";
import { CCB_FORM_ID, headerText } from "../constants";
import DRPSummaryListingCard from "../../common/DRPSummaryListingCard";

import "./RegulatoryActionDRPSummary.scss";
import { useDispatch } from "react-redux";
import { logoutActions } from "../../../../../../store/actions";
import { groupArrayByKey } from "../../helpers";
import {
  deleteSingleCCB14DrpReg,
  getSingleCCBDRPRegByQkey,
} from "../../../../../../api/CCB/DRP/regulatoryDRP";
import { DRP_TYPES } from "../../../../../../api/helpers/constants";
import { putCCBStatus } from "../../../../../../store/middlewares";

const RegulatoryActionDRPSummary = ({
  updateCCBForm,
  oktaSubId,
  setIsDRPNextDisabled,
  updateCCB14CompletedInd,
  isLoading,
  setIsLoading,
  setIsDRPCompleted,
  updateCCB14Step,
  data,
  secCFTC = [],
  actReg = [],
  actSRO = [],
  proSusp = [],
  regFormInv = {},
  regFormAct = {},
  setIsDataRefetch,
}) => {
  const dispatch = useDispatch();

  const resetShowBackModal = () =>
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: false }));

  const existingSecCFTCKeys = secCFTC?.flatMap((x) => x.key);
  const currSecCFTCKeys = [...new Set(data?.secCFTC.flatMap((x) => x.qKey))];
  const currActRegKeys = actReg?.flatMap((x) => x.key);
  const existingActRegKeys = [...new Set(data?.actReg.flatMap((x) => x.qKey))];

  const existingActSROKeys = actSRO?.flatMap((x) => x.key);
  const currActSROKeys = [...new Set(data?.actSRO.flatMap((x) => x.qKey))];
  const currProSuspKeys = proSusp?.flatMap((x) => x.key);
  const existingProSuspKeys = [
    ...new Set(data?.proSusp?.flatMap((x) => x.qKey)),
  ];

  const currRegFormInvKeys = !!Object.keys(regFormInv)?.length
    ? [regFormInv]?.flatMap((x) => x.key)
    : [];
  const existingRegFormInvKeys = [
    ...new Set(data?.regFormInv?.flatMap((x) => x.qKey)),
  ];

  const currRegFormActKeys = !!Object.keys(regFormAct)?.length
    ? [regFormAct]?.flatMap((x) => x.key)
    : [];
  const existingRegFormActKeys = [
    ...new Set(data?.regFormAct?.flatMap((x) => x.qKey)),
  ];

  const secCFTCDraft = data?.secCFTC.find((x) => x.isDraft === true);
  const actRegDraft = data?.actReg.find((x) => x.isDraft === true);
  const actSRODraft = data?.actSRO.find((x) => x.isDraft === true);
  const proSuspDraft = data?.proSusp.find((x) => x.isDraft === true);
  const regFormActDraft = data?.regFormAct?.find((x) => x.isDraft === true);
  const regFormInvDraft = data?.regFormInv?.find((x) => x.isDraft === true);

  const isDisabled =
    existingActRegKeys.length !== currActRegKeys.length ||
    existingSecCFTCKeys.length !== currSecCFTCKeys.length ||
    existingActSROKeys.length !== currActSROKeys.length ||
    currProSuspKeys.length !== existingProSuspKeys.length ||
    currRegFormInvKeys.length !== existingRegFormInvKeys.length ||
    currRegFormActKeys.length !== existingRegFormActKeys.length ||
    secCFTCDraft ||
    actRegDraft ||
    actSRODraft ||
    proSuspDraft ||
    regFormInvDraft ||
    regFormActDraft;

  const updateStatus = async (data) => {
    try {
      await dispatch(
        putCCBStatus({ oktaSubId, data: { regulatorystatus: data } })
      );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!isLoading) {
      setIsDRPNextDisabled(isDisabled);

      if (!!isDisabled) {
        updateCCB14Step({ regActionDisclosureCompleteStep: "2/2" });
        updateCCB14CompletedInd({ regActionDisclosureCompleteInd: false });
        setIsDRPCompleted(false);
      } else {
        updateCCB14CompletedInd({ regActionDisclosureCompleteInd: true });
        setIsDRPCompleted(true);
      }

      updateStatus(isDisabled ? "1/2" : null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisabled, isLoading]);

  const sectionHandler = (section) => {
    let s = null;

    switch (section) {
      case headerText.REG_PRO_SUSP:
        s = "prof";
        break;
      case headerText.REG_ACT_REG:
        s = "other";
        break;
      case headerText.REG_SEC_CFTC:
        s = "sec_cftc";
        break;
      case headerText.REG_ACT_SRO:
        s = "sro";
        break;
      case headerText.REG_FORM_ACT:
        s = "form";
        break;
      case headerText.REG_FORM_INV:
        s = "inv";
        break;
      default:
        break;
    }

    return s;
  };

  const handleOnClickRegSecCFTC = () => {
    resetShowBackModal();
    updateCCBForm(CCB_FORM_ID.REG_SEC_CFTC_ACT_DETAILS, {
      section: headerText.REG_SEC_CFTC,
      values: secCFTC,
      isReset: true,
    });
  };

  const handleOnClickRegActReg = () => {
    resetShowBackModal();
    updateCCBForm(CCB_FORM_ID.REG_ACT_REG_ACT_DETAILS, {
      section: headerText.REG_ACT_REG,
      values: actReg,
      isReset: true,
    });
  };

  const handleOnClickRegActSRO = () => {
    resetShowBackModal();
    updateCCBForm(CCB_FORM_ID.REG_ACT_SRO_ACT_DETAILS, {
      section: headerText.REG_ACT_SRO,
      values: actSRO,
      isReset: true,
    });
  };

  const handleOnClickRegProSusp = () => {
    resetShowBackModal();
    updateCCBForm(CCB_FORM_ID.REG_PRO_SUSP_ACT_DETAILS, {
      section: headerText.REG_PRO_SUSP,
      values: proSusp,
      isReset: true,
    });
  };

  const handleOnClickRegFormAct = () => {
    resetShowBackModal();
    updateCCBForm(CCB_FORM_ID.REG_FORM_ACT_ACT_DETAILS, {
      section: headerText.REG_FORM_ACT,
      values: [regFormAct],
      isReset: true,
    });
  };

  const handleOnClickRegFormInv = () => {
    resetShowBackModal();
    updateCCBForm(CCB_FORM_ID.REG_FORM_INV_DETAILS, {
      section: headerText.REG_FORM_INV,
      values: [regFormInv],
      isReset: true,
    });
  };

  const listSecCFTC = useMemo(
    () => groupArrayByKey(data?.secCFTC, "qKey"),
    [data.secCFTC]
  );
  const listActReg = useMemo(
    () => groupArrayByKey(data?.actReg, "qKey"),
    [data.actReg]
  );
  const listActSRO = useMemo(
    () => groupArrayByKey(data?.actSRO, "qKey"),
    [data.actSRO]
  );
  const listProSusp = useMemo(
    () => groupArrayByKey(data?.proSusp, "qKey"),
    [data.proSusp]
  );
  const listRegFormAct = useMemo(
    () => groupArrayByKey(data?.regFormAct, "qKey"),
    [data.regFormAct]
  );
  const listRegFormInv = useMemo(
    () => groupArrayByKey(data?.regFormInv, "qKey"),
    [data.regFormInv]
  );

  const editDRPsecCFTC = async ({
    section,
    questionKey,
    dataIndex,
    isDraft,
  }) => {
    setIsLoading(true);
    const data = await getSingleCCBDRPRegByQkey({
      oktaSubId,
      section: sectionHandler(section),
      qKey: questionKey,
      drpTypeId: DRP_TYPES.Regulatory,
      index: dataIndex,
    });
    setIsLoading(false);

    updateCCBForm(CCB_FORM_ID.REG_SEC_CFTC_ACT_DETAILS, {
      section: headerText.REG_SEC_CFTC,
      values: secCFTC,
      isEdit: true,
      data: data,
      isDraft,
    });
  };

  const editDRPactReg = async ({
    section,
    questionKey,
    dataIndex,
    isDraft,
  }) => {
    setIsLoading(true);
    const data = await getSingleCCBDRPRegByQkey({
      oktaSubId,
      section: sectionHandler(section),
      qKey: questionKey,
      drpTypeId: DRP_TYPES.Regulatory,
      index: dataIndex,
    });
    setIsLoading(false);

    updateCCBForm(CCB_FORM_ID.REG_ACT_REG_ACT_DETAILS, {
      section: headerText.REG_ACT_REG,
      values: actReg,
      isEdit: true,
      data: data,
      isDraft,
    });
  };

  const editDRPactSRO = async ({
    section,
    questionKey,
    dataIndex,
    isDraft,
  }) => {
    setIsLoading(true);
    const data = await getSingleCCBDRPRegByQkey({
      oktaSubId,
      section: sectionHandler(section),
      qKey: questionKey,
      drpTypeId: DRP_TYPES.Regulatory,
      index: dataIndex,
    });
    setIsLoading(false);

    updateCCBForm(CCB_FORM_ID.REG_ACT_SRO_ACT_DETAILS, {
      section: headerText.REG_ACT_SRO,
      values: actSRO,
      isEdit: true,
      data: data,
      isDraft,
    });
  };

  const editDRPproSusp = async ({
    section,
    questionKey,
    dataIndex,
    isDraft,
  }) => {
    setIsLoading(true);
    const data = await getSingleCCBDRPRegByQkey({
      oktaSubId,
      section: sectionHandler(section),
      qKey: questionKey,
      drpTypeId: DRP_TYPES.Regulatory,
      index: dataIndex,
    });
    setIsLoading(false);

    updateCCBForm(CCB_FORM_ID.REG_PRO_SUSP_ACT_DETAILS, {
      section: headerText.REG_PRO_SUSP,
      values: proSusp,
      isEdit: true,
      data: data,
      isDraft,
    });
  };

  const editDRPregFormAct = async ({
    section,
    questionKey,
    dataIndex,
    isDraft,
  }) => {
    setIsLoading(true);
    const data = await getSingleCCBDRPRegByQkey({
      oktaSubId,
      section: sectionHandler(section),
      qKey: questionKey,
      drpTypeId: DRP_TYPES.Regulatory,
      index: dataIndex,
    });
    setIsLoading(false);

    updateCCBForm(CCB_FORM_ID.REG_FORM_ACT_ACT_DETAILS, {
      section: headerText.REG_FORM_ACT,
      values: [regFormAct],
      isEdit: true,
      data: data,
      isDraft,
    });
  };

  const editDRPregFormInv = async ({
    section,
    questionKey,
    dataIndex,
    isDraft,
  }) => {
    setIsLoading(true);
    const data = await getSingleCCBDRPRegByQkey({
      oktaSubId,
      section: sectionHandler(section),
      qKey: questionKey,
      drpTypeId: DRP_TYPES.Regulatory,
      index: dataIndex,
    });
    setIsLoading(false);

    updateCCBForm(CCB_FORM_ID.REG_FORM_INV_DETAILS, {
      section: headerText.REG_FORM_INV,
      values: [regFormInv],
      isEdit: true,
      data: data,
      isDraft,
    });
  };

  const deleteDRPReg = async ({ section, questionKey, dataIndex }) => {
    setIsLoading(true);

    await deleteSingleCCB14DrpReg({
      oktaSubId,
      section: sectionHandler(section),
      questionKey,
      index: dataIndex,
      drpTypeId: DRP_TYPES.Regulatory,
    });

    setIsLoading(false);

    updateCCBForm(CCB_FORM_ID.DRP_SUMMARY, {
      isDelete: true,
      index: dataIndex,
    });

    setIsDataRefetch(true);
  };

  return (
    <div className="reg-act-disclosure-drp-summary">
      {secCFTC?.length ? (
        <DRPCard
          header={headerText.REG_SEC_CFTC}
          onClick={handleOnClickRegSecCFTC}
        >
          {secCFTC?.map(({ key, mainQuestion, question }, index) => (
            <DRPSummaryListingCard
              key={`${index}_${key}`}
              questionKey={key}
              mainQuestion={mainQuestion}
              question={question}
              index={index}
              length={secCFTC?.length}
              list={listSecCFTC[key]}
              isSummaryPage
              isLoading={isLoading}
              editDRP={editDRPsecCFTC}
              deleteDRP={deleteDRPReg}
              section={headerText.REG_SEC_CFTC}
            />
          ))}
        </DRPCard>
      ) : null}

      {actReg?.length ? (
        <DRPCard
          header={headerText.REG_ACT_REG}
          onClick={handleOnClickRegActReg}
        >
          {actReg?.map(({ key, mainQuestion, question }, index) => (
            <DRPSummaryListingCard
              key={`${index}_${key}`}
              questionKey={key}
              mainQuestion={mainQuestion}
              question={question}
              index={index}
              length={actReg?.length}
              list={listActReg[key]}
              isSummaryPage
              isLoading={isLoading}
              editDRP={editDRPactReg}
              deleteDRP={deleteDRPReg}
              section={headerText.REG_ACT_REG}
            />
          ))}
        </DRPCard>
      ) : null}

      {actSRO?.length ? (
        <DRPCard
          header={headerText.REG_ACT_SRO}
          onClick={handleOnClickRegActSRO}
        >
          {actSRO?.map(({ key, mainQuestion, question }, index) => (
            <DRPSummaryListingCard
              key={`${index}_${key}`}
              questionKey={key}
              mainQuestion={mainQuestion}
              question={question}
              index={index}
              length={actSRO?.length}
              list={listActSRO[key]}
              isSummaryPage
              isLoading={isLoading}
              editDRP={editDRPactSRO}
              deleteDRP={deleteDRPReg}
              section={headerText.REG_ACT_SRO}
            />
          ))}
        </DRPCard>
      ) : null}

      {proSusp?.length ? (
        <DRPCard
          header={headerText.REG_PRO_SUSP}
          onClick={handleOnClickRegProSusp}
        >
          {proSusp?.map(({ key, mainQuestion, question }, index) => (
            <DRPSummaryListingCard
              key={`${index}_${key}`}
              questionKey={key}
              mainQuestion={mainQuestion}
              question={question}
              index={index}
              length={proSusp?.length}
              list={listProSusp[key]}
              isSummaryPage
              isLoading={isLoading}
              editDRP={editDRPproSusp}
              deleteDRP={deleteDRPReg}
              section={headerText.REG_PRO_SUSP}
            />
          ))}
        </DRPCard>
      ) : null}

      <>
        {regFormAct &&
        Object.keys(regFormAct)?.length &&
        [regFormAct]?.length ? (
          <DRPCard
            header={headerText.REG_FORM_ACT}
            onClick={handleOnClickRegFormAct}
          >
            <DRPSummaryListingCard
              key={`${0}_${regFormAct?.key}`}
              questionKey={regFormAct?.key}
              mainQuestion={regFormAct?.mainQuestion}
              question={regFormAct?.question}
              index={0}
              length={1}
              list={listRegFormAct[regFormAct?.key]}
              isSummaryPage
              isLoading={isLoading}
              editDRP={editDRPregFormAct}
              deleteDRP={deleteDRPReg}
              section={headerText.REG_FORM_ACT}
            />
          </DRPCard>
        ) : null}

        {regFormInv &&
        Object.keys(regFormInv)?.length &&
        [regFormInv]?.length ? (
          <DRPCard
            header={headerText.REG_FORM_INV}
            onClick={handleOnClickRegFormInv}
          >
            <DRPSummaryListingCard
              key={`${1}_${regFormInv?.key}`}
              questionKey={regFormInv?.key}
              mainQuestion={regFormInv?.mainQuestion}
              question={regFormInv?.question}
              index={1}
              length={1}
              list={listRegFormInv[regFormInv?.key]}
              isSummaryPage
              isLoading={isLoading}
              editDRP={editDRPregFormInv}
              deleteDRP={deleteDRPReg}
              section={headerText.REG_FORM_INV}
            />
          </DRPCard>
        ) : null}
      </>
    </div>
  );
};

export default RegulatoryActionDRPSummary;
