import React from "react";
import { Button, Modal } from "react-blueprint-library";
import "../../assets/stylesheets/AddressVerificationModal.scss";
import { isSafari } from "../../common/Functions";

export default function LogoutModal(props) {
  return (
    <div>
      <div id="logoutModal"></div>

      <div>
        <Modal
          id="logoutModal"
          show={props.isOpen}
          className={isSafari() ? "modal-md safari-only-wrapper" : "modal-md"}
          title=""
        >
          <div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div>
                    <div style={{ float: "left", width: "350px" }}>
                      <h2>
                        Data on this page may be lost. <br />
                        Do you wish to proceed?
                      </h2>
                    </div>
                    <div style={{ float: "right" }}>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close modal dialog"
                        onClick={props.handleClose}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <p className="logout-modal-text">
                    We are unable to save partially complete pages at this time.
                    Please complete this page to avoid losing the data you have
                    entered.
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="logout-modal-button-wrapper">
                    <div>
                      <button
                        className="btn btn-primary"
                        variant="primary"
                        onClick={props.handleClose}
                      >
                        No, Stay on this page
                      </button>
                    </div>
                    <div>
                      <button
                        type="button"
                        className="btn btn-link"
                        onClick={props.handleLogOut}
                      >
                        Yes, proceed without saving
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}
