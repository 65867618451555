import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getCCB14,
  insertCCB14,
  updateCCB14,
  updateCCB14Status,
} from "../../api/CCB/ccb14";

export const status = {
  FULFILLED: "fulfilled",
  LOADING: "loading",
};

export const fetchCCB = createAsyncThunk(
  "ccb14/fetchCCB",
  async (oktaSubId) => {
    try {
      const response = await getCCB14({ oktaSubId });

      return response;
    } catch (err) {
      console.error({ err });
      throw err;
    }
  }
);

export const postCCB = createAsyncThunk(
  "ccb14/postCCB",
  async ({ oktaSubId, data }) => {
    try {
      const response = await insertCCB14({
        oktaSubId,
        data,
      });

      return {};
    } catch (err) {
      console.error({ err });
      throw err;
    }
  }
);

export const putCCB = createAsyncThunk(
  "ccb14/putCCB",
  async ({ oktaSubId, data }) => {
    try {
      const response = await updateCCB14({ oktaSubId, data });

      return response;
    } catch (err) {
      console.error({ err });
      throw err;
    }
  }
);

export const putCCBStatus = createAsyncThunk(
  "ccb14/putCCBStatus",
  async ({ oktaSubId, data }) => {
    try {
      const response = await updateCCB14Status({ oktaSubId, data });

      return response;
    } catch (err) {
      console.error({ err });
      throw err;
    }
  }
);
