export const FORM_ID = {
  RELEASE_OF_INFO: 1,
  AUTHORIZATION: 2,
  PERSONAL_INFO: 3,
  RESIDENTIAL_ADDRESS: 4,
  BUSINESS_INFO: 5,
  BROKER_DEALER: 6,
  CCB: 7,
  ERRORS_OMISSIONS: 8,
  DIRECT_DEPOSIT: 9,
  REVIEW_SIGN_1: 10,
  REVIEW_SIGN_2: 11,
  PROFESSIONAL_DESIGNATION: 12,
  OTHER_BUSINESS: 13,
  RESIDENTIAL_HISTORY: 14,
  EDUCATION: 15,
  PERSONAL_INFO_ADDITIONAL_INFO: 16,
  EMPLOYMENT_HISTORY: 17,
  PERSONAL_SALES_RECORD: 18,
  CCB_INTRO: 19,
  CRIMINAL_DISCLOSURE: 20,
  REGULATORY_ACTION_DISCLOSURE: 21,
  CIVIL_JUDICIAL_DISCLOSURE: 22,
  CUSTOMER_COMPLAINT_DISCLOSURE: 23,
  TERMINATION_DISCLOSURE: 24,
  FINANCIAL_DISCLOSURE: 25,
  PERSONAL_SALES_RECORD_CHARTS: 26,
  CORPORATE_INFO: 27,
  CORPORATE_CCB: 28,
  CORPORATE_ERRORS_OMISSIONS: 29,
  CORPORATE_BUSINESS_ADDRESS: 30,
  CORPORATE_DIRECT_DEPOSIT: 31,
  MMLIS_INTRO: 32,
  MMLIS_PAY_TO_PLAY_1: 33,
  MMLIS_PAY_TO_PLAY_2: 34,
  MEMBER_CORPORATE_INFO: 35,
  MMLIS_OBA_CRIA: 36,
};
