export const pdInitialState = {
  certificationsYesCheck: false,
  certificationsNoCheck: false,
  certificationsError: false,
  clu: false,
  cluDate: "",
  cluDateError: false,
  cluDateErrorText: "",
  chfc: false,
  chfcDate: "",
  chfcDateError: false,
  chfcDateErrorText: "",
  msfs: false,
  msfsDate: "",
  msfsDateError: false,
  msfsDateErrorText: "",
  cfp: false,
  cfpDate: "",
  cfpDateError: false,
  cfpDateErrorText: "",
  cic: false,
  cicDate: "",
  cicDateError: false,
  cicDateErrorText: "",
  lutcf: false,
  lutcfDate: "",
  lutcfDateError: false,
  lutcfDateErrorText: "",
  flmi: false,
  flmiDate: "",
  flmiDateError: false,
  flmiDateErrorText: "",
  rhu: false,
  rhuDate: "",
  rhuDateError: false,
  rhuDateErrorText: "",
  msm: false,
  msmDate: "",
  msmDateError: false,
  msmDateErrorText: "",
  ria: false,
  riaDate: "",
  riaDateError: false,
  riaDateErrorText: "",
  jd: false,
  jdDate: "",
  jdDateError: false,
  jdDateErrorText: "",
  pfs: false,
  pfsDate: "",
  pfsDateError: false,
  pfsDateErrorText: "",
  cfa: false,
  cfaDate: "",
  cfaDateError: false,
  cfaDateErrorText: "",
  other: false,
  otherDate: "",
  otherDateError: false,
  otherDateErrorText: "",
  otherProfessionalDesignationName: "",
  otherProfessionalDesignationNameError: false,
  professionalDesignationError: false,
  checkboxHasError: false,
  showBackModal: false,
  isDataFetched: false,
  formHasChanged: false,
  fetchedData: "",
};

export const pdArr = [
  "clu",
  "chfc",
  "msfs",
  "cfp",
  "cic",
  "lutcf",
  "flmi",
  "msm",
  "rhu",
  "ria",
  "jd",
  "pfs",
  "cfa",
  "other",
];

export const pdResetDesignations = (state) => {
  return {
    ...state,
    clu: false,
    cluDate: "",
    cluDateError: false,
    cluDateErrorText: "",
    chfc: false,
    chfcDate: "",
    chfcDateError: false,
    chfcDateErrorText: "",
    msfs: false,
    msfsDate: "",
    msfsDateError: false,
    msfsDateErrorText: "",
    cfp: false,
    cfpDate: "",
    cfpDateError: false,
    cfpDateErrorText: "",
    cic: false,
    cicDate: "",
    cicDateError: false,
    cicDateErrorText: "",
    lutcf: false,
    lutcfDate: "",
    lutcfDateError: false,
    lutcfDateErrorText: "",
    flmi: false,
    flmiDate: "",
    flmiDateError: false,
    flmiDateErrorText: "",
    rhu: false,
    rhuDate: "",
    rhuDateError: false,
    rhuDateErrorText: "",
    msm: false,
    msmDate: "",
    msmDateError: false,
    msmDateErrorText: "",
    ria: false,
    riaDate: "",
    riaDateError: false,
    riaDateErrorText: "",
    jd: false,
    jdDate: "",
    jdDateError: false,
    jdDateErrorText: "",
    pfs: false,
    pfsDate: "",
    pfsDateError: false,
    pfsDateErrorText: "",
    cfa: false,
    cfaDate: "",
    cfaDateError: false,
    cfaDateErrorText: "",
    other: false,
    otherDate: "",
    otherDateError: false,
    otherDateErrorText: "",
    otherProfessionalDesignationName: "",
    otherProfessionalDesignationNameError: false,
    professionalDesignationError: false,
  };
};

export const pdReducer = (state, action) => {
  switch (action.type) {
    case "onChange":
      return {
        ...state,
        [action.fieldName]: action.inputValue,
      };
    case "onCheckChange":
      return {
        ...state,
        [action.fieldName]: action.inputValue,
        professionalDesignationError: false,
      };

    case "onRadioChange":
      let opposite;
      if (action.fieldName.includes("Yes")) {
        opposite = action.fieldName.replace("Yes", "No");
      } else if (action.fieldName.includes("No")) {
        opposite = action.fieldName.replace("No", "Yes");
      }
      return {
        ...state,
        [action.fieldName]: action.inputValue,
        [opposite]: false,
        certificationsError: false,
      };
    case "onBlur":
      return {
        ...state,
        [action.fieldName]: action.hasError,
        [`${action.fieldName}Text`]: action.errorText,
      };
    case "onLoad":
      return {
        ...state,
        fetchedData: action.fetchedData,
        isDataFetched: true,
        certificationsYesCheck: action.hasprofdesignation,
        certificationsNoCheck: !action.hasprofdesignation,
      };
    case "setDesignation":
      return {
        ...state,
        [action.designation]: true,
        [`${action.designation}Date`]: action.designationDate,
      };
    case "setOtherDesignation":
      return {
        ...state,
        other: true,
        otherProfessionalDesignationName: action.otherProfDesName,
        otherDate: action.otherDate,
      };
    case "resetDesignations":
      return pdResetDesignations(state);
    default:
      return state;
  }
};
