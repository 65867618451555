import React, { Component } from "react";
import { Button } from "react-blueprint-library";
import { FORM_ID } from "../../../constants/Forms";
import PersonalSalesRecordMonths from "./PersonalSalesRecordMonths";
import PersonalSalesRecordFinancialPlans from "./PersonalSalesRecordFinancialPlans";
import PersonalSalesRecordCommissions from "./PersonalSalesRecordCommissions";
import "../../../assets/stylesheets/StartApplication/CAS/PersonalSalesRecord.scss";
import { UtagTracking } from "../../../utils/utag-tracking";

class PersonalSalesRecordCharts extends Component {
  constructor(props) {
    super(props);
    this.handleShowBackModal = this.handleShowBackModal.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.getPSRChartData = this.getPSRChartData.bind(this);
  }
  async componentDidMount() {
    await this.getPSRChartData();
    UtagTracking.sendPageNameView(
      "personalsalesrecordcharts",
      this.props.candidateId,
      this.props.agencynumber,
      this.props.contracttypeid
    );
  }
  async getPSRChartData() {
    await this.props.refreshPSRCharts();
  }
  handleShowBackModal() {
    this.props.toggleForm(FORM_ID.PERSONAL_SALES_RECORD);
  }
  handleNext() {
    this.props.toggleForm(FORM_ID.CCB_INTRO);
  }
  render() {
    return (
      <>
        <div className="row">
          <div className="col-md-12">
            {this.props.hasNYRegs === false ? (
              <h1>Personal Sales Record</h1>
            ) : (
              <h1>Personal Production Record</h1>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <p id="psr_intro_text_tally_screen">
              <>
                Please review the data captured from your{" "}
                {this.props.hasNYRegs === false ? <>PSRs.</> : <>PPRs.</>} If
                you need to make any changes to the following totals listed
                below, please return to the{" "}
                <span
                  id="psr_return_to_emp_hist"
                  onClick={this.handleShowBackModal}
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                >
                  previous step
                </span>
                .
              </>
            </p>
          </div>
        </div>

        {this.props.psrDataLength > 0 && (
          <PersonalSalesRecordMonths
            data={this.props.psrChartData}
          ></PersonalSalesRecordMonths>
        )}

        {this.props.psrDataLength > 0 && this.props.totalFinPlans !== 0 && (
          <PersonalSalesRecordFinancialPlans
            data={this.props.psrChartData}
            financialData={this.props.psrFinData}
          ></PersonalSalesRecordFinancialPlans>
        )}

        {this.props.psrDataLength > 0 && (
          <PersonalSalesRecordCommissions
            data={this.props.psrChartData}
            comData={this.props.psrComData}
            hasNYRegs={this.props.hasNYRegs}
            has_ind_life={this.props.has_ind_life}
            has_ind_annuity={this.props.has_ind_annuity}
            has_ind_disabilty={this.props.has_ind_disabilty}
            has_ind_long_term={this.props.has_ind_long_term}
            has_gross_commissions={this.props.has_gross_commissions}
            has_fee_based_sales={this.props.has_fee_based_sales}
          ></PersonalSalesRecordCommissions>
        )}

        <div
          className="row"
          style={{ paddingTop: "50px", paddingBottom: "20px" }}
        >
          <div
            className="col-md-12"
            style={{ float: "right", textAlign: "right" }}
          >
            <span
              id="psr_back_button_two"
              onClick={this.handleShowBackModal}
              style={{
                marginRight: "25px",
                color: "#3174af",
                cursor: "pointer",
              }}
            >
              <strong> Back </strong>
            </span>
            <Button
              id="psr_next_button_two"
              label="Next"
              onClick={this.handleNext}
            />
          </div>
        </div>
      </>
    );
  }
}

export default PersonalSalesRecordCharts;
