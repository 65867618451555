import React from "react";

export default (
  <svg width="25" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.68 15.272a1.082 1.082 0 0 0 0-1.537l-5.475-5.444a1 1 0 0 0-1.41 0L6.32 13.735a1.082 1.082 0 0 0 0 1.537 1.097 1.097 0 0 0 1.545 0l4.635-4.61 4.635 4.61a1.097 1.097 0 0 0 1.545 0Z"
      fill="#004DB2"
    />
  </svg>
);
