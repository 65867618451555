export const profDesignationDesc = {
  CLU: "Chartered Life Underwriter (CLU)",
  CHFC: "Chartered Financial Consultant (CHFC)",
  MSFS: "Master of Science in Financial Services (MSFS)",
  CFP: "Certified Financial Planner (CFP)",
  CIC: "Certified Insurance Counselor (CIC)",
  LUTCF: "Life Underwriter Training Council Fellow (LUTCF)",
  FLMI: "Fellow, Life Management Institute (FLMI)",
  RHU: "Registered Health Underwriter (RHU)",
  MSM: "Master of Science in Management (MSM)",
  RIA: "Registered Investment Advisor (RIA)",
  JD: "Juris Doctor (JD)",
  PFS: "Personal Financial Specialist (PFS)",
  CFA: "Chartered Financial Analyst (CFA)",
  Other: "Other",
};

export const profDesignationID = {
  CLU: 1,
  CHFC: 2,
  MSFS: 3,
  CFP: 4,
  CIC: 5,
  LUTCF: 6,
  FLMI: 7,
  RHU: 8,
  MSM: 9,
  RIA: 10,
  JD: 11,
  PFS: 12,
  CFA: 13,
  Other: 14,
};
